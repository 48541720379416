import { useState } from 'react';
import { SelectOption } from 'types/app';
import { ChartLineData } from 'types/chart';
import { PortfolioDailyChange } from 'types/portfolio';
import { getPreferredCurrency } from 'utils/currency';

export const useComponentState = () => {
  const preferredCurrency = getPreferredCurrency();
  const [portfolioCurrency, setportfolioCurrency] =
    useState<SelectOption>(preferredCurrency);
  const [portfolioHistory, setPortfilioHistory] = useState<ChartLineData>();
  const [fetchedAllHistory, setFetchedAllHistory] = useState(false);
  const [portfolioHistoryFetchedOnce, trackPortfolioHistory] = useState(false);
  const [dailyChange, setDailyChange] = useState<PortfolioDailyChange>();

  return {
    portfolioCurrency,
    setportfolioCurrency,
    portfolioHistory,
    setPortfilioHistory,
    portfolioHistoryFetchedOnce,
    trackPortfolioHistory,
    dailyChange,
    setDailyChange,
    fetchedAllHistory,
    setFetchedAllHistory,
  };
};
