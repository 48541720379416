import PushButton from 'components/PushButton';
import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import sharedStyles from '../shared.module.css';
import inputStyles from 'components/TextInput/styleVariants/loginInputs.module.css';
import { useDisplayType } from 'utils/display';
import { RecoverProps } from './types';
import { useCountdownTimer } from 'utils/hooks/resendOtpTimer';
import { useEffect, useState } from 'react';
import { Analytics } from 'utils/analytics';
import { page_visit_recover } from 'constants/app/analyticsEvents';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { OnboardingSteps } from 'views/Login/types';

export const Recover = (props: RecoverProps) => {
  const {
    updateStep,
    onSubmit,
    recoverFormValues,
    updateRecoverForm,
    isLoading,
    sendPasswordResetCode,
  } = props;
  const { isDesktop } = useDisplayType();
  const { timeRemaining, restart } = useCountdownTimer(30);
  const [isCodeSent, setIsCodeSent] = useState(false);

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_recover);
  }, []);

  const sendCode = () => {
    sendPasswordResetCode();
    setIsCodeSent(true);
    restart();
  };

  return (
    <div
      className={
        isDesktop ? sharedStyles.container : sharedStyles.mobileContainer
      }
    >
      <div
        className={
          isDesktop ? sharedStyles.formContainer : sharedStyles.mFormContainer
        }
      >
        <div className={sharedStyles.backButton}>
          <AiOutlineArrowLeft
            onClick={() => updateStep(OnboardingSteps.SignIn)}
          />
        </div>
        <div className={sharedStyles.header}>
          <span className={sharedStyles.headerText}>Recover Password </span>
          <span className={sharedStyles.welcomeText}>Just a step away!</span>
          <span className={sharedStyles.welcomeText}>
            Please enter your e-mail to receive the verification code.
          </span>
        </div>

        <div className={sharedStyles.inputsContainer}>
          <TextInput
            type={InputTypes.TEXT}
            label={'E-mail'}
            value={recoverFormValues.email}
            placeholder={'Enter your E-mail'}
            onChange={(s) =>
              updateRecoverForm({
                type: 'email',
                value: s,
              })
            }
            isDisabled={isCodeSent}
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
          {isCodeSent && (
            <TextInput
              type={InputTypes.TEXT}
              label={'Verification Code'}
              value={recoverFormValues.code}
              placeholder={'Enter code here'}
              onChange={(s) =>
                updateRecoverForm({
                  type: 'code',
                  value: s,
                })
              }
              inputClass={inputStyles.textInput}
              labelClass={inputStyles.label}
            />
          )}
          {isCodeSent && (
            <TextInput
              type={InputTypes.PASSWORD}
              label={'New Password'}
              value={recoverFormValues.password}
              placeholder={'Enter new password'}
              onChange={(s) =>
                updateRecoverForm({
                  type: 'password',
                  value: s,
                })
              }
              inputClass={inputStyles.textInput}
              labelClass={inputStyles.label}
              autoComplete={false}
            />
          )}
        </div>
        <div className={sharedStyles.mainActionContainer}>
          <span>
            {isCodeSent && (
              <span className={sharedStyles.actionText}>
                Not received?{' '}
                {timeRemaining > 0 ? (
                  <span className={sharedStyles.actionLinkTimer}>
                    Resend in {timeRemaining}
                  </span>
                ) : (
                  <span onClick={sendCode} className={sharedStyles.actionLink}>
                    Resend
                  </span>
                )}
              </span>
            )}
          </span>
          <PushButton
            disabled={isLoading}
            title={
              isLoading ? 'Updating...' : isCodeSent ? 'Submit' : 'Send Code'
            }
            additionalClassName={sharedStyles.submitButton}
            onClick={isCodeSent ? onSubmit : sendCode}
          />
        </div>
      </div>
    </div>
  );
};
