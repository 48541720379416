export enum OnboardingSteps {
  SignIn = 0,
  SignUp = 1,
  Verification = 2,
  Recover = 3,
}

export type LoginFormParameters = {
  email: string;
  password: string;
  submittingForm: boolean;
};

export type SignupFormParameters = {
  email: string;
  password: string;
  rePassword: string;
  code: string;
  submittingForm: boolean;
};

export type RecoverFormParameters = {
  email: string;
  password: string;
  code: string;
  submittingForm: boolean;
};
