import { Auth } from 'aws-amplify';
import { useAuthContainer } from 'containers/auth';
import { toast } from 'react-toastify';
import { useComponentState } from './state';
import { deleteUserAccount } from 'apis/user';
import {
  click_delete_account,
  page_settings,
} from 'constants/app/analyticsEvents';
import { Analytics } from 'utils/analytics';

export const useComponentLogic = () => {
  const AuthContainer = useAuthContainer();
  const State = useComponentState();

  // On click logout
  const logoutUser = async () => {
    try {
      await Auth.signOut();
      AuthContainer.onLogoutSuccess();
    } catch (error: any) {
      error && error.message && toast.error(error.message);
      console.log('error signing out: ', error);
    }
  };

  const showDeleteConfirmation = (show: boolean) => {
    State.setShowDeleteConfirmation(show);
  };

  const deleteUser = async () => {
    State.setShowDeleteLoader(true);
    await deleteUserAccount();
    Analytics.track(click_delete_account, {
      screen: page_settings,
    });
    State.setShowDeleteLoader(false);
    logoutUser();
  };

  return {
    State,
    user: AuthContainer.State.appUser,
    logoutUser,
    showDeleteConfirmation,
    deleteUser,
  };
};
