import { get_strategy_events } from './liveEvents';

export const get_startegy_chart = {
  plotInfo: {
    ema100: [
      {
        name: 'EMA',
        output_name: 'real',
        data: [
          53389.949910418574, 53269.0831795192, 53133.97460170694,
          52989.381243257296, 52800.50498101458, 52608.06547644003,
          52389.420219480824, 52177.34734384754, 51973.134921197096,
          51772.13205147042, 51593.06587223338, 51440.78060743668,
          51264.925743923086, 51102.45117473649, 50943.675705929825,
          50787.7942068025, 50617.77095518265, 50454.09469864438,
          50279.95440758212, 50089.874320303265, 49819.68492782201,
          49527.640077766126, 49264.60859107769, 49015.01931204645,
          48776.27279101582, 48539.30382485709, 48313.97305604804,
          48104.12329256194, 47907.34540558052, 47708.81896190566,
          47525.8106656303, 47350.93500888514, 47143.91372158049,
          46949.214637984835, 46842.779694658406, 46734.657126447346,
          46648.44351998304, 46592.83057899328, 46542.33868633994,
          46499.375940075784, 46439.892060074286, 46359.373999478754,
          46277.36401929105, 46193.72631593876, 46121.29490374195,
          46090.07837099459, 46046.18691810361, 45936.67232566592,
          45818.608319217084, 45704.95607527219, 45560.04387576185,
          45390.69964059824, 45248.91014276462, 45090.516080531655,
          44956.58922745182, 44842.97696552208, 44729.58573848204,
          44590.367605046755, 44562.04349405573, 44559.25451397542,
          44546.06095924324, 44504.63400955525, 44398.57511827693,
          44299.553036726895, 44183.14228352438, 44060.46619870212,
          43954.92488783673, 43915.05924649343, 43826.087776265835,
          43725.16663218136, 43627.78432263321, 43511.93394000681,
          43435.88316891757, 43353.594987354845, 43309.24657176366,
          43261.89317430299, 43232.10340847521, 43211.6880934559,
          43173.0825866548, 43130.09580276064, 43114.92816310201,
          43110.33077373366, 43127.77887722408, 43151.2517707444,
          43178.182824789066, 43250.45167974374, 43327.12016133297,
          43408.46035615806, 43480.926289699484, 43521.11269980444,
          43575.81324040237, 43620.0743643548, 43675.26794129827,
          43732.79748701514, 43767.74308133167, 43755.91589160233,
          43749.743101669614, 43720.08502044843, 43700.9540299445,
          43670.41731648025, 43588.437765658855, 43518.863552477495,
          43471.911600943284, 43402.01988607312, 43345.581868527115,
          43286.83193053648, 43215.37228834764, 43167.56551036055,
          43134.409361640544, 43099.236701014, 43047.370825746395,
          42981.268037117756, 42911.17560073919, 42842.640044288906,
          42794.78737014457, 42702.07177865656, 42633.43114937623,
          42576.176473150954, 42497.363275662814, 42400.99568604573,
          42323.121514044826, 42247.91435535087, 42158.429912670654,
          42109.55011241975, 41999.5188230649, 41880.98914340025,
          41754.08619006559, 41601.30032491578, 41373.08269471942,
          41168.01373046755, 40929.12118134938, 40693.49006884741,
          40467.619968474195, 40262.05798890045, 40085.16357327866,
          39882.962512421655, 39696.070779502414, 39478.630170007316,
          39297.25590921509, 39097.330247646474, 38906.196183336644,
          38735.65645693394, 38545.03256669762, 38368.98400102044,
          38194.195208921024, 38016.11906617011, 37830.25136188952,
          37656.01529531745, 37493.87835877651, 37379.82571800866,
          37269.35471369165, 37121.5621451037, 36989.50388480462,
          36845.16143164017, 36706.92140329086, 36572.511276493016,
          36469.55263735454, 36363.72644651584, 36241.76691292146,
          36120.3443997943, 35981.16688692709, 35831.53387926517,
          35648.22687175497, 35387.61663667071, 35125.21650525149,
          34876.87003980097, 34590.225286537585, 34310.593300665554,
          34006.83481946425, 33740.83809036595, 33480.10644501217,
          33227.500772833715, 32965.33224267859, 32730.575762625547,
          32502.99386633593, 32284.938344230268, 32062.228079988083,
          31838.07623682, 31609.228984605743, 31381.77910372246,
          31155.25298285667, 30920.095498047627, 30689.060141650643,
          30463.847663598157, 30261.330086101163, 30061.61721310906,
          29873.555684136605, 29710.217551777463, 29549.515224019495,
          29391.937298791385, 29223.03695624106, 29039.681967008564,
          28847.386284493543, 28676.84140757288, 28516.682963858566,
          28364.47221209899, 28222.51434651287, 28075.49742876014,
          27963.756489576772, 27873.318143248518, 27781.23857605548,
          27689.574247816756, 27590.470401325336, 27488.700096348595,
          27391.49177760902, 27271.084019636564, 27151.94710835663,
          27068.78874977531, 27004.91035869065, 26940.92698525123,
          26875.63159940467, 26804.69473605012, 26734.661374940217,
          26660.465902169122, 26584.384795195478, 26505.941135884677,
          26442.70309358993, 26373.624022429736, 26310.27285366875,
          26260.762500130753, 26199.16225260341, 26154.704584235027,
          26110.73795880463, 26076.93483090751, 26044.547606533106,
          26010.10587175027, 25972.179418844324, 25930.249925401862,
          25879.01052093846, 25825.786550226807, 25726.947014578753,
          25636.117766765314, 25554.52355356204, 25472.252394085564,
          25394.170564499713, 25314.445998866056, 25240.081523640987,
          25141.090800400572, 25040.031576630263, 24931.42916917224,
          24839.435126218334, 24739.87522272886, 24647.006802476804,
          24557.590034110926, 24466.387459178037, 24374.615430283422,
          24287.995322753057, 24199.06155398567, 24091.963503411695,
          23996.931354829285, 23904.314298298013, 23853.9340151634,
          23810.258886150263, 23770.983858701744, 23743.751307044284,
          23673.05464749885, 23604.810199033527, 23527.524452518013,
          23453.77743365627, 23387.63570229674, 23308.993015122545,
          23234.300480169622, 23147.977698384086, 23055.173387524996,
          22982.82599371262, 22909.698944332173, 22830.704905830546,
          22751.03510571509, 22681.268469958355, 22609.938995305714,
          22546.629708269957, 22488.11248632402, 22427.40946679285,
          22365.69739814349, 22300.17467738817, 22247.281713479493,
          22209.470590440294, 22168.852558946426, 22125.126171640557,
          22073.739316756586, 22021.149627315863, 21970.018347567035,
          21913.81739018947, 21857.306154740174, 21803.805636824527,
          21755.713049956714, 21704.64803906648, 21652.464711560213,
          21605.148182618428, 21564.449406724994, 21520.152190750243,
          21472.70303845816, 21424.56872086493, 21379.812310550773,
          21336.733848955708, 21301.756941055595, 21262.706704599048,
          21239.288155993127, 21230.0268063695, 21211.513602282976,
          21199.24283788133, 21191.52852425992, 21180.3592465518,
          21166.70341988741, 21153.177015533205, 21133.34855978007,
          21115.021261566606, 21115.684602921723, 21119.321937517332,
          21115.089423903126, 21104.71398976643, 21054.070742444324,
          20952.461618831567, 20886.09901251807, 20810.538834052364,
          20731.36143139786, 20644.202789191964, 20564.50491217826,
          20491.951745600472, 20416.12616647967, 20342.392182985022,
          20270.27451599522, 20199.589476074525, 20121.978397340376,
          20036.024171650468, 19960.596762310855, 19894.11187592846,
          19828.861145712057, 19763.381519064293, 19697.939706805595,
          19633.203871027268, 19565.47527952178, 19503.63477893719,
          19457.298248661205, 19408.190758588706, 19362.34024851764,
          19313.287966368778, 19269.573353173357, 19223.9649699422,
          19181.687643804733, 19135.251056798697, 19097.406481416547,
          19058.419422378596, 19020.18319619288, 18981.86372696134,
          18946.77395018983, 18923.564565037555, 18901.37813800711,
          18870.783323393105, 18826.453356593243, 18785.860616858725,
          18745.313079891224, 18699.64113771516, 18663.916758750504,
          18627.496030854454, 18591.732347075158, 18555.826756043967,
          18521.773156914383, 18488.31448053984, 18457.246669044,
          18422.575645894616, 18385.441672708585, 18350.749164338118,
          18316.228982866076, 18281.10365647269, 18248.146158324715,
          18216.952571031157, 18186.42242110975, 18159.965739503616,
          18133.666417929286, 18110.240350247517, 18087.1379670743,
          18068.141769706494, 18050.520348524187, 18038.443905979155,
          18036.559076157784, 18052.599886530897, 18089.776324421375,
          18146.511842749664, 18200.472004279374, 18259.5844398382,
          18316.519599445364, 18363.271092525654, 18416.901169901383,
          18501.065701190466, 18585.86737047382, 18667.491382939686,
          18751.62917733692, 18828.485827290642, 18912.29680100766,
          18993.4325079184, 19074.238992910112, 19152.42435938714,
          19243.312985933928, 19314.260253539196, 19389.723020795846,
          19475.721772859295, 19555.19144072347, 19631.957946847757,
          19705.123928098295, 19769.040483977536, 19828.31730607699,
          19895.884488134874, 19956.619448765865, 19993.049756711094,
          20025.369365489092, 20061.74918003386, 20095.844047755963,
          20129.074264632083, 20170.079526718575, 20252.336367773652,
          20316.997429797935, 20401.2147084158, 20484.99164488281,
          20559.97715686533, 20644.77364880859, 20720.167437941094,
          20788.722736199685, 20851.128226571967, 20897.349251788364,
          20942.096197297506, 20993.83389636092, 21043.304314254765,
          21084.854129814077, 21135.232661896967, 21181.37300522574,
          21204.600074429192, 21227.21334028208, 21251.035650375503,
          21273.98543947698, 21292.28196542793, 21300.463312647178,
          21281.884237149214, 21259.48435126507, 21243.568423517245,
          21258.490038893142, 21315.024493568526, 21381.467770923606,
          21438.976527935018, 21509.467685797692, 21626.015454395758,
          21730.59910876416, 21854.208433343087, 21970.303513870946,
          22091.791959140828, 22193.95390054398, 22314.774813404496,
          22416.55095571332, 22516.47964965959, 22624.431537785145,
          22713.54992317554, 22803.599825686913, 22913.401809336676,
          23014.691476478525, 23122.625506647266, 23228.172130278013,
          23326.067137599242, 23414.659867547773, 23508.735315715145,
          23601.037784710883, 23688.81565036017, 23772.330785996604,
          23854.826810036277, 23943.320536570212, 24056.073397232187,
          24177.743626989966, 24290.81939675254, 24411.275250282193,
          24531.18920572215, 24645.325855113788, 24757.391679765,
          24849.92392373005, 24959.43057870569, 25035.424032592706,
          25098.953259670077, 25141.802502052848, 25194.77373963596,
          25242.215843801583, 25287.14087659759, 25346.817096862986,
          25407.578936529066, 25488.077769469084, 25563.793061162767,
          25636.400129258553, 25707.624087095017, 25754.369352697096,
          25812.1018407625, 25875.746556787006, 25934.408209127858,
          26005.125076273842, 26061.423589614955, 26108.328072988916,
          26139.228309167353, 26168.714283243247, 26197.031822188925,
          26212.310796006965, 26223.849394105837, 26234.768812044334,
          26248.29061774643, 26266.386645117786, 26281.583741254068,
          26303.84168697181, 26314.08799020009, 26325.29931712682,
          26340.68804352035, 26348.749270381333, 26358.660571957942,
          26375.709075483526, 26374.784341315535, 26376.744849408296,
          26383.263169221995, 26392.590037158192, 26425.707066125357,
          26451.661381647627, 26476.270067159556, 26490.806303453424,
          26497.284000414744, 26512.042535059998, 26523.075752187524,
          26534.801182837276, 26518.828882187034, 26532.913062737785,
          26529.07993278258, 26528.476567776986, 26527.473269405164,
          26513.883897733773, 26502.233721541026, 26490.41107358972,
          26479.397983023588, 26452.64950811223, 26435.73545844664,
          26433.938716695222, 26435.583296562643, 26433.689963957444,
          26441.82184585928, 26478.775670693747, 26548.381895036444,
          26614.451956520872, 26695.126175203626, 26771.01337965504,
          26844.115292929197, 26911.914792079115, 26986.776677384478,
          27047.97733723825, 27115.29085531274, 27181.76034332635,
          27249.169049399097, 27315.85936525258, 27391.9057144555,
          27458.729561694006, 27519.04798621492, 27566.105055794822,
          27621.126737858292, 27673.869376712584, 27723.11374549055,
          27776.35050300559, 27832.701978193596, 27883.14352317986,
          27953.85810687927, 28000.55418397077, 28045.880239733724,
          28089.169541917214, 28129.74044207727, 28163.985779857918,
          28198.544675306275, 28230.256661933872, 28263.354945855976,
          28293.664748908333, 28329.11198160322, 28345.891942363553,
          28363.377448455365, 28382.95334056516, 28399.58357144506,
          28417.693599733277, 28436.212538352418, 28452.942785117717,
          28468.37461115499, 28492.881846577664, 28506.607156546423,
          28520.21176730788, 28531.969752113662, 28542.665994646064,
          28553.47300465307, 28566.494529313408, 28590.334637643835,
          28609.97137749247, 28626.719469027274, 28642.547400333664,
          28658.143887455768, 28670.929949090307, 28685.979455048913,
          28696.15807970141, 28696.838315746925, 28655.780329296493,
          28604.259926736166, 28554.670819276045, 28507.845456518105,
          28460.698417775173, 28413.08062732418, 28373.86556539697,
          28330.423673012872, 28285.46498641856, 28240.552214410272,
          28198.200091352643, 28157.047614296156, 28148.320730844745,
          28131.522102511186, 28088.141070778292, 28042.931346604466,
          27999.89330013705, 27959.72135359968, 27917.469841647213,
          27875.383310129448, 27833.49354161203, 27802.23624375833,
          27764.77612011955, 27727.881741503323, 27690.529825829988,
          27640.470225318502, 27604.81932976764, 27577.436768782143,
          27556.551486232, 27537.60987264325, 27518.244726650315,
          27498.626217211695, 27484.049658455027, 27478.62808105988,
          27471.625544801267, 27453.733553815102, 27436.43467156134,
          27419.395569154185, 27396.20714204222, 27374.595317447325,
          27351.76511314144, 27332.383427732697, 27326.22514203502,
          27317.92286199472, 27310.88597363839, 27324.384667229708,
          27327.753485700407, 27329.708070141984, 27338.596425188676,
          27340.018080135436, 27351.7224745882, 27363.701633507248,
          27374.659026903144, 27378.925580825853, 27379.14725249267,
          27369.174435611625, 27357.10424876783, 27347.300204237774,
          27337.501784351876, 27333.871055948868, 27356.97816375186,
          27377.55107140034, 27396.213426422113, 27422.302467483063,
          27466.792319612112, 27515.168709322763, 27564.22398240548,
          27673.24905206082, 27797.001150039814, 27929.655582712294,
          28052.863590975416, 28168.49045056006, 28285.569847578674,
          28409.140543666224, 28529.251225969863, 28650.251597732837,
          28784.33433837179, 28906.260193057497, 29021.319991214772,
          29140.938803269924, 29257.195064591313, 29371.826647470694,
          29491.178991085137, 29612.635248687413, 29753.000689307464,
          29902.478497439988, 30045.597735114443, 30184.57857204287,
          30308.902362695484, 30412.71003868171, 30560.145483460292,
          30671.103196659096, 30788.78273731931, 30903.224663312987,
          31031.078828395897, 31158.162019912812, 31248.92415813236,
          31370.89278866439, 31488.187584928462, 31611.46248423681,
          31733.625009301428, 31846.76966258259, 31953.619768274024,
          32069.763337219094, 32184.31535034347, 32294.011284000033,
          32420.51640708914, 32559.721032691337, 32706.503982539034,
          32890.35736902341, 33111.80217359721, 33322.71084342697,
          33519.74904454723, 33730.66470703144, 33928.34659402092,
          34123.61715651555, 34264.800975198414, 34407.92154994696,
          34575.46825192821, 34742.731454860324, 34885.25756466507,
          35031.64909803804, 35157.25307629471, 35305.77875795224,
          35443.802742943284, 35606.67655001371, 35770.14434110255,
          35932.49871058566, 36086.353389583965, 36223.08896602785,
          36368.69373897779, 36490.28257582971, 36627.679950565755,
          36745.22609015851, 36850.60676164052, 36955.35276636051,
          37060.86221653159, 37201.826331055716, 37355.19432450016,
          37463.90790223283, 37596.32754773317, 37726.0064081741,
          37849.616578309266, 37970.00080448136, 38147.84316478866,
          38305.509636773044, 38470.82607960922, 38626.63467209221,
          38708.933589476525, 38790.8951025562, 38849.14173418875,
          38921.65575925432, 39005.14673431859, 39079.819076213265,
          39124.327609357555, 39174.51973590493, 39224.45083024344,
          39271.10190291189, 39276.98146919086, 39289.27094504847,
          39305.025579799985, 39318.01695445741, 39367.63067813152,
          39422.14551618832, 39473.807189135085, 39549.626848756176,
          39616.784732939224, 39675.46226298003, 39742.93706965369,
          39811.39376134372, 39874.75408290127, 39928.380338685405,
          39983.43617356292, 40045.12951666069, 40130.366555934736,
          40232.51078254989, 40369.149578935045, 40515.32661697594,
          40669.47836713483, 40852.60295392424, 41027.79081622277,
          41241.0062456045, 41451.67938925589, 41663.01464887459,
          41860.63099246123, 42064.13671538279, 42256.4256913158,
          42454.49290534915, 42640.53047157986, 42811.7758087763,
          42968.852525434195, 43139.13702988104, 43309.23035602201,
          43530.3638143186, 43797.82868928259, 44166.824160781944,
          44502.74803878626, 44856.90946376079, 45196.12472190414,
          45550.93353929218, 46000.3350533656, 46351.29891369499,
          46741.84824213667, 47139.498177935944, 47555.036629858005,
          47966.09075599943, 48381.73014696974, 48850.9651935644,
          49298.510635276, 49769.28091972598, 50197.3929807215,
          50579.61985239038, 50871.125003828194, 51218.10233048506,
          51542.69416552496, 51748.52992462348, 52067.18299542301,
          52333.20452026612, 52560.203242637086, 52786.53604971358,
          53072.1489992242, 53404.97792993263, 53733.35460458743,
          54044.97114707085, 54376.36973821796, 54682.78895132255,
          54977.82639783101, 55300.641914705644, 55584.783658968896,
          55780.41150730615, 55982.052467557514, 56229.690834536574,
          56459.21418434773, 56705.48717079629, 56956.07930602805,
          57246.45397323542, 57482.08854802283, 57742.46461637881,
          57985.31145565844, 58166.12746643748, 58280.15484333971,
          58426.32682663992, 58525.211245914375, 58629.53161728241,
          58681.96406050454, 58776.77823752425, 58876.60460905843,
          58996.68352769093, 59114.395735063394, 59266.96849278491,
          59408.4938691654, 59505.14923809282, 59604.02430268504,
          59686.519068968504, 59761.28067156319, 59827.762638462926,
          59907.72773473099, 59922.86183899374, 59892.01249564733,
          59875.549079891935, 59935.08295949803, 60013.43854445847,
          60092.61797922167, 60153.46098953411, 60196.205920434426,
          60215.94501111889, 60272.54035743337, 60282.984904810924,
          60292.15193639883,
        ],
      },
    ],
    roc: [
      {
        name: 'ROC',
        output_name: 'real',
        data: [
          -1.7813187410266274, -6.988027453829071, -8.60665092483275,
          -9.06285875738051, -14.424991270394438, -15.02744300753589,
          -12.572128318049858, -10.297977000154535, -11.154823754315235,
          -9.508290576635014, -10.46329153976152, -7.155409889316489,
          -8.366665877221113, -6.0482837213554514, -0.8442588259499728,
          -0.024720122946042533, 1.5280100696522858, 1.613205840535481,
          -0.4887420451923363, -2.7295840108993885, -14.706492899835222,
          -20.11550097420066, -14.839153376248316, -14.862089978718052,
          -14.218570156314525, -14.539304962938527, -11.94627125688541,
          -10.94528443912608, -8.384947579225265, -6.880745784693609,
          5.546913992500002, 10.330833482077416, 1.798366926889683,
          1.7764696736392471, 12.48955580232003, 12.424156477676584,
          14.04939706836097, 16.235388381124906, 15.395956280373223,
          17.134789935842477, 13.072381709989633, 9.508150881040489,
          14.422859057099412, 12.709314875450307, 2.3131866479852325,
          7.6415468437330825, 3.522084374388723, -7.582780014320257,
          -9.237678913261782, -9.676102794690078, -11.745024305996221,
          -12.66249159561832, -9.445147280049893, -11.422668086440046,
          -9.894526840126261, -11.955790185444526, -10.842156414934179,
          -6.951947023005889, 7.968992186006862, 10.833632532809446,
          14.343673061297757, 14.71524117924261, 2.402098020079868,
          5.766307176830288, 0.24421292340350842, -3.1392046287567976,
          -0.9870203841221947, 11.2539646203474, -8.660797034291011,
          -12.81286863029364, -11.586408578319363, -11.015828897159285,
          1.3351925710867185, -0.2985687583824115, 7.009716869581872,
          7.712698746972735, 7.815209822303437, 0.6185250911324269,
          4.667723606108276, 5.8680744454431055, 9.165194437343827,
          13.514503668071942, 10.889691986941695, 12.812596024524247,
          8.26304908303741, 14.443214338956789, 12.847269868342238,
          12.401729527147754, 14.070729780905534, 10.9947380936412,
          9.251600351523791, 6.828478390849835, 5.4917249848038585,
          5.116651577093578, 2.2157983809493853, -7.810089570801592,
          -7.805279081774808, -10.926134399217457, -9.165507173771381,
          -7.364238544471425, -14.590602394071839, -12.521141210626263,
          -11.333463341475003, -14.250874453714669, -10.870145755101102,
          -6.466827903425654, -8.66875409577207, -3.4338721400473116,
          -2.9489425192561036, -1.8991504749299337, 2.402097623477606,
          -0.912690075144218, -4.146492436167293, -1.2324002725926708,
          -0.31026906260865905, -5.612016827679733, -1.114972181141649,
          -2.595663404420412, -6.9820389760437696, -9.012459200946655,
          -4.969514582629808, -2.9817286582095037, -4.342242708206568,
          0.6080334843003188, -9.580721158222616, -5.507042937187534,
          -9.591591539546107, -14.35171846861525, -22.074245306068406,
          -17.57523092785698, -24.343154827280088, -24.64729542979186,
          -22.37512573236201, -24.195666414714033, -14.291807204722362,
          -17.048367888171654, -14.172882064050373, -15.63845539155777,
          0.8076788675206448, -5.855125076167667, 1.1720749836620081,
          4.354808429283752, -0.607435709639581, -1.4363802791528713,
          -5.70317046023654, -2.2516562055110767, -5.962010751872315,
          1.1004926986709629, -2.807228283831753, 8.675076649746028,
          8.001274237512156, -1.6112463416775635, 4.615284197580483,
          0.15387167850631212, 1.0895957132436074, 2.4583110027447397,
          9.581974027062712, 7.212897238948401, 2.499889711247083,
          -5.118417909751683, -8.519092457353594, -4.633757892331802,
          -12.734832011170138, -24.285350170924712, -25.876036865742215,
          -24.51765939007079, -34.971966429839576, -34.23745226155032,
          -37.192734789902396, -31.67038249507721, -29.279613417902183,
          -27.093267475118477, -24.78516082880863, -6.124671538429716,
          -4.059917574710625, -4.837688387918382, 3.1211721208098897,
          1.3374006598331878, 6.907988544493926, -2.192038495188109,
          -3.0702992968271947, -6.966577106591931, -3.6781087042769167,
          -8.49971080234939, -4.713224460852383, -6.120461454205173,
          -2.250966937556542, 4.2541518501091335, 6.476215270330443,
          7.299206232069677, 4.614633984899386, 3.546769157356411,
          0.39443592909294, 4.757962769396906, 1.740055572274346,
          3.2425431816187844, 3.0688307185534613, -3.823448622842651,
          3.8797855960360783, 8.358670849112816, 11.31615767452272,
          15.971960983008572, 17.36315074694432, 10.952380717049337,
          9.66951027838383, 2.3084929265618337, 0.2786899167751766,
          10.358079753208948, 6.287061051381504, 1.6118994965939493,
          1.8094327679528677, 0.6095751632998914, 2.5707929043329747,
          2.390621026080275, 1.0571008977983576, 6.156849311854495,
          9.681401781349708, 0.007668026724805266, -2.8039339124847573,
          0.1524790998475245, -2.087507311731629, 2.836564302555411,
          2.8639320681055347, 6.159313270218658, 7.112734169881563,
          7.436067220145182, 3.356150927273971, 3.923158322590936,
          0.7261032544977564, -2.5989080218395633, -10.002440610022923,
          -11.747408058345044, -10.105876941087699, -12.309004215757612,
          -11.915284652503267, -12.0844723195492, -10.524170755373973,
          -15.148729351063995, -14.158883349198426, -15.676592845562109,
          -2.6334344322055947, -6.2838486343706474, -6.811752025622331,
          -5.927009700544361, -7.326170321871017, -7.189134447269008,
          -7.230099299412229, -2.1945995884600777, -6.223250289455818,
          -1.3437064862717119, -4.7617709591915025, 7.8158518771269,
          7.971662871159224, 8.42169420399712, 12.248883061529114,
          1.7228303894230912, 1.132033019504708, -0.47967251339101624,
          5.389340739869541, 4.25432440221345, 0.49902250389108893,
          -8.5350215893083, -12.810866791633913, -15.419114146920743,
          -13.369105017293471, -4.380285690633833, -6.457847074487145,
          -4.540175861389873, -2.9059803164119047, -5.143231302967832,
          -0.01730515477298411, 0.2789064043543954, 2.901245033112576,
          4.601990319239757, -1.7773248948670939, 1.7582767363870655,
          7.490922544330214, 7.182712318249518, 3.811404516996708,
          2.363629788150723, 0.026477348473830098, -0.7783473555637022,
          -1.4969151931692015, -1.299521773915835, 0.5180827840980617,
          -1.293743771995426, -5.708035571167414, -5.401607083151017,
          -3.4953235537684813, 0.10122841215784995, -0.4661663738106392,
          -1.6207092744387253, -0.47015790928958134, 0.5475865687303294,
          0.2548611288750502, 1.007838399019767, 0.7967385812014838,
          5.300012218534511, 7.831654292326529, 3.8120477589097757,
          6.541994180294974, 8.814592367588926, 8.326681343057851,
          6.9210206231668625, 6.661355370007316, 2.9710174549319346,
          4.542745575207507, 5.320947586338098, 2.540874338459398,
          3.0079659583022833, -0.0034479677386678453, -10.872060921677274,
          -22.807778749512785, -14.101930920991624, -16.663607311598227,
          -16.57297795139302, -19.190442115972928, -21.415493850160683,
          -20.652254033804752, -20.2951556474396, -18.933249413703855,
          -9.957174197979956, 4.885255805978983, -7.504736906395326,
          -7.551239549838284, -3.4804735642466667, 1.6733772814814696,
          -0.12340954519580727, -2.239155247426572, -1.2254272401450828,
          -1.5802249624982778, -2.919322533225155, -1.5457454427005413,
          5.426274690222432, 7.579101581683112, 5.335571586509857,
          1.6990190392041082, 3.052903948743735, 2.6885742403828905,
          3.830162644749824, 2.482594568799401, 6.236943275451479,
          4.172289787520556, -0.2106196587670084, 0.6342560714645451,
          0.6850276784178488, 5.267926941937318, 4.0772958721361885,
          2.298608716665651, -2.6733048705128937, -0.357078371931685,
          -2.8209891953716038, -4.026491427183609, -1.3541388726544312,
          -1.5240224640841071, -2.2568497190268655, -5.604595295560544,
          -5.431791564975875, -3.020394852831876, 1.727200140451135,
          -0.41820353684792755, -1.1405042713647284, 1.18371811218283,
          -1.7050633420851447, -1.677714926949514, -1.216781213012208,
          -0.6295556813779579, -0.9559209604112939, 0.1084237210902339,
          -0.5173945396376611, 1.4622574875676086, 2.394709472415757,
          2.9720797885227856, 3.4368850662472417, 5.43004642615339,
          7.982968992131423, 13.037647387642327, 19.519009689850407,
          24.35888610094976, 24.00003564670552, 24.98429263774544,
          24.736463448966205, 20.724399996964003, 22.66428613840985,
          29.96761590444399, 26.975532874182285, 20.487811607598605,
          14.98463874328213, 8.007522815644252, 10.490094147521734,
          8.60960131032089, 9.176093847070298, 11.341474561442944,
          12.674458832959434, 0.7011890744383686, 1.4992395829447114,
          4.512882752595138, 2.4981618008024675, 3.5303047909480467,
          1.153031923243386, -0.33351224377599076, -1.3506017120449854,
          0.9462875609184129, -3.282327322963652, -4.5114923015927015,
          -6.486190564117922, -7.879900525267713, -7.26043831692702,
          -7.0755252454986595, -4.831344494153511, 6.066129418377342,
          3.3177345917763112, 5.72066990068183, 7.267996341941396,
          11.356993258045488, 14.876216116482688, 11.844958616446855,
          11.01138749716528, 9.94871399198216, 4.438995956727609,
          -4.7976385511458775, 0.15788095104456445, -4.386981343485563,
          -6.0505968873759475, -2.6483040372844657, -5.5425042870599235,
          -8.57928297540994, -7.590869486287644, -6.3072154785674295,
          -3.3438874389753237, -4.14175886673055, -7.8515040426918485,
          -13.323080236794594, -12.92427436859297, -13.429446988167493,
          -6.256935767336646, 7.8693443868170565, 10.39909033019386,
          8.271957856893186, 11.551896474788027, 23.41282712465471,
          23.96657243529734, 37.37632733562448, 37.54868939971783,
          37.396563212361535, 23.884319349223617, 17.342706237341112,
          11.285017152126775, 13.082988067855684, 11.877659629789928,
          -0.9863796959532656, 1.3140579072964398, 1.3431943164930793,
          1.1239307558787992, 1.2804980667464383, 4.40997146156632,
          -0.43660791626628503, 1.2585564390789505, 2.5580645924782264,
          0.7221096930247084, 3.350831394463616, 2.3669650530958597,
          -1.4470555865192525, 1.0533195997078693, 4.117214748030595,
          6.142433432573946, 6.091892373491725, 9.258417266187058,
          8.171211060919624, 7.5437672901074615, 8.100323109729612,
          5.460911556625314, 8.739339933095614, 1.671176425728782,
          -4.702480047129731, -9.726950817240287, -6.929922206418815,
          -9.163279980404193, -9.702323141845925, -6.582914921196803,
          -6.2345547630479174, 0.14440914065687593, -3.5164899550724216,
          1.504839028929994, 3.503654803823153, 2.9542776908054957,
          3.0665226292696746, 5.203076410081708, 4.824373439938223,
          4.25719564718865, 1.523510757764579, -3.537740090259589,
          -5.6049632058188354, -5.481201965758309, -5.591106826790481,
          -3.9177348364308973, -6.53944595474133, -7.754659934348885,
          -6.659717540924948, -7.942455688935091, -6.289335209822456,
          -3.603539910165632, -3.0630540764522096, -2.707408028081393,
          -1.7983423162280854, -0.818877643720739, 0.20250039093101524,
          1.6594784442963917, -2.186708928946912, -2.534225948323654,
          -1.2129982274068474, -2.0117778805142428, 4.637064301181759,
          3.1850138354316604, 2.1841952917136975, 1.7293771670022773,
          -0.11672570613652233, 0.08442442782978787, 2.8113856160941975,
          2.4228491651554274, -3.6610609183282072, 1.3994422488490699,
          -6.148797434526987, -4.46269162544527, -4.392909757929397,
          -5.031688309779181, -3.3275498891972677, -4.909224857107941,
          -4.192843384478762, -7.3265521454563665, -0.5041549739196682,
          -3.2503760547159644, 0.6744664065234884, -0.5986729115225242,
          1.3843289909550638, 9.545915226105905, 15.69239611117219,
          15.3626744293325, 18.331935567830193, 21.48480217759845,
          19.001784870904494, 14.890833175175565, 15.746319623758186,
          14.189234080372891, 13.421669736834762, 7.644520151377754,
          1.9737686575499236, 2.449763961221918, 1.5240236570702503,
          0.7831645179433577, 0.13836611773234697, -1.2308712934027022,
          -1.132982584636466, 0.688955598238028, -0.9412982624737665,
          -0.19831320556576992, 0.1183551898096713, -0.7741769858147562,
          0.9565672322041863, -1.477255626328755, -0.7057575510222791,
          1.1257907981253368, -0.6811733185696389, -1.4050031319517542,
          -0.8338663115026179, -2.0109780586796355, -2.3524839903207084,
          -1.9289005924950642, -4.357061037577459, -3.746072926209776,
          -3.501574141815389, -2.9109231645022438, -3.036764217930854,
          -1.825203882363624, -1.8599956334520273, -1.7413087248322134,
          -2.23890130734955, -0.29539504598240507, -2.9841359537956635,
          0.058745908522261914, -0.3931723762535033, -0.9533605251574317,
          -0.459778296246971, -0.3516391748145997, 1.4224148210227883,
          1.0276256792353111, 0.7645665318270156, -0.9424361887956034,
          0.8365651899660209, 0.3774794784069391, 1.0886175340446336,
          0.43983705356298586, -1.2304209028885071, -8.85845977516736,
          -12.483599492382035, -11.77060772449725, -11.08700338643558,
          -11.211536180721627, -11.465003430153475, -9.797760293531487,
          -11.045794339492499, -10.753390410958907, -9.443410506809657,
          -1.9593282753786911, 0.2533200276349179, 6.192832876309251,
          4.238260495708479, -0.7124452480430143, -0.9631178999078949,
          -2.1307303977797343, -0.7977066506748454, -0.8978891412551149,
          -0.8658446261094022, -1.3095663627409215, 0.5168453292496267,
          -6.515434577581313, -5.122272938561522, -0.3822938246267005,
          -2.489938984811113, -0.11368595694313255, 0.9656461905317437,
          2.697705647250337, 3.1323544806355574, 3.1045091314229945,
          1.0379356313083177, 3.2882808127978835, 5.052388635301042,
          4.966370129415298, 5.585927005721203, 2.863920805260034,
          1.3498588971093017, -1.034395780524855, -1.1097368421052578,
          -1.2726061731941685, -0.5824896494243004, 0.9673233190758301,
          -1.114653075714822, -0.5988571428571343, 5.361659555376219,
          3.440049600942663, 3.2002606867258976, 5.829655011090207,
          4.202957557952325, 6.519111864347327, 6.004931560661109,
          3.3146333330742683, 2.538971329351214, 1.585754468418421,
          -3.990523199549023, -2.6728245020551267, -2.0580855130410525,
          -3.333828919199222, -0.9348279977045126, 2.039626810124484,
          1.5711456335822493, 1.4433831654848905, 4.072436074942765,
          8.320226417408904, 11.290125735241574, 12.080996635603691,
          23.110676792494967, 26.329970267178314, 27.037856091978576,
          19.827106486208468, 19.355287434000168, 20.342514124293775,
          20.241828729202883, 16.1976592434403, 15.814114437408477,
          18.10111608050824, 5.659511841400611, 2.340760899845007,
          1.6408255437941444, 2.5188233895511836, 3.4051378466081417,
          3.867609518500048, 3.182626139398592, 6.457947603940606,
          7.684404371045184, 4.823548908104502, 6.074537535355429,
          5.02970033511172, 1.3950117605720402, 8.129583444248055,
          3.188429864786646, 3.4317237264654965, 2.6481050237026293,
          1.794960313167815, 0.39448678248108493, -3.73915970913008,
          0.9286876556929835, 2.2799868249753885, 6.082440559654967,
          -0.20479050773676288, 3.5503190923668493, 1.7173249955208192,
          3.420385527276526, 1.3243625645278145, 0.7348169953734107,
          8.228131605563792, 5.458702524624193, 7.18067221032288,
          11.342150849150578, 16.655739562056482, 16.86433488226029,
          16.19227392213778, 16.796165512084293, 15.477521381790972,
          16.078773278309065, 6.646102684106436, 5.176227840817638,
          7.246950760352289, 2.4556632238736498, -4.83970801382786,
          -3.392524545451858, -4.38722496218208, -3.42575522984655,
          -3.2887019142783913, -0.2753627524034852, 6.322872781394984,
          5.9689258680929225, 1.943430957033554, -0.0714978711020775,
          3.9003774412676995, 0.5461465446710667, 4.9648758358076694,
          -0.2204520626942763, -0.4944650616106272, -3.500543750442253,
          -3.598165145981247, 0.47876869724696736, 2.848257385904951,
          -0.3402300454740992, 1.319460906693637, 3.849026545716394,
          1.2421926898824154, 3.2075643693132205, 11.610278377681293,
          9.420250648548144, 10.335950740216404, 4.888257123488127,
          -4.814969482885479, 0.006441790603051167, -5.478345952875463,
          -3.7014518708867183, -1.8885643117590223, -2.624506533494253,
          -11.977455664453862, -9.652938625027108, -10.627065337819975,
          -10.269564661940356, -7.514036621786424, -6.88571389229693,
          -3.9477048380932356, -5.998456873616542, -3.04706180984492,
          -1.5323276315512668, 1.7024015486056365, 3.9455311369467783,
          2.9860389619733585, 2.4041896733383306, 8.883234490884329,
          8.277189605389811, 7.300034327157778, 6.5608570737184735,
          2.116489027343693, 2.32266231535474, 5.51625393221109,
          4.586203631644237, 9.761440671058729, 12.144410521371519,
          12.109317516399742, 15.549236111111098, 15.550640544101535,
          21.633788038761125, 21.474079051809113, 20.940556556482235,
          16.444432418781684, 15.123038553809831, 9.848689139212507,
          9.440056761007988, 7.348655765767242, 2.746844929620562,
          2.101747720655256, -0.4381682693579303, -0.2913454124903625,
          4.512997919772643, 10.446212664573306, 19.744706658352772,
          18.071074441141466, 19.382527198279643, 19.55257332824938,
          23.0569590562548, 34.48980818478584, 23.572250506222623,
          27.7315076596522, 22.66428056002894, 19.437880518271022,
          9.420106003161855, 12.800220117524708, 15.532178515385198,
          15.268826120455682, 15.778503554759759, 4.605754706046716,
          9.063836378156376, -1.1705202224655764, 2.3499483786836084,
          -0.7547979653042436, -9.333281806009285, -1.6175125312011263,
          -9.124588467232087, -10.714002307282877, -12.42931497674704,
          -5.85377791013566, 0.5469939863179452, 7.178139016422969,
          1.5739950650266898, 4.689558451347198, 12.776028699945407,
          2.5673008649256923, 8.82233275782287, 9.174715157412683,
          2.3034533046642602, -1.8549474564718782, -1.992300802475555,
          -3.096788020803576, -0.826241662047178, -2.0064961302955986,
          2.533208762595107, -0.6268558990247142, -0.9103954951745874,
          0.5117459059466034, 2.524334370697545, -3.090765043824373,
          -4.126209941947312, -6.488631333656336, -7.4062500000000036,
          -11.653654196581066, -11.37939123149958, -7.705420414774533,
          -8.05663738965906, -7.235184639995607, -0.4428119932320618,
          3.8944217171160034, -2.0898744232570987, 1.7003313939343068,
          -0.03664956510384121, 3.565117106037685, -0.5537412273625586,
          0.07519820815471423, -6.5730693238235105, -10.126368258030539,
          -11.611477039874107, -5.318140753455591, -0.6183738300399755,
          -0.7540349100457422, -0.9484395564623549, -1.8119510295770858,
          -3.0507479409404126, -1.240080794162779, 0.2109539820674966,
          4.079416129229574,
        ],
      },
    ],
  },
  priceData: [
    [1640995200000, 46216.93, 47954.63, 46208.37, 47722.65],
    [1641081600000, 47722.66, 47990, 46654, 47286.18],
    [1641168000000, 47286.18, 47570, 45696, 46446.1],
    [1641254400000, 46446.1, 47557.54, 45500, 45832.01],
    [1641340800000, 45832.01, 47070, 42500, 43451.13],
    [1641427200000, 43451.14, 43816, 42430.58, 43082.31],
    [1641513600000, 43082.3, 43145.83, 40610, 41566.48],
    [1641600000000, 41566.48, 42300, 40501, 41679.74],
    [1641686400000, 41679.74, 42786.7, 41200.02, 41864.62],
    [1641772800000, 41864.62, 42248.5, 39650, 41822.49],
    [1641859200000, 41822.49, 43100, 41268.93, 42729.29],
    [1641945600000, 42729.29, 44322, 42450, 43902.66],
    [1642032000000, 43902.65, 44500, 42311.22, 42560.11],
    [1642118400000, 42558.35, 43448.78, 41725.95, 43059.96],
    [1642204800000, 43059.96, 43800, 42555, 43084.29],
    [1642291200000, 43084.29, 43475, 42581.79, 43071.66],
    [1642377600000, 43071.66, 43176.18, 41540.42, 42201.62],
    [1642464000000, 42201.63, 42691, 41250, 42352.12],
    [1642550400000, 42352.12, 42559.13, 41138.56, 41660.01],
    [1642636800000, 41660, 43505, 40553.31, 40680.91],
    [1642723200000, 40680.92, 41100, 35440.45, 36445.31],
    [1642809600000, 36445.31, 36835.22, 34008, 35071.42],
    [1642896000000, 35071.42, 36499, 34601.01, 36244.55],
    [1642982400000, 36244.55, 37550, 32917.17, 36660.35],
    [1643068800000, 36660.35, 37545.14, 35701, 36958.32],
    [1643155200000, 36958.32, 38919.98, 36234.63, 36809.34],
    [1643241600000, 36807.24, 37234.47, 35507.01, 37160.1],
    [1643328000000, 37160.11, 38000, 36155.01, 37716.56],
    [1643414400000, 37716.57, 38720.74, 37268.44, 38166.84],
    [1643500800000, 38166.83, 38359.26, 37351.63, 37881.76],
    [1643587200000, 37881.75, 38744, 36632.61, 38466.9],
    [1643673600000, 38466.9, 39265.2, 38000, 38694.59],
    [1643760000000, 38694.59, 38855.92, 36586.95, 36896.36],
    [1643846400000, 36896.37, 37387, 36250, 37311.61],
    [1643932800000, 37311.98, 41772.33, 37026.73, 41574.25],
    [1644019200000, 41571.7, 41913.69, 40843.01, 41382.59],
    [1644105600000, 41382.6, 42656, 41116.56, 42380.87],
    [1644192000000, 42380.87, 44500.5, 41645.85, 43839.99],
    [1644278400000, 43839.99, 45492, 42666, 44042.99],
    [1644364800000, 44043, 44799, 43117.92, 44372.72],
    [1644451200000, 44372.71, 45821, 43174.01, 43495.44],
    [1644537600000, 43495.44, 43920, 41938.51, 42373.73],
    [1644624000000, 42373.73, 43079.49, 41688.88, 42217.87],
    [1644710400000, 42217.87, 42760, 41870, 42053.66],
    [1644796800000, 42053.65, 42842.4, 41550.56, 42535.94],
    [1644883200000, 42535.94, 44751.4, 42427.03, 44544.86],
    [1644969600000, 44544.85, 44549.97, 43307, 43873.56],
    [1645056000000, 43873.56, 44164.71, 40073.21, 40515.7],
    [1645142400000, 40515.71, 40959.88, 39450, 39974.44],
    [1645228800000, 39974.45, 40444.32, 39639.03, 40079.17],
    [1645315200000, 40079.17, 40125.44, 38000, 38386.89],
    [1645401600000, 38386.89, 39494.35, 36800, 37008.16],
    [1645488000000, 37008.16, 38429, 36350, 38230.33],
    [1645574400000, 38230.33, 39249.93, 37036.79, 37250.01],
    [1645660800000, 37250.02, 39843, 34322.28, 38327.21],
    [1645747200000, 38328.68, 39683.53, 38014.37, 39219.17],
    [1645833600000, 39219.16, 40348.45, 38573.18, 39116.72],
    [1645920000000, 39116.73, 39855.7, 37000, 37699.07],
    [1646006400000, 37699.08, 44225.84, 37450.17, 43160],
    [1646092800000, 43160, 44949, 42809.98, 44421.2],
    [1646179200000, 44421.2, 45400, 43334.09, 43892.98],
    [1646265600000, 43892.99, 44101.12, 41832.28, 42454],
    [1646352000000, 42454, 42527.3, 38550, 39148.66],
    [1646438400000, 39148.65, 39613.24, 38407.59, 39397.96],
    [1646524800000, 39397.97, 39693.87, 38088.57, 38420.81],
    [1646611200000, 38420.8, 39547.57, 37155, 37988],
    [1646697600000, 37988.01, 39362.08, 37867.65, 38730.63],
    [1646784000000, 38730.63, 42594.06, 38656.45, 41941.71],
    [1646870400000, 41941.7, 42039.63, 38539.73, 39422],
    [1646956800000, 39422.01, 40236.26, 38223.6, 38729.57],
    [1647043200000, 38729.57, 39486.71, 38660.52, 38807.36],
    [1647129600000, 38807.35, 39310, 37578.51, 37777.34],
    [1647216000000, 37777.35, 39947.12, 37555, 39671.37],
    [1647302400000, 39671.37, 39887.61, 38098.33, 39280.33],
    [1647388800000, 39280.33, 41718, 38828.48, 41114],
    [1647475200000, 41114.01, 41478.82, 40500, 40917.9],
    [1647561600000, 40917.89, 42325.02, 40135.04, 41757.51],
    [1647648000000, 41757.51, 42400, 41499.29, 42201.13],
    [1647734400000, 42201.13, 42296.26, 40911, 41262.11],
    [1647820800000, 41262.11, 41544.22, 40467.94, 41002.25],
    [1647907200000, 41002.26, 43361, 40875.51, 42364.13],
    [1647993600000, 42364.13, 43025.96, 41751.47, 42882.76],
    [1648080000000, 42882.76, 44220.89, 42560.46, 43991.46],
    [1648166400000, 43991.46, 45094.14, 43579, 44313.16],
    [1648252800000, 44313.16, 44792.99, 44071.97, 44511.27],
    [1648339200000, 44511.27, 46999, 44421.46, 46827.76],
    [1648425600000, 46827.76, 48189.84, 46663.56, 47122.21],
    [1648512000000, 47122.21, 48096.47, 46950.85, 47434.8],
    [1648598400000, 47434.79, 47700.22, 46445.42, 47067.99],
    [1648684800000, 47067.99, 47600, 45200, 45510.34],
    [1648771200000, 45510.35, 46720.09, 44200, 46283.49],
    [1648857600000, 46283.49, 47213, 45620, 45811],
    [1648944000000, 45810.99, 47444.11, 45530.92, 46407.35],
    [1649030400000, 46407.36, 46890.71, 45118, 46580.51],
    [1649116800000, 46580.5, 47200, 45353.81, 45497.55],
    [1649203200000, 45497.54, 45507.14, 43121, 43170.47],
    [1649289600000, 43170.47, 43900.99, 42727.35, 43444.19],
    [1649376000000, 43444.2, 43970.62, 42107.14, 42252.01],
    [1649462400000, 42252.02, 42800, 42125.48, 42753.97],
    [1649548800000, 42753.96, 43410.3, 41868, 42158.85],
    [1649635200000, 42158.85, 42414.71, 39200, 39530.45],
    [1649721600000, 39530.45, 40699, 39254.63, 40074.94],
    [1649808000000, 40074.95, 41561.31, 39588.54, 41147.79],
    [1649894400000, 41147.78, 41500, 39551.94, 39942.38],
    [1649980800000, 39942.37, 40870.36, 39766.4, 40551.9],
    [1650067200000, 40551.9, 40709.35, 39991.55, 40378.71],
    [1650153600000, 40378.7, 40595.67, 39546.17, 39678.12],
    [1650240000000, 39678.11, 41116.73, 38536.51, 40801.13],
    [1650326400000, 40801.13, 41760, 40571, 41493.18],
    [1650412800000, 41493.19, 42199, 40820, 41358.19],
    [1650499200000, 41358.19, 42976, 39751, 40480.01],
    [1650585600000, 40480.01, 40795.06, 39177, 39709.18],
    [1650672000000, 39709.19, 39980, 39285, 39441.6],
    [1650758400000, 39441.61, 39940, 38929.62, 39450.13],
    [1650844800000, 39450.12, 40616, 38200, 40426.08],
    [1650931200000, 40426.08, 40797.31, 37702.26, 38112.65],
    [1651017600000, 38112.64, 39474.72, 37881.31, 39235.72],
    [1651104000000, 39235.72, 40372.63, 38881.43, 39742.07],
    [1651190400000, 39742.06, 39925.25, 38175, 38596.11],
    [1651276800000, 38596.11, 38795.38, 37578.2, 37630.8],
    [1651363200000, 37630.8, 38675, 37386.38, 38468.35],
    [1651449600000, 38468.35, 39167.34, 38052, 38525.16],
    [1651536000000, 38525.16, 38651.51, 37517.8, 37728.95],
    [1651622400000, 37728.95, 40023.77, 37670, 39690],
    [1651708800000, 39690, 39845.51, 35571.9, 36552.97],
    [1651795200000, 36552.97, 36675.63, 35258, 36013.77],
    [1651881600000, 36013.77, 36146.3, 34785, 35472.39],
    [1651968000000, 35472.4, 35514.22, 33713.95, 34038.4],
    [1652054400000, 34038.39, 34243.15, 30033.33, 30076.31],
    [1652140800000, 30074.23, 32658.99, 29730.4, 31017.1],
    [1652227200000, 31017.11, 32162.59, 27785, 29103.94],
    [1652313600000, 29103.94, 30243, 26700, 29029.75],
    [1652400000000, 29029.74, 31083.37, 28751.67, 29287.05],
    [1652486400000, 29287.05, 30343.27, 28630, 30086.74],
    [1652572800000, 30086.74, 31460, 29480, 31328.89],
    [1652659200000, 31328.89, 31328.9, 29087.04, 29874.01],
    [1652745600000, 29874.01, 30788.37, 29450.38, 30444.93],
    [1652832000000, 30444.93, 30709.99, 28654.47, 28715.32],
    [1652918400000, 28715.33, 30545.18, 28691.38, 30319.23],
    [1653004800000, 30319.22, 30777.33, 28730, 29201.01],
    [1653091200000, 29201.01, 29656.18, 28947.28, 29445.06],
    [1653177600000, 29445.07, 30487.99, 29255.11, 30293.94],
    [1653264000000, 30293.93, 30670.51, 28866.35, 29109.15],
    [1653350400000, 29109.14, 29845.86, 28669, 29654.58],
    [1653436800000, 29654.58, 30223.74, 29294.21, 29542.15],
    [1653523200000, 29542.14, 29886.64, 28019.56, 29201.35],
    [1653609600000, 29201.35, 29397.66, 28282.9, 28629.8],
    [1653696000000, 28629.81, 29266, 28450, 29031.33],
    [1653782400000, 29031.33, 29587.78, 28839.21, 29468.1],
    [1653868800000, 29468.1, 32222, 29299.62, 31734.22],
    [1653955200000, 31734.23, 32399, 31200.01, 31801.04],
    [1654041600000, 31801.05, 31982.97, 29301, 29805.83],
    [1654128000000, 29805.84, 30689, 29594.55, 30452.62],
    [1654214400000, 30452.63, 30699, 29282.36, 29700.21],
    [1654300800000, 29700.21, 29988.88, 29485, 29864.04],
    [1654387200000, 29864.03, 30189, 29531.42, 29919.21],
    [1654473600000, 29919.2, 31765.64, 29890.23, 31373.1],
    [1654560000000, 31373.1, 31589.6, 29218.96, 31125.33],
    [1654646400000, 31125.32, 31327.22, 29843.88, 30204.77],
    [1654732800000, 30204.77, 30700, 29944.1, 30109.93],
    [1654819200000, 30109.93, 30382.8, 28850, 29091.88],
    [1654905600000, 29091.87, 29440.41, 28099.99, 28424.7],
    [1654992000000, 28424.71, 28544.96, 26560, 26574.53],
    [1655078400000, 26574.53, 26895.84, 21925.77, 22487.41],
    [1655164800000, 22485.27, 23362.88, 20846, 22136.41],
    [1655251200000, 22136.42, 22800, 20111.62, 22583.72],
    [1655337600000, 22583.72, 22995.73, 20232, 20401.31],
    [1655424000000, 20400.6, 21365.43, 20246.66, 20468.81],
    [1655510400000, 20468.81, 20792.06, 17622, 18970.79],
    [1655596800000, 18970.79, 20815.95, 17960.41, 20574],
    [1655683200000, 20574, 21090, 19637.03, 20573.89],
    [1655769600000, 20573.9, 21723, 20348.4, 20723.52],
    [1655856000000, 20723.51, 20900, 19770.51, 19987.99],
    [1655942400000, 19988, 21233, 19890.07, 21110.13],
    [1656028800000, 21110.12, 21558.41, 20736.72, 21237.69],
    [1656115200000, 21237.68, 21614.5, 20906.62, 21491.19],
    [1656201600000, 21491.18, 21888, 20964.73, 21038.07],
    [1656288000000, 21038.08, 21539.85, 20510, 20742.56],
    [1656374400000, 20742.57, 21212.1, 20202.01, 20281.29],
    [1656460800000, 20281.28, 20432.31, 19854.92, 20123.01],
    [1656547200000, 20123, 20179.08, 18626, 19942.21],
    [1656633600000, 19942.21, 20918.35, 18975, 19279.8],
    [1656720000000, 19279.8, 19467.39, 18977.01, 19252.81],
    [1656806400000, 19252.82, 19647.63, 18781, 19315.83],
    [1656892800000, 19315.83, 20354.01, 19055.31, 20236.71],
    [1656979200000, 20236.71, 20750, 19304.4, 20175.83],
    [1657065600000, 20175.84, 20675.22, 19761.25, 20564.51],
    [1657152000000, 20564.51, 21838.1, 20251.68, 21624.98],
    [1657238400000, 21624.99, 22527.37, 21189.26, 21594.75],
    [1657324800000, 21594.75, 21980, 21322.12, 21591.83],
    [1657411200000, 21592.15, 21607.65, 20655, 20862.47],
    [1657497600000, 20861.11, 20868.48, 19875.23, 19963.61],
    [1657584000000, 19963.61, 20059.42, 19240, 19328.75],
    [1657670400000, 19331.28, 20366.61, 18910.94, 20234.87],
    [1657756800000, 20234.87, 20900, 19616.07, 20588.84],
    [1657843200000, 20588.84, 21200, 20382.29, 20830.04],
    [1657929600000, 20830.04, 21588.94, 20478.61, 21195.6],
    [1658016000000, 21195.6, 21684.54, 20750.01, 20798.16],
    [1658102400000, 20799.58, 22777.63, 20762.45, 22432.58],
    [1658188800000, 22432.58, 23800, 21579.54, 23396.62],
    [1658275200000, 23398.48, 24276.74, 22906.19, 23223.3],
    [1658361600000, 23223.3, 23442.77, 22341.46, 23152.19],
    [1658448000000, 23152.19, 23756.49, 22500, 22684.83],
    [1658534400000, 22684.83, 23000.77, 21934.57, 22451.07],
    [1658620800000, 22448.58, 23014.64, 22257.15, 22579.68],
    [1658707200000, 22577.13, 22666, 21250, 21310.9],
    [1658793600000, 21310.9, 21347.82, 20706.5, 21254.67],
    [1658880000000, 21254.67, 23112.63, 21042.53, 22952.45],
    [1658966400000, 22954.31, 24199.72, 22582.13, 23842.93],
    [1659052800000, 23845.25, 24442.66, 23414.03, 23773.75],
    [1659139200000, 23777.28, 24668, 23502.25, 23643.51],
    [1659225600000, 23644.64, 24194.82, 23227.31, 23293.32],
    [1659312000000, 23296.36, 23509.68, 22850, 23268.01],
    [1659398400000, 23266.9, 23459.89, 22654.37, 22987.79],
    [1659484800000, 22985.93, 23647.68, 22681.22, 22818.37],
    [1659571200000, 22816.91, 23223.32, 22400, 22622.98],
    [1659657600000, 22622.41, 23472.86, 22586.95, 23312.42],
    [1659744000000, 23313.56, 23354.36, 22909.52, 22954.21],
    [1659830400000, 22954.21, 23402, 22844.62, 23174.39],
    [1659916800000, 23174.39, 24245, 23154.25, 23810],
    [1660003200000, 23810.98, 23933.25, 22865, 23149.95],
    [1660089600000, 23151.32, 24226, 22664.69, 23954.05],
    [1660176000000, 23954.05, 24918.54, 23852.13, 23934.39],
    [1660262400000, 23933.09, 24456.5, 23583, 24403.68],
    [1660348800000, 24401.7, 24888, 24291.22, 24441.38],
    [1660435200000, 24443.06, 25047.56, 24144, 24305.24],
    [1660521600000, 24305.25, 25211.32, 23773.22, 24094.82],
    [1660608000000, 24093.04, 24247.49, 23671.22, 23854.74],
    [1660694400000, 23856.15, 24446.71, 23180.4, 23342.66],
    [1660780800000, 23342.66, 23600, 23111.04, 23191.2],
    [1660867200000, 23191.45, 23208.67, 20783.57, 20834.39],
    [1660953600000, 20834.39, 21382.85, 20761.9, 21140.07],
    [1661040000000, 21140.07, 21800, 21069.11, 21515.61],
    [1661126400000, 21516.7, 21548.71, 20890.18, 21399.83],
    [1661212800000, 21400.75, 21684.87, 20890.14, 21529.12],
    [1661299200000, 21529.11, 21900, 21145, 21368.08],
    [1661385600000, 21368.05, 21819.88, 21310.15, 21559.04],
    [1661472000000, 21559.04, 21886.77, 20107.9, 20241.05],
    [1661558400000, 20239.14, 20402.93, 19800, 20037.6],
    [1661644800000, 20037.6, 20171.18, 19520, 19555.61],
    [1661731200000, 19555.61, 20433.62, 19550.79, 20285.73],
    [1661817600000, 20287.2, 20576.25, 19540, 19811.66],
    [1661904000000, 19813.03, 20490, 19797.94, 20050.02],
    [1661990400000, 20048.44, 20208.37, 19565.66, 20131.46],
    [1662076800000, 20132.64, 20441.26, 19755.29, 19951.86],
    [1662163200000, 19950.98, 20055.93, 19652.72, 19831.9],
    [1662249600000, 19832.45, 20029.23, 19583.1, 20000.3],
    [1662336000000, 20000.3, 20057.27, 19633.83, 19796.84],
    [1662422400000, 19795.34, 20180, 18649.51, 18790.61],
    [1662508800000, 18790.61, 19464.06, 18510.77, 19292.84],
    [1662595200000, 19292.85, 19458.25, 19012, 19319.77],
    [1662681600000, 19320.54, 21597.22, 19291.75, 21360.11],
    [1662768000000, 21361.62, 21810.8, 21111.13, 21648.34],
    [1662854400000, 21647.21, 21860, 21350, 21826.87],
    [1662940800000, 21826.87, 22488, 21538.51, 22395.74],
    [1663027200000, 22395.44, 22799, 19860, 20173.57],
    [1663113600000, 20173.62, 20541.48, 19617.62, 20226.71],
    [1663200000000, 20227.17, 20330.24, 19497, 19701.88],
    [1663286400000, 19701.88, 19890, 19320.01, 19803.3],
    [1663372800000, 19803.3, 20189, 19748.08, 20113.62],
    [1663459200000, 20112.61, 20117.26, 19335.62, 19416.18],
    [1663545600000, 19417.45, 19686.2, 18232.56, 19537.02],
    [1663632000000, 19537.02, 19634.62, 18711.87, 18875],
    [1663718400000, 18874.31, 19956, 18125.98, 18461.36],
    [1663804800000, 18461.36, 19550.17, 18356.39, 19401.63],
    [1663891200000, 19401.63, 19500, 18531.42, 19289.91],
    [1663977600000, 19288.57, 19316.14, 18805.34, 18920.5],
    [1664064000000, 18921.99, 19180.21, 18629.2, 18807.38],
    [1664150400000, 18809.13, 19318.96, 18680.72, 19227.82],
    [1664236800000, 19226.68, 20385.86, 18816.32, 19079.13],
    [1664323200000, 19078.1, 19790, 18471.28, 19412.82],
    [1664409600000, 19412.82, 19645.52, 18843.01, 19591.51],
    [1664496000000, 19590.54, 20185, 19155.36, 19422.61],
    [1664582400000, 19422.61, 19484, 19159.42, 19310.95],
    [1664668800000, 19312.24, 19395.91, 18920.35, 19056.8],
    [1664755200000, 19057.74, 19719.1, 18959.68, 19629.08],
    [1664841600000, 19629.08, 20475, 19490.6, 20337.82],
    [1664928000000, 20337.82, 20365.6, 19730, 20158.26],
    [1665014400000, 20158.26, 20456.6, 19853, 19960.67],
    [1665100800000, 19960.67, 20068.82, 19320, 19530.09],
    [1665187200000, 19530.09, 19627.38, 19237.14, 19417.96],
    [1665273600000, 19416.52, 19558, 19316.04, 19439.02],
    [1665360000000, 19439.96, 19525, 19020.25, 19131.87],
    [1665446400000, 19131.87, 19268.09, 18860, 19060],
    [1665532800000, 19060, 19238.31, 18965.88, 19155.53],
    [1665619200000, 19155.1, 19513.79, 18190, 19375.13],
    [1665705600000, 19375.58, 19951.87, 19070.37, 19176.93],
    [1665792000000, 19176.93, 19227.68, 18975.18, 19069.39],
    [1665878400000, 19068.4, 19425.84, 19063.74, 19262.98],
    [1665964800000, 19262.98, 19676.96, 19152.03, 19549.86],
    [1666051200000, 19548.48, 19706.66, 19091, 19327.44],
    [1666137600000, 19327.44, 19360.16, 19065.97, 19123.97],
    [1666224000000, 19123.35, 19347.82, 18900, 19041.92],
    [1666310400000, 19041.92, 19250, 18650, 19164.37],
    [1666396800000, 19164.37, 19257, 19112.72, 19204.35],
    [1666483200000, 19204.29, 19695, 19070.11, 19570.4],
    [1666569600000, 19570.4, 19601.15, 19157, 19329.72],
    [1666656000000, 19330.6, 20415.87, 19237, 20080.07],
    [1666742400000, 20079.02, 21020, 20050.41, 20771.59],
    [1666828800000, 20771.61, 20872.21, 20200, 20295.11],
    [1666915200000, 20295.11, 20750, 20000.09, 20591.84],
    [1667001600000, 20591.84, 21085, 20554.01, 20809.67],
    [1667088000000, 20809.68, 20931.21, 20515, 20627.48],
    [1667174400000, 20627.48, 20845.92, 20237.95, 20490.74],
    [1667260800000, 20490.74, 20700, 20330.74, 20483.62],
    [1667347200000, 20482.81, 20800, 20048.04, 20151.84],
    [1667433600000, 20151.84, 20393.32, 20031.24, 20207.82],
    [1667520000000, 20207.12, 21302.05, 20180.96, 21148.52],
    [1667606400000, 21148.52, 21480.65, 21080.65, 21299.37],
    [1667692800000, 21299.37, 21365.27, 20886.13, 20905.58],
    [1667779200000, 20905.58, 21069.77, 20384.89, 20591.13],
    [1667865600000, 20590.67, 20700.88, 17166.83, 18547.23],
    [1667952000000, 18545.38, 18587.76, 15588, 15922.81],
    [1668038400000, 15922.68, 18199, 15754.26, 17601.15],
    [1668124800000, 17602.45, 17695, 16361.6, 17070.31],
    [1668211200000, 17069.98, 17119.1, 16631.39, 16812.08],
    [1668297600000, 16813.16, 16954.28, 16229, 16329.85],
    [1668384000000, 16331.78, 17190, 15815.21, 16619.46],
    [1668470400000, 16617.72, 17134.69, 16527.72, 16900.57],
    [1668556800000, 16900.57, 17015.92, 16378.61, 16662.76],
    [1668643200000, 16661.61, 16751, 16410.74, 16692.56],
    [1668729600000, 16692.56, 17011, 16546.04, 16700.45],
    [1668816000000, 16699.43, 16822.41, 16553.53, 16700.68],
    [1668902400000, 16700.68, 16753.33, 16180, 16280.23],
    [1668988800000, 16279.5, 16319, 15476, 15781.29],
    [1669075200000, 15781.29, 16315, 15616.63, 16226.94],
    [1669161600000, 16227.96, 16706, 16160.2, 16603.11],
    [1669248000000, 16603.11, 16812.63, 16458.05, 16598.95],
    [1669334400000, 16599.55, 16666, 16342.81, 16522.14],
    [1669420800000, 16521.35, 16701.99, 16385, 16458.57],
    [1669507200000, 16457.61, 16600, 16401, 16428.78],
    [1669593600000, 16428.77, 16487.04, 15995.27, 16212.91],
    [1669680000000, 16212.18, 16548.71, 16100, 16442.53],
    [1669766400000, 16442.91, 17249, 16428.3, 17163.64],
    [1669852800000, 17165.53, 17324, 16855.01, 16977.37],
    [1669939200000, 16978, 17105.73, 16787.85, 17092.74],
    [1670025600000, 17092.13, 17188.98, 16858.74, 16885.2],
    [1670112000000, 16885.2, 17202.84, 16878.25, 17105.7],
    [1670198400000, 17106.65, 17424.25, 16867, 16966.35],
    [1670284800000, 16966.35, 17107.01, 16906.37, 17088.96],
    [1670371200000, 17088.96, 17142.21, 16678.83, 16836.64],
    [1670457600000, 16836.64, 17299, 16733.49, 17224.1],
    [1670544000000, 17224.1, 17360, 17058.21, 17128.56],
    [1670630400000, 17128.56, 17227.72, 17092, 17127.49],
    [1670716800000, 17127.49, 17270.99, 17071, 17085.05],
    [1670803200000, 17085.05, 17241.89, 16871.85, 17209.83],
    [1670889600000, 17208.93, 18000, 17080.14, 17774.7],
    [1670976000000, 17775.82, 18387.95, 17660.94, 17803.15],
    [1671062400000, 17804.01, 17854.82, 17275.51, 17356.34],
    [1671148800000, 17356.96, 17531.73, 16527.32, 16632.12],
    [1671235200000, 16631.5, 16796.82, 16579.85, 16776.52],
    [1671321600000, 16777.54, 16863.26, 16663.07, 16738.21],
    [1671408000000, 16739, 16815.99, 16256.3, 16438.88],
    [1671494400000, 16438.88, 17061.27, 16397.2, 16895.56],
    [1671580800000, 16896.15, 16925, 16723, 16824.67],
    [1671667200000, 16824.68, 16868.52, 16559.85, 16821.43],
    [1671753600000, 16821.9, 16955.14, 16731.13, 16778.5],
    [1671840000000, 16778.52, 16869.99, 16776.62, 16836.12],
    [1671926400000, 16835.73, 16857.96, 16721, 16832.11],
    [1672012800000, 16832.11, 16944.52, 16791, 16919.39],
    [1672099200000, 16919.39, 16972.83, 16592.37, 16706.36],
    [1672185600000, 16706.06, 16785.19, 16465.33, 16547.31],
    [1672272000000, 16547.32, 16664.41, 16488.91, 16633.47],
    [1672358400000, 16633.47, 16677.35, 16333, 16607.48],
    [1672444800000, 16607.48, 16644.09, 16470, 16542.4],
    [1672531200000, 16541.77, 16628, 16499.01, 16616.75],
    [1672617600000, 16617.17, 16799.23, 16548.7, 16672.87],
    [1672704000000, 16672.78, 16778.4, 16605.28, 16675.18],
    [1672790400000, 16675.65, 16991.87, 16652.66, 16850.36],
    [1672876800000, 16850.36, 16879.82, 16753, 16831.85],
    [1672963200000, 16831.85, 17041, 16679, 16950.65],
    [1673049600000, 16950.31, 16981.91, 16908, 16943.57],
    [1673136000000, 16943.83, 17176.99, 16911, 17127.83],
    [1673222400000, 17127.83, 17398.8, 17104.66, 17178.26],
    [1673308800000, 17179.04, 17499, 17146.34, 17440.66],
    [1673395200000, 17440.64, 18000, 17315.6, 17943.26],
    [1673481600000, 17943.26, 19117.04, 17892.05, 18846.62],
    [1673568000000, 18846.62, 20000, 18714.12, 19930.01],
    [1673654400000, 19930.01, 21258, 19888.05, 20954.92],
    [1673740800000, 20952.76, 21050.74, 20551.01, 20871.5],
    [1673827200000, 20872.99, 21474.05, 20611.48, 21185.65],
    [1673913600000, 21185.65, 21647.45, 20841.31, 21134.81],
    [1674000000000, 21132.29, 21650, 20407.15, 20677.47],
    [1674086400000, 20677.47, 21192, 20659.19, 21071.59],
    [1674172800000, 21071.59, 22755.93, 20861.28, 22667.21],
    [1674259200000, 22666, 23371.8, 22422, 22783.55],
    [1674345600000, 22783.35, 23078.71, 22292.37, 22707.88],
    [1674432000000, 22706.02, 23180, 22500, 22916.45],
    [1674518400000, 22917.81, 23162.2, 22462.93, 22632.89],
    [1674604800000, 22631.94, 23816.73, 22300, 23060.94],
    [1674691200000, 23060.42, 23282.47, 22850.01, 23009.65],
    [1674777600000, 23009.65, 23500, 22534.88, 23074.16],
    [1674864000000, 23074.16, 23189, 22878.46, 23022.6],
    [1674950400000, 23021.4, 23960.54, 22967.76, 23742.3],
    [1675036800000, 23743.37, 23800.51, 22500, 22826.15],
    [1675123200000, 22827.38, 23320, 22714.77, 23125.13],
    [1675209600000, 23125.13, 23812.66, 22760.23, 23732.66],
    [1675296000000, 23731.41, 24255, 23363.27, 23488.94],
    [1675382400000, 23489.33, 23715.7, 23204.62, 23431.9],
    [1675468800000, 23431.9, 23587.78, 23253.96, 23326.84],
    [1675555200000, 23327.66, 23433.33, 22743, 22932.91],
    [1675641600000, 22932.91, 23158.25, 22628.13, 22762.52],
    [1675728000000, 22762.52, 23350.25, 22745.78, 23240.46],
    [1675814400000, 23242.42, 23452, 22665.85, 22963],
    [1675900800000, 22961.85, 23011.39, 21688, 21796.35],
    [1675987200000, 21797.83, 21938.16, 21451, 21625.19],
    [1676073600000, 21625.19, 21906.32, 21599.78, 21862.55],
    [1676160000000, 21862.02, 22090, 21630, 21783.54],
    [1676246400000, 21782.37, 21894.99, 21351.07, 21773.97],
    [1676332800000, 21774.63, 22319.08, 21532.77, 22199.84],
    [1676419200000, 22199.84, 24380, 22047.28, 24324.05],
    [1676505600000, 24322.87, 25250, 23505.25, 23517.72],
    [1676592000000, 23517.72, 25021.11, 23339.37, 24569.97],
    [1676678400000, 24568.24, 24877, 24430, 24631.95],
    [1676764800000, 24632.05, 25192, 24192.57, 24271.76],
    [1676851200000, 24272.51, 25121.23, 23840.83, 24842.2],
    [1676937600000, 24843.89, 25250, 24148.34, 24452.16],
    [1677024000000, 24450.67, 24476.05, 23574.69, 24182.21],
    [1677110400000, 24182.21, 24599.59, 23608, 23940.2],
    [1677196800000, 23940.2, 24132.35, 22841.19, 23185.29],
    [1677283200000, 23184.04, 23219.13, 22722, 23157.07],
    [1677369600000, 23157.07, 23689.99, 23059.18, 23554.85],
    [1677456000000, 23554.85, 23897.99, 23106.77, 23492.09],
    [1677542400000, 23492.09, 23600, 23020.97, 23141.57],
    [1677628800000, 23141.57, 24000, 23020.03, 23628.97],
    [1677715200000, 23629.76, 23796.93, 23195.9, 23465.32],
    [1677801600000, 23465.32, 23476.95, 21971.13, 22354.34],
    [1677888000000, 22354.34, 22410, 22157.08, 22346.57],
    [1677974400000, 22346.57, 22662.09, 22189.22, 22430.24],
    [1678060800000, 22430.24, 22602.19, 22258, 22410],
    [1678147200000, 22409.41, 22557.91, 21927, 22197.96],
    [1678233600000, 22198.56, 22287, 21580, 21705.44],
    [1678320000000, 21704.37, 21834.99, 20042.72, 20362.22],
    [1678406400000, 20362.21, 20367.78, 19549.09, 20150.69],
    [1678492800000, 20150.69, 20686.51, 19765.03, 20455.73],
    [1678579200000, 20455.73, 22150, 20270.6, 21997.11],
    [1678665600000, 21998.05, 24500, 21813.88, 24113.48],
    [1678752000000, 24112.27, 26386.87, 23976.42, 24670.41],
    [1678838400000, 24670.41, 25196.97, 23896.95, 24285.66],
    [1678924800000, 24285.66, 25167.4, 24123, 24998.78],
    [1679011200000, 24998.78, 27756.84, 24890, 27395.13],
    [1679097600000, 27395.13, 27724.85, 26578, 26907.49],
    [1679184000000, 26907.49, 28390.1, 26827.22, 27972.87],
    [1679270400000, 27972.87, 28472, 27124.47, 27717.01],
    [1679356800000, 27717.01, 28438.55, 27303.1, 28105.47],
    [1679443200000, 28107.81, 28868.05, 26601.8, 27250.97],
    [1679529600000, 27250.97, 28750, 27105, 28295.41],
    [1679616000000, 28295.42, 28374.3, 27000, 27454.47],
    [1679702400000, 27454.46, 27787.33, 27156.09, 27462.95],
    [1679788800000, 27462.96, 28194.4, 27417.76, 27968.05],
    [1679875200000, 27968.05, 28023.86, 26508.14, 27124.91],
    [1679961600000, 27124.9, 27520, 26631.78, 27261.07],
    [1680048000000, 27261.06, 28650, 27240.1, 28348.6],
    [1680134400000, 28348.6, 29184.68, 27686, 28028.53],
    [1680220800000, 28028.53, 28656.69, 27511.71, 28465.36],
    [1680307200000, 28465.36, 28819.71, 28220.27, 28452.73],
    [1680393600000, 28452.74, 28530, 27856.43, 28171.87],
    [1680480000000, 28171.87, 28500.99, 27200.24, 27800],
    [1680566400000, 27800, 28444.44, 27662.79, 28165.47],
    [1680652800000, 28165.47, 28775, 27805.1, 28170.01],
    [1680739200000, 28170.01, 28182.05, 27711, 28033.82],
    [1680825600000, 28033.83, 28100, 27766.94, 27906.33],
    [1680912000000, 27906.34, 28154.99, 27859.02, 27938.38],
    [1680998400000, 27938.38, 28530, 27800, 28323.76],
    [1681084800000, 28323.76, 29770, 28170, 29637.34],
    [1681171200000, 29637.35, 30550, 29590, 30200.42],
    [1681257600000, 30200.43, 30486, 29637.4, 29888.07],
    [1681344000000, 29888.07, 30595, 29854.59, 30373.84],
    [1681430400000, 30373.84, 31000, 29966, 30466.93],
    [1681516800000, 30466.93, 30595.6, 30202, 30295.09],
    [1681603200000, 30295.1, 30549.99, 30120, 30304.65],
    [1681689600000, 30304.66, 30316.06, 29240.65, 29430.27],
    [1681776000000, 29430.27, 30485, 29096.78, 30380.01],
    [1681862400000, 30380.01, 30413.53, 28520, 28797.1],
    [1681948800000, 28797.1, 29088.3, 28010, 28243.65],
    [1682035200000, 28243.65, 28374.02, 27125, 27262.84],
    [1682121600000, 27262.84, 27882.72, 27140.35, 27816.85],
    [1682208000000, 27816.85, 27816.85, 27311.25, 27590.6],
    [1682294400000, 27590.59, 28000, 26942.82, 27510.93],
    [1682380800000, 27510.93, 28399.99, 27192, 28300.79],
    [1682467200000, 28300.8, 30036, 27235, 28415.29],
    [1682553600000, 28415.29, 29890, 28378.86, 29472.77],
    [1682640000000, 29472.77, 29599.54, 28891, 29311.7],
    [1682726400000, 29311.69, 29448.88, 29031, 29230.45],
    [1682812800000, 29230.45, 29969.39, 29079.59, 29233.21],
    [1682899200000, 29233.2, 29337.34, 27666.95, 28068.26],
    [1682985600000, 28068.26, 28879.88, 27872, 28669.86],
    [1683072000000, 28669.85, 29266.66, 28113.69, 29026.16],
    [1683158400000, 29026.16, 29379.83, 28663.64, 28838.16],
    [1683244800000, 28838.16, 29677, 28800, 29505.61],
    [1683331200000, 29505.6, 29820, 28300, 28848.2],
    [1683417600000, 28848.19, 29138.29, 28395.23, 28430.1],
    [1683504000000, 28430.09, 28631.01, 27262, 27668.79],
    [1683590400000, 27668.8, 27818, 27353, 27628.27],
    [1683676800000, 27628.28, 28331.42, 26777, 27598.75],
    [1683763200000, 27598.74, 27630.14, 26702.05, 26968.62],
    [1683849600000, 26968.61, 27091.12, 25811.46, 26795.01],
    [1683936000000, 26795.01, 27045.45, 26692.03, 26775.28],
    [1684022400000, 26775.27, 27200, 26560.53, 26917.62],
    [1684108800000, 26917.61, 27663.59, 26726, 27162.14],
    [1684195200000, 27162.15, 27296.89, 26852.11, 27033.84],
    [1684281600000, 27033.85, 27500, 26544.71, 27405.61],
    [1684368000000, 27405.62, 27485.33, 26361.2, 26821.28],
    [1684454400000, 26821.28, 27183.6, 26630, 26880.26],
    [1684540800000, 26880.26, 27150, 26825.11, 27102.43],
    [1684627200000, 27102.42, 27277.55, 26666.03, 26747.78],
    [1684713600000, 26747.78, 27099.89, 26538.21, 26849.27],
    [1684800000000, 26849.28, 27495.83, 26798.11, 27219.61],
    [1684886400000, 27219.61, 27219.61, 26080.5, 26329.01],
    [1684972800000, 26329, 26631.98, 25871.89, 26473.79],
    [1685059200000, 26473.8, 26932.16, 26327.24, 26705.92],
    [1685145600000, 26705.93, 26895, 26551, 26854.27],
    [1685232000000, 26854.28, 28261.32, 26764.36, 28065],
    [1685318400000, 28065.01, 28447.14, 27524.6, 27736.4],
    [1685404800000, 27736.39, 28038.59, 27554, 27694.4],
    [1685491200000, 27694.39, 27835.51, 26839.01, 27210.35],
    [1685577600000, 27210.36, 27350, 26605.05, 26817.93],
    [1685664000000, 26817.93, 27300, 26505, 27242.59],
    [1685750400000, 27242.59, 27333.29, 26914.93, 27069.22],
    [1685836800000, 27069.22, 27455.02, 26951, 27115.21],
    [1685923200000, 27115.2, 27129.33, 25388, 25728.2],
    [1686009600000, 25728.2, 27355.33, 25351.02, 27230.08],
    [1686096000000, 27230.07, 27391.77, 26125.01, 26339.34],
    [1686182400000, 26339.34, 26810, 26210, 26498.61],
    [1686268800000, 26498.62, 26783.33, 26269.91, 26477.81],
    [1686355200000, 26477.8, 26533.87, 25358, 25841.21],
    [1686441600000, 25841.22, 26206.88, 25634.7, 25925.55],
    [1686528000000, 25925.54, 26106.48, 25602.11, 25905.19],
    [1686614400000, 25905.2, 26433.21, 25712.57, 25934.25],
    [1686700800000, 25934.24, 26098, 24820.56, 25128.6],
    [1686787200000, 25128.6, 25759.01, 24800, 25598.49],
    [1686873600000, 25598.49, 26518, 25175.56, 26345],
    [1686960000000, 26345.01, 26839.99, 26181, 26516.99],
    [1687046400000, 26516.99, 26700, 26255.85, 26339.97],
    [1687132800000, 26339.98, 27068.09, 26256.61, 26844.35],
    [1687219200000, 26844.35, 28402.74, 26652, 28307.99],
    [1687305600000, 28308, 30800, 28257.99, 29993.89],
    [1687392000000, 29993.89, 30500, 29525.61, 29884.92],
    [1687478400000, 29884.92, 31431.94, 29800, 30688.5],
    [1687564800000, 30688.51, 30800, 30250, 30527.43],
    [1687651200000, 30527.44, 31046.01, 30277.49, 30462.66],
    [1687737600000, 30462.67, 30666, 29930, 30267.99],
    [1687824000000, 30267.99, 30994.97, 30226.17, 30692.44],
    [1687910400000, 30692.44, 30709.74, 29858.8, 30077.41],
    [1687996800000, 30077.4, 30843.98, 30049.98, 30447.31],
    [1688083200000, 30447.31, 31282, 29500, 30472],
    [1688169600000, 30471.99, 30661.6, 30320.57, 30585.9],
    [1688256000000, 30585.9, 30791, 30155, 30617.03],
    [1688342400000, 30617.02, 31380, 30570.27, 31156.2],
    [1688428800000, 31156.2, 31350.69, 30620, 30766.51],
    [1688515200000, 30766.52, 30878.07, 30200, 30504.81],
    [1688601600000, 30504.8, 31500, 29850.45, 29895.43],
    [1688688000000, 29895.42, 30449, 29701.02, 30344.7],
    [1688774400000, 30344.7, 30386.81, 30044.47, 30284.63],
    [1688860800000, 30284.63, 30445.52, 30061.12, 30160.71],
    [1688947200000, 30160.71, 31045.78, 29950, 30411.57],
    [1689033600000, 30411.57, 30813.63, 30300, 30622.1],
    [1689120000000, 30622.1, 30983.25, 30210, 30380],
    [1689206400000, 30380, 31804.2, 30251, 31454.23],
    [1689292800000, 31454.23, 31630, 29900, 30312.01],
    [1689379200000, 30312, 30390.9, 30200, 30289.52],
    [1689465600000, 30289.52, 30441.46, 30064.29, 30231.99],
    [1689552000000, 30232, 30336.96, 29659.2, 30138],
    [1689638400000, 30138.01, 30239.78, 29512, 29859.13],
    [1689724800000, 29859.14, 30189.09, 29761.96, 29909.21],
    [1689811200000, 29909.21, 30417.46, 29570.96, 29800],
    [1689897600000, 29800, 30061.7, 29726.34, 29901.72],
    [1689984000000, 29901.72, 29999, 29625.1, 29794],
    [1690070400000, 29793.99, 30350, 29730, 30083.75],
    [1690156800000, 30083.75, 30099.58, 28861.9, 29176.5],
    [1690243200000, 29176.5, 29376, 29047.65, 29228.91],
    [1690329600000, 29228.91, 29690, 29096.94, 29351.96],
    [1690416000000, 29351.95, 29567.49, 29083.85, 29222.78],
    [1690502400000, 29222.78, 29542.22, 29123.12, 29314.14],
    [1690588800000, 29314.14, 29406.92, 29256.18, 29352.9],
    [1690675200000, 29352.9, 29449, 29033.24, 29281.09],
    [1690761600000, 29281.09, 29530, 29101.8, 29232.25],
    [1690848000000, 29232.26, 29739.25, 28585.7, 29705.99],
    [1690934400000, 29705.99, 30047.5, 28927.5, 29186.01],
    [1691020800000, 29186, 29433.33, 28968, 29193.64],
    [1691107200000, 29193.65, 29333.08, 28807.54, 29113.99],
    [1691193600000, 29114, 29152.23, 28978.64, 29072.13],
    [1691280000000, 29072.13, 29205.09, 28991.88, 29088.42],
    [1691366400000, 29088.43, 29276.78, 28701.03, 29211.06],
    [1691452800000, 29211.06, 30244, 29146.45, 29770.42],
    [1691539200000, 29770.41, 30160, 29376.67, 29581.99],
    [1691625600000, 29581.99, 29738, 29320.2, 29455.75],
    [1691712000000, 29455.76, 29564.52, 29252.45, 29426.03],
    [1691798400000, 29426.02, 29481.35, 29381.56, 29430.17],
    [1691884800000, 29430.18, 29474.65, 29272.32, 29303.84],
    [1691971200000, 29303.85, 29695.32, 29102.45, 29430.93],
    [1692057600000, 29430.92, 29499.26, 29059.6, 29200],
    [1692144000000, 29200.01, 29259.85, 28723.08, 28730.51],
    [1692230400000, 28730.51, 28783.48, 25166, 26623.41],
    [1692316800000, 26623.41, 26832.6, 25619, 26054],
    [1692403200000, 26054, 26281, 25801.09, 26100.01],
    [1692489600000, 26100.01, 26299, 25971.05, 26189.99],
    [1692576000000, 26190, 26258.42, 25812, 26126.92],
    [1692662400000, 26126.92, 26139.42, 25300, 26056],
    [1692748800000, 26055.99, 26819.27, 25812.82, 26432.72],
    [1692835200000, 26432.71, 26577.87, 25864, 26180.05],
    [1692921600000, 26180.05, 26314.05, 25777.15, 26060.01],
    [1693008000000, 26060, 26125.77, 25985.92, 26017.37],
    [1693094400000, 26017.38, 26182.23, 25966.11, 26101.77],
    [1693180800000, 26101.78, 26253.99, 25864.5, 26120],
    [1693267200000, 26120, 28142.85, 25922, 27716.34],
    [1693353600000, 27716.34, 27768.57, 27017.24, 27299.99],
    [1693440000000, 27299.99, 27587.51, 25655.01, 25940.78],
    [1693526400000, 25940.77, 26156, 25333.75, 25805.05],
    [1693612800000, 25805.04, 25987.5, 25752.47, 25869.51],
    [1693699200000, 25869.52, 26135, 25800, 25971.21],
    [1693785600000, 25971.21, 26108.02, 25631.21, 25826.02],
    [1693872000000, 25826.03, 25915.49, 25562.62, 25792.1],
    [1693958400000, 25792.11, 26040, 25372.51, 25759.95],
    [1694044800000, 25759.95, 26443.14, 25615.38, 26255],
    [1694131200000, 26255, 26445.5, 25647.26, 25910.5],
    [1694217600000, 25910.5, 25945.09, 25796.64, 25901.61],
    [1694304000000, 25901.6, 26033.66, 25570.57, 25841.61],
    [1694390400000, 25841.6, 25900.69, 24901, 25162.52],
    [1694476800000, 25162.53, 26567, 25131.48, 25840.1],
    [1694563200000, 25840.1, 26405.22, 25764.17, 26222],
    [1694649600000, 26222, 26860.49, 26126.77, 26522.73],
    [1694736000000, 26522.73, 26888, 26224, 26600],
    [1694822400000, 26599.99, 26777, 26445, 26559.67],
    [1694908800000, 26559.67, 26623.25, 26399, 26527.51],
    [1694995200000, 26527.5, 27409, 26377.35, 26762.51],
    [1695081600000, 26762.5, 27483.57, 26667.79, 27210.26],
    [1695168000000, 27210.25, 27388.63, 26800, 27125],
    [1695254400000, 27125.01, 27159.6, 26377.7, 26568.08],
    [1695340800000, 26568.08, 26743.38, 26468.77, 26580.14],
    [1695427200000, 26580.14, 26632.81, 26509, 26575.96],
    [1695513600000, 26575.97, 26738.54, 26122.08, 26248.38],
    [1695600000000, 26248.39, 26446.15, 25990.46, 26304.81],
    [1695686400000, 26304.8, 26397.46, 26088.34, 26221.67],
    [1695772800000, 26221.68, 26850, 26112.06, 26372.99],
    [1695859200000, 26373, 27308.48, 26342.4, 27021.39],
    [1695945600000, 27021.39, 27244.89, 26665.16, 26906.96],
    [1696032000000, 26906.96, 27094.99, 26886.31, 26962.56],
    [1696118400000, 26962.57, 28065.51, 26954.09, 27992.57],
    [1696204800000, 27992.58, 28580, 27281.44, 27494.51],
    [1696291200000, 27494.51, 27676.52, 27160.5, 27426.46],
    [1696377600000, 27426.45, 27839.72, 27202, 27778.57],
    [1696464000000, 27778.57, 28120.39, 27352, 27410.39],
    [1696550400000, 27410.39, 28295, 27175.94, 27931.09],
    [1696636800000, 27931.1, 28029.67, 27842.08, 27956.67],
    [1696723200000, 27956.67, 28095.14, 27687.5, 27917.05],
    [1696809600000, 27917.06, 27987.93, 27260, 27590.12],
    [1696896000000, 27590.12, 27735, 27298, 27390.12],
    [1696982400000, 27390.12, 27477.39, 26538.66, 26875.52],
    [1697068800000, 26875.52, 26947.04, 26555, 26759.63],
    [1697155200000, 26759.63, 27130, 26685, 26862],
    [1697241600000, 26862, 26989.58, 26789, 26852.48],
    [1697328000000, 26852.48, 27293.33, 26808.25, 27154.15],
    [1697414400000, 27154.14, 30000, 27112.66, 28500.78],
    [1697500800000, 28500.77, 28613.65, 28069.32, 28395.91],
    [1697587200000, 28395.91, 28982.36, 28142.87, 28320],
    [1697673600000, 28320, 28916.89, 28100.66, 28713.71],
    [1697760000000, 28713.71, 30207.55, 28578.29, 29669.04],
    [1697846400000, 29669.05, 30379.99, 29464.77, 29909.8],
    [1697932800000, 29909.8, 30248, 29640, 29992.46],
    [1698019200000, 29992.46, 34741.91, 29883.6, 33069.99],
    [1698105600000, 33069.99, 35280, 32832.34, 33922.73],
    [1698192000000, 33922.73, 35132.85, 33679.05, 34496.05],
    [1698278400000, 34496.05, 34824.13, 33751, 34151.66],
    [1698364800000, 34151.66, 34245, 33390.95, 33892.02],
    [1698451200000, 33892.01, 34493.33, 33860, 34081],
    [1698537600000, 34081.01, 34750.11, 33930, 34525.89],
    [1698624000000, 34525.88, 34856, 34062.84, 34474.73],
    [1698710400000, 34474.74, 34720.49, 34025, 34639.77],
    [1698796800000, 34639.78, 35582, 34097.39, 35421.43],
    [1698883200000, 35421.43, 35984.99, 34300, 34941.59],
    [1698969600000, 34941.58, 34946.5, 34120, 34716.78],
    [1699056000000, 34716.78, 35255, 34585.18, 35062.07],
    [1699142400000, 35062.06, 35380, 34448, 35011.88],
    [1699228800000, 35011.89, 35276.33, 34725.9, 35046.09],
    [1699315200000, 35046.09, 35888, 34523.06, 35399.12],
    [1699401600000, 35399.13, 36106, 35100, 35624.72],
    [1699488000000, 35624.72, 37972.24, 35534.05, 36701.09],
    [1699574400000, 36701.1, 37526, 36324.71, 37301.63],
    [1699660800000, 37301.63, 37408.26, 36666.93, 37130],
    [1699747200000, 37129.99, 37222.22, 36731.1, 37064.13],
    [1699833600000, 37064.13, 37417.99, 36333, 36462.93],
    [1699920000000, 36462.93, 36744, 34800, 35551.19],
    [1700006400000, 35551.2, 37980, 35360, 37858.2],
    [1700092800000, 37858.2, 37929.54, 35500, 36163.51],
    [1700179200000, 36163.51, 36800, 35861.1, 36613.92],
    [1700265600000, 36613.91, 36845.49, 36178.58, 36568.1],
    [1700352000000, 36568.11, 37500, 36384.02, 37359.86],
    [1700438400000, 37359.85, 37750, 36677, 37448.78],
    [1700524800000, 37448.79, 37649.44, 35735, 35741.65],
    [1700611200000, 35741.65, 37861.1, 35632.01, 37408.34],
    [1700697600000, 37408.35, 37653.44, 36870, 37294.28],
    [1700784000000, 37294.27, 38414, 37251.51, 37713.57],
    [1700870400000, 37713.57, 37888, 37591.1, 37780.67],
    [1700956800000, 37780.67, 37814.63, 37150, 37447.43],
    [1701043200000, 37447.42, 37569.23, 36707, 37242.7],
    [1701129600000, 37242.7, 38377, 36868.41, 37818.87],
    [1701216000000, 37818.88, 38450, 37570, 37854.64],
    [1701302400000, 37854.65, 38145.85, 37500, 37723.96],
    [1701388800000, 37723.97, 38999, 37615.86, 38682.52],
    [1701475200000, 38682.51, 39717.14, 38641.61, 39450.35],
    [1701561600000, 39450.35, 40250, 39274.86, 39972.26],
    [1701648000000, 39972.26, 42420, 39972.26, 41991.1],
    [1701734400000, 41991.1, 44488, 41414, 44073.32],
    [1701820800000, 44073.82, 44297.21, 43335.28, 43762.69],
    [1701907200000, 43762.69, 44047.33, 42821.1, 43273.14],
    [1701993600000, 43273.15, 44700, 43081.1, 44170.99],
    [1702080000000, 44171, 44358.02, 43584.51, 43713.6],
    [1702166400000, 43713.59, 44049, 43563, 43789.51],
    [1702252800000, 43789.5, 43804.5, 40222, 41253.4],
    [1702339200000, 41253.41, 42104.12, 40680, 41492.39],
    [1702425600000, 41492.38, 43475.2, 40555, 42869.03],
    [1702512000000, 42869.03, 43420, 41400, 43022.26],
    [1702598400000, 43022.26, 43080.81, 41666, 41940.3],
    [1702684800000, 41940.29, 42724.43, 41605, 42278.03],
    [1702771200000, 42278.02, 42424.07, 41252, 41374.65],
    [1702857600000, 41374.64, 42757.81, 40542.93, 42657.8],
    [1702944000000, 42657.8, 43497, 41811.1, 42275.99],
    [1703030400000, 42275.99, 44283, 42206, 43668.93],
    [1703116800000, 43668.92, 44242.35, 43286.72, 43861.8],
    [1703203200000, 43861.79, 44398.26, 43412.54, 43969.04],
    [1703289600000, 43969.04, 43988.68, 43291.1, 43702.16],
    [1703376000000, 43702.15, 43946, 42500, 42991.5],
    [1703462400000, 42991.5, 43802.32, 42720.43, 43576.13],
    [1703548800000, 43576.12, 43592.68, 41637.6, 42508.93],
    [1703635200000, 42508.93, 43677, 42098.69, 43428.85],
    [1703721600000, 43428.86, 43787.57, 42241.79, 42563.76],
    [1703808000000, 42563.76, 43111, 41300, 42066.95],
    [1703894400000, 42066.94, 42612.32, 41520.3, 42140.28],
    [1703980800000, 42140.29, 42899, 41965.84, 42283.58],
    [1704067200000, 42283.58, 44184.1, 42180.77, 44179.55],
    [1704153600000, 44179.55, 45879.63, 44148.34, 44946.91],
    [1704240000000, 44946.91, 45500, 40750, 42845.23],
    [1704326400000, 42845.23, 44729.58, 42613.77, 44151.1],
    [1704412800000, 44151.1, 44357.46, 42450, 44145.11],
    [1704499200000, 44145.12, 44214.42, 43397.05, 43968.32],
    [1704585600000, 43968.32, 44480.59, 43572.09, 43929.02],
    [1704672000000, 43929.01, 47248.99, 43175, 46951.04],
    [1704758400000, 46951.04, 47972, 44748.67, 46110],
    [1704844800000, 46110, 47695.93, 44300.36, 46653.99],
    [1704931200000, 46654, 48969.48, 45606.06, 46339.16],
    [1705017600000, 46339.16, 46515.53, 41500, 42782.73],
    [1705104000000, 42782.74, 43257, 42436.12, 42847.99],
    [1705190400000, 42847.99, 43079, 41720, 41732.35],
    [1705276800000, 41732.35, 43400.43, 41718.05, 42511.1],
    [1705363200000, 42511.1, 43578.01, 42050, 43137.95],
    [1705449600000, 43137.94, 43198, 42200.69, 42776.1],
    [1705536000000, 42776.09, 42930, 40683.28, 41327.5],
    [1705622400000, 41327.51, 42196.86, 40280, 41659.03],
    [1705708800000, 41659.03, 41872.56, 41456.3, 41696.04],
    [1705795200000, 41696.05, 41881.39, 41500.98, 41580.33],
    [1705881600000, 41580.32, 41689.65, 39431.58, 39568.02],
    [1705968000000, 39568.02, 40176.74, 38555, 39897.6],
    [1706054400000, 39897.59, 40555, 39484.19, 40084.88],
    [1706140800000, 40084.89, 40300.24, 39550, 39961.09],
    [1706227200000, 39961.09, 42246.82, 39822.52, 41823.51],
    [1706313600000, 41823.51, 42200, 41394.34, 42120.63],
    [1706400000000, 42120.63, 42842.68, 41620.81, 42031.06],
    [1706486400000, 42031.05, 43333, 41804.88, 43302.7],
    [1706572800000, 43302.71, 43882.36, 42683.99, 42941.1],
    [1706659200000, 42941.1, 43745.11, 42276.84, 42580],
    [1706745600000, 42580, 43285.13, 41884.28, 43082.94],
    [1706832000000, 43082.95, 43488, 42546.79, 43200],
    [1706918400000, 43199.99, 43380.01, 42880, 43011.09],
    [1707004800000, 43011.1, 43119.04, 42222, 42582.88],
    [1707091200000, 42582.88, 43569.76, 42258.1, 42708.7],
    [1707177600000, 42708.7, 43399.98, 42574, 43098.95],
    [1707264000000, 43098.96, 44396.5, 42788, 44349.6],
    [1707350400000, 44349.6, 45614.3, 44331.1, 45288.65],
    [1707436800000, 45288.66, 48200, 45242.12, 47132.77],
    [1707523200000, 47132.78, 48170, 46800, 47751.09],
    [1707609600000, 47751.08, 48592.66, 47557.16, 48299.99],
    [1707696000000, 48300, 50334.82, 47710.01, 49917.27],
    [1707782400000, 49917.28, 50368.61, 48300.95, 49699.59],
    [1707868800000, 49699.6, 52043.71, 49225.01, 51795.17],
    [1707955200000, 51795.17, 52816.62, 51314, 51880],
    [1708041600000, 51880.01, 52572.08, 51566, 52124.11],
    [1708128000000, 52124.1, 52162.82, 50625, 51642.64],
    [1708214400000, 51642.64, 52377, 51163.28, 52137.67],
    [1708300800000, 52137.68, 52488.77, 51677, 51774.73],
    [1708387200000, 51774.74, 52985, 50760.37, 52258.82],
    [1708473600000, 52258.82, 52366.8, 50625, 51849.39],
    [1708560000000, 51849.38, 52065.78, 50940.78, 51288.42],
    [1708646400000, 51288.42, 51548.54, 50521, 50744.15],
    [1708732800000, 50744.15, 51698, 50585, 51568.22],
    [1708819200000, 51568.21, 51958.55, 51279.8, 51728.85],
    [1708905600000, 51728.85, 54910, 50901.44, 54476.47],
    [1708992000000, 54476.48, 57588.15, 54450.13, 57037.34],
    [1709078400000, 57037.35, 64000, 56691.85, 62432.1],
    [1709164800000, 62432.11, 63676.35, 60364.7, 61130.98],
    [1709251200000, 61130.99, 63114.23, 60777, 62387.9],
    [1709337600000, 62387.9, 62433.19, 61561.12, 61987.28],
    [1709424000000, 61987.28, 63231.88, 61320, 63113.97],
    [1709510400000, 63113.97, 68499, 62300, 68245.71],
    [1709596800000, 68245.71, 69000, 59005, 63724.01],
    [1709683200000, 63724.01, 67641.1, 62779.14, 66074.04],
    [1709769600000, 66074.04, 67980, 65551, 66823.17],
    [1709856000000, 66823.18, 69990, 66082.66, 68124.19],
    [1709942400000, 68124.2, 68541.1, 67861.1, 68313.27],
    [1710028800000, 68313.28, 69887.61, 68094.75, 68955.88],
    [1710115200000, 68955.88, 72800, 67024.96, 72078.1],
    [1710201600000, 72078.1, 73000, 68620.82, 71452.01],
    [1710288000000, 71452, 73650.25, 71333.31, 73072.41],
    [1710374400000, 73072.4, 73777, 68555, 71388.94],
    [1710460800000, 71388.94, 72419.71, 65600, 69499.85],
    [1710547200000, 69499.84, 70043, 64780, 65300.63],
    [1710633600000, 65300.64, 68904.4, 64533, 68393.48],
    [1710720000000, 68393.47, 68956, 66565.2, 67609.99],
    [1710806400000, 67610, 68124.11, 61555, 61937.4],
    [1710892800000, 61937.41, 68100, 60775, 67840.51],
    [1710979200000, 67840.51, 68240.47, 64529.01, 65501.27],
    [1711065600000, 65501.28, 66649.62, 62260, 63796.64],
    [1711152000000, 63796.64, 65999, 63000, 63990.01],
    [1711238400000, 63990.02, 67628.69, 63772.29, 67209.99],
    [1711324800000, 67210, 71150, 66385.06, 69880.01],
    [1711411200000, 69880, 71561.1, 69280, 69988],
    [1711497600000, 69987.99, 71769.54, 68359.18, 69469.99],
    [1711584000000, 69469.99, 71552.06, 68903.62, 70780.6],
    [1711670400000, 70780.6, 70916.16, 69009, 69850.54],
    [1711756800000, 69850.53, 70321.1, 69540, 69582.18],
    [1711843200000, 69582.17, 71366, 69562.99, 71280.01],
    [1711929600000, 71280, 71288.23, 68062.86, 69649.8],
    [1712016000000, 69649.81, 69674.23, 64550, 65463.99],
    [1712102400000, 65463.99, 66903.63, 64493.07, 65963.28],
    [1712188800000, 65963.27, 69309.91, 65064.52, 68487.79],
    [1712275200000, 68487.8, 68756.67, 65952.56, 67820.62],
    [1712361600000, 67820.63, 69692, 67447.83, 68896],
    [1712448000000, 68896, 70326.29, 68824, 69360.39],
    [1712534400000, 69360.38, 72797.99, 69043.24, 71620],
    [1712620800000, 71620, 71758.19, 68210, 69146],
    [1712707200000, 69146, 71172.08, 67518, 70631.08],
    [1712793600000, 70631.08, 71305.89, 69567.21, 70006.23],
    [1712880000000, 70006.22, 71227.46, 65086.86, 67116.52],
    [1712966400000, 67116.52, 67929, 60660.57, 63924.51],
    [1713052800000, 63924.52, 65840, 62134, 65661.84],
    [1713139200000, 65661.85, 66867.07, 62274.4, 63419.99],
    [1713225600000, 63419.99, 64365, 61600, 63793.39],
    [1713312000000, 63793.4, 64499, 59678.16, 61277.37],
    [1713398400000, 61277.38, 64117.09, 60803.35, 63470.08],
    [1713484800000, 63470.09, 65450, 59600.01, 63818.01],
    [1713571200000, 63818.01, 65419, 63090.07, 64940.59],
    [1713657600000, 64940.58, 65695.56, 64237.5, 64941.15],
    [1713744000000, 64941.15, 67232.35, 64500, 66819.32],
    [1713830400000, 66819.32, 67183.01, 65765.81, 66414],
    [1713916800000, 66414, 67070.43, 63606.06, 64289.59],
    [1714003200000, 64289.58, 65297.94, 62794, 64498.34],
    [1714089600000, 64498.33, 64820.01, 63297.48, 63770.01],
    [1714176000000, 63770, 63923.41, 62391.24, 63461.98],
    [1714262400000, 63461.98, 64370, 62781, 63118.62],
    [1714348800000, 63118.62, 64228.35, 61765.53, 63866],
    [1714435200000, 63866, 64734, 59191.6, 60672],
    [1714521600000, 60672.01, 60841.63, 56552.82, 58364.97],
    [1714608000000, 58364.97, 59625, 56911.84, 59060.61],
    [1714694400000, 59060.6, 63333, 58811.32, 62882.01],
    [1714780800000, 62882.01, 64540, 62541.03, 63892.04],
    [1714867200000, 63892.03, 64646, 62822.17, 64012],
    [1714953600000, 64012, 65500, 62700, 63165.19],
    [1715040000000, 63165.18, 64422.41, 62261, 62312.08],
    [1715126400000, 62312.07, 63020.22, 60888, 61193.03],
    [1715212800000, 61193.03, 63429.03, 60630, 63074.01],
    [1715299200000, 63074, 63469.13, 60187.12, 60799.99],
    [1715385600000, 60799.99, 61096.27, 60487.09, 60745.92],
  ],
  strategyEvents: [
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.99,
              },
            ],
            fee_quote: 9.99,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1648166400,
        txn_comm: 9.99,
        size: 0.22708953,
        gross_profit: 0,
        txn_value: 9990,
        ticktime: 1648166400,
        exec_type: 'Market',
        type: 'buy',
        at_price: 43991.46,
      },
      timestamp: 1648166400,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.80356177,
              },
            ],
            fee_quote: 9.80356177,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1649289600,
        txn_comm: 9.80356177,
        size: -0.22708953,
        gross_profit: -186.43823369,
        txn_value: -9803.56177,
        ticktime: 1649289600,
        exec_type: 'Market',
        type: 'sell',
        at_price: 43170.47,
      },
      timestamp: 1649289600,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.78397444,
              },
            ],
            fee_quote: 9.78397444,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1667606400,
        txn_comm: 9.78397444,
        size: 0.46263164,
        gross_profit: 0,
        txn_value: 9783.97444,
        ticktime: 1667606400,
        exec_type: 'Market',
        type: 'buy',
        at_price: 21148.52,
      },
      timestamp: 1667606400,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.67158271,
              },
            ],
            fee_quote: 9.67158271,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1667779200,
        txn_comm: 9.67158271,
        size: -0.46263164,
        gross_profit: -112.39172999,
        txn_value: -9671.58271,
        ticktime: 1667779200,
        exec_type: 'Market',
        type: 'sell',
        at_price: 20905.58,
      },
      timestamp: 1667779200,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.652259,
              },
            ],
            fee_quote: 9.652259,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1673568000,
        txn_comm: 9.652259,
        size: 0.51214801,
        gross_profit: 0,
        txn_value: 9652.259,
        ticktime: 1673568000,
        exec_type: 'Market',
        type: 'buy',
        at_price: 18846.62,
      },
      timestamp: 1673568000,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 10.4284654,
              },
            ],
            fee_quote: 10.4284654,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1678406400,
        txn_comm: 10.4284654,
        size: -0.51214801,
        gross_profit: 776.20640797,
        txn_value: -10428.4654,
        ticktime: 1678406400,
        exec_type: 'Market',
        type: 'sell',
        at_price: 20362.21,
      },
      timestamp: 1678406400,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 10.40710631,
              },
            ],
            fee_quote: 10.40710631,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1678752000,
        txn_comm: 10.40710631,
        size: 0.43161039,
        gross_profit: 0,
        txn_value: 10407.10631,
        ticktime: 1678752000,
        exec_type: 'Market',
        type: 'buy',
        at_price: 24112.27,
      },
      timestamp: 1678752000,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 11.36387001,
              },
            ],
            fee_quote: 11.36387001,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1684972800,
        txn_comm: 11.36387001,
        size: -0.43161039,
        gross_profit: 956.76370417,
        txn_value: -11363.87001,
        ticktime: 1684972800,
        exec_type: 'Market',
        type: 'sell',
        at_price: 26329,
      },
      timestamp: 1684972800,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 11.34169033,
              },
            ],
            fee_quote: 11.34169033,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1685318400,
        txn_comm: 11.34169033,
        size: 0.40412208,
        gross_profit: 0,
        txn_value: 11341.69033,
        ticktime: 1685318400,
        exec_type: 'Market',
        type: 'buy',
        at_price: 28065.01,
      },
      timestamp: 1685318400,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 10.3973338,
              },
            ],
            fee_quote: 10.3973338,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1686009600,
        txn_comm: 10.3973338,
        size: -0.40412208,
        gross_profit: -944.35652728,
        txn_value: -10397.3338,
        ticktime: 1686009600,
        exec_type: 'Market',
        type: 'sell',
        at_price: 25728.2,
      },
      timestamp: 1686009600,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 10.37655302,
              },
            ],
            fee_quote: 10.37655302,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1686096000,
        txn_comm: 10.37655302,
        size: 0.38106964,
        gross_profit: 0,
        txn_value: 10376.55302,
        ticktime: 1686096000,
        exec_type: 'Market',
        type: 'buy',
        at_price: 27230.07,
      },
      timestamp: 1686096000,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 10.03712286,
              },
            ],
            fee_quote: 10.03712286,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1686182400,
        txn_comm: 10.03712286,
        size: -0.38106964,
        gross_profit: -339.4301621,
        txn_value: -10037.12286,
        ticktime: 1686182400,
        exec_type: 'Market',
        type: 'sell',
        at_price: 26339.34,
      },
      timestamp: 1686182400,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 10.01707284,
              },
            ],
            fee_quote: 10.01707284,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1687046400,
        txn_comm: 10.01707284,
        size: 0.37776055,
        gross_profit: 0,
        txn_value: 10017.07284,
        ticktime: 1687046400,
        exec_type: 'Market',
        type: 'buy',
        at_price: 26516.99,
      },
      timestamp: 1687046400,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.95020544,
              },
            ],
            fee_quote: 9.95020544,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1687132800,
        txn_comm: 9.95020544,
        size: -0.37776055,
        gross_profit: -66.86739571,
        txn_value: -9950.20544,
        ticktime: 1687132800,
        exec_type: 'Market',
        type: 'sell',
        at_price: 26339.98,
      },
      timestamp: 1687132800,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.930325,
              },
            ],
            fee_quote: 9.930325,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1687219200,
        txn_comm: 9.930325,
        size: 0.36992235,
        gross_profit: 0,
        txn_value: 9930.325,
        ticktime: 1687219200,
        exec_type: 'Market',
        type: 'buy',
        at_price: 26844.35,
      },
      timestamp: 1687219200,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.84859436,
              },
            ],
            fee_quote: 9.84859436,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1692316800,
        txn_comm: 9.84859436,
        size: -0.36992235,
        gross_profit: -81.73064371,
        txn_value: -9848.59436,
        ticktime: 1692316800,
        exec_type: 'Market',
        type: 'sell',
        at_price: 26623.41,
      },
      timestamp: 1692316800,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.82892046,
              },
            ],
            fee_quote: 9.82892046,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1696204800,
        txn_comm: 9.82892046,
        size: 0.35112592,
        gross_profit: 0,
        txn_value: 9828.92046,
        ticktime: 1696204800,
        exec_type: 'Market',
        type: 'buy',
        at_price: 27992.58,
      },
      timestamp: 1696204800,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.43669174,
              },
            ],
            fee_quote: 9.43669174,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1697068800,
        txn_comm: 9.43669174,
        size: -0.35112592,
        gross_profit: -392.22872228,
        txn_value: -9436.69174,
        ticktime: 1697068800,
        exec_type: 'Market',
        type: 'sell',
        at_price: 26875.52,
      },
      timestamp: 1697068800,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 9.4178308,
              },
            ],
            fee_quote: 9.4178308,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1697500800,
        txn_comm: 9.4178308,
        size: 0.33044128,
        gross_profit: 0,
        txn_value: 9417.8308,
        ticktime: 1697500800,
        exec_type: 'Market',
        type: 'buy',
        at_price: 28500.77,
      },
      timestamp: 1697500800,
    },
    {
      eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
      eventType: 'order',
      data: {
        txn_dedeuctions: {
          fees: {
            fee_details: [
              {
                currency: 'USDT',
                cost: 19.28619515,
              },
            ],
            fee_quote: 19.28619515,
            quote_currency: 'USDT',
          },
        },
        symbol: 'BTC/USDT',
        datetime: 1714608000,
        txn_comm: 19.28619515,
        size: -0.33044128,
        gross_profit: 9868.36435087,
        txn_value: -19286.19515,
        ticktime: 1714608000,
        exec_type: 'Market',
        type: 'sell',
        at_price: 58364.97,
      },
      timestamp: 1714608000,
    },
  ],
};
