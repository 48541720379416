import styles from './applogo.module.css';
import { AppLogoProps } from './types';

const AppLogo = (props: AppLogoProps) => {
  const { small } = props;
  return (
    <span className={small ? styles.smallLogo : styles.logo}>
      {small ? 'up' : 'uptrend.'}
    </span>
  );
};

export default AppLogo;
