import { AiOutlineLineChart } from 'react-icons/ai';
import styles from './interval.module.css';
import { ChartIntervalSelectProps } from './types';

const ChartIntervalSelect = (props: ChartIntervalSelectProps) => {
  const { selectedInterval, onChange, toggleFullChart } = props;
  const MINICHART_GRANULARITIES = [
    { value: 'ALL', label: 'All' },
    { value: '5Y', label: '5Y' },
    { value: '1Y', label: '1Y' },
    { value: '3M', label: '3M' },
    { value: '1M', label: '1M' },
    { value: '1D', label: '1D' },
  ];
  return (
    <div className={styles.container}>
      {MINICHART_GRANULARITIES.map((intervalInfo, i) => (
        <span
          key={i}
          className={`${styles.intervalItem} ${
            selectedInterval === intervalInfo.value
              ? styles.selectedInterval
              : ''
          }`}
          onClick={() => onChange(intervalInfo.value)}
        >
          {intervalInfo.label}
        </span>
      ))}
      {!!toggleFullChart && (
        <span
          key={'chart-button'}
          className={styles.intervalItem}
          onClick={() => toggleFullChart(true)}
        >
          <AiOutlineLineChart />
        </span>
      )}
    </div>
  );
};

export default ChartIntervalSelect;
