import { ChartLineData } from 'types/chart';

export type TrendingRecord = {
  symbol: string;
  ranks: number[];
};

export type SelectedTrendingRecord = {
  label: string;
  symbol: string;
  ranks: number[];
};

export type NewsSummary = {
  trending: Record<string, TrendingRecord>;
  outlookSummary: string;
};

export type NewsSentimentSummary = {
  averageSentiment: number;
  positive: number;
  negative: number;
  neutral: number;
  totalItems: number;
  timestamp: number;
  summary?: NewsSummary | null;
};

export type NewsFeedItem = {
  datetime: number;
  image: string;
  bannerImage?: string;
  link: string;
  title: string;
  rank: number;
  sentiment: number;
};

export type NewsFeed = {
  isLoading: boolean;
  data: NewsFeedItem[];
  error: string | null;
  fetchMore: boolean;
  sentimentChartData: NewsSentimentSummary[];
  summary: NewsSentimentSummary;
  selectedTrending?: SelectedTrendingRecord | null;
  trendingSearchData: NewsFeedItem[];
};

export enum NEWS_TIMEFRAME {
  'DAILY' = '24h',
  'WEEKLY' = '7d',
}

export type NewsHeaderMenuItem = {
  id: string;
  name: string;
  headerName: string;
  link: string;
};

export enum NewsHeaderMenuTopic {
  national = 'national',
  world = 'world',
  crypto = 'crypto',
}
