import { getAccountBalances, getAllSymbols } from 'apis/exchange';
import { ExchangeState } from './state';

export const useExchangeContainer = () => {
  const State = ExchangeState.get();

  const fetchExchangeSymbols = (exchange: string) => {
    getAllSymbols(exchange).then((s) => {
      State.setExchangeSymbols(s);
    });
  };

  const fetchAccountBalances = (currency: string) => {
    getAccountBalances(currency).then((b) => {
      State.setAccountBalances(b);
    });
  };

  return {
    State,
    fetchExchangeSymbols,
    fetchAccountBalances,
  };
};
