import PushButton from 'components/PushButton';
import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import sharedStyles from '../shared.module.css';
import inputStyles from 'components/TextInput/styleVariants/loginInputs.module.css';
import { SignInProps } from './types';
import { OnboardingSteps } from 'views/Login/types';
import { useDisplayType } from 'utils/display';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';
import { page_visit_login } from 'constants/app/analyticsEvents';
import { PushButtonType } from 'components/PushButton/types';
import { FaFacebook, FaGoogle } from 'react-icons/fa';

export const SignIn = (props: SignInProps) => {
  const {
    updateStep,
    loginFormValues,
    updateLoginForm,
    onSubmit,
    onLoginWithFacebook,
    onLoginWithGoogle,
  } = props;
  const { isDesktop } = useDisplayType();

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_login);
  }, []);

  return (
    <div
      className={
        isDesktop ? sharedStyles.container : sharedStyles.mobileContainer
      }
    >
      <div
        className={
          isDesktop ? sharedStyles.formContainer : sharedStyles.mFormContainer
        }
      >
        <div className={sharedStyles.header}>
          <span className={sharedStyles.headerText}>Sign In</span>
          <span className={sharedStyles.welcomeText}>Welcome Back!</span>
          <span className={sharedStyles.welcomeText}>
            Sign in to manage your Portfolio & Strategies.
          </span>
        </div>
        <div className={sharedStyles.socialContainer}>
          <PushButton
            type={PushButtonType.Primary}
            additionalClassName={sharedStyles.socialButton}
            icon={<FaFacebook />}
            title={'Login with Facebook'}
            onClick={() => {
              onLoginWithFacebook();
            }}
          />
          <PushButton
            type={PushButtonType.Primary}
            additionalClassName={sharedStyles.socialButton}
            icon={<FaGoogle />}
            title={'Login with Google'}
            onClick={() => {
              onLoginWithGoogle();
            }}
          />
        </div>
        <div className={sharedStyles.subSectionNote}>
          <span className={sharedStyles.welcomeText}>
            Sign-in with your e-mail & password:
          </span>
        </div>
        <div className={sharedStyles.inputsContainer}>
          <TextInput
            type={InputTypes.TEXT}
            label={'E-mail'}
            value={loginFormValues.email}
            placeholder={'Enter your E-mail'}
            onChange={(s) =>
              updateLoginForm({
                type: 'email',
                value: s,
              })
            }
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
          <TextInput
            type={InputTypes.PASSWORD}
            label={'Password'}
            value={loginFormValues.password}
            placeholder={'Enter your password'}
            onChange={(s) =>
              updateLoginForm({
                type: 'password',
                value: s,
              })
            }
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
        </div>
        <div className={sharedStyles.mainActionContainer}>
          <div className={sharedStyles.actionContainer}>
            <div className={sharedStyles.actionText}>
              Forgot password?{' '}
              <span
                className={sharedStyles.actionLink}
                onClick={() => updateStep(OnboardingSteps.Recover)}
              >
                Click here.
              </span>
            </div>
          </div>
          <PushButton
            disabled={loginFormValues.submittingForm}
            additionalClassName={sharedStyles.submitButton}
            title={
              !loginFormValues.submittingForm ? 'Sign In' : 'Signing In...'
            }
            onClick={onSubmit}
          />
        </div>
        <div className={sharedStyles.actionContainer}>
          <div className={sharedStyles.actionText}>
            New User?{' '}
            <span
              className={sharedStyles.actionLink}
              onClick={() => updateStep(OnboardingSteps.SignUp)}
            >
              Sign up here.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
