import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { ApplicationStateProps } from './types';
import { DISPLAY_ONBOADING } from 'constants/app/localstore';

const defaultInitialState: ApplicationStateProps = {
  appInitiated: false,
  loggedIn: false,
  showPushReqPopup: false,
  showPricingTable: false,
  subscriptionConfirmationData: null,
};

const useStateDefination = (initialState = defaultInitialState) => {
  // State Properties
  let [appInitiated, setAppInitFlag] = useState(initialState.appInitiated);
  let [loggedIn, setloginStatus] = useState(initialState.loggedIn);
  let [showOnboarding, setShowOnboardingStatus] = useState(
    localStorage.getItem(DISPLAY_ONBOADING) === null,
  );
  let [showPushReqPopup, setPushReqPopupStatus] = useState(
    initialState.showPushReqPopup,
  );
  let [showPricingTable, setPricingTableStatus] = useState(
    initialState.showPricingTable,
  );
  let [subscriptionConfirmationData, setSubscriptionConfirmationData] =
    useState(initialState.subscriptionConfirmationData);

  return {
    appInitiated,
    loggedIn,
    setAppInitFlag,
    setloginStatus,
    showOnboarding,
    setShowOnboardingStatus,
    showPushReqPopup,
    setPushReqPopupStatus,
    showPricingTable,
    setPricingTableStatus,
    subscriptionConfirmationData,
    setSubscriptionConfirmationData,
  };
};

const stateContainer = createContainer(useStateDefination);

export const ApplicationState = {
  Provider: stateContainer.Provider,
  get: stateContainer.useContainer,
};
