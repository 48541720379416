import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app';
import reportWebVitals from 'utils/reportWebVitals';
import mixpanel from 'mixpanel-browser';
import { REACT_APP_ENV } from 'constants/app/environment';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'index.css';
import { isDemo } from 'utils/demo';

// Initialize Sentry (Error Tracking)
Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: 'https://e711ccbd30114fc48a35aea59eb5061f@o4504921407094784.ingest.sentry.io/4504921408733184',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// Init Mixpanel (Analytics & User Engagement)
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  debug: process.env.REACT_APP_ENV === REACT_APP_ENV.PROD ? true : true,
  // TODO: Revist this later, before EU Launch.
  ignore_dnt: true,
  cross_subdomain_cookie: true,
});

// Intialize MSW (Mock Server Worker)
if (isDemo) {
  const { worker } = require('./mocks/browser');
  // @ts-ignore
  await worker.start();
} else {
  serviceWorkerRegistration.register();
}

// Render React App
ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
