import AnimateStep from 'components/AnimateStep';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { useDisplayType } from 'utils/display';
import styles from './pageheader.module.css';
import { PageHeaderTypes } from './types';

const PageHeader = (props: PageHeaderTypes) => {
  const {
    title,
    subTitle,
    textColor,
    rightComponent,
    secondaryMenu,
    showBackButton,
    customActionOnBack,
    play = true,
    padding = 'large',
  } = props;
  const { isDesktop } = useDisplayType();
  const history = useHistory();

  return (
    <div
      className={
        isDesktop
          ? padding === 'small'
            ? styles.containerSmallPadding
            : styles.container
          : styles.containerMobile
      }
    >
      <div className={styles.row}>
        <AnimateStep play={play}>
          <div className={styles.contents}>
            {showBackButton && (
              <div
                className={styles.iconContainer}
                onClick={() =>
                  customActionOnBack ? customActionOnBack() : history.goBack()
                }
              >
                <AiOutlineArrowLeft />
              </div>
            )}
            <div className={styles.textContainer}>
              <div
                className={styles.title}
                style={textColor ? { color: textColor } : {}}
              >
                {title}
              </div>
              <div
                className={styles.subTitle}
                style={textColor ? { color: textColor } : {}}
              >
                {subTitle}
              </div>
            </div>
          </div>
        </AnimateStep>
        <div>{rightComponent}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.secondaryMenu}>{secondaryMenu}</div>
      </div>
    </div>
  );
};

export default PageHeader;
