import styles from './pushbutton.module.css';
import { PushButtonProps, PushButtonType } from './types';

const PushButton = (props: PushButtonProps) => {
  const {
    title,
    disabled,
    additionalClassName = '',
    onClick,
    type = PushButtonType.Primary,
    icon = null,
  } = props;
  let typeClass = styles.primaryButton;
  switch (type) {
    case PushButtonType.Primary:
      typeClass = styles.primaryButton;
      break;

    case PushButtonType.Secondary:
      typeClass = styles.secondaryButton;
      break;

    case PushButtonType.Danger:
      typeClass = styles.dangerButton;
      break;

    case PushButtonType.Grey:
      typeClass = styles.greyButton;
      break;

    case PushButtonType.SimpleText:
      typeClass = styles.simpleText;
      break;

    default:
      break;
  }

  if (disabled) typeClass = styles.greyButton;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${additionalClassName} ${styles.buttonBase} ${typeClass}`}
    >
      {!!icon && (
        <div className={title ? styles.baseIcon : styles.baseIconOnly}>
          {icon}
        </div>
      )}
      {title}
    </button>
  );
};

export default PushButton;
