import { useState } from 'react';
import {
  ExchangePairBalance,
  InitialAllocationTypes,
  MakeLiveBottomsheetProps,
  MakeLiveSteps,
  MarketStructure,
  TradingModeTypes,
} from './types';

export const useComponentState = (props: MakeLiveBottomsheetProps) => {
  const { tradingModeOptions } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<MakeLiveSteps>(
    MakeLiveSteps.TradingMode,
  );

  const [tradingMode, setTradingMode] = useState<TradingModeTypes>(
    tradingModeOptions[0],
  );

  const [initialAllocationType, setInitialAllocationType] =
    useState<InitialAllocationTypes>(InitialAllocationTypes.Cash);

  const [cashAllocation, setCashAllocation] = useState<string>('');
  const [positionAllocation, setPositionAllocation] = useState<string>('');

  const [marketStructure, setMarketStructure] = useState<MarketStructure>();

  const [cashError, setCashError] = useState<string>('');
  const [positionError, setPositionError] = useState<string>('');

  const [lowCashBalance, setLowCashBalance] = useState('');
  const [lowPositionBalance, setLowPositionBalance] = useState('');

  const [tradingModeWarning, setTradingModeWarning] = useState<string>('');

  const [exchangeBalances, setExchangeBalances] =
    useState<ExchangePairBalance>();

  return {
    isLoading,
    setIsLoading,
    currentStep,
    setCurrentStep,
    tradingMode,
    setTradingMode,
    initialAllocationType,
    setInitialAllocationType,
    cashAllocation,
    setCashAllocation,
    positionAllocation,
    setPositionAllocation,
    marketStructure,
    setMarketStructure,
    cashError,
    setCashError,
    positionError,
    setPositionError,
    exchangeBalances,
    setExchangeBalances,
    tradingModeWarning,
    setTradingModeWarning,
    lowCashBalance,
    setLowCashBalance,
    lowPositionBalance,
    setLowPositionBalance,
  };
};
