import { AssistantChatState } from 'containers/assistantChat/state';

export type ChatMessageType = {
  message: string | JSX.Element;
  sender: 'user' | 'assistant';
};

export enum CHAT_ACTION {
  SHARE_STRATEGY = 'SHARE_STRATEGY',
  SHARE_BACKTEST = 'SHARE_BACKTEST',
}

export type HeaderProps = {
  toggleChat: () => void;
};

export type MessagesProps = {
  State: ReturnType<typeof AssistantChatState.get>;
  handleActionSend: (action: CHAT_ACTION) => Promise<void>;
};

export type SuggestedActionsProps = {
  State: ReturnType<typeof AssistantChatState.get>;
  handleActionSend: (action: CHAT_ACTION) => Promise<void>;
};

export type FooterProps = {
  State: ReturnType<typeof AssistantChatState.get>;
  handleSend: () => Promise<void>;
};
