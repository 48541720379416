import styles from './news.module.css';
import { useComponentLogic } from './logics';
import { NEWS_MENU_ITEMS_URL_MAP } from 'constants/app/news';
import { NewsView } from './components/NewsView';
import { SelectedTrendingRecord } from 'types/news';

export type NewsProps = {
  setNewsSymbol: React.Dispatch<React.SetStateAction<string>>;
};

export const News = (props: NewsProps) => {
  const { setNewsSymbol } = props;
  const Component = useComponentLogic();

  const onSelectTrendingTopic = (
    topicId: string,
    search: SelectedTrendingRecord,
  ) => {
    if (search.symbol) {
      setNewsSymbol(search.symbol);
    }
    Component.onSelectTrendingTopic(topicId, search);
  };

  const clearTrendingSelect = (topicId: string) => {
    setNewsSymbol('');
    Component.clearTrendingSelect(topicId);
  };

  const cryproNewsData =
    Component.State.newsItems[
      `${NEWS_MENU_ITEMS_URL_MAP.crypto.id}_${Component.State.newsTimeframe}`
    ];

  return (
    <div className={styles.container}>
      <NewsView
        newsData={cryproNewsData}
        newsTopicData={NEWS_MENU_ITEMS_URL_MAP.crypto}
        fetchNewsForTopic={Component.fetchNewsForTopic}
        onSelectTrendingTopic={onSelectTrendingTopic}
        clearTrendingSelect={clearTrendingSelect}
      />
    </div>
  );
};
