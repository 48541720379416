import PageHeader from 'components/PageHeader';
import { useDisplayType } from 'utils/display';
import { useComponentLogic } from './logics';
import styles from './porfolio.module.css';
import PortfolioChart from 'components/PortfolioChart';
import { Distribution } from './components/Distribution';
import { Assets } from './components/Assets';
import PageMenu from 'components/PageMenu';
import { Route } from 'react-router-dom';
import Loader from 'components/Loader';
import { DailyChange } from 'components/DailyChange';
import MessageContainer from 'components/MessageContainer';
import { MessageContainerType } from 'components/MessageContainer/types';
import NoDataMessage from 'components/NoDataDisplay';
import no_data_illustration from 'assets/svg/digital_currency.svg';

const Portfolio = () => {
  const Component = useComponentLogic();
  const { isDesktop } = useDisplayType();
  const isLoading = !Component.accountBalances;
  return (
    <div className={styles.container}>
      <PageHeader
        title='Portfolio'
        subTitle='Manage your Portfolio accross exchanges.'
        showBackButton={false}
        secondaryMenu={
          <PageMenu
            menuItems={[
              {
                name: 'Assets',
                headerName: '',
                link: '/app/portfolio/assets',
              },
              {
                name: 'Distribution',
                headerName: '',
                link: '/app/portfolio/distibution',
              },
            ]}
          />
        }
      />
      {isLoading ? (
        <Loader containerClassName={styles.loaderContainer} />
      ) : (
        <div className={styles.contents}>
          <div
            className={`${styles.sectionContainer} ${
              isDesktop
                ? styles.netPortFolioContainer
                : styles.netPortFolioContainerMob
            }`}
          >
            <div className={styles.totalAmountHeader}>
              Net Portfolio Value ({Component.State.portfolioCurrency.label})
            </div>
            <div className={styles.amountContainer}>
              <div
                className={
                  isDesktop ? styles.totalAmount : styles.totalAmountMobile
                }
              >
                {Component.accountBalances?.totalUsdBalance.toFixed(2)}
              </div>

              <div className={styles.currencyContainer}>
                {Component.State.dailyChange ? (
                  <DailyChange
                    changeValue={
                      Component.State.dailyChange?.totalChange.valueChange
                    }
                    changePercent={
                      Component.State.dailyChange?.totalChange.percentageChange
                    }
                    showPercent={
                      !Component.State.dailyChange.totalChange.qtyChange
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={
              isDesktop ? styles.porfolioHistory : styles.porfolioHistoryMobile
            }
          >
            <Route exact path={'/app/portfolio/assets'}>
              <PortfolioChart
                pData={Component.State.portfolioHistory}
                fetchHistory={Component.fetchAndUpdatePorfolioHistory}
              />
            </Route>
            <Route exact path={'/app/portfolio/distibution'}>
              <Distribution data={Component.accountBalances?.totals} />
            </Route>
          </div>
          <div
            className={
              isDesktop
                ? styles.portfolioContainers
                : styles.portfolioContainersMobile
            }
          >
            {Component.accountBalances?.totals.length === 0 ? (
              <NoDataMessage
                image={no_data_illustration}
                messageText='You do not have any assets to track at the moment, Link an exchange to track your portfolio.'
              />
            ) : (
              <Assets
                currency={Component.State.portfolioCurrency.label}
                totals={Component.accountBalances?.totals}
                dailyChange={Component.State.dailyChange}
              />
            )}
            {Component.accountBalances?.failedExchanges.length ? (
              <div className={styles.failedFetchError}>
                <div className={styles.failedFetchErrorHeader}>
                  <MessageContainer
                    message={`Failed to fetch
                  ${Component.accountBalances?.failedExchanges.length} linked exchange's
                  balance.`}
                    type={MessageContainerType.Warning}
                  />
                </div>
                <div>
                  {Component.accountBalances?.failedExchanges.map((e) => (
                    <div key={e.exchangeName} className={styles.failedExchange}>
                      <MessageContainer
                        message={`${e.exchangeName}: ${e.error}`}
                        type={MessageContainerType.Warning}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;
