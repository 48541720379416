import axios, { AxiosError, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import { USER_COGNITO_AUTH_TOKEN } from 'constants/app/localstore';
import { isDemo } from 'utils/demo';

// Set config defaults
const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Set Defaults
HTTP.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';
HTTP.defaults.headers.common['Accept-Language'] = 'en-GB,en-US;q=0.9,en;q=0.8';

// All HTTP request/response interceptor
HTTP.interceptors.response.use(
  function (successRes: AxiosResponse) {
    return successRes?.data;
  },
  async function (error: AxiosError) {
    console.log('Logging Axios Error:');
    console.log(error);
    // @ts-ignore
    if (error.response?.status === 401 && !error.config._retry) {
      console.log('(401) Refreshing session...');
      // @ts-ignore
      error.config._retry = true; // mark that we have retried
      try {
        const user = await Auth.currentAuthenticatedUser();
        const newToken = user.signInUserSession.idToken.jwtToken;
        updateAuthToken(newToken);
        // Update the Authorization header with new token and retry the request
        if (error.config) {
          error.config.headers.Authorization = `Bearer ${newToken}`;
          return axios(error.config);
        }
      } catch (sessionError) {
        console.error('Session refresh failed:', sessionError);
        updateAuthToken('');
        // Redirect to login
        if (!isDemo) {
          window.location.reload();
        }
      }
    }
    return Promise.reject(error);
  },
);

export const updateAuthToken = (token: string) => {
  // Update HTTP Headers
  if (token) {
    HTTP.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  } else {
    // @ts-ignore
    HTTP.interceptors.request.clear();
  }
  // Update in Local Storage
  localStorage.setItem(USER_COGNITO_AUTH_TOKEN, token);
};

export const checkHandledErrorCode = (error: any, code: string): boolean => {
  if (error?.response?.data?.code === code) {
    return true;
  } else {
    return false;
  }
};

export default HTTP;
