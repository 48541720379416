import { useState } from 'react';
import styles from './pricingtable.module.css';
import { PricingCardProps, PricingTableProps } from './types';
import PushButton from 'components/PushButton';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Price } from 'types/subscriptions';

const PricingCard = (props: PricingCardProps) => {
  const { product, isMonthly, isLoadingCheckout, onCheckout } = props;
  const monthlyPrice = product.prices.find(
    (price) => price.recurring.interval === 'month',
  ) as Price;
  const annualPrice = product.prices.find(
    (price) => price.recurring.interval === 'year',
  ) as Price;

  return (
    <div className={styles.pricingCard}>
      <div>
        <div className={styles.productName}>{product.name}</div>
        <p className={styles.description}>{product.description}</p>
        {isMonthly ? (
          <div className={styles.priceContainer}>
            <div className={styles.currency}>
              {monthlyPrice ? monthlyPrice.currency : ''}
            </div>
            <div className={styles.price}>
              {monthlyPrice ? monthlyPrice.unit_amount / 100 : ''}
            </div>
            <span className={styles.timespan}>/ Month</span>
          </div>
        ) : (
          <div className={styles.priceContainer}>
            <div className={styles.currency}>
              {annualPrice ? annualPrice.currency : ''}
            </div>
            <div className={styles.price}>
              {annualPrice ? Math.ceil(annualPrice.unit_amount / 100 / 12) : ''}
            </div>
            <span className={styles.timespan}>/ Month (Charged Annually)</span>
          </div>
        )}
        <div>
          {product.features.map((feature) => (
            <div className={styles.features} key={feature.name}>
              <div className={styles.featuresIcon}>
                <AiOutlineCheckCircle color={'#00ba7c'} />
              </div>
              {feature.name}
            </div>
          ))}
        </div>
      </div>
      <PushButton
        additionalClassName={styles.actionButton}
        title={'Buy'}
        disabled={isLoadingCheckout}
        onClick={() =>
          onCheckout(isMonthly ? monthlyPrice?.id : annualPrice?.id)
        }
      />
    </div>
  );
};

const PricingTable = (props: PricingTableProps) => {
  const { products, isLoadingCheckout, onCheckout } = props;
  const [isMonthly, setIsMonthly] = useState(true);

  return (
    <div className={styles.pricingTable}>
      <div className={styles.pageHeader}>Pricing</div>
      <div className={styles.pageDescription}>
        Each plan is designed to cater to different levels of trading expertise
        and ambition. Explore pricing options below and select the one that best
        aligns with your trading goals.
      </div>
      <div className={styles.switchWrapper}>
        <input
          id='monthly'
          className={styles.radioInput}
          type='radio'
          name='switch'
          checked={isMonthly}
          onChange={() => setIsMonthly(true)}
        />
        <input
          id='yearly'
          className={styles.radioInput}
          type='radio'
          name='switch'
          checked={!isMonthly}
          onChange={() => setIsMonthly(false)}
        />
        <label
          htmlFor='monthly'
          className={`${styles.label} ${
            isMonthly ? styles.checkedMonthlyLabel : ''
          }`}
        >
          Monthly
        </label>
        <label
          htmlFor='yearly'
          className={`${styles.label} ${
            !isMonthly ? styles.checkedYearlyLabel : ''
          }`}
        >
          Yearly
        </label>
        <span
          className={`${styles.highlighter} ${
            isMonthly ? styles.noHighlightTransform : styles.highlightTransform
          }`}
        ></span>
      </div>
      <div className={styles.cardsContainer}>
        {products.map((product) => (
          <PricingCard
            key={product.id}
            product={product}
            isMonthly={isMonthly}
            isLoadingCheckout={isLoadingCheckout}
            onCheckout={onCheckout}
          />
        ))}
      </div>
    </div>
  );
};

export default PricingTable;
