import { useMemo, useState } from 'react';
import {
  DateRange,
  MiniChart,
  SingleTicker,
} from 'react-ts-tradingview-widgets';
import { useDisplayType } from 'utils/display';
import ChartIntervalSelect from 'views/Markets/components/ChartIntervalSelect';
import { TradingViewSymbolInfoProps } from './types';

const TradingViewSymbolInfo = (props: TradingViewSymbolInfoProps) => {
  const { tvSymbol, alwaysShowChart = false, toggleFullChart } = props;
  const { isDesktop } = useDisplayType();
  const [selectedInterval, setSelectedInterval] = useState('1D');
  return useMemo(() => {
    return !isDesktop || alwaysShowChart ? (
      <>
        <MiniChart
          symbol={tvSymbol}
          autosize={false}
          isTransparent={true}
          largeChartUrl={process.env.REACT_APP_PUBLIC_URL}
          height={300}
          // @ts-ignore
          width={'100%'}
          dateRange={selectedInterval as DateRange}
          // colorTheme?: ColorTheme;
          trendLineColor='#00ba7cc7'
          underLineColor='#a1e2ce7a'
          underLineBottomColor='#fff'
        />
        <ChartIntervalSelect
          selectedInterval={selectedInterval}
          onChange={setSelectedInterval}
          toggleFullChart={toggleFullChart}
        />
      </>
    ) : (
      <SingleTicker
        symbol={tvSymbol}
        colorTheme='light'
        autosize
        isTransparent
        largeChartUrl={process.env.REACT_APP_PUBLIC_URL}
      ></SingleTicker>
    );
  }, [tvSymbol, isDesktop, selectedInterval]);
};

export default TradingViewSymbolInfo;
