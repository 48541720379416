/**
 * @name app.js
 * @fileoverview Exports the frontend application as <App/> Component,
 * Initialises Application Routes using react-router and React components from /views.
 * Renders global state providers.
 */

import { MemoryRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import { ApplicationState } from 'containers/application/state';
import { ExchangeState } from 'containers/exchange/state';
import { AuthenticationState } from 'containers/auth/state';
import { UserCommonDataState } from 'containers/userCommonData/state';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Capacitor } from '@capacitor/core';

import 'utils/localization';
import { AssistantChatState } from 'containers/assistantChat/state';

// import and load the configuration file
const platform = Capacitor.getPlatform();
switch (platform) {
  case 'web':
    awsconfig.oauth.redirectSignOut = window.location.origin;
    awsconfig.oauth.redirectSignIn = window.location.origin;
    break;

  default:
    awsconfig.oauth.redirectSignOut = 'https://app.uptrend.trade';
    awsconfig.oauth.redirectSignIn = 'https://app.uptrend.trade';
    break;
}

Amplify.configure(awsconfig);

const App = () => {
  return (
    <ApplicationState.Provider>
      <AuthenticationState.Provider>
        <ExchangeState.Provider>
          <UserCommonDataState.Provider>
            <AssistantChatState.Provider>
              <Router>
                <Routes />
              </Router>
            </AssistantChatState.Provider>
          </UserCommonDataState.Provider>
        </ExchangeState.Provider>
      </AuthenticationState.Provider>
    </ApplicationState.Provider>
  );
};

export default App;
