import { updateUserPushToken } from 'apis/user';
import {
  PREFERRED_EXCHANGE_KEY,
  PUSH_TOKEN_UPDATE_INFO,
} from 'constants/app/localstore';
import { useAuthContainer } from 'containers/auth';
import { useExchangeContainer } from 'containers/exchange';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPreferredCurrency } from 'utils/currency';
import { useComponentState } from './state';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'constants/app/firebase';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported as isWebPushSupported,
} from 'firebase/messaging';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Auth as AmplifyAuth } from 'aws-amplify';
import { OrderNotificationData } from 'types/app';
import { isDemo } from 'utils/demo';
import { Browser } from '@capacitor/browser';

export const useComponentLogic = () => {
  const State = useComponentState();
  const Exchange = useExchangeContainer();
  const CommonData = useUserCommonDataContainer();
  const Auth = useAuthContainer();
  const history = useHistory();
  const platform = Capacitor.getPlatform();

  // Initialize Firebase
  const firebaseApp = platform === 'web' ? initializeApp(firebaseConfig) : null;

  const showInAppNotificationOrderToast = (body: any, strategyId: any) => {
    if (body && strategyId) {
      toast.success(body, {
        data: {
          strategyId: strategyId,
        },
        onClick: () => {
          history.push(`/app/strategies/strategy/${strategyId}/liverun`);
        },
      });
    }
  };

  const addPushNotificationListener = async () => {
    if (isDemo) return;

    switch (platform) {
      case 'web':
        // Initialize Firebase Cloud Messaging and get a reference to the service
        isWebPushSupported().then((supported) => {
          // Web push should be supported
          if (supported) {
            // @ts-ignore
            const messaging = getMessaging(firebaseApp);
            // Handle messages from web push notification
            onMessage(messaging, (payload) => {
              if (payload?.data?.type === 'order_notification') {
                showInAppNotificationOrderToast(
                  payload.notification?.body,
                  payload?.data?.strategyId as string,
                );
              }
            });
          }
        });
        break;

      default:
        // Handle Foreground Push Notification received for Android Devices.
        if (platform === 'android') {
          await FirebaseMessaging.addListener(
            'notificationReceived',
            (event) => {
              const notificationData = event.notification
                ?.data as OrderNotificationData;
              showInAppNotificationOrderToast(
                event.notification?.body,
                notificationData?.strategyId,
              );
            },
          );
        }

        // Handle Push Notification click
        await FirebaseMessaging.addListener(
          'notificationActionPerformed',
          (event) => {
            const notificationData = event.notification
              ?.data as OrderNotificationData;
            if (notificationData?.type === 'order_notification') {
              const strategyId = notificationData?.strategyId;
              if (strategyId) {
                history.push(`/app/strategies/strategy/${strategyId}/liverun`);
              }
            }
          },
        );

        break;
    }
  };

  const requestFirebaseNotifications = async (manualRequest: boolean) => {
    // No push notifications for demo pages
    if (isDemo) return;

    switch (platform) {
      case 'web':
        // @ts-ignore
        const messaging = getMessaging(firebaseApp);
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_WEB_PUSH_VAPID_KEY,
        })
          .then((token) => {
            if (token) {
              State.setPushTokenInfo({
                os: 'web',
                token,
              });
            } else {
              if (manualRequest) {
                toast.error('Push notification permissions were not granted.');
              }
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
        break;

      default:
        const perm = await FirebaseMessaging.checkPermissions();
        if (perm.receive === 'granted') {
          const result = await FirebaseMessaging.getToken();
          State.setPushTokenInfo({
            os: platform,
            token: result.token,
          });
        } else {
          const req = await FirebaseMessaging.requestPermissions();
          if (req.receive === 'granted') {
            const result = await FirebaseMessaging.getToken();
            State.setPushTokenInfo({
              os: platform,
              token: result.token,
            });
          }
        }
        break;
    }
  };

  // Update Symbols on refresh
  let preferredExchangeId = localStorage.getItem(PREFERRED_EXCHANGE_KEY);
  preferredExchangeId = preferredExchangeId ? preferredExchangeId : 'BINANCE';
  useEffect(() => {
    Auth.State.isAuthenticated &&
      Exchange.fetchExchangeSymbols(preferredExchangeId as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth.State.isAuthenticated]);

  // Fetch user startegies,Portfolio, watchlist on render
  useEffect(() => {
    if (Auth.State.isAuthenticated) {
      CommonData.fetchUserWatchlist();
      CommonData.fetchUserStrategies();
      CommonData.fetchUserSettings();

      // Add push notification listener
      addPushNotificationListener();

      // Fetch updated push notification token.
      requestFirebaseNotifications(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth.State.isAuthenticated]);

  // Handle push token update to backend
  useEffect(() => {
    if (Auth.State.isAuthenticated && State.pushTokenInfo) {
      // Update only one in a week
      const lastUpdate = localStorage.getItem(PUSH_TOKEN_UPDATE_INFO);
      const shouldUpdate = lastUpdate !== State.pushTokenInfo.token;

      if (shouldUpdate) {
        updateUserPushToken(
          State.pushTokenInfo.token,
          State.pushTokenInfo.os,
        ).catch((e) => {
          console.log(e);
        });
        // Avoids running this hook again on isAuthenticated change
        State.setPushTokenInfo(null);

        // Update last updated token
        localStorage.setItem(PUSH_TOKEN_UPDATE_INFO, State.pushTokenInfo.token);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth.State.isAuthenticated, State.pushTokenInfo]);

  // Handle Deep Links App Open
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', async ({ url }) => {
        console.log('App opened with URL: ' + url);
        const params = new URLSearchParams(url.split('?')[1]);

        if (params.has('local')) {
          // flag added to avoid a loop
          return;
        }

        if (params.has('code') && params.has('state')) {
          if (platform === 'ios') {
            window.location.replace(
              url.replace(
                'https://app.uptrend.trade',
                'capacitor://localhost',
              ) + '&local=true',
            );
            Browser.close();
          }
          if (platform === 'android') {
            const loginUrl =
              url.replace('https://www.app.uptrend.trade', 'http://localhost') +
              '&local=true';
            history.push(loginUrl);
            Auth.State.setAuthLoadingStatus(true);
            (AmplifyAuth as any)._handleAuthResponse(loginUrl);
            await AmplifyAuth.currentAuthenticatedUser().then((user) => {
              Auth.onLoginSuccess(user);
            });
            Auth.State.setAuthLoadingStatus(false);
          }
        }
      });
    }
  }, []);

  return {
    State,
    isLoadingAuth: Auth.State.isLoadingAuthStatus,
    requestFirebaseNotifications,
  };
};
