import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import { AiOutlineLineChart } from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import { StrategyHeader } from 'views/Strategy/components/StrategyHeader';
import {
  BacktestPerformanceHeaders,
  BacktestSummaryHeaders,
  BacktestTradeSummaryHeaders,
} from './constants';
import styles from './resultanalysis.module.css';
import { ResultAnalysisProps } from './types';

const ResultAnalysis = (props: ResultAnalysisProps) => {
  const { resultData, symbolsInfo, tradingMode, interval, backtestDateRange } =
    props;
  const { isMobile } = useDisplayType();
  const summaryInfo = [
    {
      name: 'Initial Value',
      value: resultData.initial_portfolio_value.toFixed(2),
    },
    {
      name: 'Current Value',
      value: resultData.final_portfolio_value.toFixed(2),
      color:
        resultData.final_portfolio_value > resultData.initial_portfolio_value
          ? '#27ad5f'
          : '#ff6868',
    },
    {
      name: 'Gross P&L',
      value: resultData.trade_analysis.pnl_gross.toFixed(2),
      color: resultData.trade_analysis.pnl_gross > 0 ? '#27ad5f' : '#ff6868',
    },
    {
      name: 'Fees',
      value: resultData.trade_analysis.commissions.toFixed(2),
      color: '#ff6868',
    },
    {
      name: 'Net P&L',
      value: resultData.trade_analysis.pnl_net.toFixed(2),
      color: resultData.trade_analysis.pnl_net > 0 ? '#27ad5f' : '#ff6868',
    },
    {
      name: 'Returns',
      value:
        (
          (resultData.trade_analysis.pnl_net /
            resultData.initial_portfolio_value) *
          100
        ).toFixed(2) + '%',
      color: resultData.trade_analysis.pnl_net > 0 ? '#27ad5f' : '#ff6868',
    },
  ];

  return (
    <div className={isMobile ? styles.containerMobile : styles.container}>
      <StrategyHeader
        symbolsInfo={symbolsInfo}
        interval={interval}
        tradingMode={tradingMode}
        backtestDateRange={backtestDateRange}
      />

      <div className={styles.summaryContainer}>
        {summaryInfo.map((s) => {
          return (
            <div className={styles.infoColumn} key={s.name}>
              <div className={styles.rowHeader}>{s.name}</div>
              <div
                className={styles.rowValue}
                style={{
                  color: s.color ? s.color : '',
                }}
              >
                {s.value}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.analysisContainer}>
        <div className={styles.mainHeader}>Summary</div>
        <div>
          {Object.keys(BacktestSummaryHeaders).map((k) => {
            return (
              <div className={styles.infoRow} key={k}>
                <span className={styles.columnHeader}>
                  {/* @ts-ignore */}
                  {BacktestSummaryHeaders[k]}
                </span>
                <span className={styles.columnValue}>
                  {/* @ts-ignore */}
                  {resultData.trade_analysis[k]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.analysisContainer}>
        <div className={styles.mainHeader}>Trade Analysis</div>
        <div>
          {Object.keys(BacktestTradeSummaryHeaders).map((k) => {
            return (
              <div className={styles.infoRow} key={k}>
                <span className={styles.columnHeader}>
                  {/* @ts-ignore */}
                  {BacktestTradeSummaryHeaders[k]}
                </span>
                <span className={styles.columnValue}>
                  {/* @ts-ignore */}
                  {resultData.trade_analysis[k]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.analysisContainer}>
        <div className={styles.mainHeader}>Performance Analysis</div>
        <div>
          <div className={styles.infoRow}>
            <span className={styles.columnHeader}>
              {/* @ts-ignore */}
              SQN
            </span>
            <span className={styles.columnValue}>{resultData.sqn}</span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.columnHeader}>
              {/* @ts-ignore */}
              Max Drawdown
            </span>
            <span className={styles.columnValue}>
              {`${resultData.max_dd.value} (${resultData.max_dd.percentage}%)`}
            </span>
          </div>
          {Object.keys(BacktestPerformanceHeaders).map((k) => {
            return (
              <div className={styles.infoRow} key={k}>
                <span className={styles.columnHeader}>
                  {/* @ts-ignore */}
                  {BacktestPerformanceHeaders[k]}
                </span>
                <span className={styles.columnValue}>
                  {/* @ts-ignore */}
                  {resultData.trade_analysis[k]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ResultAnalysis;
