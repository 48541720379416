/**
 * @name routes/index.tsx
 * @fileoverview Exports the frontend application's Router component,
 * Imports all the views defined in /views,
 * Each Route renders a React Component (views) out of /views,
 * Views are react components composed out of other react components from /components.
 */

import Loader from 'components/Loader';
import MainMenu from 'components/MainMenu';
import ProtectedRoute from 'routes/components/ProtectedRoute';
import { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Home from 'views/Markets';
import Shell from 'routes/components/Shell';
import Strategies from 'views/Strategies';
import { useRoutingLogic } from './logics';
import Strategy from 'views/Strategy';
import Settings from 'views/Settings';
import Portfolio from 'views/Portfolio';
import { useDisplayType } from 'utils/display';
import News from 'views/News';
import { spring, AnimatedSwitch } from 'react-router-transition';
import { Onboarding } from 'views/Onboarding';

const Routes = () => {
  const routingLogic = useRoutingLogic();
  const { isDesktop } = useDisplayType();

  // we need to map the `scale` prop we define below
  // to the transform style property
  function mapStyles(styles: { opacity: any; scale: any }) {
    return {
      opacity: styles.opacity,
      transform: `scale(${styles.scale})`,
    };
  }

  // wrap the `spring` helper to use a bouncy config
  function bounce(val: number) {
    return spring(val, {
      stiffness: 330,
      damping: 22,
    });
  }

  // child matches will...
  const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
      opacity: 0,
      scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
      opacity: bounce(0),
      scale: bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
      opacity: bounce(1),
      scale: bounce(1),
    },
  };

  return (
    <Shell>
      <div
        id='app-container-main'
        className={isDesktop ? 'app-container' : 'mobile-app-container'}
      >
        <div className={isDesktop ? 'main-menu' : 'main-menu-mobile'}>
          <Route path={'/app'} component={MainMenu} />
        </div>
        <div className='app-route-container'>
          <Suspense fallback={<Loader />}>
            <AnimatedSwitch
              atEnter={bounceTransition.atEnter}
              atLeave={bounceTransition.atLeave}
              atActive={bounceTransition.atActive}
              mapStyles={mapStyles}
              className='route-wrapper'
            >
              <Route exact path={'/login'} render={routingLogic.loginRouting} />
              {/* <ProtectedRoute exact={false} path={'/app/news'}>
                <News />
              </ProtectedRoute> */}
              <ProtectedRoute exact path={'/app/market'}>
                <Home />
              </ProtectedRoute>
              <ProtectedRoute exact path={'/app/strategies'}>
                <Strategies />
              </ProtectedRoute>
              <ProtectedRoute
                exact={false}
                path={'/app/strategies/strategy/:strategyId'}
              >
                <Strategy />
              </ProtectedRoute>
              <ProtectedRoute exact={false} path={'/app/portfolio'}>
                <Portfolio />
              </ProtectedRoute>
              <ProtectedRoute exact path={'/app/settings'}>
                <Settings />
              </ProtectedRoute>
              <Redirect to={'/app/market'} />
            </AnimatedSwitch>
          </Suspense>
        </div>
      </div>
    </Shell>
  );
};

export default Routes;
