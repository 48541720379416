import styles from './strheader.module.css';
import { StrategyHeaderProps } from './types';
import { useDisplayType } from 'utils/display';
import { TradingModeTypes } from 'views/Strategy/components/MakeLiveBottomsheet/types';
import { readableLocalTime, readableShortLocalTime } from 'utils/dateTime';
import { AiOutlineRight } from 'react-icons/ai';
import { PUBLIC_URL } from 'utils/environment';

export const StrategyHeader = (props: StrategyHeaderProps) => {
  const {
    symbolsInfo,
    interval,
    tradingMode,
    onSelect,
    fullWidth,
    backtestDateRange,
  } = props;
  const { isDesktop } = useDisplayType();
  return (
    <div
      className={
        !isDesktop || fullWidth ? styles.containerMobile : styles.container
      }
      onClick={() => (onSelect ? onSelect() : null)}
    >
      <div className={styles.contents}>
        <div className={styles.cardHeader}>
          <div className={styles.headerIcon}>
            <img
              src={`${PUBLIC_URL}/icons/crypto/${symbolsInfo.base_currency.toLocaleLowerCase()}.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
              }}
              width={22}
              alt={'Symbol Icon'}
            />
          </div>

          <div className={styles.headerTextContainer}>
            <div className={styles.headerText}>
              {symbolsInfo.base_currency}/{symbolsInfo.quote_currency}
              <div className={styles.headerSubText}>
                {symbolsInfo.exchange_id}
              </div>
            </div>
            <div className={styles.statusContainer}>
              <span className={styles.interval}>{interval} </span>
              {tradingMode === TradingModeTypes.Live && (
                <span className={styles.statusLive}>Live</span>
              )}
              {tradingMode === TradingModeTypes.Paper && (
                <span className={styles.statusPaper}>Paper</span>
              )}
              {!tradingMode && (
                <span className={styles.statusInactive}>Inactive</span>
              )}
            </div>
          </div>
        </div>
        {backtestDateRange && (
          <div className={styles.cardDetails}>
            <div className={styles.dateRange}>
              {`From: ${readableShortLocalTime(backtestDateRange.start)}`}
            </div>
            <div className={styles.dateRange}>
              {`To: ${readableShortLocalTime(backtestDateRange.end)}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
