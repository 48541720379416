import {
  deleteLinkedExchange,
  getLinkedExchanges,
  putExchangeCredentials,
} from 'apis/exchange';
import { useAuthContainer } from 'containers/auth';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { UserExchangeKeys } from 'types/exchange';
import { useComponentState } from './state';
import { SelectOption } from 'types/app';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { useApplicationContainer } from 'containers/application';
import { OnCloseActionType } from 'components/SubscriptionConfirmation/types';
import { Analytics } from 'utils/analytics';
import {
  success_delete_exchange,
  page_settings,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const State = useComponentState();
  const Auth = useAuthContainer();
  const CommonData = useUserCommonDataContainer();
  const AppContainer = useApplicationContainer();

  // Method to add a new exchange credentials
  const addExchangeCredentials = async () => {
    if (State.pk && State.sk && State.selectedExchange) {
      const exchangeKeys: UserExchangeKeys = {
        userId: Auth.State.appUser.sub,
        exchangeId: State.selectedExchange.value,
        exchangeName: State.selectedExchange.label,
        pk: State.pk,
        sk: State.sk,
      };
      putExchangeCredentials(exchangeKeys)
        .then((result) => {
          toast.success('Credentials added successfully.');
          State.clearState();
          fetchLinkedExchanges();
        })
        .catch((e) => {
          toast.error('Something went wrong. Please try again later');
        });
    } else {
      toast.error('Please provide all required credentials.');
    }
  };

  // Method to get linked exchanges
  const fetchLinkedExchanges = () => {
    State.setIsLoadingExchanges(true);
    getLinkedExchanges()
      .then((result) => {
        State.setLinkedExchanges(result);
      })
      .catch(() => {
        toast.error('Failed to fetch linked exchanges, Please Refresh.');
      })
      .finally(() => {
        State.setIsLoadingExchanges(false);
      });
  };

  // method to unlink exchange
  const deleteExchangeCredentials = (id: string) => {
    deleteLinkedExchange(id)
      .then(() => {
        fetchLinkedExchanges();
        toast.success('Exchange unlinked successfully.');
        Analytics.track(success_delete_exchange, {
          screen: page_settings,
        });
      })
      .catch(() => {
        toast.error(
          'Failed to delete linked exchange, Please try again later.',
        );
      });
  };

  const selectedExchange = (exchange: SelectOption | null) => {
    const maxExchanges =
      CommonData.State.userSettings?.plan.planDetails?.maxExchanges ?? 0;
    if (State.linkedExchanges.length < maxExchanges) {
      State.setExchange(exchange);
    } else {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `You have reached the maximum limit of linked exchanges for your current plan. Please upgrade your plan to link more exchanges.`,
        screen: page_settings,
        reason: 'maxExchanges',
      });
      return;
    }
  };

  // Fetch linked exchanges on render
  useEffect(() => {
    fetchLinkedExchanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    State,
    selectedExchange,
    addExchangeCredentials,
    deleteExchangeCredentials,
  };
};
