export const BacktestSummaryHeaders = {
  pnl_gross: 'Gross P&L',
  commissions: 'Fees',
  pnl_net: 'Net P&L',
};

export const BacktestTradeSummaryHeaders = {
  total_open: 'Currently Open',
  total_closed: 'Total Closed',
  total_won: 'Total Won',
  total_won_value: 'Total Won (Value)',
  max_win_value: 'Largest Win',
  total_lost: 'Total Lost',
  total_lost_value: 'Total Lost (Value)',
  max_lost_value: 'Largest Loss',
};

export const BacktestPerformanceHeaders = {
  strike_rate: 'Strike Rate (%)',
  win_streak: 'Longest Winning Streak',
  lose_streak: 'Longest Loosing Streak',
};
