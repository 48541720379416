import { PieChart } from '../PieChart';
import { DiostributionProps } from './types';
import styles from './dist.module.css';

export const Distribution = (props: DiostributionProps) => {
  const { data } = props;
  return (
    <div className={styles.pieChartContainer}>
      <PieChart data={data} />
    </div>
  );
};
