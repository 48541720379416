import { get_exchange_symbols } from './responses/exchangeSymbols';
import {
  get_exchanges,
  get_exchanges_portfolio,
  get_market_structure,
} from './responses/exchanges';
import { get_strategy_events } from './responses/liveEvents';
import { get_startegy_chart } from './responses/liveResult';
import {
  get_news_summary_crypto_market_news_24h,
  get_news_topic_crypto_market_news_24h,
} from './responses/news';
import { post_createBillingPortalSession } from './responses/plans';
import { get_user_portfiloHistory_1 } from './responses/portfolio';
import { user_settings } from './responses/settings';
import {
  delete_strategy,
  get_startegy_details,
  get_strategies,
  get_strategy_summary,
  post_run_backtest,
  post_stop_startegy,
} from './responses/startegies';
import { get_user_watchlist } from './responses/watchlist';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const INTEL_BASE_URL = process.env.REACT_APP_CLIMBER_BASE_URL;

export type MockConfig = {
  apiName: string;
  mock: boolean;
  mockJson: any;
  status: number;
  methodType: string;
};

export const mockList = [
  {
    apiName: `${BASE_URL}/exchanges`,
    mock: true,
    mockJson: get_exchanges,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/exchanges/*`,
    mock: true,
    mockJson: get_exchanges,
    status: 200,
    methodType: 'DELETE',
  },
  {
    apiName: `${BASE_URL}/exchange/add`,
    mock: true,
    mockJson: get_exchanges,
    status: 200,
    methodType: 'PUT',
  },
  {
    apiName: `${BASE_URL}/user/watchlist`,
    mock: true,
    mockJson: get_user_watchlist,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/user/settings`,
    mock: true,
    mockJson: user_settings,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/user/settings`,
    mock: true,
    mockJson: user_settings,
    status: 200,
    methodType: 'PUT',
  },
  {
    apiName: `${BASE_URL}/user/plans/createBillingPortalSession`,
    mock: true,
    mockJson: post_createBillingPortalSession,
    status: 200,
    methodType: 'POST',
  },
  {
    apiName: `${BASE_URL}/strategies`,
    mock: true,
    mockJson: get_strategies,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/strategy/details/*`,
    mock: true,
    mockJson: get_startegy_details,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/strategy/*`,
    mock: true,
    mockJson: delete_strategy,
    status: 200,
    methodType: 'DELETE',
  },
  {
    apiName: `${BASE_URL}/strategy`,
    mock: true,
    mockJson: get_startegy_details,
    status: 200,
    methodType: 'PUT',
  },
  {
    apiName: `${BASE_URL}/strategy/summary/*`,
    mock: true,
    mockJson: get_strategy_summary,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/strategy/events/*`,
    mock: true,
    mockJson: get_strategy_events,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/strategy/status/`,
    mock: true,
    mockJson: post_stop_startegy,
    status: 200,
    methodType: 'POST',
  },
  {
    apiName: `${INTEL_BASE_URL}/get-strategy-chart`,
    mock: true,
    mockJson: get_startegy_chart,
    status: 200,
    methodType: 'POST',
  },
  {
    apiName: `${BASE_URL}/user/watchlist`,
    mock: true,
    mockJson: get_user_watchlist,
    status: 200,
    methodType: 'PUT',
  },
  {
    apiName: `${BASE_URL}/exchanges/portfolio/usd`,
    mock: true,
    mockJson: get_exchanges_portfolio,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/user/portfolioHistory`,
    mock: true,
    mockJson: get_user_portfiloHistory_1,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/exchange/symbols/*`,
    mock: true,
    mockJson: get_exchange_symbols,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/news/topic/crypto_market_news/24h`,
    mock: true,
    mockJson: get_news_topic_crypto_market_news_24h,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/news/summary/crypto_market_news/24h`,
    mock: true,
    mockJson: get_news_summary_crypto_market_news_24h,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/exchange/marketStructure?exchange=binance&symbol=BTC%2FUSDT`,
    mock: true,
    mockJson: get_market_structure,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/exchange/marketStructure?exchange=binance&symbol=ETH%2FUSDT`,
    mock: true,
    mockJson: get_market_structure,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/exchange/marketStructure?exchange=binance&symbol=ETH%2FBTC`,
    mock: true,
    mockJson: get_market_structure,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${BASE_URL}/exchange/marketStructure?exchange=binance&symbol=DOGE%2FUSDT`,
    mock: true,
    mockJson: get_market_structure,
    status: 200,
    methodType: 'GET',
  },
  {
    apiName: `${INTEL_BASE_URL}/run-backtest`,
    mock: true,
    mockJson: post_run_backtest,
    status: 200,
    methodType: 'POST',
  },
];
