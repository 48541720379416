export const supportedExchanges = [
  // { value: 'ascendex', label: 'AscendEX' },
  { value: 'binance', label: 'Binance' },
  { value: 'binanceus', label: 'Binance US' },
  // { value: 'bitfinex', label: 'Bitfinex' },
  // { value: 'bitflyer', label: 'bitFlyer' },
  // { value: 'bitget', label: 'Bitget' },
  // { value: 'bithumb', label: 'Bithumb' },
  // { value: 'bitmex', label: 'BitMEX' },
  // { value: 'bitpanda', label: 'Bitpanda Pro' },
  // { value: 'bitso', label: 'Bitso' },
  // { value: 'bitstamp', label: 'Bitstamp' },
  // { value: 'bittrex', label: 'Bittrex' },
  // { value: 'bybit', label: 'Bybit' },
  // { value: 'cex', label: 'CEX.IO' },
  // { value: 'coinbase', label: 'Coinbase' },
  // { value: 'coinfloor', label: 'Coinfloor' },
  // { value: 'currencycom', label: 'Currencycom' },
  // { value: 'deribit', label: 'Deribit' },
  // { value: 'exmo', label: 'EXMO' },
  // { value: 'ftx', label: 'FTX' },
  // { value: 'gemini', label: 'Gemini' },
  // { value: 'huobi', label: 'Huobi' },
  // { value: 'kraken', label: 'Kraken' },
  // { value: 'kucoin', label: 'KuCoin' },
  // { value: 'mercado', label: 'Mercado' },
  // { value: 'okcoin', label: 'OKCoin' },
  // { value: 'okex', label: 'OKEx' },
  // { value: 'phemex', label: 'Phemex' },
  // { value: 'poloniex', label: 'Poloniex' },
  // { value: 'timex', label: 'TimeX' },
  // { value: 'upbit', label: 'UpBit' },
  // { value: 'bitbns', label: 'BitBns' },
];

export const MIN_CASH_BALANCE_MULTIPLIER = 1;

export const MIN_AMOUNT_BALANCE_MULTIPLIER = 1;
