import AppLogo from 'components/AppLogo';
import styles from './login.module.css';
import configure from 'assets/images/configure.png';
import liveStrategy from 'assets/images/liveStrategy.png';
import { SignIn } from './components/SignIn';
import { SignUp } from './components/SignUp';
import { Verification } from './components/Verification';
import StepFlow from 'components/StepFlow';
import { useComponentLogic } from './logics';
import { useDisplayType } from 'utils/display';
import { Recover } from './components/Recover';

const Login = () => {
  const Component = useComponentLogic();
  const { isDesktop, isMobile } = useDisplayType();
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        {isDesktop ? (
          <div className={styles.illustrationContainer}>
            <AppLogo />
            <div className={styles.imageContainer}>
              <div className={styles.imageFloater}>
                <img
                  className={styles.illustration1}
                  src={configure}
                  alt='Illustration'
                />
                <img
                  className={styles.illustration2}
                  src={liveStrategy}
                  alt='Illustration'
                />
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={
            isDesktop ? styles.onboardingContainer : styles.mOnboardingContainer
          }
        >
          {isMobile ? <AppLogo /> : null}
          <StepFlow
            currentStep={Component.State.currentStep}
            stepComponents={[
              <SignIn
                updateStep={Component.updateCurrentStep}
                loginFormValues={Component.State.loginFormValues}
                updateLoginForm={Component.State.updateLoginFormValues}
                onSubmit={Component.onSubmitLoginForm}
                onLoginWithFacebook={Component.onLoginWithFacebook}
                onLoginWithGoogle={Component.onLoginWithGoogle}
              />,
              <SignUp
                updateStep={Component.updateCurrentStep}
                signupFormValues={Component.State.signupFormValues}
                updateSignupForm={Component.State.updateSignupFormValues}
                onSubmit={Component.onSubmitSignupForm}
                onLoginWithFacebook={Component.onLoginWithFacebook}
                onLoginWithGoogle={Component.onLoginWithGoogle}
              />,
              <Verification
                code={Component.State.signupFormValues.code}
                onUpdateCode={Component.State.updateSignupFormValues}
                onSubmit={Component.onSubmitVerificationForm}
                resendOTP={Component.resendOTP}
                isLoading={Component.State.signupFormValues.submittingForm}
              />,
              <Recover
                updateStep={Component.updateCurrentStep}
                recoverFormValues={Component.State.recoverFormValues}
                updateRecoverForm={Component.State.updateRecoverFormValues}
                onSubmit={Component.onSubmitNewPassword}
                isLoading={Component.State.recoverFormValues.submittingForm}
                sendPasswordResetCode={Component.sendPasswordResetCode}
              />,
            ]}
          />
        </div>
      </div>
      {isDesktop && (
        <div className={styles.footer}>
          <a
            className={styles.footerlink}
            href={'https://www.uptrend.trade/terms'}
            target='_blank'
            rel='noreferrer'
          >
            Terms of Service
          </a>
          <a
            className={styles.footerlink}
            href={'https://www.uptrend.trade/privacy'}
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      )}
    </div>
  );
};

export default Login;
