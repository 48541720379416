import { NewsFeedItem, NewsSentimentSummary } from 'types/news';
import HTTP from 'utils/http';

export const getTopicNews = async (
  topicId: string,
  timeframe: string,
  last: number,
): Promise<NewsFeedItem[]> => {
  return HTTP.get(`/news/topic/${topicId}/${timeframe}`, {
    params: { last },
  }).then((response: any) => {
    return response.map((i: any) => i.data);
  });
};

export const getTopicNewsByRank = async (
  topicId: string,
  timeframe: string,
  ranks: number[],
): Promise<NewsFeedItem[]> => {
  return HTTP.get(`/news/topic/${topicId}/${timeframe}`, {
    params: { ranks: ranks.join(',') },
  }).then((response: any) => {
    return response.map((i: any) => i.data);
  });
};

export const getNewsSentimentChartData = async (
  topicId: string,
  timeframe: string,
  last: number,
): Promise<NewsSentimentSummary[]> => {
  return HTTP.get(`/news/chart/${topicId}/${timeframe}`, {
    params: { last },
  }).then((response: any) => {
    return response.map((i: any) => {
      return { ...i.data, timestamp: i.sortKey };
    });
  });
};

export const getLatestNewsSummary = async (
  topicId: string,
  timeframe: string,
): Promise<NewsSentimentSummary> => {
  return HTTP.get(`/news/summary/${topicId}/${timeframe}`);
};
