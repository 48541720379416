import PageHeader from 'components/PageHeader';
import styles from './settings.module.css';
import { SettingsContainer } from './components/SettingsContainer';
import { AccountSettings } from './components/AccountSettings';
import { ExchangeSettings } from './components/ExchangeSettings';
import { ManageAccount } from './components/ManageAccount';
import { useDisplayType } from 'utils/display';
import './customselect.css';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';
import { page_visit_settings } from 'constants/app/analyticsEvents';

const Settings = () => {
  const { isDesktop } = useDisplayType();

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_settings);
  }, []);

  return (
    <div className={styles.container}>
      <PageHeader
        title='Settings'
        subTitle='Manage your Account & Exchange Settings.'
        showBackButton={false}
      />
      <div className={isDesktop ? styles.contents : styles.contentsMobile}>
        <SettingsContainer
          header={'Account Settings'}
          settingsComponent={<AccountSettings />}
        />
        <SettingsContainer
          header={'Exchange Settings'}
          settingsComponent={<ExchangeSettings />}
        />
        <SettingsContainer
          header={'Manage Account'}
          settingsComponent={<ManageAccount />}
        />
      </div>
    </div>
  );
};

export default Settings;
