// App Authentication Events
export const event_login = 'Login';
export const event_logout = 'Logout';
export const event_signup = 'Sign Up';
export const event_verified_email = 'Email Verified';
export const page_visit_login = 'Visit - Login Screen';
export const page_visit_signup = 'Visit - Sign Up Screen';
export const page_visit_verification = 'Visit - Verification Screen';
export const page_visit_recover = 'Visit - Forgot Password Screen';
export const success_password_update = 'Success - Update Password';
export const onboarding_slides_displayed = 'Onboarding Slides - Displayed';
export const onboarding_slides_completed = 'Onboarding Slides - Completed';

// Markets Screen Events
export const page_markets = 'Markets Screen';
export const page_visit_markets = 'Visit - Markets Screen';
export const click_create_strategy = 'Click - Create Startegy';
export const click_watch_symbol = 'Click - Watch Symbol';
export const click_unwatch_symbol = 'Click - Unwatch Symbol';
export const click_watchlist_symbol = 'Click - Watchlist Symbol';
export const select_symbol = 'Select - Symbol';
export const select_exchange = 'Select - Exchange';
export const click_markets_tab_prefix = 'Click - Tab';

// Settings Screen Events
export const page_settings = 'Settings Screen';
export const page_visit_settings = 'Visit - Settings Screen';
export const click_manage_billing = 'Click - Manage Billing';
export const success_update_currency = 'Success - Update Currency';
export const success_delete_exchange = 'Success - Delete Exchange';
export const click_delete_account = 'Click - Delete Account';

// Portfolio Screen Events
export const page_portfolio = 'Portfolio Screen';
export const page_visit_portfolio = 'Visit - Portfolio Screen';

// Startegies Screen Events
export const page_strategies = 'Strategies Screen';
export const page_visit_strategies = 'Visit - Strategies Screen';
export const click_select_strategy = 'Click - Select Startegy';
export const click_create_new = 'Click - Create New';

// Startegy Configure Screen Events
export const page_str_configure = 'Strategy Configure Screen';
export const page_visit_str_configure = 'Visit - Strategies Configure Screen';
export const success_text_to_cond = 'Success - Text to Condition';
export const fail_text_to_cond = 'Fail - Text to Condition';

// Startegy Backtest Screen Events
export const page_str_backtest = 'Strategy Backtest Screen';
export const page_visit_str_backtest = 'Visit - Strategy Backtest Screen';
export const success_run_backtest = 'Success - Run Backtest';
export const fail_run_backtest = 'Fail - Run Backtest';
export const action_save_strategy = 'Action -  Strategy Saved';
export const action_deploy_strategy = 'Action - Strategy Deployed';
export const action_save_update_strategy = 'Action - Save/Update Strategy';
export const action_stop_live_strategy = 'Action - Stop Live Strategy';

// Startegy Live Screen Events
export const page_str_live = 'Strategy Live Screen';
export const page_visit_str_live = 'Visit - Strategy Live Screen';

// Assistant Events
export const page_assistant = 'Assistant Chat';
export const open_assistant = 'Open - Assistant Chat';
export const close_assistant = 'Close - Assistant Chat';
export const click_share_startegy = 'Assistant - Share Strategy';
export const click_share_backtest = 'Assistant - Share Backtest';
export const click_assistant_send = 'Assistant - Click Send';

// Subscription Events
export const page_subscription = 'Subscriptions Screen';
export const open_pricing_table = 'Open - Pricing Table';
export const close_pricing_table = 'Close - Pricing Table';
export const click_subscribe = 'Click - Subscribe';
export const upgrade_popup_displayed = 'Upgrade - Popup Displayed';
