import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';
import { useApplicationContainer } from 'containers/application';
import { OnCloseActionType, SubscriptionConfirmationProps } from './types';
import { useHistory } from 'react-router-dom';

export const SubscriptionConfirmation = (
  props: SubscriptionConfirmationProps,
) => {
  const { data } = props;
  const AppContainer = useApplicationContainer();
  const history = useHistory();

  const onClose = () => {
    if (data?.onCloseAction === OnCloseActionType.Close) {
      AppContainer.hideSubscriptionConfirmation();
    }
    if (data?.onCloseAction === OnCloseActionType.CloseAndGoBack) {
      AppContainer.hideSubscriptionConfirmation();
      history.goBack();
    }
  };

  return (
    <ConfirmationBottomSheet
      show={data?.showConfirmation ?? false}
      header={'Upgrade Plan'}
      subtitle={'You need to upgrade your plan.'}
      message={
        data?.message ??
        'This feature is not available in your current plan. Please upgrade your plan to access this feature.'
      }
      onCancel={onClose}
      onConfim={() => {
        onClose();
        AppContainer.showPricingTable(true);
      }}
      cancelText={'Cancel'}
      confirmText={'See Plans'}
    />
  );
};
