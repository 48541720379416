import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import commonStyles from '../formsCommon.module.css';
import Select from 'react-select';
import { STOP_LOSS_TYPES, TRAIL_STOP_VALUE_TYPES } from 'constants/app/app';
import { RiskManagementFormTypes } from './types';

const RiskManagementForm = (props: RiskManagementFormTypes) => {
  const { basicParams, updateBasicParams } = props;
  const isTrailingDisabled = basicParams.stopLossType?.value === null;
  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.header}>Risk Management</div>
        <div className={commonStyles.table}>
          <div className={commonStyles.row}>
            <div className={commonStyles.paramName}>Stop Loss Type</div>
            <div className={commonStyles.paramValue}>
              <Select
                value={basicParams.stopLossType}
                className={'paramsSelect'}
                classNamePrefix='headerMenuSelect'
                options={STOP_LOSS_TYPES}
                // @ts-ignore
                onChange={(s: SelectOption) => {
                  updateBasicParams({
                    type: 'stopLossType',
                    value: s,
                  });
                }}
              />
            </div>
          </div>
          <div className={commonStyles.row}>
            <div className={commonStyles.paramName}>Stop Loss Value Type</div>
            <div
              className={`${commonStyles.paramValue} ${
                isTrailingDisabled ? commonStyles.disabledParamValue : ''
              }`}
            >
              <Select
                isDisabled={isTrailingDisabled}
                value={basicParams.stopLossValueType}
                className={'paramsSelect'}
                classNamePrefix='headerMenuSelect'
                options={TRAIL_STOP_VALUE_TYPES}
                // @ts-ignore
                onChange={(s: SelectOption) => {
                  updateBasicParams({
                    type: 'stopLossValueType',
                    value: s,
                  });
                }}
              />
            </div>
          </div>
          <div className={commonStyles.row}>
            <div className={commonStyles.paramName}>Value (Amount)</div>
            <div
              className={`${commonStyles.paramValue} ${
                isTrailingDisabled ? commonStyles.disabledParamValue : ''
              }`}
            >
              <TextInput
                isDisabled={isTrailingDisabled}
                inputClass={commonStyles.basicParamInput}
                value={basicParams.stopLossValue}
                type={InputTypes.NUMBER}
                onChange={(v) => {
                  updateBasicParams({
                    type: 'stopLossValue',
                    value: v,
                  });
                }}
                renderEmptyErrorSpan={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskManagementForm;
