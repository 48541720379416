import {
  getLatestNewsSummary,
  getNewsSentimentChartData,
  getTopicNews,
  getTopicNewsByRank,
} from 'apis/news';
import { SelectedTrendingRecord } from 'types/news';
import { useComponentState } from './state';

export const useComponentLogic = () => {
  const State = useComponentState();

  const fetchNewsForTopic = async (topicId: string) => {
    // Determine key to fetch & update
    const timeframe = State.newsTimeframe;
    const key = `${topicId}_${timeframe}`;

    // check if paginated data sould be fetched
    if (key in State.newsItems && !State.newsItems[key].fetchMore) {
      return;
    }

    // Let Loading boolean
    State.updateNewsFeed({
      type: 'SET_LOADING',
      payload: { key, isLoading: true },
    });

    // Determine last key for pagination
    let last = 0;
    if (key in State.newsItems && State.newsItems[key].data.length) {
      last =
        State.newsItems[key].data[State.newsItems[key].data.length - 1].rank;
    }

    // fetch additional data along with the first call;
    if (last === 0) {
      // fetchSentimentChart(topicId);
      fetchLatestNewsSummary(topicId);
    }

    // fetch news via API
    const news = await getTopicNews(topicId, timeframe, last);

    // Check if paginated news is fetched
    if (news.length) {
      // update News data in State
      const oldState = State.newsItems;
      State.updateNewsFeed({
        type: 'SET_NEWS_ITEMS',
        payload: {
          key,
          data: last ? [...oldState[key].data, ...news] : news,
        },
      });
    } else {
      // Unset loading boolean
      State.updateNewsFeed({
        type: 'SET_FETCH_MORE',
        payload: { key, fetchMore: false },
      });
    }

    // Unset loading boolean
    State.updateNewsFeed({
      type: 'SET_LOADING',
      payload: { key, isLoading: false },
    });
  };

  const fetchSentimentChart = async (topicId: string) => {
    // Determine key to fetch & update
    const timeframe = State.newsTimeframe;
    const key = `${topicId}_${timeframe}`;
    try {
      const chartData = await getNewsSentimentChartData(topicId, timeframe, 0);
      // Set Chart data for key in state
      State.updateNewsFeed({
        type: 'SET_SENTIMENT_CHART_DATA',
        payload: { key, sentimentChartData: chartData },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchLatestNewsSummary = async (topicId: string) => {
    // Determine key to fetch & update
    const timeframe = State.newsTimeframe;
    const key = `${topicId}_${timeframe}`;
    try {
      const summary = await getLatestNewsSummary(topicId, timeframe);
      // Set Summary data for key in state
      State.updateNewsFeed({
        type: 'SET_SUMMARY',
        payload: { key, summary: summary },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSelectTrendingTopic = async (
    topicId: string,
    search: SelectedTrendingRecord,
  ) => {
    // Determine key to fetch & update
    const timeframe = State.newsTimeframe;
    const key = `${topicId}_${timeframe}`;
    State.updateNewsFeed({
      type: 'SET_TRENDING_SELECTED',
      payload: { key, selectedTrending: search },
    });

    try {
      const news = await getTopicNewsByRank(topicId, timeframe, search.ranks);
      State.updateNewsFeed({
        type: 'SET_TRENDING_SEARCH_DATA',
        payload: { key, trendingSearchData: news },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const clearTrendingSelect = (topicId: string) => {
    // Determine key to fetch & update
    const timeframe = State.newsTimeframe;
    const key = `${topicId}_${timeframe}`;
    State.updateNewsFeed({
      type: 'SET_TRENDING_SELECTED',
      payload: { key, selectedTrending: null },
    });
  };

  return {
    State,
    fetchNewsForTopic,
    onSelectTrendingTopic,
    clearTrendingSelect,
  };
};
