import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import styles from './onboarding.module.css';
import { Pagination } from 'swiper/modules';
import { OnboardingSlide } from './components/OnboardingSlide';
import configure from 'assets/images/onboarding_configure.png';
import backtest from 'assets/images/onboarding_backtest.png';
import live from 'assets/images/onboarding_live.png';
import assistant from 'assets/images/onboarding_assistant.png';
import portfolio from 'assets/images/onboarding_portfolio.png';
import notification from 'assets/images/onboarding_notification.png';
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useRef, useState } from 'react';
import PushButton from 'components/PushButton';
import { AiOutlineClose } from 'react-icons/ai';
import { PushButtonType } from 'components/PushButton/types';
import { useDisplayType } from 'utils/display';
import { useApplicationContainer } from 'containers/application';
import { Analytics } from 'utils/analytics';
import { onboarding_slides_displayed } from 'constants/app/analyticsEvents';
import AppLogo from 'components/AppLogo';

export const Onboarding = () => {
  const { isDesktop } = useDisplayType();
  const swiperRef = useRef<SwiperRef>(null);
  const [lastSlide, setLastSlide] = useState(false);
  const App = useApplicationContainer();

  useEffect(() => {
    Analytics.track(onboarding_slides_displayed);
  }, []);

  const onContinue = () => {
    if (lastSlide) {
      App.hideOnboardingSlides('Seen');
    } else {
      swiperRef.current?.swiper.slideNext();
    }
  };

  const Actions = (
    <div
      className={
        isDesktop ? styles.actionsContainer : styles.mobileActionsContainer
      }
    >
      {!lastSlide && (
        <PushButton
          type={isDesktop ? PushButtonType.Secondary : PushButtonType.Primary}
          title={'Skip'}
          onClick={() => {
            App.hideOnboardingSlides('Skipped');
          }}
          disabled={false}
          additionalClassName={
            isDesktop ? styles.actionButton : styles.mactionButton
          }
        />
      )}
      <PushButton
        title={lastSlide ? 'Continue' : 'Next '}
        onClick={onContinue}
        disabled={false}
      />
    </div>
  );

  const slides = [
    {
      header: 'No Code, Use plain text',
      explainer:
        'Create powerful trading strategies without writing a single line of code.',
      description:
        'Develop trading strategies using everyday language. Select your desired technical indicators and write your buy and sell criteria in simple, easy-to-understand English.',
      image: configure,
      actions: Actions,
    },
    {
      header: 'Backtest & Paper trade',
      explainer:
        'Backtest your strategies, validate them risk-free with paper trading.',
      description:
        'Say goodbye to emotional trading and hello to precision. Remove the human element, ensuring that your trading strategies are based on data-driven, unbiased signals.',
      image: backtest,
      actions: Actions,
    },
    {
      header: 'Go live with-in minutes',
      explainer:
        'Create, backtest, validate and finally take your strategy live.',
      description:
        'Launch in minutes: create, test, validate, and deploy trading strategies. Trade with precision using data-driven, unbiased signals. Say goodbye to emotional trading.',
      image: live,
      actions: Actions,
    },
    {
      header: 'Instant trade alerts.',
      explainer: 'Real-time push notifications for every trade and PNL update.',
      description:
        'Receive instant alerts for every trade and PNL update. Keep your trading on track with timely, data-driven notifications, ensuring you never miss an opportunity.',
      image: notification,
      actions: Actions,
    },
    {
      header: 'Smart strategy assistant',
      explainer:
        'Integrated with a powerful AI assistant to enhance your strategies.',
      description:
        'Strategy Assistant helps you define new trading strategies, improve existing ones, recommend suitable indicators, interpret backtest results, and advise on risk management techniques.',
      image: assistant,
      actions: Actions,
    },
    {
      header: 'Unified portfolio tracking',
      explainer:
        'Monitor your investments across multiple exchanges in real-time.',
      description:
        'Effortlessly track your investments across various exchanges in real-time. Stay informed with our unified portfolio tracking, ensuring you have a complete view of your assets at all times.',
      image: portfolio,
      actions: Actions,
    },
  ];

  return (
    <div className={styles.container}>
      {isDesktop && (
        <div className={styles.topbar}>
          <AppLogo />
        </div>
      )}
      <div className={styles.swiperContainer}>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          ref={swiperRef}
          modules={[Pagination]}
          className={styles.swiper}
          onReachEnd={() => setLastSlide(true)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <OnboardingSlide
                header={slide.header}
                explainer={slide.explainer}
                description={slide.description}
                image={slide.image}
                actions={slide.actions}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
