import { useReducer, useState } from 'react';
import { UseReducerAction } from 'types/app';
import {
  LoginFormParameters,
  OnboardingSteps,
  SignupFormParameters,
  RecoverFormParameters,
} from './types';

export const useComponentState = () => {
  const [currentStep, setCurentStep] = useState<OnboardingSteps>(0);

  // Login Form State & Reducer
  const loginFormReducer = (
    state: LoginFormParameters,
    action: UseReducerAction,
  ): LoginFormParameters => {
    return {
      ...state,
      [action.type]: action.value,
    };
  };

  const [loginFormValues, updateLoginFormValues] = useReducer(
    loginFormReducer,
    { email: '', password: '', submittingForm: false },
  );

  // Sign Up Form State & Reducer
  const signupFormReducer = (
    state: SignupFormParameters,
    action: UseReducerAction,
  ): SignupFormParameters => {
    return {
      ...state,
      [action.type]: action.value,
    };
  };

  const [signupFormValues, updateSignupFormValues] = useReducer(
    signupFormReducer,
    {
      email: '',
      password: '',
      rePassword: '',
      code: '',
      submittingForm: false,
    },
  );

  // Sign Up Form State & Reducer
  const recoverFormReducer = (
    state: RecoverFormParameters,
    action: UseReducerAction,
  ): RecoverFormParameters => {
    return {
      ...state,
      [action.type]: action.value,
    };
  };

  const [recoverFormValues, updateRecoverFormValues] = useReducer(
    recoverFormReducer,
    {
      email: '',
      password: '',
      code: '',
      submittingForm: false,
    },
  );

  return {
    currentStep,
    setCurentStep,
    loginFormValues,
    updateLoginFormValues,
    signupFormValues,
    updateSignupFormValues,
    recoverFormValues,
    updateRecoverFormValues,
  };
};
