import { useCallback, useEffect } from 'react';
import { useComponentState } from './state';
import { LiveRunProps } from './types';
import { StrategyState } from 'views/Strategy/state';
import { getLiveStrategyEvents, getLiveStrategySummary } from 'apis/startegies';
import {
  TICKSIZE_IN_SEC,
  LIVE_HISTORY_TICK_LIMIT,
  LIVE_TIMELINE_EVENTS_LIMIT,
} from 'constants/app/app';
import { ChartPriceCandleData } from 'types/chart';
import { getCandleSticksData } from 'apis/exchange';
import { IndicatorInfo } from 'types/strategies';
import { formatLiveChartData } from 'utils/formatters/ohlcv';
import { isDemo } from 'utils/demo';

export const useComponentLogic = (props: LiveRunProps) => {
  const State = useComponentState();
  const strategyState = StrategyState.get();
  const { selectedStrategy, updateMarketStructure } = props;
  const { summary, isLoadingTimeline, liveStrategyEvents } = strategyState;

  const handleScrollEnd = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      // @ts-ignore
      e.target.clientHeight + e.target.scrollTop > e.target.scrollHeight - 5;
    if (bottom && !isLoadingTimeline) {
      fetchStrategyTimeline(false);
    }
  };

  // fetch paginated events for timeline
  const fetchStrategyTimeline = async (refresh: boolean) => {
    if (strategyState.fetchMoreTimelineEvents || refresh) {
      try {
        strategyState.setIsLoadingTimeline(true);
        const lastLen = refresh ? 0 : strategyState.liveStrategyEvents.length;
        const last = lastLen
          ? strategyState.liveStrategyEvents[lastLen - 1].timestamp
          : undefined;
        const events = await getLiveStrategyEvents(
          selectedStrategy?.eventsHash as string,
          LIVE_TIMELINE_EVENTS_LIMIT,
          last,
        );
        if (events.length) {
          strategyState.setLiveStrategyEvents(
            last ? [...strategyState.liveStrategyEvents, ...events] : events,
          );
        } else {
          strategyState.setFetchMoreTimelineEvents(false);
        }
        if (isDemo) {
          strategyState.setFetchMoreTimelineEvents(false);
        }
        strategyState.setIsLoadingTimeline(false);
      } catch (error) {
        console.log(error);
        strategyState.setIsLoadingTimeline(false);
      }
    }
  };

  // Live //
  // fetch candlesticks for live strategy
  const fetchLiveChartData = useCallback(
    (last?: number): Promise<ChartPriceCandleData | void> => {
      strategyState.setIsLoadingLiveHistory(true);
      const date = new Date();
      const offset = date.getTimezoneOffset();
      last = last ? last + offset * 60 : undefined;
      const since = last
        ? (last -
            LIVE_HISTORY_TICK_LIMIT *
              // @ts-ignore
              TICKSIZE_IN_SEC[selectedStrategy?.cron_interval]) *
          1000
        : undefined;
      // @ts-ignore
      const tickSizeSec = TICKSIZE_IN_SEC[selectedStrategy?.cron_interval];
      return getCandleSticksData(
        selectedStrategy?.eventsHash as string,
        selectedStrategy?.symbols_info.exchange_id as string,
        `${selectedStrategy?.symbols_info.base_currency}/${selectedStrategy?.symbols_info.quote_currency}`,
        selectedStrategy?.cron_interval as string,
        LIVE_HISTORY_TICK_LIMIT,
        since,
        tickSizeSec,
        selectedStrategy?.strategy.indicators as IndicatorInfo[],
      )
        .then((res) => {
          // TODO:
          // Extract prepend to util functions
          const fData = formatLiveChartData(res);
          // Handle cData
          const oldCdata = strategyState.liveChartData?.cData || [];
          const cData = !since ? fData.ohlcv : [...fData.ohlcv, ...oldCdata];

          // Handle Indicator Plots (iData)
          const oldPlotInfo = strategyState.liveChartData?.iData || {};
          if (since) {
            for (let ind in fData.plotInfo) {
              fData.plotInfo[ind].forEach((iLineInfo: any, index) => {
                fData.plotInfo[ind][index].data = [
                  ...iLineInfo.data,
                  ...oldPlotInfo[ind][index].data,
                ];
              });
            }
          }

          // Handle Volume Data (vData)
          const oldVdata = strategyState.liveChartData?.vData || [];
          const vData = !since ? fData.vData : [...fData.vData, ...oldVdata];

          // Handle Orders Data (oData)
          const oldOdata = strategyState.liveChartData?.oData || [];
          const oData = !since ? fData.orders : [...fData.orders, ...oldOdata];

          // Handle Events Data (cEventsData)
          const oldEventsData = strategyState.liveChartData?.cEventsData || [];
          const cEventsData = !since
            ? fData.chartEvents
            : [...fData.chartEvents, ...oldEventsData];

          // Get current price
          const currentPrice = cData[cData.length - 1].close;

          // Get minMove if already available
          const minMove =
            strategyState.marketStructure?.limits?.price?.min ?? undefined;

          // get SL Data if already available
          const slData = strategyState.summary?.allocations?.stop_loss_order;

          // Update values in State
          strategyState.setLiveChartData({
            cData,
            oData,
            vData,
            slData,
            cEventsData,
            iData: fData.plotInfo,
            cDataMinMove: minMove,
          });
          strategyState.setCurrentPrice(currentPrice);
          return cData;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          strategyState.setIsLoadingLiveHistory(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      strategyState?.liveChartData?.cData,
      strategyState.marketStructure?.limits?.price?.min,
      selectedStrategy,
    ],
  );

  const refreshStrategySummary = () => {
    getLiveStrategySummary(selectedStrategy?.id as string)
      .then((summary) => {
        strategyState.setSummary(summary);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetch candlestics & orders on mount
  useEffect(() => {
    if (!strategyState.liveChartData && selectedStrategy) {
      fetchLiveChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy]);

  // fetch timeline events on mount
  useEffect(() => {
    if (!liveStrategyEvents.length && selectedStrategy) {
      fetchStrategyTimeline(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy]);

  // fetch summary & market structure on mount
  useEffect(() => {
    if (!summary && selectedStrategy) {
      refreshStrategySummary();
    }
    if (selectedStrategy) {
      updateMarketStructure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy]);

  // Refresh on Visibility Change
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const now = Date.now() / 1000;
        const nextRun = summary?.timelogs?.next_run_timestamp as number;
        let waitTime = Math.ceil(nextRun - now);
        if (waitTime <= 0) {
          refreshStrategySummary();
          fetchStrategyTimeline(true);
          fetchLiveChartData();
        }
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  // Set live data refresh Logic
  useEffect(() => {
    if (summary?.timelogs?.next_run_timestamp) {
      const now = Date.now() / 1000;
      const nextRun = summary?.timelogs?.next_run_timestamp as number;
      let waitTime = Math.ceil(nextRun - now) + 120;
      if (waitTime <= 0) {
        waitTime = 300;
      }
      const refreshTimer = setTimeout(() => {
        if (!document.hidden) {
          refreshStrategySummary();
          fetchStrategyTimeline(true);
          fetchLiveChartData();
        }
      }, waitTime * 1000);
      return () => clearTimeout(refreshTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  return {
    State,
    strategyState,
    handleScrollEnd,
    fetchStrategyTimeline,
    fetchLiveChartData,
  };
};
