import { useEffect } from 'react';
import { useComponentState } from './state';
import { BacktestInitBottomSheetProps, BacktestInitSteps } from './types';

export const useComponentLogic = (props: BacktestInitBottomSheetProps) => {
  const { onCancel, isOpen, updateMarketStructure } = props;
  const State = useComponentState();

  const goBack = () => {
    if (State.currentStep === BacktestInitSteps.Callendar) {
      State.setCurrentStep(BacktestInitSteps.Allocations);
    } else {
      onCancel();
    }
  };

  // Fetch market structure on init
  useEffect(() => {
    if (isOpen) {
      updateMarketStructure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return { State, goBack };
};
