import { IndicatorInfo } from 'types/strategies';
import HTTP from 'utils/http';
import { BasicStrategyParams } from 'views/Strategy/types';

export const runBacktest = async (inputs: {
  uid: string;
  basicParams: BasicStrategyParams;
  indicators: IndicatorInfo[];
  buyCondition: string;
  sellCondition: string;
}): Promise<void> => {
  const { basicParams, indicators, buyCondition, sellCondition, uid } = inputs;
  const symbolSplit = basicParams.symbol.split('/');
  var data = {
    userId: uid,
    strategy_type: 'DynamicIndicators',
    bt_parameters: {
      initial_cash: Number(basicParams.initialCash),
      start_date_time: `${basicParams.startDate
        .toLocaleString('en-GB')
        .replace(',', '')}`,
      commission: Number(basicParams.commission),
    },
    symbols_info: {
      exchange_id: basicParams.exchange,
      base_currency: symbolSplit[0],
      quote_currency: symbolSplit[1],
    },
    strategy: {
      name: basicParams.name,
      interval: basicParams.interval,
      indicators: indicators,
      buy_condition: buyCondition,
      sell_condition: sellCondition,
      fractional_sizing: true,
      sl_info: {
        stop_loss_type: basicParams.stopLossType?.value,
        stop_loss_value_type: basicParams.stopLossValueType?.value,
        stop_loss_value: Number(basicParams.stopLossValue),
      },
    },
  };

  return HTTP.post(
    `${process.env.REACT_APP_CLIMBER_BASE_URL}/run-backtest`,
    data,
  );
};
