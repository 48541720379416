import {
  BillingPortalData,
  CheckoutData,
  PricingTableData,
} from 'types/subscriptions';
import HTTP from 'utils/http';

export const getPricingTableData = async (
  currency: string,
): Promise<PricingTableData> => {
  return HTTP.get(`/user/plans/pricingTable`, { params: { currency } });
};

export const getCheckoutSession = async (
  priceId: string,
  customerEmail: string,
  currency: string,
  customerId?: string,
): Promise<CheckoutData> => {
  return HTTP.post(`/user/plans/createCheckoutSession`, {
    priceId,
    customerEmail,
    customerId,
    currency,
  });
};

export const getBillingPortalUrl = async (
  customerId: string,
): Promise<BillingPortalData> => {
  return HTTP.post(`/user/plans/createBillingPortalSession`, { customerId });
};
