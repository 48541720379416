import { putUserSettings } from 'apis/user';
import { useAuthContainer } from 'containers/auth';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { toast } from 'react-toastify';
import { UserSettings, UserSettingsUpdateObject } from 'types/user';
import { useComponentState } from './state';
import { getBillingPortalUrl } from 'apis/subscriptions';
import { openInAppBrowser } from 'utils/inAppBrowser';
import { Analytics } from 'utils/analytics';
import {
  success_update_currency,
  page_settings,
  click_manage_billing,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const AuthContainer = useAuthContainer();
  const CommonData = useUserCommonDataContainer();
  const State = useComponentState();

  // update user Settings
  const updateUserSettings = (updateKeys: UserSettingsUpdateObject) => {
    let updatedSettings = {
      ...CommonData.State.userSettings,
      ...updateKeys,
    };
    putUserSettings(updatedSettings as UserSettings)
      .then(() => {
        CommonData.State.setUserSettings(updatedSettings as UserSettings);
        toast.success('Settings Updated Successfully.');
        Analytics.track(success_update_currency, {
          screen: page_settings,
        });
      })
      .catch(() => {
        toast.error('Something went wrong, failed to update settings.');
      })
      .finally(() => {
        State.setTzToUpdate('');
        State.setCurrencyToUpdate('');
      });
  };

  const openBillingPortal = async () => {
    if (CommonData.State.userSettings?.plan?.isPlanActive) {
      const customerId = CommonData.State.userSettings?.plan?.customerId;
      if (customerId) {
        const { url } = await getBillingPortalUrl(customerId);
        openInAppBrowser(url);
      }
    }
    Analytics.track(click_manage_billing, {
      screen: page_settings,
    });
  };

  return {
    State,
    user: AuthContainer.State.appUser,
    userSettings: CommonData.State.userSettings,
    updateUserSettings,
    openBillingPortal,
  };
};
