import {
  AiOutlineBarChart,
  AiOutlineFundView,
  AiOutlineExperiment,
  // AiOutlineRead,
  AiOutlineSetting,
} from 'react-icons/ai';
import styles from './mainmenu.module.css';

export const menuItems = [
  // {
  //   id: '4',
  //   displayName: 'News',
  //   linkTo: '/app/news',
  //   icon: <AiOutlineRead className={styles.navIcon} />,
  // },
  {
    id: '1',
    displayName: 'Markets',
    linkTo: '/app/market',
    icon: <AiOutlineBarChart className={styles.navIcon} />,
  },
  {
    id: '2',
    displayName: 'Strategies',
    linkTo: '/app/strategies',
    icon: <AiOutlineExperiment className={styles.navIcon} />,
  },
  {
    id: '3',
    displayName: 'Portfolio',
    linkTo: '/app/portfolio/assets',
    icon: <AiOutlineFundView className={styles.navIcon} />,
  },
  {
    id: '5',
    displayName: 'Settings',
    linkTo: '/app/settings',
    icon: <AiOutlineSetting className={styles.navIcon} />,
  },
];

export const hideMenuPaths = ['/app/strategies/strategy/'];
