import styles from './change.module.css';

export type DailyChangeProps = {
  changeValue: number;
  changePercent: number;
  showPercent: boolean;
  subText?: string;
};

export const DailyChange = (props: DailyChangeProps) => {
  const { changeValue, changePercent, showPercent, subText } = props;
  const isPositive = changeValue >= 0;
  return (
    <div className={isPositive ? styles.pContainer : styles.lContainer}>
      <span className={styles.text}>{isPositive ? '+' : '-'}</span>
      <span className={styles.text}>{Math.abs(changeValue).toFixed(2)}</span>
      {showPercent && (
        <span className={styles.text}>
          ({Math.abs(changePercent).toFixed(2)}%)
        </span>
      )}
      {subText && <span className={styles.subText}>({subText})</span>}
    </div>
  );
};
