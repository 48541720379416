import { rest } from 'msw';
import { MockConfig, mockList } from './mockList';

const commonResponse =
  (config: MockConfig) => (req: any, res: any, ctx: any) => {
    return res(ctx.status(config.status), ctx.json(config.mockJson));
  };

const createHandler = (config: MockConfig) => {
  const responseHandler = commonResponse(config);
  console.log(`Mocking ${config.methodType} ${config.apiName}`);
  switch (config.methodType.toLowerCase()) {
    case 'get':
      return rest.get(config.apiName, responseHandler);
    case 'post':
      return rest.post(config.apiName, responseHandler);
    case 'put':
      return rest.put(config.apiName, responseHandler);
    case 'delete':
      return rest.delete(config.apiName, responseHandler);
    case 'patch':
      return rest.patch(config.apiName, responseHandler);
    default:
      throw new Error(`Unsupported method type: ${config.methodType}`);
  }
};

export const handlers = mockList
  .filter((config: MockConfig) => config.mock)
  .map((config: MockConfig) => createHandler(config));
