import { PUBLIC_URL } from 'utils/environment';
import styles from './symbollist.module.css';
import { SymbolListProps } from './types';

const SymbolList = (props: SymbolListProps) => {
  const { onSelectItem, list } = props;

  return (
    <div>
      <div className={styles.listContainer}>
        {list.map((symbol_info) => {
          return (
            <div
              className={styles.itemContainer}
              onClick={() => onSelectItem(symbol_info)}
              key={
                symbol_info.base_currency +
                symbol_info.quote_currency +
                symbol_info.exchange_id
              }
            >
              <div className={styles.icon}>
                <img
                  src={`${PUBLIC_URL}/icons/crypto/${symbol_info.base_currency.toLocaleLowerCase()}.svg`}
                  width={28}
                  alt={'Symbol Icon'}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
                  }}
                />
              </div>

              <div className={styles.infoContainer}>
                <div className={styles.text}>
                  {symbol_info.base_currency}/{symbol_info.quote_currency}
                </div>
                <div className={styles.subText}>
                  <div>Exchange: {symbol_info.exchange_id}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SymbolList;
