import { StrategyAllocationsProps } from './types';
import styles from './allocations.module.css';
import { AiOutlineReload } from 'react-icons/ai';
import Loader from 'components/Loader';
import { usePalette } from 'react-palette';
import { PUBLIC_URL } from 'utils/environment';

const StrategyAllocations = (props: StrategyAllocationsProps) => {
  const {
    allocations,
    currentPrice,
    marketStructure,
    refreshPrice,
    isRefreshing,
    symbol,
  } = props;

  const { data } = usePalette(
    `${PUBLIC_URL}/icons/crypto/${
      allocations?.position
        ? symbol.base_currency.toLocaleLowerCase()
        : symbol.quote_currency.toLocaleLowerCase()
    }.svg`,
  );

  // Ignore no allocations
  if (!allocations) return null;

  // Calculate current value of position
  let currentValueInQuoteCur = 0;
  let percentageGain = 0;
  if (allocations.position && currentPrice && marketStructure) {
    currentValueInQuoteCur = currentPrice * allocations.position.quantity;
    const positaionCost =
      allocations.position.avg_price * allocations.position.quantity;
    percentageGain =
      ((currentValueInQuoteCur - positaionCost) / positaionCost) * 100;
  }

  return (
    <div
      className={styles.container}
      style={
        data
          ? {
              background: `linear-gradient(15deg, ${data.darkVibrant}, ${data.lightVibrant})`,
              boxShadow: `0 1px 6px ${data.lightVibrant}`,
              color: '#fff',
            }
          : {}
      }
    >
      <div className={styles.header}>
        <span>{allocations.position ? 'Open Position' : 'Balance'}</span>
        {allocations.position ? (
          <span>
            {isRefreshing ? (
              <Loader bgColor='#fff' />
            ) : (
              <AiOutlineReload
                size={22}
                onClick={refreshPrice}
                className={styles.refresh}
              />
            )}
          </span>
        ) : (
          <span>No Open Position</span>
        )}
      </div>
      <div className={styles.descriptionContainer}>
        <span className={styles.leftSec}>
          <img
            src={`${PUBLIC_URL}/icons/crypto/${
              allocations.position
                ? symbol.base_currency.toLocaleLowerCase()
                : symbol.quote_currency.toLocaleLowerCase()
            }.svg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
            }}
            width={22}
            alt={'Symbol Icon'}
            className={styles.icon}
          />
          <span className={styles.amount}>
            {allocations.position
              ? `${allocations.position.quantity} ${symbol.base_currency}`
              : `${allocations.cash.available} ${symbol.quote_currency}`}
          </span>
        </span>
        <span className={styles.rightSec}>
          <span>Current Value</span>
          {allocations.position ? (
            <span>
              {`${
                currentValueInQuoteCur && marketStructure
                  ? currentValueInQuoteCur.toFixed(
                      marketStructure.precision.price,
                    )
                  : '-'
              } ${symbol.quote_currency} ${
                percentageGain
                  ? ` (${
                      percentageGain > 0 ? '+' : ''
                    } ${percentageGain.toFixed(2)}%)`
                  : ''
              }`}
            </span>
          ) : (
            <span>{`${allocations.cash.available} ${symbol.quote_currency}`}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default StrategyAllocations;
