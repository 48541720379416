import styles from './floatingButton.module.css';
import { FloatingButtonProps, FloatingButtonType } from './types';

const FloatingButton = (props: FloatingButtonProps) => {
  const {
    disabled,
    additionalClassName = '',
    onClick,
    type = FloatingButtonType.Primary,
    icon,
  } = props;
  let typeClass = styles.primaryButton;
  switch (type) {
    case FloatingButtonType.Primary:
      typeClass = styles.primaryButton;
      break;

    case FloatingButtonType.Secondary:
      typeClass = styles.secondaryButton;
      break;

    case FloatingButtonType.Danger:
      typeClass = styles.dangerButton;
      break;

    case FloatingButtonType.Grey:
      typeClass = styles.greyButton;
      break;

    case FloatingButtonType.SimpleText:
      typeClass = styles.simpleText;
      break;

    default:
      break;
  }
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${additionalClassName} ${styles.buttonBase} ${typeClass}`}
    >
      <div className={styles.baseIconOnly}>{icon}</div>
    </button>
  );
};

export default FloatingButton;
