import { NewsSummaryProps } from './types';
import styles from './summary.module.css';
import { SentimentChart } from 'components/SentimentChart';
import { useState } from 'react';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
// martin36.github.io/react-gauge-chart

export const NewsSummary = (props: NewsSummaryProps) => {
  const { data, chartData } = props;
  const [showFullText, setShowFullText] = useState(false);

  const handleShowMoreClick = () => {
    setShowFullText(true);
  };

  let sentimentClass = '';
  if (data?.averageSentiment && data?.averageSentiment > 0) {
    sentimentClass = 'positive';
  }
  if (data?.averageSentiment && data?.averageSentiment < 0) {
    sentimentClass = 'negative';
  }

  return data ? (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>Today's Outlook</div>
        {sentimentClass ? (
          <div>
            <span className={styles.sentimentTag}>
              <span className={styles.positive}>
                {((data.positive / data.totalItems) * 100).toFixed(2)}%
              </span>
            </span>
            &nbsp;
            <span className={styles.sentimentTag}>
              <span className={styles.negative}>
                {((data.negative / data.totalItems) * 100).toFixed(2)}%
              </span>
            </span>
          </div>
        ) : null}
      </div>
      <div className={styles.contents}>
        {/* {chartData && (
          <div className={styles.chartContainer}>
            <SentimentChart data={chartData} />
          </div>
        )} */}
        {data.summary?.outlookSummary && (
          <div className={styles.textContainer}>
            <p>{data.summary.outlookSummary}</p>
            {/* {data.summary.outlookSummary.length > 200 && !showFullText ? (
              <>
                <p>{data.summary.outlookSummary.substring(0, 150)}...</p>
                <div className={styles.buttonContainer}>
                  <PushButton
                    title={'Read Summary'}
                    onClick={handleShowMoreClick}
                    type={PushButtonType.Secondary}
                  />
                </div>
              </>
            ) : (
              <p>{data.summary.outlookSummary}</p>
            )} */}
          </div>
        )}
      </div>
    </div>
  ) : null;
};
