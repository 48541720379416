import PushButton from 'components/PushButton';
import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import sharedStyles from '../shared.module.css';
import inputStyles from 'components/TextInput/styleVariants/loginInputs.module.css';
import { useDisplayType } from 'utils/display';
import { VerificationProps } from './types';
import { useCountdownTimer } from 'utils/hooks/resendOtpTimer';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';
import { page_visit_verification } from 'constants/app/analyticsEvents';

export const Verification = (props: VerificationProps) => {
  const { onSubmit, code, onUpdateCode, resendOTP, isLoading } = props;
  const { isDesktop } = useDisplayType();
  const { timeRemaining, restart } = useCountdownTimer(30);
  const requestCode = () => {
    resendOTP();
    restart();
  };

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_verification);
  }, []);

  return (
    <div
      className={
        isDesktop ? sharedStyles.container : sharedStyles.mobileContainer
      }
    >
      <div
        className={
          isDesktop ? sharedStyles.formContainer : sharedStyles.mFormContainer
        }
      >
        <div className={sharedStyles.header}>
          <span className={sharedStyles.headerText}>Verification</span>
          <span className={sharedStyles.welcomeText}>Just a step away!</span>
          <span className={sharedStyles.welcomeText}>
            Please enter the verification code sent to your e-mail.
          </span>
        </div>

        <div className={sharedStyles.inputsContainer}>
          <TextInput
            type={InputTypes.PASSWORD}
            label={'Verification Code'}
            value={code}
            placeholder={'Enter code here'}
            onChange={(s) =>
              onUpdateCode({
                type: 'code',
                value: s,
              })
            }
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
        </div>
        <div className={sharedStyles.mainActionContainer}>
          <span className={sharedStyles.actionText}>
            Not received?{' '}
            {timeRemaining > 0 ? (
              <span className={sharedStyles.actionLinkTimer}>
                Resend in {timeRemaining} Seconds
              </span>
            ) : (
              <span onClick={requestCode} className={sharedStyles.actionLink}>
                Resend
              </span>
            )}
          </span>
          <PushButton
            disabled={isLoading}
            title={isLoading ? 'Verifying...' : 'Verify'}
            additionalClassName={sharedStyles.submitButton}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};
