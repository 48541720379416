import React, { useState } from 'react';
import styles from './input.module.css';
import { TextInputProps, InputTypes } from './types';

const TextInput = (props: TextInputProps) => {
  const {
    type,
    value,
    label,
    onChange,
    maxlength,
    error,
    inputClass = '',
    containerClass = '',
    labelClass = '',
    onFocus,
    placeholder = '',
    renderEmptyErrorSpan = true,
    isDisabled = false,
    autoComplete = true,
    regExp: regExpValidation,
  } = props;
  const NUMERIC_INPUT_REGEXP = /^[0-9]*[.]*[0-9]*$/;
  const [touched, setTouched] = useState(false);

  const onBlur = () => {
    setTouched(!!value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    if (type === InputTypes.NUMBER && val !== '') {
      let regex = RegExp(NUMERIC_INPUT_REGEXP);
      const match = regex.test(val);
      if (!match) return;
    }
    if (regExpValidation && val !== '') {
      let regex = RegExp(regExpValidation);
      const match = regex.test(val);
      if (!match) return;
    }
    onChange(val);
  };

  const onFocusChange = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const getInputMode = () => {
    switch (type) {
      case InputTypes.TEXT:
        return 'text';

      case InputTypes.NUMBER:
        return 'tel';

      default:
        return 'text';
    }
  };

  const displayError = error && touched;

  const show = displayError || renderEmptyErrorSpan;

  return (
    <div className={`${styles.container} ${containerClass}`}>
      {label && <span className={labelClass}>{label}</span>}
      <input
        disabled={isDisabled}
        maxLength={maxlength}
        className={`${
          error && touched ? styles.inputError : styles.input
        } ${inputClass}`}
        type={type === InputTypes.PASSWORD ? 'password' : 'text'}
        value={value}
        onBlur={onBlur}
        inputMode={getInputMode()}
        onChange={handleChange}
        spellCheck='false'
        onFocus={onFocusChange}
        placeholder={placeholder}
        autoComplete={autoComplete ? 'on' : 'new-password'}
      />
      {show && (
        <span className={styles.error}>{displayError ? error : ''}</span>
      )}
    </div>
  );
};

export default TextInput;
