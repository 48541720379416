import { useAuthContainer } from 'containers/auth';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { appHeight } from 'utils/display';
import Login from 'views/Login';

export const useRoutingLogic = () => {
  const AuthContainer = useAuthContainer();
  const AuthContainerState = AuthContainer.State;
  const isAuthenticated = AuthContainerState.isAuthenticated;

  const loginRouting = () => {
    if (isAuthenticated) return <Redirect to={'/app/market'} />;
    return <Login />;
  };

  // Check for auth status of refresh
  useEffect(() => {
    AuthContainer.fetchAndUpdateAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update app-caontainer height to innerHeight
  useEffect(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  return {
    loginRouting,
  };
};
