export const user_settings = {
  portfolioCurrency: 'usd',
  defaultTimeZone: 'ist',
  ip: '178.171.18.xxx',
  plan: {
    customerId: 'cus_demo',
    productId: 'prod_PU4c9ZsU3Lr24L',
    subscriptionId: 'sub_demo',
    isPlanActive: true,
    currentPeriodEnd: 1715002932,
    planDetails: {
      displayName: 'Elite Trader Plan',
      maxStrategies: 10,
      maxIndicators: 6,
      maxExchanges: 3,
      supportedTimeframes: [
        '1w',
        '1d',
        '12h',
        '6h',
        '4h',
        '2h',
        '1h',
        '30m',
        '15m',
      ],
      maxBacktestsPerHour: 60,
      portfolioTracking: true,
      dedicatedStaticIP: true,
      strategyAssistant: true,
    },
  },
};
