import { runBacktest } from 'apis/backtest';
import { GRANULARITIES } from 'constants/app/app';
import { supportedExchanges } from 'constants/app/exchange';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SelectGroup, UseReducerAction } from 'types/app';
import { BasicStrategyParamsInfoType } from './types';
import { toast } from 'react-toastify';
import { useExchangeContainer } from 'containers/exchange';
import { getConditionFromText } from 'apis/startegies';
import { useAuthContainer } from 'containers/auth';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { IndicatorInfo } from 'types/strategies';
import { getBacktestResultsFromS3 } from 'utils/s3';
import { useApplicationContainer } from 'containers/application';
import { INDICATOR_GROUPS, INDICATORS_INFO } from 'constants/app/indicators';
import { OnCloseActionType } from 'components/SubscriptionConfirmation/types';
import { StrategyState } from 'views/Strategy/state';
import { MovesTypes } from 'views/Strategy/types';
import { Analytics } from 'utils/analytics';
import {
  fail_run_backtest,
  fail_text_to_cond,
  page_str_configure,
  success_run_backtest,
  success_text_to_cond,
} from 'constants/app/analyticsEvents';

export const useComponentLogics = () => {
  const Exchange = useExchangeContainer();
  const CommonDataContainer = useUserCommonDataContainer();
  const Auth = useAuthContainer();
  const AppContainer = useApplicationContainer();
  const State = StrategyState.get();

  // @ts-ignore
  let { strategyId } = useParams();
  if (strategyId === 'configure') strategyId = undefined;
  const symbolOptions = useCallback(() => {
    return Exchange.State.exchangeSymbols.map((s) => {
      return { value: s, label: s };
    });
  }, [Exchange.State.exchangeSymbols]);

  // BasicStrategyParamsInfo Render Options
  const basicStrategyParamsInfo: BasicStrategyParamsInfoType[] = [
    {
      displayName: 'Symbol',
      id: 'symbol',
      inputType: 'SELECT',
      selectOptions: symbolOptions(),
    },
    {
      displayName: 'Run Interval',
      id: 'interval',
      inputType: 'SELECT',
      selectOptions: GRANULARITIES,
    },
    {
      displayName: 'Exchange',
      id: 'exchange',
      inputType: 'SELECT',
      selectOptions: supportedExchanges,
    },
  ];

  const checkIntervalAccess = (i: string) => {
    const hasAccess =
      CommonDataContainer.State.userSettings?.plan?.planDetails?.supportedTimeframes.includes(
        i,
      );
    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `The selected interval (${i}) is not supported in your current plan. Please upgrade your plan to access this interval.`,
        screen: page_str_configure,
        reason: 'noIntervalAccess',
      });
      return false;
    } else {
      AppContainer.hideSubscriptionConfirmation();
      return true;
    }
  };

  const checkAddIndicatorAccess = () => {
    const maxIndicators =
      CommonDataContainer.State.userSettings?.plan?.planDetails
        ?.maxIndicators ?? 1;
    const hasAccess = State.strategyIndicators.length + 1 <= maxIndicators;
    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `You have reached the maximum limit of indicators for your current plan. Please upgrade your plan to add more indicators.`,
        screen: page_str_configure,
        reason: 'maxIndicators',
      });
      return false;
    } else {
      AppContainer.hideSubscriptionConfirmation();
      return true;
    }
  };

  const updateBasacParamInfo = (data: UseReducerAction) => {
    const { type, value } = data;

    // Check if user has access to selected interval (if timeframe is updated)
    if (type === 'interval' && !checkIntervalAccess(value)) {
      return;
    }
    State.updateBasicParams({ type: type, value });
  };

  // Execute backtest and save results
  const getBacktestResults = async () => {
    try {
      State.setIsLoadingResult(true);
      await runBacktest({
        uid: Auth.State.appUser.sub,
        basicParams: State.basicParams,
        indicators: State.strategyIndicators,
        buyCondition: State.buyCondition,
        sellCondition: State.sellCondition,
      });
      const btResults = await getBacktestResultsFromS3(Auth.State.appUser.sub);
      if (State.marketStructure) {
        btResults.chartData.cDataMinMove =
          State.marketStructure?.limits?.price?.min;
      }

      // Add bactest date-ranges to chart data
      btResults.chartData.backtestDateRange = {
        start: new Date(btResults.chartData.cData[0].time * 1000),
        end: new Date(
          btResults.chartData.cData[btResults.chartData.cData.length - 1].time *
            1000,
        ),
      };

      State.setResultChartData(btResults.chartData);
      State.setResultAnalysisData(btResults.analysis);
      State.setIsLoadingResult(false);
      Analytics.track(success_run_backtest, {
        screen: page_str_configure,
      });
    } catch (error: any) {
      console.log(error);
      const response = error?.response?.data;
      State.setIsLoadingResult(false);
      if (response?.type === 'BacktestLimitException') {
        AppContainer.showSubscriptionConfirmation({
          showConfirmation: true,
          onCloseAction: OnCloseActionType.Close,
          message:
            'You have reached the limit of free backtests, please upgrade your plan to run more backtests.',
          screen: page_str_configure,
          reason: 'BacktestLimitException',
        });
      } else {
        toast.error(response?.message ?? 'Backtest failed with an error!');
      }
      Analytics.track(fail_run_backtest, {
        screen: page_str_configure,
        uid: Auth.State.appUser.sub,
        basicParams: JSON.stringify(State.basicParams),
        indicators: JSON.stringify(State.strategyIndicators),
        buyCondition: JSON.stringify(State.buyCondition),
        sellCondition: JSON.stringify(State.sellCondition),
        error: error?.message ?? 'Unknown Error',
      });
    }
  };

  // fetch indicators and save results
  const fetchIndicators = async () => {
    // Format indicator Groups for Select Input
    const groups: SelectGroup[] = [];
    for (let key in INDICATOR_GROUPS) {
      // @ts-ignore
      const groupData = INDICATOR_GROUPS[key].map((f) => {
        return {
          value: f.name,
          label: `${f.display_name} (${f.name})`,
        };
      });
      groups.push({
        label: key,
        options: groupData,
      });
    }
    State.setIndicatorFunctions(groups);
  };

  // On remove Indicator from strategy
  const removeIndicator = (id: string) => {
    const newIndicators = State.strategyIndicators.filter((i) => {
      return i.id !== id;
    });
    State.updateStrategyIndicators(newIndicators);
  };

  // Method to add indicatos
  const AddIndicatorToStrategy = () => {
    if (!checkAddIndicatorAccess()) {
      return;
    }
    if (!State.selectedIndicatorInfo?.id) {
      toast.error('Add a unique identifier for the indicator.');
      return;
    }
    // return if indicator id already exists in State.strategyIndicators
    const idExists = State.strategyIndicators.find((i) => {
      return i.id === State.selectedIndicatorInfo?.id;
    });

    if (idExists) {
      toast.error(
        `Unique Identifier ${idExists.id} already exists. Please use a unique id.`,
      );
      return;
    }

    const strInds = State.strategyIndicators
      ? [...State.strategyIndicators]
      : [];
    strInds.push(State.selectedIndicatorInfo as IndicatorInfo);
    State.updateStrategyIndicators(strInds);
    State.setSelectedIndicator(null);
  };

  // Update Entry Condition
  const updateEntryCondition = (text: string, condition: string) => {
    State.updateBuyConditionText(text);
    State.updateBuyCondition(condition);
  };

  // Update Exit Condition
  const updateExitCondition = (text: string, condition: string) => {
    State.updateSellConditionText(text);
    State.updateSellCondition(condition);
  };

  const textToConditionAnalytics = (
    type: MovesTypes,
    success: boolean,
    result: string,
  ) => {
    const event = success ? success_text_to_cond : fail_text_to_cond;
    Analytics.track(event, {
      screen: page_str_configure,
      contitionType: type,
      result: result,
    });
  };

  const updateConditionFromText = (
    text: string,
    vars: any[],
    type: MovesTypes,
  ) => {
    State.setLoaderState(type, true);
    getConditionFromText(text, vars)
      .then((cond) => {
        if (cond.error) {
          switch (type) {
            case MovesTypes.ENTRY:
              State.updateBuyCondition('');
              State.setBuyCondErrorText(cond.error);
              break;
            case MovesTypes.EXIT:
              State.updateSellCondition('');
              State.setSellCondErrorText(cond.error);
              break;
          }
          textToConditionAnalytics(type, false, cond.error);
        } else if (cond.condition) {
          switch (type) {
            case MovesTypes.ENTRY:
              State.updateBuyCondition(cond.condition);
              State.setBuyCondErrorText('');
              break;
            case MovesTypes.EXIT:
              State.updateSellCondition(cond.condition);
              State.setSellCondErrorText('');
              break;
          }
          textToConditionAnalytics(type, true, cond.condition);
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        State.setLoaderState(type, false);
      });
  };

  // fetch Indicator Functions
  useEffect(() => {
    fetchIndicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch selected indicator info
  useEffect(() => {
    if (State.selectedIndicator !== null) {
      // @ts-ignore
      const i = INDICATORS_INFO[State.selectedIndicator];
      State.updateSelectedIndicatorInfo({ type: 'full', value: i });
    } else {
      State.updateSelectedIndicatorInfo({ type: 'full', value: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.selectedIndicator]);

  return {
    strategyState: State,
    basicStrategyParamsInfo,
    getBacktestResults,
    AddIndicatorToStrategy,
    removeIndicator,
    updateEntryCondition,
    updateExitCondition,
    updateConditionFromText,
    updateBasacParamInfo,
  };
};
