import commonStyles from '../common.module.css';
import { useComponentLogic } from './logics';
import Select from 'react-select';
import { PORTFOLIO_CURRENCIES } from 'constants/app/app';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';
import { PREFERRED_PORTFOLIO_CURRENCY } from 'constants/app/localstore';
import { useApplicationContainer } from 'containers/application';

export const AccountSettings = () => {
  const Component = useComponentLogic();
  const currency = Component.userSettings?.portfolioCurrency;
  const AppContainer = useApplicationContainer();
  const showManageButton =
    Component.user?.email !== 'uptrendreviewer@gmail.com';
  return (
    <div>
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Registered E-mail:</div>
        <div className={commonStyles.paramValue}>{Component.user?.email}</div>
      </div>
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Verification Status:</div>
        <div className={commonStyles.paramValue}>
          {Component.user?.email_verified ? 'Verified' : 'Verification Pending'}
        </div>
      </div>
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Current Plan:</div>
        <div className={commonStyles.paramValue}>
          {Component.userSettings?.plan.isPlanActive ? (
            <>
              <span>
                {Component.userSettings.plan?.planDetails?.displayName}
              </span>
              {showManageButton && (
                <span>
                  &nbsp;-&nbsp;
                  <span
                    onClick={Component.openBillingPortal}
                    className={commonStyles.planAction}
                  >
                    Manage
                  </span>
                </span>
              )}
            </>
          ) : (
            <span
              className={commonStyles.planAction}
              onClick={() => AppContainer.showPricingTable(true)}
            >
              See Plans
            </span>
          )}
        </div>
      </div>
      {Component.userSettings?.plan?.ip ? (
        <div className={commonStyles.row}>
          <div className={commonStyles.paramName}>IP Address:</div>
          <div className={commonStyles.paramValue}>
            {Component.userSettings?.plan?.ip ?? '-'}
          </div>
        </div>
      ) : null}
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Porfolio Currency:</div>
        <div className={commonStyles.paramValue}>
          {currency ? (
            <Select
              value={{
                value: currency,
                label: currency.toUpperCase(),
              }}
              className={'settingsSelect'}
              classNamePrefix='headerMenuSelect'
              options={PORTFOLIO_CURRENCIES}
              onChange={(s) => {
                Component.State.setCurrencyToUpdate(s?.value ?? '');
                // Update preferred currency in local store
                localStorage.setItem(
                  PREFERRED_PORTFOLIO_CURRENCY,
                  s?.value ?? '',
                );
              }}
            />
          ) : (
            '-'
          )}
        </div>
      </div>
      <ConfirmationBottomSheet
        show={!!Component.State.currencyUpdate}
        header={'Portfolio Currency'}
        subtitle={'Update your Porfolio Currency.'}
        message={`You are changing your Porfolio Currency from ${currency?.toUpperCase()} to ${Component.State.currencyUpdate.toUpperCase()}. You will loose your porfilo history as a result of this.  Please press save to update your Porfolio Currency to ${Component.State.currencyUpdate.toUpperCase()}.`}
        onCancel={() => {
          Component.State.setCurrencyToUpdate('');
        }}
        onConfim={() => {
          Component.updateUserSettings({
            portfolioCurrency: Component.State.currencyUpdate,
          });
        }}
        cancelText={'Cancel'}
        confirmText={'Save'}
      />
    </div>
  );
};
