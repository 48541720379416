import styles from './indicatorform.module.css';
import Select from 'react-select';
import { IndicatorFormProps } from './types';
import { SelectOption } from 'types/app';
import { AiFillCloseCircle } from 'react-icons/ai';
import commonStyles from '../formsCommon.module.css';

const IndicatorForm = (props: IndicatorFormProps) => {
  const {
    indicators,
    onSelectIndicator,
    selectedIndicatorInfo,
    strategyIndicators,
    removeIndicator,
  } = props;

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.header}>Indicators</div>
        <div className={commonStyles.table}>
          <div className={commonStyles.row}>
            {/* <div className={commonStyles.paramNameFit}>
              Add Indicator to Strategy
            </div> */}
            {indicators && (
              <Select
                value={
                  selectedIndicatorInfo
                    ? {
                        value: selectedIndicatorInfo?.name,
                        label: selectedIndicatorInfo?.name,
                      }
                    : null
                }
                // menuIsOpen
                className={'paramsSelect'}
                classNamePrefix='headerMenuSelect'
                options={indicators}
                // @ts-ignore
                onChange={(s: SelectOption) => onSelectIndicator(s?.value)}
                placeholder={'Select an Indicator to Add'}
              />
            )}
          </div>
          <div className={commonStyles.indicatorContainer}>
            {strategyIndicators && strategyIndicators.length > 0 && (
              <div>
                {/* @ts-ignore */}
                {strategyIndicators.map((i) => {
                  const keys = Object.keys(i.parameters);
                  const ind_desc = `${i?.display_name} (${i.name}) - ${i?.id}`;
                  return (
                    <div className={styles.indicatorDescription} key={i.name}>
                      <div className={styles.indicatorInfoRowHeader}>
                        {ind_desc}
                        <AiFillCloseCircle
                          className={styles.closeIcon}
                          onClick={() => i.id && removeIndicator(i.id)}
                        />
                      </div>
                      <div className={styles.indicatorInfoRow}>
                        <span className={styles.indicatorInfoHeader}>
                          Inputs:{' '}
                        </span>
                        <span className={styles.indicatorInfoValue}>
                          {keys.map((k, index) => (
                            <span key={k}>
                              {k} = {i.parameters[k]}
                              {index === keys.length - 1 ? '' : ', '}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className={styles.indicatorInfoRow}>
                        <span className={styles.indicatorInfoHeader}>
                          Outputs:{' '}
                        </span>
                        <span className={styles.indicatorInfoValue}>
                          {i.output_names.map((o, index) => (
                            <span key={o}>
                              {o}
                              {index === i.output_names.length - 1 ? '' : ', '}
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorForm;
