import { useComponentLogic } from './logics';
import PricingTable from 'components/PricingTable';
import styles from './subscriptions.module.css';
import Loader from 'components/Loader';
import { Animate } from 'react-simple-animate';

const Subscriptions = () => {
  const Component = useComponentLogic();
  return (
    <div className={styles.container}>
      {Component.State.isLoadingProducts && (
        <div className={styles.loaderContainer}>
          <Loader bgColor={'#fff'} />
        </div>
      )}
      <>
        <Animate
          play={!Component.State.isLoadingProducts}
          end={{
            opacity: 1,
            filter: 'blur(0)',
            transform: 'translateY(0)',
            height: 'fit-content',
          }}
          start={{
            opacity: 0,
            filter: 'blur(2px)',
            transform: 'translateY(20px)',
            height: '0px',
          }}
          duration={0.4}
        >
          <PricingTable
            products={Component.State.products}
            isLoadingCheckout={Component.State.isLoadingCheckout}
            onCheckout={Component.initiateCheckoutSession}
          />
        </Animate>
      </>
    </div>
  );
};

export default Subscriptions;
