import styles from './listbutton.module.css';
import { ListButtonProps, ListButtonType } from './types';

const ListButton = (props: ListButtonProps) => {
  const {
    title,
    disabled,
    additionalClassName = '',
    onClick,
    type = ListButtonType.Primary,
  } = props;
  return (
    <button
      type='button'
      onClick={onClick}
      disabled={disabled}
      className={`${additionalClassName} ${styles.buttonBase} ${
        type === ListButtonType.Primary
          ? styles.primaryButton
          : styles.secondaryButton
      }`}
    >
      {title}
    </button>
  );
};

export default ListButton;
