import { AiOutlineClose } from 'react-icons/ai';
import styles from './fullscreen.module.css';
import { FullscreenViewProps } from './types';

export const FullscreenView = (props: FullscreenViewProps) => {
  const { children, isOpen, onClose } = props;
  return isOpen ? (
    <div className={styles.container}>
      {' '}
      {onClose && (
        <div className={styles.closeButton} onClick={onClose}>
          <AiOutlineClose fontSize={18} />
        </div>
      )}
      {children}
    </div>
  ) : null;
};
