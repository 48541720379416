import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import styles from './addIndicator.module.css';
import Select from 'react-select';
import { AddIndicatorBottomSheetProps } from './types';
import { SelectOption } from 'types/app';
import commonStyles from '../formsCommon.module.css';
import { INDICATOR_PRICE_INPUTS } from 'constants/app/app';
import Bottomsheet from 'components/BottomSheet';
import PageHeader from 'components/PageHeader';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import { useEffect } from 'react';

const AddIndicatorBottomSheet = (props: AddIndicatorBottomSheetProps) => {
  const {
    onSelectIndicator,
    selectedIndicatorInfo,
    onAddIndicator,
    updateSelectedIndicatorInfo,
    isOpen,
    onCancel,
  } = props;

  const { isDesktop } = useDisplayType();

  // update indicator info with default id as function name short name
  useEffect(() => {
    if (!selectedIndicatorInfo?.id) {
      updateSelectedIndicatorInfo({
        type: 'id',
        value: selectedIndicatorInfo?.name.toLocaleLowerCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Bottomsheet
      isOpen={isOpen}
      onDismiss={onCancel}
      header={
        <PageHeader
          title='Add Indicator.'
          subTitle='Add an indicator to your strategy.'
          showBackButton={false}
        />
      }
    >
      <>
        <div className={isDesktop ? styles.container : styles.mContainer}>
          {selectedIndicatorInfo && (
            <div className={styles.indicatorInputs}>
              <div className={styles.indicatorDescriptionToAdd}>
                <div className={styles.indicatorInfoRow}>
                  <span className={styles.indicatorInfoHeader}>Indicator:</span>
                  <span className={styles.indicatorInfoValueRight}>
                    {selectedIndicatorInfo.name} (
                    {selectedIndicatorInfo.display_name})
                  </span>
                </div>
                <div className={styles.indicatorInfoRow}>
                  <span className={styles.indicatorInfoHeader}>Group:</span>
                  <span className={styles.indicatorInfoValueRight}>
                    {selectedIndicatorInfo.group}
                  </span>
                </div>
                <div className={styles.indicatorInfoRow}>
                  <span className={styles.indicatorInfoHeader}>Outputs:</span>
                  <span className={styles.indicatorInfoValueRight}>
                    {selectedIndicatorInfo.output_names.join(', ')}
                  </span>
                </div>
              </div>
              <div className={styles.indicatorInputRow}>
                <div className={commonStyles.paramName}>
                  Indicator Idendifier Name:
                </div>
                <div className={commonStyles.paramValue}>
                  <TextInput
                    inputClass={styles.basicParamInput}
                    placeholder={'Indicator Idendifier Name'}
                    value={selectedIndicatorInfo.id ?? ''}
                    type={InputTypes.TEXT}
                    onChange={(v) =>
                      updateSelectedIndicatorInfo({ type: 'id', value: v })
                    }
                    renderEmptyErrorSpan={false}
                    regExp={/^[a-zA-Z][a-zA-Z0-9]*$/}
                  />
                </div>
              </div>
              {Object.keys(selectedIndicatorInfo.input_names).map(
                (input_name: string, i) => {
                  return typeof selectedIndicatorInfo.input_names[
                    input_name
                  ] === 'string' ? (
                    <div className={styles.indicatorInputRow}>
                      <div className={commonStyles.paramName}>
                        {input_name} Input:
                      </div>
                      <div className={commonStyles.paramValue}>
                        <Select
                          value={
                            selectedIndicatorInfo
                              ? {
                                  value:
                                    selectedIndicatorInfo?.input_names[
                                      input_name
                                    ],
                                  label:
                                    selectedIndicatorInfo?.input_names[
                                      input_name
                                    ],
                                }
                              : null
                          }
                          className={'paramsSelect'}
                          classNamePrefix='headerMenuSelect'
                          options={INDICATOR_PRICE_INPUTS}
                          // @ts-ignore
                          onChange={(s: SelectOption) => {
                            updateSelectedIndicatorInfo({
                              type: 'input_price',
                              value: s.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null;
                },
              )}
              {/* {selectedIndicatorInfo.input_names.hasOwnProperty('price') && (
                <div className={styles.indicatorInputRow}>
                  <div className={commonStyles.paramName}>Price Input:</div>
                  <div className={commonStyles.paramValue}>
                    <Select
                      value={
                        selectedIndicatorInfo
                          ? {
                              value: selectedIndicatorInfo?.input_names.price,
                              label: selectedIndicatorInfo?.input_names.price,
                            }
                          : null
                      }
                      className={'paramsSelect'}
                      classNamePrefix='headerMenuSelect'
                      options={INDICATOR_PRICE_INPUTS}
                      // @ts-ignore
                      onChange={(s: SelectOption) => {
                        updateSelectedIndicatorInfo({
                          type: 'input_price',
                          value: s.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )} */}
              {Object.keys(selectedIndicatorInfo.parameters).map((k) => {
                return (
                  <div className={styles.indicatorInputRow} key={k}>
                    <div className={commonStyles.paramName}>{k}:</div>
                    <div className={commonStyles.paramValue}>
                      <TextInput
                        inputClass={styles.basicParamInput}
                        placeholder={k}
                        value={selectedIndicatorInfo.parameters[k]}
                        type={InputTypes.TEXT}
                        onChange={(v) =>
                          updateSelectedIndicatorInfo({
                            type: 'parameter',
                            value: {
                              name: k,
                              value: Number(v),
                            },
                          })
                        }
                        renderEmptyErrorSpan={false}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className={styles.addButtonContainer}>
            {selectedIndicatorInfo ? (
              <>
                <ListButton
                  type={ListButtonType.Secondary}
                  additionalClassName={styles.buttonStyles}
                  title='Add Indicator'
                  onClick={onAddIndicator}
                />
                <ListButton
                  type={ListButtonType.Secondary}
                  additionalClassName={styles.buttonStyles}
                  title='Cancel'
                  onClick={() => onSelectIndicator(null)}
                />
              </>
            ) : null}
          </div> */}
        </div>
        <div className={styles.actionsContainer}>
          <PushButton
            additionalClassName={styles.actionButton}
            title={'Cancel'}
            onClick={() => onSelectIndicator(null)}
            type={PushButtonType.Secondary}
            disabled={false}
            // eslint-disable-next-line react/jsx-no-undef
            icon={<AiOutlineClose />}
          />
          <PushButton
            additionalClassName={styles.actionButton}
            title='Add Indicator'
            onClick={onAddIndicator}
            disabled={false}
            icon={<AiOutlinePlus />}
          />
        </div>
      </>
    </Bottomsheet>
  );
};

export default AddIndicatorBottomSheet;
