import {
  SentimentChartData,
  SentimentChartDatum,
} from 'components/SentimentChart/types';
import {
  CandleChartAxisMap,
  ChartEvent,
  ChartLineData,
  ChartVolumeData,
  LiveStrategyChartInfo,
  OrdersData,
  PortfolioChartData,
  PriceCandleData,
  StrategyOrder,
} from 'types/chart';
import { NewsSentimentSummary } from 'types/news';

export const formatLiveChartData = (data: LiveStrategyChartInfo) => {
  // Convert to local timezone
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const vData: ChartVolumeData = [];
  const xyMap: CandleChartAxisMap = {};
  const formatedOhlcv = data.priceData.map((d, i) => {
    const t: number = d[0] / 1000 - offset * 60;

    for (let ind in data.plotInfo) {
      data.plotInfo[ind].forEach((iLineData: any) => {
        iLineData.data[i] = {
          time: t,
          value: iLineData.data[i] as unknown as number,
        };
      });
    }

    vData.push({
      time: t,
      value: d[5],
      color: d[4] > d[1] ? '#26A69A66' : '#FF525266',
    });

    const y: PriceCandleData = {
      time: t,
      open: d[1],
      high: d[2],
      low: d[3],
      close: d[4],
    };

    xyMap[t] = y;

    return y;
  });

  // Filter Orders & Chart Events
  const orders: OrdersData = [];
  const chartEvents: ChartEvent[] = [];
  data.strategyEvents.forEach((e: any) => {
    if (e.eventType === 'order') {
      const oDatetime = e.data.datetime - offset * 60;
      const oTicktime = (e.data.ticktime ?? e.data.datetime) - offset * 60;
      const green =
        xyMap[oTicktime ?? oDatetime].close >
        xyMap[oTicktime ?? oDatetime].open;
      let eData: StrategyOrder = {
        ...e.data,
        datetime: oDatetime,
        ticktime: oTicktime,
        markerPosition: green ? 'belowBar' : 'aboveBar',
        shape: green ? 'arrowUp' : 'arrowDown',
      };
      orders.push(eData);
    } else {
      let eData: ChartEvent = {
        ...e,
        timestamp: e.timestamp - offset * 60,
      };
      chartEvents.push(eData);
    }
  });
  return {
    ohlcv: formatedOhlcv,
    plotInfo: data.plotInfo,
    orders,
    chartEvents,
    vData,
  };
};

export const formatPortfolioChartData = (
  pData: ChartLineData,
): PortfolioChartData => {
  let low = pData[0].value;
  let high = pData[0].value;

  const formatted: ChartLineData = pData.map((d) => {
    if (d.value > high) high = d.value;
    if (d.value < low) low = d.value;

    // Create a new Date object with the current time
    const date = new Date(d.time * 1000); // Convert from UNIX timestamp to JS Date

    // Convert back to UNIX timestamp and adjust for local timezone offset
    const offset = date.getTimezoneOffset();
    const adjustedTime = date.getTime() / 1000 - offset * 60;

    return {
      time: adjustedTime,
      value: d.value,
    };
  });

  return {
    low,
    high,
    pData: formatted,
  };
};

export const formatSentimentChartData = (
  data: NewsSentimentSummary[],
): SentimentChartData => {
  const positivePercentage: SentimentChartDatum[] = [];
  const negativePercentage: SentimentChartDatum[] = [];
  // Convert to local timezone
  const date = new Date();
  const offset = date.getTimezoneOffset();
  data.forEach((d) => {
    positivePercentage.push({
      primary: new Date((d.timestamp / 1000 - offset * 60) * 1000),
      secondary: (d.positive / d.totalItems) * 100,
    });
    negativePercentage.push({
      primary: new Date((d.timestamp / 1000 - offset * 60) * 1000),
      secondary: (d.negative / d.totalItems) * 100,
    });
  });

  return [
    {
      label: 'Positive',
      data: positivePercentage,
      color: '#00ba7c',
    },
    {
      label: 'Negative',
      data: negativePercentage,
      color: 'rgb(255, 104, 104)',
    },
  ];
};
