export const get_exchange_symbols = [
  '1000BONK/USDC:USDC',
  '1000BONK/USDT:USDT',
  '1000FLOKI/USDT:USDT',
  '1000LUNC/USDT:USDT',
  '1000PEPE/USDC:USDC',
  '1000PEPE/USDT:USDT',
  '1000RATS/USDT:USDT',
  '1000SATS/FDUSD',
  '1000SATS/TRY',
  '1000SATS/USDT',
  '1000SATS/USDT:USDT',
  '1000SHIB/USDC:USDC',
  '1000SHIB/USDT:USDT',
  '1000XEC/USDT:USDT',
  '1INCH/BTC',
  '1INCH/BUSD',
  '1INCH/USDT',
  '1INCH/USDT:USDT',
  '1INCHDOWN/USDT',
  '1INCHUP/USDT',
  'AAVE/BKRW',
  'AAVE/BNB',
  'AAVE/BRL',
  'AAVE/BTC',
  'AAVE/BUSD',
  'AAVE/ETH',
  'AAVE/TRY',
  'AAVE/USD:AAVE',
  'AAVE/USDT',
  'AAVE/USDT:USDT',
  'AAVEDOWN/USDT',
  'AAVEUP/USDT',
  'ACA/BTC',
  'ACA/BUSD',
  'ACA/TRY',
  'ACA/USDT',
  'ACE/BNB',
  'ACE/BTC',
  'ACE/FDUSD',
  'ACE/TRY',
  'ACE/USDT',
  'ACE/USDT:USDT',
  'ACH/BTC',
  'ACH/BUSD',
  'ACH/TRY',
  'ACH/USDT',
  'ACH/USDT:USDT',
  'ACM/BTC',
  'ACM/BUSD',
  'ACM/TRY',
  'ACM/USDT',
  'ADA/AUD',
  'ADA/BIDR',
  'ADA/BKRW',
  'ADA/BNB',
  'ADA/BRL',
  'ADA/BTC',
  'ADA/BUSD',
  'ADA/ETH',
  'ADA/EUR',
  'ADA/FDUSD',
  'ADA/GBP',
  'ADA/JPY',
  'ADA/PAX',
  'ADA/RUB',
  'ADA/TRY',
  'ADA/TUSD',
  'ADA/USD:ADA',
  'ADA/USD:ADA-240628',
  'ADA/USD:ADA-240927',
  'ADA/USDC',
  'ADA/USDT',
  'ADA/USDT:USDT',
  'ADADOWN/USDT',
  'ADAUP/USDT',
  'ADX/BNB',
  'ADX/BTC',
  'ADX/BUSD',
  'ADX/ETH',
  'ADX/USDT',
  'AE/BNB',
  'AE/BTC',
  'AE/ETH',
  'AERGO/BTC',
  'AERGO/BUSD',
  'AERGO/USDT',
  'AEUR/USDT',
  'AEVO/BNB',
  'AEVO/BTC',
  'AEVO/FDUSD',
  'AEVO/TRY',
  'AEVO/USDT',
  'AEVO/USDT:USDT',
  'AGI/BNB',
  'AGI/BTC',
  'AGI/ETH',
  'AGIX/BTC',
  'AGIX/BUSD',
  'AGIX/FDUSD',
  'AGIX/TRY',
  'AGIX/USDT',
  'AGIX/USDT:USDT',
  'AGLD/BNB',
  'AGLD/BTC',
  'AGLD/BUSD',
  'AGLD/USDT',
  'AGLD/USDT:USDT',
  'AI/BNB',
  'AI/BTC',
  'AI/FDUSD',
  'AI/TRY',
  'AI/TUSD',
  'AI/USDT',
  'AI/USDT:USDT',
  'AION/BNB',
  'AION/BTC',
  'AION/BUSD',
  'AION/ETH',
  'AION/USDT',
  'AKRO/BTC',
  'AKRO/BUSD',
  'AKRO/USDT',
  'ALCX/BTC',
  'ALCX/BUSD',
  'ALCX/USDT',
  'ALGO/BIDR',
  'ALGO/BNB',
  'ALGO/BTC',
  'ALGO/BUSD',
  'ALGO/ETH',
  'ALGO/FDUSD',
  'ALGO/PAX',
  'ALGO/RUB',
  'ALGO/TRY',
  'ALGO/TUSD',
  'ALGO/USD:ALGO',
  'ALGO/USDC',
  'ALGO/USDT',
  'ALGO/USDT:USDT',
  'ALICE/BIDR',
  'ALICE/BNB',
  'ALICE/BTC',
  'ALICE/BUSD',
  'ALICE/TRY',
  'ALICE/USDT',
  'ALICE/USDT:USDT',
  'ALPACA/BNB',
  'ALPACA/BTC',
  'ALPACA/BUSD',
  'ALPACA/USDT',
  'ALPHA/BNB',
  'ALPHA/BTC',
  'ALPHA/BUSD',
  'ALPHA/USDT',
  'ALPHA/USDT:USDT',
  'ALPINE/BTC',
  'ALPINE/BUSD',
  'ALPINE/EUR',
  'ALPINE/TRY',
  'ALPINE/USDT',
  'ALT/BNB',
  'ALT/BTC',
  'ALT/FDUSD',
  'ALT/TRY',
  'ALT/USDC',
  'ALT/USDT',
  'ALT/USDT:USDT',
  'AMB/BNB',
  'AMB/BTC',
  'AMB/BUSD',
  'AMB/ETH',
  'AMB/USDT',
  'AMB/USDT:USDT',
  'AMP/BNB',
  'AMP/BTC',
  'AMP/BUSD',
  'AMP/TRY',
  'AMP/USDT',
  'ANC/BNB',
  'ANC/BTC',
  'ANC/BUSD',
  'ANC/USDT',
  'ANKR/BNB',
  'ANKR/BTC',
  'ANKR/BUSD',
  'ANKR/PAX',
  'ANKR/TRY',
  'ANKR/TUSD',
  'ANKR/USDC',
  'ANKR/USDT',
  'ANKR/USDT:USDT',
  'ANT/BNB',
  'ANT/BTC',
  'ANT/BUSD',
  'ANT/USDT',
  'ANT/USDT:USDT',
  'ANY/BTC',
  'ANY/BUSD',
  'ANY/USDT',
  'APE/AUD',
  'APE/BNB',
  'APE/BRL',
  'APE/BTC',
  'APE/BUSD',
  'APE/ETH',
  'APE/EUR',
  'APE/GBP',
  'APE/TRY',
  'APE/USD:APE',
  'APE/USDT',
  'APE/USDT:USDT',
  'API3/BNB',
  'API3/BTC',
  'API3/BUSD',
  'API3/TRY',
  'API3/USDT',
  'API3/USDT:USDT',
  'APPC/BNB',
  'APPC/BTC',
  'APPC/ETH',
  'APT/BRL',
  'APT/BTC',
  'APT/BUSD',
  'APT/ETH',
  'APT/EUR',
  'APT/FDUSD',
  'APT/TRY',
  'APT/USD:APT',
  'APT/USDC',
  'APT/USDT',
  'APT/USDT:USDT',
  'AR/BNB',
  'AR/BTC',
  'AR/BUSD',
  'AR/TRY',
  'AR/USDT',
  'AR/USDT:USDT',
  'ARB/BTC',
  'ARB/ETH',
  'ARB/EUR',
  'ARB/FDUSD',
  'ARB/RUB',
  'ARB/TRY',
  'ARB/TUSD',
  'ARB/USDC',
  'ARB/USDC:USDC',
  'ARB/USDT',
  'ARB/USDT:USDT',
  'ARDR/BNB',
  'ARDR/BTC',
  'ARDR/ETH',
  'ARDR/USDT',
  'ARK/BTC',
  'ARK/BUSD',
  'ARK/ETH',
  'ARK/TRY',
  'ARK/USDT',
  'ARK/USDT:USDT',
  'ARKM/BNB',
  'ARKM/BTC',
  'ARKM/FDUSD',
  'ARKM/RUB',
  'ARKM/TRY',
  'ARKM/TUSD',
  'ARKM/USDT',
  'ARKM/USDT:USDT',
  'ARN/BTC',
  'ARN/ETH',
  'ARPA/BNB',
  'ARPA/BTC',
  'ARPA/BUSD',
  'ARPA/ETH',
  'ARPA/RUB',
  'ARPA/TRY',
  'ARPA/USDT',
  'ARPA/USDT:USDT',
  'ASR/BTC',
  'ASR/BUSD',
  'ASR/TRY',
  'ASR/USDT',
  'AST/BTC',
  'AST/ETH',
  'AST/USDT',
  'ASTR/BTC',
  'ASTR/BUSD',
  'ASTR/ETH',
  'ASTR/USDT',
  'ASTR/USDT:USDT',
  'ATA/BNB',
  'ATA/BTC',
  'ATA/BUSD',
  'ATA/USDT',
  'ATA/USDT:USDT',
  'ATM/BTC',
  'ATM/BUSD',
  'ATM/TRY',
  'ATM/USDT',
  'ATOM/BIDR',
  'ATOM/BNB',
  'ATOM/BRL',
  'ATOM/BTC',
  'ATOM/BUSD',
  'ATOM/ETH',
  'ATOM/EUR',
  'ATOM/FDUSD',
  'ATOM/PAX',
  'ATOM/TRY',
  'ATOM/TUSD',
  'ATOM/USD:ATOM',
  'ATOM/USDC',
  'ATOM/USDT',
  'ATOM/USDT:USDT',
  'AUCTION/BTC',
  'AUCTION/BUSD',
  'AUCTION/FDUSD',
  'AUCTION/USDT',
  'AUCTION/USDT:USDT',
  'AUD/BUSD',
  'AUD/USDC',
  'AUD/USDT',
  'AUDIO/BTC',
  'AUDIO/BUSD',
  'AUDIO/TRY',
  'AUDIO/USDT',
  'AUDIO/USDT:USDT',
  'AUTO/BTC',
  'AUTO/BUSD',
  'AUTO/USDT',
  'AVA/BNB',
  'AVA/BTC',
  'AVA/BUSD',
  'AVA/USDT',
  'AVAX/AUD',
  'AVAX/BIDR',
  'AVAX/BNB',
  'AVAX/BRL',
  'AVAX/BTC',
  'AVAX/BUSD',
  'AVAX/ETH',
  'AVAX/EUR',
  'AVAX/FDUSD',
  'AVAX/GBP',
  'AVAX/TRY',
  'AVAX/TUSD',
  'AVAX/USD:AVAX',
  'AVAX/USDC',
  'AVAX/USDC:USDC',
  'AVAX/USDT',
  'AVAX/USDT:USDT',
  'AXL/BTC',
  'AXL/FDUSD',
  'AXL/TRY',
  'AXL/USDT',
  'AXL/USDT:USDT',
  'AXS/AUD',
  'AXS/BNB',
  'AXS/BRL',
  'AXS/BTC',
  'AXS/BUSD',
  'AXS/ETH',
  'AXS/TRY',
  'AXS/USD:AXS',
  'AXS/USDT',
  'AXS/USDT:USDT',
  'BADGER/BTC',
  'BADGER/BUSD',
  'BADGER/USDT',
  'BADGER/USDT:USDT',
  'BAKE/BNB',
  'BAKE/BTC',
  'BAKE/BUSD',
  'BAKE/USDT',
  'BAKE/USDT:USDT',
  'BAL/BNB',
  'BAL/BTC',
  'BAL/BUSD',
  'BAL/USDT',
  'BAL/USDT:USDT',
  'BAND/BNB',
  'BAND/BTC',
  'BAND/BUSD',
  'BAND/TRY',
  'BAND/USDT',
  'BAND/USDT:USDT',
  'BAR/BTC',
  'BAR/BUSD',
  'BAR/TRY',
  'BAR/USDT',
  'BAT/BNB',
  'BAT/BTC',
  'BAT/BUSD',
  'BAT/ETH',
  'BAT/PAX',
  'BAT/TUSD',
  'BAT/USDC',
  'BAT/USDT',
  'BAT/USDT:USDT',
  'BCC/BNB',
  'BCC/BTC',
  'BCC/ETH',
  'BCC/USDT',
  'BCD/BTC',
  'BCD/ETH',
  'BCH/BNB',
  'BCH/BTC',
  'BCH/BUSD',
  'BCH/EUR',
  'BCH/FDUSD',
  'BCH/PAX',
  'BCH/TRY',
  'BCH/TUSD',
  'BCH/USD:BCH',
  'BCH/USD:BCH-240628',
  'BCH/USD:BCH-240927',
  'BCH/USDC',
  'BCH/USDC:USDC',
  'BCH/USDT',
  'BCH/USDT:USDT',
  'BCHA/BUSD',
  'BCHABC/BTC',
  'BCHABC/BUSD',
  'BCHABC/PAX',
  'BCHABC/TUSD',
  'BCHABC/USDC',
  'BCHABC/USDT',
  'BCHDOWN/USDT',
  'BCHUP/USDT',
  'BCN/BNB',
  'BCN/BTC',
  'BCN/ETH',
  'BCPT/BNB',
  'BCPT/BTC',
  'BCPT/ETH',
  'BCPT/PAX',
  'BCPT/TUSD',
  'BCPT/USDC',
  'BDOT/DOT',
  'BEAM/BNB',
  'BEAM/BTC',
  'BEAM/USDT',
  'BEAMX/TRY',
  'BEAMX/USDT',
  'BEAMX/USDT:USDT',
  'BEAR/BUSD',
  'BEAR/USDT',
  'BEL/BNB',
  'BEL/BTC',
  'BEL/BUSD',
  'BEL/ETH',
  'BEL/TRY',
  'BEL/USDT',
  'BEL/USDT:USDT',
  'BETA/BNB',
  'BETA/BTC',
  'BETA/BUSD',
  'BETA/ETH',
  'BETA/USDT',
  'BETH/BUSD',
  'BETH/ETH',
  'BETH/USDT',
  'BGBP/USDC',
  'BICO/BTC',
  'BICO/BUSD',
  'BICO/USDT',
  'BICO/USDT:USDT',
  'BIFI/BNB',
  'BIFI/BUSD',
  'BIFI/USDT',
  'BIGTIME/USDT:USDT',
  'BKRW/BUSD',
  'BKRW/USDT',
  'BLUEBIRD/USDT:USDT',
  'BLUR/BTC',
  'BLUR/FDUSD',
  'BLUR/TRY',
  'BLUR/USDC',
  'BLUR/USDT',
  'BLUR/USDT:USDT',
  'BLZ/BNB',
  'BLZ/BTC',
  'BLZ/BUSD',
  'BLZ/ETH',
  'BLZ/FDUSD',
  'BLZ/USDT',
  'BLZ/USDT:USDT',
  'BNB/AUD',
  'BNB/BIDR',
  'BNB/BKRW',
  'BNB/BRL',
  'BNB/BTC',
  'BNB/BUSD',
  'BNB/DAI',
  'BNB/ETH',
  'BNB/EUR',
  'BNB/FDUSD',
  'BNB/GBP',
  'BNB/IDRT',
  'BNB/JPY',
  'BNB/NGN',
  'BNB/PAX',
  'BNB/RUB',
  'BNB/TRY',
  'BNB/TUSD',
  'BNB/UAH',
  'BNB/USD:BNB',
  'BNB/USD:BNB-240628',
  'BNB/USD:BNB-240927',
  'BNB/USDC',
  'BNB/USDC:USDC',
  'BNB/USDP',
  'BNB/USDS',
  'BNB/USDT',
  'BNB/USDT:USDT',
  'BNB/UST',
  'BNB/ZAR',
  'BNBBEAR/BUSD',
  'BNBBEAR/USDT',
  'BNBBULL/BUSD',
  'BNBBULL/USDT',
  'BNBDOWN/USDT',
  'BNBUP/USDT',
  'BNT/BTC',
  'BNT/BUSD',
  'BNT/ETH',
  'BNT/USDT',
  'BNT/USDT:USDT',
  'BNX/BNB',
  'BNX/BTC',
  'BNX/BUSD',
  'BNX/USDT',
  'BNX/USDT:USDT',
  'BOME/BTC',
  'BOME/FDUSD',
  'BOME/TRY',
  'BOME/USDC',
  'BOME/USDC:USDC',
  'BOME/USDT',
  'BOME/USDT:USDT',
  'BOND/BNB',
  'BOND/BTC',
  'BOND/BUSD',
  'BOND/ETH',
  'BOND/USDT',
  'BOND/USDT:USDT',
  'BONK/FDUSD',
  'BONK/TRY',
  'BONK/USDC',
  'BONK/USDT',
  'BOT/BTC',
  'BOT/BUSD',
  'BQX/BTC',
  'BQX/ETH',
  'BRD/BNB',
  'BRD/BTC',
  'BRD/ETH',
  'BSV/BTC',
  'BSV/PAX',
  'BSV/TUSD',
  'BSV/USDC',
  'BSV/USDT',
  'BSV/USDT:USDT',
  'BSW/BNB',
  'BSW/BUSD',
  'BSW/ETH',
  'BSW/TRY',
  'BSW/USDT',
  'BTC/AEUR',
  'BTC/ARS',
  'BTC/AUD',
  'BTC/BIDR',
  'BTC/BKRW',
  'BTC/BRL',
  'BTC/BUSD',
  'BTC/DAI',
  'BTC/EUR',
  'BTC/FDUSD',
  'BTC/GBP',
  'BTC/IDRT',
  'BTC/JPY',
  'BTC/NGN',
  'BTC/PAX',
  'BTC/PLN',
  'BTC/RON',
  'BTC/RUB',
  'BTC/TRY',
  'BTC/TUSD',
  'BTC/UAH',
  'BTC/USD:BTC',
  'BTC/USD:BTC-240628',
  'BTC/USD:BTC-240927',
  'BTC/USDC',
  'BTC/USDC:USDC',
  'BTC/USDP',
  'BTC/USDS',
  'BTC/USDT',
  'BTC/USDT:USDT',
  'BTC/USDT:USDT-240628',
  'BTC/USDT:USDT-240927',
  'BTC/UST',
  'BTC/VAI',
  'BTC/ZAR',
  'BTCB/BTC',
  'BTCDOM/USDT:USDT',
  'BTCDOWN/USDT',
  'BTCST/BTC',
  'BTCST/BUSD',
  'BTCST/USDT',
  'BTCST/USDT:USDT',
  'BTCUP/USDT',
  'BTG/BTC',
  'BTG/BUSD',
  'BTG/ETH',
  'BTG/USDT',
  'BTS/BNB',
  'BTS/BTC',
  'BTS/BUSD',
  'BTS/ETH',
  'BTS/USDT',
  'BTS/USDT:USDT',
  'BTT/BNB',
  'BTT/BRL',
  'BTT/BTC',
  'BTT/BUSD',
  'BTT/EUR',
  'BTT/PAX',
  'BTT/TRX',
  'BTT/TRY',
  'BTT/TUSD',
  'BTT/USDC',
  'BTT/USDT',
  'BTTC/BUSD',
  'BTTC/TRY',
  'BTTC/USDC',
  'BTTC/USDT',
  'BULL/BUSD',
  'BULL/USDT',
  'BURGER/BNB',
  'BURGER/BUSD',
  'BURGER/ETH',
  'BURGER/USDT',
  'BUSD/BIDR',
  'BUSD/BKRW',
  'BUSD/BRL',
  'BUSD/BVND',
  'BUSD/DAI',
  'BUSD/IDRT',
  'BUSD/NGN',
  'BUSD/PLN',
  'BUSD/RON',
  'BUSD/RUB',
  'BUSD/TRY',
  'BUSD/UAH',
  'BUSD/USDT',
  'BUSD/VAI',
  'BUSD/ZAR',
  'BZRX/BNB',
  'BZRX/BTC',
  'BZRX/BUSD',
  'BZRX/USDT',
  'C98/BNB',
  'C98/BRL',
  'C98/BTC',
  'C98/BUSD',
  'C98/USDT',
  'C98/USDT:USDT',
  'CAKE/AUD',
  'CAKE/BNB',
  'CAKE/BRL',
  'CAKE/BTC',
  'CAKE/BUSD',
  'CAKE/GBP',
  'CAKE/TRY',
  'CAKE/TUSD',
  'CAKE/USDT',
  'CAKE/USDT:USDT',
  'CDT/BTC',
  'CDT/ETH',
  'CELO/BTC',
  'CELO/BUSD',
  'CELO/USDT',
  'CELO/USDT:USDT',
  'CELR/BNB',
  'CELR/BTC',
  'CELR/BUSD',
  'CELR/ETH',
  'CELR/USDT',
  'CELR/USDT:USDT',
  'CFX/BTC',
  'CFX/BUSD',
  'CFX/TRY',
  'CFX/TUSD',
  'CFX/USDC',
  'CFX/USDT',
  'CFX/USDT:USDT',
  'CHAT/BTC',
  'CHAT/ETH',
  'CHESS/BNB',
  'CHESS/BTC',
  'CHESS/BUSD',
  'CHESS/USDT',
  'CHR/BNB',
  'CHR/BTC',
  'CHR/BUSD',
  'CHR/ETH',
  'CHR/USDT',
  'CHR/USDT:USDT',
  'CHZ/BNB',
  'CHZ/BRL',
  'CHZ/BTC',
  'CHZ/BUSD',
  'CHZ/EUR',
  'CHZ/FDUSD',
  'CHZ/GBP',
  'CHZ/TRY',
  'CHZ/USD:CHZ',
  'CHZ/USDT',
  'CHZ/USDT:USDT',
  'CITY/BNB',
  'CITY/BTC',
  'CITY/BUSD',
  'CITY/TRY',
  'CITY/USDT',
  'CKB/BTC',
  'CKB/BUSD',
  'CKB/TRY',
  'CKB/USDC',
  'CKB/USDT',
  'CKB/USDT:USDT',
  'CLOAK/BTC',
  'CLOAK/ETH',
  'CLV/BNB',
  'CLV/BTC',
  'CLV/BUSD',
  'CLV/USDT',
  'CMT/BNB',
  'CMT/BTC',
  'CMT/ETH',
  'CND/BNB',
  'CND/BTC',
  'CND/ETH',
  'COCOS/BNB',
  'COCOS/BTC',
  'COCOS/BUSD',
  'COCOS/TRY',
  'COCOS/USDT',
  'COCOS/USDT:USDT',
  'COMBO/BNB',
  'COMBO/TRY',
  'COMBO/USDT',
  'COMBO/USDT:USDT',
  'COMP/BNB',
  'COMP/BTC',
  'COMP/BUSD',
  'COMP/TRY',
  'COMP/TUSD',
  'COMP/USDT',
  'COMP/USDT:USDT',
  'COS/BNB',
  'COS/BTC',
  'COS/BUSD',
  'COS/TRY',
  'COS/USDT',
  'COTI/BNB',
  'COTI/BTC',
  'COTI/BUSD',
  'COTI/TRY',
  'COTI/USDT',
  'COTI/USDT:USDT',
  'COVER/BUSD',
  'COVER/ETH',
  'CREAM/BNB',
  'CREAM/BUSD',
  'CREAM/USDT',
  'CRV/BNB',
  'CRV/BTC',
  'CRV/BUSD',
  'CRV/ETH',
  'CRV/TRY',
  'CRV/USDT',
  'CRV/USDT:USDT',
  'CTK/BNB',
  'CTK/BTC',
  'CTK/BUSD',
  'CTK/USDT',
  'CTK/USDT:USDT',
  'CTSI/BNB',
  'CTSI/BTC',
  'CTSI/BUSD',
  'CTSI/USDT',
  'CTSI/USDT:USDT',
  'CTXC/BNB',
  'CTXC/BTC',
  'CTXC/BUSD',
  'CTXC/USDT',
  'CVC/BNB',
  'CVC/BTC',
  'CVC/BUSD',
  'CVC/ETH',
  'CVC/USDT',
  'CVC/USDT:USDT',
  'CVP/BUSD',
  'CVP/ETH',
  'CVP/USDT',
  'CVX/BTC',
  'CVX/BUSD',
  'CVX/USDT',
  'CVX/USDT:USDT',
  'CYBER/BNB',
  'CYBER/BTC',
  'CYBER/ETH',
  'CYBER/FDUSD',
  'CYBER/TRY',
  'CYBER/TUSD',
  'CYBER/USDT',
  'CYBER/USDT:USDT',
  'DAI/BNB',
  'DAI/BTC',
  'DAI/BUSD',
  'DAI/USDT',
  'DAR/BNB',
  'DAR/BTC',
  'DAR/BUSD',
  'DAR/ETH',
  'DAR/EUR',
  'DAR/TRY',
  'DAR/USDT',
  'DAR/USDT:USDT',
  'DASH/BNB',
  'DASH/BTC',
  'DASH/BUSD',
  'DASH/ETH',
  'DASH/USDT',
  'DASH/USDT:USDT',
  'DATA/BTC',
  'DATA/BUSD',
  'DATA/ETH',
  'DATA/USDT',
  'DCR/BNB',
  'DCR/BTC',
  'DCR/BUSD',
  'DCR/USDT',
  'DEFI/USDT:USDT',
  'DEGO/BTC',
  'DEGO/BUSD',
  'DEGO/USDT',
  'DENT/BTC',
  'DENT/BUSD',
  'DENT/ETH',
  'DENT/TRY',
  'DENT/USDT',
  'DENT/USDT:USDT',
  'DEXE/BUSD',
  'DEXE/ETH',
  'DEXE/USDT',
  'DF/BUSD',
  'DF/ETH',
  'DF/USDT',
  'DGB/BTC',
  'DGB/BUSD',
  'DGB/USDT',
  'DGB/USDT:USDT',
  'DGD/BTC',
  'DGD/ETH',
  'DIA/BNB',
  'DIA/BTC',
  'DIA/BUSD',
  'DIA/USDT',
  'DLT/BNB',
  'DLT/BTC',
  'DLT/ETH',
  'DNT/BTC',
  'DNT/BUSD',
  'DNT/ETH',
  'DNT/USDT',
  'DOCK/BTC',
  'DOCK/BUSD',
  'DOCK/ETH',
  'DOCK/USDT',
  'DODO/BTC',
  'DODO/BUSD',
  'DODO/USDT',
  'DODOX/USDT:USDT',
  'DOGE/AUD',
  'DOGE/BIDR',
  'DOGE/BNB',
  'DOGE/BRL',
  'DOGE/BTC',
  'DOGE/BUSD',
  'DOGE/EUR',
  'DOGE/FDUSD',
  'DOGE/GBP',
  'DOGE/PAX',
  'DOGE/RUB',
  'DOGE/TRY',
  'DOGE/TUSD',
  'DOGE/USD:DOGE',
  'DOGE/USDC',
  'DOGE/USDC:USDC',
  'DOGE/USDT',
  'DOGE/USDT:USDT',
  'DOT/AUD',
  'DOT/BIDR',
  'DOT/BKRW',
  'DOT/BNB',
  'DOT/BRL',
  'DOT/BTC',
  'DOT/BUSD',
  'DOT/ETH',
  'DOT/EUR',
  'DOT/FDUSD',
  'DOT/GBP',
  'DOT/NGN',
  'DOT/RUB',
  'DOT/TRY',
  'DOT/TUSD',
  'DOT/USD:DOT',
  'DOT/USD:DOT-240628',
  'DOT/USD:DOT-240927',
  'DOT/USDC',
  'DOT/USDT',
  'DOT/USDT:USDT',
  'DOTDOWN/USDT',
  'DOTUP/USDT',
  'DREP/BNB',
  'DREP/BTC',
  'DREP/BUSD',
  'DREP/USDT',
  'DUSK/BNB',
  'DUSK/BTC',
  'DUSK/BUSD',
  'DUSK/PAX',
  'DUSK/USDC',
  'DUSK/USDT',
  'DUSK/USDT:USDT',
  'DYDX/BNB',
  'DYDX/BTC',
  'DYDX/BUSD',
  'DYDX/ETH',
  'DYDX/FDUSD',
  'DYDX/TRY',
  'DYDX/USDT',
  'DYDX/USDT:USDT',
  'DYM/BTC',
  'DYM/FDUSD',
  'DYM/TRY',
  'DYM/USDT',
  'DYM/USDT:USDT',
  'EASY/BTC',
  'EASY/ETH',
  'EDO/BTC',
  'EDO/ETH',
  'EDU/BNB',
  'EDU/BTC',
  'EDU/EUR',
  'EDU/TRY',
  'EDU/TUSD',
  'EDU/USDT',
  'EDU/USDT:USDT',
  'EGLD/BNB',
  'EGLD/BTC',
  'EGLD/BUSD',
  'EGLD/ETH',
  'EGLD/EUR',
  'EGLD/FDUSD',
  'EGLD/RON',
  'EGLD/USD:EGLD',
  'EGLD/USDT',
  'EGLD/USDT:USDT',
  'ELF/BTC',
  'ELF/BUSD',
  'ELF/ETH',
  'ELF/USDT',
  'ENA/BNB',
  'ENA/BTC',
  'ENA/FDUSD',
  'ENA/TRY',
  'ENA/USDC',
  'ENA/USDC:USDC',
  'ENA/USDT',
  'ENA/USDT:USDT',
  'ENG/BTC',
  'ENG/ETH',
  'ENJ/BNB',
  'ENJ/BRL',
  'ENJ/BTC',
  'ENJ/BUSD',
  'ENJ/ETH',
  'ENJ/EUR',
  'ENJ/GBP',
  'ENJ/TRY',
  'ENJ/USDT',
  'ENJ/USDT:USDT',
  'ENS/BNB',
  'ENS/BTC',
  'ENS/BUSD',
  'ENS/FDUSD',
  'ENS/TRY',
  'ENS/USD:ENS',
  'ENS/USDT',
  'ENS/USDT:USDT',
  'EOS/AUD',
  'EOS/BNB',
  'EOS/BTC',
  'EOS/BUSD',
  'EOS/ETH',
  'EOS/EUR',
  'EOS/PAX',
  'EOS/TRY',
  'EOS/TUSD',
  'EOS/USD:EOS',
  'EOS/USDC',
  'EOS/USDT',
  'EOS/USDT:USDT',
  'EOSBEAR/BUSD',
  'EOSBEAR/USDT',
  'EOSBULL/BUSD',
  'EOSBULL/USDT',
  'EOSDOWN/USDT',
  'EOSUP/USDT',
  'EPS/BTC',
  'EPS/BUSD',
  'EPS/USDT',
  'EPX/BUSD',
  'EPX/USDT',
  'ERD/BNB',
  'ERD/BTC',
  'ERD/BUSD',
  'ERD/PAX',
  'ERD/USDC',
  'ERD/USDT',
  'ERN/BNB',
  'ERN/BUSD',
  'ERN/USDT',
  'ETC/BNB',
  'ETC/BRL',
  'ETC/BTC',
  'ETC/BUSD',
  'ETC/ETH',
  'ETC/EUR',
  'ETC/FDUSD',
  'ETC/GBP',
  'ETC/PAX',
  'ETC/TRY',
  'ETC/TUSD',
  'ETC/USD:ETC',
  'ETC/USDC',
  'ETC/USDT',
  'ETC/USDT:USDT',
  'ETH/AEUR',
  'ETH/AUD',
  'ETH/BIDR',
  'ETH/BKRW',
  'ETH/BRL',
  'ETH/BTC',
  'ETH/BTC:BTC',
  'ETH/BUSD',
  'ETH/DAI',
  'ETH/EUR',
  'ETH/FDUSD',
  'ETH/GBP',
  'ETH/JPY',
  'ETH/NGN',
  'ETH/PAX',
  'ETH/PLN',
  'ETH/RUB',
  'ETH/TRY',
  'ETH/TUSD',
  'ETH/UAH',
  'ETH/USD:ETH',
  'ETH/USD:ETH-240628',
  'ETH/USD:ETH-240927',
  'ETH/USDC',
  'ETH/USDC:USDC',
  'ETH/USDP',
  'ETH/USDT',
  'ETH/USDT:USDT',
  'ETH/USDT:USDT-240628',
  'ETH/USDT:USDT-240927',
  'ETH/UST',
  'ETH/ZAR',
  'ETHBEAR/BUSD',
  'ETHBEAR/USDT',
  'ETHBULL/BUSD',
  'ETHBULL/USDT',
  'ETHDOWN/USDT',
  'ETHFI/BNB',
  'ETHFI/BTC',
  'ETHFI/FDUSD',
  'ETHFI/TRY',
  'ETHFI/USDC',
  'ETHFI/USDC:USDC',
  'ETHFI/USDT',
  'ETHFI/USDT:USDT',
  'ETHUP/USDT',
  'ETHW/USDT:USDT',
  'EUR/AEUR',
  'EUR/BUSD',
  'EUR/USDC',
  'EUR/USDT',
  'EVX/BTC',
  'EVX/ETH',
  'EZ/BTC',
  'EZ/ETH',
  'FARM/BNB',
  'FARM/BTC',
  'FARM/BUSD',
  'FARM/ETH',
  'FARM/USDT',
  'FDUSD/BUSD',
  'FDUSD/TRY',
  'FDUSD/USDT',
  'FET/BNB',
  'FET/BRL',
  'FET/BTC',
  'FET/BUSD',
  'FET/FDUSD',
  'FET/TRY',
  'FET/USDC',
  'FET/USDT',
  'FET/USDT:USDT',
  'FIDA/BNB',
  'FIDA/BTC',
  'FIDA/BUSD',
  'FIDA/TRY',
  'FIDA/USDT',
  'FIL/BNB',
  'FIL/BTC',
  'FIL/BUSD',
  'FIL/ETH',
  'FIL/FDUSD',
  'FIL/TRY',
  'FIL/USD:FIL',
  'FIL/USDC',
  'FIL/USDC:USDC',
  'FIL/USDT',
  'FIL/USDT:USDT',
  'FILDOWN/USDT',
  'FILUP/USDT',
  'FIO/BNB',
  'FIO/BTC',
  'FIO/BUSD',
  'FIO/USDT',
  'FIRO/BTC',
  'FIRO/BUSD',
  'FIRO/ETH',
  'FIRO/USDT',
  'FIS/BIDR',
  'FIS/BRL',
  'FIS/BTC',
  'FIS/BUSD',
  'FIS/TRY',
  'FIS/USDT',
  'FLM/BNB',
  'FLM/BTC',
  'FLM/BUSD',
  'FLM/USDT',
  'FLM/USDT:USDT',
  'FLOKI/FDUSD',
  'FLOKI/TRY',
  'FLOKI/TUSD',
  'FLOKI/USDC',
  'FLOKI/USDT',
  'FLOW/BNB',
  'FLOW/BTC',
  'FLOW/BUSD',
  'FLOW/USDT',
  'FLOW/USDT:USDT',
  'FLUX/BTC',
  'FLUX/BUSD',
  'FLUX/USDT',
  'FOOTBALL/USDT:USDT',
  'FOR/BNB',
  'FOR/BTC',
  'FOR/BUSD',
  'FOR/USDT',
  'FORTH/BTC',
  'FORTH/BUSD',
  'FORTH/USDT',
  'FRONT/BTC',
  'FRONT/BUSD',
  'FRONT/ETH',
  'FRONT/TRY',
  'FRONT/TUSD',
  'FRONT/USDT',
  'FRONT/USDT:USDT',
  'FTM/AUD',
  'FTM/BIDR',
  'FTM/BNB',
  'FTM/BRL',
  'FTM/BTC',
  'FTM/BUSD',
  'FTM/ETH',
  'FTM/EUR',
  'FTM/FDUSD',
  'FTM/PAX',
  'FTM/RUB',
  'FTM/TRY',
  'FTM/TUSD',
  'FTM/USD:FTM',
  'FTM/USDC',
  'FTM/USDT',
  'FTM/USDT:USDT',
  'FTT/BNB',
  'FTT/BTC',
  'FTT/BUSD',
  'FTT/ETH',
  'FTT/USDT',
  'FTT/USDT:USDT',
  'FUEL/BTC',
  'FUEL/ETH',
  'FUN/BNB',
  'FUN/BTC',
  'FUN/ETH',
  'FUN/USDT',
  'FXS/BTC',
  'FXS/BUSD',
  'FXS/USDT',
  'FXS/USDT:USDT',
  'GAL/BNB',
  'GAL/BRL',
  'GAL/BTC',
  'GAL/BUSD',
  'GAL/ETH',
  'GAL/EUR',
  'GAL/TRY',
  'GAL/USDT',
  'GAL/USDT:USDT',
  'GALA/AUD',
  'GALA/BNB',
  'GALA/BRL',
  'GALA/BTC',
  'GALA/BUSD',
  'GALA/ETH',
  'GALA/EUR',
  'GALA/FDUSD',
  'GALA/TRY',
  'GALA/USD:GALA-221226',
  'GALA/USDC',
  'GALA/USDT',
  'GALA/USDT:USDT',
  'GAS/BTC',
  'GAS/BUSD',
  'GAS/FDUSD',
  'GAS/TRY',
  'GAS/USDT',
  'GAS/USDT:USDT',
  'GBP/BUSD',
  'GBP/USDT',
  'GFT/BUSD',
  'GFT/USDT',
  'GHST/BUSD',
  'GHST/ETH',
  'GHST/USDT',
  'GLM/BTC',
  'GLM/BUSD',
  'GLM/ETH',
  'GLM/USDT',
  'GLM/USDT:USDT',
  'GLMR/BNB',
  'GLMR/BTC',
  'GLMR/BUSD',
  'GLMR/USDT',
  'GLMR/USDT:USDT',
  'GMT/AUD',
  'GMT/BNB',
  'GMT/BRL',
  'GMT/BTC',
  'GMT/BUSD',
  'GMT/ETH',
  'GMT/EUR',
  'GMT/GBP',
  'GMT/TRY',
  'GMT/USD:GMT',
  'GMT/USDT',
  'GMT/USDT:USDT',
  'GMX/BTC',
  'GMX/BUSD',
  'GMX/USDT',
  'GMX/USDT:USDT',
  'GNO/BNB',
  'GNO/BTC',
  'GNO/BUSD',
  'GNO/USDT',
  'GNS/BTC',
  'GNS/USDT',
  'GNT/BNB',
  'GNT/BTC',
  'GNT/ETH',
  'GO/BNB',
  'GO/BTC',
  'GRS/BTC',
  'GRS/ETH',
  'GRT/BTC',
  'GRT/BUSD',
  'GRT/ETH',
  'GRT/EUR',
  'GRT/FDUSD',
  'GRT/TRY',
  'GRT/USDT',
  'GRT/USDT:USDT',
  'GTC/BNB',
  'GTC/BTC',
  'GTC/BUSD',
  'GTC/USDT',
  'GTC/USDT:USDT',
  'GTO/BNB',
  'GTO/BTC',
  'GTO/BUSD',
  'GTO/ETH',
  'GTO/PAX',
  'GTO/TUSD',
  'GTO/USDC',
  'GTO/USDT',
  'GVT/BTC',
  'GVT/ETH',
  'GXS/BNB',
  'GXS/BTC',
  'GXS/ETH',
  'GXS/USDT',
  'HARD/BNB',
  'HARD/BTC',
  'HARD/BUSD',
  'HARD/USDT',
  'HBAR/BNB',
  'HBAR/BTC',
  'HBAR/BUSD',
  'HBAR/TRY',
  'HBAR/USDT',
  'HBAR/USDT:USDT',
  'HC/BTC',
  'HC/ETH',
  'HC/USDT',
  'HEGIC/BUSD',
  'HEGIC/ETH',
  'HFT/BTC',
  'HFT/BUSD',
  'HFT/USDT',
  'HFT/USDT:USDT',
  'HIFI/ETH',
  'HIFI/USDT',
  'HIFI/USDT:USDT',
  'HIGH/BNB',
  'HIGH/BTC',
  'HIGH/BUSD',
  'HIGH/USDT',
  'HIGH/USDT:USDT',
  'HIVE/BNB',
  'HIVE/BTC',
  'HIVE/BUSD',
  'HIVE/USDT',
  'HNT/BTC',
  'HNT/BUSD',
  'HNT/USDT',
  'HNT/USDT:USDT',
  'HOOK/BNB',
  'HOOK/BTC',
  'HOOK/BUSD',
  'HOOK/USDT',
  'HOOK/USDT:USDT',
  'HOT/BNB',
  'HOT/BRL',
  'HOT/BTC',
  'HOT/BUSD',
  'HOT/ETH',
  'HOT/EUR',
  'HOT/TRY',
  'HOT/USDT',
  'HOT/USDT:USDT',
  'HSR/BTC',
  'HSR/ETH',
  'ICN/BTC',
  'ICN/ETH',
  'ICP/BNB',
  'ICP/BTC',
  'ICP/BUSD',
  'ICP/ETH',
  'ICP/EUR',
  'ICP/FDUSD',
  'ICP/RUB',
  'ICP/TRY',
  'ICP/USDC',
  'ICP/USDT',
  'ICP/USDT:USDT',
  'ICX/BNB',
  'ICX/BTC',
  'ICX/BUSD',
  'ICX/ETH',
  'ICX/USD:ICX',
  'ICX/USDT',
  'ICX/USDT:USDT',
  'ID/BNB',
  'ID/BTC',
  'ID/EUR',
  'ID/TRY',
  'ID/TUSD',
  'ID/USDT',
  'ID/USDT:USDT',
  'IDEX/BNB',
  'IDEX/BTC',
  'IDEX/BUSD',
  'IDEX/USDT',
  'IDEX/USDT:USDT',
  'ILV/BNB',
  'ILV/BTC',
  'ILV/BUSD',
  'ILV/USDT',
  'ILV/USDT:USDT',
  'IMX/BNB',
  'IMX/BTC',
  'IMX/BUSD',
  'IMX/TRY',
  'IMX/USDT',
  'IMX/USDT:USDT',
  'INJ/BNB',
  'INJ/BTC',
  'INJ/BUSD',
  'INJ/ETH',
  'INJ/FDUSD',
  'INJ/TRY',
  'INJ/TUSD',
  'INJ/USDC',
  'INJ/USDT',
  'INJ/USDT:USDT',
  'INS/BTC',
  'INS/ETH',
  'IOST/BTC',
  'IOST/BUSD',
  'IOST/ETH',
  'IOST/USDT',
  'IOST/USDT:USDT',
  'IOTA/BNB',
  'IOTA/BTC',
  'IOTA/BUSD',
  'IOTA/ETH',
  'IOTA/FDUSD',
  'IOTA/TRY',
  'IOTA/USDT',
  'IOTA/USDT:USDT',
  'IOTX/BTC',
  'IOTX/BUSD',
  'IOTX/ETH',
  'IOTX/USDT',
  'IOTX/USDT:USDT',
  'IQ/BNB',
  'IQ/BUSD',
  'IQ/USDT',
  'IRIS/BNB',
  'IRIS/BTC',
  'IRIS/BUSD',
  'IRIS/USDT',
  'JASMY/BNB',
  'JASMY/BTC',
  'JASMY/BUSD',
  'JASMY/ETH',
  'JASMY/EUR',
  'JASMY/TRY',
  'JASMY/USDT',
  'JASMY/USDT:USDT',
  'JOE/BTC',
  'JOE/BUSD',
  'JOE/TRY',
  'JOE/USDT',
  'JOE/USDT:USDT',
  'JST/BNB',
  'JST/BTC',
  'JST/BUSD',
  'JST/USDT',
  'JTO/FDUSD',
  'JTO/TRY',
  'JTO/USDC',
  'JTO/USDT',
  'JTO/USDT:USDT',
  'JUP/FDUSD',
  'JUP/TRY',
  'JUP/USDC',
  'JUP/USDT',
  'JUP/USDT:USDT',
  'JUV/BTC',
  'JUV/BUSD',
  'JUV/TRY',
  'JUV/USDT',
  'KAS/USDT:USDT',
  'KAVA/BNB',
  'KAVA/BTC',
  'KAVA/BUSD',
  'KAVA/ETH',
  'KAVA/USDT',
  'KAVA/USDT:USDT',
  'KDA/BTC',
  'KDA/BUSD',
  'KDA/USDT',
  'KEEP/BNB',
  'KEEP/BTC',
  'KEEP/BUSD',
  'KEEP/USDT',
  'KEY/BTC',
  'KEY/BUSD',
  'KEY/ETH',
  'KEY/USDT',
  'KEY/USDT:USDT',
  'KLAY/BNB',
  'KLAY/BTC',
  'KLAY/BUSD',
  'KLAY/USDT',
  'KLAY/USDT:USDT',
  'KMD/BTC',
  'KMD/BUSD',
  'KMD/ETH',
  'KMD/USDT',
  'KNC/BNB',
  'KNC/BTC',
  'KNC/BUSD',
  'KNC/ETH',
  'KNC/USD:KNC',
  'KNC/USDT',
  'KNC/USDT:USDT',
  'KP3R/BNB',
  'KP3R/BUSD',
  'KP3R/USDT',
  'KSM/AUD',
  'KSM/BNB',
  'KSM/BTC',
  'KSM/BUSD',
  'KSM/ETH',
  'KSM/USDT',
  'KSM/USDT:USDT',
  'LAZIO/BTC',
  'LAZIO/BUSD',
  'LAZIO/EUR',
  'LAZIO/TRY',
  'LAZIO/USDT',
  'LDO/BTC',
  'LDO/BUSD',
  'LDO/FDUSD',
  'LDO/TRY',
  'LDO/TUSD',
  'LDO/USDT',
  'LDO/USDT:USDT',
  'LEND/BKRW',
  'LEND/BTC',
  'LEND/BUSD',
  'LEND/ETH',
  'LEND/USDT',
  'LEVER/BUSD',
  'LEVER/TRY',
  'LEVER/USDT',
  'LEVER/USDT:USDT',
  'LINA/BNB',
  'LINA/BTC',
  'LINA/BUSD',
  'LINA/USDT',
  'LINA/USDT:USDT',
  'LINK/AUD',
  'LINK/BKRW',
  'LINK/BNB',
  'LINK/BRL',
  'LINK/BTC',
  'LINK/BUSD',
  'LINK/ETH',
  'LINK/EUR',
  'LINK/FDUSD',
  'LINK/GBP',
  'LINK/NGN',
  'LINK/PAX',
  'LINK/TRY',
  'LINK/TUSD',
  'LINK/USD:LINK',
  'LINK/USD:LINK-240628',
  'LINK/USD:LINK-240927',
  'LINK/USDC',
  'LINK/USDC:USDC',
  'LINK/USDT',
  'LINK/USDT:USDT',
  'LINKDOWN/USDT',
  'LINKUP/USDT',
  'LIT/BTC',
  'LIT/BUSD',
  'LIT/ETH',
  'LIT/USDT',
  'LIT/USDT:USDT',
  'LOKA/BNB',
  'LOKA/BTC',
  'LOKA/BUSD',
  'LOKA/USDT',
  'LOOM/BNB',
  'LOOM/BTC',
  'LOOM/BUSD',
  'LOOM/ETH',
  'LOOM/TRY',
  'LOOM/USDT',
  'LOOM/USDT:USDT',
  'LPT/BNB',
  'LPT/BTC',
  'LPT/BUSD',
  'LPT/TRY',
  'LPT/USDT',
  'LPT/USDT:USDT',
  'LQTY/BTC',
  'LQTY/FDUSD',
  'LQTY/USDT',
  'LQTY/USDT:USDT',
  'LRC/BNB',
  'LRC/BTC',
  'LRC/BUSD',
  'LRC/ETH',
  'LRC/TRY',
  'LRC/USDT',
  'LRC/USDT:USDT',
  'LSK/BNB',
  'LSK/BTC',
  'LSK/BUSD',
  'LSK/ETH',
  'LSK/USDT',
  'LSK/USDT:USDT',
  'LTC/BNB',
  'LTC/BRL',
  'LTC/BTC',
  'LTC/BUSD',
  'LTC/ETH',
  'LTC/EUR',
  'LTC/FDUSD',
  'LTC/GBP',
  'LTC/NGN',
  'LTC/PAX',
  'LTC/RUB',
  'LTC/TRY',
  'LTC/TUSD',
  'LTC/UAH',
  'LTC/USD:LTC',
  'LTC/USD:LTC-240628',
  'LTC/USD:LTC-240927',
  'LTC/USDC',
  'LTC/USDC:USDC',
  'LTC/USDT',
  'LTC/USDT:USDT',
  'LTCDOWN/USDT',
  'LTCUP/USDT',
  'LTO/BNB',
  'LTO/BTC',
  'LTO/BUSD',
  'LTO/USDT',
  'LUN/BTC',
  'LUN/ETH',
  'LUNA/AUD',
  'LUNA/BIDR',
  'LUNA/BNB',
  'LUNA/BRL',
  'LUNA/BTC',
  'LUNA/BUSD',
  'LUNA/ETH',
  'LUNA/EUR',
  'LUNA/GBP',
  'LUNA/TRY',
  'LUNA/USDT',
  'LUNA/UST',
  'LUNA2/USDT:USDT',
  'LUNC/BUSD',
  'LUNC/TRY',
  'LUNC/USDT',
  'MAGIC/BTC',
  'MAGIC/BUSD',
  'MAGIC/FDUSD',
  'MAGIC/TRY',
  'MAGIC/USDT',
  'MAGIC/USDT:USDT',
  'MANA/BIDR',
  'MANA/BNB',
  'MANA/BRL',
  'MANA/BTC',
  'MANA/BUSD',
  'MANA/ETH',
  'MANA/TRY',
  'MANA/USD:MANA',
  'MANA/USDT',
  'MANA/USDT:USDT',
  'MANTA/BNB',
  'MANTA/BTC',
  'MANTA/FDUSD',
  'MANTA/TRY',
  'MANTA/USDC',
  'MANTA/USDT',
  'MANTA/USDT:USDT',
  'MASK/BNB',
  'MASK/BUSD',
  'MASK/TRY',
  'MASK/USDT',
  'MASK/USDT:USDT',
  'MATIC/AUD',
  'MATIC/BIDR',
  'MATIC/BNB',
  'MATIC/BRL',
  'MATIC/BTC',
  'MATIC/BUSD',
  'MATIC/ETH',
  'MATIC/EUR',
  'MATIC/FDUSD',
  'MATIC/GBP',
  'MATIC/RUB',
  'MATIC/TRY',
  'MATIC/TUSD',
  'MATIC/USD:MATIC',
  'MATIC/USDC',
  'MATIC/USDC:USDC',
  'MATIC/USDT',
  'MATIC/USDT:USDT',
  'MAV/BTC',
  'MAV/TRY',
  'MAV/TUSD',
  'MAV/USDT',
  'MAV/USDT:USDT',
  'MAVIA/USDT:USDT',
  'MBL/BNB',
  'MBL/BTC',
  'MBL/BUSD',
  'MBL/USDT',
  'MBL/USDT:USDT',
  'MBOX/BNB',
  'MBOX/BTC',
  'MBOX/BUSD',
  'MBOX/TRY',
  'MBOX/USDT',
  'MC/BNB',
  'MC/BTC',
  'MC/BUSD',
  'MC/USDT',
  'MCO/BNB',
  'MCO/BTC',
  'MCO/ETH',
  'MCO/USDT',
  'MDA/BTC',
  'MDA/ETH',
  'MDT/BNB',
  'MDT/BTC',
  'MDT/BUSD',
  'MDT/USDT',
  'MDT/USDT:USDT',
  'MDX/BNB',
  'MDX/BTC',
  'MDX/BUSD',
  'MDX/USDT',
  'MEME/BNB',
  'MEME/ETH',
  'MEME/FDUSD',
  'MEME/TRY',
  'MEME/TUSD',
  'MEME/USDT',
  'MEME/USDT:USDT',
  'METIS/BTC',
  'METIS/FDUSD',
  'METIS/TRY',
  'METIS/USDT',
  'METIS/USDT:USDT',
  'MFT/BNB',
  'MFT/BTC',
  'MFT/ETH',
  'MFT/USDT',
  'MINA/BNB',
  'MINA/BTC',
  'MINA/BUSD',
  'MINA/TRY',
  'MINA/USDT',
  'MINA/USDT:USDT',
  'MIR/BTC',
  'MIR/BUSD',
  'MIR/USDT',
  'MITH/BNB',
  'MITH/BTC',
  'MITH/USDT',
  'MKR/BNB',
  'MKR/BTC',
  'MKR/BUSD',
  'MKR/TRY',
  'MKR/USDT',
  'MKR/USDT:USDT',
  'MLN/BNB',
  'MLN/BTC',
  'MLN/BUSD',
  'MLN/USDT',
  'MOB/BTC',
  'MOB/BUSD',
  'MOB/USDT',
  'MOD/BTC',
  'MOD/ETH',
  'MOVR/BNB',
  'MOVR/BTC',
  'MOVR/BUSD',
  'MOVR/TRY',
  'MOVR/USDT',
  'MOVR/USDT:USDT',
  'MTH/BTC',
  'MTH/ETH',
  'MTL/BTC',
  'MTL/BUSD',
  'MTL/ETH',
  'MTL/TRY',
  'MTL/USDT',
  'MTL/USDT:USDT',
  'MULTI/BTC',
  'MULTI/BUSD',
  'MULTI/USDT',
  'MYRO/USDT:USDT',
  'NANO/BNB',
  'NANO/BTC',
  'NANO/BUSD',
  'NANO/ETH',
  'NANO/USDT',
  'NAS/BNB',
  'NAS/BTC',
  'NAS/ETH',
  'NAV/BNB',
  'NAV/BTC',
  'NAV/ETH',
  'NBS/BTC',
  'NBS/USDT',
  'NCASH/BNB',
  'NCASH/BTC',
  'NCASH/ETH',
  'NEAR/BNB',
  'NEAR/BTC',
  'NEAR/BUSD',
  'NEAR/ETH',
  'NEAR/EUR',
  'NEAR/FDUSD',
  'NEAR/RUB',
  'NEAR/TRY',
  'NEAR/USD:NEAR',
  'NEAR/USDC',
  'NEAR/USDC:USDC',
  'NEAR/USDT',
  'NEAR/USDT:USDT',
  'NEBL/BNB',
  'NEBL/BTC',
  'NEBL/BUSD',
  'NEBL/USDT',
  'NEO/BNB',
  'NEO/BTC',
  'NEO/BUSD',
  'NEO/ETH',
  'NEO/PAX',
  'NEO/RUB',
  'NEO/TRY',
  'NEO/TUSD',
  'NEO/USDC',
  'NEO/USDC:USDC',
  'NEO/USDT',
  'NEO/USDT:USDT',
  'NEXO/BTC',
  'NEXO/BUSD',
  'NEXO/USDT',
  'NFP/BNB',
  'NFP/BTC',
  'NFP/FDUSD',
  'NFP/TRY',
  'NFP/TUSD',
  'NFP/USDT',
  'NFP/USDT:USDT',
  'NKN/BNB',
  'NKN/BTC',
  'NKN/BUSD',
  'NKN/USDT',
  'NKN/USDT:USDT',
  'NMR/BTC',
  'NMR/BUSD',
  'NMR/USDT',
  'NMR/USDT:USDT',
  'NPXS/BTC',
  'NPXS/ETH',
  'NPXS/USDC',
  'NPXS/USDT',
  'NTRN/BNB',
  'NTRN/BTC',
  'NTRN/TRY',
  'NTRN/USDT',
  'NTRN/USDT:USDT',
  'NU/AUD',
  'NU/BNB',
  'NU/BTC',
  'NU/BUSD',
  'NU/RUB',
  'NU/USDT',
  'NULS/BNB',
  'NULS/BTC',
  'NULS/BUSD',
  'NULS/ETH',
  'NULS/USDT',
  'NXS/BNB',
  'NXS/BTC',
  'NXS/ETH',
  'OAX/BTC',
  'OAX/ETH',
  'OAX/USDT',
  'OCEAN/BNB',
  'OCEAN/BTC',
  'OCEAN/BUSD',
  'OCEAN/TRY',
  'OCEAN/USDT',
  'OCEAN/USDT:USDT',
  'OG/BTC',
  'OG/BUSD',
  'OG/TRY',
  'OG/USDT',
  'OGN/BNB',
  'OGN/BTC',
  'OGN/BUSD',
  'OGN/TRY',
  'OGN/USDT',
  'OGN/USDT:USDT',
  'OM/BTC',
  'OM/BUSD',
  'OM/TRY',
  'OM/USDT',
  'OM/USDT:USDT',
  'OMG/BNB',
  'OMG/BTC',
  'OMG/BUSD',
  'OMG/ETH',
  'OMG/USDT',
  'OMG/USDT:USDT',
  'OMNI/BNB',
  'OMNI/BRL',
  'OMNI/BTC',
  'OMNI/FDUSD',
  'OMNI/TRY',
  'OMNI/USDC',
  'OMNI/USDT',
  'OMNI/USDT:USDT',
  'ONDO/USDT:USDT',
  'ONE/BIDR',
  'ONE/BNB',
  'ONE/BTC',
  'ONE/BUSD',
  'ONE/ETH',
  'ONE/PAX',
  'ONE/TRY',
  'ONE/TUSD',
  'ONE/USDC',
  'ONE/USDT',
  'ONE/USDT:USDT',
  'ONG/BNB',
  'ONG/BTC',
  'ONG/USDT',
  'ONG/USDT:USDT',
  'ONT/BNB',
  'ONT/BTC',
  'ONT/BUSD',
  'ONT/ETH',
  'ONT/PAX',
  'ONT/TRY',
  'ONT/USDC',
  'ONT/USDT',
  'ONT/USDT:USDT',
  'OOKI/BNB',
  'OOKI/BUSD',
  'OOKI/ETH',
  'OOKI/USDT',
  'OP/BNB',
  'OP/BTC',
  'OP/BUSD',
  'OP/ETH',
  'OP/EUR',
  'OP/FDUSD',
  'OP/TRY',
  'OP/TUSD',
  'OP/USD:OP',
  'OP/USDC',
  'OP/USDT',
  'OP/USDT:USDT',
  'ORBS/USDT:USDT',
  'ORDI/BTC',
  'ORDI/FDUSD',
  'ORDI/TRY',
  'ORDI/TUSD',
  'ORDI/USDC',
  'ORDI/USDC:USDC',
  'ORDI/USDT',
  'ORDI/USDT:USDT',
  'ORN/BTC',
  'ORN/BUSD',
  'ORN/USDT',
  'OSMO/BTC',
  'OSMO/BUSD',
  'OSMO/USDT',
  'OST/BNB',
  'OST/BTC',
  'OST/ETH',
  'OXT/BTC',
  'OXT/BUSD',
  'OXT/USDT',
  'OXT/USDT:USDT',
  'PAX/BNB',
  'PAX/BTC',
  'PAX/BUSD',
  'PAX/ETH',
  'PAX/TUSD',
  'PAX/USDT',
  'PAXG/BNB',
  'PAXG/BTC',
  'PAXG/BUSD',
  'PAXG/TRY',
  'PAXG/USDT',
  'PDA/BTC',
  'PDA/USDT',
  'PENDLE/BTC',
  'PENDLE/FDUSD',
  'PENDLE/TRY',
  'PENDLE/TUSD',
  'PENDLE/USDC',
  'PENDLE/USDT',
  'PENDLE/USDT:USDT',
  'PEOPLE/BNB',
  'PEOPLE/BTC',
  'PEOPLE/BUSD',
  'PEOPLE/ETH',
  'PEOPLE/USDT',
  'PEOPLE/USDT:USDT',
  'PEPE/BRL',
  'PEPE/FDUSD',
  'PEPE/TRY',
  'PEPE/TUSD',
  'PEPE/USDC',
  'PEPE/USDT',
  'PERL/BNB',
  'PERL/BTC',
  'PERL/USDC',
  'PERL/USDT',
  'PERP/BTC',
  'PERP/BUSD',
  'PERP/USDT',
  'PERP/USDT:USDT',
  'PHA/BTC',
  'PHA/BUSD',
  'PHA/USDT',
  'PHB/BNB',
  'PHB/BTC',
  'PHB/BUSD',
  'PHB/PAX',
  'PHB/TUSD',
  'PHB/USDC',
  'PHB/USDT',
  'PHB/USDT:USDT',
  'PHX/BNB',
  'PHX/BTC',
  'PHX/ETH',
  'PIVX/BNB',
  'PIVX/BTC',
  'PIVX/USDT',
  'PIXEL/BNB',
  'PIXEL/BTC',
  'PIXEL/FDUSD',
  'PIXEL/TRY',
  'PIXEL/USDC',
  'PIXEL/USDT',
  'PIXEL/USDT:USDT',
  'PLA/BNB',
  'PLA/BTC',
  'PLA/BUSD',
  'PLA/USDT',
  'PNT/BTC',
  'PNT/USDT',
  'POA/BNB',
  'POA/BTC',
  'POA/ETH',
  'POE/BTC',
  'POE/ETH',
  'POLS/BNB',
  'POLS/BTC',
  'POLS/BUSD',
  'POLS/USDT',
  'POLY/BNB',
  'POLY/BTC',
  'POLY/BUSD',
  'POLY/USDT',
  'POLYX/BTC',
  'POLYX/BUSD',
  'POLYX/TRY',
  'POLYX/USDT',
  'POLYX/USDT:USDT',
  'POND/BTC',
  'POND/BUSD',
  'POND/USDT',
  'PORTAL/BNB',
  'PORTAL/BTC',
  'PORTAL/FDUSD',
  'PORTAL/TRY',
  'PORTAL/USDT',
  'PORTAL/USDT:USDT',
  'PORTO/BTC',
  'PORTO/BUSD',
  'PORTO/EUR',
  'PORTO/TRY',
  'PORTO/USDT',
  'POWR/BNB',
  'POWR/BTC',
  'POWR/BUSD',
  'POWR/ETH',
  'POWR/USDT',
  'POWR/USDT:USDT',
  'PPT/BTC',
  'PPT/ETH',
  'PROM/BNB',
  'PROM/BTC',
  'PROM/BUSD',
  'PROM/USDT',
  'PROS/BUSD',
  'PROS/ETH',
  'PROS/USDT',
  'PSG/BTC',
  'PSG/BUSD',
  'PSG/TRY',
  'PSG/USDT',
  'PUNDIX/BUSD',
  'PUNDIX/ETH',
  'PUNDIX/USDT',
  'PYR/BTC',
  'PYR/BUSD',
  'PYR/USDT',
  'PYTH/BTC',
  'PYTH/FDUSD',
  'PYTH/TRY',
  'PYTH/USDT',
  'PYTH/USDT:USDT',
  'QI/BNB',
  'QI/BTC',
  'QI/BUSD',
  'QI/USDT',
  'QKC/BTC',
  'QKC/BUSD',
  'QKC/ETH',
  'QKC/USDT',
  'QLC/BNB',
  'QLC/BTC',
  'QLC/ETH',
  'QNT/BNB',
  'QNT/BTC',
  'QNT/BUSD',
  'QNT/USDT',
  'QNT/USDT:USDT',
  'QSP/BNB',
  'QSP/BTC',
  'QSP/ETH',
  'QTUM/BNB',
  'QTUM/BTC',
  'QTUM/BUSD',
  'QTUM/ETH',
  'QTUM/USDT',
  'QTUM/USDT:USDT',
  'QUICK/BNB',
  'QUICK/BTC',
  'QUICK/BUSD',
  'QUICK/TUSD',
  'QUICK/USDT',
  'RAD/BNB',
  'RAD/BTC',
  'RAD/BUSD',
  'RAD/TRY',
  'RAD/USDT',
  'RAD/USDT:USDT',
  'RAMP/BTC',
  'RAMP/BUSD',
  'RAMP/USDT',
  'RARE/BNB',
  'RARE/BTC',
  'RARE/BUSD',
  'RARE/TRY',
  'RARE/USDT',
  'RAY/BNB',
  'RAY/BUSD',
  'RAY/FDUSD',
  'RAY/TRY',
  'RAY/USDT',
  'RAY/USDT:USDT',
  'RCN/BNB',
  'RCN/BTC',
  'RCN/ETH',
  'RDN/BNB',
  'RDN/BTC',
  'RDN/ETH',
  'RDNT/BTC',
  'RDNT/TRY',
  'RDNT/TUSD',
  'RDNT/USDT',
  'RDNT/USDT:USDT',
  'REEF/BIDR',
  'REEF/BTC',
  'REEF/BUSD',
  'REEF/TRY',
  'REEF/USDT',
  'REEF/USDT:USDT',
  'REI/BNB',
  'REI/BUSD',
  'REI/ETH',
  'REI/USDT',
  'REN/BNB',
  'REN/BTC',
  'REN/BUSD',
  'REN/USDT',
  'REN/USDT:USDT',
  'RENBTC/BTC',
  'RENBTC/ETH',
  'REP/BNB',
  'REP/BTC',
  'REP/BUSD',
  'REP/USDT',
  'REQ/BTC',
  'REQ/BUSD',
  'REQ/ETH',
  'REQ/USDT',
  'REZ/BNB',
  'REZ/BTC',
  'REZ/FDUSD',
  'REZ/TRY',
  'REZ/USDT',
  'REZ/USDT:USDT',
  'RGT/BNB',
  'RGT/BTC',
  'RGT/BUSD',
  'RGT/USDT',
  'RIF/BTC',
  'RIF/USDT',
  'RIF/USDT:USDT',
  'RLC/BNB',
  'RLC/BTC',
  'RLC/BUSD',
  'RLC/ETH',
  'RLC/USDT',
  'RLC/USDT:USDT',
  'RNDR/BRL',
  'RNDR/BTC',
  'RNDR/BUSD',
  'RNDR/EUR',
  'RNDR/FDUSD',
  'RNDR/TRY',
  'RNDR/USDC',
  'RNDR/USDT',
  'RNDR/USDT:USDT',
  'RONIN/BTC',
  'RONIN/FDUSD',
  'RONIN/TRY',
  'RONIN/USDT',
  'RONIN/USDT:USDT',
  'ROSE/BNB',
  'ROSE/BTC',
  'ROSE/BUSD',
  'ROSE/ETH',
  'ROSE/TRY',
  'ROSE/USD:ROSE',
  'ROSE/USDT',
  'ROSE/USDT:USDT',
  'RPL/BTC',
  'RPL/BUSD',
  'RPL/USDT',
  'RPX/BNB',
  'RPX/BTC',
  'RPX/ETH',
  'RSR/BNB',
  'RSR/BTC',
  'RSR/BUSD',
  'RSR/USDT',
  'RSR/USDT:USDT',
  'RUNE/AUD',
  'RUNE/BNB',
  'RUNE/BTC',
  'RUNE/BUSD',
  'RUNE/ETH',
  'RUNE/EUR',
  'RUNE/FDUSD',
  'RUNE/GBP',
  'RUNE/TRY',
  'RUNE/TUSD',
  'RUNE/USD:RUNE',
  'RUNE/USDC',
  'RUNE/USDT',
  'RUNE/USDT:USDT',
  'RVN/BTC',
  'RVN/BUSD',
  'RVN/TRY',
  'RVN/USDT',
  'RVN/USDT:USDT',
  'SAGA/BNB',
  'SAGA/BTC',
  'SAGA/FDUSD',
  'SAGA/TRY',
  'SAGA/USDC',
  'SAGA/USDT',
  'SAGA/USDT:USDT',
  'SALT/BTC',
  'SALT/ETH',
  'SAND/AUD',
  'SAND/BIDR',
  'SAND/BNB',
  'SAND/BRL',
  'SAND/BTC',
  'SAND/BUSD',
  'SAND/ETH',
  'SAND/FDUSD',
  'SAND/TRY',
  'SAND/USD:SAND',
  'SAND/USDT',
  'SAND/USDT:USDT',
  'SANTOS/BRL',
  'SANTOS/BTC',
  'SANTOS/BUSD',
  'SANTOS/TRY',
  'SANTOS/USDT',
  'SC/BTC',
  'SC/BUSD',
  'SC/ETH',
  'SC/USDT',
  'SC/USDT:USDT',
  'SCRT/BTC',
  'SCRT/BUSD',
  'SCRT/ETH',
  'SCRT/USDT',
  'SEI/BNB',
  'SEI/BTC',
  'SEI/FDUSD',
  'SEI/TRY',
  'SEI/TUSD',
  'SEI/USDC',
  'SEI/USDT',
  'SEI/USDT:USDT',
  'SFP/BTC',
  'SFP/BUSD',
  'SFP/USDT',
  'SFP/USDT:USDT',
  'SHIB/AUD',
  'SHIB/BRL',
  'SHIB/BUSD',
  'SHIB/DOGE',
  'SHIB/EUR',
  'SHIB/FDUSD',
  'SHIB/GBP',
  'SHIB/JPY',
  'SHIB/RUB',
  'SHIB/TRY',
  'SHIB/TUSD',
  'SHIB/UAH',
  'SHIB/USDC',
  'SHIB/USDT',
  'SKL/BTC',
  'SKL/BUSD',
  'SKL/TRY',
  'SKL/USDT',
  'SKL/USDT:USDT',
  'SKY/BNB',
  'SKY/BTC',
  'SKY/ETH',
  'SLP/BIDR',
  'SLP/BNB',
  'SLP/BUSD',
  'SLP/ETH',
  'SLP/TRY',
  'SLP/USDT',
  'SLP/USDT:USDT',
  'SNGLS/BTC',
  'SNGLS/ETH',
  'SNM/BTC',
  'SNM/BUSD',
  'SNM/ETH',
  'SNT/BTC',
  'SNT/BUSD',
  'SNT/ETH',
  'SNT/USDT',
  'SNT/USDT:USDT',
  'SNX/BNB',
  'SNX/BTC',
  'SNX/BUSD',
  'SNX/ETH',
  'SNX/TRY',
  'SNX/USDT',
  'SNX/USDT:USDT',
  'SOL/AUD',
  'SOL/BIDR',
  'SOL/BNB',
  'SOL/BRL',
  'SOL/BTC',
  'SOL/BUSD',
  'SOL/ETH',
  'SOL/EUR',
  'SOL/FDUSD',
  'SOL/GBP',
  'SOL/JPY',
  'SOL/RUB',
  'SOL/TRY',
  'SOL/TUSD',
  'SOL/USD:SOL',
  'SOL/USDC',
  'SOL/USDC:USDC',
  'SOL/USDT',
  'SOL/USDT:USDT',
  'SPARTA/BNB',
  'SPELL/BNB',
  'SPELL/BTC',
  'SPELL/BUSD',
  'SPELL/TRY',
  'SPELL/USDT',
  'SPELL/USDT:USDT',
  'SRM/BIDR',
  'SRM/BNB',
  'SRM/BTC',
  'SRM/BUSD',
  'SRM/USDT',
  'SRM/USDT:USDT',
  'SSV/BTC',
  'SSV/BUSD',
  'SSV/ETH',
  'SSV/TUSD',
  'SSV/USDT',
  'SSV/USDT:USDT',
  'STEEM/BNB',
  'STEEM/BTC',
  'STEEM/BUSD',
  'STEEM/ETH',
  'STEEM/USDT',
  'STEEM/USDT:USDT',
  'STG/BTC',
  'STG/BUSD',
  'STG/USDT',
  'STG/USDT:USDT',
  'STMX/BTC',
  'STMX/BUSD',
  'STMX/ETH',
  'STMX/USDT',
  'STMX/USDT:USDT',
  'STORJ/BTC',
  'STORJ/BUSD',
  'STORJ/ETH',
  'STORJ/TRY',
  'STORJ/USDT',
  'STORJ/USDT:USDT',
  'STORM/BNB',
  'STORM/BTC',
  'STORM/ETH',
  'STORM/USDT',
  'STPT/BNB',
  'STPT/BTC',
  'STPT/BUSD',
  'STPT/USDT',
  'STPT/USDT:USDT',
  'STRAT/BNB',
  'STRAT/BTC',
  'STRAT/BUSD',
  'STRAT/ETH',
  'STRAT/USDT',
  'STRAX/BTC',
  'STRAX/BUSD',
  'STRAX/ETH',
  'STRAX/TRY',
  'STRAX/USDT',
  'STRAX/USDT:USDT',
  'STRK/BTC',
  'STRK/FDUSD',
  'STRK/TRY',
  'STRK/USDC',
  'STRK/USDT',
  'STRK/USDT:USDT',
  'STX/BNB',
  'STX/BTC',
  'STX/BUSD',
  'STX/FDUSD',
  'STX/TRY',
  'STX/USDC',
  'STX/USDT',
  'STX/USDT:USDT',
  'SUB/BTC',
  'SUB/ETH',
  'SUI/BNB',
  'SUI/BTC',
  'SUI/EUR',
  'SUI/FDUSD',
  'SUI/TRY',
  'SUI/TUSD',
  'SUI/USDC',
  'SUI/USDC:USDC',
  'SUI/USDT',
  'SUI/USDT:USDT',
  'SUN/BTC',
  'SUN/BUSD',
  'SUN/USDT',
  'SUPER/BTC',
  'SUPER/BUSD',
  'SUPER/FDUSD',
  'SUPER/TRY',
  'SUPER/USDT',
  'SUPER/USDT:USDT',
  'SUSD/BTC',
  'SUSD/ETH',
  'SUSD/USDT',
  'SUSHI/BNB',
  'SUSHI/BTC',
  'SUSHI/BUSD',
  'SUSHI/USDT',
  'SUSHI/USDT:USDT',
  'SUSHIDOWN/USDT',
  'SUSHIUP/USDT',
  'SWRV/BNB',
  'SWRV/BUSD',
  'SXP/AUD',
  'SXP/BIDR',
  'SXP/BNB',
  'SXP/BTC',
  'SXP/BUSD',
  'SXP/EUR',
  'SXP/GBP',
  'SXP/TRY',
  'SXP/USDT',
  'SXP/USDT:USDT',
  'SXPDOWN/USDT',
  'SXPUP/USDT',
  'SYN/BTC',
  'SYN/USDT',
  'SYS/BNB',
  'SYS/BTC',
  'SYS/BUSD',
  'SYS/ETH',
  'SYS/USDT',
  'T/BUSD',
  'T/USDT',
  'T/USDT:USDT',
  'TAO/BTC',
  'TAO/FDUSD',
  'TAO/TRY',
  'TAO/USDT',
  'TAO/USDT:USDT',
  'TCT/BNB',
  'TCT/BTC',
  'TCT/USDT',
  'TFUEL/BNB',
  'TFUEL/BTC',
  'TFUEL/BUSD',
  'TFUEL/PAX',
  'TFUEL/TUSD',
  'TFUEL/USDC',
  'TFUEL/USDT',
  'THETA/BNB',
  'THETA/BTC',
  'THETA/BUSD',
  'THETA/ETH',
  'THETA/EUR',
  'THETA/FDUSD',
  'THETA/TRY',
  'THETA/USD:THETA',
  'THETA/USDT',
  'THETA/USDT:USDT',
  'TIA/BTC',
  'TIA/FDUSD',
  'TIA/TRY',
  'TIA/TUSD',
  'TIA/USDC',
  'TIA/USDC:USDC',
  'TIA/USDT',
  'TIA/USDT:USDT',
  'TKO/BIDR',
  'TKO/BTC',
  'TKO/BUSD',
  'TKO/USDT',
  'TLM/BNB',
  'TLM/BTC',
  'TLM/BUSD',
  'TLM/TRY',
  'TLM/USDT',
  'TLM/USDT:USDT',
  'TNB/BTC',
  'TNB/ETH',
  'TNSR/BTC',
  'TNSR/FDUSD',
  'TNSR/TRY',
  'TNSR/USDT',
  'TNSR/USDT:USDT',
  'TNT/BTC',
  'TNT/ETH',
  'TOKEN/USDT:USDT',
  'TOMO/BNB',
  'TOMO/BTC',
  'TOMO/BUSD',
  'TOMO/TRY',
  'TOMO/USDC',
  'TOMO/USDT',
  'TOMO/USDT:USDT',
  'TON/USDT:USDT',
  'TORN/BNB',
  'TORN/BTC',
  'TORN/BUSD',
  'TORN/USDT',
  'TRB/BNB',
  'TRB/BTC',
  'TRB/BUSD',
  'TRB/TRY',
  'TRB/USDT',
  'TRB/USDT:USDT',
  'TRIBE/BNB',
  'TRIBE/BTC',
  'TRIBE/BUSD',
  'TRIBE/USDT',
  'TRIG/BNB',
  'TRIG/BTC',
  'TRIG/ETH',
  'TROY/BNB',
  'TROY/BTC',
  'TROY/BUSD',
  'TROY/USDT',
  'TRU/BTC',
  'TRU/BUSD',
  'TRU/RUB',
  'TRU/USDT',
  'TRU/USDT:USDT',
  'TRX/AUD',
  'TRX/BNB',
  'TRX/BTC',
  'TRX/BUSD',
  'TRX/ETH',
  'TRX/EUR',
  'TRX/NGN',
  'TRX/PAX',
  'TRX/TRY',
  'TRX/TUSD',
  'TRX/USD:TRX',
  'TRX/USDC',
  'TRX/USDT',
  'TRX/USDT:USDT',
  'TRX/XRP',
  'TRXDOWN/USDT',
  'TRXUP/USDT',
  'TUSD/BNB',
  'TUSD/BTC',
  'TUSD/BUSD',
  'TUSD/ETH',
  'TUSD/TRY',
  'TUSD/USDT',
  'TUSDB/TUSD',
  'TVK/BTC',
  'TVK/BUSD',
  'TVK/USDT',
  'TWT/BTC',
  'TWT/BUSD',
  'TWT/TRY',
  'TWT/USDT',
  'TWT/USDT:USDT',
  'UFT/BUSD',
  'UFT/ETH',
  'UFT/USDT',
  'UMA/BTC',
  'UMA/BUSD',
  'UMA/TRY',
  'UMA/USDT',
  'UMA/USDT:USDT',
  'UNFI/BNB',
  'UNFI/BTC',
  'UNFI/BUSD',
  'UNFI/ETH',
  'UNFI/TRY',
  'UNFI/USDT',
  'UNFI/USDT:USDT',
  'UNI/AUD',
  'UNI/BNB',
  'UNI/BTC',
  'UNI/BUSD',
  'UNI/ETH',
  'UNI/EUR',
  'UNI/FDUSD',
  'UNI/TRY',
  'UNI/USD:UNI',
  'UNI/USDC',
  'UNI/USDT',
  'UNI/USDT:USDT',
  'UNIDOWN/USDT',
  'UNIUP/USDT',
  'USDC/BNB',
  'USDC/BUSD',
  'USDC/PAX',
  'USDC/TUSD',
  'USDC/USDT',
  'USDC/USDT:USDT',
  'USDP/BUSD',
  'USDP/USDT',
  'USDS/PAX',
  'USDS/TUSD',
  'USDS/USDC',
  'USDS/USDT',
  'USDSB/USDS',
  'USDSB/USDT',
  'USDT/ARS',
  'USDT/BIDR',
  'USDT/BKRW',
  'USDT/BRL',
  'USDT/BVND',
  'USDT/CZK',
  'USDT/DAI',
  'USDT/IDRT',
  'USDT/MXN',
  'USDT/NGN',
  'USDT/PLN',
  'USDT/RON',
  'USDT/RUB',
  'USDT/TRY',
  'USDT/UAH',
  'USDT/VAI',
  'USDT/ZAR',
  'UST/BTC',
  'UST/BUSD',
  'UST/USDT',
  'USTC/BUSD',
  'USTC/FDUSD',
  'USTC/TRY',
  'USTC/USDT',
  'USTC/USDT:USDT',
  'UTK/BTC',
  'UTK/BUSD',
  'UTK/USDT',
  'VANRY/BTC',
  'VANRY/TRY',
  'VANRY/USDT',
  'VANRY/USDT:USDT',
  'VEN/BNB',
  'VEN/BTC',
  'VEN/ETH',
  'VEN/USDT',
  'VET/BNB',
  'VET/BTC',
  'VET/BUSD',
  'VET/ETH',
  'VET/EUR',
  'VET/GBP',
  'VET/TRY',
  'VET/USD:VET-221226',
  'VET/USDT',
  'VET/USDT:USDT',
  'VGX/BTC',
  'VGX/ETH',
  'VGX/USDT',
  'VIA/BNB',
  'VIA/BTC',
  'VIA/ETH',
  'VIB/BTC',
  'VIB/BUSD',
  'VIB/ETH',
  'VIB/USDT',
  'VIBE/BTC',
  'VIBE/ETH',
  'VIC/BTC',
  'VIC/TRY',
  'VIC/USDT',
  'VIDT/BTC',
  'VIDT/BUSD',
  'VIDT/USDT',
  'VITE/BNB',
  'VITE/BTC',
  'VITE/BUSD',
  'VITE/USDT',
  'VOXEL/BNB',
  'VOXEL/BTC',
  'VOXEL/BUSD',
  'VOXEL/ETH',
  'VOXEL/USDT',
  'VTHO/BNB',
  'VTHO/BUSD',
  'VTHO/USDT',
  'W/BTC',
  'W/FDUSD',
  'W/TRY',
  'W/USDT',
  'W/USDT:USDT',
  'WABI/BNB',
  'WABI/BTC',
  'WABI/ETH',
  'WAN/BNB',
  'WAN/BTC',
  'WAN/ETH',
  'WAN/USDT',
  'WAVES/BNB',
  'WAVES/BTC',
  'WAVES/BUSD',
  'WAVES/ETH',
  'WAVES/EUR',
  'WAVES/PAX',
  'WAVES/RUB',
  'WAVES/TRY',
  'WAVES/TUSD',
  'WAVES/USDC',
  'WAVES/USDT',
  'WAVES/USDT:USDT',
  'WAXP/BNB',
  'WAXP/BTC',
  'WAXP/BUSD',
  'WAXP/USDT',
  'WAXP/USDT:USDT',
  'WBETH/ETH',
  'WBETH/USDT',
  'WBTC/BTC',
  'WBTC/BUSD',
  'WBTC/ETH',
  'WBTC/USDT',
  'WIF/BTC',
  'WIF/FDUSD',
  'WIF/TRY',
  'WIF/USDC',
  'WIF/USDC:USDC',
  'WIF/USDT',
  'WIF/USDT:USDT',
  'WIN/BNB',
  'WIN/BRL',
  'WIN/BTC',
  'WIN/BUSD',
  'WIN/EUR',
  'WIN/TRX',
  'WIN/USDC',
  'WIN/USDT',
  'WING/BNB',
  'WING/BTC',
  'WING/BUSD',
  'WING/ETH',
  'WING/USDT',
  'WINGS/BTC',
  'WINGS/ETH',
  'WLD/BTC',
  'WLD/FDUSD',
  'WLD/RUB',
  'WLD/TRY',
  'WLD/USDC',
  'WLD/USDC:USDC',
  'WLD/USDT',
  'WLD/USDT:USDT',
  'WNXM/BNB',
  'WNXM/BTC',
  'WNXM/BUSD',
  'WNXM/USDT',
  'WOO/BNB',
  'WOO/BTC',
  'WOO/BUSD',
  'WOO/USDT',
  'WOO/USDT:USDT',
  'WPR/BTC',
  'WPR/ETH',
  'WRX/BNB',
  'WRX/BTC',
  'WRX/BUSD',
  'WRX/EUR',
  'WRX/USDT',
  'WTC/BNB',
  'WTC/BTC',
  'WTC/ETH',
  'WTC/USDT',
  'XAI/BNB',
  'XAI/BTC',
  'XAI/FDUSD',
  'XAI/TRY',
  'XAI/TUSD',
  'XAI/USDT',
  'XAI/USDT:USDT',
  'XEC/BUSD',
  'XEC/TRY',
  'XEC/USDT',
  'XEM/BNB',
  'XEM/BTC',
  'XEM/BUSD',
  'XEM/ETH',
  'XEM/USDT',
  'XEM/USDT:USDT',
  'XLM/BNB',
  'XLM/BTC',
  'XLM/BUSD',
  'XLM/ETH',
  'XLM/EUR',
  'XLM/PAX',
  'XLM/TRY',
  'XLM/TUSD',
  'XLM/USD:XLM',
  'XLM/USDC',
  'XLM/USDT',
  'XLM/USDT:USDT',
  'XLMDOWN/USDT',
  'XLMUP/USDT',
  'XMR/BNB',
  'XMR/BTC',
  'XMR/BUSD',
  'XMR/ETH',
  'XMR/USDT',
  'XMR/USDT:USDT',
  'XNO/BTC',
  'XNO/BUSD',
  'XNO/ETH',
  'XNO/USDT',
  'XRP/AUD',
  'XRP/BIDR',
  'XRP/BKRW',
  'XRP/BNB',
  'XRP/BRL',
  'XRP/BTC',
  'XRP/BUSD',
  'XRP/ETH',
  'XRP/EUR',
  'XRP/FDUSD',
  'XRP/GBP',
  'XRP/JPY',
  'XRP/NGN',
  'XRP/PAX',
  'XRP/RUB',
  'XRP/TRY',
  'XRP/TUSD',
  'XRP/USD:XRP',
  'XRP/USD:XRP-240628',
  'XRP/USD:XRP-240927',
  'XRP/USDC',
  'XRP/USDC:USDC',
  'XRP/USDT',
  'XRP/USDT:USDT',
  'XRPBEAR/BUSD',
  'XRPBEAR/USDT',
  'XRPBULL/BUSD',
  'XRPBULL/USDT',
  'XRPDOWN/USDT',
  'XRPUP/USDT',
  'XTZ/BNB',
  'XTZ/BTC',
  'XTZ/BUSD',
  'XTZ/ETH',
  'XTZ/TRY',
  'XTZ/USD:XTZ',
  'XTZ/USDT',
  'XTZ/USDT:USDT',
  'XTZDOWN/USDT',
  'XTZUP/USDT',
  'XVG/BTC',
  'XVG/BUSD',
  'XVG/ETH',
  'XVG/TRY',
  'XVG/TUSD',
  'XVG/USDT',
  'XVG/USDT:USDT',
  'XVS/BNB',
  'XVS/BTC',
  'XVS/BUSD',
  'XVS/TRY',
  'XVS/USDT',
  'XVS/USDT:USDT',
  'XZC/BNB',
  'XZC/BTC',
  'XZC/ETH',
  'XZC/USDT',
  'XZC/XRP',
  'YFI/BNB',
  'YFI/BTC',
  'YFI/BUSD',
  'YFI/EUR',
  'YFI/USDT',
  'YFI/USDT:USDT',
  'YFIDOWN/USDT',
  'YFII/BNB',
  'YFII/BTC',
  'YFII/BUSD',
  'YFII/USDT',
  'YFIUP/USDT',
  'YGG/BNB',
  'YGG/BTC',
  'YGG/BUSD',
  'YGG/TRY',
  'YGG/USDC',
  'YGG/USDT',
  'YGG/USDT:USDT',
  'YOYOW/BNB',
  'YOYOW/BTC',
  'YOYOW/ETH',
  'ZEC/BNB',
  'ZEC/BTC',
  'ZEC/BUSD',
  'ZEC/ETH',
  'ZEC/PAX',
  'ZEC/TUSD',
  'ZEC/USDC',
  'ZEC/USDT',
  'ZEC/USDT:USDT',
  'ZEN/BNB',
  'ZEN/BTC',
  'ZEN/BUSD',
  'ZEN/ETH',
  'ZEN/USDT',
  'ZEN/USDT:USDT',
  'ZETA/USDT:USDT',
  'ZIL/BIDR',
  'ZIL/BNB',
  'ZIL/BTC',
  'ZIL/BUSD',
  'ZIL/ETH',
  'ZIL/EUR',
  'ZIL/TRY',
  'ZIL/USD:ZIL-221226',
  'ZIL/USDT',
  'ZIL/USDT:USDT',
  'ZRX/BNB',
  'ZRX/BTC',
  'ZRX/BUSD',
  'ZRX/ETH',
  'ZRX/USDT',
  'ZRX/USDT:USDT',
];
