import { useState } from 'react';

export const useComponentState = () => {
  const [tzUpdate, setTzToUpdate] = useState<string>('');
  const [currencyUpdate, setCurrencyToUpdate] = useState<string>('');

  return {
    tzUpdate,
    setTzToUpdate,
    currencyUpdate,
    setCurrencyToUpdate,
  };
};
