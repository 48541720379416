import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import commonStyles from '../formsCommon.module.css';
import Select from 'react-select';
import { BasicParamsFormProps } from './types';
import CustomDatePicker from 'components/DatePicker';
import './customselect.css';

const BasicParamsForm = (props: BasicParamsFormProps) => {
  const { basicParams, updateBasicParams, basicParamsRenderOptions } = props;

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.header}>Basic Parameters</div>
        <div className={commonStyles.table}>
          {basicParamsRenderOptions.map((i) => {
            return (
              <div className={commonStyles.row} key={i.displayName}>
                <div className={commonStyles.paramName}>{i.displayName}</div>
                <div className={commonStyles.paramValue}>
                  {i.inputType === 'TEXT' && (
                    <TextInput
                      inputClass={commonStyles.basicParamInput}
                      value={basicParams[i.id] as string}
                      isDisabled={!!basicParams.id}
                      type={InputTypes.TEXT}
                      onChange={(v) =>
                        updateBasicParams({ type: i.id, value: v })
                      }
                      renderEmptyErrorSpan={false}
                    />
                  )}
                  {i.inputType === 'NUMBER' && (
                    <TextInput
                      inputClass={commonStyles.basicParamInput}
                      isDisabled={!!basicParams.id}
                      value={basicParams[i.id] as string}
                      type={InputTypes.NUMBER}
                      onChange={(v) =>
                        updateBasicParams({ type: i.id, value: v })
                      }
                      renderEmptyErrorSpan={false}
                    />
                  )}
                  {i.inputType === 'SELECT' && (
                    <Select
                      value={{
                        value: basicParams[i.id] as string,
                        label: basicParams[i.id]?.toString().toUpperCase(),
                      }}
                      className={'paramsSelect'}
                      isDisabled={!!basicParams.id}
                      classNamePrefix='headerMenuSelect'
                      options={i.selectOptions}
                      onChange={(s) =>
                        updateBasicParams({ type: i.id, value: s?.value })
                      }
                    />
                  )}
                  {i.inputType === 'DATETIME' && (
                    <CustomDatePicker
                      // value={selectedExchange}
                      startDate={basicParams[i.id] as Date}
                      onChangeStartDate={(s) =>
                        updateBasicParams({
                          type: i.id,
                          value: s,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BasicParamsForm;
