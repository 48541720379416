export type SelectOption = { label: string; value: any };
export type SelectGroup = { label: string; options: SelectOption[] };

export type UseReducerAction = {
  type: string;
  value: any;
};

export enum PUSH_NOTIFICATION_STATUS {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export type PushTokenInfo = {
  os: string;
  token: string;
};

export type OrderNotificationData = {
  strategyId: string;
  type: string;
};
