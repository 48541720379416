export enum InputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
}

export type TextInputProps = {
  type: InputTypes;
  maxlength?: number;
  value: string;
  label?: string;
  onChange: (v: string) => void;
  width?: number | string;
  height?: number | string;
  error?: string;
  inputClass?: string;
  containerClass?: string;
  labelClass?: string;
  onFocus?: () => void;
  placeholder?: string;
  renderEmptyErrorSpan?: boolean;
  isDisabled?: boolean;
  autoComplete?: boolean;
  regExp?: RegExp;
};
