import BottomSheet from 'components/BottomSheet';
import PageHeader from 'components/PageHeader';
import { BacktestInitBottomSheetProps, BacktestInitSteps } from './types';
import { BacktestCallendar } from './components/BacktestCallendar';
import StepFlow from 'components/StepFlow';
import { BacktestAllocations } from './components/BacktestAllocations';
import { useComponentLogic } from './logics';

const BacktestInitBottomSheet = (props: BacktestInitBottomSheetProps) => {
  const {
    startDate,
    onDateUpdate,
    onDateSubmit,
    isOpen,
    onCancel,
    cashAllocation,
    onChangeCashAllocation,
    symbol,
    marketStructure,
  } = props;
  const Component = useComponentLogic(props);
  const symbolSplit = symbol.split('/');
  const quote = symbolSplit[1];
  return (
    <BottomSheet
      isOpen={isOpen}
      onDismiss={onCancel}
      header={
        <PageHeader
          title={'Run Backtest'}
          subTitle={'Provide Initial Cash & Start-Date to run the backtest'}
          showBackButton={true}
          customActionOnBack={Component.goBack}
        />
      }
    >
      <StepFlow
        currentStep={Component.State.currentStep}
        doNotAnimateFirstStep={true}
        stepComponents={[
          <BacktestAllocations
            quoteCurrency={quote}
            onCancel={onCancel}
            onContinue={() =>
              Component.State.setCurrentStep(BacktestInitSteps.Callendar)
            }
            onChangeCashAllocation={onChangeCashAllocation}
            cashAllocation={cashAllocation}
            cashError={''}
            lowCashBalance={''}
            marketStructure={marketStructure}
            isLoading={false}
          />,
          <BacktestCallendar
            startDate={startDate}
            onCancel={onCancel}
            onSubmit={onDateSubmit}
            onUpdate={onDateUpdate}
          />,
        ]}
      />
    </BottomSheet>
  );
};

export default BacktestInitBottomSheet;
