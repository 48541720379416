import { getMarketStructure, getPairBalance } from 'apis/exchange';
import { HANDLED_ERROR_CODES } from 'constants/app/error';
import {
  MIN_AMOUNT_BALANCE_MULTIPLIER,
  MIN_CASH_BALANCE_MULTIPLIER,
} from 'constants/app/exchange';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { StrategyAllocations } from 'types/strategies';
import { checkHandledErrorCode } from 'utils/http';
import { useComponentState } from './state';
import {
  InitialAllocationTypes,
  MakeLiveBottomsheetProps,
  MakeLiveSteps,
  TradingModeTypes,
} from './types';
import { getLiveStrategyAllocation } from 'apis/startegies';
import { Allocations } from './components/Allocations';
import { Analytics } from 'utils/analytics';
import {
  action_deploy_strategy,
  page_str_backtest,
  page_strategies,
} from 'constants/app/analyticsEvents';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { useApplicationContainer } from 'containers/application';
import { OnCloseActionType } from 'components/SubscriptionConfirmation/types';

export const useComponentLogic = (props: MakeLiveBottomsheetProps) => {
  const State = useComponentState(props);
  const { onMakeLive } = props;
  const CommonDataContainer = useUserCommonDataContainer();
  const AppContainer = useApplicationContainer();

  const moveToNextStep = () => {
    switch (State.currentStep) {
      case MakeLiveSteps.TradingMode:
        State.setCurrentStep(MakeLiveSteps.Allocations);
        break;

      case MakeLiveSteps.Allocations:
        State.setCurrentStep(MakeLiveSteps.Confirmation);
        break;

      default:
        break;
    }
  };

  const moveToPreviousStep = () => {
    switch (State.currentStep) {
      case MakeLiveSteps.Allocations:
        State.setCurrentStep(MakeLiveSteps.TradingMode);
        break;

      default:
        break;
    }
  };

  const onDismissBottomSheet = () => {
    resetDeployForm();
    props.onDismiss();
  };

  const updateTradingMode = (mode: TradingModeTypes) => {
    State.setTradingMode(mode);
    State.setCashAllocation('');
    State.setPositionAllocation('');
  };

  const updateAllocationType = (type: InitialAllocationTypes) => {
    State.setInitialAllocationType(type);
    State.setCashAllocation('');
    State.setPositionAllocation('');
  };

  const onChangeCashAllocation = (value: string) => {
    State.setCashAllocation(value);
    const min = State.marketStructure?.limits.cost.min as number;
    if (Number(value) < MIN_CASH_BALANCE_MULTIPLIER * min) {
      State.setCashError(
        `Amount must be greater than ${MIN_CASH_BALANCE_MULTIPLIER * min}`,
      );
    } else {
      State.setCashError('');
    }
  };

  const onChangePositionAllocation = (value: string) => {
    const decimalSplit = value.split('.');
    if (decimalSplit.length > 1) {
      const precisionLength = decimalSplit[1].length;
      const requiredPrecision = State.marketStructure?.precision.amount;
      if (
        requiredPrecision !== undefined &&
        precisionLength > requiredPrecision
      ) {
        if (requiredPrecision > 0) {
          decimalSplit[1] = decimalSplit[1].substring(0, requiredPrecision); // Truncate the fraction part
          value = decimalSplit[0] + '.' + decimalSplit[1];
        } else {
          value = decimalSplit[0]; // If required precision is 0, take only the integer part
        }
      }
    }

    State.setPositionAllocation(value);
    const min = State.marketStructure?.limits.amount.min as number;
    if (Number(value) < MIN_AMOUNT_BALANCE_MULTIPLIER * min) {
      State.setPositionError(
        `Amount must be greater than ${MIN_AMOUNT_BALANCE_MULTIPLIER * min}`,
      );
    } else {
      State.setPositionError('');
    }
  };

  const resetDeployForm = () => {
    State.setCashAllocation('');
    State.setPositionAllocation('');
    State.setTradingMode(TradingModeTypes.Paper);
    State.setInitialAllocationType(InitialAllocationTypes.Cash);
    State.setCurrentStep(MakeLiveSteps.TradingMode);
  };

  const onSubmitTradingMode = async () => {
    State.setIsLoading(true);
    let exchangeNotLinked = false;
    if (State.tradingMode === TradingModeTypes.Live) {
      try {
        const balance = await getPairBalance(
          props.basicStrategyParams.exchange,
          props.basicStrategyParams.symbol,
        );
        State.setExchangeBalances(balance);
        if (props.basicStrategyParams.id) {
          const allocation = await getLiveStrategyAllocation(
            props.basicStrategyParams.id,
          );
          if (allocation) {
            if (allocation.position) {
              updateAllocationType(InitialAllocationTypes.Position);
              onChangePositionAllocation(
                allocation.position.quantity.toString(),
              );
            } else {
              updateAllocationType(InitialAllocationTypes.Cash);
              onChangeCashAllocation(allocation.cash.available.toString());
            }
          }
        }
      } catch (error: any) {
        exchangeNotLinked = checkHandledErrorCode(
          error,
          HANDLED_ERROR_CODES.UserExchangeNotLinked,
        );
        if (exchangeNotLinked) {
          State.setTradingModeWarning(
            `${props.basicStrategyParams.exchange} is not linked. Please go to setting and link the Exchange before making the strategy live.`,
          );
        } else {
          onDismissBottomSheet();
          toast.error('Something Went Wrong, Please Try Again!.');
        }
        State.setIsLoading(false);
      }
    }
    if (!exchangeNotLinked) {
      try {
        const marketStructure = await getMarketStructure(
          props.basicStrategyParams.exchange,
          props.basicStrategyParams.symbol,
        );
        State.setMarketStructure(marketStructure);
        State.setIsLoading(false);
        moveToNextStep();
      } catch (error) {
        State.setIsLoading(false);
        onDismissBottomSheet();
        toast.error('Something Went Wrong, Please Try Again!.');
      }
    }
  };

  const checkMakeLiveAccess = () => {
    const strategyId = props.basicStrategyParams.id;
    const maxStrategies =
      CommonDataContainer.State.userSettings?.plan?.planDetails
        ?.maxStrategies ?? 0;
    const hasAccess =
      !!strategyId ||
      CommonDataContainer.State.userStrategies.length + 1 <= maxStrategies;
    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `You have reached the maximum limit of strategies for your current plan. Please upgrade your plan to create more strategies.`,
        screen: page_strategies,
        reason: 'maxStrategies',
      });
      return false;
    } else {
      AppContainer.hideSubscriptionConfirmation();
      return true;
    }
  };

  const makeStrategyLive = () => {
    if (!checkMakeLiveAccess()) {
      return;
    }
    // Put allocations
    State.setIsLoading(true);
    let allocations: StrategyAllocations | undefined;
    switch (State.initialAllocationType) {
      case InitialAllocationTypes.Cash:
        allocations = {
          position: null,
          cash: {
            symbol: props.basicStrategyParams.symbol,
            total_allocation: Number(
              Number(State.cashAllocation).toFixed(
                State.marketStructure?.precision.price,
              ),
            ),
            available: Number(State.cashAllocation),
          },
        };
        break;

      case InitialAllocationTypes.Position:
        allocations = {
          position: {
            symbol: props.basicStrategyParams.symbol,
            quantity: Number(State.positionAllocation),
            avg_price: State.marketStructure?.ticker.close as number,
            amount:
              (State.marketStructure?.ticker.close as number) *
              Number(State.positionAllocation),
            commission: 0,
          },
          cash: {
            symbol: props.basicStrategyParams.symbol,
            total_allocation: Number(
              (
                (State.marketStructure?.ticker.close as number) *
                Number(State.positionAllocation)
              ).toFixed(State.marketStructure?.precision.price),
            ),
            available: 0,
          },
        };
        break;

      default:
        break;
    }

    // Update Strategy & status with appropriate trading mode
    onMakeLive(State.tradingMode, allocations as StrategyAllocations).finally(
      () => {
        Analytics.track(action_deploy_strategy, {
          screen: page_str_backtest,
          tradingMode: State.tradingMode,
          allocations: !!allocations ? JSON.stringify(allocations) : undefined,
        });
        State.setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    // Check if user is low on balance (only for live trading)
    if (
      State.tradingMode === TradingModeTypes.Live &&
      State.exchangeBalances &&
      State.marketStructure
    ) {
      const symbolSplit = props.basicStrategyParams.symbol.split('/');
      const base = symbolSplit[0];
      const quote = symbolSplit[1];

      if (
        State.exchangeBalances[quote].free <
        MIN_CASH_BALANCE_MULTIPLIER * State.marketStructure?.limits.cost.min
      ) {
        State.setLowCashBalance(
          `Low ${quote} Balance (${
            State.exchangeBalances[quote].free
          }) on your linked exchange. You need at least ${
            MIN_CASH_BALANCE_MULTIPLIER * State.marketStructure?.limits.cost.min
          } ${quote} in your exchange wallet.`,
        );
      } else {
        State.setLowCashBalance('');
      }

      if (
        State.exchangeBalances[base].free <
        MIN_AMOUNT_BALANCE_MULTIPLIER * State.marketStructure?.limits.amount.min
      ) {
        State.setLowPositionBalance(
          `Low ${base} Balance (${
            State.exchangeBalances[base].free
          }) on your linked exchange. You need at least ${
            MIN_AMOUNT_BALANCE_MULTIPLIER *
            State.marketStructure?.limits.amount.min
          } ${base} in your exchange wallet.`,
        );
      } else {
        State.setLowPositionBalance('');
      }
    } else {
      State.setLowPositionBalance('');
      State.setLowCashBalance('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.marketStructure, State.exchangeBalances]);

  return {
    State,
    moveToNextStep,
    moveToPreviousStep,
    onDismissBottomSheet,
    onChangeCashAllocation,
    onChangePositionAllocation,
    updateTradingMode,
    updateAllocationType,
    onSubmitTradingMode,
    makeStrategyLive,
  };
};
