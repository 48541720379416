import Loader from 'components/Loader';
import MessageContainer from 'components/MessageContainer';
import { MessageContainerType } from 'components/MessageContainer/types';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import RadioButton from 'components/RadioButton';
import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import { AiOutlineClose, AiOutlineRocket } from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import { InitialAllocationTypes } from '../../types';
import styles from '../makeliveCommon.module.css';
import { AllocationsProps } from './types';
import { PUBLIC_URL } from 'utils/environment';

export const Allocations = (props: AllocationsProps) => {
  const {
    onCancel,
    onContinue,
    quoteCurrency,
    baseCurrency,
    initialAllocationType,
    setInitialAllocationType,
    onChangeCashAllocation,
    onChangePositionAllocation,
    cashAllocation,
    positionAllocation,
    cashError,
    positionError,
    lowCashBalance,
    lowPositionBalance,
    marketStructure,
    isLoading,
  } = props;
  const { isDesktop } = useDisplayType();
  return (
    <>
      <div className={isDesktop ? styles.container : styles.mobileContainer}>
        {isLoading && <Loader containerClassName={styles.loaderContainer} />}
        <div className={styles.inputContainer}>
          <div className={styles.inputName}>Configure Initial Allocations</div>
          <div>
            <RadioButton
              text={'Start with Cash'}
              selected={initialAllocationType === InitialAllocationTypes.Cash}
              onSelect={() =>
                setInitialAllocationType(InitialAllocationTypes.Cash)
              }
              description={`Initial cash allocation for strategy in quote currency (${quoteCurrency}). This amount is used to take a postion in the market when
                    the buying condition of the strategy is met.`}
              showOnSelect={
                lowCashBalance ? (
                  <MessageContainer
                    message={lowCashBalance}
                    type={MessageContainerType.Warning}
                  />
                ) : (
                  <div className={styles.inputContainer}>
                    <div className={styles.cashInput}>
                      <TextInput
                        placeholder={`Amount in ${quoteCurrency}`}
                        inputClass={styles.basicParamInput}
                        value={cashAllocation}
                        type={InputTypes.NUMBER}
                        onChange={(v) => onChangeCashAllocation(v)}
                        renderEmptyErrorSpan={false}
                        error={cashError}
                      />
                      <div className={styles.icon}>
                        <img
                          className={styles.currencyIcon}
                          src={`${PUBLIC_URL}/icons/crypto/${quoteCurrency.toLocaleLowerCase()}.svg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
                          }}
                          width={16}
                          alt={'Symbol Icon'}
                        />
                        <div className={styles.currencyText}>
                          {quoteCurrency.toLocaleUpperCase()}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description}></div>
                  </div>
                )
              }
            />
            <RadioButton
              text={'Start with a Position'}
              selected={
                initialAllocationType === InitialAllocationTypes.Position
              }
              onSelect={() =>
                setInitialAllocationType(InitialAllocationTypes.Position)
              }
              description={`Initial position for strategy in base currency (${baseCurrency}). This amount is used to place a sell order in the market when
                    the selling condition of the strategy is met.`}
              showOnSelect={
                lowPositionBalance ? (
                  <MessageContainer
                    message={lowPositionBalance}
                    type={MessageContainerType.Warning}
                  />
                ) : (
                  <div className={styles.inputContainer}>
                    <div className={styles.cashInput}>
                      <TextInput
                        placeholder={`Amount in ${baseCurrency}`}
                        inputClass={styles.basicParamInput}
                        value={positionAllocation}
                        type={InputTypes.NUMBER}
                        onChange={(v) => onChangePositionAllocation(v)}
                        renderEmptyErrorSpan={false}
                        error={positionError}
                      />
                      <div className={styles.icon}>
                        <img
                          className={styles.currencyIcon}
                          src={`${PUBLIC_URL}/icons/crypto/${baseCurrency.toLocaleLowerCase()}.svg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
                          }}
                          width={16}
                          alt={'Symbol Icon'}
                        />
                        <div className={styles.currencyText}>
                          {baseCurrency.toLocaleUpperCase()}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description}>
                      {positionAllocation && (
                        <div>
                          ~$
                          {(
                            (marketStructure?.ticker.close as number) *
                            Number(positionAllocation)
                          ).toFixed(marketStructure?.precision.price)}{' '}
                          {quoteCurrency} at current price.
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <PushButton
          additionalClassName={styles.actionButton}
          title={'Cancel'}
          onClick={onCancel}
          icon={<AiOutlineClose />}
          type={PushButtonType.Secondary}
        />
        <PushButton
          additionalClassName={styles.actionButton}
          disabled={
            isLoading ||
            (initialAllocationType === InitialAllocationTypes.Cash &&
              !cashAllocation) ||
            (initialAllocationType === InitialAllocationTypes.Cash &&
              !!cashError) ||
            (initialAllocationType === InitialAllocationTypes.Position &&
              !positionAllocation) ||
            (initialAllocationType === InitialAllocationTypes.Position &&
              !!positionError) ||
            (initialAllocationType === InitialAllocationTypes.Cash &&
              !!lowCashBalance) ||
            (initialAllocationType === InitialAllocationTypes.Position &&
              !!lowPositionBalance)
          }
          title='Deploy'
          onClick={onContinue}
          icon={<AiOutlineRocket />}
        />
      </div>
    </>
  );
};
