import { PREFERRED_PORTFOLIO_CURRENCY } from 'constants/app/localstore';

export const getPreferredCurrency = () => {
  let preferredPortfolioCurrency = localStorage.getItem(
    PREFERRED_PORTFOLIO_CURRENCY,
  );
  const defaultPortfolioCurrency = {
    value: 'usd',
    label: 'USD',
  };
  return preferredPortfolioCurrency
    ? {
        value: preferredPortfolioCurrency,
        label: preferredPortfolioCurrency.toUpperCase(),
      }
    : defaultPortfolioCurrency;
};

export const getPreferedCheckoutCurrency = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (['Asia/Calcutta', 'Asia/Kolkata'].includes(tz)) {
    return 'inr';
  } else {
    return 'usd';
  }
};
