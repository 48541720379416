import { useState } from 'react';
import { AccountBalances, PorfolioHistoryScanItem } from 'types/portfolio';
import { createContainer } from 'unstated-next';
import { ExchangeStateProps } from './types';

const defaultInitialState: ExchangeStateProps = {
  exchangeSymbols: [],
};

const useStateDefination = (initialState = defaultInitialState) => {
  // State Properties
  const [exchangeSymbols, setExchangeSymbols] = useState<string[]>(
    defaultInitialState.exchangeSymbols,
  );
  const [accountBalances, setAccountBalances] =
    useState<AccountBalances | null>(null);
  const [lastDayPortfolioScan, setLastDayPorfoiloScan] =
    useState<PorfolioHistoryScanItem>();

  return {
    exchangeSymbols,
    setExchangeSymbols,
    accountBalances,
    setAccountBalances,
    lastDayPortfolioScan,
    setLastDayPorfoiloScan,
  };
};

// Export Exchange Context
export const stateContainer = createContainer(useStateDefination);
export const ExchangeState = {
  Provider: stateContainer.Provider,
  get: stateContainer.useContainer,
};
