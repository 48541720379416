import Loader from 'components/Loader';
import MessageContainer from 'components/MessageContainer';
import { MessageContainerType } from 'components/MessageContainer/types';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import RadioButton from 'components/RadioButton';
import { AiOutlineClose } from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import { TradingModeTypes } from '../../types';
import styles from '../makeliveCommon.module.css';
import { TradingModeProps } from './types';

export const TradingMode = (props: TradingModeProps) => {
  const {
    onCancel,
    onContinue,
    selectedTradingMode,
    setTradingMode,
    isLoading,
    warning,
    tradingModeOptions,
  } = props;
  const { isDesktop } = useDisplayType();
  return (
    <>
      <div className={isDesktop ? styles.container : styles.mobileContainer}>
        {isLoading && <Loader containerClassName={styles.loaderContainer} />}
        <div className={styles.inputContainer}>
          <div className={styles.inputName}>Select a Trading Mode</div>
          <div>
            {tradingModeOptions.includes(TradingModeTypes.Paper) && (
              <RadioButton
                text={'Paper Trading'}
                selected={TradingModeTypes.Paper === selectedTradingMode}
                onSelect={() => setTradingMode(TradingModeTypes.Paper)}
                description='Paper Trading mode allows an investor to test their strategy without risking real money. Market Orders are simulated based on actual status of Order Book on your exchange in real time.'
              />
            )}
            {tradingModeOptions.includes(TradingModeTypes.Live) && (
              <RadioButton
                text={'Live Trading'}
                selected={TradingModeTypes.Live === selectedTradingMode}
                onSelect={() => setTradingMode(TradingModeTypes.Live)}
                description='In Live Trading mode, the strategy deployed places actual orders on your linked exchange on your behalf based on the buy and sell conditions provided in the strategy.'
                showOnSelect={
                  warning ? (
                    <MessageContainer
                      message={warning}
                      type={MessageContainerType.Warning}
                    />
                  ) : undefined
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <PushButton
          additionalClassName={styles.actionButton}
          title={'Cancel'}
          onClick={onCancel}
          disabled={isLoading}
          icon={<AiOutlineClose />}
          type={PushButtonType.Secondary}
        />
        <PushButton
          additionalClassName={styles.actionButton}
          title={isLoading ? 'Please Wait..' : 'Continue'}
          onClick={onContinue}
          disabled={
            isLoading ||
            (TradingModeTypes.Live === selectedTradingMode && !!warning)
          }
        />
      </div>
    </>
  );
};
