import mixpanel from 'mixpanel-browser';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';

const IdentifyUser = async (user: any) => {
  // Add mixpanel Properties
  mixpanel.identify(user.sub);
  mixpanel.people.set({
    $email: user.email,
  });

  // Add Google Analytics Properties
  await FirebaseAnalytics.setUserId({
    userId: user.sub,
  });
};

const track = async (
  eventName: string,
  properties?: Record<string, string | number | null | undefined>,
) => {
  // Send Event to Mixpanel
  mixpanel.track(eventName, properties);

  // Send Event to Google Analytics
  await FirebaseAnalytics.logEvent({
    name: eventName,
    params: properties,
  });
};

const ResetUser = async () => {
  // Reset Mixpanel User
  mixpanel.reset();

  // Reset Google Analytics User
  const platform = Capacitor.getPlatform();
  if (platform === 'ios' || platform === 'android') {
    await FirebaseAnalytics.resetAnalyticsData();
  }
};

export const Analytics = { IdentifyUser, track, ResetUser };
