import {
  AiFillExclamationCircle,
  AiFillCheckCircle,
  AiFillInfoCircle,
} from 'react-icons/ai';
import styles from './messagecon.module.css';
import { MessageContainerProps, MessageContainerType } from './types';

const MessageContainer = (props: MessageContainerProps) => {
  const { message, type } = props;
  let icon = null;
  let backgroundColor = '#fef7df';
  let iconColor = '#cab981';
  let messageColor = '#a59768';
  switch (type) {
    case MessageContainerType.Warning:
      icon = <AiFillExclamationCircle />;
      backgroundColor = '#fef7df';
      iconColor = '#cab981';
      messageColor = '#a59768';
      break;
    case MessageContainerType.Success:
      icon = <AiFillCheckCircle />;
      backgroundColor = '#00ba7c';
      iconColor = '#FFFFFF';
      messageColor = '#FFFFFF';
      break;
    case MessageContainerType.Info:
      icon = <AiFillInfoCircle />;
      backgroundColor = '#7b5fa5';
      iconColor = '#FFFFFF';
      messageColor = '#FFFFFF';
      break;
  }
  return (
    <div className={styles.container} style={{ backgroundColor }}>
      <div className={styles.iconContainer} style={{ color: iconColor }}>
        {icon}
      </div>
      <div className={styles.messageContainer} style={{ color: messageColor }}>
        {message}
      </div>
    </div>
  );
};

export default MessageContainer;
