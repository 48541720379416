export const get_user_watchlist = [
  { base_currency: 'BTC', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ETH', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ALGO', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'AAVE', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'DOGE', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'MATIC', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'SOL', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ETH', exchange_id: 'binance', quote_currency: 'BTC' },
  { base_currency: 'STX', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ADA', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'LINK', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'XRP', exchange_id: 'binance', quote_currency: 'USDT' },
];
