import { AuthenticationState } from 'containers/auth/state';
import { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isDemo } from 'utils/demo';

type ProtectedRouteProps = {
  exact: boolean;
  path: string;
  children: ReactElement;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { exact, path, children } = props;
  const AuthContainerState = AuthenticationState.get();
  const isAuthenticated = isDemo ? true : AuthContainerState.isAuthenticated;
  let isRestricted = !isAuthenticated;
  const isDisabled = false;

  return (
    <Route
      exact={exact}
      path={path}
      render={() =>
        isDisabled ? (
          <Redirect to={'/'} />
        ) : isRestricted ? (
          <Redirect to='/login' />
        ) : (
          children
        )
      }
    />
  );
};

export default ProtectedRoute;
