import styles from './assets.module.css';
import { useDisplayType } from 'utils/display';
import { AssetsProps } from './types';
import { DailyChange } from 'components/DailyChange';
import { PUBLIC_URL } from 'utils/environment';

export const Assets = (props: AssetsProps) => {
  const { currency, totals, dailyChange } = props;

  const { isDesktop } = useDisplayType();
  return (
    <table className={isDesktop ? styles.table : styles.tableMobile}>
      <thead>
        <tr>
          <th align='left'>Name</th>
          <th align='right'>Qty</th>
          {isDesktop && <th align='right'>Price ({currency})</th>}
          <th align='right'>Total ({currency})</th>
          <th align='right'>Daily Change ({currency})</th>
        </tr>
      </thead>
      <tbody>
        {totals?.map((i) => {
          const qtyChanged = !(
            dailyChange?.assetsChange[i.symbol].qtyChange === 0
          );
          const changeType =
            dailyChange && qtyChanged
              ? dailyChange?.assetsChange[i.symbol].qtyChange > 0
                ? '+'
                : '-'
              : '';
          return (
            <tr key={i.symbol}>
              <td align='left'>
                <div className={styles.assetNameContainer}>
                  <img
                    src={`${PUBLIC_URL}/icons/crypto/${i.symbol.toLocaleLowerCase()}.svg`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
                    }}
                    width={22}
                    alt={'Symbol Icon'}
                    className={styles.icon}
                  />
                  {isDesktop && <span>{i.name}</span>}
                  {isDesktop ? (
                    <span>{` : ${i.symbol}`}</span>
                  ) : (
                    <span>{i.symbol}</span>
                  )}
                </div>
              </td>
              <td align='right'>
                {i.balance.toFixed(2)}{' '}
                {qtyChanged && dailyChange ? (
                  <span className={styles.subText}>
                    ({changeType}
                    {Math.abs(
                      dailyChange?.assetsChange[i.symbol].qtyChange,
                    ).toFixed(2)}
                    )
                  </span>
                ) : (
                  ''
                )}
              </td>
              {isDesktop && <td align='right'>{i.usdPrice.toFixed(2)}</td>}
              <td align='right'>{i.amount.toFixed(2)}</td>
              <td align='right'>
                {dailyChange ? (
                  <DailyChange
                    changeValue={
                      dailyChange?.assetsChange[i.symbol].valueChange
                    }
                    changePercent={
                      dailyChange?.assetsChange[i.symbol].percentageChange
                    }
                    showPercent={!qtyChanged}
                  />
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
