import PageHeader from 'components/PageHeader';
import {
  MakeLiveBottomsheetProps,
  MakeLiveSteps,
  MarketStructure,
} from './types';
import BottomSheet from 'components/BottomSheet';
import StepFlow from 'components/StepFlow';
import { useComponentLogic } from './logics';
import { TradingMode } from './components/TradingMode';
import { Allocations } from './components/Allocations';

export const MakeLiveBottomsheet = (props: MakeLiveBottomsheetProps) => {
  const { isBottomSheetOpen, basicStrategyParams, tradingModeOptions } = props;
  const currencyArray = basicStrategyParams.symbol.split('/');
  const quoteCurrency = currencyArray[1];
  const baseCurrency = currencyArray[0];
  const Component = useComponentLogic(props);
  return (
    <BottomSheet
      isOpen={isBottomSheetOpen}
      onDismiss={Component.onDismissBottomSheet}
      header={
        <PageHeader
          title='Deploy Strategy.'
          subTitle='Confirm your strategy parameters.'
          showBackButton={
            Component.State.currentStep !== MakeLiveSteps.TradingMode
          }
          customActionOnBack={Component.moveToPreviousStep}
        />
      }
    >
      <StepFlow
        currentStep={Component.State.currentStep}
        doNotAnimateFirstStep={true}
        stepComponents={[
          <TradingMode
            selectedTradingMode={Component.State.tradingMode}
            setTradingMode={Component.updateTradingMode}
            onCancel={Component.onDismissBottomSheet}
            onContinue={Component.onSubmitTradingMode}
            isLoading={Component.State.isLoading}
            warning={Component.State.tradingModeWarning}
            tradingModeOptions={tradingModeOptions}
          />,
          <Allocations
            isLoading={Component.State.isLoading}
            baseCurrency={baseCurrency}
            quoteCurrency={quoteCurrency}
            onCancel={Component.onDismissBottomSheet}
            onContinue={Component.makeStrategyLive}
            initialAllocationType={Component.State.initialAllocationType}
            setInitialAllocationType={Component.updateAllocationType}
            onChangeCashAllocation={Component.onChangeCashAllocation}
            onChangePositionAllocation={Component.onChangePositionAllocation}
            cashAllocation={Component.State.cashAllocation}
            positionAllocation={Component.State.positionAllocation}
            cashError={Component.State.cashError}
            positionError={Component.State.positionError}
            lowCashBalance={Component.State.lowCashBalance}
            lowPositionBalance={Component.State.lowPositionBalance}
            marketStructure={Component.State.marketStructure as MarketStructure}
          />,
        ]}
      />
    </BottomSheet>
  );
};
