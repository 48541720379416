import { useReducer, useState } from 'react';
import { NewsFeed, NEWS_TIMEFRAME } from 'types/news';

export const useComponentState = () => {
  const [newsTimeframe, setNewsTimeframe] = useState<NEWS_TIMEFRAME>(
    NEWS_TIMEFRAME.DAILY,
  );

  const initialNewsState = {};

  function newsReducer(
    state: Record<string, NewsFeed>,
    action: { type: string; payload: any },
  ) {
    switch (action.type) {
      case 'SET_NEWS_ITEMS':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            data: action.payload.data,
            fetchMore: true,
          },
        };
      case 'SET_LOADING':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            isLoading: action.payload.isLoading,
          },
        };
      case 'SET_ERROR':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            error: action.payload.error,
          },
        };
      case 'SET_FETCH_MORE':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            fetchMore: action.payload.fetchMore,
          },
        };
      case 'SET_SUMMARY':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            summary: action.payload.summary,
          },
        };
      case 'SET_TRENDING_SELECTED':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            selectedTrending: action.payload.selectedTrending,
            trendingSearchData: [],
            isLoading: action.payload.selectedTrending ? true : false,
          },
        };
      case 'SET_TRENDING_SEARCH_DATA':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            trendingSearchData: action.payload.trendingSearchData,
            isLoading: false,
          },
        };
      case 'SET_SENTIMENT_CHART_DATA':
        return {
          ...state,
          [action.payload.key]: {
            ...state[action.payload.key],
            sentimentChartData: action.payload.sentimentChartData,
          },
        };
      default:
        return state;
    }
  }

  const [newsItems, updateNewsFeed] = useReducer(newsReducer, initialNewsState);

  return {
    newsItems,
    updateNewsFeed,
    newsTimeframe,
    setNewsTimeframe,
  };
};
