import styles from './splash.module.css';
import { PUBLIC_URL } from 'utils/environment';

const SplashLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <img
          src={`${PUBLIC_URL}/android-chrome-512x512.png`}
          width={48}
          alt={'Logo'}
        />
        <div className={styles.progressBar}>
          <span className={styles.bar}>
            <span className={styles.progress}></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SplashLoader;
