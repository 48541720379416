// Import necessary components and hooks
import styles from './chat.module.css'; // Ensure proper CSS module import
import {
  ChatMessageType,
  CHAT_ACTION,
  HeaderProps,
  MessagesProps,
  SuggestedActionsProps,
  FooterProps,
} from './types'; // Import types
import { useComponentLogics } from './logics'; // Custom hook for logic

// UI Components
import FloatingButton from 'components/FloatingButton';
import BottomSheet from 'components/BottomSheet';
import PageHeader from 'components/PageHeader';
import Loader from 'components/Loader';
import PushButton from 'components/PushButton';
import TextareaAutosize from 'react-textarea-autosize';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  AiFillMessage,
  AiOutlineCloudUpload,
  AiOutlineSend,
} from 'react-icons/ai'; // Icons
import { PushButtonType } from 'components/PushButton/types';

export const Chat = () => {
  const { State, toggleChat, handleSend, handleActionSend } =
    useComponentLogics();

  return (
    <div>
      <FloatingButton
        icon={<AiFillMessage size={'24px'} />}
        onClick={toggleChat}
        additionalClassName={styles.floatingButton}
      />
      {State.isOpen && (
        <BottomSheet
          isOpen={State.isOpen}
          onDismiss={toggleChat}
          header={<Header toggleChat={toggleChat} />}
          footer={<Footer State={State} handleSend={handleSend} />}
        >
          <Messages State={State} handleActionSend={handleActionSend} />
        </BottomSheet>
      )}
    </div>
  );
};

// Header Component
const Header = (props: HeaderProps) => (
  <PageHeader
    title='Strategy Assistant'
    subTitle='Talk to the AI trading assistant.'
    showBackButton={true}
    customActionOnBack={props.toggleChat}
  />
);

// Messages Component
const Messages = (props: MessagesProps) => (
  <div className={styles.messagesContainer}>
    {props.State.messages.map((msg: ChatMessageType, index: number) => (
      <div
        key={index}
        className={`${styles.message} ${
          msg.sender === 'user' ? styles.userMessage : styles.assistantMessage
        }`}
      >
        <div
          className={`${styles.messageBubble} ${
            msg.sender === 'user' ? styles.userBubble : styles.assistantBubble
          }`}
        >
          {typeof msg.message === 'string' ? (
            <MarkdownPreview
              source={msg.message as string}
              style={{
                backgroundColor: 'transparent',
                color: msg.sender === 'user' ? '#fff' : '#5f5f5f',
                fontSize: '13px',
              }}
            />
          ) : (
            msg.message
          )}
        </div>
      </div>
    ))}
    <SuggestedActions
      State={props.State}
      handleActionSend={props.handleActionSend}
    />
    {props.State.isLoading && (
      <Loader containerClassName={styles.loaderContainer} />
    )}
  </div>
);

// Suggested Actions Component
const SuggestedActions = (props: SuggestedActionsProps) => (
  <div className={styles.suggestedActions}>
    <PushButton
      additionalClassName={styles.actionButton}
      title='Share Strategy'
      onClick={() => props.handleActionSend(CHAT_ACTION.SHARE_STRATEGY)}
      disabled={props.State.isLoading}
      type={PushButtonType.Secondary}
    />
    <PushButton
      additionalClassName={styles.actionButton}
      title='Share Backtest'
      onClick={() => props.handleActionSend(CHAT_ACTION.SHARE_BACKTEST)}
      disabled={props.State.isLoading}
      type={PushButtonType.Secondary}
    />
  </div>
);

// Footer Component
const Footer = (props: FooterProps) => (
  <div className={styles.actionsContainer}>
    <TextareaAutosize
      placeholder='Enter your message'
      className={styles.textarea}
      onChange={(e) => props.State.setInputValue(e.target.value)}
      value={props.State.inputValue}
      maxRows={10}
    />
    <PushButton
      title=''
      onClick={props.handleSend}
      disabled={props.State.isLoading || !props.State.inputValue}
      type={PushButtonType.Primary}
      icon={<AiOutlineSend />}
    />
  </div>
);

export const ActionMessage = (action: CHAT_ACTION) => {
  switch (action) {
    case CHAT_ACTION.SHARE_STRATEGY:
      return (
        <div className={styles.actionMessageContainer}>
          <AiOutlineCloudUpload className={styles.actionMessageIcon} />
          Strategy Shared
        </div>
      );
    case CHAT_ACTION.SHARE_BACKTEST:
      return (
        <div className={styles.actionMessageContainer}>
          <AiOutlineCloudUpload className={styles.actionMessageIcon} />
          Backtest Shared
        </div>
      );
    default:
      return null;
  }
};
