import { useState } from 'react';
import { SelectOption } from 'types/app';
import { LinkedExchange } from 'types/exchange';

export const useComponentState = () => {
  const [selectedExchange, setExchange] = useState<SelectOption | null>(null);
  const [linkedExchanges, setLinkedExchanges] = useState<LinkedExchange[]>([]);
  const [isLoadingExchanges, setIsLoadingExchanges] = useState<boolean>(false);
  const [pk, setPrivateKey] = useState<string>('');
  const [sk, setSecretKey] = useState<string>('');

  const clearState = () => {
    setExchange(null);
    setPrivateKey('');
    setSecretKey('');
  };

  return {
    selectedExchange,
    setExchange,
    pk,
    setPrivateKey,
    sk,
    setSecretKey,
    linkedExchanges,
    setLinkedExchanges,
    clearState,
    isLoadingExchanges,
    setIsLoadingExchanges,
  };
};
