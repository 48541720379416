import CustomDatePicker from 'components/DatePicker';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import { AiOutlineClose, AiOutlineRocket } from 'react-icons/ai';
import styles from './bc.module.css';
import { BacktestCallendarProps } from './types';

export const BacktestCallendar = (props: BacktestCallendarProps) => {
  const { onCancel, onSubmit, onUpdate, startDate } = props;
  return (
    <>
      <CustomDatePicker
        startDate={startDate}
        onChangeStartDate={onUpdate}
        isOpen={true}
      />
      <div className={styles.actionsContainer}>
        <PushButton
          additionalClassName={styles.actionButton}
          title={'Cancel'}
          onClick={onCancel}
          type={PushButtonType.Secondary}
          disabled={false}
          icon={<AiOutlineClose />}
        />
        <PushButton
          additionalClassName={styles.actionButton}
          title={'Run Backtest'}
          onClick={onSubmit}
          disabled={false}
          icon={<AiOutlineRocket />}
        />
      </div>
    </>
  );
};
