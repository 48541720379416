import {
  PREFERRED_EXCHANGE_KEY,
  PREFERRED_INDICATORS,
  PREFERRED_SYMBOL_KEY,
} from 'constants/app/localstore';
import { useState } from 'react';
import { SelectOption } from 'types/app';

export const useComponentState = () => {
  let preferredExchangeId =
    localStorage.getItem(PREFERRED_EXCHANGE_KEY) ?? 'binance';
  let preferredExchangeSymbol =
    localStorage.getItem(PREFERRED_SYMBOL_KEY) ?? 'BTC/USDT';
  let preferredChartIndicators = JSON.parse(
    localStorage.getItem(PREFERRED_INDICATORS) ?? '[]',
  );

  const [exchange, setExchange] = useState<string>(preferredExchangeId);
  const [symbol, setSymbol] = useState<string>(preferredExchangeSymbol);
  const [newsSymbol, setNewsSymbol] = useState<string>('');
  const [showChartOnMobile, setShowChart] = useState<boolean>(false);

  const [indicators, setIndicators] = useState<SelectOption[]>(
    preferredChartIndicators,
  );

  return {
    exchange,
    setExchange,
    symbol,
    setSymbol,
    indicators,
    setIndicators,
    newsSymbol,
    setNewsSymbol,
    showChartOnMobile,
    setShowChart,
  };
};
