import { TrendingProps } from './types';
import styles from './trending.module.css';
import { useDisplayType } from 'utils/display';

export const Trending = (props: TrendingProps) => {
  const { data, onTrendingClick, selected } = props;
  return data ? (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        {data && (
          <div className={styles.contents}>
            {Object.keys(data).map((topic, i) => (
              <div
                className={
                  topic === selected?.label
                    ? styles.selectedTopic
                    : styles.topic
                }
                key={i}
                onClick={() =>
                  onTrendingClick({
                    label: topic,
                    ranks: data[topic].ranks,
                    symbol: data[topic].symbol,
                  })
                }
              >
                <div>
                  <div className={styles.title}>
                    {topic}
                    {data[topic].symbol ? (
                      <div
                        className={styles.symbol}
                      >{`${data[topic].symbol}`}</div>
                    ) : null}
                  </div>
                  <div
                    className={styles.subtitle}
                  >{`${data[topic].ranks.length} News Items`}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : null;
};
