import { useAuthContainer } from 'containers/auth';
import { useComponentState } from './state';
import { OnboardingSteps } from './types';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { toast } from 'react-toastify';
import { isValidEmail } from 'utils/validations';
import { HANDLED_ERROR_CODES } from 'constants/app/error';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';

import {
  event_signup,
  event_verified_email,
  success_password_update,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const State = useComponentState();
  const AuthContainer = useAuthContainer();

  useEffect(() => {
    const hubListenerCancel = Hub.listen(
      'auth',
      ({ payload: { event, data } }) => {
        if (event === 'signIn') {
          console.log('User has signed in');
          console.log(data);
          // AuthContainer.onLoginSuccess(data);
        }
      },
    );

    return hubListenerCancel;
  }, []);

  const updateCurrentStep = (c: OnboardingSteps) => {
    State.setCurentStep(c);
  };

  const onLoginWithFacebook = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  };

  const onLoginWithGoogle = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  // Submit login form
  const onSubmitLoginForm = async () => {
    // Check for valid email
    if (!isValidEmail(State.loginFormValues.email)) {
      toast.error('Please enter a valid e-mail.');
      return;
    }

    // Initiate Login
    try {
      State.updateLoginFormValues({ type: 'submittingForm', value: true });
      const user = await Auth.signIn(
        State.loginFormValues.email,
        State.loginFormValues.password,
      );
      AuthContainer.onLoginSuccess(user);
    } catch (error: any) {
      if (error.code === HANDLED_ERROR_CODES.UserNotConfirmedException) {
        State.setCurentStep(OnboardingSteps.Verification);
      } else {
        console.log(error);
        error && error.message && toast.error(error.message);
      }
    } finally {
      State.updateLoginFormValues({ type: 'submittingForm', value: false });
    }
  };

  // Submit Signup form
  const onSubmitSignupForm = async () => {
    // Check for valid email
    if (!isValidEmail(State.signupFormValues.email)) {
      toast.error('Please enter a valid e-mail.');
      return;
    }

    // Check if rePassword matches
    if (State.signupFormValues.password !== State.signupFormValues.rePassword) {
      toast.error('Passwords do not match.');
      return;
    }

    // Initiate Signup
    try {
      State.updateSignupFormValues({ type: 'submittingForm', value: true });
      await Auth.signUp({
        username: State.signupFormValues.email,
        password: State.signupFormValues.password,
        attributes: {
          email: State.signupFormValues.email,
        },
      });
      State.setCurentStep(OnboardingSteps.Verification);
      Analytics.track(event_signup, {
        email: State.signupFormValues.email,
        method: 'email',
      });
    } catch (error: any) {
      error && error.message && toast.error(error.message);
    } finally {
      State.updateSignupFormValues({ type: 'submittingForm', value: false });
    }
  };

  // On Submit Verification form
  const onSubmitVerificationForm = async () => {
    const email = State.signupFormValues.email
      ? State.signupFormValues.email
      : State.loginFormValues.email;
    if (!isValidEmail(email)) {
      toast.error('Please enter a valid e-mail.');
      return;
    }
    try {
      State.updateSignupFormValues({ type: 'submittingForm', value: true });
      await Auth.confirmSignUp(email, State.signupFormValues.code);
      toast.success('Verification successfull. Please login to continue');
      Analytics.track(event_verified_email, {
        email,
      });
      State.setCurentStep(OnboardingSteps.SignIn);
    } catch (error: any) {
      error && error.message && toast.error(error.message);
    } finally {
      State.updateSignupFormValues({ type: 'submittingForm', value: false });
    }
  };

  // Resend verification Code
  const resendOTP = async () => {
    try {
      const email = State.signupFormValues.email
        ? State.signupFormValues.email
        : State.loginFormValues.email;
      await Auth.resendSignUp(email);
      toast.success('Verification Code resent successfully.');
    } catch (error: any) {
      error && error.message && toast.error(error.message);
    }
  };

  // Send password reset code
  const sendPasswordResetCode = async () => {
    if (State.recoverFormValues.email) {
      try {
        const data = await Auth.forgotPassword(State.recoverFormValues.email);
      } catch (err: any) {
        console.log(err);
        toast.error(err.message ?? 'Something went wrong. Please try again');
      }
    }
  };

  // Submit new password
  const onSubmitNewPassword = async () => {
    if (
      State.recoverFormValues.email &&
      State.recoverFormValues.code &&
      State.recoverFormValues.password
    ) {
      try {
        const data = await Auth.forgotPasswordSubmit(
          State.recoverFormValues.email,
          State.recoverFormValues.code,
          State.recoverFormValues.password,
        );
        updateCurrentStep(OnboardingSteps.SignIn);
        toast.success('Password reset. Please login to continue');
        Analytics.track(success_password_update, {
          email: State.recoverFormValues.email,
        });
      } catch (err: any) {
        console.log(err);
        toast.error(err.message ?? 'Something went wrong. Please try again');
      }
    }
  };

  // Reset email on Forms on Page Change (why? see resendOTP())
  useEffect(() => {
    switch (State.currentStep) {
      case OnboardingSteps.SignIn:
        if (State.signupFormValues.email) {
          State.updateSignupFormValues({ type: 'email', value: '' });
        }
        break;

      case OnboardingSteps.SignUp:
        if (State.loginFormValues.email) {
          State.updateLoginFormValues({ type: 'email', value: '' });
        }
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.currentStep]);

  // Move to signup if query param is present
  useEffect(() => {
    const location = window.location;
    const params = new URLSearchParams(location.search);
    console.log(params);
    if (params.get('a') === 'signup') {
      State.setCurentStep(OnboardingSteps.SignUp);
    }
  }, []);

  return {
    State,
    updateCurrentStep,
    onSubmitLoginForm,
    onSubmitSignupForm,
    onSubmitVerificationForm,
    resendOTP,
    onLoginWithFacebook,
    onLoginWithGoogle,
    sendPasswordResetCode,
    onSubmitNewPassword,
  };
};
