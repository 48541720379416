import styles from './strategies.module.css';
import PageHeader from 'components/PageHeader';
import PushButton from 'components/PushButton';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { useComponentLogic } from './logics';
import { useDisplayType } from 'utils/display';
import NoDataMessage from 'components/NoDataDisplay';
import no_data_illustration from 'assets/svg/no_data_illustration.svg';
import Loader from 'components/Loader';
import { StrategyCard } from 'views/Strategy/components/StrategyCard';
import {
  click_create_new,
  page_strategies,
  page_visit_strategies,
} from 'constants/app/analyticsEvents';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';

const RightMenu = () => {
  const history = useHistory();
  const { isDesktop } = useDisplayType();
  return (
    <div>
      <PushButton
        title={isDesktop ? 'Create New' : ''}
        icon={<AiOutlinePlus />}
        onClick={() => {
          Analytics.track(click_create_new, {
            screen: page_strategies,
          });
          history.push('/app/strategies/strategy/configure');
        }}
      />
    </div>
  );
};

const Strategies = () => {
  const Component = useComponentLogic();
  const { isDesktop } = useDisplayType();

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_strategies);
  }, []);

  return (
    <div className={styles.container}>
      <PageHeader
        title='Strategies'
        subTitle='Manage & Backtest your Startegies '
        rightComponent={<RightMenu />}
        showBackButton={false}
        // secondaryMenu={
        //   <PageMenu
        //     menuItems={[
        //       {
        //         name: 'All',
        //         headerName: '',
        //         link: '/app/strategies',
        //       },
        //     ]}
        //   />
        // }
      />
      <div
        className={
          isDesktop ? styles.listContainer : styles.listContainerMobile
        }
      >
        {Component.userStrategies.map((s) => {
          return (
            <StrategyCard
              onSelect={() => Component.onSelectStrategy(s.id)}
              key={s.id}
              symbolsInfo={s.symbols_info}
              interval={s.strategy.interval}
              tradingMode={s.tradingMode}
            />
          );
        })}
        {Component.userStrategies.length === 0 ? (
          Component.isLoadingUserStrategies ? (
            <Loader containerClassName={styles.loaderContainer} />
          ) : (
            <NoDataMessage
              image={no_data_illustration}
              messageText='You do not have any strategies running at the moment, Click on "Create New" to proceed to create new startegies.'
            />
          )
        ) : null}
      </div>
    </div>
  );
};

export default Strategies;
