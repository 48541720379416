export const PREFERRED_EXCHANGE_KEY = '_uptrend.trade_preferred_ex_id';

export const PREFERRED_SYMBOL_KEY = '_uptrend.trade_preferred_symbol';

export const USER_WATCHLIST_KEY = '_uptrend.trade_user_watchlist';

export const PREFERRED_INDICATORS = '_uptrend.trade_preferred_indicators';

export const PREFERRED_PORTFOLIO_CURRENCY = '_uptrend.trade_portfolio_curr';

export const PUSH_TOKEN_UPDATE_INFO = '_uptrend.trade_push_update_info';

export const USER_COGNITO_AUTH_TOKEN = '_uptrend.user_cognito_auth_token';

export const DISPLAY_ONBOADING = '_uptrend.trade_display_onboarding';
