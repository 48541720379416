import { MarketStructure } from 'views/Strategy/components/MakeLiveBottomsheet/types';

export type BacktestInitBottomSheetProps = {
  startDate: Date;
  onDateUpdate: (value: Date) => void;
  onDateSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
  onChangeCashAllocation: (v: string) => void;
  cashAllocation: string;
  symbol: string;
  marketStructure: MarketStructure | undefined;
  updateMarketStructure: () => void;
};

export enum BacktestInitSteps {
  Allocations = 0,
  Callendar = 1,
}
