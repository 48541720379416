import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef } from 'react';
import { useDisplayType } from 'utils/display';
import { PieChartProps } from './types';

export const PieChart = (props: PieChartProps) => {
  const { data } = props;

  // Make monochrome colors
  const pieColors = (function () {
    const colors = [];
    // @ts-ignore
    const base = '#00ba7c';
    let i: number;

    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten(i / 15)
          .get(),
      );
    }
    return colors;
  })();

  const toPlot = data?.map((t, i) => {
    return {
      name: t.symbol,
      y: t.amount,
      sliced: i === 0 ? true : false,
      selected: i === 0 ? true : false,
    };
  });
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    chart: {
      plotBackgroundColor: undefined,
      plotBorderWidth: undefined,
      plotShadow: false,
      type: 'pie',
      reflow: true,
      height: '50%',
      backgroundColor: 'transparent',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        colors: pieColors,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    title: undefined,
    yAxis: {
      visible: false,
      minPadding: 0,
      maxPadding: 0,
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0,
    },
    series: [
      {
        name: 'Allocation',
        colorByPoint: true,
        type: 'pie',
        data: toPlot,
      },
    ],
  };

  useEffect(() => {
    chartComponentRef.current?.chart?.reflow();
  }, [data]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};
