import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';
import { DatePickerProps } from './types';

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const CustomDatePicker = (props: DatePickerProps) => {
  const { startDate, onChangeStartDate, isOpen } = props;
  return (
    <DatePicker
      showTimeSelect
      selected={startDate}
      onChange={onChangeStartDate}
      open={isOpen}
      dateFormat={'dd MMMM yyyy (HH:mm)'}
    />
  );
};

export default CustomDatePicker;
