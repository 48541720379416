export enum PushButtonType {
  Primary,
  Secondary,
  Danger,
  Grey,
  SimpleText,
}

export type PushButtonProps = {
  title: string;
  onClick: () => void;
  type?: PushButtonType;
  disabled?: boolean;
  additionalClassName?: string;
  icon?: JSX.Element;
};
