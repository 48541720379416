export enum ListButtonType {
  Primary,
  Secondary,
}

export type ListButtonProps = {
  title: string;
  onClick: () => void;
  type?: ListButtonType;
  disabled?: boolean;
  additionalClassName?: string;
};
