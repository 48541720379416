import { useEffect } from 'react';
import { useComponentState } from './state';
import { getCheckoutSession, getPricingTableData } from 'apis/subscriptions';
import { toast } from 'react-toastify';
import { AuthenticationState } from 'containers/auth/state';
import { getPreferedCheckoutCurrency } from 'utils/currency';
import { UserCommonDataState } from 'containers/userCommonData/state';
import { openInAppBrowser } from 'utils/inAppBrowser';
import { Analytics } from 'utils/analytics';
import {
  click_subscribe,
  page_subscription,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const State = useComponentState();
  const AuthState = AuthenticationState.get();
  const CommonDataState = UserCommonDataState.get();

  // Fetch Subscription Products when page loads
  useEffect(() => {
    State.setIsLoadingProducts(true);
    const currency = getPreferedCheckoutCurrency();
    getPricingTableData(currency)
      .then((data) => {
        State.setProducts(data);
      })
      .catch(() => {
        toast.error('Failed to fetch Products, Please try again.');
      })
      .finally(() => {
        State.setIsLoadingProducts(false);
      });
  }, []);

  // Get and redirect to checkout url
  const initiateCheckoutSession = (priceId: string) => {
    State.setIsLoadingCheckout(true);
    const currency = getPreferedCheckoutCurrency();
    getCheckoutSession(
      priceId,
      AuthState.appUser.email,
      currency,
      CommonDataState.userSettings?.plan.customerId,
    )
      .then((data) => {
        openInAppBrowser(data.checkoutUrl);
      })
      .catch(() => {
        toast.error('Failed to initiate checkout. Please try again.');
      })
      .finally(() => {
        State.setIsLoadingCheckout(false);

        // Track event
        Analytics.track(click_subscribe, {
          priceId,
          customerId: CommonDataState.userSettings?.plan.customerId,
          screen: page_subscription,
        });
      });
  };

  return { State, initiateCheckoutSession };
};
