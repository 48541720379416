export const get_backtestResult = {
  open_data: [
    7195.24, 7200.77, 6965.49, 7345.0, 7354.19, 7357.64, 7758.9, 8145.92,
    8054.72, 7817.74, 8198.86, 8020.01, 8184.97, 8110.34, 8814.64, 8820.01,
    8720.15, 8913.27, 8915.09, 8701.72, 8642.35, 8736.04, 8682.77, 8404.52,
    8438.99, 8340.01, 8614.39, 8907.57, 9375.34, 9301.57, 9511.52, 9351.71,
    9384.41, 9331.59, 9291.35, 9197.02, 9612.03, 9772.0, 9813.87, 9895.04,
    10151.72, 9851.74, 10223.08, 10325.33, 10227.78, 10344.36, 9904.46, 9910.7,
    9706.0, 10164.78, 9594.65, 9597.21, 9677.05, 9650.85, 9936.4, 9655.52,
    9316.48, 8786.0, 8823.25, 8690.8, 8523.61, 8530.3, 8911.18, 8760.07,
    8750.99, 9054.64, 9130.89, 8885.25, 8034.76, 7929.87, 7894.57, 7934.58,
    4800.01, 5576.05, 5172.48, 5360.33, 5028.86, 5312.64, 5393.26, 6162.05,
    6204.57, 6187.04, 5816.05, 6465.25, 6744.69, 6677.42, 6737.27, 6359.11,
    6236.65, 5880.5, 6394.45, 6412.14, 6643.36, 6793.86, 6732.97, 6857.41,
    6772.78, 7329.9, 7197.32, 7360.26, 7283.54, 6858.92, 6876.84, 6903.79,
    6838.04, 6868.57, 6621.25, 7101.99, 7026.78, 7248.6, 7121.4, 6828.98,
    6841.36, 7125.12, 7483.96, 7505.0, 7539.03, 7693.1, 7773.51, 7738.58,
    8778.58, 8620.0, 8825.67, 8972.58, 8894.15, 8871.92, 9021.36, 9143.4,
    9986.3, 9800.02, 9539.1, 8722.77, 8562.04, 8810.99, 9309.35, 9791.97,
    9315.96, 9380.81, 9681.11, 9733.93, 9775.13, 9511.43, 9067.51, 9170.0,
    9179.01, 8718.14, 8900.35, 8841.0, 9204.07, 9575.87, 9426.6, 9697.72,
    9448.27, 10202.71, 9518.02, 9666.32, 9788.14, 9621.17, 9666.85, 9746.99,
    9782.0, 9772.44, 9885.22, 9278.88, 9464.96, 9473.34, 9342.1, 9426.05,
    9526.97, 9465.13, 9386.32, 9310.23, 9358.95, 9294.69, 9685.69, 9624.33,
    9298.33, 9249.49, 9162.21, 9012.0, 9116.16, 9192.93, 9138.08, 9231.99,
    9086.54, 9057.79, 9135.0, 9069.41, 9342.47, 9257.4, 9436.06, 9232.42,
    9288.34, 9234.02, 9303.31, 9242.61, 9255.85, 9197.6, 9133.72, 9154.31,
    9170.3, 9208.99, 9160.78, 9390.0, 9518.16, 9603.27, 9538.1, 9700.42,
    9931.54, 11029.96, 10906.27, 11100.52, 11099.79, 11335.46, 11801.17,
    11071.36, 11219.68, 11191.99, 11744.91, 11762.47, 11594.36, 11761.02,
    11681.69, 11892.9, 11392.09, 11564.34, 11779.77, 11760.55, 11852.4,
    11910.99, 12281.15, 11945.1, 11754.38, 11853.54, 11531.23, 11663.51,
    11648.12, 11748.19, 11318.42, 11461.42, 11330.38, 11526.9, 11465.84,
    11711.17, 11649.51, 11921.97, 11388.54, 10138.29, 10446.25, 10166.69,
    10255.89, 10373.45, 10126.66, 10219.29, 10336.86, 10387.89, 10440.67,
    10332.84, 10671.77, 10785.23, 10954.01, 10940.0, 10933.4, 11080.64,
    10920.28, 10417.22, 10529.61, 10241.46, 10736.33, 10686.57, 10728.59,
    10774.26, 10696.11, 10840.58, 10776.59, 10619.13, 10570.4, 10542.07,
    10666.62, 10792.2, 10599.65, 10666.4, 10925.44, 11050.64, 11293.22,
    11369.02, 11528.24, 11420.57, 11417.89, 11505.13, 11319.24, 11360.31,
    11503.14, 11751.46, 11910.0, 12780.75, 12968.84, 12923.06, 13111.73,
    13029.64, 13052.15, 13636.16, 13266.4, 13455.69, 13560.1, 13791.0, 13761.49,
    13549.63, 14023.53, 14144.01, 15590.02, 15579.93, 14818.3, 15475.1,
    15328.41, 15297.21, 15684.25, 16291.85, 16320.04, 16069.56, 15957.0,
    16713.08, 17659.38, 17777.75, 17802.81, 18655.66, 18703.8, 18413.88,
    18368.01, 19160.0, 18718.83, 17149.47, 17139.53, 17719.84, 18185.0,
    19695.87, 18764.96, 19204.08, 19422.34, 18650.51, 19147.66, 19358.67,
    19166.9, 18324.11, 18541.29, 18254.81, 18036.53, 18808.69, 19174.99,
    19273.69, 19426.43, 21335.52, 22797.15, 23107.39, 23821.6, 23455.54,
    22719.88, 23810.79, 23232.39, 23728.99, 24712.47, 26493.4, 26281.54,
    27079.42, 27385.0, 28875.55, 28923.63, 29331.7, 32176.45, 33000.05,
    31989.75, 33949.53, 36769.36, 39432.48, 40586.96, 40088.22, 38150.02,
    35410.37, 34049.15, 37371.38, 39145.21, 36737.43, 35994.98, 35824.99,
    36622.46, 35901.94, 35468.23, 30851.99, 32950.0, 32078.0, 32259.45,
    32254.19, 32464.01, 30362.19, 33368.18, 34246.28, 34262.89, 33092.97,
    33517.09, 35472.71, 37620.26, 36936.65, 38289.32, 39181.01, 38795.69,
    46374.86, 46420.42, 44807.58, 47968.66, 47298.15, 47156.78, 48580.47,
    47911.1, 49133.45, 52117.67, 51552.61, 55906.0, 55841.19, 57412.35,
    54087.67, 48891.0, 49676.21, 47073.73, 46276.88, 46103.67, 45134.11,
    49595.76, 48436.61, 50349.37, 48374.09, 48746.81, 48882.2, 50959.11,
    52375.18, 54874.67, 55851.59, 57773.15, 57221.72, 61188.38, 58976.08,
    55605.2, 56900.74, 58912.97, 57641.0, 58030.01, 58100.02, 57351.56,
    54083.25, 54342.8, 52303.66, 51293.78, 55025.59, 55817.14, 55777.65,
    57635.46, 58746.57, 58739.46, 58720.45, 58950.01, 57051.95, 58202.01,
    59129.99, 57990.03, 55953.44, 58077.52, 58142.55, 59769.13, 59998.8,
    59860.01, 63575.01, 62959.53, 63158.74, 61334.81, 60006.67, 56150.01,
    55633.14, 56425.0, 53787.62, 51690.95, 51110.56, 50047.84, 49066.76,
    54001.38, 55011.97, 54846.23, 53555.0, 57697.25, 57797.35, 56578.21,
    57169.39, 53205.05, 57436.11, 56393.68, 57315.49, 58866.53, 58240.83,
    55816.14, 56670.02, 49537.15, 49671.92, 49844.16, 46762.99, 46426.83,
    43538.02, 42849.78, 36671.23, 40525.39, 37263.35, 37458.51, 34681.44,
    38810.99, 38324.72, 39241.92, 38529.99, 35661.79, 34605.15, 35641.26,
    37253.82, 36694.85, 37568.68, 39246.78, 36829.15, 35516.07, 35796.31,
    33556.96, 33380.8, 37388.05, 36677.83, 37331.98, 35546.12, 39020.56,
    40516.28, 40143.8, 38349.0, 38092.97, 35820.48, 35483.72, 35600.17,
    31614.12, 32509.56, 33675.07, 34663.08, 31576.09, 32283.65, 34702.49,
    34494.89, 35911.72, 35045.0, 33502.33, 33786.54, 34669.12, 35288.13,
    33690.15, 34220.02, 33862.11, 32875.71, 33815.81, 33502.87, 34259.0,
    33086.94, 32729.12, 32820.03, 31874.49, 31383.86, 31520.07, 31778.57,
    30839.65, 29790.34, 32144.51, 32287.58, 33634.1, 34261.51, 35381.02,
    37241.33, 39456.61, 40019.57, 40018.49, 42206.36, 41461.84, 39850.27,
    39146.86, 38207.04, 39723.17, 40862.46, 42836.87, 44572.54, 43794.36,
    46248.87, 45585.0, 45510.67, 44400.06, 47799.99, 47068.5, 46973.82, 45901.3,
    44695.95, 44699.37, 46760.62, 49322.47, 48821.88, 49239.22, 49488.85,
    47674.01, 48973.32, 46843.86, 49069.9, 48895.35, 48767.84, 46982.91,
    47100.89, 48810.51, 49246.63, 49998.0, 49917.54, 51756.88, 52666.2,
    46868.57, 46048.31, 46395.14, 44842.2, 45173.68, 46025.23, 44940.72,
    47103.28, 48121.4, 47737.81, 47299.98, 48292.75, 47241.75, 43016.64,
    40734.09, 43546.37, 44865.26, 42810.58, 42670.63, 43160.9, 42147.35,
    41025.01, 41524.29, 43820.01, 48141.6, 47634.89, 48200.01, 49224.93,
    51471.99, 55315.0, 53785.22, 53955.67, 54949.72, 54659.01, 57471.35,
    55996.91, 57370.83, 57347.94, 61672.42, 60875.57, 61528.32, 62005.6,
    64280.59, 66001.4, 62193.15, 60688.23, 61286.75, 60852.22, 63078.78,
    60328.81, 58413.44, 60575.9, 62253.7, 61859.19, 61299.81, 60911.12,
    63220.57, 62896.49, 61395.01, 60940.18, 61470.62, 63273.58, 67525.82,
    66947.67, 64882.42, 64774.25, 64122.22, 64380.01, 65519.11, 63606.73,
    60058.87, 60344.86, 56891.62, 58057.1, 59707.52, 58617.7, 56243.83,
    57541.26, 57138.29, 58960.37, 53723.72, 54716.47, 57274.89, 57776.25,
    56950.56, 57184.07, 56484.26, 53601.05, 49152.46, 49396.32, 50441.91,
    50588.95, 50471.19, 47535.9, 47140.54, 49389.99, 50053.9, 46702.76,
    48336.95, 48864.98, 47632.38, 46133.83, 46834.47, 46681.24, 46914.17,
    48887.59, 48588.17, 50838.82, 50819.99, 50399.67, 50775.48, 50701.44,
    47543.74, 46464.66, 47120.88, 46216.93, 47722.66, 47286.18, 46446.1,
    45832.01, 43451.14, 43082.3, 41566.48, 41679.74, 41864.62, 41822.49,
    42729.29, 43902.65, 42558.35, 43059.96, 43084.29, 43071.66, 42201.63,
    42352.12, 41660.0, 40680.92, 36445.31, 35071.42, 36244.55, 36660.35,
    36958.32, 36807.24, 37160.11, 37716.57, 38166.83, 37881.75, 38466.9,
    38694.59, 36896.37, 37311.98, 41571.7, 41382.6, 42380.87, 43839.99, 44043.0,
    44372.71, 43495.44, 42373.73, 42217.87, 42053.65, 42535.94, 44544.85,
    43873.56, 40515.71, 39974.45, 40079.17, 38386.89, 37008.16, 38230.33,
    37250.02, 38328.68, 39219.16, 39116.73, 37699.08, 43160.0, 44421.2,
    43892.99, 42454.0, 39148.65, 39397.97, 38420.8, 37988.01, 38730.63, 41941.7,
    39422.01, 38729.57, 38807.35, 37777.35, 39671.37, 39280.33, 41114.01,
    40917.89, 41757.51, 42201.13, 41262.11, 41002.26, 42364.13, 42882.76,
    43991.46, 44313.16, 44511.27, 46827.76, 47122.21, 47434.79, 47067.99,
    45510.35, 46283.49, 45810.99, 46407.36, 46580.5, 45497.54, 43170.47,
    43444.2, 42252.02, 42753.96, 42158.85, 39530.45, 40074.95, 41147.78,
    39942.37, 40551.9, 40378.7, 39678.11, 40801.13, 41493.19, 41358.19,
    40480.01, 39709.19, 39441.61, 39450.12, 40426.08, 38112.64, 39235.72,
    39742.06, 38596.11, 37630.8, 38468.35, 38525.16, 37728.95, 39690.0,
    36552.97, 36013.77, 35472.4, 34038.39, 30074.23, 31017.11, 29103.94,
    29029.74, 29287.05, 30086.74, 31328.89, 29874.01, 30444.93, 28715.33,
    30319.22, 29201.01, 29445.07, 30293.93, 29109.14, 29654.58, 29542.14,
    29201.35, 28629.81, 29031.33, 29468.1, 31734.23, 31801.05, 29805.84,
    30452.63, 29700.21, 29864.03, 29919.2, 31373.1, 31125.32,
  ],
  high_data: [
    7255.0, 7212.5, 7405.0, 7404.0, 7495.0, 7795.34, 8207.68, 8455.0, 8055.96,
    8199.0, 8286.34, 8197.0, 8196.0, 8880.0, 8916.48, 8859.81, 9041.65, 8988.88,
    9198.98, 8746.99, 8789.0, 8818.0, 8691.81, 8528.02, 8451.26, 8618.13,
    9000.0, 9400.0, 9449.24, 9578.0, 9530.22, 9464.53, 9477.03, 9618.79, 9350.0,
    9744.45, 9862.57, 9885.0, 9940.0, 10166.0, 10188.0, 10323.59, 10450.0,
    10500.0, 10381.56, 10375.0, 10050.0, 9964.16, 10250.0, 10250.0, 9699.0,
    9755.51, 9709.17, 9990.0, 9990.0, 9675.0, 9377.44, 8971.77, 8900.0, 8790.0,
    8750.0, 8965.75, 8919.65, 8848.29, 9159.42, 9170.0, 9188.0, 8886.76,
    8179.31, 8149.0, 7980.0, 7966.17, 5955.0, 5640.52, 5940.0, 5365.42, 5525.0,
    5436.17, 6400.0, 6900.0, 6456.98, 6407.87, 6600.0, 6833.0, 6957.96, 6780.0,
    6842.59, 6360.0, 6266.0, 6599.0, 6523.23, 6679.94, 7198.0, 7048.0, 6990.41,
    6895.54, 7355.14, 7459.69, 7420.0, 7371.92, 7295.75, 6944.3, 7177.0,
    6903.79, 6978.0, 6933.0, 7190.0, 7148.12, 7293.08, 7266.15, 7220.0, 6940.0,
    7156.38, 7738.0, 7615.96, 7705.0, 7700.0, 7792.02, 7780.0, 8952.89, 9460.0,
    9059.18, 9010.0, 9200.0, 8950.0, 9118.58, 9395.0, 10067.0, 10035.96,
    9914.25, 9574.83, 9168.0, 8978.26, 9398.0, 9939.0, 9845.62, 9588.0, 9888.0,
    9950.0, 9897.21, 9842.0, 9578.47, 9271.0, 9307.85, 9298.0, 8979.66, 9017.67,
    9225.0, 9625.47, 9605.26, 9740.0, 9700.0, 10380.0, 10228.99, 9690.0,
    9881.63, 9854.75, 9735.0, 9802.0, 9800.0, 9877.0, 9992.72, 9964.0, 9557.12,
    9494.73, 9480.99, 9495.0, 9589.0, 9565.0, 9489.0, 9438.3, 9395.0, 9422.0,
    9780.0, 9720.0, 9670.0, 9340.0, 9298.0, 9196.24, 9191.0, 9238.0, 9205.0,
    9292.0, 9261.96, 9125.0, 9190.0, 9145.24, 9375.0, 9379.42, 9470.0, 9440.79,
    9317.48, 9299.28, 9345.0, 9343.82, 9279.54, 9276.49, 9226.15, 9186.83,
    9219.3, 9232.27, 9221.52, 9437.73, 9544.0, 9664.0, 9637.0, 9732.9, 10111.0,
    11394.86, 11242.23, 11342.82, 11170.0, 11444.0, 11861.0, 12123.46, 11473.0,
    11414.98, 11780.93, 11900.0, 11909.94, 11808.27, 11797.11, 12067.35,
    11935.0, 11617.52, 11792.96, 11850.0, 11980.0, 11931.72, 12468.0, 12387.77,
    12020.08, 11888.0, 11878.0, 11686.0, 11718.07, 11824.9, 11767.85, 11539.32,
    11592.2, 11542.65, 11580.02, 11719.0, 11800.77, 12050.85, 11954.57, 11462.6,
    10627.05, 10565.68, 10347.14, 10410.75, 10438.0, 10343.0, 10483.35, 10397.6,
    10477.97, 10580.11, 10750.0, 10930.04, 11093.0, 11045.46, 11038.03,
    11179.79, 11080.64, 10988.86, 10572.71, 10537.15, 10795.24, 10760.53,
    10820.94, 10799.0, 10950.0, 10867.54, 10849.34, 10920.0, 10664.64, 10603.56,
    10696.87, 10798.0, 10800.0, 10681.87, 10950.0, 11104.64, 11491.0, 11445.0,
    11720.01, 11557.0, 11547.98, 11617.34, 11541.15, 11402.42, 11505.0,
    11823.99, 12038.38, 13217.68, 13185.0, 13027.69, 13166.73, 13350.0,
    13238.81, 13789.29, 13859.48, 13642.91, 13669.98, 14100.0, 13895.0, 13830.0,
    14066.11, 14259.0, 15750.0, 15960.0, 15753.52, 15650.0, 15840.0, 15460.0,
    15965.0, 16340.7, 16480.0, 16326.99, 16180.0, 16880.0, 17858.82, 18476.93,
    18179.8, 18815.22, 18965.9, 18750.0, 18766.0, 19418.97, 19484.21, 18915.03,
    17457.62, 17880.49, 18360.05, 19863.16, 19888.0, 19342.0, 19598.0, 19527.0,
    19177.0, 19420.0, 19420.91, 19294.84, 18639.57, 18557.32, 18292.73,
    18948.66, 19411.0, 19349.0, 19570.0, 21560.0, 23800.0, 23285.18, 24171.47,
    24295.0, 24102.77, 23837.1, 24100.0, 23794.43, 24789.86, 26867.03, 28422.0,
    27500.0, 27410.0, 28996.0, 29300.0, 29600.0, 33300.0, 34778.11, 33600.0,
    34360.0, 36939.21, 40365.0, 41950.0, 41380.0, 41350.0, 38264.74, 36628.0,
    37850.0, 40100.0, 39747.76, 37950.0, 36852.5, 37469.83, 37850.0, 36415.31,
    35600.0, 33826.53, 33456.0, 33071.0, 34875.0, 32921.88, 32557.29, 33783.98,
    38531.9, 34933.0, 34342.69, 34717.27, 35984.33, 37662.63, 38708.27,
    38310.12, 40955.51, 39700.0, 46794.45, 48142.19, 47310.0, 48678.9, 48985.8,
    48150.0, 49707.43, 49010.92, 50689.18, 52618.74, 52530.0, 56368.0, 57700.46,
    58352.8, 57508.47, 54183.59, 51374.99, 52041.73, 48424.11, 48394.0,
    46638.46, 49790.0, 50200.0, 52640.0, 51773.88, 49448.93, 49200.0, 51450.03,
    52402.78, 54895.0, 57387.69, 58150.0, 58081.51, 61844.0, 61724.79, 60633.43,
    56938.29, 58974.73, 60129.97, 59468.0, 59880.0, 58589.1, 58430.73, 55830.9,
    57200.0, 53287.0, 55073.46, 56700.36, 56559.75, 58405.82, 59368.0, 59800.0,
    59490.0, 60200.0, 59791.72, 58492.85, 59272.0, 59495.24, 58655.0, 58153.31,
    58894.9, 61500.0, 60699.0, 61300.0, 63777.77, 64854.0, 63800.0, 63520.61,
    62506.05, 60499.0, 57526.81, 57076.24, 56757.91, 55521.48, 52131.85,
    51166.22, 50567.91, 54356.62, 55460.0, 56428.0, 55195.84, 57963.0, 58458.07,
    57911.02, 58981.44, 57200.0, 58069.82, 58360.0, 58650.0, 59500.0, 59300.0,
    59500.0, 56862.43, 58000.01, 51367.19, 51483.0, 50700.0, 49795.89, 46686.0,
    45799.29, 43584.9, 42451.67, 42200.0, 38829.0, 38270.64, 39920.0, 39791.77,
    40841.0, 40411.14, 38877.83, 37338.58, 36488.0, 37499.0, 37894.81, 38225.0,
    39476.0, 39289.07, 37925.0, 36480.0, 36900.0, 34068.01, 37534.79, 38491.0,
    37680.4, 37463.63, 39380.0, 41064.05, 41330.0, 40527.14, 39559.88, 38202.84,
    36457.0, 36137.72, 35750.0, 33298.78, 34881.0, 35298.0, 35500.0, 32730.0,
    34749.0, 35297.71, 36600.0, 36100.0, 35057.57, 33977.04, 34945.61, 35967.85,
    35293.78, 35118.88, 35059.09, 33929.64, 34100.0, 34262.0, 34666.0, 34678.43,
    33340.0, 33114.03, 33185.25, 32249.18, 31955.92, 32435.0, 31899.0, 31063.07,
    32858.0, 32591.35, 33650.0, 34500.0, 35398.0, 40550.0, 39542.61, 40900.0,
    40640.0, 42316.71, 42448.0, 42599.0, 40480.01, 39780.0, 39969.66, 41350.0,
    43392.43, 44700.0, 45310.0, 46454.15, 46700.0, 46743.47, 46218.12, 47886.0,
    48144.0, 47372.27, 48053.83, 47160.0, 46000.0, 47033.0, 49382.99, 49757.04,
    49500.0, 50500.0, 49860.0, 49264.3, 49352.84, 49149.93, 49299.0, 49632.27,
    48888.61, 48246.11, 49156.0, 50450.13, 51000.0, 50535.69, 51900.0, 52780.0,
    52920.0, 47340.99, 47399.97, 47033.0, 45987.93, 46460.0, 46880.0, 47250.0,
    48500.0, 48557.0, 48150.0, 48843.2, 48372.83, 47347.25, 43639.0, 44000.55,
    44978.0, 45200.0, 42966.84, 43950.0, 44350.0, 42787.38, 42590.0, 44141.37,
    48495.0, 48336.59, 49228.08, 49536.12, 51886.3, 55750.0, 55332.31, 56100.0,
    55489.0, 56561.31, 57839.04, 57680.0, 57777.0, 58532.54, 62933.0, 62378.42,
    61718.39, 62695.78, 64486.0, 67000.0, 66639.74, 63732.39, 61747.64, 61500.0,
    63710.63, 63293.48, 61496.0, 62499.0, 62980.0, 62359.25, 62405.3, 62437.74,
    64270.0, 63500.0, 63086.31, 62595.72, 61560.49, 63286.35, 67789.0, 68524.25,
    69000.0, 65600.07, 65450.7, 65000.0, 65550.51, 66401.82, 63617.31, 60840.23,
    60976.0, 58320.0, 59845.0, 60029.76, 59444.0, 58009.99, 57735.0, 59398.9,
    59150.0, 55280.0, 57445.05, 58865.97, 59176.99, 59053.55, 57375.47, 57600.0,
    53859.1, 49699.05, 50891.11, 51936.33, 51200.0, 50797.76, 50125.0, 49485.71,
    50777.0, 50189.97, 48700.41, 49500.0, 49436.43, 47995.96, 47392.37,
    48300.01, 47537.57, 49328.96, 49576.13, 51375.0, 51810.0, 51156.23, 51280.0,
    52088.0, 50704.05, 48139.08, 47900.0, 48548.26, 47954.63, 47990.0, 47570.0,
    47557.54, 47070.0, 43816.0, 43145.83, 42300.0, 42786.7, 42248.5, 43100.0,
    44322.0, 44500.0, 43448.78, 43800.0, 43475.0, 43176.18, 42691.0, 42559.13,
    43505.0, 41100.0, 36835.22, 36499.0, 37550.0, 37545.14, 38919.98, 37234.47,
    38000.0, 38720.74, 38359.26, 38744.0, 39265.2, 38855.92, 37387.0, 41772.33,
    41913.69, 42656.0, 44500.5, 45492.0, 44799.0, 45821.0, 43920.0, 43079.49,
    42760.0, 42842.4, 44751.4, 44549.97, 44164.71, 40959.88, 40444.32, 40125.44,
    39494.35, 38429.0, 39249.93, 39843.0, 39683.53, 40348.45, 39855.7, 44225.84,
    44949.0, 45400.0, 44101.12, 42527.3, 39613.24, 39693.87, 39547.57, 39362.08,
    42594.06, 42039.63, 40236.26, 39486.71, 39310.0, 39947.12, 39887.61,
    41718.0, 41478.82, 42325.02, 42400.0, 42296.26, 41544.22, 43361.0, 43025.96,
    44220.89, 45094.14, 44792.99, 46999.0, 48189.84, 48096.47, 47700.22,
    47600.0, 46720.09, 47213.0, 47444.11, 46890.71, 47200.0, 45507.14, 43900.99,
    43970.62, 42800.0, 43410.3, 42414.71, 40699.0, 41561.31, 41500.0, 40870.36,
    40709.35, 40595.67, 41116.73, 41760.0, 42199.0, 42976.0, 40795.06, 39980.0,
    39940.0, 40616.0, 40797.31, 39474.72, 40372.63, 39925.25, 38795.38, 38675.0,
    39167.34, 38651.51, 40023.77, 39845.51, 36675.63, 36146.3, 35514.22,
    34243.15, 32658.99, 32162.59, 30243.0, 31083.37, 30343.27, 31460.0, 31328.9,
    30788.37, 30709.99, 30545.18, 30777.33, 29656.18, 30487.99, 30670.51,
    29845.86, 30223.74, 29886.64, 29397.66, 29266.0, 29587.78, 32222.0, 32399.0,
    31982.97, 30689.0, 30699.0, 29988.88, 30189.0, 31765.64, 31589.6, 31327.22,
  ],
  low_data: [
    7175.15, 6924.74, 6871.04, 7272.21, 7318.0, 7346.76, 7723.71, 7870.0,
    7750.0, 7672.0, 8003.16, 7960.0, 8055.89, 8105.54, 8564.0, 8586.0, 8672.44,
    8806.38, 8466.0, 8521.28, 8488.0, 8590.0, 8306.39, 8238.0, 8254.9, 8293.66,
    8535.0, 8862.4, 9216.0, 9204.44, 9210.01, 9281.0, 9120.0, 9234.0, 9093.01,
    9177.22, 9526.35, 9730.0, 9667.11, 9880.75, 9756.0, 9700.0, 10223.08,
    10080.0, 10111.37, 9801.0, 9638.12, 9452.67, 9576.01, 9350.0, 9400.0,
    9550.21, 9560.02, 9645.0, 9473.56, 9250.0, 8633.63, 8531.0, 8445.0, 8523.55,
    8411.0, 8498.0, 8651.0, 8660.0, 8746.54, 8985.5, 8835.0, 8000.0, 7632.01,
    7728.01, 7590.0, 4410.0, 3782.13, 5055.13, 5093.1, 4442.12, 4921.45,
    5009.37, 5252.53, 5670.0, 5860.02, 5734.01, 5688.0, 6371.33, 6450.0, 6510.0,
    6261.0, 6024.0, 5866.56, 5857.76, 6321.4, 6150.11, 6551.0, 6602.1, 6650.01,
    6677.52, 6765.0, 7077.0, 7150.0, 7108.08, 6739.98, 6760.0, 6780.0, 6575.0,
    6754.28, 6605.0, 6468.27, 6972.98, 7014.4, 7055.6, 6751.0, 6762.0, 6818.0,
    7020.0, 7388.0, 7431.07, 7480.0, 7606.0, 7659.12, 7710.05, 8401.0, 8613.56,
    8753.0, 8712.0, 8522.0, 8760.0, 8906.21, 9021.0, 9705.0, 9520.0, 8117.0,
    8200.0, 8528.78, 8792.99, 9256.76, 9150.0, 9220.0, 9322.1, 9464.23, 9474.0,
    9326.0, 8815.0, 8933.52, 9070.0, 8700.0, 8642.72, 8700.0, 8811.73, 9110.0,
    9330.0, 9331.23, 9381.41, 9421.67, 9266.0, 9365.21, 9450.0, 9581.0, 9531.05,
    9372.46, 9633.0, 9570.0, 9704.18, 9113.0, 9232.51, 9351.0, 9245.0, 8910.45,
    9373.09, 9236.61, 9280.0, 9215.79, 9170.95, 9281.54, 9277.09, 9577.03,
    9208.0, 9009.69, 9045.45, 8833.0, 8948.06, 9024.67, 9064.89, 9080.1, 8940.0,
    9037.47, 9040.04, 8893.03, 9055.92, 9203.0, 9231.0, 9160.0, 9125.0, 9178.25,
    9157.5, 9200.89, 9113.0, 9160.57, 9047.25, 9089.81, 9121.1, 9101.35, 9131.0,
    9152.8, 9261.0, 9440.33, 9463.44, 9513.0, 9650.0, 9917.21, 10565.0, 10812.0,
    10831.0, 10960.0, 11220.0, 10518.5, 10936.0, 11000.0, 11093.0, 11562.5,
    11322.0, 11512.0, 11521.97, 11450.0, 11125.0, 11150.0, 11270.36, 11634.03,
    11680.0, 11686.0, 11769.78, 11817.93, 11561.0, 11668.0, 11485.81, 11376.81,
    11514.13, 11585.09, 11117.64, 11244.0, 11125.0, 11276.89, 11417.04, 11458.0,
    11570.0, 11515.0, 11160.1, 9960.8, 9875.5, 9825.0, 9994.86, 9875.0, 9850.0,
    9981.01, 10070.83, 10200.0, 10269.25, 10200.0, 10212.34, 10606.48, 10661.22,
    10745.83, 10812.84, 10887.37, 10723.0, 10296.35, 10353.0, 10136.82,
    10190.93, 10556.24, 10644.68, 10594.82, 10626.0, 10635.87, 10665.13,
    10437.0, 10374.0, 10496.46, 10517.87, 10615.64, 10525.0, 10546.17, 10530.41,
    10829.0, 11050.51, 11221.0, 11172.0, 11300.0, 11280.0, 11250.83, 11200.0,
    11255.0, 11346.22, 11407.96, 11677.59, 11886.95, 12678.08, 12720.08,
    12870.0, 12888.0, 12765.0, 13019.87, 12888.0, 12920.77, 13115.0, 13411.5,
    13603.0, 13195.05, 13284.99, 13525.0, 14093.56, 15166.0, 14344.22, 14703.88,
    14805.54, 15072.46, 15272.68, 15440.64, 15952.35, 15670.0, 15774.72,
    15864.0, 16538.0, 17214.45, 17335.65, 17740.04, 18308.58, 17610.86, 18000.0,
    18018.0, 18500.27, 16188.0, 16438.08, 16865.56, 17517.0, 18184.99, 18001.12,
    18330.0, 18867.2, 18565.31, 18500.0, 18857.0, 18902.88, 18200.0, 17650.0,
    17911.12, 17572.33, 18020.7, 18711.12, 19000.0, 19050.0, 19278.6, 21230.0,
    22350.0, 22750.0, 23060.0, 21815.0, 22353.4, 22600.0, 22703.42, 23433.6,
    24500.0, 25700.0, 26101.0, 25880.0, 27320.0, 27850.0, 28624.57, 28946.53,
    31962.99, 28130.0, 29900.0, 33288.0, 36300.0, 36500.0, 38720.0, 35111.11,
    30420.0, 32531.0, 32380.0, 36701.23, 34408.0, 35357.8, 33850.0, 34800.0,
    35844.06, 33400.0, 30071.0, 28850.0, 31390.16, 30900.0, 31910.0, 30837.37,
    29241.72, 29842.1, 31915.4, 32825.0, 32171.67, 32296.16, 33418.0, 35362.38,
    36161.95, 36570.0, 38215.94, 37351.0, 37988.89, 44961.09, 43727.0, 43994.02,
    46125.0, 46202.53, 47014.17, 45570.79, 47003.62, 48947.0, 50901.9, 50710.2,
    53863.93, 55477.59, 47622.0, 44892.56, 46988.69, 46674.34, 44106.78,
    45000.0, 43000.0, 44950.53, 47047.6, 48100.71, 47500.0, 46300.0, 47070.0,
    48882.2, 49274.67, 51789.41, 53005.0, 54272.82, 54962.84, 56078.23,
    58966.78, 54600.0, 53271.34, 54123.69, 57023.0, 56270.74, 57820.17,
    55450.11, 53650.0, 53000.0, 51700.0, 50427.56, 51214.6, 53950.0, 54691.84,
    54800.01, 57071.35, 56769.0, 57935.45, 58428.57, 56880.0, 56388.0, 56777.77,
    57413.02, 55473.0, 55700.0, 57654.0, 57900.01, 59232.52, 59350.59, 59805.15,
    61301.0, 62020.0, 60000.0, 59580.91, 50931.3, 54221.58, 53329.96, 53536.02,
    50500.0, 47500.0, 48657.14, 46930.0, 48753.44, 53222.0, 53813.16, 52330.94,
    53013.01, 56956.14, 56035.25, 56435.0, 53046.69, 52900.0, 55200.0, 55241.63,
    56900.0, 56235.66, 53400.0, 54370.0, 48600.0, 46000.0, 48799.75, 46555.0,
    43825.39, 42001.0, 42250.02, 30000.0, 34850.0, 33488.0, 35200.62, 31111.01,
    34031.0, 36419.62, 37800.44, 37134.27, 34684.0, 33632.76, 33379.0, 34153.84,
    35666.0, 35920.0, 37170.0, 35555.15, 34800.0, 35222.0, 33300.0, 31000.0,
    32396.82, 35782.0, 35936.77, 34600.36, 34757.0, 38730.0, 39506.4, 38116.01,
    37365.0, 35129.29, 34803.52, 33336.0, 31251.23, 28805.0, 31683.0, 32286.57,
    31275.0, 30151.0, 31973.45, 33862.72, 34225.43, 34017.55, 32711.0, 32699.0,
    33316.73, 34357.15, 33125.55, 33532.0, 33777.77, 32077.0, 32261.07,
    33004.78, 33306.47, 32658.34, 32202.25, 31550.0, 31133.0, 31020.0, 31164.31,
    31108.97, 30407.44, 29278.0, 29482.61, 31708.0, 31924.32, 33401.14,
    33851.12, 35205.78, 36383.0, 38772.0, 39200.0, 38313.23, 41000.15, 39422.01,
    38690.0, 37642.03, 37508.56, 37332.7, 39853.86, 42446.41, 43261.0, 42779.0,
    44589.46, 45341.14, 43770.0, 44217.39, 45971.03, 45500.0, 45660.0, 44376.0,
    44203.28, 43927.7, 46622.99, 48222.0, 48050.0, 49029.0, 47600.0, 47126.28,
    46250.0, 46348.0, 48346.88, 47762.54, 46853.0, 46700.0, 46512.0, 48584.06,
    48316.84, 49370.0, 49450.0, 50969.33, 42843.05, 44412.02, 45513.08,
    44132.29, 44722.22, 44742.06, 43370.0, 44594.44, 46682.32, 47021.1,
    46699.56, 47035.56, 46829.18, 42500.0, 39600.0, 40565.39, 43069.09, 40675.0,
    41646.28, 40750.0, 42098.0, 40888.0, 40753.88, 41410.17, 43283.03, 47430.18,
    47088.0, 46891.0, 49022.4, 50382.41, 53357.0, 53617.61, 53661.67, 54080.0,
    54415.06, 53879.0, 54167.19, 56818.05, 56850.0, 60150.0, 58963.0, 59844.45,
    61322.22, 63481.4, 62000.0, 60000.0, 59562.15, 59510.63, 60650.0, 59817.55,
    58000.0, 57820.0, 60174.81, 60673.0, 59945.36, 59405.0, 60624.68, 60382.76,
    60677.01, 60721.0, 60050.0, 61322.78, 63273.58, 66222.4, 62822.9, 64100.0,
    62278.0, 63360.22, 63576.27, 63400.0, 58574.07, 58373.0, 56474.26, 55600.0,
    57353.0, 58486.65, 55610.0, 55317.0, 55837.0, 57000.0, 53500.0, 53610.0,
    53256.64, 56666.67, 55875.55, 56458.01, 55777.77, 51680.0, 42000.3,
    47727.21, 47100.0, 50039.74, 48600.0, 47320.0, 46852.0, 46751.0, 48638.0,
    45672.75, 46290.0, 46547.0, 47511.0, 45456.0, 45500.0, 46406.91, 45558.85,
    46630.0, 48421.87, 47920.42, 50384.43, 50142.32, 49412.0, 50449.0, 47313.01,
    46096.99, 45900.0, 45678.0, 46208.37, 46654.0, 45696.0, 45500.0, 42500.0,
    42430.58, 40610.0, 40501.0, 41200.02, 39650.0, 41268.93, 42450.0, 42311.22,
    41725.95, 42555.0, 42581.79, 41540.42, 41250.0, 41138.56, 40553.31,
    35440.45, 34008.0, 34601.01, 32917.17, 35701.0, 36234.63, 35507.01,
    36155.01, 37268.44, 37351.63, 36632.61, 38000.0, 36586.95, 36250.0,
    37026.73, 40843.01, 41116.56, 41645.85, 42666.0, 43117.92, 43174.01,
    41938.51, 41688.88, 41870.0, 41550.56, 42427.03, 43307.0, 40073.21, 39450.0,
    39639.03, 38000.0, 36800.0, 36350.0, 37036.79, 34322.28, 38014.37, 38573.18,
    37000.0, 37450.17, 42809.98, 43334.09, 41832.28, 38550.0, 38407.59,
    38088.57, 37155.0, 37867.65, 38656.45, 38539.73, 38223.6, 38660.52,
    37578.51, 37555.0, 38098.33, 38828.48, 40500.0, 40135.04, 41499.29, 40911.0,
    40467.94, 40875.51, 41751.47, 42560.46, 43579.0, 44071.97, 44421.46,
    46663.56, 46950.85, 46445.42, 45200.0, 44200.0, 45620.0, 45530.92, 45118.0,
    45353.81, 43121.0, 42727.35, 42107.14, 42125.48, 41868.0, 39200.0, 39254.63,
    39588.54, 39551.94, 39766.4, 39991.55, 39546.17, 38536.51, 40571.0, 40820.0,
    39751.0, 39177.0, 39285.0, 38929.62, 38200.0, 37702.26, 37881.31, 38881.43,
    38175.0, 37578.2, 37386.38, 38052.0, 37517.8, 37670.0, 35571.9, 35258.0,
    34785.0, 33713.95, 30033.33, 29730.4, 27785.0, 26700.0, 28751.67, 28630.0,
    29480.0, 29087.04, 29450.38, 28654.47, 28691.38, 28730.0, 28947.28,
    29255.11, 28866.35, 28669.0, 29294.21, 28019.56, 28282.9, 28450.0, 28839.21,
    29299.62, 31200.01, 29301.0, 29594.55, 29282.36, 29485.0, 29531.42,
    29890.23, 29218.96, 29843.88,
  ],
  close_data: [
    7200.85, 6965.71, 7344.96, 7354.11, 7358.75, 7758.0, 8145.28, 8055.98,
    7817.76, 8197.02, 8020.01, 8184.98, 8110.34, 8810.01, 8821.41, 8720.01,
    8913.28, 8915.96, 8701.7, 8642.35, 8736.03, 8682.36, 8404.52, 8439.0,
    8340.58, 8615.0, 8907.57, 9374.21, 9301.53, 9513.21, 9352.89, 9384.61,
    9331.51, 9292.24, 9197.02, 9612.04, 9772.0, 9813.73, 9895.05, 10151.75,
    9851.83, 10223.08, 10326.46, 10229.63, 10344.36, 9904.72, 9917.27, 9706.0,
    10164.71, 9593.79, 9596.42, 9677.05, 9650.86, 9936.4, 9656.13, 9315.84,
    8785.25, 8823.21, 8692.91, 8523.61, 8531.88, 8915.24, 8760.07, 8750.87,
    9054.68, 9131.88, 8886.66, 8033.31, 7929.87, 7894.56, 7934.52, 4800.0,
    5578.6, 5172.06, 5361.3, 5028.97, 5312.64, 5393.04, 6162.37, 6208.36,
    6186.98, 5816.19, 6467.31, 6744.72, 6677.43, 6737.36, 6359.11, 6236.65,
    5881.42, 6394.38, 6410.44, 6642.92, 6794.09, 6734.1, 6856.99, 6772.78,
    7329.9, 7197.32, 7361.28, 7283.54, 6858.92, 6876.83, 6903.79, 6837.91,
    6868.7, 6621.24, 7101.94, 7027.55, 7248.6, 7120.74, 6826.83, 6841.37,
    7125.14, 7482.39, 7505.0, 7538.67, 7693.1, 7774.62, 7738.98, 8778.57,
    8620.0, 8826.96, 8972.05, 8894.16, 8871.96, 9021.83, 9142.92, 9986.4,
    9800.01, 9539.4, 8722.77, 8561.52, 8810.79, 9309.37, 9791.98, 9316.42,
    9381.27, 9680.04, 9733.93, 9775.53, 9511.43, 9068.65, 9170.0, 9179.15,
    8720.34, 8900.35, 8841.18, 9204.07, 9575.89, 9427.07, 9697.72, 9448.27,
    10200.77, 9518.04, 9666.24, 9789.06, 9621.16, 9666.3, 9746.99, 9782.01,
    9772.43, 9885.0, 9280.4, 9465.13, 9473.34, 9342.1, 9426.02, 9525.59,
    9465.14, 9386.32, 9310.23, 9358.95, 9294.69, 9685.69, 9624.89, 9296.49,
    9249.49, 9162.21, 9012.0, 9116.35, 9192.56, 9138.55, 9232.0, 9086.54,
    9058.26, 9135.46, 9069.41, 9344.2, 9257.39, 9436.06, 9232.43, 9288.34,
    9234.03, 9302.75, 9242.62, 9255.85, 9197.6, 9133.72, 9154.32, 9170.28,
    9208.99, 9160.78, 9390.0, 9518.16, 9603.27, 9537.8, 9700.42, 9931.54,
    11029.96, 10906.27, 11100.53, 11099.61, 11335.46, 11801.17, 11071.35,
    11219.81, 11191.97, 11744.91, 11762.46, 11594.23, 11761.41, 11681.68,
    11892.92, 11392.08, 11564.33, 11780.0, 11760.54, 11852.4, 11911.0, 12281.13,
    11945.01, 11754.59, 11853.55, 11531.34, 11662.96, 11648.13, 11748.2,
    11318.42, 11461.43, 11330.38, 11526.91, 11465.84, 11711.16, 11649.51,
    11921.97, 11388.54, 10140.85, 10446.25, 10166.69, 10256.2, 10373.44,
    10126.65, 10219.2, 10336.87, 10387.89, 10440.92, 10332.83, 10671.77,
    10785.31, 10954.01, 10939.99, 10933.39, 11080.65, 10920.28, 10417.22,
    10529.61, 10241.46, 10736.32, 10686.67, 10728.6, 10774.25, 10696.12,
    10840.48, 10776.59, 10619.13, 10570.4, 10542.06, 10666.63, 10792.21,
    10599.66, 10666.39, 10925.57, 11050.64, 11293.22, 11369.02, 11528.25,
    11420.56, 11417.89, 11505.12, 11319.32, 11360.2, 11503.14, 11751.47,
    11909.99, 12780.96, 12968.52, 12923.07, 13111.73, 13028.83, 13052.19,
    13636.17, 13266.4, 13455.7, 13560.1, 13791.0, 13761.5, 13549.37, 14023.53,
    14144.01, 15590.02, 15579.92, 14818.3, 15475.1, 15328.41, 15297.21,
    15684.24, 16291.86, 16320.7, 16070.45, 15957.0, 16713.57, 17659.38,
    17776.12, 17802.82, 18655.67, 18703.8, 18414.43, 18368.0, 19160.01,
    18719.11, 17149.47, 17139.52, 17719.85, 18184.99, 19695.87, 18764.96,
    19204.09, 19421.9, 18650.52, 19147.66, 19359.4, 19166.9, 18324.11, 18541.28,
    18254.63, 18036.53, 18808.69, 19174.99, 19273.14, 19426.43, 21335.52,
    22797.16, 23107.39, 23821.61, 23455.52, 22719.71, 23810.79, 23232.76,
    23729.2, 24712.47, 26493.39, 26281.66, 27079.41, 27385.0, 28875.54,
    28923.63, 29331.69, 32178.33, 33000.05, 31988.71, 33949.53, 36769.36,
    39432.28, 40582.81, 40088.22, 38150.02, 35404.47, 34051.24, 37371.38,
    39144.5, 36742.22, 35994.98, 35828.61, 36631.27, 35891.49, 35468.23,
    30850.13, 32945.17, 32078.0, 32259.9, 32254.2, 32467.77, 30366.15, 33364.86,
    34252.2, 34262.88, 33092.98, 33526.37, 35466.24, 37618.87, 36936.66,
    38290.24, 39186.94, 38795.69, 46374.87, 46420.42, 44807.58, 47969.51,
    47287.6, 47153.69, 48577.79, 47911.1, 49133.45, 52119.71, 51552.6, 55906.0,
    55841.19, 57408.57, 54087.67, 48891.0, 49676.2, 47073.73, 46276.87,
    46106.43, 45135.66, 49587.03, 48440.65, 50349.37, 48374.09, 48751.71,
    48882.2, 50971.75, 52375.17, 54884.5, 55851.59, 57773.16, 57221.72,
    61188.39, 58968.31, 55605.2, 56900.75, 58912.97, 57648.16, 58030.01,
    58102.28, 57351.56, 54083.25, 54340.89, 52303.65, 51293.78, 55025.59,
    55817.14, 55777.63, 57635.47, 58746.57, 58740.55, 58720.44, 58950.01,
    57051.94, 58202.01, 59129.99, 57991.15, 55953.45, 58077.52, 58142.54,
    59769.13, 60002.43, 59860.0, 63575.0, 62959.53, 63159.98, 61334.8, 60006.66,
    56150.01, 55633.14, 56425.0, 53787.63, 51690.96, 51125.14, 50047.84,
    49066.77, 54001.39, 55011.97, 54846.22, 53555.0, 57694.27, 57800.37,
    56578.21, 57169.39, 53200.01, 57436.11, 56393.68, 57314.75, 58862.05,
    58240.84, 55816.14, 56670.02, 49631.32, 49670.97, 49841.45, 46762.99,
    46431.5, 43538.04, 42849.78, 36690.09, 40526.64, 37252.01, 37449.73,
    34655.25, 38796.29, 38324.72, 39241.91, 38529.98, 35663.49, 34605.15,
    35641.27, 37253.81, 36693.09, 37568.68, 39246.79, 36829.0, 35513.2,
    35796.31, 33552.79, 33380.81, 37388.05, 36675.72, 37331.98, 35546.11,
    39020.57, 40516.29, 40144.04, 38349.01, 38092.97, 35819.84, 35483.72,
    35600.16, 31608.93, 32509.56, 33678.07, 34663.09, 31584.45, 32283.65,
    34700.34, 34494.89, 35911.73, 35045.0, 33504.69, 33786.55, 34669.13,
    35286.51, 33690.14, 34220.01, 33862.12, 32875.71, 33815.81, 33502.87,
    34258.99, 33086.63, 32729.77, 32820.02, 31880.0, 31383.87, 31520.07,
    31778.56, 30839.65, 29790.35, 32144.51, 32287.83, 33634.09, 34258.14,
    35381.02, 37237.6, 39457.87, 40019.56, 40016.48, 42206.37, 41461.83,
    39845.44, 39147.82, 38207.05, 39723.18, 40862.46, 42836.87, 44572.54,
    43794.37, 46253.4, 45584.99, 45511.0, 44399.0, 47800.0, 47068.51, 46973.82,
    45901.29, 44695.95, 44705.29, 46760.62, 49322.47, 48821.87, 49239.22,
    49488.85, 47674.01, 48973.32, 46843.87, 49069.9, 48895.35, 48767.83,
    46982.91, 47100.89, 48810.52, 49246.64, 49999.14, 49915.64, 51756.88,
    52663.9, 46863.73, 46048.31, 46395.14, 44850.91, 45173.69, 46025.24,
    44940.73, 47111.52, 48121.41, 47737.82, 47299.98, 48292.74, 47241.75,
    43015.62, 40734.38, 43543.61, 44865.26, 42810.57, 42670.64, 43160.9,
    42147.35, 41026.54, 41524.28, 43824.1, 48141.61, 47634.9, 48200.01,
    49224.94, 51471.99, 55315.0, 53785.22, 53951.43, 54949.72, 54659.0,
    57471.35, 55996.93, 57367.0, 57347.94, 61672.42, 60875.57, 61528.33,
    62009.84, 64280.59, 66001.41, 62193.15, 60688.22, 61286.75, 60852.22,
    63078.78, 60328.81, 58413.44, 60575.89, 62253.71, 61859.19, 61299.8,
    60911.11, 63219.99, 62896.48, 61395.01, 60937.12, 61470.61, 63273.59,
    67525.83, 66947.66, 64882.43, 64774.26, 64122.23, 64380.0, 65519.1,
    63606.74, 60058.87, 60344.87, 56891.62, 58052.24, 59707.51, 58622.02,
    56247.18, 57541.27, 57138.29, 58960.36, 53726.53, 54721.03, 57274.88,
    57776.25, 56950.56, 57184.07, 56480.34, 53601.05, 49152.47, 49396.33,
    50441.92, 50588.95, 50471.19, 47545.59, 47140.54, 49389.99, 50053.9,
    46702.75, 48343.28, 48864.98, 47632.38, 46131.2, 46834.48, 46681.23,
    46914.16, 48889.88, 48588.16, 50838.81, 50820.0, 50399.66, 50775.49,
    50701.44, 47543.74, 46464.66, 47120.87, 46216.93, 47722.65, 47286.18,
    46446.1, 45832.01, 43451.13, 43082.31, 41566.48, 41679.74, 41864.62,
    41822.49, 42729.29, 43902.66, 42560.11, 43059.96, 43084.29, 43071.66,
    42201.62, 42352.12, 41660.01, 40680.91, 36445.31, 35071.42, 36244.55,
    36660.35, 36958.32, 36809.34, 37160.1, 37716.56, 38166.84, 37881.76,
    38466.9, 38694.59, 36896.36, 37311.61, 41574.25, 41382.59, 42380.87,
    43839.99, 44042.99, 44372.72, 43495.44, 42373.73, 42217.87, 42053.66,
    42535.94, 44544.86, 43873.56, 40515.7, 39974.44, 40079.17, 38386.89,
    37008.16, 38230.33, 37250.01, 38327.21, 39219.17, 39116.72, 37699.07,
    43160.0, 44421.2, 43892.98, 42454.0, 39148.66, 39397.96, 38420.81, 37988.0,
    38730.63, 41941.71, 39422.0, 38729.57, 38807.36, 37777.34, 39671.37,
    39280.33, 41114.0, 40917.9, 41757.51, 42201.13, 41262.11, 41002.25,
    42364.13, 42882.76, 43991.46, 44313.16, 44511.27, 46827.76, 47122.21,
    47434.8, 47067.99, 45510.34, 46283.49, 45811.0, 46407.35, 46580.51,
    45497.55, 43170.47, 43444.19, 42252.01, 42753.97, 42158.85, 39530.45,
    40074.94, 41147.79, 39942.38, 40551.9, 40378.71, 39678.12, 40801.13,
    41493.18, 41358.19, 40480.01, 39709.18, 39441.6, 39450.13, 40426.08,
    38112.65, 39235.72, 39742.07, 38596.11, 37630.8, 38468.35, 38525.16,
    37728.95, 39690.0, 36552.97, 36013.77, 35472.39, 34038.4, 30076.31, 31017.1,
    29103.94, 29029.75, 29287.05, 30086.74, 31328.89, 29874.01, 30444.93,
    28715.32, 30319.23, 29201.01, 29445.06, 30293.94, 29109.15, 29654.58,
    29542.15, 29201.35, 28629.8, 29031.33, 29468.1, 31734.22, 31801.04,
    29805.83, 30452.62, 29700.21, 29864.04, 29919.21, 31373.1, 31125.33,
    30204.77,
  ],
  datetime_data: [
    1577836800.0, 1577923200.0, 1578009600.0, 1578096000.0, 1578182400.0,
    1578268800.0, 1578355200.0, 1578441600.0, 1578528000.0, 1578614400.0,
    1578700800.0, 1578787200.0, 1578873600.0, 1578960000.0, 1579046400.0,
    1579132800.0, 1579219200.0, 1579305600.0, 1579392000.0, 1579478400.0,
    1579564800.0, 1579651200.0, 1579737600.0, 1579824000.0, 1579910400.0,
    1579996800.0, 1580083200.0, 1580169600.0, 1580256000.0, 1580342400.0,
    1580428800.0, 1580515200.0, 1580601600.0, 1580688000.0, 1580774400.0,
    1580860800.0, 1580947200.0, 1581033600.0, 1581120000.0, 1581206400.0,
    1581292800.0, 1581379200.0, 1581465600.0, 1581552000.0, 1581638400.0,
    1581724800.0, 1581811200.0, 1581897600.0, 1581984000.0, 1582070400.0,
    1582156800.0, 1582243200.0, 1582329600.0, 1582416000.0, 1582502400.0,
    1582588800.0, 1582675200.0, 1582761600.0, 1582848000.0, 1582934400.0,
    1583020800.0, 1583107200.0, 1583193600.0, 1583280000.0, 1583366400.0,
    1583452800.0, 1583539200.0, 1583625600.0, 1583712000.0, 1583798400.0,
    1583884800.0, 1583971200.0, 1584057600.0, 1584144000.0, 1584230400.0,
    1584316800.0, 1584403200.0, 1584489600.0, 1584576000.0, 1584662400.0,
    1584748800.0, 1584835200.0, 1584921600.0, 1585008000.0, 1585094400.0,
    1585180800.0, 1585267200.0, 1585353600.0, 1585440000.0, 1585526400.0,
    1585612800.0, 1585699200.0, 1585785600.0, 1585872000.0, 1585958400.0,
    1586044800.0, 1586131200.0, 1586217600.0, 1586304000.0, 1586390400.0,
    1586476800.0, 1586563200.0, 1586649600.0, 1586736000.0, 1586822400.0,
    1586908800.0, 1586995200.0, 1587081600.0, 1587168000.0, 1587254400.0,
    1587340800.0, 1587427200.0, 1587513600.0, 1587600000.0, 1587686400.0,
    1587772800.0, 1587859200.0, 1587945600.0, 1588032000.0, 1588118400.0,
    1588204800.0, 1588291200.0, 1588377600.0, 1588464000.0, 1588550400.0,
    1588636800.0, 1588723200.0, 1588809600.0, 1588896000.0, 1588982400.0,
    1589068800.0, 1589155200.0, 1589241600.0, 1589328000.0, 1589414400.0,
    1589500800.0, 1589587200.0, 1589673600.0, 1589760000.0, 1589846400.0,
    1589932800.0, 1590019200.0, 1590105600.0, 1590192000.0, 1590278400.0,
    1590364800.0, 1590451200.0, 1590537600.0, 1590624000.0, 1590710400.0,
    1590796800.0, 1590883200.0, 1590969600.0, 1591056000.0, 1591142400.0,
    1591228800.0, 1591315200.0, 1591401600.0, 1591488000.0, 1591574400.0,
    1591660800.0, 1591747200.0, 1591833600.0, 1591920000.0, 1592006400.0,
    1592092800.0, 1592179200.0, 1592265600.0, 1592352000.0, 1592438400.0,
    1592524800.0, 1592611200.0, 1592697600.0, 1592784000.0, 1592870400.0,
    1592956800.0, 1593043200.0, 1593129600.0, 1593216000.0, 1593302400.0,
    1593388800.0, 1593475200.0, 1593561600.0, 1593648000.0, 1593734400.0,
    1593820800.0, 1593907200.0, 1593993600.0, 1594080000.0, 1594166400.0,
    1594252800.0, 1594339200.0, 1594425600.0, 1594512000.0, 1594598400.0,
    1594684800.0, 1594771200.0, 1594857600.0, 1594944000.0, 1595030400.0,
    1595116800.0, 1595203200.0, 1595289600.0, 1595376000.0, 1595462400.0,
    1595548800.0, 1595635200.0, 1595721600.0, 1595808000.0, 1595894400.0,
    1595980800.0, 1596067200.0, 1596153600.0, 1596240000.0, 1596326400.0,
    1596412800.0, 1596499200.0, 1596585600.0, 1596672000.0, 1596758400.0,
    1596844800.0, 1596931200.0, 1597017600.0, 1597104000.0, 1597190400.0,
    1597276800.0, 1597363200.0, 1597449600.0, 1597536000.0, 1597622400.0,
    1597708800.0, 1597795200.0, 1597881600.0, 1597968000.0, 1598054400.0,
    1598140800.0, 1598227200.0, 1598313600.0, 1598400000.0, 1598486400.0,
    1598572800.0, 1598659200.0, 1598745600.0, 1598832000.0, 1598918400.0,
    1599004800.0, 1599091200.0, 1599177600.0, 1599264000.0, 1599350400.0,
    1599436800.0, 1599523200.0, 1599609600.0, 1599696000.0, 1599782400.0,
    1599868800.0, 1599955200.0, 1600041600.0, 1600128000.0, 1600214400.0,
    1600300800.0, 1600387200.0, 1600473600.0, 1600560000.0, 1600646400.0,
    1600732800.0, 1600819200.0, 1600905600.0, 1600992000.0, 1601078400.0,
    1601164800.0, 1601251200.0, 1601337600.0, 1601424000.0, 1601510400.0,
    1601596800.0, 1601683200.0, 1601769600.0, 1601856000.0, 1601942400.0,
    1602028800.0, 1602115200.0, 1602201600.0, 1602288000.0, 1602374400.0,
    1602460800.0, 1602547200.0, 1602633600.0, 1602720000.0, 1602806400.0,
    1602892800.0, 1602979200.0, 1603065600.0, 1603152000.0, 1603238400.0,
    1603324800.0, 1603411200.0, 1603497600.0, 1603584000.0, 1603670400.0,
    1603756800.0, 1603843200.0, 1603929600.0, 1604016000.0, 1604102400.0,
    1604188800.0, 1604275200.0, 1604361600.0, 1604448000.0, 1604534400.0,
    1604620800.0, 1604707200.0, 1604793600.0, 1604880000.0, 1604966400.0,
    1605052800.0, 1605139200.0, 1605225600.0, 1605312000.0, 1605398400.0,
    1605484800.0, 1605571200.0, 1605657600.0, 1605744000.0, 1605830400.0,
    1605916800.0, 1606003200.0, 1606089600.0, 1606176000.0, 1606262400.0,
    1606348800.0, 1606435200.0, 1606521600.0, 1606608000.0, 1606694400.0,
    1606780800.0, 1606867200.0, 1606953600.0, 1607040000.0, 1607126400.0,
    1607212800.0, 1607299200.0, 1607385600.0, 1607472000.0, 1607558400.0,
    1607644800.0, 1607731200.0, 1607817600.0, 1607904000.0, 1607990400.0,
    1608076800.0, 1608163200.0, 1608249600.0, 1608336000.0, 1608422400.0,
    1608508800.0, 1608595200.0, 1608681600.0, 1608768000.0, 1608854400.0,
    1608940800.0, 1609027200.0, 1609113600.0, 1609200000.0, 1609286400.0,
    1609372800.0, 1609459200.0, 1609545600.0, 1609632000.0, 1609718400.0,
    1609804800.0, 1609891200.0, 1609977600.0, 1610064000.0, 1610150400.0,
    1610236800.0, 1610323200.0, 1610409600.0, 1610496000.0, 1610582400.0,
    1610668800.0, 1610755200.0, 1610841600.0, 1610928000.0, 1611014400.0,
    1611100800.0, 1611187200.0, 1611273600.0, 1611360000.0, 1611446400.0,
    1611532800.0, 1611619200.0, 1611705600.0, 1611792000.0, 1611878400.0,
    1611964800.0, 1612051200.0, 1612137600.0, 1612224000.0, 1612310400.0,
    1612396800.0, 1612483200.0, 1612569600.0, 1612656000.0, 1612742400.0,
    1612828800.0, 1612915200.0, 1613001600.0, 1613088000.0, 1613174400.0,
    1613260800.0, 1613347200.0, 1613433600.0, 1613520000.0, 1613606400.0,
    1613692800.0, 1613779200.0, 1613865600.0, 1613952000.0, 1614038400.0,
    1614124800.0, 1614211200.0, 1614297600.0, 1614384000.0, 1614470400.0,
    1614556800.0, 1614643200.0, 1614729600.0, 1614816000.0, 1614902400.0,
    1614988800.0, 1615075200.0, 1615161600.0, 1615248000.0, 1615334400.0,
    1615420800.0, 1615507200.0, 1615593600.0, 1615680000.0, 1615766400.0,
    1615852800.0, 1615939200.0, 1616025600.0, 1616112000.0, 1616198400.0,
    1616284800.0, 1616371200.0, 1616457600.0, 1616544000.0, 1616630400.0,
    1616716800.0, 1616803200.0, 1616889600.0, 1616976000.0, 1617062400.0,
    1617148800.0, 1617235200.0, 1617321600.0, 1617408000.0, 1617494400.0,
    1617580800.0, 1617667200.0, 1617753600.0, 1617840000.0, 1617926400.0,
    1618012800.0, 1618099200.0, 1618185600.0, 1618272000.0, 1618358400.0,
    1618444800.0, 1618531200.0, 1618617600.0, 1618704000.0, 1618790400.0,
    1618876800.0, 1618963200.0, 1619049600.0, 1619136000.0, 1619222400.0,
    1619308800.0, 1619395200.0, 1619481600.0, 1619568000.0, 1619654400.0,
    1619740800.0, 1619827200.0, 1619913600.0, 1620000000.0, 1620086400.0,
    1620172800.0, 1620259200.0, 1620345600.0, 1620432000.0, 1620518400.0,
    1620604800.0, 1620691200.0, 1620777600.0, 1620864000.0, 1620950400.0,
    1621036800.0, 1621123200.0, 1621209600.0, 1621296000.0, 1621382400.0,
    1621468800.0, 1621555200.0, 1621641600.0, 1621728000.0, 1621814400.0,
    1621900800.0, 1621987200.0, 1622073600.0, 1622160000.0, 1622246400.0,
    1622332800.0, 1622419200.0, 1622505600.0, 1622592000.0, 1622678400.0,
    1622764800.0, 1622851200.0, 1622937600.0, 1623024000.0, 1623110400.0,
    1623196800.0, 1623283200.0, 1623369600.0, 1623456000.0, 1623542400.0,
    1623628800.0, 1623715200.0, 1623801600.0, 1623888000.0, 1623974400.0,
    1624060800.0, 1624147200.0, 1624233600.0, 1624320000.0, 1624406400.0,
    1624492800.0, 1624579200.0, 1624665600.0, 1624752000.0, 1624838400.0,
    1624924800.0, 1625011200.0, 1625097600.0, 1625184000.0, 1625270400.0,
    1625356800.0, 1625443200.0, 1625529600.0, 1625616000.0, 1625702400.0,
    1625788800.0, 1625875200.0, 1625961600.0, 1626048000.0, 1626134400.0,
    1626220800.0, 1626307200.0, 1626393600.0, 1626480000.0, 1626566400.0,
    1626652800.0, 1626739200.0, 1626825600.0, 1626912000.0, 1626998400.0,
    1627084800.0, 1627171200.0, 1627257600.0, 1627344000.0, 1627430400.0,
    1627516800.0, 1627603200.0, 1627689600.0, 1627776000.0, 1627862400.0,
    1627948800.0, 1628035200.0, 1628121600.0, 1628208000.0, 1628294400.0,
    1628380800.0, 1628467200.0, 1628553600.0, 1628640000.0, 1628726400.0,
    1628812800.0, 1628899200.0, 1628985600.0, 1629072000.0, 1629158400.0,
    1629244800.0, 1629331200.0, 1629417600.0, 1629504000.0, 1629590400.0,
    1629676800.0, 1629763200.0, 1629849600.0, 1629936000.0, 1630022400.0,
    1630108800.0, 1630195200.0, 1630281600.0, 1630368000.0, 1630454400.0,
    1630540800.0, 1630627200.0, 1630713600.0, 1630800000.0, 1630886400.0,
    1630972800.0, 1631059200.0, 1631145600.0, 1631232000.0, 1631318400.0,
    1631404800.0, 1631491200.0, 1631577600.0, 1631664000.0, 1631750400.0,
    1631836800.0, 1631923200.0, 1632009600.0, 1632096000.0, 1632182400.0,
    1632268800.0, 1632355200.0, 1632441600.0, 1632528000.0, 1632614400.0,
    1632700800.0, 1632787200.0, 1632873600.0, 1632960000.0, 1633046400.0,
    1633132800.0, 1633219200.0, 1633305600.0, 1633392000.0, 1633478400.0,
    1633564800.0, 1633651200.0, 1633737600.0, 1633824000.0, 1633910400.0,
    1633996800.0, 1634083200.0, 1634169600.0, 1634256000.0, 1634342400.0,
    1634428800.0, 1634515200.0, 1634601600.0, 1634688000.0, 1634774400.0,
    1634860800.0, 1634947200.0, 1635033600.0, 1635120000.0, 1635206400.0,
    1635292800.0, 1635379200.0, 1635465600.0, 1635552000.0, 1635638400.0,
    1635724800.0, 1635811200.0, 1635897600.0, 1635984000.0, 1636070400.0,
    1636156800.0, 1636243200.0, 1636329600.0, 1636416000.0, 1636502400.0,
    1636588800.0, 1636675200.0, 1636761600.0, 1636848000.0, 1636934400.0,
    1637020800.0, 1637107200.0, 1637193600.0, 1637280000.0, 1637366400.0,
    1637452800.0, 1637539200.0, 1637625600.0, 1637712000.0, 1637798400.0,
    1637884800.0, 1637971200.0, 1638057600.0, 1638144000.0, 1638230400.0,
    1638316800.0, 1638403200.0, 1638489600.0, 1638576000.0, 1638662400.0,
    1638748800.0, 1638835200.0, 1638921600.0, 1639008000.0, 1639094400.0,
    1639180800.0, 1639267200.0, 1639353600.0, 1639440000.0, 1639526400.0,
    1639612800.0, 1639699200.0, 1639785600.0, 1639872000.0, 1639958400.0,
    1640044800.0, 1640131200.0, 1640217600.0, 1640304000.0, 1640390400.0,
    1640476800.0, 1640563200.0, 1640649600.0, 1640736000.0, 1640822400.0,
    1640908800.0, 1640995200.0, 1641081600.0, 1641168000.0, 1641254400.0,
    1641340800.0, 1641427200.0, 1641513600.0, 1641600000.0, 1641686400.0,
    1641772800.0, 1641859200.0, 1641945600.0, 1642032000.0, 1642118400.0,
    1642204800.0, 1642291200.0, 1642377600.0, 1642464000.0, 1642550400.0,
    1642636800.0, 1642723200.0, 1642809600.0, 1642896000.0, 1642982400.0,
    1643068800.0, 1643155200.0, 1643241600.0, 1643328000.0, 1643414400.0,
    1643500800.0, 1643587200.0, 1643673600.0, 1643760000.0, 1643846400.0,
    1643932800.0, 1644019200.0, 1644105600.0, 1644192000.0, 1644278400.0,
    1644364800.0, 1644451200.0, 1644537600.0, 1644624000.0, 1644710400.0,
    1644796800.0, 1644883200.0, 1644969600.0, 1645056000.0, 1645142400.0,
    1645228800.0, 1645315200.0, 1645401600.0, 1645488000.0, 1645574400.0,
    1645660800.0, 1645747200.0, 1645833600.0, 1645920000.0, 1646006400.0,
    1646092800.0, 1646179200.0, 1646265600.0, 1646352000.0, 1646438400.0,
    1646524800.0, 1646611200.0, 1646697600.0, 1646784000.0, 1646870400.0,
    1646956800.0, 1647043200.0, 1647129600.0, 1647216000.0, 1647302400.0,
    1647388800.0, 1647475200.0, 1647561600.0, 1647648000.0, 1647734400.0,
    1647820800.0, 1647907200.0, 1647993600.0, 1648080000.0, 1648166400.0,
    1648252800.0, 1648339200.0, 1648425600.0, 1648512000.0, 1648598400.0,
    1648684800.0, 1648771200.0, 1648857600.0, 1648944000.0, 1649030400.0,
    1649116800.0, 1649203200.0, 1649289600.0, 1649376000.0, 1649462400.0,
    1649548800.0, 1649635200.0, 1649721600.0, 1649808000.0, 1649894400.0,
    1649980800.0, 1650067200.0, 1650153600.0, 1650240000.0, 1650326400.0,
    1650412800.0, 1650499200.0, 1650585600.0, 1650672000.0, 1650758400.0,
    1650844800.0, 1650931200.0, 1651017600.0, 1651104000.0, 1651190400.0,
    1651276800.0, 1651363200.0, 1651449600.0, 1651536000.0, 1651622400.0,
    1651708800.0, 1651795200.0, 1651881600.0, 1651968000.0, 1652054400.0,
    1652140800.0, 1652227200.0, 1652313600.0, 1652400000.0, 1652486400.0,
    1652572800.0, 1652659200.0, 1652745600.0, 1652832000.0, 1652918400.0,
    1653004800.0, 1653091200.0, 1653177600.0, 1653264000.0, 1653350400.0,
    1653436800.0, 1653523200.0, 1653609600.0, 1653696000.0, 1653782400.0,
    1653868800.0, 1653955200.0, 1654041600.0, 1654128000.0, 1654214400.0,
    1654300800.0, 1654387200.0, 1654473600.0, 1654560000.0, 1654646400.0,
  ],
  orders: [
    {
      datetime: 1578528000.0,
      type: 'buy',
      size: 0.6200362960186099,
      at_price: 8054.719999999999,
      txn_value: 4994.218754267017,
      txn_comm: 4.994218754267018,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1578614400.0,
      type: 'sell',
      size: -0.6200362960186099,
      at_price: 7817.740000000001,
      txn_value: -4847.282552836527,
      txn_comm: 4.8472825528365275,
      gross_profit: -146.93620143049046,
      exec_type: 'Market',
    },
    {
      datetime: 1578873600.0,
      type: 'buy',
      size: 0.5911290039762033,
      at_price: 8184.97,
      txn_value: 4838.3731636751045,
      txn_comm: 4.838373163675104,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1582761600.0,
      type: 'sell',
      size: -0.5911290039762033,
      at_price: 8786.0,
      txn_value: -5193.659428934922,
      txn_comm: 5.1936594289349225,
      gross_profit: 355.2862652598173,
      exec_type: 'Market',
    },
    {
      datetime: 1583625600.0,
      type: 'buy',
      size: 0.5832661599970838,
      at_price: 8885.25,
      txn_value: 5182.465648114088,
      txn_comm: 5.182465648114088,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1583712000.0,
      type: 'sell',
      size: -0.5832661599970838,
      at_price: 8034.76,
      txn_value: -4686.403611698169,
      txn_comm: 4.686403611698169,
      gross_profit: -496.06203641591964,
      exec_type: 'Market',
    },
    {
      datetime: 1587945600.0,
      type: 'buy',
      size: 0.6080595700861328,
      at_price: 7693.1,
      txn_value: 4677.863078629628,
      txn_comm: 4.6778630786296285,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1599177600.0,
      type: 'sell',
      size: -0.6080595700861328,
      at_price: 10138.29,
      txn_value: -6164.684258808539,
      txn_comm: 6.16468425880854,
      gross_profit: 1486.8211801789112,
      exec_type: 'Market',
    },
    {
      datetime: 1600128000.0,
      type: 'buy',
      size: 0.5765084641852531,
      at_price: 10671.77,
      txn_value: 6152.36573283826,
      txn_comm: 6.152365732838259,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1600732800.0,
      type: 'sell',
      size: -0.5765084641852531,
      at_price: 10417.22,
      txn_value: -6005.615503279902,
      txn_comm: 6.005615503279903,
      gross_profit: -146.7502295583568,
      exec_type: 'Market',
    },
    {
      datetime: 1600819200.0,
      type: 'buy',
      size: 0.5692154249069604,
      at_price: 10529.61,
      txn_value: 5993.616430254579,
      txn_comm: 5.99361643025458,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1600905600.0,
      type: 'sell',
      size: -0.5692154249069604,
      at_price: 10241.46,
      txn_value: -5829.597005567638,
      txn_comm: 5.8295970055676385,
      gross_profit: -164.01942468694148,
      exec_type: 'Market',
    },
    {
      datetime: 1600992000.0,
      type: 'buy',
      size: 0.5418942090744258,
      at_price: 10736.33,
      txn_value: 5817.955053712029,
      txn_comm: 5.817955053712029,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1619395200.0,
      type: 'sell',
      size: -0.5418942090744258,
      at_price: 49066.76,
      txn_value: -26588.99310204467,
      txn_comm: 26.58899310204467,
      gross_profit: 20771.03804833264,
      exec_type: 'Market',
    },
    {
      datetime: 1619913600.0,
      type: 'buy',
      size: 0.4590946754120544,
      at_price: 57797.35,
      txn_value: 26534.4556379269,
      txn_comm: 26.534455637926904,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1620864000.0,
      type: 'sell',
      size: -0.4590946754120544,
      at_price: 49537.15,
      txn_value: -22742.24180008825,
      txn_comm: 22.74224180008825,
      gross_profit: -3792.2138378386503,
      exec_type: 'Market',
    },
    {
      datetime: 1627430400.0,
      type: 'buy',
      size: 0.5752513518622585,
      at_price: 39456.61,
      txn_value: 22697.46824240191,
      txn_comm: 22.69746824240191,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1628035200.0,
      type: 'sell',
      size: -0.5752513518622585,
      at_price: 38207.04,
      txn_value: -21978.651410655384,
      txn_comm: 21.978651410655388,
      gross_profit: -718.8168317465222,
      exec_type: 'Market',
    },
    {
      datetime: 1628121600.0,
      type: 'buy',
      size: 0.5522081464942404,
      at_price: 39723.17,
      txn_value: 21935.458078575615,
      txn_comm: 21.935458078575618,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1632182400.0,
      type: 'sell',
      size: -0.5522081464942404,
      at_price: 43016.64,
      txn_value: -23754.139042810002,
      txn_comm: 23.754139042810003,
      gross_profit: 1818.6809642343867,
      exec_type: 'Market',
    },
    {
      datetime: 1633046400.0,
      type: 'buy',
      size: 0.5409508005048128,
      at_price: 43820.01,
      txn_value: 23704.469487628903,
      txn_comm: 23.704469487628906,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1637971200.0,
      type: 'sell',
      size: -0.5409508005048128,
      at_price: 53723.72,
      txn_value: -29061.889340096423,
      txn_comm: 29.061889340096425,
      gross_profit: 5357.419852467519,
      exec_type: 'Market',
    },
    {
      datetime: 1638144000.0,
      type: 'buy',
      size: 0.5064354707779244,
      at_price: 57274.89,
      txn_value: 29006.035880903833,
      txn_comm: 29.006035880903834,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1638576000.0,
      type: 'sell',
      size: -0.5064354707779244,
      at_price: 53601.05,
      txn_value: -27145.472990941067,
      txn_comm: 27.145472990941066,
      gross_profit: -1860.562889962768,
      exec_type: 'Market',
    },
    {
      datetime: 1646179200.0,
      type: 'buy',
      size: 0.609871708376031,
      at_price: 44421.2,
      txn_value: 27091.233132113346,
      txn_comm: 27.09123313211335,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1646265600.0,
      type: 'sell',
      size: -0.609871708376031,
      at_price: 43892.99,
      txn_value: -26769.092797032044,
      txn_comm: 26.769092797032044,
      gross_profit: -322.1403350813028,
      exec_type: 'Market',
    },
    {
      datetime: 1648080000.0,
      type: 'buy',
      size: 0.6229918147004508,
      at_price: 42882.76,
      txn_value: 26715.608471763906,
      txn_comm: 26.715608471763904,
      gross_profit: 0.0,
      exec_type: 'Market',
    },
    {
      datetime: 1649289600.0,
      type: 'sell',
      size: -0.6229918147004508,
      at_price: 43170.47,
      txn_value: -26894.84944677137,
      txn_comm: 26.89484944677137,
      gross_profit: 179.24097500746618,
      exec_type: 'Market',
    },
  ],
  trades_closed: [
    {
      datetime: 1578614400.0,
      pnl_gross: -146.9362014304899,
      pnl_with_comm: -156.77770273759344,
    },
    {
      datetime: 1582761600.0,
      pnl_gross: 355.2862652598173,
      pnl_with_comm: 345.25423266720725,
    },
    {
      datetime: 1583712000.0,
      pnl_gross: -496.06203641591964,
      pnl_with_comm: -505.9309056757319,
    },
    {
      datetime: 1599177600.0,
      pnl_gross: 1486.8211801789112,
      pnl_with_comm: 1475.978632841473,
    },
    {
      datetime: 1600732800.0,
      pnl_gross: -146.7502295583568,
      pnl_with_comm: -158.90821079447497,
    },
    {
      datetime: 1600905600.0,
      pnl_gross: -164.01942468694148,
      pnl_with_comm: -175.8426381227637,
    },
    {
      datetime: 1619395200.0,
      pnl_gross: 20771.03804833264,
      pnl_with_comm: 20738.631100176885,
    },
    {
      datetime: 1620864000.0,
      pnl_gross: -3792.2138378386503,
      pnl_with_comm: -3841.4905352766655,
    },
    {
      datetime: 1628035200.0,
      pnl_gross: -718.8168317465222,
      pnl_with_comm: -763.4929513995795,
    },
    {
      datetime: 1632182400.0,
      pnl_gross: 1818.6809642343867,
      pnl_with_comm: 1772.9913671130012,
    },
    {
      datetime: 1637971200.0,
      pnl_gross: 5357.419852467519,
      pnl_with_comm: 5304.653493639794,
    },
    {
      datetime: 1638576000.0,
      pnl_gross: -1860.562889962768,
      pnl_with_comm: -1916.714398834613,
    },
    {
      datetime: 1646265600.0,
      pnl_gross: -322.1403350813028,
      pnl_with_comm: -376.0006610104482,
    },
    {
      datetime: 1649289600.0,
      pnl_gross: 179.24097500746618,
      pnl_with_comm: 125.6305170889309,
    },
  ],
  plot_info: {
    roc: [
      {
        name: 'ROC',
        output_name: 'real',
        data: [
          -4.007097303984297, -4.8021822883140715, 1.2292286001347907,
          2.074776914289833, 2.1764787559011456, 6.936981890460592,
          11.33302533849816, 9.037876408995915, 7.890698316312461,
          13.922973970255303, 11.375879236479026, 17.503886897387332,
          10.420478804513579, 19.797093054088123, 19.87647358586717,
          12.400232018561486, 9.42877347371731, 10.67505132833,
          11.306819344671615, 5.432828027746672, 8.92791904249497,
          6.076740566256733, 3.62722154681554, -4.211232450360447,
          -5.450715928632722, -1.2042417382548853, -0.06406171465499488,
          5.1396596664857075, 6.893250744107471, 10.076657390640253,
          7.061102125336083, 8.088238681648763, 11.029660230447424,
          10.110676620452663, 10.268350642281487, 11.573302379570528,
          9.704442401238511, 4.688608426736773, 6.380885725251639,
          6.712140276520762, 5.334607805715663, 8.934521519807426,
          10.662261520375571, 10.08787977925667, 12.475127813139464,
          3.0449311488508046, 1.4865943512075264, -1.0977477472887465,
          2.725200984330556, -5.496195237274348, -2.5925132691083763,
          -5.341149633965503, -6.542416278182439, -2.866477086659047,
          -6.653190724220748, -5.945448230742512, -11.414633260967989,
          -9.095301875128792, -14.479508023347442, -11.154924174908976,
          -11.093095133393504, -7.872337127533702, -9.230161871584508,
          -11.931182319552347, -6.228685819267133, -1.9747011541632387,
          1.1543211633135098, -8.952524081371728, -8.777728056542633,
          -7.3800889529201825, -7.001504943810732, -46.159609836639284,
          -36.317860473717666, -40.89661942184034, -40.78973525292998,
          -44.92952163190931, -40.21780961576115, -32.8665269982112,
          -22.289142192747168, -21.3590117751971, -22.024520701945438,
          21.170624999999998, 15.930699458645536, 30.406839827844223,
          24.548710200884116, 33.970972187147666, 19.697739730152986,
          15.642568940708767, -4.559122545384319, 2.996282432075459,
          3.611778282780942, 14.21428804767384, 5.052796293976924,
          -0.15745649930611894, 2.689058515027476, 0.5257252098744836,
          15.266130008759093, 15.403622136884376, 25.161610631446152,
          13.905335622843818, 6.9960876320502186, 3.5211924876409784,
          1.614638605022889, 1.54155714943347, 0.1707746401846899,
          -2.2374859363511024, -3.110001500702597, -2.358794662457686,
          -1.5307120500782423, -2.2351768508170555, -0.46785791349075456,
          -0.5156445629745154, 3.206209922375969, 9.425102114535001,
          9.263761701632035, 13.85586385631694, 8.323922759133428,
          10.630589608042618, 6.7651684463206685, 23.281709485250126,
          26.26651022509716, 29.02328042482718, 25.92103453405825,
          18.867901833505062, 18.21399067288474, 19.67402738148771,
          18.84571889095423, 28.448721609544904, 26.63180419125002,
          8.66690132903194, 1.1922273781902692, -3.0071508197612618,
          -1.7973595777999285, 4.668344171906069, 10.36997461665743,
          3.265302050692598, 2.606935202320493, -3.067772170151395,
          -0.6742850262397737, 2.475312912761818, 9.041393960863342,
          5.9233640755379735, 4.076932942448974, -1.3988057193988546,
          -10.944058300772664, -4.465985861521915, -5.757109645069381,
          -4.917025136259778, -1.6235991012879758, -3.564614910905095,
          1.9585908743480207, 4.186069591394537, 11.240676117775351,
          3.691954048032775, 10.847054128623412, 9.985112944996533,
          8.82212555337636, 5.022017433591874, 1.7867790878967948,
          3.7651147175103317, 0.7703872662852884, 4.622327685385796,
          -9.0225541797335, -0.5558917592277557, -1.9956053232694337,
          -4.56591337676957, -2.0282377592722667, -1.455675904948106,
          -2.891661938711343, -4.045078669925717, -4.729632240906312,
          -5.32169954476479, 0.15398043187795363, 2.3302374082553756,
          1.5997525687877712, -0.4882199933633813, -1.8727946683754215,
          -3.8147768274721106, -4.787462203411675, -2.8762070758294955,
          -1.2638785507984251, -2.3549650334706462, -0.6744711227593458,
          -6.185929964721149, -5.887132216575974, -1.7321591267241754,
          -1.9469181544063519, 1.9863111629181418, 2.7229249889036744,
          3.5069956726101914, 0.4337203129487488, 1.6391002949045719,
          0.021988734835365342, 2.379453565383516, 2.0352694667629345,
          1.317831833317662, 1.4134326268191666, -2.25252027995978,
          -1.113380769309702, -2.8166416915534565, -0.2538876547127922,
          -1.3733347401150175, 1.6890783330788306, 2.315551852946718,
          3.902032107778952, 3.0461816040666, 5.466860920240069,
          8.734885676372839, 20.489124260458436, 18.93061062475736,
          20.540146096368893, 21.164464161348697, 20.71842385516507,
          23.985833396370726, 15.287292765901617, 17.63519889282643,
          15.37613835277234, 18.25869905372177, 6.641003231199383,
          6.307931125856947, 5.9535896033792834, 5.244058124564743,
          4.917841887316454, -3.466520692439823, 4.452754180836127,
          4.992865298075455, 5.080160150536517, 0.9152049696421694,
          1.262831074452131, 5.924498651484389, 1.5610373246064846,
          0.6241396785393816, -0.33103728941252797, 1.2224282132850206,
          0.8528812304733568, -1.1194397283531443, -0.10492715470548086,
          -4.505247882285445, -3.7744102090504605, -7.741551469612329,
          -3.5002063623220114, -2.456487210527969, -1.2012435093284202,
          1.024772489580572, 2.220791291404578, -2.228598066814147,
          -13.681670383548116, -7.7057575173920085, -11.296496161473746,
          -9.480529337939226, -10.006758099091595, -11.679824592005472,
          -12.739643212115615, -11.267770060714998, -12.86767203742335,
          -8.320820754899227, 1.8931351908370475, 2.1588608352279604,
          6.084772920193293, 6.803786977633042, 5.461544097232918,
          7.9665042240030015, 8.429720526068563, 5.643971531034064,
          0.28234800330000365, 0.849446217383143, -0.8842688789034603,
          0.604866859012132, -0.9145773278653957, -2.057785231161924,
          -1.5149922440514052, -2.1701411913413793, -2.1674721248302276,
          -1.3158087521565442, 1.9382330410608661, 0.3873837682497161,
          2.9351283898975344, -0.6491050937379006, 0.9875854686258512,
          -1.2018343493093275, -1.0010905631482547, 2.14517039823785,
          1.9386595427508757, 4.794002555539367, 7.061689611107513,
          9.06162491485658, 8.33328590427298, 7.04308671061058,
          6.605783245507646, 6.7894630582490345, 6.504637464034246,
          5.286406109704123, 6.341985622552171, 5.461418443986754,
          12.419188285357908, 12.49339665604059, 13.156184985674969,
          14.834965129283951, 13.243755823494219, 15.308958488672463,
          20.034594461365106, 15.328510302404386, 14.502270779740755,
          13.854839508681383, 7.9026927554737725, 6.114653021316241,
          4.846371643889569, 6.954078523581564, 8.55932574145184,
          19.443710212615663, 14.254369078707585, 11.697973828619679,
          15.00776622546578, 13.040538049129434, 10.921688057428746,
          13.971878065617837, 20.240719679217545, 16.38082565516672,
          13.620182678038262, 2.353941816623717, 7.276353152005921,
          19.172779603598265, 14.869176935851769, 16.142639712794747,
          21.954722462462108, 19.252192009303613, 13.028408051628237,
          12.544192344691085, 19.225099483835216, 17.309707338472148,
          2.6080603964323767, -2.943817959633921, -0.31654826812600456,
          2.146682379533149, 5.5757847346141975, 0.3269923758808302,
          4.288267407679736, 5.737695993031378, -2.659132223834948,
          2.2893716635032257, 12.886287447950284, 11.828685984204924,
          3.410074013041875, 1.9592532082778114, -7.3174731555397,
          -3.8818627910744308, -2.0589364036515234, -1.2712968350161447,
          3.3383519601598133, 1.4558959162634055, 10.20754775457917,
          18.94025637948755, 26.103750741509412, 28.47877816418285,
          28.49079932050116, 25.964972198089107, 26.594621954001063,
          21.16178417824466, 23.12057090852866, 27.21055798723697,
          24.175037683637424, 15.284798632812159, 17.18939265750048,
          14.958644692781053, 23.107652271192446, 27.30633445585353,
          23.18654693943376, 38.504120905135686, 39.06937444161625,
          29.443596694300478, 28.14339727758508, 39.9050136102514,
          45.61720510158824, 48.19357312397297, 38.83106601642774,
          31.89914267330898, 20.70381897531306, 5.820407709163256,
          13.246434475099257, 22.369736072508097, 8.226004896091354,
          -2.1060469912992708, -9.138883168814981, -9.736979770498888,
          -10.468736202305816, -7.029590023805998, -12.863742911558907,
          -3.248251752359088, -14.164261528474453, -17.58765599254045,
          -12.214885219238248, -9.799171995650513, -15.2460840652205,
          -8.916999055724784, -4.567350087722744, -3.3983934354773426,
          7.270147646055314, 1.7641432719879946, 10.562503896751663,
          16.61186178506444, 14.517365180348607, 17.933076401613036,
          29.048101257485715, 16.27709512343227, 35.392383554924955,
          35.48312342686897, 35.39904837823611, 43.07993976085094,
          33.331303233723105, 25.345843721515294, 31.516466296627787,
          25.126141805326885, 25.382206418771137, 34.34407275653557,
          11.164947740015219, 20.43406759352888, 24.62442738483086,
          19.677207459488333, 14.38023921704632, 3.684356409858913,
          2.2611362106015775, -1.7477578264744387, -5.813921065994743,
          -11.537439483066958, -12.447364439426911, -11.30284763710514,
          -13.252833616189053, -12.2964219453646, -10.563553578847085,
          -0.284899061176902, -1.5983509205615531, 8.280669494429272,
          13.177857534444293, 19.038711086501394, 23.74160475331477,
          16.508611223539702, 18.127481774088494, 21.52761792252813,
          21.900608362865338, 14.057947916083346, 16.403823886813605,
          15.579649511739358, 10.067728658446363, 5.731144494347218,
          4.029768892882024, -0.7297506316081837, -5.484752992395192,
          -11.190848459977454, -11.302104469332763, -7.753627358592363,
          -3.29549259016797, -5.254920945251962, -3.2447349577159224,
          -0.6798895950560713, 1.108889358558729, 2.421887041956672,
          8.574170376225542, 8.481863289320435, 9.078314802121845,
          13.46796824098362, 7.459074950400346, 3.89487888487301,
          0.31521597457617734, 0.7669756141487127, -1.028196199369591,
          1.7510561273260006, 2.183209117642848, 1.5436638602775332,
          11.433546343910471, 8.174150686548455, 6.815475531113746,
          5.765793573674616, 7.243896488956447, -3.3188572790298076,
          -4.315944917439108, -5.5950789312141485, -10.357580517989028,
          -13.646909455395928, -19.582949272512785, -20.5079199288813,
          -22.313512448863992, -11.956360826154167, -8.323559418237913,
          -2.321976434198325, -3.7354353897694814, 2.2494816127602846,
          7.4603398588114045, 9.45474798688204, 11.822461513063832,
          6.298313773381636, 17.057042882586337, 4.430052633830361,
          4.185961709787889, 7.321981350765827, 8.749584539258692,
          -3.255314609232418, -1.9556103187574836, -12.278384204802517,
          -13.116144846044353, -6.313081520097463, -18.582595513519284,
          -17.665419245560855, -24.036936390719667, -27.203045085925492,
          -37.00281451984553, -27.392614394331105, -34.26504878593655,
          -24.544158809396965, -30.230373999138738, -22.16059123480556,
          -18.0447614662792, -15.484294067604953, -11.50272267653757,
          -16.77089123911488, -5.682569871046905, -12.054712653207867,
          0.004831954034156816, -2.020415100456019, 8.406893616407318,
          1.161193505873892, -3.902755192992935, -9.501856561008381,
          -7.0949167375638496, -5.918377590078805, -3.5380282992560463,
          4.901003808225712, -1.5517607460820648, 1.7411725204936612,
          -5.383660006154056, -0.5764038281857009, 10.011919954383774,
          13.039771127355483, 7.131181957022958, 13.531452973061263,
          7.306683091273092, -5.093418886515888, -2.9326213636705623,
          -15.330153932365764, -8.542566261118301, -13.691496561941563,
          -14.446534961616686, -21.32219378019751, -15.816210118592366,
          -8.90618400193003, -3.698927745070879, 1.2062151318971148,
          -1.5594311935676841, 5.997545630301326, 3.928044550587595,
          2.9427458283684205, 1.7985124811435105, 6.6668566335649215,
          5.997958719041985, -2.4155959278785, -4.693970614198218,
          -5.8363103086373265, -4.400428021115699, 2.2513266053200143,
          -2.071593577917852, -5.593910201957753, -6.989895005201719,
          -5.372907325407372, -8.287957835196435, -6.916430512915328,
          -3.337266328240507, -8.801090377548238, -11.081199909142125,
          -6.172044184606729, -2.414268240676054, 2.7629891685764907,
          4.381837671031286, 10.981869510664977, 18.652033672074218,
          25.183319707094576, 25.93257844282433, 29.756595810912255,
          41.67799304137078, 28.985727267268977, 23.406992665657622,
          16.3932783672756, 11.526924695853324, 12.272568738832291,
          9.734408232539149, 8.563564125483714, 11.376886702402533,
          9.440835375825163, 9.588671093960466, 9.944471819020029,
          14.218841603957678, 13.413722654288286, 25.107800785457137,
          18.4912940001279, 14.955927763526722, 7.153697270598891,
          0.27687450614211073, 2.0799933872778675, 1.0966112761440217,
          8.198926883607971, 7.274878600777845, 10.901641928872285,
          3.5331589958158993, 1.2864227059662614, 4.25662635059274,
          2.053493485695057, 9.786009694390675, 9.372626818884289,
          4.292522212066485, -4.743395859939692, -3.525018603343144,
          -0.8706474229283168, -0.48942337516429957, 4.877143751910107,
          1.9241497207050706, 10.488053186041203, 7.324245616966807,
          -4.155037237692327, -5.576463008503774, -1.2510293636558534,
          -4.776937336003617, -7.450914270120446, -6.541359979076744,
          -10.11699401229701, -5.617718214170953, -7.024128966042764,
          -9.35380782661368, 0.9308904775612081, 4.874076811939454,
          1.824781647388063, -4.091979404654222, -9.827202515446498,
          -5.391889319860144, -0.16793229660488906, -9.129295764602796,
          -11.327120298428506, -9.587618370507911, -10.893514119879132,
          -15.046153935353423, -12.102578757137493, 1.8795033060083677,
          18.18422178022594, 9.395844763445194, 7.432811043555754,
          14.983145517567277, 20.62624324359794, 28.15997812835227,
          27.612340989409788, 31.5037290495372, 32.33154193161207,
          24.723610981172463, 19.379783933275174, 17.554419133870326,
          19.01864750650466, 16.50179766597988, 19.8174385719301,
          10.052553556901378, 14.396352752670726, 14.936415957834658,
          16.980741667109477, 20.751221207852332, 8.215919758279554,
          8.377762852356362, 6.832760994997122, 6.110559507455715,
          2.28037103132972, -0.8981599679477315, -5.062529732238785,
          -2.312455571567351, -3.1531757875900013, -6.275956831831319,
          -1.4364122093831866, 0.3672706169335571, 3.1544175535495045,
          3.3593844234442116, -2.6693128814476053, 1.0083242152464145,
          5.23367567463926, 4.453421980263106, 8.468764351554302,
          8.22589173896393, 5.844439949233116, 6.342274832949202,
          1.4271435348218287, 2.358669356377341, 6.717304875428787,
          4.380942190901038, -2.296609713162112, -4.628661025871928,
          -15.74835881321266, -13.287126092233848, -7.975841841928544,
          -9.497970335747574, -12.281310241393673, -10.622444858651757,
          -12.79139975976471, -7.304854799978743, -10.543555015270856,
          -9.319499735437331, 0.6736668774768528, -0.47541662475039104,
          -4.617425848105217, -2.4529178626052084, 0.4145274483094097,
          -6.847641701338869, -13.976302055941826, -16.22111873129676,
          -6.11357182382708, -7.551173652981314, -11.87901223014347,
          -17.70737976244565, -17.22550226020604, -13.629809840397867,
          -11.37818929560267, -12.869710574699567, -1.6462855274617993,
          -1.0756872018629737, -5.569851425163829, -8.811706904373384,
          -7.20551665217325, -1.817960403898644, -0.4802236037177332,
          -1.012573600440092, -2.9283232675176074, 8.856138021850967,
          5.123193957877903, 3.14065410443225, 6.598683500593516,
          9.907047724750285, 1.5143970852243838, -0.4639337909476682,
          0.4406132391584938, -5.467286890456668, -1.7813187410266274,
          -6.988027453829071, -8.60665092483275, -9.06285875738051,
          -14.424991270394438, -15.02744300753589, -12.572128318049858,
          -10.297977000154535, -11.154823754315235, -9.508290576635014,
          -10.46329153976152, -7.155409889316489, -8.366665877221113,
          -6.0482837213554514, -0.8442588259499728, -0.024720122946042533,
          1.5280100696522858, 1.613205840535481, -0.4887420451923363,
          -2.7295840108993885, -14.706492899835222, -20.11550097420066,
          -14.839153376248316, -14.862089978718052, -14.218570156314525,
          -14.539304962938527, -11.94627125688541, -10.94528443912608,
          -8.384947579225265, -6.880745784693609, 5.546913992500002,
          10.330833482077416, 1.798366926889683, 1.7764696736392471,
          12.48955580232003, 12.424156477676584, 14.04939706836097,
          16.235388381124906, 15.395956280373223, 17.134789935842477,
          13.072381709989633, 9.508150881040489, 14.422859057099412,
          12.709314875450307, 2.3131866479852325, 7.6415468437330825,
          3.522084374388723, -7.582780014320257, -9.237678913261782,
          -9.676102794690078, -11.745024305996221, -12.66249159561832,
          -9.445147280049893, -11.422668086440046, -9.894526840126261,
          -11.955790185444526, -10.842156414934179, -6.951947023005889,
          7.968992186006862, 10.833632532809446, 14.343673061297757,
          14.71524117924261, 2.402098020079868, 5.766307176830288,
          0.24421292340350842, -3.1392046287567976, -0.9870203841221947,
          11.2539646203474, -8.660797034291011, -12.81286863029364,
          -11.586408578319363, -11.015828897159285, 1.3351925710867185,
          -0.2985687583824115, 7.009716869581872, 7.712698746972735,
          7.815209822303437, 0.6185250911324269, 4.667723606108276,
          5.8680744454431055, 9.165194437343827, 13.514503668071942,
          10.889691986941695, 12.812596024524247, 8.26304908303741,
          14.443214338956789, 12.847269868342238, 12.401729527147754,
          14.070729780905534, 10.9947380936412, 9.251600351523791,
          6.828478390849835, 5.4917249848038585, 5.116651577093578,
          2.2157983809493853, -7.810089570801592, -7.805279081774808,
          -10.926134399217457, -9.165507173771381, -7.364238544471425,
          -14.590602394071839, -12.521141210626263, -11.333463341475003,
          -14.250874453714669, -10.870145755101102, -6.466827903425654,
          -8.66875409577207, -3.4338721400473116, -2.9489425192561036,
          -1.8991504749299337, 2.402097623477606, -0.912690075144218,
          -4.146492436167293, -1.2324002725926708, -0.31026906260865905,
          -5.612016827679733, -1.114972181141649, -2.595663404420412,
          -6.9820389760437696, -9.012459200946655, -4.969514582629808,
          -2.9817286582095037, -4.342242708206568, 0.6080334843003188,
          -9.580721158222616, -5.507042937187534, -9.591591539546107,
          -14.35171846861525, -22.074245306068406, -17.57523092785698,
          -24.343154827280088, -24.64729542979186, -22.37512573236201,
          -24.195666414714033, -14.291807204722362, -17.048367888171654,
          -14.172882064050373, -15.63845539155777, 0.8076788675206448,
          -5.855125076167667, 1.1720749836620081, 4.354808429283752,
          -0.607435709639581, -1.4363802791528713, -5.70317046023654,
          -2.2516562055110767, -5.962010751872315, 1.1004926986709629,
          -2.807228283831753, 8.675076649746028, 8.001274237512156,
          -1.6112463416775635, 4.615284197580483, 0.15387167850631212,
          1.0895957132436074, 2.4583110027447397, 9.581974027062712,
          7.212897238948401, 2.499889711247083,
        ],
      },
    ],
    ema100: [
      {
        name: 'EMA',
        output_name: 'real',
        data: [
          8103.056558772898, 8080.534844737791, 8065.969006228132,
          8051.87278828302, 8038.147584554643, 8032.600107632768,
          8034.831392630139, 8035.250176934493, 8030.943440757572,
          8034.232085495046, 8033.950460039699, 8036.941143999308,
          8038.394586692391, 8053.674099827195, 8068.876790919726,
          8081.770517832207, 8098.236052132559, 8114.428605555677,
          8126.057742079327, 8136.281351147063, 8148.157562015438,
          8158.735828114142, 8163.602841418813, 8169.056250499629,
          8172.452760390725, 8181.216072066157, 8195.599318163857,
          8218.938143546751, 8240.375606050777, 8265.580247515118,
          8287.111133702938, 8308.8437845207, 8329.094600668806,
          8348.166786794176, 8364.975761313102, 8389.670102673239,
          8417.042971927234, 8444.700140799961, 8473.419939992042,
          8506.65419860606, 8533.291343188119, 8566.752504709146,
          8601.598197685202, 8633.836451196386, 8667.708204638042,
          8692.203487714516, 8716.46223053205, 8736.057037848246,
          8764.347195514618, 8780.771805504428, 8796.92325490038,
          8814.351507278589, 8830.916031886933, 8852.806803532736,
          8868.714193561791, 8877.568169926904, 8875.740087354094,
          8874.699887604507, 8871.100087849962, 8864.21909601135,
          8857.638123813105, 8858.778755024727, 8856.824126212356,
          8854.7260247032, 8858.685509362544, 8864.095301256355,
          8864.542126974051, 8848.082084855752, 8829.899667333857,
          8811.37808976289, 8794.014563232931, 8714.92516594119,
          8652.819717110671, 8583.89378211838, 8520.08004385861,
          8450.949151901013, 8388.804416219806, 8329.482348571888,
          8286.569232758584, 8245.416574684157, 8204.655454393382,
          8157.35910876183, 8123.892789776447, 8096.582437503646,
          8068.480409038228, 8042.121589057273, 8008.794626897723,
          7973.702654087867, 7932.2713144029585, 7901.818021048444,
          7872.285783007881, 7847.941906116635, 7827.073551540068,
          7805.430510915513, 7786.649510699364, 7766.572886725119,
          7757.925898869176, 7746.824791960876, 7739.190241625017,
          7730.167464563136, 7712.915039522281, 7696.358900125801,
          7680.6644664599435, 7663.976259203311, 7648.228214466612,
          7627.891814180145, 7617.476926770637, 7605.795205448446,
          7598.722033063328, 7589.257042309599, 7574.159477115349,
          7559.648794400193, 7551.0446598576145, 7549.685161642612,
          7548.800306956619, 7548.599706818864, 7551.461098763045,
          7555.880086906351, 7559.50582775969, 7583.645712358508,
          7604.167579440517, 7628.381290738725, 7654.988591912215,
          7679.526639597122, 7703.139181387278, 7729.251870864758,
          7757.245299164465, 7801.386976408733, 7840.963669945194,
          7874.596072520537, 7891.391595836962, 7904.661465226329,
          7922.604604528778, 7950.065305429198, 7986.538863737531,
          8012.873143663521, 8039.970111115728, 8072.446742578783,
          8105.34740114158, 8138.420323891251, 8165.608634309247,
          8183.4906415506475, 8203.025480331822, 8222.354678741092,
          8232.215774211565, 8245.446154920248, 8257.242864723807,
          8275.991916907493, 8301.732473008335, 8324.016384433911,
          8351.218436227298, 8372.942229569331, 8409.13683888479,
          8431.095317322715, 8455.55362787078, 8481.959694645617,
          8504.518116533822, 8527.523698384637, 8551.671545941377,
          8576.034683645508, 8599.725680008964, 8625.176656642449,
          8638.151376312895, 8654.527190643334, 8670.7413056801,
          8684.035537250791, 8698.72829888939, 8715.101797921283,
          8729.954039546605, 8742.951385298156, 8754.184625193242,
          8766.160177169613, 8776.626114255363, 8794.627379319614,
          8811.068223293483, 8820.680535703514, 8829.171812224236,
          8835.766627823756, 8839.25639756982, 8844.74339959814,
          8851.63085703184, 8857.312424219328, 8864.73198017538,
          8869.12421819171, 8872.869481197815, 8878.069293451323,
          8881.858218333475, 8891.013501138752, 8898.268481314222,
          8908.917818317901, 8915.324000133389, 8922.710455576293,
          8928.875199030228, 8936.27866043557, 8942.34482557546,
          8948.55284883139, 8953.484475587204, 8957.053495872606,
          8960.95976328107, 8965.104718463623, 8969.934129979194,
          8973.71325611822, 8981.956557977266, 8992.57444791831,
          9004.66742914765, 9015.224509758587, 9028.79273728812,
          9046.668920708158, 9085.942011387204, 9121.988110171616,
          9161.16715749495, 9199.55216427723, 9241.847368945007,
          9292.527025005502, 9327.751242332126, 9365.217752384955,
          9401.391064218917, 9447.797379778938, 9493.632283149653,
          9535.228277542728, 9579.311083928022, 9620.942151573012,
          9665.931811937902, 9700.112964176755, 9737.028153004938,
          9777.483041064246, 9816.751495696637, 9857.06136706898,
          9897.733419206228, 9944.929391103135, 9984.534947714954,
          10019.585542809707, 10055.901670674863, 10085.118271255558,
          10116.362661923766, 10146.694688420324, 10178.407664887249,
          10200.98216657265, 10225.941529610816, 10247.811598331395,
          10273.140279552556, 10296.75809579904, 10324.766054298068,
          10350.998607678304, 10382.106952080714, 10402.036319366245,
          10396.86431304216, 10397.842247437366, 10393.264975210883,
          10390.550817285915, 10390.211989220848, 10384.992939929347,
          10381.709911415894, 10380.821992377954, 10380.961952924925,
          10382.149240985818, 10381.172622352437, 10386.92702587021,
          10394.815797635158, 10405.888950157234, 10416.465208569962,
          10426.701343053724, 10439.650821409095, 10449.168230886144,
          10448.535592650775, 10450.14102645967, 10446.008728906014,
          10451.75746694748, 10456.409200275253, 10461.799117101486,
          10467.986263297496, 10472.50376303418, 10479.790421191918,
          10485.667640574256, 10488.310459572785, 10489.93599502679,
          10490.968153541111, 10494.446605946237, 10500.342910778985,
          10502.309585813065, 10505.558702925679, 10513.875758313288,
          10524.504753198175, 10539.72683729326, 10556.148484079531,
          10575.398019048253, 10592.133899859178, 10608.48550580256,
          10626.240644301519, 10639.964987978716, 10654.227067424681,
          10671.03722450538, 10692.431932931016, 10716.541993665056,
          10757.421558146936, 10801.205685708383, 10843.22280084287,
          10888.143735479645, 10930.53356249985, 10972.546561262228,
          11025.291579851095, 11069.669964408498, 11116.918083925162,
          11165.297923847436, 11217.29202436531, 11267.672380318472,
          11312.854511401274, 11366.531253749763, 11421.53083288343,
          11504.075172826333, 11584.784971384228, 11648.814971950876,
          11724.582992308286, 11795.945903351685, 11865.277865661552,
          11940.90087822271, 12027.058484594538, 12112.081088859992,
          12190.464631654844, 12265.049490433956, 12353.139005474868,
          12458.213084574376, 12563.518172008547, 12667.266723057883,
          12785.848966165648, 12903.036115350485, 13012.172627917802,
          13118.228615483786, 13237.867850820741, 13346.40729931934,
          13421.715471610047, 13495.335363261333, 13578.989118444277,
          13670.197056692905, 13789.517312996015, 13888.04093056045,
          13993.309228965194, 14100.806075916378, 14190.899420947737,
          14289.053095780455, 14389.456004774902, 14484.058856165499,
          14560.099472875092, 14638.934730837962, 14710.532656959982,
          14776.393990485527, 14856.241436218486, 14941.761209758715,
          15027.531086793197, 15114.637995965608, 15237.823778223714,
          15387.513604397502, 15540.382443914383, 15704.367147995286,
          15857.855323282509, 15993.733633712558, 16148.526829084587,
          16288.808674053209, 16436.143155755126, 16600.030816037204,
          16795.93891868993, 16983.774979705973, 17183.688544464272,
          17385.69471190062, 17613.216400773876, 17837.184986897166,
          18064.798947552667, 18344.274809977367, 18634.488180076823,
          18898.928216114906, 19196.95993460768, 19544.92825273426,
          19938.73719822467, 20347.530719051905, 20738.435457288502,
          21083.219309619424, 21366.80843220122, 21617.985294929906,
          21929.933704931293, 22270.816205823743, 22557.37865719357,
          22823.46977289271, 23080.997302142358, 23349.319533783102,
          23597.67934499532, 23832.739754005313, 23971.697976698277,
          24149.39049201118, 24306.392660486206, 24463.887855328063,
          24618.15146215325, 24773.589453001703, 24884.33322620959,
          25052.264449452963, 25234.441391047952, 25413.222353601457,
          25565.296762441034, 25722.941777046162, 25915.878375520497,
          26147.62078392603, 26361.265124838388, 26597.482449099014,
          26846.778638225765, 27083.390744399512, 27465.400234609424,
          27840.747160656763, 28176.72404856455, 28568.660404038517,
          28939.332475245676, 29300.012822270513, 29681.75098420575,
          30042.728192439303, 30420.762287638525, 30850.444420556574,
          31260.388095397037, 31748.420014300067, 32225.504568472345,
          32724.17913147289, 33147.21855461204, 33458.97660303556,
          33780.10973960911, 34043.34974476537, 34285.597670611605,
          34519.67355832227, 34729.89111162282, 35024.091881689696,
          35289.766299874056, 35587.97627413397, 35841.1666449432,
          36096.82096880571, 36349.996791205594, 36639.536458706476,
          36951.133162494465, 37306.24933749458, 37673.48380605904,
          38071.49719603807, 38450.70952878979, 38900.96062722959,
          39298.333882135936, 39621.24212209364, 39963.410594923465,
          40338.64939502399, 40681.41198126114, 41024.94857569161,
          41363.11355439079, 41679.71645430384, 41925.33097996119,
          42171.18363382334, 42371.826532163475, 42548.498878061226,
          42795.56998938675, 43053.42286088404, 43305.38735868832,
          43589.15137138756, 43889.29827492444, 44183.382467500196,
          44471.24499289623, 44757.95321085868, 45001.398493811976,
          45262.796741459264, 45537.394627767004, 45784.003645038945,
          45985.37882038471, 46224.82716057511, 46460.82147422709,
          46724.35233612358, 46987.284567091425, 47242.18982318862,
          47565.61180688786, 47870.4418701178, 48173.205001402595,
          48433.830644939175, 48662.99558266315, 48811.25329389754,
          48946.34015936492, 49094.4324334369, 49187.367038715376,
          49236.94313695864, 49274.333173850544, 49289.65014070499,
          49285.23667257222, 49378.625847372765, 49490.17721673172,
          49596.237469865744, 49674.62880709612, 49833.43358319323,
          49991.19470035772, 50121.63064688529, 50261.19023803608,
          50319.384688768034, 50460.309942455795, 50577.80241884281,
          50711.20732143998, 50872.610146758, 51018.515688406354,
          51113.51815002207, 51223.547889625595, 51192.018624484495,
          51161.898849742225, 51135.75134776713, 51049.16201414798,
          50957.723162382674, 50810.79874332559, 50653.15480781419,
          50376.65847498619, 50181.60860419438, 49925.576948665774,
          49678.53047443477, 49381.03977197071, 49171.440766585154,
          48956.654216751784, 48764.28304414284, 48561.62357792219,
          48306.21499222076, 48034.90677455303, 47789.48822456188,
          47580.8609329864, 47365.261508570824, 47171.26979552982,
          47014.349403539134, 46812.65931634034, 46588.907646709835,
          46375.19284182449, 46121.28387465965, 45868.99726328025,
          45701.057713512324, 45522.33815482891, 45360.1528448323,
          45165.81536275642, 45044.12733577114, 44954.467190508345,
          44859.21120653788, 44730.2963311609, 44598.86412658345,
          44425.022064670906, 44247.96657824178, 44076.72288362313,
          43829.83589582861, 43605.67201670329, 43409.08583815471,
          43235.89780175561, 43005.176063106985, 42792.868616312786,
          42632.62052490065, 42471.47734618975, 42341.581359136486,
          42197.09459954962, 42024.96777579616, 41861.83079013683,
          41719.40107152026, 41592.017089905996, 41435.544276244495,
          41292.66240938817, 41145.52295573692, 40981.764283346085,
          40839.86419852735, 40694.577184695125, 40567.139814701164,
          40419.01090747936, 40266.74871129165, 40119.288736810624,
          39956.134504398535, 39786.38669243025, 39622.697252976184,
          39467.3678024222, 39296.52190534453, 39108.28087751593,
          38970.38442449581, 38838.05661410975, 38735.00777026599,
          38646.35692333003, 38581.69678623439, 38555.081008289155,
          38572.958018026, 38601.60360182747, 38629.62095624673,
          38700.447669984416, 38755.12850820255, 38776.71883477279,
          38784.06737269808, 38772.64128611, 38791.46383489991,
          38832.47365995139, 38911.76863698206, 39023.863119418056,
          39118.32860220186, 39259.61714473252, 39384.872052757615,
          39506.18151705944, 39603.069011771135, 39765.38447688458,
          39910.000823876966, 40049.87843132495, 40165.74796733831,
          40255.454938282106, 40343.5704840587, 40470.64077150308,
          40645.92451860203, 40807.82442912476, 40974.7827572609,
          41143.37814820623, 41272.69759081601, 41425.1851632751,
          41532.48585311123, 41681.7415787922, 41824.585309905226,
          41962.07530376849, 42061.497773000796, 42161.28771808989,
          42292.95568406831, 42430.652403195665, 42580.52344471654,
          42725.77327749443, 42904.60707397969, 43097.86039924742,
          43172.43207450985, 43229.38015224233, 43292.06846605932,
          43322.93661524626, 43359.58519712257, 43412.37044074391,
          43442.63498647175, 43515.28617485845, 43606.49654763353,
          43688.304932828905, 43759.82325099071, 43849.58397869387,
          43916.75558307616, 43898.911314104356, 43836.24732768645,
          43830.452529118396, 43850.943766165554, 43830.342305449405,
          43807.377903361295, 43794.57636072048, 43761.958016943834,
          43707.79132353901, 43664.553475548135, 43667.712812665995,
          43756.30483617756, 43833.10870080771, 43919.58199386102,
          44024.63858804199, 44172.11089322928, 44392.76216267028,
          44578.75142677582, 44764.34902228521, 44966.0395366954,
          45157.97934784995, 45401.8084696747, 45611.61285641382,
          45844.39279985117, 46072.18581371551, 46381.101342156784,
          46668.12052350021, 46962.382097292284, 47260.35156071224,
          47597.38598525259, 47961.8221043565, 48243.63057753756,
          48490.05809085364, 48743.45793063872, 48983.235397358745,
          49262.35509246055, 49481.49281340193, 49658.3630547207,
          49874.551707102466, 50119.683554486575, 50352.149028655156,
          50568.93419640456, 50773.7297568718, 51020.190355745624,
          51255.364408107096, 51456.1494693327, 51643.89146003898,
          51838.479945978805, 52064.91776883071, 52371.074446675644,
          52659.71970515732, 52901.753572381924, 53136.85270956248,
          53354.3849331355, 53572.71394436054, 53809.276044472215,
          54003.28523171039, 54123.197801379494, 54246.39923105515,
          54298.77984034119, 54373.105784096806, 54478.73755074835,
          54560.78274776324, 54594.17675275802, 54652.53503488163,
          54701.75790547803, 54786.086659825, 54765.105339828464,
          54764.23256082196, 54813.948351696774, 54872.607790277034,
          54913.7553587864, 54958.712084354986, 54988.84333020934,
          54961.36227416559, 54846.33470438013, 54738.41381914488,
          54653.33473361726, 54572.85186760504, 54491.63084052375,
          54354.08547734506, 54211.24299264516, 54115.772636355156,
          54035.339514843174, 53890.13972247004, 53780.300916084496,
          53682.96782863728, 53563.15420826823, 53415.986798203514,
          53285.659930912356, 53154.87914020122, 53031.300543365556,
          52949.292215774156, 52862.93316199645, 52822.85151522425,
          52783.1910891802, 52735.99245375089, 52697.17062298355,
          52657.65120470664, 52556.38563629661, 52435.757405874894,
          52330.51211070905, 52209.45107881382, 52120.6035326987,
          52024.87237363536, 51914.40163356337, 51793.95823487895,
          51628.75371537639, 51459.517206161014, 51263.61547930634,
          51073.83576684483, 50891.47505859048, 50711.89119604413,
          50553.81988523138, 50422.113748890166, 50266.430506337885,
          50123.72812007377, 49984.333305814886, 49847.44868589776,
          49696.04613766216, 49550.62185770845, 49394.37211795185,
          49221.82831363597, 48968.82795098971, 48693.63175394041,
          48447.115283565356, 48213.71399082149, 47990.83490189433,
          47769.41916126276, 47559.333633316965, 47364.42722473643,
          47182.29678464264, 46998.12773940219, 46829.19253664175,
          46668.11129829241, 46474.61127258365, 46293.165702829516,
          46199.72182752596, 46104.333078466036, 46030.6011363182,
          45987.22269797526, 45948.72304058961, 45917.51505958784,
          45869.55317721976, 45800.328955888675, 45729.38917458395,
          45656.602458255555, 45594.80716205247, 45574.01612914054,
          45540.3437305437, 45440.84583488937, 45332.60017479255,
          45228.57185449963, 45093.09300589567, 44932.99532261061,
          44800.26927661832, 44650.759191932804, 44525.540396052944,
          44420.463754546945, 44315.43912574404, 44184.42191533327,
          44164.13633285142, 44169.22670249792, 44163.75647076529,
          44129.89990698776, 44031.261492988, 43939.512948572396,
          43830.23170206601, 43714.54394558946, 43615.85258033026,
          43582.7012421059, 43500.31111849984, 43405.84099734143,
          43314.7819676911, 43205.1296514992, 43135.154212855654,
          43058.82106012584, 43020.309752004534, 42978.67787572722,
          42954.49633363361, 42939.57818841314, 42906.36099656338,
          42868.6558283146, 42858.665217852926, 42859.14234225188,
          42881.564474088475, 42909.91290034415, 42941.622941921494,
          43018.576150992354, 43099.836227210326, 43185.67709399824,
          43262.5545772854, 43307.06517971539, 43366.00428506756,
          43414.4200417989, 43473.68598156526, 43535.20724925704,
          43574.065521548975, 43566.073531023256, 43563.65999575547,
          43537.68672851279, 43522.16758537392, 43495.17119754473,
          43416.661866900286, 43350.48915666463, 43306.871351582166,
          43240.24776046172, 43187.01315134367, 43131.40318795073,
          43063.02134264477, 43018.23141506765, 42988.032377145515,
          42955.758270667386, 42906.73355243635, 42843.4156603089,
          42776.05297396615, 42710.193113095534, 42664.96315045998,
          42574.81833559939, 42508.69757647861, 42453.91287199389,
          42377.520735914804, 42283.52626589669, 42207.97822102745,
          42135.05112754176, 42047.8016002637, 42001.11245966442,
          41893.22845056216, 41776.80353074904, 41651.96365885302,
          41501.200022044046, 41274.96457606298, 41071.83854485381,
          40834.85045485671, 40601.086089414, 40377.04577081174,
          40173.277339706554, 39998.14095674207, 39797.663116014504,
          39612.46048005382, 39396.675520052755, 39216.92412361607,
          39018.589190475155, 38829.014355020205, 38660.002981653466,
          38470.877180036565, 38296.29703785762, 38122.94759156341,
          37946.28229272057, 37761.79749484492, 37588.916950392544,
          37428.10869394913, 37315.35842278182, 37206.16399856832,
          37059.62273126994, 36928.79099401707, 36785.65077631376,
          36648.5891767828, 36515.33414357918, 36413.50772489444,
          36308.79133430248, 36187.91962471233,
        ],
      },
    ],
  },
  portfolio_value_data: [
    5000.0, 5000.0, 5000.0, 5000.0, 5000.0, 5000.0, 5000.0, 5000.0,
    4848.081980541163, 4843.222297262406, 4843.222297262406, 4843.222297262406,
    4794.2679665319865, 5207.863196744017, 5214.602067389345, 5154.661586386159,
    5268.90908898464, 5270.493314715295, 5143.838014323354, 5108.754507937367,
    5164.131473029858, 5132.4055793864545, 4968.166296921707, 4988.548424978806,
    4930.369508407468, 5092.587129678617, 5265.533742371935, 5541.37818078739,
    5498.414924778401, 5623.545112340083, 5528.775310422618, 5547.525922428744,
    5516.136972317607, 5492.9233363314615, 5436.636032572847, 5681.966391803052,
    5776.523387279086, 5801.191200615011, 5849.261811218356, 6001.004626539049,
    5823.713215666505, 6043.169858392671, 6104.280774823731, 6047.041753368714,
    6114.861983994905, 5854.978028686806, 5862.396697686708, 5737.508873016655,
    6008.665658430579, 5671.178287480486, 5672.732956760943, 5720.395688351544,
    5704.914019737408, 5873.704995532772, 5708.029269588362, 5506.8739808253,
    5193.226842605566, 5188.476529929613, 5188.476529929613, 5188.476529929613,
    5188.476529929613, 5188.476529929613, 5188.476529929613, 5188.476529929613,
    5188.476529929613, 5188.476529929613, 5188.476529929613, 4686.386291933584,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4682.545624253881, 4682.545624253881, 4682.545624253881,
    4682.545624253881, 4727.436777328673, 4705.765534250803, 5337.898182716646,
    5241.478176688088, 5367.322185313114, 5455.5455483369105,
    5408.1837884229035, 5394.6848659669895, 5485.814753735799,
    5559.444687077529, 6072.33077325378, 5958.994549985426, 5800.528145425278,
    5303.96845870584, 5205.918853029451, 5357.489862064822, 5660.656202518366,
    5954.111831637635, 5664.943022487473, 5704.375685607559, 5886.045643362193,
    5918.813973594134, 5944.109251709718, 5783.52071924997, 5514.284102807232,
    5575.910940235462, 5581.47468530175, 5302.490873950531, 5411.947677161736,
    5375.968792399739, 5596.627529788296, 5822.7162391377215,
    5732.2248139175035, 5896.796136561315, 5745.11567680333, 6202.680503293144,
    5787.539993008239, 5877.654421295004, 5952.336297692982, 5850.243095875522,
    5877.690904869209, 5926.755231579459, 5948.0494777238755, 5942.22426704245,
    6010.673532847046, 5643.04071677297, 5755.367561154982, 5760.35973022539,
    5680.557992247285, 5731.586351368913, 5792.130842762389, 5755.373641750683,
    5707.446386436493, 5661.179133748639, 5690.803796003236, 5651.729888029501,
    5889.481179933179, 5852.511158071943, 5652.824395255656, 5624.245595461608,
    5571.174156184491, 5479.837528161852, 5543.288544300341, 5589.628764136604,
    5556.787466756252, 5613.610633580802, 5525.162288516073, 5507.966363874037,
    5554.908562684685, 5514.746228080497, 5681.834917344466, 5629.049266065288,
    5737.691269452577, 5613.872099195939, 5647.868709759454, 5614.8449945080765,
    5656.6308481643955, 5620.068226215117, 5628.112854327356, 5592.693384369839,
    5553.850539032736, 5566.37656617651, 5576.0811969150855, 5599.61918287312,
    5570.304630999269, 5709.6840456544105, 5787.612960156649, 5839.364910166681,
    5799.555250113141, 5898.437897400548, 6038.9726252388555,
    6706.8774182128645, 6631.666529988911, 6749.788182073845, 6749.228767269365,
    6892.639616874178, 7175.819039258991, 6732.04500381873, 6822.3175275937165,
    6805.389149162519, 7141.609607845945, 7152.281053300955, 7049.987191825367,
    7151.642590752366, 7103.1620012294, 7231.608504814395, 6927.067949732455,
    7031.806210679792, 7162.946418160268, 7151.113578926391, 7206.969931034503,
    7242.602221841551, 7467.663310517531, 7263.28232782018, 7147.495624484379,
    7207.669199540102, 7011.74632546265, 7091.779126077387, 7082.761602653009,
    7143.6101238315305, 6882.2782817999105, 6969.236880917929, 6889.55067425814,
    7009.052621567168, 6971.918423622008, 7121.0875973555385, 7083.60072485973,
    7249.272635325396, 6924.915418854351, 6166.245573853584, 6158.524257095354,
    6158.524257095354, 6158.524257095354, 6158.524257095354, 6158.524257095354,
    6158.524257095354, 6158.524257095354, 6158.524257095354, 6158.524257095354,
    6158.524257095354, 6158.524257095354, 6217.828662386109, 6315.085640294162,
    6307.002991626284, 6303.198035762661, 6388.094672198582, 6295.640009797193,
    6005.621661804159, 5999.616046300879, 5829.603005183683, 5823.7734081781155,
    5791.044986701767, 5813.766610888258, 5838.504081532506, 5796.165886977521,
    5874.393734999504, 5839.7721139817395, 5754.4454518208795,
    5728.038947012684, 5712.681665127514, 5780.185426751915, 5848.236501527482,
    5743.894771570202, 5780.055372141737, 5920.503513249647, 5988.278221978586,
    6119.73091921586, 6160.806500263701, 6247.092315174622, 6188.735727799397,
    6187.288870261168, 6234.558302118731, 6133.874358072702, 6156.026993339666,
    6233.4853515847635, 6368.053940524215, 6453.955010546693,
    6925.9286098242455, 7027.566287678245, 7002.937195875813, 7105.170957359794,
    7060.247927427524, 7072.906576151503, 7389.361956366786, 7188.985734677335,
    7291.566308455124, 7348.140063882494, 7473.263436757779, 7457.277557590082,
    7342.325539019126, 7599.270097193856, 7664.557511503142, 8448.141956766854,
    8442.668825255201, 8029.951357739937, 8385.867474260021, 8306.377012730893,
    8289.469913407771, 8499.199229145846, 8828.46498846365, 8844.093217453355,
    8708.48419163248, 8647.006293612987, 9056.987195372425, 9569.516157257107,
    9632.776887224456, 9647.245462606743, 10109.399938815866,
    10135.481307098618, 9978.673379818752, 9953.513231691426,
    10382.698864220461, 10143.777707439549, 9293.198881107968,
    9287.807033727677, 9602.284500079837, 9854.341172488717, 10673.078295095083,
    10168.62355692561, 10406.585560956464, 10524.615538634966,
    10106.609183639133, 10376.006470738394, 10490.747150567813,
    10386.432515320987, 9929.72949485515, 10047.412660239843, 9892.07868520866,
    9773.891558209527, 10192.320590688434, 10390.816439472399,
    10444.003356093051, 10527.070319402072, 11561.595135003967,
    12353.649386755511, 12521.76122723667, 12908.792909241805,
    12710.41085824175, 12311.679680262696, 12902.929613899621,
    12589.698504228329, 12858.716465381238, 13391.54478433785,
    14356.615019162675, 14241.879758275347, 14674.17586356447,
    14839.773314915525, 15647.48830930932, 15673.548001823709,
    15894.673352778616, 17437.25108409824, 17882.536393578877,
    17334.497104173548, 18397.05410721086, 19925.1036547852, 21368.12458201367,
    21991.590126380066, 21723.57466951395, 20673.275313485894,
    19185.477667761606, 18452.17016721582, 20251.334806532184,
    21212.178266526233, 19910.39664595092, 19505.471617162148,
    19415.316677598435, 19850.27348345411, 19449.390985465034,
    19220.028842532196, 16717.507195605587, 17852.797239384872,
    17382.882838101803, 17481.45339473244, 17478.364597740718,
    17594.09694397274, 16455.24123629775, 18080.22481999132, 18561.069227471417,
    18566.856657624332, 17932.894622428164, 18167.74615369893,
    19218.950473056135, 20385.44820433602, 20015.762555963352,
    20749.25971948231, 21235.17625675935, 21023.160147458984, 25130.27389899169,
    25154.957180215028, 24280.96852405143, 25994.40008055013, 25624.87700044019,
    25552.31194690303, 26324.02349004592, 25962.74803979809, 26625.132426260214,
    28243.36942705081, 27936.055802142615, 30295.13805192722,
    30260.017888237107, 31109.37203365618, 29309.79555474092, 26493.75017527012,
    26919.24550823536, 25508.98208594544, 25077.168266502395, 24984.80781750775,
    24458.75317616457, 26870.924801612193, 26249.708118213457,
    27284.032432957993, 26213.63963965746, 26418.269730888143,
    26488.981506230266, 27621.296550801733, 28381.801721700962,
    29741.593117357694, 30265.65358801148, 31306.941243342626,
    31008.119100690623, 33157.634602999875, 31954.586107317922,
    30132.13627383763, 30834.187316404004, 31924.597681787745,
    31239.20446720832, 31446.126770943392, 31485.289465433198,
    31078.478644796844, 29307.40038233681, 29447.014006362744,
    28343.045447867964, 27795.80274294997, 29818.048971316002,
    30246.985332508866, 30225.575092308332, 31232.32782969517,
    31834.426485397762, 31831.164282259135, 31820.266789714646,
    31944.669443291863, 30916.116301873968, 31539.332574904194,
    32042.199563041075, 31425.06876197876, 30320.850932147798,
    31471.872164816516, 31507.106126290535, 32388.545827828904,
    32514.969746805968, 32437.7877546075, 34450.924741318995, 34117.40511245996,
    34226.02780666893, 33236.97333415047, 32517.261959310355, 30427.36565788347,
    30147.276798039173, 30576.381146436845, 29147.205616250227,
    28011.03228491015, 27704.41770353166, 27120.635072095778, 26588.99892039913,
    26562.404508355, 26562.404508355, 26562.404508355, 26562.404508355,
    26562.404508355, 26562.404508355, 25976.16937013522, 26247.57696034532,
    24425.25573765822, 26370.026692171225, 25891.452629681437,
    26314.310962363215, 27024.66815362829, 26739.473950315565,
    25626.30709084396, 26018.3188522848, 22786.889160461975, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 22720.91397307833,
    22720.91397307833, 22720.91397307833, 22720.91397307833, 23022.054253366787,
    23020.282479203055, 24280.019662132698, 23851.72202061717,
    22921.891487980534, 22520.584639894383, 21979.405425602927,
    21957.421021678754, 22564.610782819604, 23654.896069339302,
    24613.347182964953, 24183.63536960753, 25541.531768081262,
    25172.430320883046, 25131.57244012394, 24517.516981222343,
    26395.576887449257, 25991.642150370186, 25939.353560978645,
    25347.093757619175, 24681.495190323807, 24686.652814412064,
    25821.62278414607, 27236.297224242342, 26959.861826107328,
    27190.325896046696, 27328.17361565605, 26326.004183072448,
    27043.49374989388, 25867.594112341718, 27096.826012682293,
    27000.43808071172, 26930.020497870777, 25944.37313303028,
    26009.522650153667, 26953.594263644613, 27194.423280493684,
    27609.9599107306, 27563.85053049833, 28580.598258149384, 29081.46209118259,
    25878.560966131095, 25428.279399316758, 25619.801750765357,
    24767.06536470456, 24945.307110229973, 25415.539957377136,
    24816.66470042267, 26015.3926227509, 26573.06210781397, 26361.240584900243,
    26119.461770039212, 26667.67192955283, 26087.306689668847,
    23753.603275525144, 23730.412388791756, 23730.412388791756,
    23730.412388791756, 23730.412388791756, 23730.412388791756,
    23730.412388791756, 23730.412388791756, 23730.412388791756,
    23730.412388791756, 23730.412388791756, 26044.480898765727,
    25770.375718641935, 26076.07242551521, 26630.509129476606,
    27846.052625750945, 29924.931961598944, 29097.396246002692,
    29187.307678554604, 29727.333453190553, 29570.06823646779, 31091.4112202675,
    30293.822540987192, 31034.96300423482, 31024.652481977206,
    33363.98339974425, 32932.926754361986, 33286.03779889951, 33546.51101885058,
    34774.87504909688, 35705.75400562158, 33645.67271009112, 32831.57962188742,
    33155.35490451356, 32920.2955531702, 34124.7549675422, 32637.15649467798,
    31601.03555991508, 32770.814618466706, 33678.432690569694,
    33465.01678075454, 33162.41431246015, 32952.15214581193, 34201.14263008148,
    34026.13963661017, 33213.91823817621, 32966.22227613306, 33254.814118694376,
    34230.13759298854, 36530.39022492713, 36217.628700599256, 35100.4408788727,
    35041.9262307821, 34689.21008032894, 34828.65096817507, 35444.8480250301,
    34410.35535217672, 32491.132235589714, 32645.84416453409,
    30777.805812690844, 31405.64413077274, 32301.063762324342,
    31713.86707788437, 30429.195478813523, 31129.234500238792,
    30911.242146651366, 31896.892371727172, 29065.64784352107,
    29035.06588243155, 29035.06588243155, 29259.96633417987, 28841.807630313244,
    28960.065377094597, 28603.671543244047, 27145.496956587882,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    27118.35148359694, 27118.35148359694, 27118.35148359694, 27118.35148359694,
    26769.113816666446, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 26742.350822586493, 26742.350822586493,
    26742.350822586493, 27406.34623907312, 27606.762705862257,
    27730.183614272562, 29173.337923108007, 29356.777862946554,
    29551.51887430377, 29322.999246753494, 28352.596046585335,
    28834.262168120993, 28539.904765593175, 28911.42593428979,
    29019.303196923323, 28344.627981275324, 26894.876189122195,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542, 26867.98133967542,
    26867.98133967542, 26867.98133967542, 26867.98133967542,
  ],
  drawdown_data: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 3.0383603891767414,
    3.135554054751883, 3.135554054751883, 3.135554054751883, 4.114640669360269,
    0.0, 0.0, 1.1494737321959314, 0.0, 0.0, 2.4031014333765923,
    3.0687603061055264, 2.0180623583844253, 2.620015377750269,
    5.736218599300503, 5.349497151420245, 6.453358082405616, 3.3755129627042924,
    0.09410072354163089, 0.0, 0.7753171613146367, 0.0, 1.6852323583126578,
    1.3518019041854101, 1.9099720528031998, 2.3227656824871885,
    3.3236877456017804, 0.0, 0.0, 0.0, 0.0, 0.0, 2.9543621761010623, 0.0, 0.0,
    0.9376865771163636, 0.0, 4.250037956511879, 4.128716019576604,
    6.1710814073308145, 1.7366921091969878, 7.255825195658072,
    7.2304007578780105, 6.450943564643664, 6.70412456291734, 3.9437846527581417,
    6.653179016491149, 9.942791918459616, 15.072051402004414,
    15.149736109989425, 15.149736109989425, 15.149736109989425,
    15.149736109989425, 15.149736109989425, 15.149736109989425,
    15.149736109989425, 15.149736109989425, 15.149736109989425,
    15.149736109989425, 23.360718456119304, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    23.423527194726255, 23.423527194726255, 23.423527194726255,
    22.6893952847618, 23.04379810095933, 12.706154338591631, 14.282968439726359,
    12.224966003131534, 10.78219651373482, 11.556731736900481,
    11.777487699851832, 10.287186070684506, 9.083071676370297,
    0.6955383596955594, 2.5489934918800827, 5.140489505607277,
    13.261027434658462, 14.86449135474406, 12.385759873443355,
    7.427899152350155, 2.628843509109755, 7.357794218169665, 6.712928263332138,
    3.74197064842376, 3.2060905203405885, 2.7924216888642324, 5.418622131001331,
    9.821609756027716, 8.813789177418867, 8.722801922418663, 13.285191263035566,
    11.495178610293802, 12.083562859295137, 8.474998382024662,
    4.777634321458907, 6.257494790216355, 3.5661613950463322, 6.046682789560136,
    0.0, 6.69292106960043, 5.240090664440586, 4.036064818544963,
    5.682017753945333, 5.239502473993158, 4.44848435393682, 4.105177196118346,
    4.199091604225171, 3.0955482930993714, 9.022547368400627, 7.211607012495229,
    7.131122952938103, 8.417691524956867, 7.595009152480397, 6.618907104965107,
    7.2115089807539725, 7.984195165198655, 8.730118684285118, 8.252508040969403,
    8.882460010170295, 5.0494189277310095, 5.645451914463244, 8.864814296747307,
    9.325563480569924, 10.181184518102652, 11.653719303251533,
    10.630758083424054, 9.883658183442737, 10.413127617873807,
    9.497020995996682, 10.922990704057082, 11.200224468280576,
    10.443419425916618, 11.09091907679926, 8.397104859296714, 9.248118404991233,
    7.49658528427659, 9.492805631123408, 8.944710165856975, 9.477120552525195,
    8.803446426731774, 9.392911286801011, 9.263215293142006, 9.834250185858345,
    10.460476948892165, 10.258531561940131, 10.102072902922906,
    9.72259203258729, 10.195203057099794, 7.9481194844227545, 6.691744688705575,
    5.857396539021655, 6.499210348912463, 4.905018172886179, 2.6393085693737177,
    0.0, 1.1213994760022692, 0.0, 0.008287886810520714, 0.0, 0.0,
    6.184298029428672, 4.9262879920921065, 5.162196650582267,
    0.47673208069888784, 0.3280181095601713, 1.753553799854723,
    0.3369155266368265, 1.0125260633257902, 0.0, 4.211242282808779,
    2.7629025271706347, 0.9494718444508677, 1.1130985013142687,
    0.3407066873640756, 0.0, 0.0, 2.736879987739913, 4.287387804190696,
    3.481599265612978, 6.105216131165991, 5.033491318639716, 5.154245603473079,
    4.339418814311037, 7.838931729730746, 6.674463066614333, 7.741546615327058,
    6.141287707822215, 6.638554341320002, 4.641019536511126, 5.143008859503391,
    2.924484756624624, 7.267974854982661, 17.427375640128517, 17.5307723311303,
    17.5307723311303, 17.5307723311303, 17.5307723311303, 17.5307723311303,
    17.5307723311303, 17.5307723311303, 17.5307723311303, 17.5307723311303,
    17.5307723311303, 17.5307723311303, 16.73662290546954, 15.434247934023313,
    15.54248324581749, 15.593435675050124, 14.4565788979596, 15.694645727662209,
    19.578301644293706, 19.658723260180185, 21.93537974625593, 22.0134442861684,
    22.451712859823203, 22.14744600630171, 21.81618481232946,
    22.383138527226528, 21.335583960702273, 21.799204501400766,
    22.94181978295323, 23.29543112977178, 23.50108156213046, 22.597133984186936,
    21.685857297680148, 23.08310467773174, 22.598875554538587,
    20.718124705607025, 19.81054885609752, 18.050256623155857,
    17.500210653756167, 16.344751290860216, 17.126208420736905,
    17.145583390899137, 16.512595133501563, 17.86086084741269,
    17.564213364179707, 16.52696308890277, 14.724945732952564,
    13.574638515680274, 7.254407144070094, 5.89336991424678, 6.223179799592637,
    4.85416037232422, 5.455727797960561, 5.286214950398851, 1.0485388922189887,
    3.7317908461098295, 2.3581272312369728, 1.600544128264306, 0.0,
    0.21390760947980683, 1.7520845992746061, 0.0, 0.0, 0.0, 0.06478503249190025,
    4.950089631152002, 0.7371382112838663, 1.6780606287328064,
    1.878188649895842, 0.0, 0.0, 0.0, 1.5333287708146017, 2.2284582375435353,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.5471187063414724, 1.7953570224607571, 0.0,
    2.3011469359306207, 10.493417919178894, 10.545348996549077,
    7.516488480947756, 5.0888280459765936, 0.0, 4.726422164459347,
    2.496868539426798, 1.3910022240570026, 5.307457659298502, 2.783375293828886,
    1.7083276210112903, 2.6856898436304704, 6.964708584416051,
    5.862091681111075, 7.3174728817021695, 8.424811586913838, 4.504395930718377,
    2.644615244248705, 2.146287440871726, 1.3680024792857666, 0.0, 0.0, 0.0,
    0.0, 1.5367978431045028, 4.625631793594097, 0.04542094201531525,
    2.4719151299191355, 0.3879250694673099, 0.0, 0.0, 0.7991804525940385, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 3.064661954789115, 0.0, 0.0, 0.0, 0.0,
    1.2187179522985827, 5.994631608347339, 12.759934331680638,
    16.094424908904276, 7.913276438161487, 3.5441359873240272,
    9.463587982810957, 11.30486015304409, 11.714812043951545, 9.736979593655363,
    11.559869596994403, 12.602823478977252, 23.982271861496454,
    18.81988916313284, 20.956680539211565, 20.508461215078206,
    20.522506570479855, 19.996249280456993, 25.174845739968468,
    17.78573210900679, 15.599239887585755, 15.572923326938449, 18.4556709206911,
    17.387755731652327, 12.607727032880648, 7.303436963011583,
    8.984468876793974, 5.649115865466752, 3.439559692017711, 4.403637814981824,
    0.0, 0.0, 3.4744191767140453, 0.0, 1.421548791143027, 1.7007052760486066,
    0.0, 1.3724172916972306, 0.0, 0.0, 1.0880912268698968, 0.0,
    0.11592673263252912, 0.0, 5.784676325090584, 14.836756760607614,
    13.469016735174412, 18.002259710198793, 19.39031029179164, 19.6871997593602,
    21.378184202164316, 13.624341974690303, 15.621221509020545,
    12.296421787491177, 15.737162385348668, 15.079386037406637,
    14.852085482237532, 11.212297950215177, 8.767680392276484,
    4.396678000503291, 2.712103750380782, 0.0, 0.9544916583492397, 0.0,
    3.628269959803192, 9.124590355695997, 7.007276949682233, 3.718711952693311,
    5.785787070643415, 5.1617307825137715, 5.043620142358933, 6.270519544282943,
    11.611908589868834, 11.190848325174015, 14.520303431705951,
    16.170730886710487, 10.071845207503825, 8.778217461349529,
    8.842788533613522, 5.806526298864871, 3.9906589642024652, 4.00049743180634,
    4.033363143353517, 3.658177593881414, 6.760187594693832, 4.880631708117285,
    3.3640368298704977, 5.225239561764047, 8.555446444890325, 5.084085334696471,
    4.977823347386823, 2.319492280976487, 1.9382108039026507,
    2.1709837176601505, 0.0, 0.9681006572779404, 0.652803767500427,
    3.523712110150018, 5.612803709995876, 11.679103285752548, 12.49211153428984,
    11.246559051679636, 15.394997855333939, 18.69294512342976, 19.5829490454747,
    21.277483040771834, 22.820652507741247, 22.897847567797893,
    22.897847567797893, 22.897847567797893, 22.897847567797893,
    22.897847567797893, 22.897847567797893, 24.59950040475839,
    23.811691101385517, 29.101305927026132, 23.456258750163187,
    24.845405967787233, 23.61798366822086, 21.556044267177437,
    22.38387169257793, 25.615038541740972, 24.477153958426207,
    33.85695933690763, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 34.04846417423501, 34.04846417423501, 34.04846417423501,
    34.04846417423501, 33.17435039485283, 33.17949328775639, 29.522879735613426,
    30.76609060653047, 33.46509082094688, 34.62995606360563, 36.200825984674516,
    36.26463966773019, 34.50216227213038, 31.33741330035007, 28.55533670640563,
    29.802652465224853, 25.861114150448856, 26.93249742961968,
    27.051094770811236, 28.83350108794885, 23.38209471691914, 24.55458787961967,
    24.706364906750757, 26.425505416930264, 28.357524868637686,
    28.34255393788043, 25.04809964309405, 20.94175285931797, 21.744156278705635,
    21.075192900596488, 20.675065122766398, 23.584041993804185,
    21.501399590998357, 24.914659601815536, 21.346593114281497,
    21.62637640803724, 21.830776096491718, 24.691794696838176, 24.5026865158167,
    21.762349005054133, 21.063299505926377, 19.85712976343892,
    19.99097052556211, 17.039677533326085, 15.585830251158674,
    24.882826337914135, 26.189849502590725, 25.633921460348958,
    28.10914206027109, 27.591763363287555, 26.226828022137813,
    27.965171075194387, 24.485647865500894, 22.866911970164466,
    23.48176200540801, 24.18356846394714, 22.592289960888213,
    24.276904363090072, 31.050897896403733, 31.11821361262186,
    31.11821361262186, 31.11821361262186, 31.11821361262186, 31.11821361262186,
    31.11821361262186, 31.11821361262186, 31.11821361262186, 31.11821361262186,
    31.11821361262186, 24.401213917114195, 25.19685345997686,
    24.309513833628213, 22.700161666380207, 19.171828231497205,
    13.137507378116224, 15.539578503376152, 15.278594413030167,
    13.711072557838092, 14.167563110424469, 9.751591709880103,
    12.066736180657436, 9.915442800834928, 9.945370944520574,
    3.1550425706602754, 4.40626194610209, 3.381293684179017, 2.6252233554233175,
    0.0, 0.0, 5.769605916195228, 8.04961122871582, 7.142823816874115,
    7.801147266104028, 4.42785506736664, 8.594126062876237, 11.495957892557728,
    8.219793892863297, 5.677856052928334, 6.275563385425939, 7.1230527515565285,
    7.71192749318812, 4.213918505412918, 4.704043972147934, 6.9788073010670875,
    7.672521714727548, 6.8642714743996835, 4.1327132103153925, 0.0,
    0.8561680354414952, 3.914410268409001, 4.0745910048597045,
    5.040132703925575, 4.658420691030144, 2.9716140266037696, 5.80348268851442,
    11.05725387674933, 10.63373820119324, 15.747393818724966,
    14.028719820949052, 11.577556211586353, 13.184975899206577,
    16.701696063324153, 14.785376480875273, 15.382118952678038,
    12.68395389337563, 20.434335180773306, 20.518051672442898,
    20.518051672442898, 19.90239864940222, 21.047085857208966,
    20.72336156613731, 21.698970727868517, 25.690646090977985,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 25.764955379282327, 25.764955379282327,
    25.764955379282327, 26.72097491474348, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 26.794237187375018,
    26.794237187375018, 26.794237187375018, 24.976585056099577,
    24.427955639454634, 24.090097468079044, 20.139539316497395,
    19.637382239310266, 19.104289080003333, 19.72984940427915,
    22.38627654396513, 21.06774115857802, 21.87352888960247, 20.856509453431485,
    20.561201185522748, 22.408088698888605, 26.376707110098092,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
    26.450330329782236, 26.450330329782236, 26.450330329782236,
  ],
  analysis: {
    initial_portfolio_value: 5000,
    final_portfolio_value: 26867.98133967542,
    trade_analysis: {
      total_open: 0,
      total_closed: 14,
      total_won: 6,
      total_won_value: 29763.14,
      max_win_value: 20738.63,
      total_lost: 8,
      total_lost_value: -7895.16,
      max_lost_value: -3841.49,
      win_streak: 2,
      lose_streak: 2,
      pnl_gross: 22320.99,
      commissions: -453.0,
      pnl_net: 21867.98,
      strike_rate: 42.86,
    },
    sqn: 1.03,
    max_dd: { percentage: 36.26, value: 12493.5 },
  },
};
