import { useState } from 'react';
import { StrategySymbolsInfo, UserStrategy } from 'types/strategies';
import { UserSettings } from 'types/user';
import { createContainer } from 'unstated-next';
import { UserCommonDataProps } from './types';
import { USER_WATCHLIST_KEY } from 'constants/app/localstore';

const cachedWatchlist = JSON.parse(
  localStorage.getItem(USER_WATCHLIST_KEY) ?? '[]',
);

const defaultInitialState: UserCommonDataProps = {
  userStrategies: [],
  watchlist: cachedWatchlist,
  userSettings: undefined,
};

const useStateDefination = (initialState = defaultInitialState) => {
  // State Properties
  const [userStrategies, setUserStartegies] = useState<UserStrategy[]>(
    initialState.userStrategies,
  );

  const [isLoadingUserStrategies, setIsLoadingUserStartegies] = useState(false);

  const [watchlist, setWatchlist] = useState<StrategySymbolsInfo[]>(
    initialState.watchlist,
  );

  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    initialState.userSettings,
  );

  return {
    userStrategies,
    setUserStartegies,
    watchlist,
    setWatchlist,
    isLoadingUserStrategies,
    setIsLoadingUserStartegies,
    userSettings,
    setUserSettings,
  };
};

// Export Exchange Context
export const stateContainer = createContainer(useStateDefination);
export const UserCommonDataState = {
  Provider: stateContainer.Provider,
  get: stateContainer.useContainer,
};
