import PushButton from 'components/PushButton';
import TradingViewChart from 'components/TradingViewChart';
import { TRADING_VIEW_GRANULARITIES } from 'constants/app/app';
import BasicParamsForm from './components/BasicParamsForm';
import BuySellConditionForm from './components/BuySellConditionForm';
import IndicatorForm from './components/IndicatorsForm';
import RiskManagementForm from './components/RiskManagementForm';
import BacktestInitBottomSheet from './components/BacktestInitBottomSheet';
import styles from './configure.module.css';
import {
  ConfigureConfirmationActionTypes,
  ConfigureConfirmationActions,
  StrategyConfigureProps,
} from './types';
import {
  AiOutlineReconciliation,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import StrategyMenu from '../StrategyMenu';
import { useEffect, useState } from 'react';
import BottomSheetMenu from 'components/BottomSheetMenu';
import { PushButtonType } from 'components/PushButton/types';
import AddIndicatorBottomSheet from './components/AddIndicatorBottomSheet';
import { StrategyHeader } from '../StrategyHeader';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';
import { Chat } from '../Chat';
import { useComponentLogics } from './logics';
import { Analytics } from 'utils/analytics';
import { page_visit_str_configure } from 'constants/app/analyticsEvents';

export const StrategyConfigure = (props: StrategyConfigureProps) => {
  const {
    updateMarketStructure,
    deleteStrategy,
    stopLiveStrategy,
    duplicateStrategy,
  } = props;

  const {
    strategyState,
    basicStrategyParamsInfo,
    getBacktestResults,
    AddIndicatorToStrategy,
    removeIndicator,
    updateEntryCondition,
    updateExitCondition,
    updateConditionFromText,
    updateBasacParamInfo,
  } = useComponentLogics();

  const {
    basicParams,
    indicatorFunctions: indicators,
    setSelectedIndicator,
    selectedIndicatorInfo,
    strategyIndicators,
    updateSelectedIndicatorInfo,
    buyConditionText: buyText,
    buyCondition,
    sellConditionText: sellText,
    sellCondition,
    buyCondErrorText,
    sellCondErrorText,
    isLoadingBuyCond,
    isLoadingSellCond,
    showBuyCond,
    showSellCond,
    setShowBuyCond,
    setShowSellCond,
    selectedStrategy,
    marketStructure,
  } = strategyState;

  const { isDesktop } = useDisplayType();
  // Move to State file if state size grows beyond one state param
  const [isCalenderOpen, setIsCalendarOpen] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<
    keyof ConfigureConfirmationActionTypes | ''
  >('');
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
  const symbolSplit = basicParams.symbol.split('/');
  const bottomSheetActions = [
    {
      name: 'Save',
      description: 'Run backtest to verify and save your changes.',
      action: () => {
        setShowMoreOptions(false);
        setIsCalendarOpen(true);
      },
    },
    selectedStrategy?.tradingMode
      ? {
          name: 'Stop',
          description:
            'Stop strategy with current position. You can resume it later.',
          action: () => {
            setConfirmationAction('STOP');
            setShowMoreOptions(false);
          },
        }
      : {
          name: 'Backtest & Deploy',
          description: 'Backtest and deploy your strategy.',
          action: () => {
            setShowMoreOptions(false);
            setIsCalendarOpen(true);
          },
        },
    {
      name: 'Duplicate',
      description: 'Create a copy of this strategy with same settings.',
      action: () => {
        setConfirmationAction('DUPLICATE');
        setShowMoreOptions(false);
      },
    },
    {
      name: 'Delete',
      description: 'Delete this strategy and associated data.',
      action: () => {
        setConfirmationAction('DELETE');
        setShowMoreOptions(false);
      },
    },
  ];

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_str_configure);
  }, []);

  return (
    <div className={styles.configContents}>
      <div
        className={
          isDesktop ? styles.formContainer : styles.formContainerMobile
        }
      >
        <StrategyMenu
          selectedStrategy={selectedStrategy}
          showBackButton={true}
        />
        <div
          className={
            isDesktop ? styles.formContents : styles.formContentsMobile
          }
        >
          <StrategyHeader
            symbolsInfo={{
              base_currency: symbolSplit[0],
              quote_currency: symbolSplit[1],
              exchange_id: basicParams.exchange,
            }}
            interval={basicParams.interval}
            tradingMode={selectedStrategy?.tradingMode}
          />
          <BasicParamsForm
            basicParams={basicParams}
            updateBasicParams={updateBasacParamInfo}
            basicParamsRenderOptions={basicStrategyParamsInfo}
            indicators={indicators}
            onSelectIndicator={setSelectedIndicator}
            selectedIndicatorInfo={selectedIndicatorInfo}
          />
          <IndicatorForm
            indicators={indicators}
            onSelectIndicator={setSelectedIndicator}
            selectedIndicatorInfo={selectedIndicatorInfo}
            onAddIndicator={AddIndicatorToStrategy}
            strategyIndicators={strategyIndicators}
            updateSelectedIndicatorInfo={updateSelectedIndicatorInfo}
            removeIndicator={removeIndicator}
          />
          <BuySellConditionForm
            buyText={buyText}
            buyCondition={buyCondition}
            sellText={sellText}
            sellCondition={sellCondition}
            updateBuyCondition={updateEntryCondition}
            updateSellCondition={updateExitCondition}
            strategyIndicators={strategyIndicators}
            updateConditionFromText={updateConditionFromText}
            buyCondErrorText={buyCondErrorText}
            sellCondErrorText={sellCondErrorText}
            isLoadingBuyCond={isLoadingBuyCond}
            isLoadingSellCond={isLoadingSellCond}
            showBuyCond={showBuyCond}
            showSellCond={showSellCond}
            setShowBuyCond={setShowBuyCond}
            setShowSellCond={setShowSellCond}
          />
          <RiskManagementForm
            basicParams={basicParams}
            updateBasicParams={updateBasacParamInfo}
          />
        </div>
        <div className={styles.actionsContainer}>
          <PushButton
            additionalClassName={styles.actionButton}
            title={'More Options'}
            onClick={() => setShowMoreOptions(true)}
            icon={<AiOutlineUnorderedList />}
            type={PushButtonType.Secondary}
          />
          <PushButton
            additionalClassName={styles.actionButton}
            title={'Run Backtest'}
            onClick={() => setIsCalendarOpen(true)}
            icon={<AiOutlineReconciliation />}
          />
        </div>
        <AddIndicatorBottomSheet
          isOpen={!!selectedIndicatorInfo}
          onCancel={() => setSelectedIndicator(null)}
          onSelectIndicator={setSelectedIndicator}
          selectedIndicatorInfo={selectedIndicatorInfo}
          onAddIndicator={AddIndicatorToStrategy}
          updateSelectedIndicatorInfo={updateSelectedIndicatorInfo}
        />
        <BacktestInitBottomSheet
          isOpen={isCalenderOpen}
          startDate={basicParams.startDate}
          onDateUpdate={(date) => {
            updateBasacParamInfo({
              type: 'startDate',
              value: date,
            });
          }}
          onCancel={() => setIsCalendarOpen(false)}
          onDateSubmit={() => {
            setIsCalendarOpen(false);
            getBacktestResults();
          }}
          onChangeCashAllocation={(value) => {
            updateBasacParamInfo({ type: 'initialCash', value });
          }}
          cashAllocation={basicParams.initialCash}
          symbol={basicParams.symbol}
          updateMarketStructure={updateMarketStructure}
          marketStructure={marketStructure}
        />
        <BottomSheetMenu
          show={showMoreOptions}
          header={'More Options'}
          subtitle={'Manage your strategy'}
          onCancel={() => {
            setShowMoreOptions(false);
          }}
          showbackButton={true}
          actions={bottomSheetActions}
        />
        <ConfirmationBottomSheet
          show={!!confirmationAction}
          isLoading={isConfirmationLoading}
          header={
            confirmationAction
              ? ConfigureConfirmationActions[confirmationAction].title
              : ''
          }
          subtitle={
            confirmationAction
              ? ConfigureConfirmationActions[confirmationAction].subtitle
              : ''
          }
          message={
            confirmationAction
              ? ConfigureConfirmationActions[confirmationAction].message
              : ''
          }
          onConfim={async () => {
            setIsConfirmationLoading(true);
            switch (confirmationAction) {
              case 'DELETE':
                await deleteStrategy();
                break;
              case 'STOP':
                await stopLiveStrategy();
                break;
              case 'DUPLICATE':
                duplicateStrategy();
                break;
            }
            setIsConfirmationLoading(false);
            setConfirmationAction('');
          }}
          onCancel={() => {
            setConfirmationAction('');
          }}
        />
        <Chat />
      </div>
      {isDesktop && (
        <div className={styles.chart}>
          <TradingViewChart
            key={`${basicParams.exchange.toUpperCase()}:${basicParams.symbol.replace(
              '/',
              '',
            )}`}
            interval={TRADING_VIEW_GRANULARITIES[basicParams.interval]}
            exchangeSymbol={`${basicParams.exchange.toUpperCase()}:${basicParams.symbol.replace(
              '/',
              '',
            )}`}
            allow_symbol_change={false}
            hide_top_toolbar={false}
            hide_legend={false}
          />
        </div>
      )}
    </div>
  );
};
