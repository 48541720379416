import ListButton from 'components/ListButton';
import { ListButtonType } from 'components/ListButton/types';
import commonStyles from '../common.module.css';
import Select from 'react-select';
import { supportedExchanges } from 'constants/app/exchange';
import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import { useComponentLogic } from './logics';
import Loader from 'components/Loader';
import CustomActionBottomSheet from 'components/CustomActionBottomSheet';

export const ExchangeSettings = () => {
  const Component = useComponentLogic();

  return (
    <div>
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Link Exchange:</div>
        <div className={commonStyles.paramValue}>
          <Select
            value={Component.State.selectedExchange}
            className={'settingsSelect'}
            classNamePrefix='headerMenuSelect'
            options={supportedExchanges}
            inputProps={{ autoComplete: 'none' }}
            onChange={(o) => Component.selectedExchange(o)}
          />
        </div>
      </div>
      <CustomActionBottomSheet
        header={'Link Exchange'}
        subtitle={'Enter your exchange credentials to link exchange'}
        onCancel={() => Component.State.setExchange(null)}
        onConfim={() => Component.addExchangeCredentials()}
        show={!!Component.State.selectedExchange}
      >
        <div>
          <div className={commonStyles.textRow}>
            Generate the required credentials on your exchange and enter to link
            a new exchange to your account:
          </div>
          <div className={commonStyles.row}>
            <div className={commonStyles.paramName}>API Key:</div>
            <div className={commonStyles.paramValue}>
              <TextInput
                inputClass={commonStyles.basicParamInput}
                containerClass={commonStyles.inputContainer}
                placeholder='Enter API Key here'
                value={Component.State.pk}
                type={InputTypes.TEXT}
                onChange={(v) => Component.State.setPrivateKey(v)}
                renderEmptyErrorSpan={false}
                autoComplete={false}
              />
            </div>
          </div>
          <div className={commonStyles.row}>
            <div className={commonStyles.paramName}>Secret Key:</div>
            <div className={commonStyles.paramValue}>
              <TextInput
                inputClass={commonStyles.basicParamInput}
                containerClass={commonStyles.inputContainer}
                placeholder='Enter Secret Key here'
                value={Component.State.sk}
                type={InputTypes.TEXT}
                onChange={(v) => Component.State.setSecretKey(v)}
                renderEmptyErrorSpan={false}
                autoComplete={false}
              />
            </div>
          </div>
        </div>
      </CustomActionBottomSheet>
      <div className={commonStyles.textRowHeader}>
        Exchanges Linked to your account:{' '}
        {Component.State.isLoadingExchanges && (
          <Loader containerClassName={commonStyles.loaderContainer} />
        )}
      </div>
      {Component.State.linkedExchanges.length > 0 ? (
        <div>
          {Component.State.linkedExchanges.map((exchange) => {
            return (
              <div className={commonStyles.row} key={exchange.exchangeId}>
                <div className={commonStyles.paramName}>
                  {exchange.exchangeName}
                </div>
                <div className={commonStyles.paramValue}>
                  <ListButton
                    type={ListButtonType.Secondary}
                    title='Delete'
                    onClick={() =>
                      Component.deleteExchangeCredentials(exchange.exchangeId)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : Component.State.isLoadingExchanges ? null : (
        <div className={`${commonStyles.row} ${commonStyles.rowMessage}`}>
          No Exchanges Linked to this account. Select an exchange from the
          dropdown and add credentials to link exchange.
        </div>
      )}
    </div>
  );
};
