import styles from './newslist.module.css';
import { NewsListProps } from './types';
import { useDisplayType } from 'utils/display';
import { readableLocalTime } from 'utils/dateTime';
import { NewsFeedItem } from 'types/news';
import Loader from 'components/Loader';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useEffect, useRef } from 'react';
import { openInAppBrowser } from 'utils/inAppBrowser';

export const NewsList = (props: NewsListProps) => {
  const { data, isLoading, onBack, onScrollEnd, fetchMore } = props;
  const { isDesktop } = useDisplayType();
  const lastNewsItemRef = useRef(null);

  // Add intersection observer to last element as the data changes.
  useEffect(() => {
    if (onScrollEnd && !isLoading && fetchMore) {
      if (!lastNewsItemRef.current) return;

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollEnd();
        }
      });

      observer.observe(lastNewsItemRef.current);

      return () => {
        observer.disconnect();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onScrollEnd, isLoading]);

  const sentimentClasses = {
    '1': 'positive',
    '0': 'neutral',
    '-1': 'negative',
  };

  const onClickNews = (newsItem: NewsFeedItem) => {
    // @ts-ignore
    // if (window.webViewInterface) {
    //   // @ts-ignore
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({ type: 'OPEN_LINK', data: newsItem.link }),
    //   );
    // } else {
    //   window.open(newsItem.link, '_blank');
    // }
    openInAppBrowser(newsItem.link);
  };

  return (
    <div className={isDesktop ? styles.container : styles.containerMobile}>
      {onBack && (
        <div className={styles.header}>
          <div className={styles.iconContainer} onClick={onBack}>
            <AiOutlineArrowLeft />
          </div>
          <div className={styles.headerText}>News Feed</div>
        </div>
      )}
      <div className={styles.newsListContainer}>
        {data &&
          data.map((i, index) => {
            let sentimentClass = '';
            if (i?.sentiment?.toString() in sentimentClasses) {
              // @ts-ignore
              sentimentClass = sentimentClasses[i.sentiment.toString()];
            }
            return (
              <div
                key={index}
                ref={index === data.length - 1 ? lastNewsItemRef : null}
                className={styles.newsItemContainer}
                onClick={() => onClickNews(i)}
              >
                <div className={styles.newsData}>
                  {sentimentClass ? (
                    <div className={styles[sentimentClass]}>
                      {sentimentClass}
                    </div>
                  ) : null}
                  <div className={styles.title}>{i.title}</div>
                  <div className={styles.time}>
                    {readableLocalTime(new Date(i.datetime))}
                  </div>
                  <div className={styles.source}>
                    {i.link ? new URL(i.link).hostname : '-'}
                  </div>
                </div>
                {i.image ? (
                  <div>
                    <img
                      src={i.image}
                      alt='news'
                      className={styles.newsImage}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
      {isLoading && <Loader containerClassName={styles.loaderContainer} />}
    </div>
  );
};
