import { NewsHeaderMenuItem, NewsHeaderMenuTopic } from 'types/news';

export const NEWS_MENU_ITEMS_URL_MAP: Record<
  NewsHeaderMenuTopic,
  NewsHeaderMenuItem
> = {
  national: {
    id: 'indian_stock_market_news',
    name: 'National',
    headerName: 'Indian Finanacial News',
    link: `/app/news/national`,
  },
  world: {
    id: 'us_stock_market_news',
    name: 'World',
    headerName: 'World Finanacial News',
    link: `/app/news/world`,
  },
  crypto: {
    id: 'crypto_market_news',
    name: 'Cryptocurrencies',
    headerName: 'Cryptocurrencies News',
    link: `/app/news/crypto`,
  },
};
