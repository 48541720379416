import { Animate } from 'react-simple-animate';

export type AnimateStepProps = {
  play?: boolean;
  children: React.ReactElement;
  height?: string;
};

const AnimateStep = (props: AnimateStepProps) => {
  const { play, children, height } = props;
  return (
    <Animate
      play={play}
      end={{
        opacity: 1,
        filter: 'blur(0)',
        transform: 'translateX(0)',
        height: height ? height : '100%',
      }}
      start={{
        opacity: 0,
        filter: 'blur(2px)',
        transform: 'translateX(20px)',
      }}
      duration={0.2}
    >
      {children}
    </Animate>
  );
};

export default AnimateStep;
