import styles from './backtest.module.css';
import { useComponentLogic } from './logics';
import { BacktestResults } from './components/BacktestResults';
import Loader from 'components/Loader';
import { Route, Switch } from 'react-router-dom';
import { StrategyConfigure } from './components/StrategyConfigure';
import LiveRun from './components/LiveRun';
import { UserStrategy } from 'types/strategies';
import { AssistantChatState } from 'containers/assistantChat/state';
import { StrategyState } from './state';

const Strategy = () => {
  return (
    // @ts-ignore
    <StrategyState.Provider>
      <StrategyView />
    </StrategyState.Provider>
  );
};

const StrategyView = () => {
  const Component = useComponentLogic();
  const isLoading =
    Component.State.isLoadingResult || Component.State.loadingStrategy;

  return (
    // @ts-ignore
    <AssistantChatState.Provider>
      <div
        className={`${styles.container} ${
          isLoading ? styles.loadingContainer : ''
        }`}
      >
        {isLoading && <Loader containerClassName={styles.loaderContainer} />}
        <Switch>
          <Route
            exact
            path={[
              '/app/strategies/strategy/configure',
              '/app/strategies/strategy/:strategyId/configure',
            ]}
            render={() => (
              <StrategyConfigure
                updateMarketStructure={Component.updateMarketStructure}
                deleteStrategy={Component.deleteStrategy}
                stopLiveStrategy={Component.stopLiveStrategy}
                duplicateStrategy={Component.duplicateStrategy}
              />
            )}
          />
          <Route
            exact
            path={[
              '/app/strategies/strategy/backtest/results',
              '/app/strategies/strategy/:strategyId/backtest/results',
            ]}
            render={() => (
              <BacktestResults
                selectedStrategy={Component.selectedStrategy as UserStrategy}
                saveOrUpdate={Component.saveOrUpdateStrategy}
              />
            )}
          />
          <Route
            exact
            path={[
              '/app/strategies/strategy/liverun/',
              '/app/strategies/strategy/:strategyId/liverun/',
            ]}
            render={() => (
              <LiveRun
                saveOrUpdate={Component.saveOrUpdateStrategy}
                stopLiveStrategy={Component.stopLiveStrategy}
                selectedStrategy={Component.selectedStrategy as UserStrategy}
                updateMarketStructure={Component.updateMarketStructure}
              />
            )}
          />
        </Switch>
      </div>
    </AssistantChatState.Provider>
  );
};

export default Strategy;
