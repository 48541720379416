import TradingViewChart from 'components/TradingViewChart';
import TradingViewSymbolInfo from 'components/TradingViewSymbolInfo';
import { NewsList } from '../NewsList';
import styles from './newsSearch.module.css';
import { NewsSearchProps } from './types';

export const NewsSearch = (props: NewsSearchProps) => {
  const { symbol, data, isLoading, onBack } = props;

  return (
    <div className={styles.conatiner}>
      {/* {symbol && (
        <div className={styles.tvSymbolInfoContainer}>
          <TradingViewSymbolInfo tvSymbol={symbol} alwaysShowChart={true} />
        </div>
      )} */}
      {/* {symbol && (
        <div className={styles.chartContainer}>
          {isDesktop ? (
            <div className={styles.chart}>
              <TradingViewChart
                exchangeSymbol={symbol}
                allow_symbol_change={false}
                studies={[]}
                hide_legend={!isDesktop}
                hide_top_toolbar={true}
              />
            </div>
          ) : null}
        </div>
      )} */}
      <NewsList data={data} isLoading={isLoading} onBack={onBack} />
    </div>
  );
};
