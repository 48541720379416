import BottomSheet from 'components/BottomSheet';
import Loader from 'components/Loader';
import PageHeader from 'components/PageHeader';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import styles from './cabs.module.css';
import { CustomActionBottomSheetProps } from './types';

const CustomActionBottomSheet = (props: CustomActionBottomSheetProps) => {
  const {
    header,
    subtitle,
    children,
    show,
    onCancel,
    onConfim,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    isLoading,
  } = props;
  const { isDesktop } = useDisplayType();
  return (
    <BottomSheet
      isOpen={show}
      onDismiss={onCancel}
      header={
        <PageHeader title={header} subTitle={subtitle} showBackButton={false} />
      }
    >
      <>
        <div className={isDesktop ? styles.container : styles.mobileContainer}>
          {isLoading && <Loader containerClassName={styles.loaderContainer} />}
          <div className={styles.messageContainer}>{children}</div>
        </div>
        <div className={styles.actionsContainer}>
          <PushButton
            additionalClassName={styles.actionButton}
            title={cancelText}
            onClick={onCancel}
            disabled={isLoading}
            icon={<AiOutlineClose />}
          />
          <PushButton
            additionalClassName={styles.actionButton}
            title={isLoading ? 'Please Wait..' : confirmText}
            onClick={onConfim}
            disabled={isLoading}
            type={PushButtonType.Secondary}
            icon={<AiOutlineCheck />}
          />
        </div>
      </>
    </BottomSheet>
  );
};

export default CustomActionBottomSheet;
