import { updateUserWatchlist } from 'apis/user';
import {
  PREFERRED_EXCHANGE_KEY,
  PREFERRED_INDICATORS,
  PREFERRED_SYMBOL_KEY,
} from 'constants/app/localstore';
import { useExchangeContainer } from 'containers/exchange';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { SelectOption } from 'types/app';
import { StrategySymbolsInfo } from 'types/strategies';
import { useComponentState } from './state';
import { Analytics } from 'utils/analytics';
import {
  click_unwatch_symbol,
  click_watch_symbol,
  click_watchlist_symbol,
  page_markets,
  page_visit_markets,
  select_exchange,
  select_symbol,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const State = useComponentState();
  const Exchange = useExchangeContainer();
  const firstRun = useRef(true);
  const CommonDataContainer = useUserCommonDataContainer();

  //Check if selected symbol is in watchlist
  const symbolIsInWatchlist: boolean =
    CommonDataContainer.State.watchlist?.some((e) => {
      const symbolSplit = State.symbol.split('/');
      const base = symbolSplit[0];
      const quote = symbolSplit[1];
      return (
        State.exchange === e.exchange_id &&
        base === e.base_currency &&
        quote === e.quote_currency
      );
    });

  // Update Exchange
  const selectExchange = (s: string, track = true) => {
    State.setExchange(s);
    localStorage.setItem(PREFERRED_EXCHANGE_KEY, s);

    if (track) {
      Analytics.track(select_exchange, {
        screen: page_markets,
        exchange: s,
      });
    }
  };

  // Update Symbol
  const selectSymbol = (s: string, track = true) => {
    State.setSymbol(s);
    localStorage.setItem(PREFERRED_SYMBOL_KEY, s);

    if (track) {
      Analytics.track(select_symbol, {
        screen: page_markets,
        symbol: s,
        exchange: State.exchange,
      });
    }
  };

  // Select from Watchlist/Favourites
  const selectFromWatchlist = (s: StrategySymbolsInfo) => {
    const symbol = `${s.base_currency}/${s.quote_currency}`;
    selectExchange(s.exchange_id, false);
    selectSymbol(symbol, false);

    Analytics.track(click_watchlist_symbol, {
      screen: page_markets,
      symbol: symbol,
      exchange: s.exchange_id,
    });
  };

  // Add to watchlist
  const addToWatchlist = () => {
    const watchlist = [...CommonDataContainer.State.watchlist];
    const symbolSplit = State.symbol.split('/');
    const base = symbolSplit[0];
    const quote = symbolSplit[1];
    const found = watchlist.some((e) => {
      return (
        State.exchange === e.exchange_id &&
        base === e.base_currency &&
        quote === e.quote_currency
      );
    });
    if (!found) {
      const newSymbolInfo = {
        base_currency: symbolSplit[0],
        exchange_id: State.exchange,
        quote_currency: symbolSplit[1],
      };
      watchlist.push(newSymbolInfo);
      CommonDataContainer.updateWatchlist(watchlist);
      updateUserWatchlist(watchlist);
    }

    Analytics.track(click_watch_symbol, {
      screen: page_markets,
      symbol: State.symbol,
      exchange: State.exchange,
    });
  };

  // Remove from watchlist
  const removeFromWatchlist = () => {
    const watchlist = [...CommonDataContainer.State.watchlist];
    const symbolSplit = State.symbol.split('/');
    const base = symbolSplit[0];
    const quote = symbolSplit[1];
    const initial: StrategySymbolsInfo[] = [];
    const updatedWatchlist: StrategySymbolsInfo[] = watchlist.reduce(
      (acc, e) => {
        if (
          !(
            State.exchange === e.exchange_id &&
            base === e.base_currency &&
            quote === e.quote_currency
          )
        ) {
          acc.push(e);
        }
        return acc;
      },
      initial,
    );
    CommonDataContainer.updateWatchlist(updatedWatchlist);
    updateUserWatchlist(updatedWatchlist);

    Analytics.track(click_unwatch_symbol, {
      screen: page_markets,
      symbol: State.symbol,
      exchange: State.exchange,
    });
  };

  // On select indicator
  const toggleIndicator = (i: SelectOption) => {
    const indicators = [...State.indicators];
    let localUpdate = indicators;
    const found = indicators.some((e) => e.value === i.value);
    if (found) {
      const updatedIndicators = indicators.filter((e) => e.value !== i.value);
      State.setIndicators(updatedIndicators);
      localUpdate = updatedIndicators;
    } else {
      if (indicators.length >= 3) {
        toast.error('Can not add more than 3 indicators.');
        return;
      }
      State.setIndicators([...indicators, i]);
      localUpdate = [...indicators, i];
    }
    localStorage.setItem(PREFERRED_INDICATORS, JSON.stringify(localUpdate));
  };

  // Update Symbols on init & exchange change
  useEffect(() => {
    if (!firstRun.current) Exchange.fetchExchangeSymbols(State.exchange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.exchange]);

  // change run value after first run.
  useEffect(() => {
    firstRun.current = false;
  }, []);

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_markets);
  }, []);

  return {
    State,
    watchlist: CommonDataContainer.State.watchlist,
    selectSymbol,
    selectExchange,
    selectFromWatchlist,
    removeFromWatchlist,
    addToWatchlist,
    symbolIsInWatchlist,
    exchangeSymbols: Exchange.State.exchangeSymbols,
    toggleIndicator,
  };
};
