import { useUserCommonDataContainer } from 'containers/userCommonData';
import { useHistory } from 'react-router';
import { UserStrategy } from 'types/strategies';
import { useComponentState } from './state';
import { Analytics } from 'utils/analytics';
import {
  click_select_strategy,
  page_strategies,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const State = useComponentState();
  const CommonDataContainer = useUserCommonDataContainer();
  const history = useHistory();

  const onSelectStrategy = (strId: string) => {
    Analytics.track(click_select_strategy, {
      screen: page_strategies,
    });
    history.push(`/app/strategies/strategy/${strId}/configure`);
  };

  return {
    State,
    userStrategies: CommonDataContainer.State.userStrategies,
    isLoadingUserStrategies: CommonDataContainer.State.isLoadingUserStrategies,
    onSelectStrategy,
  };
};
