import { AssistantThread } from 'types/assistant';
import {
  LambdaClient,
  InvokeWithResponseStreamCommand,
} from '@aws-sdk/client-lambda';
import HTTP from 'utils/http';

export const createThread = async (): Promise<AssistantThread> => {
  return HTTP.get(`${process.env.REACT_APP_API_BASE_URL}/assistant/thread`);
};

export const streamAssistantResponse = async (
  threadId: string,
  message: string,
  responseCallback: (response: string) => void,
  responseEndCallback: () => void,
  credentials: any,
) => {
  try {
    // Create an object containing the data you want to send
    const event = {
      threadId,
      message,
    };

    // create Lambda client
    const lambda = new LambdaClient({
      region: 'ap-south-1',
      credentials: credentials,
    });

    const input = {
      FunctionName: 'uptrend-assitant-api', // required
      URL: 'https://3r32gqejtx3l2dvukfqkezxvza0cmqsd.lambda-url.ap-south-1.on.aws/',
      Payload: JSON.stringify(event),
    };
    const command = new InvokeWithResponseStreamCommand(input);
    const response = await lambda.send(command);
    const eventStream = response.EventStream;

    const decoder = new TextDecoder('utf-8');
    // @ts-ignore
    for await (const chunk of eventStream) {
      if (chunk?.PayloadChunk?.Payload) {
        responseCallback(decoder.decode(chunk.PayloadChunk?.Payload));
      }
    }
    responseEndCallback();
  } catch (error) {
    console.error(error);
  }
};
