import { useMemo } from 'react';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import { TradingViewChartProps } from './types';
import moment from 'moment-timezone';

const TradingViewChart = (props: TradingViewChartProps) => {
  const {
    interval,
    exchangeSymbol,
    allow_symbol_change = true,
    save_image = false,
    hide_top_toolbar = false,
    studies,
    hide_legend = false,
  } = props;
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let convertedTimezone = moment.tz.zone(browserTimezone)?.name;
  convertedTimezone =
    convertedTimezone === 'Asia/Calcutta' ? 'Asia/Kolkata' : convertedTimezone;

  return useMemo(
    () => (
      <AdvancedRealTimeChart
        theme='light'
        interval={interval}
        timezone={convertedTimezone ?? 'Etc/UTC'}
        // @ts-ignore
        range={null}
        autosize={true}
        symbol={exchangeSymbol}
        hide_side_toolbar={true}
        withdateranges={false}
        allow_symbol_change={allow_symbol_change}
        save_image={save_image}
        hide_top_toolbar={hide_top_toolbar}
        container_id={'custom-con-id'}
        studies={studies}
        hide_legend={hide_legend}
      ></AdvancedRealTimeChart>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interval, exchangeSymbol, hide_top_toolbar, hide_legend, studies],
  );
};

export default TradingViewChart;
