export const get_strategy_events = [
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 19.28619515,
            },
          ],
          fee_quote: 19.28619515,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1714608000,
      txn_comm: 19.28619515,
      size: -0.33044128,
      gross_profit: 9868.36435087,
      txn_value: -19286.19515,
      ticktime: 1714608000,
      exec_type: 'Market',
      type: 'sell',
      at_price: 58364.97,
    },
    timestamp: 1714608000,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.4178308,
            },
          ],
          fee_quote: 9.4178308,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1697500800,
      txn_comm: 9.4178308,
      size: 0.33044128,
      gross_profit: 0,
      txn_value: 9417.8308,
      ticktime: 1697500800,
      exec_type: 'Market',
      type: 'buy',
      at_price: 28500.77,
    },
    timestamp: 1697500800,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.43669174,
            },
          ],
          fee_quote: 9.43669174,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1697068800,
      txn_comm: 9.43669174,
      size: -0.35112592,
      gross_profit: -392.22872228,
      txn_value: -9436.69174,
      ticktime: 1697068800,
      exec_type: 'Market',
      type: 'sell',
      at_price: 26875.52,
    },
    timestamp: 1697068800,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.82892046,
            },
          ],
          fee_quote: 9.82892046,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1696204800,
      txn_comm: 9.82892046,
      size: 0.35112592,
      gross_profit: 0,
      txn_value: 9828.92046,
      ticktime: 1696204800,
      exec_type: 'Market',
      type: 'buy',
      at_price: 27992.58,
    },
    timestamp: 1696204800,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.84859436,
            },
          ],
          fee_quote: 9.84859436,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1692316800,
      txn_comm: 9.84859436,
      size: -0.36992235,
      gross_profit: -81.73064371,
      txn_value: -9848.59436,
      ticktime: 1692316800,
      exec_type: 'Market',
      type: 'sell',
      at_price: 26623.41,
    },
    timestamp: 1692316800,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.930325,
            },
          ],
          fee_quote: 9.930325,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1687219200,
      txn_comm: 9.930325,
      size: 0.36992235,
      gross_profit: 0,
      txn_value: 9930.325,
      ticktime: 1687219200,
      exec_type: 'Market',
      type: 'buy',
      at_price: 26844.35,
    },
    timestamp: 1687219200,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.95020544,
            },
          ],
          fee_quote: 9.95020544,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1687132800,
      txn_comm: 9.95020544,
      size: -0.37776055,
      gross_profit: -66.86739571,
      txn_value: -9950.20544,
      ticktime: 1687132800,
      exec_type: 'Market',
      type: 'sell',
      at_price: 26339.98,
    },
    timestamp: 1687132800,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 10.01707284,
            },
          ],
          fee_quote: 10.01707284,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1687046400,
      txn_comm: 10.01707284,
      size: 0.37776055,
      gross_profit: 0,
      txn_value: 10017.07284,
      ticktime: 1687046400,
      exec_type: 'Market',
      type: 'buy',
      at_price: 26516.99,
    },
    timestamp: 1687046400,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 10.03712286,
            },
          ],
          fee_quote: 10.03712286,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1686182400,
      txn_comm: 10.03712286,
      size: -0.38106964,
      gross_profit: -339.4301621,
      txn_value: -10037.12286,
      ticktime: 1686182400,
      exec_type: 'Market',
      type: 'sell',
      at_price: 26339.34,
    },
    timestamp: 1686182400,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 10.37655302,
            },
          ],
          fee_quote: 10.37655302,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1686096000,
      txn_comm: 10.37655302,
      size: 0.38106964,
      gross_profit: 0,
      txn_value: 10376.55302,
      ticktime: 1686096000,
      exec_type: 'Market',
      type: 'buy',
      at_price: 27230.07,
    },
    timestamp: 1686096000,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 10.3973338,
            },
          ],
          fee_quote: 10.3973338,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1686009600,
      txn_comm: 10.3973338,
      size: -0.40412208,
      gross_profit: -944.35652728,
      txn_value: -10397.3338,
      ticktime: 1686009600,
      exec_type: 'Market',
      type: 'sell',
      at_price: 25728.2,
    },
    timestamp: 1686009600,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 11.34169033,
            },
          ],
          fee_quote: 11.34169033,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1685318400,
      txn_comm: 11.34169033,
      size: 0.40412208,
      gross_profit: 0,
      txn_value: 11341.69033,
      ticktime: 1685318400,
      exec_type: 'Market',
      type: 'buy',
      at_price: 28065.01,
    },
    timestamp: 1685318400,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 11.36387001,
            },
          ],
          fee_quote: 11.36387001,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1684972800,
      txn_comm: 11.36387001,
      size: -0.43161039,
      gross_profit: 956.76370417,
      txn_value: -11363.87001,
      ticktime: 1684972800,
      exec_type: 'Market',
      type: 'sell',
      at_price: 26329,
    },
    timestamp: 1684972800,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 10.40710631,
            },
          ],
          fee_quote: 10.40710631,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1678752000,
      txn_comm: 10.40710631,
      size: 0.43161039,
      gross_profit: 0,
      txn_value: 10407.10631,
      ticktime: 1678752000,
      exec_type: 'Market',
      type: 'buy',
      at_price: 24112.27,
    },
    timestamp: 1678752000,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 10.4284654,
            },
          ],
          fee_quote: 10.4284654,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1678406400,
      txn_comm: 10.4284654,
      size: -0.51214801,
      gross_profit: 776.20640797,
      txn_value: -10428.4654,
      ticktime: 1678406400,
      exec_type: 'Market',
      type: 'sell',
      at_price: 20362.21,
    },
    timestamp: 1678406400,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.652259,
            },
          ],
          fee_quote: 9.652259,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1673568000,
      txn_comm: 9.652259,
      size: 0.51214801,
      gross_profit: 0,
      txn_value: 9652.259,
      ticktime: 1673568000,
      exec_type: 'Market',
      type: 'buy',
      at_price: 18846.62,
    },
    timestamp: 1673568000,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.67158271,
            },
          ],
          fee_quote: 9.67158271,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1667779200,
      txn_comm: 9.67158271,
      size: -0.46263164,
      gross_profit: -112.39172999,
      txn_value: -9671.58271,
      ticktime: 1667779200,
      exec_type: 'Market',
      type: 'sell',
      at_price: 20905.58,
    },
    timestamp: 1667779200,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.78397444,
            },
          ],
          fee_quote: 9.78397444,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1667606400,
      txn_comm: 9.78397444,
      size: 0.46263164,
      gross_profit: 0,
      txn_value: 9783.97444,
      ticktime: 1667606400,
      exec_type: 'Market',
      type: 'buy',
      at_price: 21148.52,
    },
    timestamp: 1667606400,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.80356177,
            },
          ],
          fee_quote: 9.80356177,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1649289600,
      txn_comm: 9.80356177,
      size: -0.22708953,
      gross_profit: -186.43823369,
      txn_value: -9803.56177,
      ticktime: 1649289600,
      exec_type: 'Market',
      type: 'sell',
      at_price: 43170.47,
    },
    timestamp: 1649289600,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'order',
    data: {
      txn_dedeuctions: {
        fees: {
          fee_details: [
            {
              currency: 'USDT',
              cost: 9.99,
            },
          ],
          fee_quote: 9.99,
          quote_currency: 'USDT',
        },
      },
      symbol: 'BTC/USDT',
      datetime: 1648166400,
      txn_comm: 9.99,
      size: 0.22708953,
      gross_profit: 0,
      txn_value: 9990,
      ticktime: 1648166400,
      exec_type: 'Market',
      type: 'buy',
      at_price: 43991.46,
    },
    timestamp: 1648166400,
  },
  {
    eventsGroupHash: 'b437a948-5035-54a6-9513-74d7fdf2efe5',
    eventType: 'strategy_start_event',
    data: {
      eventTime: 1648080000,
      tradingMode: 'LIVE',
      eventType: 'strategy_start_event',
      allocations: {
        cash: { available: 10000, symbol: 'BTC/USDT', total_allocation: 10000 },
        position: null,
      },
    },
    timestamp: 1648080000,
  },
];
