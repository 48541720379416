export enum FloatingButtonType {
  Primary,
  Secondary,
  Danger,
  Grey,
  SimpleText,
}

export type FloatingButtonProps = {
  icon: JSX.Element;
  onClick: () => void;
  type?: FloatingButtonType;
  disabled?: boolean;
  additionalClassName?: string;
};
