import { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StrategyState } from './state';
import { toast } from 'react-toastify';
import { useExchangeContainer } from 'containers/exchange';
import {
  deleteUserStrategy,
  getUserStrategy,
  putStrategy,
  updateStrategyStatus,
} from 'apis/startegies';
import { useAuthContainer } from 'containers/auth';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { StrategyAllocations, UserStrategy } from 'types/strategies';
import { TradingModeTypes } from './components/MakeLiveBottomsheet/types';
import { getMarketStructure } from 'apis/exchange';
import { useApplicationContainer } from 'containers/application';
import { OnCloseActionType } from 'components/SubscriptionConfirmation/types';
import { isDemo } from 'utils/demo';
import { Analytics } from 'utils/analytics';
import {
  action_save_update_strategy,
  action_stop_live_strategy,
  page_strategies,
} from 'constants/app/analyticsEvents';

export const useComponentLogic = () => {
  const Exchange = useExchangeContainer();
  const CommonDataContainer = useUserCommonDataContainer();
  const Auth = useAuthContainer();
  const AppContainer = useApplicationContainer();
  const State = StrategyState.get();
  const selectedStrategy = State.selectedStrategy;
  const history = useHistory();
  const firstRun = useRef(true);
  // @ts-ignore
  let { strategyId } = useParams();
  if (['configure', 'backtest', 'liverun'].includes(strategyId)) {
    strategyId = undefined;
  }

  // Common //
  // Fetch selected Strategy Details
  const fetchSelectedStrategy = async () => {
    State.setLoadingStrategy(true);
    const strategy = await getUserStrategy(strategyId);
    State.setSelectedStrategy(strategy);
    State.setLoadingStrategy(false);
  };
  useEffect(() => {
    if (strategyId || isDemo) {
      fetchSelectedStrategy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyId]);

  // Common //
  // Method to Save/Update Startegies
  const saveOrUpdateStrategy = (
    tradingModeOverride?: TradingModeTypes,
    allocations?: StrategyAllocations,
  ): Promise<void | UserStrategy> => {
    if (!checkSaveOrUpdateAccess()) {
      return Promise.resolve();
    }
    State.setLoadingStrategyStateChange(true);
    const currentTradingMode = State.tradingMode;
    return putStrategy({
      userId: Auth.State.appUser.sub,
      basicParams: State.basicParams,
      indicators: State.strategyIndicators,
      buyCondition: State.buyCondition,
      buyConditionText: State.buyConditionText,
      sellCondition: State.sellCondition,
      sellConditionText: State.sellConditionText,
      tradingMode: tradingModeOverride
        ? tradingModeOverride
        : currentTradingMode
        ? currentTradingMode
        : undefined,
      allocations: allocations ? allocations : undefined,
    })
      .then((r) => {
        CommonDataContainer.fetchUserStrategies();
        toast.success('Strategy Saved Succcessfully');
        // @ts-ignore
        if (Object.values(TradingModeTypes).includes(tradingModeOverride)) {
          AppContainer.requestWebPushNotifications();
        }
        State.setLoadingStrategyStateChange(false);
        history.push('/app/strategies');
        return r;
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong, Please try again later.');
      })
      .finally(() => {
        Analytics.track(action_save_update_strategy, {
          tradingMode: tradingModeOverride
            ? tradingModeOverride
            : currentTradingMode
            ? currentTradingMode
            : 'None',
        });
      });
  };

  // Common //
  // Stop/Deactivate a strategy
  const stopLiveStrategy = () => {
    // set tradingMode = undefined to stop strategy
    State.setLoadingStrategyStateChange(true);
    return updateStrategyStatus(State.basicParams.id as string, undefined)
      .then(() => {
        State.setLoadingStrategyStateChange(false);
        CommonDataContainer.fetchUserStrategies();
        toast.success('Strategy Stopped Succcessfully');
        history.push('/app/strategies');
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong, Please try again later.');
      })
      .finally(() => {
        Analytics.track(action_stop_live_strategy, {
          id: State.basicParams.id,
        });
      });
  };

  // common //
  // fetch price ticker & precision
  const updateMarketStructure = async () => {
    State.setMarketStructureLoading(true);
    const marketStructure = await getMarketStructure(
      State.basicParams?.exchange as string,
      State.basicParams?.symbol,
    );
    State.setCurrentPrice(marketStructure.ticker.close);
    State.updateBasicParams({
      type: 'commission',
      value: marketStructure.taker * 100,
    });
    State.setMarketStructure(marketStructure);
    State.setMarketStructureLoading(false);
  };

  // Common //
  // Delete User Strategy
  const deleteStrategy = () => {
    return deleteUserStrategy(selectedStrategy?.id as string)
      .then(() => {
        CommonDataContainer.fetchUserStrategies();
        toast.success('Strategy Deleted Succcessfully');
        history.push('/app/strategies');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong, Please try again later.');
      });
  };

  // Common //
  // Duplicate Strategy
  const duplicateStrategy = () => {
    const access = checkDuplicateStrategyAccess();
    if (!access) return;

    if (selectedStrategy?.id) {
      State.updateBasicParams({ type: 'reset', value: null });
      State.setTradingMode(undefined);
      State.updateStrategyIndicators(
        selectedStrategy?.strategy.indicators ?? [],
      );
      State.updateBuyCondition(selectedStrategy?.strategy.buy_condition ?? '');
      State.updateSellCondition(
        selectedStrategy?.strategy.sell_condition ?? '',
      );
      State.updateBuyConditionText(
        selectedStrategy?.strategy.buy_condition_text ?? '',
      );
      State.updateSellConditionText(
        selectedStrategy?.strategy.sell_condition_text ?? '',
      );
      State.setLiveStrategyEvents([]);

      // to check access to create new strategy
      strategyId = undefined;
      State.setSelectedStrategy(null);

      checkMaxStartegiesAccess();
      toast.success('Strategy Duplicated Succcessfully');
    } else {
      toast.error('Please save this strategy first before creating a copy.');
    }
  };

  // Common //
  const checkDuplicateStrategyAccess = () => {
    const maxStrategies =
      CommonDataContainer.State.userSettings?.plan?.planDetails
        ?.maxStrategies ?? 0;
    const hasAccess =
      CommonDataContainer.State.userStrategies.length + 1 <= maxStrategies;
    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `You have reached the maximum limit of strategies for your current plan. Please upgrade your plan to create more strategies.`,
        screen: page_strategies,
        reason: 'maxStrategies',
      });
      return false;
    } else {
      AppContainer.hideSubscriptionConfirmation();
      return true;
    }
  };

  // common //
  const checkMaxStartegiesAccess = () => {
    const maxStrategies =
      CommonDataContainer.State.userSettings?.plan?.planDetails
        ?.maxStrategies ?? 1;
    const hasAccess =
      !!strategyId ||
      CommonDataContainer.State.userStrategies.length + 1 <= maxStrategies;
    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.CloseAndGoBack,
        message: `You have reached the maximum limit of strategies for your current plan. Please upgrade your plan to create more strategies.`,
        screen: page_strategies,
        reason: 'maxStrategies',
      });
      return false;
    } else {
      AppContainer.hideSubscriptionConfirmation();
      return true;
    }
  };

  const checkSaveOrUpdateAccess = () => {
    const maxStrategies =
      CommonDataContainer.State.userSettings?.plan?.planDetails
        ?.maxStrategies ?? 0;
    const hasAccess =
      !!strategyId ||
      CommonDataContainer.State.userStrategies.length + 1 <= maxStrategies;
    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `You have reached the maximum limit of strategies for your current plan. Please upgrade your plan to create more strategies.`,
        screen: page_strategies,
        reason: 'maxStrategies',
      });
      return false;
    } else {
      AppContainer.hideSubscriptionConfirmation();
      return true;
    }
  };

  // redirect to live page if selectedStrategy is live.
  useEffect(() => {
    // Check if create strategy access is granted in plan
    checkMaxStartegiesAccess();

    if (
      strategyId &&
      selectedStrategy &&
      selectedStrategy.is_active === 'YES'
    ) {
      history.push(`/app/strategies/strategy/${strategyId}/liverun`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy]);

  // redirect to results page as results arrive.
  useEffect(() => {
    if (State.resultChartData) {
      history.push(
        strategyId
          ? `/app/strategies/strategy/${strategyId}/backtest/results`
          : `/app/strategies/strategy/backtest/results`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.resultChartData]);

  // Common //
  // Update minmove for cData chartdata when market structure is fetched (for live)
  useEffect(() => {
    const oldChartData = State.liveChartData;
    if (oldChartData && State.marketStructure && !oldChartData.cDataMinMove) {
      State.setLiveChartData({
        ...oldChartData,
        cDataMinMove: State.marketStructure.limits?.price?.min,
        slData: State.summary?.allocations?.stop_loss_order,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.marketStructure, State.liveChartData, State.summary]);

  // Common //
  // fetch exchange symbols on Exchange change
  useEffect(() => {
    if (!firstRun.current)
      Exchange.fetchExchangeSymbols(State.basicParams.exchange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State.basicParams.exchange]);

  // Common //
  // Update Editable Params when selected strategy changes
  // TODO: Check if this is working fine
  useEffect(() => {
    if (selectedStrategy) {
      State.updateBasicParams({ type: 'reset', value: null });
      State.setTradingMode(selectedStrategy?.tradingMode);
      State.updateStrategyIndicators(
        selectedStrategy?.strategy.indicators ?? [],
      );
      State.updateBuyCondition(selectedStrategy?.strategy.buy_condition ?? '');
      State.updateSellCondition(
        selectedStrategy?.strategy.sell_condition ?? '',
      );
      State.updateBuyConditionText(
        selectedStrategy?.strategy.buy_condition_text ?? '',
      );
      State.updateSellConditionText(
        selectedStrategy?.strategy.sell_condition_text ?? '',
      );
    }
  }, [selectedStrategy]);

  // Common //
  // change run value after first run.
  useEffect(() => {
    firstRun.current = false;
  }, []);

  return {
    State,
    saveOrUpdateStrategy,
    stopLiveStrategy,
    selectedStrategy,
    updateMarketStructure,
    deleteStrategy,
    duplicateStrategy,
  };
};
