import { useState } from 'react';

export const useComponentState = () => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState<boolean>(false);

  return {
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    showDeleteLoader,
    setShowDeleteLoader,
  };
};
