import styles from './news.module.css';
import { NewsViewProps } from './types';
import { NewsList } from '../NewsList';
import { Trending } from '../Trending';
import { useDisplayType } from 'utils/display';
import Loader from 'components/Loader';
import { NewsSummary } from '../NewsSummary';
import { useEffect, useState } from 'react';
import { NewsSearch } from '../NewsSearch';
import { SelectedTrendingRecord } from 'types/news';
import BottomSheet from 'components/BottomSheet';
import PageHeader from 'components/PageHeader';
import FloatingButton from 'components/FloatingButton';
import { AiOutlineRise } from 'react-icons/ai';
import { NEWS_MENU_ITEMS_URL_MAP } from 'constants/app/news';

export const NewsView = (props: NewsViewProps) => {
  const {
    newsData,
    fetchNewsForTopic,
    newsTopicData,
    onSelectTrendingTopic,
    clearTrendingSelect,
  } = props;
  const { data, isLoading, summary, sentimentChartData, fetchMore } =
    newsData ?? {};

  const { isDesktop } = useDisplayType();

  const [showTrendingBottoSheet, setTrendingBottomSheetDisplay] =
    useState(false);

  useEffect(() => {
    if (!data) {
      fetchNewsForTopic(newsTopicData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScrollEnd = () => {
    fetchNewsForTopic(newsTopicData.id);
  };

  const onTrendingClick = (trending: SelectedTrendingRecord) => {
    if (
      newsTopicData.id === NEWS_MENU_ITEMS_URL_MAP.crypto.id &&
      trending?.symbol?.startsWith('BINANCE:')
    ) {
      trending.symbol = trending.symbol + 'USDT';
    }
    onSelectTrendingTopic(newsTopicData.id, trending);
    showTrendingBottoSheet && setTrendingBottomSheetDisplay(false);
  };

  const onTrendingClear = () => {
    clearTrendingSelect(newsTopicData.id);
  };

  return !data && isLoading ? (
    <Loader containerClassName={styles.loaderContainer} />
  ) : (
    <div className={styles.container}>
      <div
        className={
          isDesktop ? styles.newsContainer : styles.newsContainerMobile
        }
      >
        {newsData?.selectedTrending ? (
          <NewsSearch
            symbol={newsData?.selectedTrending?.symbol}
            data={newsData?.trendingSearchData ?? []}
            isLoading={isLoading}
            onBack={onTrendingClear}
          />
        ) : (
          <>
            <NewsSummary data={summary} chartData={sentimentChartData} />
            <NewsList
              data={data}
              isLoading={isLoading}
              onScrollEnd={onScrollEnd}
              fetchMore={fetchMore}
            />
          </>
        )}
      </div>
      <>
        <div>
          <FloatingButton
            icon={<AiOutlineRise size={'24px'} />}
            onClick={() => setTrendingBottomSheetDisplay(true)}
          />
        </div>
        <BottomSheet
          isOpen={showTrendingBottoSheet}
          onDismiss={() => setTrendingBottomSheetDisplay(false)}
          header={
            <PageHeader
              title={'In News Today'}
              subTitle={"See what's trending in news today."}
              showBackButton={true}
              play={showTrendingBottoSheet}
              customActionOnBack={() => setTrendingBottomSheetDisplay(false)}
            />
          }
        >
          <div className={styles.trendingContainerMobile}>
            <Trending
              data={summary?.summary?.trending}
              selected={newsData?.selectedTrending}
              onTrendingClick={onTrendingClick}
            />
          </div>
        </BottomSheet>
      </>
    </div>
  );
};
