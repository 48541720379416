import {
  STOP_LOSS_TYPES,
  TRADING_VIEW_GRANULARITIES,
  TRAIL_STOP_VALUE_TYPES,
} from 'constants/app/app';
import {
  PREFERRED_EXCHANGE_KEY,
  PREFERRED_SYMBOL_KEY,
} from 'constants/app/localstore';
import { useEffect, useReducer, useState } from 'react';
import { SelectGroup, UseReducerAction } from 'types/app';
import { BacktestAnalysis } from 'types/backtest';
import { ChartData } from 'types/chart';
import {
  IndicatorInfo,
  LiveStrategySummary,
  UserStrategy,
} from 'types/strategies';
import {
  MarketStructure,
  TradingModeTypes,
} from './components/MakeLiveBottomsheet/types';
import { BasicStrategyParams, MovesTypes } from './types';
import { createContainer } from 'unstated-next';
import { useUserCommonDataContainer } from 'containers/userCommonData';

const useComponentState = () => {
  const CommonDataContainer = useUserCommonDataContainer();
  const tz = CommonDataContainer.State.userSettings?.defaultTimeZone as string;
  // Details of strategy selected on Strategy list page
  const [selectedStrategy, setSelectedStrategy] = useState<UserStrategy | null>(
    null,
  );

  // Loading flag for selected strategy
  const [loadingStrategy, setLoadingStrategy] = useState<boolean>(false);

  // Iniatal state params depending on the user flow (Create New / Edit Existing)
  const initialStateParams = {
    resultChartData: null,
    liveChartData: null,
    liveStrategyEvents: [],
    isLoadingTimeline: false,
    fetchMoreTimelineEvents: true,
    isLoadingLiveHistory: false,
    indicatorFunctions: null,
    resultAnalysisData: null,
    isLoadingResult: false,
    isLoadingStrategyStateChange: false,
    selectedIndicator: null,
    strategyIndicators: selectedStrategy
      ? selectedStrategy.strategy.indicators
      : [],
    buyCondition: selectedStrategy
      ? selectedStrategy.strategy.buy_condition
      : '',
    buyConditionText: selectedStrategy
      ? selectedStrategy.strategy.buy_condition_text
      : '',
    sellCondition: selectedStrategy
      ? selectedStrategy.strategy.sell_condition
      : '',
    sellConditionText: selectedStrategy
      ? selectedStrategy.strategy.sell_condition_text
      : '',
    tradingMode: selectedStrategy ? selectedStrategy.tradingMode : undefined,
  };

  // Backtest result chart data
  const [resultChartData, setResultChartData] = useState<ChartData | null>(
    initialStateParams.resultChartData,
  );

  // Live Strategy chart data
  const [liveChartData, setLiveChartData] = useState<ChartData | null>(
    initialStateParams.liveChartData,
  );

  // Live Strategy Timeline Events
  const [liveStrategyEvents, setLiveStrategyEvents] = useState<any[]>(
    initialStateParams.liveStrategyEvents,
  );

  // Latest Price of symbol
  const [currentPrice, setCurrentPrice] = useState<number>(0);

  const [fetchMoreTimelineEvents, setFetchMoreTimelineEvents] =
    useState<boolean>(initialStateParams.fetchMoreTimelineEvents);

  const [isLoadingTimeline, setIsLoadingTimeline] = useState(
    initialStateParams.isLoadingLiveHistory,
  );

  // Indicators to select from
  const [indicatorFunctions, setIndicatorFunctions] = useState<
    SelectGroup[] | null
  >(initialStateParams.indicatorFunctions);

  // Backtest result analysers data
  const [resultAnalysisData, setResultAnalysisData] =
    useState<BacktestAnalysis | null>(initialStateParams.resultAnalysisData);

  // Loading flag
  const [isLoadingResult, setIsLoadingResult] = useState(
    initialStateParams.isLoadingResult,
  );

  const [isLoadingLiveHistory, setIsLoadingLiveHistory] = useState(
    initialStateParams.isLoadingLiveHistory,
  );

  const [isLoadingStrategyStateChange, setLoadingStrategyStateChange] =
    useState(initialStateParams.isLoadingStrategyStateChange);

  // Selected indicator from dropdown (to be added)
  const [selectedIndicator, setSelectedIndicator] = useState<string | null>(
    initialStateParams.selectedIndicator,
  );

  const selectedIndicatorInfoReducer = (
    state: IndicatorInfo | null,
    action: UseReducerAction,
  ): IndicatorInfo | null => {
    if (state) {
      switch (action.type) {
        case 'full':
          return action.value as IndicatorInfo;
        case 'id':
          return {
            ...state,
            id: action.value as string,
          };
        case 'input_price':
          return {
            ...state,
            input_names: { ...state.input_names, price: action.value },
          };
        case 'input_price_param':
          return {
            ...state,
            input_names: {
              ...state.input_names,
              [action.value.key]: action.value.value,
            },
          };
        case 'parameter':
          const newParams = {
            ...state.parameters,
            [action.value.name]: action.value.value,
          };
          return {
            ...state,
            parameters: newParams,
          };
        default:
          return state;
      }
    } else {
      switch (action.type) {
        case 'full':
          return action.value as IndicatorInfo;
        default:
          return null;
      }
    }
  };

  const [selectedIndicatorInfo, updateSelectedIndicatorInfo] = useReducer(
    selectedIndicatorInfoReducer,
    null,
  );

  // Live Strategy Summary
  const [summary, setSummary] = useState<LiveStrategySummary | null>(null);

  // Market Structure (for live price)
  const [marketStructure, setMarketStructure] = useState<MarketStructure>();
  const [isLoadingMarketStructure, setMarketStructureLoading] = useState(false);

  // -------------------------- Editable strategy State Properties --------------------------------

  // Indicators already added to the strategy
  const [strategyIndicators, updateStrategyIndicators] = useState<
    IndicatorInfo[]
  >(initialStateParams.strategyIndicators);

  // Buy and sell condition strings
  const [buyCondition, updateBuyCondition] = useState<string>(
    initialStateParams.buyCondition,
  );
  const [buyConditionText, updateBuyConditionText] = useState<string>(
    initialStateParams.buyConditionText,
  );
  const [sellCondition, updateSellCondition] = useState<string>(
    initialStateParams.sellCondition,
  );
  const [sellConditionText, updateSellConditionText] = useState<string>(
    initialStateParams.sellConditionText,
  );

  const [buyCondErrorText, setBuyCondErrorText] = useState<string>('');
  const [sellCondErrorText, setSellCondErrorText] = useState<string>('');

  const [isLoadingBuyCond, setisLoadingBuyCond] = useState<boolean>(false);
  const [isLoadingSellCond, setisLoadingSellCond] = useState<boolean>(false);

  const [showBuyCond, setShowBuyCond] = useState<boolean>(false);
  const [showSellCond, setShowSellCond] = useState<boolean>(false);

  const setLoaderState = (type: MovesTypes, isLoading: boolean) => {
    switch (type) {
      case MovesTypes.ENTRY:
        isLoading && setBuyCondErrorText('');
        setisLoadingBuyCond(isLoading);
        break;
      case MovesTypes.EXIT:
        isLoading && setSellCondErrorText('');
        setisLoadingSellCond(isLoading);
        break;
      default:
        console.log('Invalid MovesTypes');
    }
  };

  // Current trading mode of the strategy (undefined if not live)
  const [tradingMode, setTradingMode] = useState<undefined | TradingModeTypes>(
    initialStateParams.tradingMode,
  );
  // Find preferred exchange and symbol
  let preferredExchangeId = localStorage.getItem(PREFERRED_EXCHANGE_KEY);
  let preferredExchangeSymbol = localStorage.getItem(PREFERRED_SYMBOL_KEY);

  preferredExchangeId = preferredExchangeId ? preferredExchangeId : 'binance';
  preferredExchangeSymbol = preferredExchangeSymbol
    ? preferredExchangeSymbol
    : 'BTC/USDT';

  // Basic params State & Reducer
  const initialBasicParams: BasicStrategyParams = selectedStrategy
    ? {
        id: selectedStrategy.id,
        name: selectedStrategy.strategy.name,
        initialCash: selectedStrategy.bt_parameters.initial_cash.toString(),
        exchange: selectedStrategy.symbols_info.exchange_id,
        symbol: `${selectedStrategy.symbols_info.base_currency}/${selectedStrategy.symbols_info.quote_currency}`,
        interval: selectedStrategy.strategy
          .interval as keyof typeof TRADING_VIEW_GRANULARITIES,
        tz,
        commission: selectedStrategy.bt_parameters.commission.toString(),
        startDate: new Date('01/01/2020'),
        stopLossType:
          STOP_LOSS_TYPES.find(
            (i) => i.value === selectedStrategy.strategy.sl_info.stop_loss_type,
          ) ?? null,
        stopLossValueType:
          TRAIL_STOP_VALUE_TYPES.find(
            (i) =>
              i.value ===
              selectedStrategy.strategy.sl_info.stop_loss_value_type,
          ) ?? null,
        stopLossValue:
          selectedStrategy.strategy.sl_info.stop_loss_value.toString(),
      }
    : {
        id: null,
        name: 'Strategy Name',
        initialCash: '5000',
        exchange: preferredExchangeId,
        symbol: preferredExchangeSymbol,
        interval: '1d',
        tz,
        commission: '0.1',
        startDate: new Date('01/01/2020'),
        stopLossType: STOP_LOSS_TYPES[0],
        stopLossValueType: TRAIL_STOP_VALUE_TYPES[0],
        stopLossValue: '0',
      };

  const basicParamReducer = (
    state: BasicStrategyParams,
    action: UseReducerAction,
  ): BasicStrategyParams => {
    return action.type === 'reset'
      ? initialBasicParams
      : {
          ...state,
          [action.type]: action.value,
        };
  };

  const [basicParams, updateBasicParams] = useReducer(
    basicParamReducer,
    initialBasicParams,
  );

  return {
    basicParams,
    updateBasicParams,
    resultChartData,
    liveChartData,
    setLiveChartData,
    liveStrategyEvents,
    setLiveStrategyEvents,
    fetchMoreTimelineEvents,
    setFetchMoreTimelineEvents,
    isLoadingTimeline,
    setIsLoadingTimeline,
    isLoadingLiveHistory,
    setIsLoadingLiveHistory,
    setResultChartData,
    isLoadingResult,
    setIsLoadingResult,
    resultAnalysisData,
    setResultAnalysisData,
    indicatorFunctions,
    setIndicatorFunctions,
    selectedIndicator,
    setSelectedIndicator,
    strategyIndicators,
    updateStrategyIndicators,
    selectedIndicatorInfo,
    updateSelectedIndicatorInfo,
    buyCondition,
    updateBuyCondition,
    buyConditionText,
    updateBuyConditionText,
    sellConditionText,
    updateSellConditionText,
    sellCondition,
    updateSellCondition,
    tradingMode,
    setTradingMode,
    selectedStrategy,
    setSelectedStrategy,
    loadingStrategy,
    setLoadingStrategy,
    summary,
    setSummary,
    currentPrice,
    setCurrentPrice,
    marketStructure,
    setMarketStructure,
    isLoadingMarketStructure,
    setMarketStructureLoading,
    buyCondErrorText,
    setBuyCondErrorText,
    sellCondErrorText,
    setSellCondErrorText,
    isLoadingBuyCond,
    isLoadingSellCond,
    setLoaderState,
    showBuyCond,
    setShowBuyCond,
    showSellCond,
    setShowSellCond,
    isLoadingStrategyStateChange,
    setLoadingStrategyStateChange,
  };
};

// Create the container with `createContainer`
const ComponentStateContainer = createContainer(useComponentState);

export const StrategyState = {
  Provider: ComponentStateContainer.Provider,
  get: ComponentStateContainer.useContainer,
};
