// All Supported by Backtrader & ccxt
// export const GRANULARITIES = [
//   { value: '1m', label: '1m' },
//   { value: '3m', label: '3m' },
//   { value: '5m', label: '5m' },
//   { value: '15m', label: '15m' },
//   { value: '30m', label: '30m' },
//   { value: '1h', label: '1h' },
//   { value: '90m', label: '90m' },
//   { value: '2h', label: '2h' },
//   { value: '3h', label: '3h' },
//   { value: '4h', label: '4h' },
//   { value: '6h', label: '6h' },
//   { value: '8h', label: '8h' },
//   { value: '12h', label: '12h' },
//   { value: '1d', label: '1d' },
//   { value: '3d', label: '3d' },
//   { value: '1w', label: '1w' },
//   { value: '2w', label: '2w' },
//   { value: '1M', label: '1M' },
//   { value: '3M', label: '3M' },
//   { value: '6M', label: '6M' },
//   { value: '1y', label: '1y' },
// ];

export type TRADING_VIEW_GRANULARITIES_TYPE = {
  '1m': '1';
  '3m': '3';
  '5m': '5';
  '15m': '15';
  '30m': '30';
  '1h': '60';
  '2h': '120';
  '3h': '180';
  '4h': '240';
  '1d': 'D';
  '1w': 'W';
};

// All Supported by Trading View
export const TRADING_VIEW_GRANULARITIES: TRADING_VIEW_GRANULARITIES_TYPE = {
  '1m': '1',
  '3m': '3',
  '5m': '5',
  '15m': '15',
  '30m': '30',
  '1h': '60',
  '2h': '120',
  '3h': '180',
  '4h': '240',
  '1d': 'D',
  '1w': 'W',
};

export type GRANULARITIES_TYPES = {
  value: keyof TRADING_VIEW_GRANULARITIES_TYPE;
  label: string;
};
export const GRANULARITIES: GRANULARITIES_TYPES[] = [
  // { value: '1m', label: '1m' },
  // { value: '3m', label: '3m' },
  // { value: '5m', label: '5m' },
  { value: '15m', label: '15m' },
  { value: '30m', label: '30m' },
  { value: '1h', label: '1h' },
  { value: '2h', label: '2h' },
  { value: '3h', label: '3h' },
  { value: '4h', label: '4h' },
  // { value: '6h', label: '6h' },
  { value: '1d', label: '1d' },
  { value: '1w', label: '1w' },
];

export const STOP_LOSS_TYPES = [
  { value: null, label: 'None' },
  { value: 'StopTrail', label: 'Trailing Stop Loss' },
];

export const TRAIL_STOP_VALUE_TYPES = [
  // { value: 'trailamount', label: 'Trail Amount' },
  { value: 'trailpercent', label: 'Trail Percent' },
];

export const PORTFOLIO_CURRENCIES = [
  { value: 'usd', label: 'USD' },
  { value: 'aed', label: 'AED' },
  { value: 'aud', label: 'AUD' },
  { value: 'brl', label: 'BRL' },
  { value: 'cad', label: 'CAD' },
  { value: 'chf', label: 'CHF' },
  { value: 'clp', label: 'CLP' },
  { value: 'cny', label: 'CNY' },
  { value: 'czk', label: 'CZK' },
  { value: 'dkk', label: 'DKK' },
  { value: 'eur', label: 'EUR' },
  { value: 'gbp', label: 'GBP' },
  { value: 'hkd', label: 'HKD' },
  { value: 'huf', label: 'HUF' },
  { value: 'idr', label: 'IDR' },
  { value: 'ils', label: 'ILS' },
  { value: 'inr', label: 'INR' },
  { value: 'jpy', label: 'JPY' },
  { value: 'krw', label: 'KRW' },
  { value: 'kwd', label: 'KWD' },
  { value: 'lkr', label: 'LKR' },
  { value: 'mmk', label: 'MMK' },
  { value: 'mxn', label: 'MXN' },
  { value: 'myr', label: 'MYR' },
  { value: 'ngn', label: 'NGN' },
  { value: 'nok', label: 'NOK' },
  { value: 'nzd', label: 'NZD' },
  { value: 'php', label: 'PHP' },
  { value: 'pkr', label: 'PKR' },
  { value: 'pln', label: 'PLN' },
  { value: 'rub', label: 'RUB' },
  { value: 'sar', label: 'SAR' },
  { value: 'sek', label: 'SEK' },
  { value: 'sgd', label: 'SGD' },
  { value: 'thb', label: 'THB' },
  { value: 'try', label: 'TRY' },
  { value: 'twd', label: 'TWD' },
  { value: 'uah', label: 'UAH' },
  { value: 'vnd', label: 'VND' },
  { value: 'zar', label: 'ZAR' },
];

export const STRATEGY_TIMEZONES = [
  {
    label: 'IST',
    value: 'ist',
  },
  {
    label: 'EDT',
    value: 'edt',
  },
  {
    label: 'CST',
    value: 'cst',
  },
  {
    label: 'HKT',
    value: 'hkt',
  },
  {
    label: 'JST',
    value: 'jst',
  },
  {
    label: 'AEDT',
    value: 'aedt',
  },
  {
    label: 'BST',
    value: 'bst',
  },
];

export const INDICATOR_PRICE_INPUTS = [
  {
    value: 'open',
    label: 'open',
  },
  {
    value: 'high',
    label: 'high',
  },
  {
    value: 'low',
    label: 'low',
  },
  {
    value: 'close',
    label: 'close',
  },
];
// TODO Check values
export const TICKSIZE_IN_SEC = {
  '15m': 900,
  '30m': 1800,
  '1h': 3600,
  '2h': 7200,
  '3h': 10800,
  '4h': 14400,
  '1d': 86400,
  '1w': 2419200,
};

export const LIVE_HISTORY_TICK_LIMIT = 250;

export const LIVE_TIMELINE_EVENTS_LIMIT = 10;

export const MS_IN_A_DAY = 86400000;

export const SAMPLE_WATCHLIST = [
  { base_currency: 'BTC', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ETH', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'DOGE', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ALGO', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'AAVE', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'MATIC', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'SOL', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ETH', exchange_id: 'binance', quote_currency: 'BTC' },
  { base_currency: 'STX', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'ADA', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'LINK', exchange_id: 'binance', quote_currency: 'USDT' },
  { base_currency: 'XRP', exchange_id: 'binance', quote_currency: 'USDT' },
];
