import { useState } from 'react';
import { PushTokenInfo } from 'types/app';

export const useComponentState = () => {
  const [pushTokenInfo, setPushTokenInfo] = useState<PushTokenInfo | null>(
    null,
  );

  return {
    pushTokenInfo,
    setPushTokenInfo,
  };
};
