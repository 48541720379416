export type SubscriptionConfirmationProps = {
  data: SubscriptionConfirmationData | null;
};

export enum OnCloseActionType {
  Close = 'close',
  CloseAndGoBack = 'closeAndGoBack',
}

export type SubscriptionConfirmationData = {
  showConfirmation: boolean;
  onCloseAction: OnCloseActionType;
  message?: string;
  screen: string;
  reason: string;
};
