import ListButton from 'components/ListButton';
import { ListButtonType } from 'components/ListButton/types';
import commonStyles from '../common.module.css';
import { useComponentLogic } from './logics';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';

export const ManageAccount = () => {
  const Component = useComponentLogic();
  return (
    <div>
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Logout</div>
        <div className={commonStyles.paramValue}>
          <ListButton
            type={ListButtonType.Secondary}
            title='Logout'
            onClick={Component.logoutUser}
          />
        </div>
      </div>
      <div className={commonStyles.row}>
        <div className={commonStyles.paramName}>Delete Account</div>
        <div className={commonStyles.paramValue}>
          <ListButton
            type={ListButtonType.Secondary}
            title='Delete Account'
            onClick={() => {
              Component.showDeleteConfirmation(true);
            }}
          />
        </div>
      </div>
      <ConfirmationBottomSheet
        show={!!Component.State.showDeleteConfirmation}
        header={'Delete Account'}
        subtitle={'Delete your account & associated data.'}
        message={`Aye you sure you want to delete your account? This action is irreversible and will delete all your data. Please press confirm to delete your account.`}
        onCancel={() => {
          Component.showDeleteConfirmation(false);
        }}
        onConfim={() => {
          Component.deleteUser();
        }}
        cancelText={'Cancel'}
        confirmText={'Delete Account'}
        isLoading={Component.State.showDeleteLoader}
      />
    </div>
  );
};
