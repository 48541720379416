import AnimateStep from 'components/AnimateStep';
import styles from './stepflow.module.css';
import { StepFlowProps } from './types';

const StepFlow = (props: StepFlowProps) => {
  const { stepComponents, currentStep, doNotAnimateFirstStep = false } = props;
  return (
    <div className={styles.container}>
      {stepComponents.map((c, i) => {
        return i === currentStep ? (
          doNotAnimateFirstStep && currentStep === 0 ? (
            <div key={i}>{c}</div>
          ) : (
            <AnimateStep key={i} play={i === currentStep}>
              {c}
            </AnimateStep>
          )
        ) : null;
      })}
    </div>
  );
};

export default StepFlow;
