import { useEffect, useRef } from 'react';
import {
  BarData,
  createChart,
  HistogramData,
  IChartApi,
  ISeriesApi,
  ITimeScaleApi,
  LineData,
  LineStyle,
  LineWidth,
} from 'lightweight-charts';
import styles from './chart.module.css';
import { ChartContainerProps, PaneMapRef } from './types';
import {
  ChartPriceCandleData,
  ChartVolumeData,
  ChartLineData,
} from 'types/chart';
import { INDICATORS_INFO } from 'constants/app/indicators';
import { isDemo } from 'utils/demo';

const colorArray = [
  '#73B876',
  '#EC447D',
  '#2862FF',
  '#FF7916',
  'RGB(68, 184, 172)',
  'RGB(214, 80, 118)',
  'RGB(0, 155, 119)',
  'RGB(181, 101, 167)',
  'RGB(239, 192, 80)',
  'RGB(91, 94, 166)',
  'RGB(155, 35, 53)',
];

const ChartContainer = (props: ChartContainerProps) => {
  const { data, isBacktest = false, fetchHistory } = props;

  const firstRenderRef = useRef<boolean>(true);
  const nextPaneAddress = useRef(1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candleStickSeries = useRef<ISeriesApi<'Candlestick'> | null>(null);
  const volumeSeries = useRef<ISeriesApi<'Histogram'> | null>(null);
  const timescaleRef = useRef<ITimeScaleApi | null>(null);

  const timerRef = useRef<any>(null);
  const paneMap = useRef<PaneMapRef>({});

  const addCandleStickSeries = () => {
    if (chartRef.current) {
      // const cData: ChartPriceCandleData = data.;
      const cData: ChartPriceCandleData = data.cData;
      const minMove = data.cDataMinMove;
      if (candleStickSeries.current) {
        chartRef.current?.removeSeries(candleStickSeries.current);
      }
      candleStickSeries.current = chartRef.current.addCandlestickSeries({
        upColor: isBacktest ? '#26A69A99' : 'rgb(38,166,154)',
        downColor: isBacktest ? '#FF525299' : 'rgb(255,82,82)',
        wickUpColor: isBacktest ? '#26A69A99' : 'rgb(38,166,154)',
        wickDownColor: isBacktest ? '#FF525299' : 'rgb(255,82,82)',
        borderVisible: false,
        title: 'Price',
        lastValueVisible: true,
        priceLineVisible: false,
        priceFormat: minMove
          ? {
              minMove: minMove,
            }
          : undefined,
      });
      candleStickSeries.current.setData(cData as BarData[]);
    }
  };

  const addVolumeSeries = () => {
    const vData: ChartVolumeData | undefined = data.vData;
    if (chartRef.current && vData && vData.length) {
      if (volumeSeries.current) {
        chartRef.current?.removeSeries(
          volumeSeries.current as ISeriesApi<'Histogram'>,
        );
      }

      volumeSeries.current = chartRef.current.addHistogramSeries({
        // color: '#D1D4DC',
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
        pane: 0,
      });
      volumeSeries.current.setData(vData as HistogramData[]);
      volumeSeries.current.priceScale().applyOptions({
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        },
      });
    }
  };

  // const addTradesHistogram = (data: TradesData) => {
  //   if (chartRef.current) {
  //     let ts = chartRef.current.addHistogramSeries({
  //       title: 'P&L',
  //       priceFormat: {
  //         type: 'price',
  //         minMove: 1,
  //         precision: 0,
  //       },
  //       priceScaleId: '',
  //       scaleMargins: {
  //         top: 0.8,
  //         bottom: 0,
  //       },
  //     });
  //     const d = data.map((t) => {
  //       return {
  //         time: t.datetime,
  //         value: Math.abs(t.pnl_with_comm),
  //         color: t.pnl_with_comm > 0 ? '#b1e4c7' : '#f5d5d5',
  //       };
  //     });
  //     ts.setData(d as HistogramData[]);
  //   }
  // };

  const addIndicatorPlots = () => {
    const iData = data.iData;
    let update_address = false;
    let color_index = 0;
    const minMove = data.cDataMinMove;

    // Process each Indicator
    for (let ind in iData) {
      update_address = false;

      // Remove existing series, Check Pane Map if already plotted
      if (paneMap.current.hasOwnProperty(ind)) {
        // Remove indicator series
        paneMap.current[ind].series.forEach((s) => {
          chartRef.current?.removeSeries(s);
        });
        paneMap.current[ind].series = [];
      }
      // eslint-disable-next-line no-loop-func
      iData[ind].forEach((line, index) => {
        // @ts-ignore
        const info: any = INDICATORS_INFO[line.name];

        // Identify Pane Address
        let pane = nextPaneAddress.current;
        if (info.group === 'Overlap Studies') {
          pane = 0;
        } else {
          update_address = true;
        }

        // Check Pane Map if already plotted, else update pane address in pane map
        if (paneMap.current.hasOwnProperty(ind)) {
          // Get Pane Address
          pane = paneMap.current[ind].paneAddress;
        } else {
          paneMap.current[ind] = { paneAddress: 0, series: [] };
          paneMap.current[ind].paneAddress = pane;
        }

        if (chartRef.current) {
          if (line.output_name.includes('hist')) {
            let hSeries = chartRef.current.addHistogramSeries({
              title: `${ind} (${line.output_name})`,
              pane: pane,
              lastValueVisible: true,
              priceLineVisible: false,
              priceFormat: minMove
                ? {
                    minMove: minMove,
                    precision: minMove,
                  }
                : undefined,
            });
            const d = line.data.map((t, index) => {
              return {
                time: t.time,
                value: t.value,
                // @ts-ignore
                color:
                  // @ts-ignore
                  t.value > 0
                    ? // @ts-ignore
                      line.data[index - 1] &&
                      // @ts-ignore
                      line.data[index - 1].value > t.value
                      ? '#B2DFDB'
                      : '#26A69A'
                    : // @ts-ignore
                    line.data[index - 1] && line.data[index - 1].value > t.value
                    ? '#FF5252'
                    : '#FFCDD2',
              };
            });
            hSeries.setData(d as HistogramData[]);
            paneMap.current[ind].series.push(hSeries);
          } else {
            let iSeries = chartRef.current.addLineSeries({
              title: `${ind} (${line.output_name})`,
              lastValueVisible: true,
              priceLineVisible: false,
              priceFormat: minMove
                ? {
                    minMove: minMove,
                    precision: minMove,
                  }
                : undefined,
              color: colorArray[color_index],
              lineWidth: 1,
              pane: pane,
            });
            iSeries.setData(line.data as LineData[]);
            paneMap.current[ind].series.push(iSeries);
            if (pane !== 0) {
              var zeroLine = {
                price: 0,
                color: '#c5c5c5',
                lineWidth: 1,
                lineStyle: 2,
                axisLabelVisible: false,
                title: null,
              };
              //@ts-ignore
              iSeries.createPriceLine(zeroLine);
            }
          }
        }
        color_index++;
      });
      if (update_address) {
        nextPaneAddress.current++;
      }
    }
  };

  const addPortfolioPlot = (pData: ChartLineData[], dData: ChartLineData[]) => {
    // Add Portfolio values
    if (chartRef.current) {
      //@ts-ignore
      const isLoss = pData[pData.length - 1].value < pData[0].value;
      let pSeries = chartRef.current.addAreaSeries({
        title: 'Portfolio Value',
        priceScaleId: 'right',
        topColor: isLoss ? '#ffd3d3' : '#a1e2ce7a',
        bottomColor: isLoss ? '#9600000a' : 'rgba(0, 150, 136, 0.04)',
        lineColor: isLoss ? '#dd3434' : '#00ba7cc7',
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
        pane: nextPaneAddress.current,
      });
      pSeries.setData(pData as unknown as LineData[]);
    }

    // Add drawdown percentage values
    // if (chartRef.current) {
    //   //@ts-ignore
    //   let dSeries = chartRef.current.addLineSeries({
    //     title: 'Drawdown(%)',
    //     color: '#dd3434',
    //     lineWidth: 1,
    //     lastValueVisible: true,
    //     priceLineVisible: false,
    //     pane: 2,
    //     priceScaleId: 'right',
    //   });
    //   dSeries.setData((dData as unknown) as LineData[]);
    // }
  };

  const addChartMarkers = () => {
    var markers = [];
    if (data.oData) {
      for (var i = 0; i < data.oData.length; i++) {
        if (data.oData[i].type === 'sell') {
          markers.push({
            time: data.oData[i].ticktime ?? data.oData[i].datetime,
            position: data.oData[i].markerPosition ?? 'belowBar',
            color:
              data.oData[i].gross_profit > 0
                ? 'rgb(37, 174, 95)'
                : 'rgb(255, 82, 82)',
            shape: data.oData[i].shape ?? 'arrowUp',
            text: data.oData[i].exec_type === 'StopTrail' ? 'Sell(SL)' : 'Sell',
            size: 1,
          });
        } else {
          markers.push({
            time: data.oData[i].ticktime ?? data.oData[i].datetime,
            position: data.oData[i].markerPosition ?? 'aboveBar',
            color: 'rgb(41, 119, 189)',
            shape: data.oData[i].shape ?? 'arrowDown',
            text: 'Buy',
            size: 1,
          });
        }
      }
    }

    if (data.cEventsData) {
      for (var j = 0; j < data.cEventsData.length; j++) {
        markers.push({
          time: data.cEventsData[j].timestamp,
          position:
            data.cEventsData[j].eventType === 'strategy_stop_event'
              ? 'belowBar'
              : 'aboveBar',
          color:
            data.cEventsData[j].eventType === 'strategy_stop_event'
              ? 'rgb(255,82,82)'
              : 'rgb(38,166,154)',
          shape: 'circle',
          text:
            data.cEventsData[j].eventType === 'strategy_stop_event'
              ? 'Stopped'
              : 'Started',
          size: 0.1,
        });
      }
    }
    // @ts-ignore
    candleStickSeries.current.setMarkers(markers);
  };

  const addStopLossLine = () => {
    if (candleStickSeries.current && data.slData?.at_price) {
      const myPriceLine = {
        price: data.slData.at_price,
        color: '#D1D4DC',
        lineWidth: 1 as LineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: 'SL',
        lineVisible: true,
      };
      candleStickSeries.current.createPriceLine(myPriceLine);
    }
  };

  useEffect(() => {
    const initChart = () => {
      if (containerRef.current) {
        chartRef.current = createChart('lightweight_chart_container', {
          // @ts-ignore
          width: containerRef.current.clientWidth,
          // @ts-ignore
          height: containerRef.current.clientHeight,
          timeScale: {
            timeVisible: true,
            borderColor: '#efefef',
          },
          rightPriceScale: {
            borderColor: '#efefef',
          },
          layout: {
            background: {
              color: 'transparent',
            },
            textColor: '#929292',
          },
          grid: {
            horzLines: {
              visible: true,
              color: '#D1D4DC',
              style: LineStyle.SparseDotted,
            },
            vertLines: {
              visible: true,
              color: '#D1D4DC',
              style: LineStyle.SparseDotted,
            },
          },
          crosshair: {
            vertLine: {
              color: '#D1D4DC',
              width: 1,
              style: 1,
              visible: true,
              labelVisible: true,
            },
            horzLine: {
              color: '#D1D4DC',
              width: 1,
              style: 1,
              visible: true,
              labelVisible: true,
            },
            mode: 1,
          },
        });

        addCandleStickSeries();
        addChartMarkers();
        addStopLossLine();
        addVolumeSeries();
        // addTradesHistogram(data.tData);
        addIndicatorPlots();
        !!data.pData &&
          !!data.drawdownData &&
          addPortfolioPlot(data.pData, data.drawdownData);
        // chartRef.current.timeScale().fitContent();
      }
    };
    initChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add/update pagination subscription
  useEffect(() => {
    // Subscribe to view changes
    if (!isDemo && fetchHistory && chartRef.current) {
      timescaleRef.current = chartRef.current.timeScale();
      const handler = () => {
        if (timerRef.current !== null) {
          return;
        }
        timerRef.current = setTimeout(async () => {
          let logicalRange = timescaleRef?.current?.getVisibleLogicalRange();
          if (logicalRange) {
            let barsInfo =
              candleStickSeries?.current?.barsInLogicalRange(logicalRange);
            if (barsInfo && barsInfo?.barsBefore < 1) {
              await fetchHistory(barsInfo.from as number);
            }
          }
          timerRef.current = null;
        }, 50);
      };
      timescaleRef.current.subscribeVisibleLogicalRangeChange(handler);
      return () => {
        timescaleRef.current?.unsubscribeVisibleLogicalRangeChange(handler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.cData, data.cDataMinMove]);

  // Update chart when data changes, avoid on first render
  useEffect(() => {
    if (fetchHistory && !firstRenderRef.current) {
      // candleStickSeries.current.setData(data.cData as BarData[]);
      addCandleStickSeries();
      addChartMarkers();
      addStopLossLine();
      addIndicatorPlots();
      addVolumeSeries();
    }
    firstRenderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Resize Chart on window resize
  useEffect(() => {
    const handler = () => {
      if (containerRef?.current) {
        chartRef?.current?.resize(
          containerRef?.current?.clientWidth,
          containerRef?.current?.clientHeight,
        );
      }
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      id={'lightweight_chart_container'}
      className={styles.container}
    />
  );
};

export default ChartContainer;
