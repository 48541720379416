export const ASSISTANT_WELCOME_MESSAGE = `
Hello! I'm your Strategy Assistant, ready to assist with:

- **Defining a Trading Strategy:**
I can guide you in developing a new strategy from scratch.

- **Improving Your Strategy:** If you have an existing strategy, I can suggest improvements to enhance performance.

- **Indicator Suggestions:** Unsure which indicators to use? I can recommend ones that align with your strategy and goals.

- **Backtest Explanation:** I can interpret backtest results, discuss strategy performance, and propose enhancements.

- **Risk Management:** I can advise on risk management techniques to protect your capital and minimize losses.

Trading involves risks, and there's no profit guarantee. These tools aim to support strategy creation and risk management, but always monitor market conditions.

How may I assist you today?`;
