import { BuySellConditionFormProps } from './types';
import commonStyles from '../formsCommon.module.css';
import QueryBuilder from 'components/QueryBuilder';
import { IndicatorInfo } from 'components/QueryBuilder/types';
import { FaCheckCircle, FaCog, FaExclamationCircle } from 'react-icons/fa';
import { useMemo } from 'react';
import { MovesTypes } from 'views/Strategy/types';
import Loader from 'components/Loader';

const BuySellConditionForm = (props: BuySellConditionFormProps) => {
  const {
    buyText,
    buyCondition,
    sellText,
    sellCondition,
    updateBuyCondition,
    updateSellCondition,
    strategyIndicators,
    updateConditionFromText,
    buyCondErrorText,
    sellCondErrorText,
    isLoadingBuyCond,
    isLoadingSellCond,
    showBuyCond,
    showSellCond,
    setShowBuyCond,
    setShowSellCond,
  } = props;

  const indicators = useMemo(
    () =>
      strategyIndicators.reduce((acc, i) => {
        const si: IndicatorInfo = {
          name: i.display_name,
          outputs: [] as string[],
        };
        i.output_names.forEach((name) => {
          si.outputs.push(`${i.id}.${name}`);
        });
        acc.push(si);
        return acc;
      }, [] as IndicatorInfo[]),
    [strategyIndicators],
  );

  const updateBuyConditionFromText = (text: string) => {
    updateConditionFromText(text, indicators, MovesTypes.ENTRY);
  };

  const updateSellConditionFromText = (text: string) => {
    updateConditionFromText(text, indicators, MovesTypes.EXIT);
  };

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.header}>
          <span>Buy Condition</span>
          <span
            className={
              !isLoadingBuyCond
                ? commonStyles.headerIconContainer
                : commonStyles.loadingHeader
            }
          >
            {isLoadingBuyCond && <Loader size='SMALL' />}
            {buyCondition && showBuyCond && (
              <FaCog
                color='#00ba7c'
                className={commonStyles.headerIcon}
                onClick={() => setShowBuyCond(false)}
              />
            )}
            {buyCondition && !showBuyCond && (
              <FaCog
                color='#999999'
                className={commonStyles.headerIcon}
                onClick={() => setShowBuyCond(true)}
              />
            )}
            {buyCondition && (
              <FaCheckCircle
                color='#00ba7c'
                className={commonStyles.headerIcon}
              />
            )}
            {buyCondErrorText && (
              <FaExclamationCircle
                color='#ff4d4f'
                className={commonStyles.headerIcon}
              />
            )}
          </span>
        </div>
        <div className={commonStyles.table}>
          <div className={commonStyles.row}>
            <QueryBuilder
              indicators={indicators}
              placeholder={'Add buy expresssion here.'}
              text={buyText}
              condition={buyCondition}
              onChange={updateBuyCondition}
              onBlur={updateBuyConditionFromText}
              error={buyCondErrorText}
              showCondition={showBuyCond}
              moveType={MovesTypes.ENTRY}
            />
          </div>
        </div>
      </div>
      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.header}>
          <span>Sell Condition</span>
          <span
            className={
              !isLoadingSellCond
                ? commonStyles.headerIconContainer
                : commonStyles.loadingHeader
            }
          >
            {isLoadingSellCond && <Loader size='SMALL' />}
            {sellCondition && showSellCond && (
              <FaCog
                color='#00ba7c'
                className={commonStyles.headerIcon}
                onClick={() => setShowSellCond(false)}
              />
            )}
            {sellCondition && !showSellCond && (
              <FaCog
                color='#999999'
                className={commonStyles.headerIcon}
                onClick={() => setShowSellCond(true)}
              />
            )}
            {sellCondition && (
              <FaCheckCircle
                color='#00ba7c'
                className={commonStyles.headerIcon}
              />
            )}
            {sellCondErrorText && (
              <FaExclamationCircle
                color='#ff4d4f'
                className={commonStyles.headerIcon}
              />
            )}
          </span>
        </div>
        <div className={commonStyles.table}>
          <div className={commonStyles.row}>
            <QueryBuilder
              indicators={indicators}
              placeholder={'Add sell expresssion here.'}
              text={sellText}
              condition={sellCondition}
              onChange={updateSellCondition}
              onBlur={updateSellConditionFromText}
              error={sellCondErrorText}
              showCondition={showSellCond}
              moveType={MovesTypes.EXIT}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySellConditionForm;
