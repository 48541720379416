import { useState } from 'react';
import { PricingTableData } from 'types/subscriptions';

export const useComponentState = () => {
  const [products, setProducts] = useState<PricingTableData>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  return {
    products,
    setProducts,
    isLoadingProducts,
    setIsLoadingProducts,
    isLoadingCheckout,
    setIsLoadingCheckout,
  };
};
