import { TagProps } from './types';
import styles from './tag.module.css';

const Tag = (props: TagProps) => {
  const { text, bg, color } = props;
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: bg,
        color,
      }}
    >
      {text}
    </div>
  );
};

export default Tag;
