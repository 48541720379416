import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CodeViewProps } from './types';

const CodeEditor = (props: CodeViewProps) => {
  const { value } = props;
  return (
    <SyntaxHighlighter
      language='python'
      style={lightfair}
      customStyle={{
        borderRadius: '6px',
        background: '#f6f8fc',
        lineHeight: '18px',
        padding: '12px 8px',
        fontFamily: 'SourceSansPro',
      }}
    >
      {value}
    </SyntaxHighlighter>
  );
};

export default CodeEditor;
