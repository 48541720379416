import StepFlow from 'components/StepFlow';
import { useState } from 'react';
import { useDisplayType } from 'utils/display';
import { IndicatorsList } from './components/IndicatorsList';
import { News } from './components/News';
import SymbolList from './components/SymbolList';
import WatchlistMenu from './components/WatchlistMenu';
import { WatchlistMenuItem } from './components/WatchlistMenu/types';
import { WatchlistListProps } from './types';

const Watchlist = (props: WatchlistListProps) => {
  const {
    onSelectItem,
    list,
    toggleIndicator,
    selectedIndicators,
    setNewsSymbol,
  } = props;

  const menuItems: WatchlistMenuItem[] = [
    {
      id: 'watchlist',
      index: 1,
      name: 'Watchlist',
    },
    {
      id: 'news',
      index: 0,
      name: 'News',
    },
    {
      id: 'indicators',
      index: 2,
      name: 'Indicators',
    },
  ];

  const [selectedMenuItem, selectMenuItem] = useState<WatchlistMenuItem>(
    menuItems[0],
  );

  return (
    <div>
      <WatchlistMenu
        menuItems={menuItems}
        selected={selectedMenuItem}
        onSelect={selectMenuItem}
      />
      <StepFlow
        stepComponents={[
          <News setNewsSymbol={setNewsSymbol} />,
          <SymbolList onSelectItem={onSelectItem} list={list} />,
          <IndicatorsList
            onSelectIndicator={toggleIndicator}
            selectedIndicators={selectedIndicators}
          />,
        ]}
        currentStep={selectedMenuItem.index}
        doNotAnimateFirstStep={true}
      />
    </div>
  );
};

export default Watchlist;
