import styles from './radio.module.css';
import { RadioButtonProps } from './types';

const RadioButton = (props: RadioButtonProps) => {
  const { selected, text, description, onSelect, showOnSelect } = props;
  return (
    <div className={styles.container} onClick={onSelect}>
      <div className={styles.radioContainer}>
        <div className={selected ? styles.selectedRadio : styles.radio}>
          {selected ? <div className={styles.selectedRing} /> : null}
        </div>
        <div className={styles.text}>{text}</div>
      </div>
      <div className={styles.description}>{description}</div>
      {selected ? (
        <div className={styles.showWhenSelected}>{showOnSelect}</div>
      ) : null}
    </div>
  );
};

export default RadioButton;
