import { useComponentLogic } from './logics';
import styles from './markets.module.css';
import TradingViewSymbolInfo from 'components/TradingViewSymbolInfo';
import MarketDropdown from './components/MarketDropdown';
import TradingViewChart from 'components/TradingViewChart';
import { useDisplayType } from 'utils/display';
import PushButton from 'components/PushButton';
import { AiOutlineStar, AiFillStar, AiOutlineRocket } from 'react-icons/ai';
import { PushButtonType } from 'components/PushButton/types';
import { useHistory } from 'react-router-dom';
import Watchlist from './components/Watchlist';
import PageHeader from 'components/PageHeader';
import { Analytics } from 'utils/analytics';
import {
  click_create_strategy,
  page_markets,
} from 'constants/app/analyticsEvents';

function Markets() {
  const Component = useComponentLogic();
  const { isDesktop } = useDisplayType();
  const history = useHistory();
  const chartSymbol = Component.State.newsSymbol
    ? Component.State.newsSymbol
    : `${Component.State.exchange.toUpperCase()}:${Component.State.symbol.replace(
        '/',
        '',
      )}`;
  const showChart = Component.State.showChartOnMobile || isDesktop;

  return (
    <div className={styles.container}>
      <div className={styles.graphContainer}>
        {!Component.State.showChartOnMobile && (
          <div
            className={
              isDesktop
                ? styles.sideBarContainer
                : styles.sideBarContainerMobile
            }
          >
            <div
              className={
                isDesktop
                  ? styles.sideBarContents
                  : styles.sideBarContentsMobile
              }
            >
              <div
                className={
                  isDesktop
                    ? styles.tvSymbolInfoContainer
                    : styles.tvSymbolInfoContainerMobile
                }
              >
                <TradingViewSymbolInfo
                  tvSymbol={chartSymbol}
                  toggleFullChart={Component.State.setShowChart}
                />
              </div>
              <MarketDropdown
                symbol={Component.State.symbol.replace('/', '')}
                exchange={Component.State.exchange}
                selectExchange={Component.selectExchange}
                symbols={Component.exchangeSymbols}
                selectSymbol={Component.selectSymbol}
              />
              <div className={styles.actionsContainer}>
                <PushButton
                  title={Component.symbolIsInWatchlist ? 'Unwatch' : 'Watch'}
                  onClick={
                    Component.symbolIsInWatchlist
                      ? Component.removeFromWatchlist
                      : Component.addToWatchlist
                  }
                  icon={
                    Component.symbolIsInWatchlist ? (
                      <AiFillStar />
                    ) : (
                      <AiOutlineStar />
                    )
                  }
                  type={PushButtonType.Secondary}
                />
                <PushButton
                  additionalClassName={styles.actionButton}
                  title={'Create Strategy'}
                  onClick={() => {
                    Analytics.track(click_create_strategy, {
                      screen: page_markets,
                    });
                    history.push('/app/strategies/strategy/configure');
                  }}
                  icon={<AiOutlineRocket />}
                />
              </div>
              <Watchlist
                list={Component.watchlist}
                setNewsSymbol={Component.State.setNewsSymbol}
                onSelectItem={Component.selectFromWatchlist}
                toggleIndicator={Component.toggleIndicator}
                selectedIndicators={Component.State.indicators}
              />
            </div>
          </div>
        )}
        {showChart && (
          <div className={styles.chartContainer}>
            {!isDesktop && (
              <PageHeader
                title={'Back'}
                subTitle={chartSymbol}
                showBackButton={true}
                customActionOnBack={() => {
                  Component.State.setShowChart(false);
                }}
              />
            )}
            <div className={styles.chart}>
              <TradingViewChart
                exchangeSymbol={chartSymbol}
                allow_symbol_change={false}
                studies={Component.State.indicators.map((i) => i.value)}
                hide_legend={false}
                hide_top_toolbar={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Markets;
