import { useEffect, useRef } from 'react';
import {
  createChart,
  CrosshairMode,
  IChartApi,
  ISeriesApi,
  ITimeScaleApi,
  LineData,
  LineStyle,
} from 'lightweight-charts';
import styles from './chart.module.css';
import { PortfolioChartProps } from './types';
import { ChartLineData } from 'types/chart';
import { formatPortfolioChartData } from 'utils/formatters/ohlcv';
import { isDemo } from 'utils/demo';

const PortfolioChart = (props: PortfolioChartProps) => {
  const { pData, fetchHistory } = props;

  const firstRenderRef = useRef<boolean>(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const pSeriesRef = useRef<ISeriesApi<'Line'> | null>(null);
  const timescaleRef = useRef<ITimeScaleApi | null>(null);
  const timerRef = useRef<any>(null);

  const addPortfolioPlot = (pData?: ChartLineData) => {
    if (chartRef.current && !!pData) {
      const portfolioChartData = formatPortfolioChartData(pData);
      if (pSeriesRef.current) {
        chartRef.current?.removeSeries(pSeriesRef.current);
      }
      //@ts-ignore
      pSeriesRef.current = chartRef.current.addLineSeries({
        // title: 'Portfolio Value',
        priceScaleId: 'right',
        color: '#00ba7c',
        lineWidth: 1,
        lastValueVisible: true,
        priceLineVisible: false,
        autoscaleInfoProvider: () => {
          return {
            priceRange: {
              minValue: portfolioChartData.low,
              maxValue: portfolioChartData.high,
            },
          };
        },
      });
      pSeriesRef.current.setData(
        portfolioChartData.pData as unknown as LineData[],
      );
      if (isDemo) {
        chartRef.current.timeScale().fitContent();
      }

      chartRef.current.timeScale().applyOptions({
        timeVisible: false,
        secondsVisible: false,
      });
      chartRef.current.priceScale('right').applyOptions({
        autoScale: true,
      });
    }
  };

  useEffect(() => {
    const initChart = () => {
      if (containerRef.current) {
        chartRef.current = createChart('lightweight_chart_container', {
          // @ts-ignore
          width: containerRef.current.clientWidth,
          // @ts-ignore
          height: containerRef.current.clientHeight,
          timeScale: {
            timeVisible: true,
            borderColor: '#FFF',
          },
          rightPriceScale: {
            borderColor: '#FFF',
          },
          layout: {
            background: {
              color: 'transparent',
            },
            textColor: '#929292',
          },
          grid: {
            horzLines: {
              visible: true,
              color: '#D1D4DC',
              style: LineStyle.SparseDotted,
            },
            vertLines: {
              visible: true,
              color: '#D1D4DC',
              style: LineStyle.SparseDotted,
            },
          },
          crosshair: {
            vertLine: {
              color: '#D1D4DC',
              width: 1,
              style: 1,
              visible: true,
              labelVisible: true,
            },
            horzLine: {
              color: '#D1D4DC',
              width: 1,
              style: 1,
              visible: true,
              labelVisible: true,
            },
            mode: CrosshairMode.Magnet,
          },
        });

        if (pData) {
          addPortfolioPlot(pData);
        }
      }
    };
    initChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add/update pagination subscription
  useEffect(() => {
    // Subscribe to view changes
    if (!isDemo && fetchHistory && chartRef.current) {
      timescaleRef.current = chartRef.current.timeScale();
      const handler = () => {
        if (timerRef.current !== null) {
          return;
        }
        timerRef.current = setTimeout(async () => {
          let logicalRange = timescaleRef?.current?.getVisibleLogicalRange();
          if (logicalRange) {
            let barsInfo =
              pSeriesRef?.current?.barsInLogicalRange(logicalRange);
            if (barsInfo && barsInfo?.barsBefore < 1) {
              await fetchHistory(barsInfo.from as number);
            }
          }
          timerRef.current = null;
        }, 50);
      };
      timescaleRef.current.subscribeVisibleLogicalRangeChange(handler);
      return () => {
        timescaleRef.current?.unsubscribeVisibleLogicalRangeChange(handler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pData, fetchHistory]);

  // Update chart when data changes, avoid on first render
  useEffect(() => {
    addPortfolioPlot(pData);
    firstRenderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pData]);

  // Resize Chart on window resize
  useEffect(() => {
    const handler = () => {
      if (containerRef?.current) {
        chartRef?.current?.resize(
          containerRef?.current?.clientWidth,
          containerRef?.current?.clientHeight,
        );
      }
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      id={'lightweight_chart_container'}
      className={styles.container}
    />
  );
};

export default PortfolioChart;
