import { LiveStrategyChartInfo } from 'types/chart';
import { LinkedExchange, UserExchangeKeys } from 'types/exchange';
import { AccountBalances } from 'types/portfolio';
import { IndicatorInfo } from 'types/strategies';
import { dynaomoResponseFormater } from 'utils/formatters/dynamo';
import HTTP from 'utils/http';
import {
  ExchangePairBalance,
  MarketStructure,
} from 'views/Strategy/components/MakeLiveBottomsheet/types';

export const getAllSymbols = (e: string): Promise<string[]> => {
  return HTTP.get(`/exchange/symbols/${e.toLocaleLowerCase()}`);
};

export const putExchangeCredentials = (
  keys: UserExchangeKeys,
): Promise<any> => {
  return HTTP.put(`/exchange/add`, { keys });
};

export const getLinkedExchanges = async (): Promise<LinkedExchange[]> => {
  const resp = await HTTP.get(`/exchanges`);
  return dynaomoResponseFormater(resp);
};

export const getAccountBalances = async (
  currency: string,
): Promise<AccountBalances> => {
  return HTTP.get(`/exchanges/portfolio/${currency}`);
};

export const deleteLinkedExchange = async (
  exchangeId: string,
): Promise<LinkedExchange[]> => {
  const resp = await HTTP.delete(`/exchanges/${exchangeId}`);
  return dynaomoResponseFormater(resp);
};

export const getMarketStructure = async (
  exchange: string,
  symbol: string,
): Promise<MarketStructure> => {
  return HTTP.get(`/exchange/marketStructure`, {
    params: { exchange, symbol },
  });
};

export const getPairBalance = async (
  exchange: string,
  symbol: string,
): Promise<ExchangePairBalance> => {
  return HTTP.get(`/exchange/getPairBalance`, {
    params: { exchange, symbol },
  });
};

export const getCandleSticksData = async (
  eventHash: string,
  exchange: string,
  symbol: string,
  interval: string,
  limit: number,
  since: number | undefined,
  tickSizeSec: number,
  indicators: IndicatorInfo[],
): Promise<LiveStrategyChartInfo> => {
  const data = {
    eventHash,
    exchange,
    symbol,
    interval,
    since,
    limit,
    tickSizeSec,
    indicators,
  };
  return HTTP.post(
    `${process.env.REACT_APP_CLIMBER_BASE_URL}/get-strategy-chart`,
    data,
  );
};
