import StrategyMenu from '../StrategyMenu';
import not_live_illustration from 'assets/svg/not_live_illustration.svg';
import NoDataMessage from 'components/NoDataDisplay';
import { LiveRunProps } from './types';
import styles from './liverun.module.css';
import { useDisplayType } from 'utils/display';
import PushButton from 'components/PushButton';
import {
  AiOutlineRocket,
  AiFillStop,
  AiOutlineLineChart,
} from 'react-icons/ai';
import { PushButtonType } from 'components/PushButton/types';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';
import ChartContainer from 'components/ChartContainer';
import { TradingModeTypes } from '../MakeLiveBottomsheet/types';
import { useComponentLogic } from './logics';
import TimelineList from 'components/TimelineList';
import { TimelineEventTypes } from 'components/TimelineList/types';
import { readableLocalTime } from 'utils/dateTime';
import StrategyAllocations from 'components/StrategyAllocations';
import Loader from 'components/Loader';
import { MakeLiveBottomsheet } from '../MakeLiveBottomsheet';
import { StrategyHeader } from '../StrategyHeader';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';
import { page_visit_str_live } from 'constants/app/analyticsEvents';

const LiveRun = (props: LiveRunProps) => {
  const {
    saveOrUpdate,
    stopLiveStrategy,
    selectedStrategy,
    updateMarketStructure,
  } = props;

  const { isDesktop, isMobile } = useDisplayType();
  const Component = useComponentLogic(props);
  const { strategyState, fetchLiveChartData } = Component;

  const {
    tradingMode,
    liveChartData,
    basicParams,
    liveStrategyEvents: timelineEvents,
    loadingStrategy,
    isLoadingLiveHistory,
    isLoadingTimeline,
    summary,
    currentPrice,
    marketStructure,
    isLoadingMarketStructure,
    isLoadingStrategyStateChange,
  } = strategyState;

  const showChartsOnMobile = Component.State.showChartsOnMobile;
  const showChart = isDesktop || (isMobile && showChartsOnMobile);

  const defaultEvents = [];
  if (summary?.timelogs?.next_run_timestamp) {
    defaultEvents.push({
      timestamp: summary?.timelogs?.next_run_timestamp as number,
      eventType: TimelineEventTypes.RUN_ALERT,
      data: `Next run is scheduled at ${readableLocalTime(
        new Date((summary?.timelogs?.next_run_timestamp as number) * 1000),
      )}`,
    });
  }

  if (summary?.timelogs?.last_run_timestamp) {
    defaultEvents.push({
      timestamp: summary?.timelogs?.last_run_timestamp as number,
      eventType: TimelineEventTypes.RUN_ALERT,
      data: `Last run at ${readableLocalTime(
        new Date((summary?.timelogs?.last_run_timestamp as number) * 1000),
      )}`,
    });
  }

  const initial_portfolio_value =
    summary?.summary?.initial_portfolio_value ?? 0;

  const final_portfolio_value = summary?.summary?.final_portfolio_value ?? 0;

  const pnl_gross = summary?.summary?.pnl_gross ?? 0;
  const commissions = summary?.summary?.commissions ?? 0;
  const pnl_net = summary?.summary?.pnl_net ?? 0;
  const quote_currency = basicParams.symbol.split('/')[1];

  const summaryInfo = [
    {
      name: 'Initial Value',
      value: initial_portfolio_value,
      currency: quote_currency,
    },
    {
      name: 'Current Value',
      value: final_portfolio_value,
      color:
        final_portfolio_value >= initial_portfolio_value
          ? '#27ad5f'
          : '#ff6868',
      currency: quote_currency,
    },
    {
      name: 'Gross P&L',
      value: pnl_gross,
      color: pnl_gross >= 0 ? '#27ad5f' : '#ff6868',
      currency: quote_currency,
    },
    {
      name: 'Fees',
      value: commissions,
      color: '#ff6868',
      currency: quote_currency,
    },
    {
      name: 'Net P&L',
      value: pnl_net,
      color: pnl_net >= 0 ? '#27ad5f' : '#ff6868',
      currency: quote_currency,
    },
    {
      name: 'Returns',
      value: initial_portfolio_value
        ? ((pnl_net / initial_portfolio_value) * 100).toFixed(2) + '%'
        : '0',
      color: pnl_net >= 0 ? '#27ad5f' : '#ff6868',
    },
  ];

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_str_live);
  }, []);

  return tradingMode || timelineEvents.length ? (
    <div className={styles.container}>
      {!showChartsOnMobile && (
        <div
          className={
            isDesktop ? styles.resultsContainer : styles.resultsContainerMobile
          }
        >
          <StrategyMenu
            selectedStrategy={selectedStrategy}
            showBackButton={true}
          />
          <div
            className={
              isDesktop
                ? styles.liveDetailsContainer
                : styles.liveDetailsContainerMob
            }
            onScroll={Component.handleScrollEnd}
          >
            <StrategyHeader
              symbolsInfo={selectedStrategy.symbols_info}
              interval={selectedStrategy.strategy.interval}
              tradingMode={tradingMode}
            />
            <StrategyAllocations
              allocations={summary?.allocations}
              symbol={selectedStrategy.symbols_info}
              currentPrice={currentPrice}
              marketStructure={marketStructure}
              refreshPrice={updateMarketStructure}
              isRefreshing={isLoadingMarketStructure}
            />

            <div className={styles.analysisContainer}>
              <div className={styles.mainHeader}>
                <span>Summary</span>
              </div>
              <div>
                {summaryInfo.map((s) => {
                  return (
                    <div className={styles.infoRow} key={s.name}>
                      <span className={styles.columnHeader}>{s.name}</span>
                      <span
                        className={styles.columnValue}
                        style={{ color: s.color }}
                      >
                        {`${s.value} ${s.currency ? s.currency : ''}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>

            <TimelineList
              isLoading={isLoadingTimeline}
              events={[...defaultEvents, ...timelineEvents]}
            />
          </div>
          {tradingMode && (
            <div className={styles.actionsContainer}>
              <PushButton
                additionalClassName={styles.actionButton}
                title={'Stop'}
                onClick={() =>
                  Component.State.setShowDeactivateConfirmation(true)
                }
                icon={<AiFillStop />}
                type={PushButtonType.Grey}
              />
              {isMobile && (
                <PushButton
                  additionalClassName={styles.seeChartButton}
                  title={'Charts'}
                  onClick={() => {
                    Component.State.setShowChartsOnMobile(true);
                  }}
                  icon={<AiOutlineLineChart />}
                  type={PushButtonType.Secondary}
                />
              )}
              {tradingMode === TradingModeTypes.Paper && (
                <PushButton
                  additionalClassName={styles.actionButton}
                  title={'Make Live'}
                  onClick={() =>
                    Component.State.setMakeLiveBottomsheetView(true)
                  }
                  icon={<AiOutlineRocket />}
                  type={PushButtonType.Primary}
                />
              )}
            </div>
          )}
          <ConfirmationBottomSheet
            show={Component.State.showDeactivateConfirmation}
            header={'Stop Strategy'}
            subtitle={'Stop your strategy temporarily.'}
            message={`Are you sure you want to stop this strategy? , You can resume it later.`}
            onCancel={() =>
              Component.State.setShowDeactivateConfirmation(false)
            }
            onConfim={() => stopLiveStrategy()}
            cancelText={'Cancel'}
            confirmText={'Confirm'}
            isLoading={isLoadingStrategyStateChange}
          />
          <MakeLiveBottomsheet
            tradingModeOptions={[TradingModeTypes.Live]}
            isBottomSheetOpen={Component.State.showMakeLiveBottomSheet}
            onDismiss={() => Component.State.setMakeLiveBottomsheetView(false)}
            basicStrategyParams={basicParams}
            onMakeLive={saveOrUpdate}
          />
        </div>
      )}
      {showChart && (
        <div className={styles.resultContainer}>
          {isMobile && (
            <StrategyMenu
              selectedStrategy={selectedStrategy}
              showBackButton={true}
              customActionOnBack={() => {
                Component.State.setShowChartsOnMobile(false);
              }}
            />
          )}
          <div className={styles.summaryContainer}>
            {isDesktop &&
              summaryInfo.map((s) => {
                return (
                  <div className={styles.infoColumn} key={s.name}>
                    <span className={styles.rowHeader}>{s.name}</span>
                    <span
                      className={styles.rowValue}
                      style={{
                        color: s.color ? s.color : '',
                      }}
                    >
                      {s.value}
                    </span>
                  </div>
                );
              })}
          </div>
          <div className={styles.chartContainer}>
            {liveChartData ? (
              <ChartContainer
                data={liveChartData}
                fetchHistory={fetchLiveChartData}
                isBacktest={false}
                isLoadingHistory={isLoadingLiveHistory}
              />
            ) : (
              <Loader containerClassName={styles.loaderContainer} />
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <>
      <StrategyMenu selectedStrategy={selectedStrategy} showBackButton={true} />
      {loadingStrategy ? null : (
        <NoDataMessage
          image={not_live_illustration}
          messageText='Please run the backest and make Strategy live to see the live run statistics.'
        />
      )}
    </>
  );
};

export default LiveRun;
