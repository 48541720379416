import { PorfolioHistoryScanItem } from 'types/portfolio';
import { StrategySymbolsInfo } from 'types/strategies';
import { UserSettings } from 'types/user';
import HTTP from 'utils/http';

export const updateUserWatchlist = (
  watchlist: StrategySymbolsInfo[],
): Promise<any> => {
  const data = {
    data: watchlist,
  };
  return HTTP.put(`/user/watchlist`, { data });
};

export const getUserWatchlist = async (): Promise<StrategySymbolsInfo[]> => {
  return HTTP.get(`/user/watchlist`);
};

export const getUserSettings = async (): Promise<UserSettings> => {
  return HTTP.get(`/user/settings`);
};

export const putUserSettings = (settings: UserSettings): Promise<any> => {
  const data = {
    data: settings,
  };
  return HTTP.put(`/user/settings`, { data });
};

export const updateUserPushToken = (
  pushToken: string,
  deviceType: string,
): Promise<any> => {
  return HTTP.put(`/user/pushToken`, {
    pushToken,
    deviceType,
  });
};

export const getUserPortfolioHistory = async (
  limit: number,
  last: number | undefined,
): Promise<PorfolioHistoryScanItem[]> => {
  const params = {
    limit,
    last,
  };
  const result: any[] = await HTTP.get(`/user/portfolioHistory`, {
    params,
  });
  const resp = result.map((p, i) => {
    return p.data as PorfolioHistoryScanItem;
  });
  return resp;
};

export const deleteUserAccount = (): Promise<any> => {
  return HTTP.delete(`/user/delete`);
};
