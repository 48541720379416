import { useState } from 'react';
import { IndicatorsListProps } from './types';
import styles from './indicatorsList.module.css';
import { TRADINGVIEW_INDICATOR_ID_MAP } from 'constants/app/indicators';
import { InputTypes } from 'components/TextInput/types';
import TextInput from 'components/TextInput';
import { AiFillCheckCircle } from 'react-icons/ai';
import { SelectOption } from 'types/app';

export const IndicatorsList = (props: IndicatorsListProps) => {
  const { onSelectIndicator, selectedIndicators } = props;
  const [searchQuery, setSearchQuery] = useState('');

  // Filter indicators based on search query
  const filteredIndicators = TRADINGVIEW_INDICATOR_ID_MAP.filter((indicator) =>
    indicator.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleSearchInputChange = (s: string) => {
    setSearchQuery(s);
  };

  const selectedVals = selectedIndicators.map((i) => i.value);

  const onSelect = (indicator: SelectOption) => {
    onSelectIndicator(indicator);
    setSearchQuery('');
  };

  return (
    <div className={styles.listContainer}>
      <TextInput
        type={InputTypes.TEXT}
        label={''}
        value={searchQuery}
        placeholder={'Search here..'}
        onChange={handleSearchInputChange}
        inputClass={styles.textInput}
      />
      {!searchQuery &&
        selectedIndicators.map((indicator, i) => (
          <div
            key={i}
            className={styles.itemContainer}
            onClick={() => onSelectIndicator(indicator)}
          >
            <div className={styles.text}>{indicator.label}</div>
            {selectedVals.includes(indicator.value) ? (
              <AiFillCheckCircle className={styles.selectedIcon} />
            ) : null}
          </div>
        ))}
      {filteredIndicators.length > 0 ? (
        filteredIndicators.map((indicator, i) => (
          <div
            key={i}
            className={styles.itemContainer}
            onClick={() => onSelect(indicator)}
          >
            <div className={styles.text}>{indicator.label}</div>
            {selectedVals.includes(indicator.value) ? (
              <AiFillCheckCircle className={styles.selectedIcon} />
            ) : null}
          </div>
        ))
      ) : (
        <div className={styles.noResults}>No matching indicators</div>
      )}
    </div>
  );
};
