import styles from './nodata.module.css';
import { NoDataMessageProps } from './types';

const NoDataMessage = (props: NoDataMessageProps) => {
  const { image, messageText } = props;
  return (
    <div className={styles.illustrationContainer}>
      <img
        className={styles.illustration}
        src={image}
        alt='Growing Illustration'
      />
      <div className={styles.illustrationText}>{messageText}</div>
    </div>
  );
};

export default NoDataMessage;
