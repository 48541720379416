import styles from './mainmenu.module.css';
import AppLogo from 'components/AppLogo';
import { Link, useLocation } from 'react-router-dom';
import { hideMenuPaths, menuItems } from './fixtures';
import { useCheckTabletDisplay, useDisplayType } from 'utils/display';
import { useEffect, useState } from 'react';

const MainMenu = () => {
  const location = useLocation();
  const selectedPath = location.pathname;
  const { isDesktop, isMobile } = useDisplayType();
  const { isLandscapeTablet } = useCheckTabletDisplay();
  const [hideMenu, setHideMenu] = useState(false);

  // Hide Main Menu when not required (Mobile Only).
  useEffect(() => {
    if (isMobile) {
      let show = !hideMenuPaths.some((p) => {
        return selectedPath.includes(p);
      });
      setHideMenu(!show);
    } else {
      setHideMenu(false);
    }
  }, [selectedPath, isMobile, isLandscapeTablet]);

  const shrinkMenu = isLandscapeTablet;

  return hideMenu ? null : (
    <div
      className={
        shrinkMenu
          ? styles.shrinkedContainer
          : isDesktop
          ? styles.container
          : styles.containerMobile
      }
    >
      {isDesktop && (
        <div className={styles.logoContainer}>
          <AppLogo small={shrinkMenu} />
        </div>
      )}
      <div
        className={
          shrinkMenu
            ? styles.shrinkedMenuContainer
            : isDesktop
            ? styles.menuContainer
            : styles.mobileMenuContainer
        }
      >
        {menuItems.map((m) => {
          return (
            <Link
              to={m.linkTo}
              className={`${
                isDesktop && !shrinkMenu
                  ? styles.menuItemContainer
                  : styles.menuItemContainerMobile
              } ${selectedPath.includes(m.linkTo) ? styles.selectedItem : ''}`}
              key={m.linkTo}
            >
              <span
                className={
                  selectedPath.includes(m.linkTo)
                    ? styles.selectedIconContainer
                    : styles.iconContainer
                }
              >
                {m.icon}
              </span>

              <span
                className={`${
                  isDesktop && !shrinkMenu
                    ? styles.navText
                    : styles.navTextMobile
                } ${
                  selectedPath.includes(m.linkTo) ? styles.selectedItem : ''
                }`}
              >
                {m.displayName}
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MainMenu;
