import { TimelineEventTypes, TimelineListProps } from './types';
import styles from './timeline.module.css';
import { readableLocalTime } from 'utils/dateTime';
import Loader from 'components/Loader';
import { OrderCard } from './components/OrderCard';
import { TradingModeTypes } from 'views/Strategy/components/MakeLiveBottomsheet/types';

const TimelineList = (props: TimelineListProps) => {
  const { events, isLoading } = props;
  const lastIndex = events.length - 1;
  return (
    <div className={styles.container}>
      {events.map((e, i) => {
        return (
          <div className={styles.itemContainer} key={e.timestamp}>
            <div className={styles.iconContainer}>
              <div className={styles.icon}></div>
              <div
                className={i === lastIndex ? styles.lastLine : styles.line}
              ></div>
            </div>

            <div
              className={
                e.eventType === TimelineEventTypes.ORDER
                  ? styles.contentFullWidth
                  : styles.content
              }
            >
              <div className={styles.timestamp}>
                {readableLocalTime(new Date(e.timestamp * 1000))}
              </div>
              {e.eventType === TimelineEventTypes.RUN_ALERT && (
                <div className={styles.textInfo}>{e.data}</div>
              )}
              {e.eventType === TimelineEventTypes.START_EVENT && (
                <div className={styles.textInfo}>{`Strategy was deployed in ${
                  e.data.tradingMode === TradingModeTypes.Live
                    ? 'Live Trading'
                    : 'Paper Trading'
                } mode${
                  e.data.allocations
                    ? ` with ${
                        e.data.allocations.position
                          ? `${e.data.allocations.position.quantity} ${
                              e.data.allocations.position.symbol.split('/')[0]
                            }.`
                          : `${e.data.allocations.cash.total_allocation} ${
                              e.data.allocations.cash.symbol.split('/')[1]
                            }.`
                      }`
                    : `.`
                }`}</div>
              )}
              {e.eventType === TimelineEventTypes.STOP_EVENT && (
                <div className={styles.textInfo}>{`Strategy running in ${
                  e.data.tradingMode === TradingModeTypes.Live
                    ? 'Live Trading'
                    : 'Paper Trading'
                } mode was stopped.`}</div>
              )}
              {e.eventType === TimelineEventTypes.LOG_EVENT && (
                <div
                  className={styles.textInfo}
                >{`Error: ${e.data.message}`}</div>
              )}
              {e.eventType === TimelineEventTypes.ORDER && (
                <OrderCard event={e} />
              )}
            </div>
          </div>
        );
      })}
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default TimelineList;
