import { SelectGroup, SelectOption, UseReducerAction } from 'types/app';
import { IndicatorInfo, UserStrategy } from 'types/strategies';
import { BasicStrategyParams, MovesTypes } from 'views/Strategy/types';
import { MarketStructure } from '../MakeLiveBottomsheet/types';

export interface BasicStrategyParamsInfoType {
  displayName: string;
  id: keyof BasicStrategyParams;
  inputType: string;
  selectOptions?: SelectOption[];
}

export type StrategyConfigureProps = {
  updateMarketStructure: () => void;
  deleteStrategy: () => Promise<void>;
  stopLiveStrategy: () => Promise<void>;
  duplicateStrategy: () => void;
};

export const ConfigureConfirmationActions = {
  DELETE: {
    title: 'Delete Strategy',
    subtitle: 'Delete your strategy permanently.',
    message:
      'Are you sure you want to delete this strategy and the accosiated data? This action is irreversible.',
  },
  STOP: {
    title: 'Stop Strategy',
    subtitle: 'Stop your strategy temporarily.',
    message:
      'Are you sure you want to stop this strategy? , You can resume it later.',
  },
  DUPLICATE: {
    title: 'Duplicate Strategy',
    subtitle: 'Create a copy of this strategy with same settings.',
    message: 'Are you sure you want to create a copy of this strategy?',
  },
};

export type ConfigureConfirmationActionTypes =
  typeof ConfigureConfirmationActions;
