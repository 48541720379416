export type MessageContainerProps = {
  message: string;
  type: MessageContainerType;
};

export enum MessageContainerType {
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info',
  Success = 'Success',
}
