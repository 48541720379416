import styles from './dot.module.css';
import { BlinkingDotTypes } from './types';

const BlinkingDot = (props: BlinkingDotTypes) => {
  const { isBlinking, color } = props;
  return (
    <div
      className={`${styles.dot} ${isBlinking ? styles.blinking : ''}`}
      style={{ backgroundColor: color }}
    ></div>
  );
};

export default BlinkingDot;
