import { StrategyAllocations, UserStrategy } from 'types/strategies';
import { BasicStrategyParams } from 'views/Strategy/types';

export type MakeLiveBottomsheetProps = {
  isBottomSheetOpen: boolean;
  onDismiss: () => void;
  basicStrategyParams: BasicStrategyParams;
  onMakeLive: (
    tradingMode: TradingModeTypes,
    allocations: StrategyAllocations,
  ) => Promise<void | UserStrategy>;
  tradingModeOptions: TradingModeTypes[];
};

export enum MakeLiveSteps {
  TradingMode = 0,
  Allocations = 1,
  Confirmation = 2,
}

export enum TradingModeTypes {
  Live = 'LIVE',
  Paper = 'PAPER',
}

export enum InitialAllocationTypes {
  Cash = 'CASH',
  Position = 'POSITION',
}

export type MarketStructure = {
  limits: {
    amount: { min: number; max: number };
    cost: { min: number };
    market: { min: number; max: number };
    price: { min: number; max: number };
  };
  ticker: {
    close: number;
    symbol: 'ETH/USDT';
    timestamp: number;
  };
  precision: {
    base: number;
    quote: number;
    amount: number;
    price: number;
  };
  taker: number;
  maker: number;
};

export type ExchangeSymbolBalance = {
  free: number;
  used: number;
  total: number;
};

export type ExchangePairBalance = {
  [key: string]: ExchangeSymbolBalance;
};
