import { Capacitor } from '@capacitor/core';

const platform = Capacitor.getPlatform();

let PUBLIC_URL: string;

switch (platform) {
  case 'android':
    PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL_ANDROID as string;
    break;
  case 'ios':
    PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL_IOS as string;
    break;
  default:
    PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL as string;
    break;
}

export { PUBLIC_URL };
