import styles from './strcard.module.css';
import { StrategyCardProps } from './types';
import { useDisplayType } from 'utils/display';
import { TradingModeTypes } from 'views/Strategy/components/MakeLiveBottomsheet/types';
import { PUBLIC_URL } from 'utils/environment';

export const StrategyCard = (props: StrategyCardProps) => {
  const { symbolsInfo, interval, tradingMode, onSelect, fullWidth } = props;
  const { isDesktop } = useDisplayType();
  return (
    <div
      className={
        !isDesktop || fullWidth ? styles.containerMobile : styles.container
      }
      onClick={() => (onSelect ? onSelect() : null)}
    >
      <div className={styles.contents}>
        <div className={styles.cardHeader}>
          <div className={styles.headerIcon}>
            <img
              src={`${PUBLIC_URL}/icons/crypto/${symbolsInfo.base_currency.toLocaleLowerCase()}.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${PUBLIC_URL}/icons/crypto/generic.svg`;
              }}
              width={28}
              alt={'Symbol Icon'}
            />
          </div>

          <div className={styles.headerTextContainer}>
            <div className={styles.headerText}>
              {symbolsInfo.base_currency}/{symbolsInfo.quote_currency}
              <span className={styles.statusContainer}>
                Interval: {interval}{' '}
                {tradingMode === TradingModeTypes.Live && (
                  <span className={styles.statusLive}>Live</span>
                )}
                {tradingMode === TradingModeTypes.Paper && (
                  <span className={styles.statusPaper}>Paper</span>
                )}
                {!tradingMode && (
                  <span className={styles.statusInactive}>Inactive</span>
                )}
              </span>
            </div>
            <div className={styles.headerSubText}>
              <div>Exchange: {symbolsInfo.exchange_id}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
