import { TRADING_VIEW_GRANULARITIES } from 'constants/app/app';
import { SelectOption } from 'types/app';

export type BasicStrategyParams = {
  id: string | null;
  name: string;
  initialCash: string;
  exchange: string;
  symbol: string;
  interval: keyof typeof TRADING_VIEW_GRANULARITIES;
  tz: string;
  commission: string;
  startDate: Date;
  stopLossType: SelectOption | null;
  stopLossValueType: SelectOption | null;
  stopLossValue: string;
};

export enum MovesTypes {
  ENTRY = 'entry',
  EXIT = 'exit',
}

export enum TradeTypes {
  LONG = 'long',
  SHORT = 'short',
}
