import Tag from 'components/Tag';
import { TimelineOrderEvents } from 'components/TimelineList/types';
import styles from './order.module.css';

export type OrderCardProps = {
  event: TimelineOrderEvents;
};

export const OrderCard = (props: OrderCardProps) => {
  const { event } = props;
  let bg = '';
  let color = '';
  let tagBg = '';
  let tagText = '';
  switch (event.data.type) {
    case 'buy':
      bg = 'linear-gradient(160deg, #e4f2ff, #f9f9f9 80%)';
      // bg = '#e4f2ff';
      color = '#5f5f5f';
      tagText = 'BUY';
      tagBg = '#33A4FF';
      break;

    case 'sell':
      if (event.data.gross_profit > 0)
        bg =
          'linear-gradient(160deg, rgba(161, 226, 189, 0.21), rgb(249, 249, 249) 73.53%)';
      // bg = 'rgb(37 174 95 / 18%)';
      if (event.data.gross_profit < 0)
        bg =
          'linear-gradient(160deg, rgba(255, 82, 82, 0.18), rgba(250, 205, 205, 0.34) 0%, rgb(249, 249, 249) 84.45%)';
      // bg = 'rgb(255 82 82 / 18%)';
      color = '#5f5f5f';
      tagText = 'SELL';
      if (event.data.exec_type === 'StopTrail') tagText = tagText + ' - SL';
      tagBg = event.data.gross_profit >= 0 ? '#00BA7C' : '#FF6E4E';
      break;

    default:
      break;
  }
  const [baseCurrency, quoteCurrency] = event.data.symbol.split('/');
  return (
    <div className={styles.container} style={{ color }}>
      <div className={styles.header}>
        <Tag text={tagText} bg={tagBg} color='#fff' />
      </div>
      <table className={styles.table}>
        <tbody>
          <tr className={`${styles.tableHeader} ${styles.tableRow}`}>
            <th align='left'>Qty</th>
            <td>{`${event.data.size} ${baseCurrency}`}</td>
          </tr>
          <tr className={`${styles.tableHeader} ${styles.tableRow}`}>
            <th align='left'>Avg Price</th>
            <td>{`${event.data.at_price} ${quoteCurrency}`} </td>
          </tr>
          <tr className={`${styles.tableHeader} ${styles.tableRow}`}>
            <th align='left'>Amount</th>
            <td>{`${event.data.txn_value} ${quoteCurrency}`}</td>
          </tr>
          <tr className={`${styles.tableHeader} ${styles.tableRow}`}>
            <th align='left'>Fees</th>
            <td>{`${event.data.txn_dedeuctions?.fees.fee_quote} ${event.data.txn_dedeuctions?.fees.quote_currency}`}</td>
          </tr>
          {event.data.gross_profit !== 0 && (
            <tr className={`${styles.tableHeader} ${styles.tableRow}`}>
              <th align='left'>
                {event.data.gross_profit > 0 ? 'Profit' : 'Loss'}
              </th>
              <td>{`${event.data.gross_profit} ${quoteCurrency}`}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
