import styles from './marketlist.module.css';
import Select from 'react-select';
import { MarketDropdownProps } from './types';
import './customselect.css';
import { supportedExchanges } from 'constants/app/exchange';

const MarketDropdown = (props: MarketDropdownProps) => {
  const { exchange, symbol, symbols, selectSymbol, selectExchange } = props;
  const options = symbols.map((s) => {
    return { value: s, label: s };
  });

  const exchangesOptions = supportedExchanges;

  const selectedExchange = {
    value: exchange,
    label: exchange.toUpperCase(),
  };

  const selectedSymbol = {
    value: symbol,
    label: symbol,
  };

  return (
    <div className={styles.container}>
      <Select
        value={selectedExchange}
        className={'symbolSelect'}
        classNamePrefix='headerMenuSelect'
        options={exchangesOptions}
        onChange={(s) => {
          // @ts-ignore
          if (s) selectExchange(s.value);
        }}
      />
      <Select
        value={selectedSymbol}
        className={'symbolSelect'}
        classNamePrefix='headerMenuSelect'
        options={options}
        onChange={(s) => {
          // @ts-ignore
          if (s) selectSymbol(s.value);
        }}
      />
    </div>
  );
};

export default MarketDropdown;
