import { BacktestAnalysis } from 'types/backtest';
import { BacktestDateRange } from 'types/chart';
import {
  IndicatorInfo,
  StrategyAllocations,
  UserStrategy,
} from 'types/strategies';
import {
  BacktestPerformanceHeaders,
  BacktestTradeSummaryHeaders,
} from 'views/Strategy/components/BacktestResults/components/ResultAnalysis/constants';
import { TradingModeTypes } from 'views/Strategy/components/MakeLiveBottomsheet/types';
import { BasicStrategyParams } from 'views/Strategy/types';

export const formatToUserStartegyText = (strategyObj: UserStrategy) => {
  const { strategy, symbols_info } = strategyObj;

  // Symbol and Time Frame
  const symbol = `${symbols_info.base_currency}/${symbols_info.quote_currency}`;
  const timeFrame = strategy.interval;

  // Markdown key-value format
  let markdownKeyValue = `Trading Strategy Description\n\n`;

  // Symbol and Time Frame
  markdownKeyValue += `- Symbol: ${symbol}\n`;
  markdownKeyValue += `- Time Frame: ${timeFrame}\n`;

  // Indicators with details
  strategy.indicators.forEach((indicator) => {
    console.log(indicator);
    const inputs = Object.entries(indicator.input_names)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');

    const params = Object.entries(indicator.parameters)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');

    const outputs = indicator.output_names.join(', ');
    markdownKeyValue += `- Indicator (ID: ${indicator.id}):\n  - Name: ${indicator.name}\n  - Inputs: {${inputs}}\n - Parameters: {${params}}\n - Outputs: {${outputs}}\n`;
  });

  // Buy and Sell Conditions
  markdownKeyValue += `- Buy Condition: ${strategy.buy_condition_text}\n`;
  markdownKeyValue += `- Sell Condition: ${strategy.sell_condition_text}\n`;

  // Stop Loss Info
  if (strategy.sl_info) {
    const stopLossInfo = `${strategy.sl_info.stop_loss_type} (${strategy.sl_info.stop_loss_value_type}: ${strategy.sl_info.stop_loss_value}%)`;
    markdownKeyValue += `- Stop Loss Info: ${stopLossInfo}\n`;
  } else {
    markdownKeyValue += `- Stop Loss Info: N/A\n`;
  }

  return markdownKeyValue;
};

export const formatToUserStartegyObj = (inputs: {
  userId: string;
  basicParams: BasicStrategyParams;
  indicators: IndicatorInfo[];
  buyCondition: string;
  buyConditionText: string;
  sellCondition: string;
  sellConditionText: string;
  tradingMode?: TradingModeTypes;
  allocations?: StrategyAllocations;
}): UserStrategy => {
  const {
    basicParams,
    indicators,
    buyCondition,
    buyConditionText,
    sellCondition,
    sellConditionText,
    userId,
    tradingMode,
    allocations,
  } = inputs;

  const symbolSplit = basicParams.symbol.split('/');
  var data: UserStrategy = {
    userId: userId,
    // @ts-ignore
    id: basicParams.id,
    strategy_type: 'DynamicIndicators',
    cron_interval: basicParams.interval,
    bt_parameters: {
      initial_cash: Number(basicParams.initialCash),
      start_date_time: `${basicParams.startDate.toLocaleDateString()} ${basicParams.startDate.toLocaleTimeString()}`,
      commission: Number(basicParams.commission),
    },
    symbols_info: {
      exchange_id: basicParams.exchange,
      base_currency: symbolSplit[0],
      quote_currency: symbolSplit[1],
    },
    strategy: {
      name: basicParams.name,
      interval: basicParams.interval,
      tz: 'utc', // hardcoded for crypto startegies
      indicators: indicators,
      buy_condition: buyCondition,
      buy_condition_text: buyConditionText,
      sell_condition: sellCondition,
      sell_condition_text: sellConditionText,
      fractional_sizing: true,
      sl_info: {
        stop_loss_type: basicParams.stopLossType?.value,
        stop_loss_value_type: basicParams.stopLossValueType?.value,
        stop_loss_value: Number(basicParams.stopLossValue),
      },
    },
    is_active: tradingMode ? 'YES' : undefined,
    tradingMode: tradingMode ? tradingMode : undefined,
    // Alocations are extraced and deleted form strategy before saving.
    allocations: allocations ? allocations : undefined,
  };

  return data;
};

export const formatToBacktestText = (
  analysisData: BacktestAnalysis,
  backtestDateRange?: BacktestDateRange,
) => {
  let markdownKeyValue = `Backtest Results for this strategy:\n\n`;
  if (backtestDateRange) {
    markdownKeyValue += `From: ${backtestDateRange.start.toLocaleDateString()} To: ${backtestDateRange.end.toLocaleDateString()}\n\n`;
  }
  const summaryInfo = !!analysisData
    ? [
        {
          name: 'Initial Value',
          value: analysisData?.initial_portfolio_value.toFixed(2),
        },
        {
          name: 'Current Value',
          value: analysisData.final_portfolio_value.toFixed(2),
        },
        {
          name: 'Gross P&L',
          value: analysisData.trade_analysis.pnl_gross.toFixed(2),
        },
        {
          name: 'Fees',
          value: analysisData.trade_analysis.commissions.toFixed(2),
        },
        {
          name: 'Net P&L',
          value: analysisData.trade_analysis.pnl_net.toFixed(2),
        },
        {
          name: 'Returns',
          value:
            (
              (analysisData.trade_analysis.pnl_net /
                analysisData.initial_portfolio_value) *
              100
            ).toFixed(2) + '%',
        },
      ]
    : [];

  summaryInfo.forEach((info) => {
    markdownKeyValue += `- ${info.name}: ${info.value}\n`;
  });

  Object.keys(BacktestTradeSummaryHeaders).forEach((k) => {
    // @ts-ignore
    markdownKeyValue += `- ${BacktestTradeSummaryHeaders[k]}: ${analysisData.trade_analysis[k]}\n`;
  });

  Object.keys(BacktestPerformanceHeaders).forEach((k) => {
    // @ts-ignore
    markdownKeyValue += `- ${BacktestPerformanceHeaders[k]}: ${analysisData.trade_analysis[k]}\n`;
  });

  markdownKeyValue += `- SQN: ${analysisData.sqn}\n`;
  markdownKeyValue += `- Max Drawdown: ${`${analysisData.max_dd.value} (${analysisData.max_dd.percentage}%)`}\n`;

  return markdownKeyValue;
};
