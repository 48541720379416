import PushButton from 'components/PushButton';
import TextInput from 'components/TextInput';
import { InputTypes } from 'components/TextInput/types';
import sharedStyles from '../shared.module.css';
import inputStyles from 'components/TextInput/styleVariants/loginInputs.module.css';
import { OnboardingSteps } from 'views/Login/types';
import { SignupProps } from './types';
import { useDisplayType } from 'utils/display';
import { useEffect } from 'react';
import { Analytics } from 'utils/analytics';
import { page_visit_signup } from 'constants/app/analyticsEvents';
import { PushButtonType } from 'components/PushButton/types';
import { FaFacebook, FaGoogle } from 'react-icons/fa';

export const SignUp = (props: SignupProps) => {
  const {
    updateStep,
    signupFormValues,
    updateSignupForm,
    onSubmit,
    onLoginWithFacebook,
    onLoginWithGoogle,
  } = props;
  const { isDesktop } = useDisplayType();

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_signup);
  }, []);

  return (
    <div
      className={
        isDesktop ? sharedStyles.container : sharedStyles.mobileContainer
      }
    >
      <div
        className={
          isDesktop ? sharedStyles.formContainer : sharedStyles.mFormContainer
        }
      >
        <div className={sharedStyles.header}>
          <span className={sharedStyles.headerText}>Sign Up</span>
          <span className={sharedStyles.welcomeText}>Welcome!</span>
          <span className={sharedStyles.welcomeText}>
            Sign up to manage your Portfolio & Strategies.
          </span>
        </div>
        <div className={sharedStyles.socialContainer}>
          <PushButton
            type={PushButtonType.Primary}
            additionalClassName={sharedStyles.socialButton}
            icon={<FaFacebook />}
            title={'Login with Facebook'}
            onClick={() => {
              onLoginWithFacebook();
            }}
          />
          <PushButton
            type={PushButtonType.Primary}
            additionalClassName={sharedStyles.socialButton}
            icon={<FaGoogle />}
            title={'Login with Google'}
            onClick={() => {
              onLoginWithGoogle();
            }}
          />
        </div>
        <div className={sharedStyles.subSectionNote}>
          <span className={sharedStyles.welcomeText}>
            Sign-in with your e-mail & password:
          </span>
        </div>
        <div className={sharedStyles.inputsContainer}>
          <TextInput
            type={InputTypes.TEXT}
            label={'E-mail'}
            value={signupFormValues.email}
            placeholder={'Enter your E-mail'}
            onChange={(s) =>
              updateSignupForm({
                type: 'email',
                value: s,
              })
            }
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
          <TextInput
            type={InputTypes.PASSWORD}
            label={'Password'}
            value={signupFormValues.password}
            placeholder={'Enter your password'}
            onChange={(s) =>
              updateSignupForm({
                type: 'password',
                value: s,
              })
            }
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
          <TextInput
            type={InputTypes.PASSWORD}
            label={'Verify Password'}
            value={signupFormValues.rePassword}
            placeholder={'Re-Enter your password'}
            onChange={(s) =>
              updateSignupForm({
                type: 'rePassword',
                value: s,
              })
            }
            inputClass={inputStyles.textInput}
            labelClass={inputStyles.label}
          />
        </div>
        <div className={sharedStyles.mainActionContainer}>
          <div className={sharedStyles.actionContainer}>
            <div className={sharedStyles.actionText}>
              Already Registered?{' '}
              <span
                className={sharedStyles.actionLink}
                onClick={() => updateStep(OnboardingSteps.SignIn)}
              >
                Sign In here.
              </span>
            </div>
          </div>
          <PushButton
            disabled={signupFormValues.submittingForm}
            additionalClassName={sharedStyles.submitButton}
            title={
              !signupFormValues.submittingForm ? 'Sign Up' : 'Signing Up...'
            }
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};
