import styles from './watchlist.module.css';
import { WatchlistMenuItem, WatchlistMenuProps } from './types';
import { Analytics } from 'utils/analytics';
import {
  click_markets_tab_prefix,
  page_markets,
} from 'constants/app/analyticsEvents';

const WatchlistMenu = (props: WatchlistMenuProps) => {
  const { menuItems, selected, onSelect } = props;

  const onSelectMenuItem = (i: WatchlistMenuItem) => {
    onSelect(i);
    Analytics.track(`${click_markets_tab_prefix} ${i.name}`, {
      screen: page_markets,
    });
  };

  return (
    <div className={styles.container}>
      {menuItems.map((i) => {
        return (
          <div
            className={`${styles.menuitem} ${
              i.id === selected.id ? styles.selected : ''
            }`}
            onClick={() => onSelectMenuItem(i)}
            key={i.id}
          >
            {i.name}
          </div>
        );
      })}
    </div>
  );
};

export default WatchlistMenu;
