export const get_user_portfiloHistory_1 = [
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 15095.6965,
      timestamp: 1704844860,
      totals: [
        {
          balance: 0.0851,
          id: 'bitcoin',
          name: 'Bitcoin',
          symbol: 'BTC',
          usdPrice: 63125,
          amount: 5371.9375,
        },
        {
          balance: 1.752,
          id: 'ethereum',
          name: 'Ethereum',
          symbol: 'ETH',
          usdPrice: 3059,
          amount: 5359.368,
        },
        {
          balance: 52.35407589,
          id: 'tether',
          name: 'Tether',
          symbol: 'USDT',
          usdPrice: 83.37,
          amount: 4364.7593069493,
        },
      ],
    },
    timestamp: 1704844860,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 15370.350161083064,
      timestamp: 1704758465,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 15369.714029486586,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 47569.329268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6361315964780487,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0139024390243903,
        },
      ],
    },
    timestamp: 1704758465,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14405.172817771267,
      timestamp: 1704672063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14404.53546196195,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44582.09756097561,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6373558093170731,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0158536585365854,
        },
      ],
    },
    timestamp: 1704672063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14417.871740944249,
      timestamp: 1704585662,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14417.23492072805,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44621.40243902439,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6368202162,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0150000000000001,
        },
      ],
    },
    timestamp: 1704585662,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14477.436961104531,
      timestamp: 1704499265,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14476.799834835121,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44805.756097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.637126269409756,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1704499265,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14491.04661998697,
      timestamp: 1704412868,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14490.409493717561,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44847.87804878049,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.637126269409756,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1704412868,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14068.25761397587,
      timestamp: 1704326467,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14067.61956954683,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 43539.34146341463,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6380444290390243,
          id: 'tether',
          balance: 0.62740908,
          price: 1.016951219512195,
        },
      ],
    },
    timestamp: 1704326467,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14747.478909686748,
      timestamp: 1704240067,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14746.841630390732,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 45641.53658536585,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6372792960146341,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0157317073170733,
        },
      ],
    },
    timestamp: 1704240067,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14474.276566335713,
      timestamp: 1704153665,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14473.639746119512,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44795.9756097561,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6368202162,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0150000000000001,
        },
      ],
    },
    timestamp: 1704153665,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13862.188812554024,
      timestamp: 1704067267,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13861.551839311222,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 42901.560975609755,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.636973242804878,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1704067267,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13837.62098388008,
      timestamp: 1703980865,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13836.984316690487,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 42825.5243902439,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6366671895951218,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0147560975609755,
        },
      ],
    },
    timestamp: 1703980865,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13802.998223170121,
      timestamp: 1703894462,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13802.361249927319,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 42718.365853658535,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.636973242804878,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1703894462,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13916.065329676863,
      timestamp: 1703808057,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13915.430957886341,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 43068.31707317073,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6343717905219511,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0110975609756097,
        },
      ],
    },
    timestamp: 1703808057,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14258.6390151539,
      timestamp: 1703721664,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14258.001123751465,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44128.57317073171,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6378914024341463,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0167073170731709,
        },
      ],
    },
    timestamp: 1703721664,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13931.564209535389,
      timestamp: 1703635262,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13930.92800142561,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 43116.28048780488,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6362081097804878,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0140243902439026,
        },
      ],
    },
    timestamp: 1703635262,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14282.716032671824,
      timestamp: 1703548866,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14282.08005410195,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44203.09756097561,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6359785698731708,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0136585365853659,
        },
      ],
    },
    timestamp: 1703548866,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14092.973183193215,
      timestamp: 1703462465,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14092.33682205683,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 43615.84146341463,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6363611363853658,
          id: 'tether',
          balance: 0.62740908,
          price: 1.014268292682927,
        },
      ],
    },
    timestamp: 1703462465,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14359.521345151927,
      timestamp: 1703376063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14358.883606776099,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44440.80487804878,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6377383758292682,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0164634146341462,
        },
      ],
    },
    timestamp: 1703376063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14444.828208972322,
      timestamp: 1703289665,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14444.190241056585,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44704.829268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6379679157365853,
          id: 'tether',
          balance: 0.62740908,
          price: 1.016829268292683,
        },
      ],
    },
    timestamp: 1703289665,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14378.84709743543,
      timestamp: 1703203259,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14378.21058327244,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44500.62195121951,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6365141629902439,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0145121951219511,
        },
      ],
    },
    timestamp: 1703203259,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14325.370956591421,
      timestamp: 1703116865,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14324.733371242197,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 44335.10975609756,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6375853492243903,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0162195121951219,
        },
      ],
    },
    timestamp: 1703116865,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13837.962868361674,
      timestamp: 1703030514,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13837.327119331707,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 42826.58536585366,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6357490299658537,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0132926829268294,
        },
      ],
    },
    timestamp: 1703030514,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13955.862952813211,
      timestamp: 1702944072,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13955.227586349756,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 43191.48780487805,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6353664634536585,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0126829268292683,
        },
      ],
    },
    timestamp: 1702944072,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13532.820086853846,
      timestamp: 1702857670,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13532.185485523416,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 41882.170731707316,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6346013304292683,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0114634146341464,
        },
      ],
    },
    timestamp: 1702857670,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13825.960223552327,
      timestamp: 1702771263,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13825.325086628782,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 42789.439024390245,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6351369235463414,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0123170731707318,
        },
      ],
    },
    timestamp: 1702771263,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 13713.65848377435,
      timestamp: 1702684869,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 13713.023729417317,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 42441.865853658535,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6347543570341462,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0117073170731707,
        },
      ],
    },
    timestamp: 1702684869,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 14123.093680197144,
      timestamp: 1702598464,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 14122.456171361222,
          id: 'bitcoin',
          balance: 0.32310134,
          price: 43709.060975609755,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.6375088359219512,
          id: 'tether',
          balance: 0.62740908,
          price: 1.0160975609756098,
        },
      ],
    },
    timestamp: 1702598464,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 11910.75040644478,
      timestamp: 1699747260,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 11910.616371884145,
          id: 'bitcoin',
          balance: 0.31555889,
          price: 37744.51219512195,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.13403456063414637,
          id: 'tether',
          balance: 0.13178458,
          price: 1.0170731707317073,
        },
      ],
    },
    timestamp: 1699747260,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 11962.87905111951,
      timestamp: 1699660869,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 11962.745161200488,
          id: 'bitcoin',
          balance: 0.31555889,
          price: 37909.70731707317,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.13388991902195124,
          id: 'tether',
          balance: 0.13178458,
          price: 1.0159756097560977,
        },
      ],
    },
    timestamp: 1699660869,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 11763.47657065881,
      timestamp: 1699574463,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 11763.342728953658,
          id: 'bitcoin',
          balance: 0.31555889,
          price: 37277.80487804878,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.13384170515121954,
          id: 'tether',
          balance: 0.13178458,
          price: 1.015609756097561,
        },
      ],
    },
    timestamp: 1699574463,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10887.54797107583,
      timestamp: 1699488063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10887.263136725365,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 36166.29268292683,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2848343504634146,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0134146341463415,
        },
      ],
    },
    timestamp: 1699488063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10823.46881571274,
      timestamp: 1699401668,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10823.183398668658,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35953.42682926829,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854170440804878,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1699401668,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10706.722843411402,
      timestamp: 1699315264,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10706.437494919512,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35565.60975609756,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28534849189024386,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1699315264,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10697.453079346655,
      timestamp: 1699228861,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10697.167867959146,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35534.81707317073,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28521138750975605,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0147560975609755,
        },
      ],
    },
    timestamp: 1699228861,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10709.799189668236,
      timestamp: 1699142462,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10709.513909728536,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35575.829268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28527993970000004,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0150000000000001,
        },
      ],
    },
    timestamp: 1699142462,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10600.953440782514,
      timestamp: 1699056060,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10600.668297947193,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35214.256097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2851428353195122,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0145121951219511,
        },
      ],
    },
    timestamp: 1699056060,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10675.3015956464,
      timestamp: 1698969659,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10675.016212878414,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35461.23170731707,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2853827679853659,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0153658536585366,
        },
      ],
    },
    timestamp: 1698969659,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10832.184169177368,
      timestamp: 1698883266,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10831.898683581097,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35982.37804878049,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854855962707317,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0157317073170733,
        },
      ],
    },
    timestamp: 1698883266,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10599.41567896724,
      timestamp: 1698796863,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10599.130090542683,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35209.14634146341,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2855884245560975,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0160975609756098,
        },
      ],
    },
    timestamp: 1698796863,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10538.368336322968,
      timestamp: 1698710467,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10538.082713622318,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35006.35365853659,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28562270065121953,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0162195121951219,
        },
      ],
    },
    timestamp: 1698710467,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10572.63512842855,
      timestamp: 1698624064,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10572.349128690854,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 35120.18292682927,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28599973769756093,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0175609756097561,
        },
      ],
    },
    timestamp: 1698624064,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10436.057626865022,
      timestamp: 1698537664,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10435.77172995561,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 34666.48780487805,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2858969094121951,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0171951219512194,
        },
      ],
    },
    timestamp: 1698537664,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10383.13825538294,
      timestamp: 1698451264,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10382.852255645244,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 34490.69512195122,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28599973769756093,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0175609756097561,
        },
      ],
    },
    timestamp: 1698451264,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10440.778232255545,
      timestamp: 1698364863,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10440.492815211464,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 34682.170731707316,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854170440804878,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1698364863,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10530.974318830065,
      timestamp: 1698278458,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10530.689038890365,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 34981.79268292683,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28527993970000004,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0150000000000001,
        },
      ],
    },
    timestamp: 1698278458,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10333.43717065449,
      timestamp: 1698192062,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10333.152370580121,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 34325.59756097561,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2848000743682927,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0132926829268294,
        },
      ],
    },
    timestamp: 1698192062,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 10091.469456378218,
      timestamp: 1698105662,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 10091.183902229755,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 33521.80487804878,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2855541484609756,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0159756097560977,
        },
      ],
    },
    timestamp: 1698105662,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 9156.426239782753,
      timestamp: 1698019261,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 9156.141028395243,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 30415.69512195122,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28521138750975605,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0147560975609755,
        },
      ],
    },
    timestamp: 1698019261,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 9145.079022136271,
      timestamp: 1697932861,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 9144.79353654,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 30378,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854855962707317,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0157317073170733,
        },
      ],
    },
    timestamp: 1697932861,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 9070.128971792861,
      timestamp: 1697846462,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 9069.84355474878,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 30129.024390243903,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854170440804878,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1697846462,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8766.062940723854,
      timestamp: 1697760065,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8765.77776361244,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 29118.951219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28517711141463414,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0146341463414634,
        },
      ],
    },
    timestamp: 1697760065,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8659.460703594807,
      timestamp: 1697673663,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8659.175217998536,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28764.829268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854855962707317,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0157317073170733,
        },
      ],
    },
    timestamp: 1697673663,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8683.091725021523,
      timestamp: 1697587259,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8682.806342253536,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28843.329268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2853827679853659,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0153658536585366,
        },
      ],
    },
    timestamp: 1697587259,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8709.850557208723,
      timestamp: 1697500865,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8709.565277269023,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28932.219512195123,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28527993970000004,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0150000000000001,
        },
      ],
    },
    timestamp: 1697500865,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8302.44606903298,
      timestamp: 1697414464,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8302.160583436707,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27578.865853658535,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854855962707317,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0157317073170733,
        },
      ],
    },
    timestamp: 1697414464,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8212.385643235419,
      timestamp: 1697328057,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8212.100191915242,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27279.69512195122,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28545132017560976,
          id: 'tether',
          balance: 0.28106398,
          price: 1.015609756097561,
        },
      ],
    },
    timestamp: 1697328057,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8212.514030285793,
      timestamp: 1697241667,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8212.228681793902,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27280.121951219513,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28534849189024386,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1697241667,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8178.901112304815,
      timestamp: 1697155260,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8178.615729536828,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27168.463414634145,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2853827679853659,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0153658536585366,
        },
      ],
    },
    timestamp: 1697155260,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8211.21787814837,
      timestamp: 1697068862,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8210.932769589146,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27275.817073170732,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28510855922439027,
          id: 'tether',
          balance: 0.28106398,
          price: 1.014390243902439,
        },
      ],
    },
    timestamp: 1697068862,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8371.40828537433,
      timestamp: 1696982463,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8371.122936882439,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27807.951219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28534849189024386,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1696982463,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8434.328046212006,
      timestamp: 1696896061,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8434.04259489183,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28016.963414634145,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28545132017560976,
          id: 'tether',
          balance: 0.28106398,
          price: 1.015609756097561,
        },
      ],
    },
    timestamp: 1696896061,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8525.393884337158,
      timestamp: 1696809664,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8525.10887860622,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28319.475609756097,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28500573093902437,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0140243902439026,
        },
      ],
    },
    timestamp: 1696809664,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8535.199566200688,
      timestamp: 1696723252,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8534.91449191756,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28352.048780487807,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2850742831292683,
          id: 'tether',
          balance: 0.28106398,
          price: 1.014268292682927,
        },
      ],
    },
    timestamp: 1696723252,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8528.536413931302,
      timestamp: 1696636863,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8528.251373924268,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28329.914634146342,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28504000703414634,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0141463414634146,
        },
      ],
    },
    timestamp: 1696636863,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8374.5873549819,
      timestamp: 1696550457,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8374.30214359439,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27818.51219512195,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28521138750975605,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0147560975609755,
        },
      ],
    },
    timestamp: 1696550457,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8491.788617119817,
      timestamp: 1696464061,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8491.503268627926,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28207.841463414636,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28534849189024386,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1696464061,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8380.134480876652,
      timestamp: 1696377668,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8379.849235213049,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27836.939024390245,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.285245663604878,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0148780487804878,
        },
      ],
    },
    timestamp: 1696377668,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8418.65224673957,
      timestamp: 1696291255,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8418.366829695487,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27964.89024390244,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854170440804878,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1696291255,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8529.666644998166,
      timestamp: 1696204863,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8529.382084856463,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 28333.670731707316,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.284560141702439,
          id: 'tether',
          balance: 0.28106398,
          price: 1.012439024390244,
        },
      ],
    },
    timestamp: 1696204863,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8233.795385398369,
      timestamp: 1696118463,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8233.510276839146,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27350.817073170732,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28510855922439027,
          id: 'tether',
          balance: 0.28106398,
          price: 1.014390243902439,
        },
      ],
    },
    timestamp: 1696118463,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8216.379465854958,
      timestamp: 1696032058,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8216.094391571829,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27292.963414634145,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2850742831292683,
          id: 'tether',
          balance: 0.28106398,
          price: 1.014268292682927,
        },
      ],
    },
    timestamp: 1696032058,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8253.164762410534,
      timestamp: 1695945664,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8252.879208262073,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 27415.158536585364,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2855541484609756,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0159756097560977,
        },
      ],
    },
    timestamp: 1695945664,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8052.558541704715,
      timestamp: 1695859254,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8052.273467421585,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 26748.768292682926,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2850742831292683,
          id: 'tether',
          balance: 0.28106398,
          price: 1.014268292682927,
        },
      ],
    },
    timestamp: 1695859254,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8015.887873096519,
      timestamp: 1695772867,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8015.602456052438,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 26626.951219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.2854170440804878,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0154878048780487,
        },
      ],
    },
    timestamp: 1695772867,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8016.011697829028,
      timestamp: 1695686466,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8015.727274791707,
          id: 'bitcoin',
          balance: 0.30103343,
          price: 26627.365853658535,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.28442303732195123,
          id: 'tether',
          balance: 0.28106398,
          price: 1.0119512195121951,
        },
      ],
    },
    timestamp: 1695686466,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7549.35127286654,
      timestamp: 1695600060,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7549.225589354877,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26569.585365853658,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12568351166341463,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0119512195121951,
        },
      ],
    },
    timestamp: 1695600060,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7652.612092259553,
      timestamp: 1695513658,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7652.486181554268,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26933.01219512195,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12591070528536585,
          id: 'tether',
          balance: 0.12419918,
          price: 1.013780487804878,
        },
      ],
    },
    timestamp: 1695513658,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7651.527485338612,
      timestamp: 1695427263,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7651.4016352182925,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26929.19512195122,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1258501203195122,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0132926829268294,
        },
      ],
    },
    timestamp: 1695427263,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7629.760010454126,
      timestamp: 1695340859,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7629.634478404878,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26852.585365853658,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12553204924878048,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0107317073170732,
        },
      ],
    },
    timestamp: 1695340859,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7809.72917006981,
      timestamp: 1695254464,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7809.603335095731,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 27485.98780487805,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12583497407804878,
          id: 'tether',
          balance: 0.12419918,
          price: 1.013170731707317,
        },
      ],
    },
    timestamp: 1695254464,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7845.606036311092,
      timestamp: 1695168063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7845.479989289634,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 27612.256097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1260470214585366,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0148780487804878,
        },
      ],
    },
    timestamp: 1695168063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7717.300438699206,
      timestamp: 1695081663,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7717.174346239023,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 27160.682926829268,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12609246018292686,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0152439024390243,
        },
      ],
    },
    timestamp: 1695081663,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7640.737522632114,
      timestamp: 1694995262,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7640.611611926829,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26891.219512195123,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12591070528536585,
          id: 'tether',
          balance: 0.12419918,
          price: 1.013780487804878,
        },
      ],
    },
    timestamp: 1694995262,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7646.517104312901,
      timestamp: 1694908867,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7646.391239046341,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26911.560975609755,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1258652665609756,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0134146341463415,
        },
      ],
    },
    timestamp: 1694908867,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7662.709069258633,
      timestamp: 1694822462,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7662.583203992072,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26968.548780487807,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1258652665609756,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0134146341463415,
        },
      ],
    },
    timestamp: 1694822462,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7634.6147389201105,
      timestamp: 1694736062,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7634.488949384756,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26869.670731707316,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12578953535365853,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0128048780487804,
        },
      ],
    },
    timestamp: 1694736062,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7536.146126453836,
      timestamp: 1694649664,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7536.020458088414,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26523.10975609756,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12566836542195123,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0118292682926828,
        },
      ],
    },
    timestamp: 1694649664,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7366.924652766323,
      timestamp: 1694563261,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7366.800044637804,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 25927.536585365855,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12460812851951218,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0032926829268292,
        },
      ],
    },
    timestamp: 1694563261,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7231.509835755354,
      timestamp: 1694476882,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7231.384212828657,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 25450.939024390245,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12562292669756098,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0114634146341464,
        },
      ],
    },
    timestamp: 1694476882,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7433.048419747539,
      timestamp: 1694390456,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7432.922721089633,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26160.256097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12569865790487805,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0120731707317072,
        },
      ],
    },
    timestamp: 1694390456,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7449.91261066756,
      timestamp: 1694304063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7449.786896863414,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26219.60975609756,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12571380414634145,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0121951219512195,
        },
      ],
    },
    timestamp: 1694304063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7454.649332087281,
      timestamp: 1694217662,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7454.5235576981695,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26236.280487804877,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12577438911219513,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0126829268292683,
        },
      ],
    },
    timestamp: 1694217662,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7562.806126780185,
      timestamp: 1694131259,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7562.6802009286575,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26616.939024390245,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12592585152682925,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0139024390243903,
        },
      ],
    },
    timestamp: 1694131259,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7428.308581687101,
      timestamp: 1694044867,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7428.182595250609,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26143.573170731706,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12598643649268296,
          id: 'tether',
          balance: 0.12419918,
          price: 1.014390243902439,
        },
      ],
    },
    timestamp: 1694044867,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7422.151072201207,
      timestamp: 1693958466,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7422.0252826658525,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26121.90243902439,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12578953535365853,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0128048780487804,
        },
      ],
    },
    timestamp: 1693958466,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7397.247526114054,
      timestamp: 1693872061,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7397.122296989634,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26034.256097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1252291244195122,
          id: 'tether',
          balance: 0.12419918,
          price: 1.0082926829268293,
        },
      ],
    },
    timestamp: 1693872061,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7444.305809666702,
      timestamp: 1693785662,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7444.180519957317,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26199.878048780487,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12528970938536585,
          id: 'tether',
          balance: 0.12419918,
          price: 1.008780487804878,
        },
      ],
    },
    timestamp: 1693785662,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7414.867133403288,
      timestamp: 1693699264,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7414.741843693902,
          id: 'bitcoin',
          balance: 0.28413035,
          price: 26096.268292682926,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.12528970938536585,
          id: 'tether',
          balance: 0.12419918,
          price: 1.008780487804878,
        },
      ],
    },
    timestamp: 1693699264,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6837.810245508622,
      timestamp: 1693612862,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6837.644072462561,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26021.231707317074,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1661730460609756,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0079268292682928,
        },
      ],
    },
    timestamp: 1693612862,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6872.698039677325,
      timestamp: 1693526457,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6872.53182642,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26154,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16621325732439024,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0081707317073172,
        },
      ],
    },
    timestamp: 1693526457,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7236.079397462556,
      timestamp: 1693440058,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7235.913083677072,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 27536.878048780487,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16631378548292683,
          id: 'tether',
          balance: 0.16486618,
          price: 1.008780487804878,
        },
      ],
    },
    timestamp: 1693440058,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7328.24770149154,
      timestamp: 1693353661,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7328.081870241218,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 27887.634146341465,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16583125032195123,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0058536585365854,
        },
      ],
    },
    timestamp: 1693353661,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6919.032346344963,
      timestamp: 1693267263,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6918.866173298902,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26330.329268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1661730460609756,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0079268292682928,
        },
      ],
    },
    timestamp: 1693267263,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6910.07879990268,
      timestamp: 1693180861,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6909.912707279145,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26296.256097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16609262353414633,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0074390243902438,
        },
      ],
    },
    timestamp: 1693180861,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6879.45285406256,
      timestamp: 1693094459,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6879.286982600975,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26179.70731707317,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16587146158536584,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0060975609756098,
        },
      ],
    },
    timestamp: 1693094459,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6889.274708393371,
      timestamp: 1693008058,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6889.108877143049,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26217.085365853658,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16583125032195123,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0058536585365854,
        },
      ],
    },
    timestamp: 1693008058,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6922.175892976926,
      timestamp: 1692921661,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6922.009820459024,
          id: 'bitcoin',
          balance: 0.26277173,
          price: 26342.29268292683,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.16607251790243902,
          id: 'tether',
          balance: 0.16486618,
          price: 1.0073170731707317,
        },
      ],
    },
    timestamp: 1692921661,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6825.829740453481,
      timestamp: 1692835264,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6825.333165314024,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 26603.475609756097,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4965751394560976,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0064634146341465,
        },
      ],
    },
    timestamp: 1692835264,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6766.758415695321,
      timestamp: 1692748862,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6766.25913294878,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 26373.219512195123,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49928274654146343,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0119512195121951,
        },
      ],
    },
    timestamp: 1692748862,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6793.481898514266,
      timestamp: 1692662463,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6792.981833570121,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 26477.378048780487,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5000649441439025,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0135365853658536,
        },
      ],
    },
    timestamp: 1692662463,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6815.812307783101,
      timestamp: 1692576063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6815.311761486585,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 26564.414634146342,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5005462965146341,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0145121951219511,
        },
      ],
    },
    timestamp: 1692576063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6788.557357714552,
      timestamp: 1692489658,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6788.057172432316,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 26458.182926829268,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5001852822365853,
          id: 'tether',
          balance: 0.49338618,
          price: 1.013780487804878,
        },
      ],
    },
    timestamp: 1692489658,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6776.327233019862,
      timestamp: 1692403260,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6775.826867230488,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 26410.51219512195,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5003657893756097,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0141463414634146,
        },
      ],
    },
    timestamp: 1692403260,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6929.817539233456,
      timestamp: 1692316857,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6929.317353951219,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 27008.780487804877,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5001852822365853,
          id: 'tether',
          balance: 0.49338618,
          price: 1.013780487804878,
        },
      ],
    },
    timestamp: 1692316857,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7475.816659410285,
      timestamp: 1692230467,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7475.316474128049,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29136.951219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5001852822365853,
          id: 'tether',
          balance: 0.49338618,
          price: 1.013780487804878,
        },
      ],
    },
    timestamp: 1692230467,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7591.962079952392,
      timestamp: 1692144059,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7591.462135346341,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29589.658536585364,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4999446060512195,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0132926829268294,
        },
      ],
    },
    timestamp: 1692144059,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7662.400414108853,
      timestamp: 1692057658,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7661.899807643292,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29864.20731707317,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.5006064655609757,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0146341463414634,
        },
      ],
    },
    timestamp: 1692057658,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7594.782653007188,
      timestamp: 1691971272,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7594.284272796341,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29600.658536585364,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49838021084634143,
          id: 'tether',
          balance: 0.49338618,
          price: 1.010121951219512,
        },
      ],
    },
    timestamp: 1691971272,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7634.655934127098,
      timestamp: 1691884859,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7634.157132732926,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29756.073170731706,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49880139417073177,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0109756097560976,
        },
      ],
    },
    timestamp: 1691884859,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7633.216706602708,
      timestamp: 1691798462,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7632.717905208537,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29750.463414634145,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49880139417073177,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0109756097560976,
        },
      ],
    },
    timestamp: 1691798462,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7626.823756605425,
      timestamp: 1691712062,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7626.32585774695,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29725.548780487807,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4978988584756098,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0091463414634145,
        },
      ],
    },
    timestamp: 1691712062,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7670.323325504749,
      timestamp: 1691625661,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7669.824945293902,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29895.09756097561,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49838021084634143,
          id: 'tether',
          balance: 0.49338618,
          price: 1.010121951219512,
        },
      ],
    },
    timestamp: 1691625661,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7716.237812288286,
      timestamp: 1691539255,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7715.739432077439,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30074.060975609755,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49838021084634143,
          id: 'tether',
          balance: 0.49338618,
          price: 1.010121951219512,
        },
      ],
    },
    timestamp: 1691539255,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7561.845160944231,
      timestamp: 1691452865,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7561.347863776219,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29472.280487804877,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4972971680121952,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0079268292682928,
        },
      ],
    },
    timestamp: 1691452865,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7515.263707720375,
      timestamp: 1691366461,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7514.766952073779,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29290.719512195123,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.496755646595122,
          id: 'tether',
          balance: 0.49338618,
          price: 1.006829268292683,
        },
      ],
    },
    timestamp: 1691366461,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7514.944815336803,
      timestamp: 1691280067,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7514.4478190140235,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29289.475609756097,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4969963227804878,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0073170731707317,
        },
      ],
    },
    timestamp: 1691280067,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7522.844802605418,
      timestamp: 1691193654,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7522.3479266207305,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29320.268292682926,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49687598468780486,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0070731707317073,
        },
      ],
    },
    timestamp: 1691193654,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7555.872848070383,
      timestamp: 1691107265,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7555.37506955,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29449,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49777852038292686,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0089024390243904,
        },
      ],
    },
    timestamp: 1691107265,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7546.677255184585,
      timestamp: 1691020858,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7546.179657171341,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29413.158536585364,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49759801324390246,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0085365853658537,
        },
      ],
    },
    timestamp: 1691020858,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7647.850256998527,
      timestamp: 1690934457,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7647.354223380487,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29807.51219512195,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4960336180390244,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0053658536585366,
        },
      ],
    },
    timestamp: 1690934457,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7520.727417302856,
      timestamp: 1690848062,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7520.2328879109755,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29312.024390243903,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4945293918804878,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0023170731707316,
        },
      ],
    },
    timestamp: 1690848062,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7535.085697419716,
      timestamp: 1690761658,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7534.590746844511,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29367.98780487805,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4949505752048781,
          id: 'tether',
          balance: 0.49338618,
          price: 1.003170731707317,
        },
      ],
    },
    timestamp: 1690761658,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7556.4989601473,
      timestamp: 1690675260,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7556.003949403049,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29451.451219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49501074425121955,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0032926829268292,
        },
      ],
    },
    timestamp: 1690675260,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7545.5983158587405,
      timestamp: 1690588866,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7545.103365283536,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29408.963414634145,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4949505752048781,
          id: 'tether',
          balance: 0.49338618,
          price: 1.003170731707317,
        },
      ],
    },
    timestamp: 1690588866,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7528.522170096767,
      timestamp: 1690502466,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7528.026617831098,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29342.40243902439,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49555226566829264,
          id: 'tether',
          balance: 0.49338618,
          price: 1.004390243902439,
        },
      ],
    },
    timestamp: 1690502466,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7529.258390306219,
      timestamp: 1690416063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7528.765004126219,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29345.280487804877,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49338618,
          id: 'tether',
          balance: 0.49338618,
          price: 1,
        },
      ],
    },
    timestamp: 1690416063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7494.838650339647,
      timestamp: 1690329663,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7494.345565004877,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29211.121951219513,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49308533476829264,
          id: 'tether',
          balance: 0.49338618,
          price: 0.999390243902439,
        },
      ],
    },
    timestamp: 1690329663,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7469.354034355779,
      timestamp: 1690243260,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7468.861851556707,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29111.79268292683,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49218279907317075,
          id: 'tether',
          balance: 0.49338618,
          price: 0.9975609756097561,
        },
      ],
    },
    timestamp: 1690243260,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7717.202852796752,
      timestamp: 1690156856,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7716.709346278659,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30077.841463414636,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4935065180926829,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0002439024390244,
        },
      ],
    },
    timestamp: 1690156856,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7644.234137648258,
      timestamp: 1690070457,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7643.740510792072,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29793.426829268294,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49362685618536584,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0004878048780488,
        },
      ],
    },
    timestamp: 1690070457,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7672.777533287316,
      timestamp: 1689984060,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7672.284147107316,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29904.682926829268,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.49338618,
          id: 'tether',
          balance: 0.49338618,
          price: 1,
        },
      ],
    },
    timestamp: 1689984060,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7652.078168332371,
      timestamp: 1689897660,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7651.584300799999,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29824,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4938675323707317,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0009756097560976,
        },
      ],
    },
    timestamp: 1689897660,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7683.913375758878,
      timestamp: 1689811264,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7683.419387888414,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29948.085365853658,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4939878704634146,
          id: 'tether',
          balance: 0.49338618,
          price: 1.001219512195122,
        },
      ],
    },
    timestamp: 1689811264,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7667.587409285419,
      timestamp: 1689724865,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7667.093541753048,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 29884.451219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4938675323707317,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0009756097560976,
        },
      ],
    },
    timestamp: 1689724865,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7741.344630985885,
      timestamp: 1689638465,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7740.850823622561,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30171.939024390245,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4938073633243902,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0008536585365853,
        },
      ],
    },
    timestamp: 1689638465,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7767.720340593312,
      timestamp: 1689552065,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7767.226232384756,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30274.743902439026,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4941082085560976,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0014634146341463,
        },
      ],
    },
    timestamp: 1689552065,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7784.171697962468,
      timestamp: 1689465667,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7783.677228739634,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30338.865853658535,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4944692228341464,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0021951219512195,
        },
      ],
    },
    timestamp: 1689465667,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7790.647981146039,
      timestamp: 1689379259,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7790.15375259939,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30364.10975609756,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4942285466487805,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0017073170731707,
        },
      ],
    },
    timestamp: 1689379259,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 8070.383029771141,
      timestamp: 1689292862,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 8069.889523253048,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 31454.451219512193,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4935065180926829,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0002439024390244,
        },
      ],
    },
    timestamp: 1689292862,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7799.783285310919,
      timestamp: 1689206456,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7799.28971862378,
          id: 'bitcoin',
          balance: 0.25655795,
          price: 30399.719512195123,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.4935666871390244,
          id: 'tether',
          balance: 0.49338618,
          price: 1.0003658536585367,
        },
      ],
    },
    timestamp: 1689206456,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7783.594620192585,
      timestamp: 1689120063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7783.214618845854,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30769.585365853658,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3800013467317074,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0048780487804878,
        },
      ],
    },
    timestamp: 1689120063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7739.714109132846,
      timestamp: 1689033660,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7739.333692736099,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30596.10975609756,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3804163967463415,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0059756097560975,
        },
      ],
    },
    timestamp: 1689033660,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7685.086241548225,
      timestamp: 1688947261,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7684.705410101464,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30380.146341463416,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.38083144676097563,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0070731707317073,
        },
      ],
    },
    timestamp: 1688947261,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7725.5311432221415,
      timestamp: 1688860857,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7725.1498967253665,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30540.036585365855,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3812464967756098,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0081707317073172,
        },
      ],
    },
    timestamp: 1688860857,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7739.415854396048,
      timestamp: 1688774467,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7739.034469549269,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30594.926829268294,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.38138484678048784,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0085365853658537,
        },
      ],
    },
    timestamp: 1688774467,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7643.0600953867915,
      timestamp: 1688688066,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7642.678433840001,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30214,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.38166154679024394,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0092682926829268,
        },
      ],
    },
    timestamp: 1688688066,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7755.680443997381,
      timestamp: 1688601663,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7755.300488767318,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30659.231707317074,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37995523006341464,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0047560975609757,
        },
      ],
    },
    timestamp: 1688601663,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7783.39534991805,
      timestamp: 1688515260,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7783.017193238049,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30768.80487804878,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37815668,
          id: 'tether',
          balance: 0.37815668,
          price: 1,
        },
      ],
    },
    timestamp: 1688515260,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7877.326198981445,
      timestamp: 1688428873,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7876.948595701464,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 31140.146341463416,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3776032799804878,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9985365853658537,
        },
      ],
    },
    timestamp: 1688428873,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7755.679060497332,
      timestamp: 1688342465,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7755.300488767318,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30659.231707317074,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3785717300146342,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0010975609756099,
        },
      ],
    },
    timestamp: 1688342465,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7746.242779515952,
      timestamp: 1688256063,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7745.864161669269,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30621.926829268294,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3786178466829268,
          id: 'tether',
          balance: 0.37815668,
          price: 1.001219512195122,
        },
      ],
    },
    timestamp: 1688256063,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7714.870432059033,
      timestamp: 1688169660,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7714.491998679025,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30497.90243902439,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3784333800097561,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0007317073170732,
        },
      ],
    },
    timestamp: 1688169660,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7713.673723778391,
      timestamp: 1688083262,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7713.295105931708,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30493.170731707316,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3786178466829268,
          id: 'tether',
          balance: 0.37815668,
          price: 1.001219512195122,
        },
      ],
    },
    timestamp: 1688083262,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7614.621087329186,
      timestamp: 1687996868,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7614.242976765854,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30101.585365853658,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37811056333170734,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9998780487804877,
        },
      ],
    },
    timestamp: 1687996868,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7759.247638030079,
      timestamp: 1687910459,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7758.869573583414,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30673.341463414636,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37806444666341465,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9997560975609756,
        },
      ],
    },
    timestamp: 1687910459,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7658.258454554314,
      timestamp: 1687824061,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7657.880205640977,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30274.09756097561,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3782489133365854,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0002439024390244,
        },
      ],
    },
    timestamp: 1687824061,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7706.180343840489,
      timestamp: 1687737666,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7705.802187160488,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30463.548780487807,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37815668,
          id: 'tether',
          balance: 0.37815668,
          price: 1,
        },
      ],
    },
    timestamp: 1687737666,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7725.848777784713,
      timestamp: 1687651262,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7725.47071333805,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30541.30487804878,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37806444666341465,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9997560975609756,
        },
      ],
    },
    timestamp: 1687651262,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7763.699060764391,
      timestamp: 1687564864,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7763.32090408439,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30690.939024390245,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37815668,
          id: 'tether',
          balance: 0.37815668,
          price: 1,
        },
      ],
    },
    timestamp: 1687564864,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7565.9403433630105,
      timestamp: 1687478466,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7565.562140566342,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 29909.134146341465,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3782027966682927,
          id: 'tether',
          balance: 0.37815668,
          price: 1.0001219512195123,
        },
      ],
    },
    timestamp: 1687478466,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7601.288735368782,
      timestamp: 1687392062,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7600.910578688781,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 30048.878048780487,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37815668,
          id: 'tether',
          balance: 0.37815668,
          price: 1,
        },
      ],
    },
    timestamp: 1687392062,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 7166.906034337883,
      timestamp: 1687305657,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 7166.52796989122,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 28331.621951219513,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37806444666341465,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9997560975609756,
        },
      ],
    },
    timestamp: 1687305657,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6774.250994148284,
      timestamp: 1687219259,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6773.873114168293,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 26779.329268292684,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3778799799902439,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9992682926829268,
        },
      ],
    },
    timestamp: 1687219259,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6656.1966041145415,
      timestamp: 1687132862,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6655.81877025122,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 26312.621951219513,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.3778338633219513,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9991463414634147,
        },
      ],
    },
    timestamp: 1687132862,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6698.127675647215,
      timestamp: 1687046459,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6697.750118483903,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 26478.39024390244,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37755716331219513,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9984146341463416,
        },
      ],
    },
    timestamp: 1687046459,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6652.056466967538,
      timestamp: 1686960060,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6651.679002037561,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 26296.256097560974,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37746492997560976,
          id: 'tether',
          balance: 0.37815668,
          price: 0.998170731707317,
        },
      ],
    },
    timestamp: 1686960060,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6462.441555888108,
      timestamp: 1686873662,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6462.064044841464,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 25546.646341463416,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37751104664390245,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9982926829268293,
        },
      ],
    },
    timestamp: 1686873662,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6356.236294381132,
      timestamp: 1686787267,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6355.858322167805,
          id: 'bitcoin',
          balance: 0.25295156,
          price: 25126.780487804877,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.37797221332682923,
          id: 'tether',
          balance: 0.37815668,
          price: 0.9995121951219511,
        },
      ],
    },
    timestamp: 1686787267,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6038.10119184858,
      timestamp: 1686700864,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6037.901228055488,
          id: 'bitcoin',
          balance: 0.23204249,
          price: 26020.670731707316,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.1999637930926829,
          id: 'tether',
          balance: 0.19918648,
          price: 1.0039024390243902,
        },
      ],
    },
    timestamp: 1686700864,
  },
  {
    eventsGroupHash: '88062db6-f131-4cd7-a29b-b127adf46d33-portfolio',
    eventType: 'portfolio_snapshot',
    data: {
      totalPortfolioBalance: 6037.034483807043,
      timestamp: 1686614461,
      totals: [
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          amount: 6036.83439855878,
          id: 'bitcoin',
          balance: 0.23204249,
          price: 26016.073170731706,
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          amount: 0.20008524826341464,
          id: 'tether',
          balance: 0.19918648,
          price: 1.0045121951219513,
        },
      ],
    },
    timestamp: 1686614461,
  },
];
