export const get_strategies = {
  $metadata: {
    httpStatusCode: 200,
    requestId: '7ULFH45HVP0CKMM2QO4A97K2LRVV4KQNSO5AEMVJF66Q9ASUAAJG',
    attempts: 1,
    totalRetryDelay: 0,
  },
  Count: 1,
  Items: [
    {
      updated_at: 1715154289,
      is_active: 'YES',
      tradingMode: 'LIVE',
      strategy: {
        sell_condition_text: 'close is less than ema100',
        sl_info: {
          stop_loss_value: 0,
          stop_loss_type: null,
          stop_loss_value_type: 'trailpercent',
        },
        sell_condition: 'close[0] < ema100.real[0]',
        buy_condition_text:
          'close is greater than ema100 and roc is greater than 0',
        tz: 'utc',
        name: 'Strategy Name',
        buy_condition: 'close[0] > ema100.real[0] and roc.real[0] > 0',
        interval: '1d',
        fractional_sizing: true,
        indicators: [
          {
            input_names: { price: 'close' },
            output_names: ['real'],
            output_flags: { real: ['Line'] },
            name: 'ROC',
            function_flags: null,
            id: 'roc',
            display_name: 'Rate of change : ((price/prevPrice)-1)*100',
            parameters: { timeperiod: 10 },
            group: 'Momentum Indicators',
          },
          {
            input_names: { price: 'close' },
            output_names: ['real'],
            output_flags: { real: ['Line'] },
            name: 'EMA',
            function_flags: [
              'Output scale same as input',
              'Function has an unstable period',
            ],
            id: 'ema100',
            display_name: 'Exponential Moving Average',
            parameters: { timeperiod: 100 },
            group: 'Overlap Studies',
          },
        ],
      },
      created_at: 1715154289,
      userId: '88062db6-f131-4cd7-a29b-b127adf46d33',
      cron_interval: '1d',
      cron_pk: 'utc_1d',
      id: 'd8fa785a-c552-4b77-bd5a-57a51324699f',
      bt_parameters: {
        start_date_time: '01/01/2020 00:00:00',
        initial_cash: 5000,
        commission: 0.1,
      },
      symbols_info: {
        base_currency: 'BTC',
        exchange_id: 'binance',
        quote_currency: 'USDT',
      },
      strategy_type: 'DynamicIndicators',
    },
  ],
  ScannedCount: 1,
};

export const get_startegy_details = {
  updated_at: 1715154289,
  is_active: 'YES',
  tradingMode: 'LIVE',
  strategy: {
    sell_condition_text: 'close is less than ema100',
    sl_info: {
      stop_loss_value: 0,
      stop_loss_type: null,
      stop_loss_value_type: 'trailpercent',
    },
    sell_condition: 'close[0] < ema100.real[0]',
    buy_condition_text:
      'close is greater than ema100 and roc is greater than 0',
    tz: 'utc',
    name: 'Strategy Name',
    buy_condition: 'close[0] > ema100.real[0] and roc.real[0] > 0',
    interval: '1d',
    fractional_sizing: true,
    indicators: [
      {
        input_names: { price: 'close' },
        output_names: ['real'],
        output_flags: { real: ['Line'] },
        name: 'ROC',
        function_flags: null,
        id: 'roc',
        display_name: 'Rate of change : ((price/prevPrice)-1)*100',
        parameters: { timeperiod: 10 },
        group: 'Momentum Indicators',
      },
      {
        input_names: { price: 'close' },
        output_names: ['real'],
        output_flags: { real: ['Line'] },
        name: 'EMA',
        function_flags: [
          'Output scale same as input',
          'Function has an unstable period',
        ],
        id: 'ema100',
        display_name: 'Exponential Moving Average',
        parameters: { timeperiod: 100 },
        group: 'Overlap Studies',
      },
    ],
  },
  created_at: 1715154289,
  userId: '88062db6-f131-4cd7-a29b-b127adf46d33',
  cron_interval: '1d',
  cron_pk: 'utc_1d',
  id: 'd8fa785a-c552-4b77-bd5a-57a51324699f',
  bt_parameters: {
    start_date_time: '01/01/2020 00:00:00',
    initial_cash: 5000,
    commission: 0.1,
  },
  symbols_info: {
    base_currency: 'BTC',
    exchange_id: 'binance',
    quote_currency: 'USDT',
  },
  strategy_type: 'DynamicIndicators',
};

export const post_stop_startegy = {
  updated_at: 1715154289,
  strategy: {
    sell_condition_text: 'close is less than ema100',
    sl_info: {
      stop_loss_value: 0,
      stop_loss_type: null,
      stop_loss_value_type: 'trailpercent',
    },
    sell_condition: 'close[0] < ema100.real[0]',
    buy_condition_text:
      'close is greater than ema100 and roc is greater than 0',
    tz: 'utc',
    name: 'Strategy Name',
    buy_condition: 'close[0] > ema100.real[0] and roc.real[0] > 0',
    interval: '1d',
    fractional_sizing: true,
    indicators: [
      {
        input_names: { price: 'close' },
        output_names: ['real'],
        output_flags: { real: ['Line'] },
        name: 'ROC',
        function_flags: null,
        id: 'roc',
        display_name: 'Rate of change : ((price/prevPrice)-1)*100',
        parameters: { timeperiod: 10 },
        group: 'Momentum Indicators',
      },
      {
        input_names: { price: 'close' },
        output_names: ['real'],
        output_flags: { real: ['Line'] },
        name: 'EMA',
        function_flags: [
          'Output scale same as input',
          'Function has an unstable period',
        ],
        id: 'ema100',
        display_name: 'Exponential Moving Average',
        parameters: { timeperiod: 100 },
        group: 'Overlap Studies',
      },
    ],
  },
  created_at: 1715154289,
  userId: '88062db6-f131-4cd7-a29b-b127adf46d33',
  cron_interval: '1d',
  cron_pk: 'utc_1d',
  id: 'd8fa785a-c552-4b77-bd5a-57a51324699f',
  bt_parameters: {
    start_date_time: '01/01/2020 00:00:00',
    initial_cash: 5000,
    commission: 0.1,
  },
  symbols_info: {
    base_currency: 'BTC',
    exchange_id: 'binance',
    quote_currency: 'USDT',
  },
  strategy_type: 'DynamicIndicators',
};

export const delete_strategy = {
  success: true,
  message: 'Strategy deleted successfully.',
};

export const post_run_backtest = { success: true };

export const get_strategy_summary = {
  allocations: {
    cash: {
      available: 19230.5569,
      symbol: 'BTC/USDT',
      total_allocation: 10000,
    },
    position: null,
  },
  summary: {
    final_portfolio_value: 19230.5569,
    pnl_net: 9266.92,
    pnl_gross: 9477.89,
    initial_portfolio_value: 10000,
    commissions: 210.97,
  },
  timelogs: {
    last_run_timestamp: 1715299200,
    next_run_timestamp: 1715385600,
    last_run_success: true,
  },
};
