import { getUserPortfolioHistory } from 'apis/user';
import { PREFERRED_PORTFOLIO_CURRENCY } from 'constants/app/localstore';
import { useExchangeContainer } from 'containers/exchange';
import { useCallback, useEffect } from 'react';
import { SelectOption } from 'types/app';
import { ChartLineData } from 'types/chart';
import { PortfolioAssetChange } from 'types/portfolio';
import { arrayToObject } from 'utils/formatters/transformers';
import { useComponentState } from './state';
import { UserCommonDataState } from 'containers/userCommonData/state';
import { useApplicationContainer } from 'containers/application';
import { OnCloseActionType } from 'components/SubscriptionConfirmation/types';
import {
  page_portfolio,
  page_visit_portfolio,
} from 'constants/app/analyticsEvents';
import { Analytics } from 'utils/analytics';

export const useComponentLogic = () => {
  const Exchange = useExchangeContainer();
  const State = useComponentState();
  const CommonDataState = UserCommonDataState.get();
  const AppContainer = useApplicationContainer();

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_portfolio);
  }, []);

  useEffect(() => {
    // Check if portfolio access is granted in plan
    const hasAccess =
      CommonDataState.userSettings?.plan?.planDetails?.portfolioTracking;

    if (!hasAccess) {
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.CloseAndGoBack,
        message:
          'Portfolio tracking in not included in your current plan. You need to upgrade your plan to access the unified exchanges portfolio.',
        screen: page_portfolio,
        reason: 'noPortfolioTracking',
      });
      return;
    }

    // Update Account Balances on Portfolio Page
    Exchange.fetchAccountBalances(State.portfolioCurrency.value);

    // fetch portfolio history on Portfolio Page
    fetchAndUpdatePorfolioHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Canculate Daily Change after accountBalances & lastDayPortfolioScan has been fetched
  useEffect(() => {
    if (Exchange.State.accountBalances && Exchange.State.lastDayPortfolioScan) {
      let totalQtyChange = false;
      const assetsChange: PortfolioAssetChange = {};
      const lastDayAssets = arrayToObject(
        Exchange.State.lastDayPortfolioScan.totals,
        'symbol',
      );

      // Iterate over current totals and compare with last day to calculate assetsChange
      Exchange.State.accountBalances.totals.forEach((a) => {
        let qtyChange, valueChange, percentageChange;
        // If not newly added to assets
        if (a.symbol in lastDayAssets) {
          qtyChange = a.balance - lastDayAssets[a.symbol].balance;
          valueChange = a.amount - lastDayAssets[a.symbol].amount;
          percentageChange =
            (valueChange / lastDayAssets[a.symbol].amount) * 100;
        } else {
          qtyChange = a.balance;
          valueChange = 0;
          percentageChange = 0;
          totalQtyChange = true;
        }

        if (qtyChange !== 0) {
          totalQtyChange = true;
        }
        // Add processed Symbol to assetsChanges
        assetsChange[a.symbol] = { qtyChange, valueChange, percentageChange };

        // Remove processed Symbol from lastDayAssets to avoid reprocessing
        delete lastDayAssets[a.symbol];
      });

      // Process removed assets from lastDayAssets (must have not been deleted)
      if (Object.keys(lastDayAssets).length) {
        // Individual calculations not required since not displayed on UI
        totalQtyChange = true;
      }

      const totalChange =
        Exchange.State.accountBalances?.totalUsdBalance -
        Exchange.State.lastDayPortfolioScan.totalPortfolioBalance;
      const totalPercentageChange = totalQtyChange
        ? 0
        : (totalChange /
            Exchange.State.lastDayPortfolioScan.totalPortfolioBalance) *
          100;

      State.setDailyChange({
        totalChange: {
          qtyChange: totalQtyChange,
          valueChange: totalChange,
          percentageChange: totalPercentageChange,
        },
        assetsChange: assetsChange,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Exchange.State.accountBalances, Exchange.State.lastDayPortfolioScan]);

  // Update portfolio on currency change
  const changeCurrency = (c: SelectOption) => {
    State.setportfolioCurrency(c);
    Exchange.fetchAccountBalances(c.value);
    // Update preferred currency in local store
    localStorage.setItem(PREFERRED_PORTFOLIO_CURRENCY, c.value);
  };

  const fetchAndUpdatePorfolioHistory = useCallback(
    (last?: number) => {
      if (State.fetchedAllHistory) {
        return Promise.resolve();
      }
      const date = new Date();
      const offset = date.getTimezoneOffset();
      last = last ? last + offset * 60 : undefined;
      return getUserPortfolioHistory(90, last).then((result) => {
        if (result.length) {
          let firstFetch = !State.portfolioHistoryFetchedOnce;
          let pData: ChartLineData = [];

          if (firstFetch && Exchange.State.accountBalances) {
            pData.push({
              time: Math.floor(Date.now() / 1000),
              value: Exchange.State.accountBalances.totalUsdBalance,
            });
          }

          result.forEach((p) => {
            /**
             * Check if first call and extract last day's accountBalances
             * This is done to avoid doing this while fetching paginated responses
             */
            if (firstFetch) {
              Exchange.State.setLastDayPorfoiloScan(p);
              firstFetch = false;
              State.trackPortfolioHistory(true);
            }
            pData.push({
              time: p.timestamp as number,
              value: p.totalPortfolioBalance as number,
            });
          });

          const pDataToUpdate =
            !!State.portfolioHistory && last
              ? [...pData.reverse(), ...State.portfolioHistory]
              : pData.reverse();

          State.setPortfilioHistory(pDataToUpdate);
        } else {
          State.setFetchedAllHistory(true);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      State.portfolioHistory,
      State.portfolioHistoryFetchedOnce,
      Exchange.State.accountBalances,
    ],
  );

  return {
    State,
    changeCurrency,
    fetchAndUpdatePorfolioHistory,
    accountBalances: Exchange.State.accountBalances,
  };
};
