import { Animate } from 'react-simple-animate';
import styles from './bottomsheet.module.css';
import { BottomsheetProps } from './types';

const Bottomsheet = (props: BottomsheetProps) => {
  const { header, children, footer, isOpen, onDismiss, background } = props;
  return isOpen ? (
    <div className={isOpen ? styles.overlay : styles.overlayHidden}>
      <div className={styles.freeOverlay} onClick={onDismiss}></div>
      <Animate
        play={isOpen}
        end={{
          opacity: 1,
          filter: 'blur(0)',
          transform: 'translateY(0)',
          height: 'fit-content',
        }}
        start={{
          opacity: 0,
          filter: 'blur(2px)',
          transform: 'translateY(20px)',
          height: '0px',
        }}
        duration={0.2}
      >
        <div
          className={styles.container}
          style={{ background: background ?? '#ffffff' }}
        >
          <div>{header}</div>
          <div> {children}</div>
          <div>{footer}</div>
        </div>
      </Animate>
    </div>
  ) : null;
};

export default Bottomsheet;
