import BlinkingDot from 'components/BlinkingDot';
import PageHeader from 'components/PageHeader';
import PageMenu from 'components/PageMenu';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { UserStrategy } from 'types/strategies';
import { TradingModeTypes } from '../MakeLiveBottomsheet/types';

type StrategyMenuProps = {
  showBackButton: boolean;
  customActionOnBack?: () => void;
  selectedStrategy?: UserStrategy | null;
  rightComponent?: JSX.Element;
};

const StrategyMenu = (props: StrategyMenuProps) => {
  const {
    showBackButton,
    customActionOnBack,
    selectedStrategy,
    rightComponent,
  } = props;
  const location = useLocation();
  // @ts-ignore
  let { strategyId } = useParams();
  const selectedPath = location.pathname;
  const tradingMode = selectedStrategy?.tradingMode;
  const history = useHistory();

  const menuItems = [
    {
      name: 'Configure',
      headerName: 'Configure Strategy',
      subTitle: 'Configure your strategy parameters.',
      link: strategyId
        ? `/app/strategies/strategy/${strategyId}/configure`
        : `/app/strategies/strategy/configure`,
    },
    {
      name: 'Backtest',
      headerName: 'Backtest Results',
      subTitle: 'Backtest your strategy before making it live.',
      link: strategyId
        ? `/app/strategies/strategy/${strategyId}/backtest/results`
        : `/app/strategies/strategy/backtest/results`,
    },
    {
      name: 'Live',
      headerName: 'Live Statistics',
      subTitle: 'Live statistics & Summary of strategy.',
      link: strategyId
        ? `/app/strategies/strategy/${strategyId}/liverun`
        : `/app/strategies/strategy/liverun`,
      rightItem: tradingMode ? (
        <BlinkingDot
          color={tradingMode === TradingModeTypes.Live ? '#00ba7c' : '#7b5fa5'}
          isBlinking={true}
        />
      ) : undefined,
    },
  ];

  const selected = menuItems.find((item) => selectedPath.includes(item.link));
  return (
    <PageHeader
      title={selected?.headerName ?? 'Strategy'}
      subTitle={
        selected?.subTitle ?? 'Backtest your strategy before making it live.'
      }
      secondaryMenu={<PageMenu menuItems={menuItems} />}
      showBackButton={showBackButton}
      customActionOnBack={
        customActionOnBack
          ? customActionOnBack
          : () => {
              history.push('/app/strategies');
            }
      }
      rightComponent={rightComponent}
      padding='small'
    />
  );
};

export default StrategyMenu;
