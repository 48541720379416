// state.ts
import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { ChatMessageType } from './types';

const defaultInitialState = {
  isOpen: false,
  messages: [] as ChatMessageType[],
  inputValue: '',
  threadId: undefined as string | undefined,
  isLoading: false,
};

const useStateDefination = (initialState = defaultInitialState) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState.isOpen);
  const [messages, setMessages] = useState<ChatMessageType[]>(
    initialState.messages,
  );
  const [inputValue, setInputValue] = useState<string>(initialState.inputValue);
  const [threadId, setThreadId] = useState<string | undefined>(
    initialState.threadId,
  );
  const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading);

  return {
    isOpen,
    setIsOpen,
    messages,
    setMessages,
    inputValue,
    setInputValue,
    threadId,
    setThreadId,
    isLoading,
    setIsLoading,
  };
};

// Create and export the container
const stateContainer = createContainer(useStateDefination);

export const AssistantChatState = {
  Provider: stateContainer.Provider,
  get: stateContainer.useContainer,
};
