import { useCallback } from 'react';
import { PUSH_NOTIFICATION_STATUS } from 'types/app';
import { ApplicationState } from './state';
import { isSupported as isWebPushSupported } from 'firebase/messaging';
import { SubscriptionConfirmationData } from 'components/SubscriptionConfirmation/types';
import { Analytics } from 'utils/analytics';
import {
  close_pricing_table,
  onboarding_slides_completed,
  open_pricing_table,
  upgrade_popup_displayed,
} from 'constants/app/analyticsEvents';
import { DISPLAY_ONBOADING } from 'constants/app/localstore';
import { useHistory } from 'react-router-dom';

export const useApplicationContainer = () => {
  const AppState = ApplicationState.get();
  const history = useHistory();

  const onLoginSuccess = useCallback(() => {
    AppState.setloginStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInitSuccess = useCallback(() => {
    AppState.setAppInitFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePushRequestPopupStatus = (status: boolean) => {
    AppState.setPushReqPopupStatus(status);
  };

  const showPricingTable = (status: boolean) => {
    AppState.setPricingTableStatus(status);

    // Track event
    const event = status ? open_pricing_table : close_pricing_table;
    Analytics.track(event);
  };

  // Request to enable push notification if not already done
  const requestWebPushNotifications = () => {
    isWebPushSupported().then((status) => {
      if (status) {
        let currentLocalPushStatus = Notification.permission;
        if (
          currentLocalPushStatus !== PUSH_NOTIFICATION_STATUS.GRANTED &&
          currentLocalPushStatus !== PUSH_NOTIFICATION_STATUS.DENIED
        ) {
          AppState.setPushReqPopupStatus(true);
        }
      }
    });
  };

  const showSubscriptionConfirmation = (data: SubscriptionConfirmationData) => {
    AppState.setSubscriptionConfirmationData(data);
    Analytics.track(upgrade_popup_displayed, {
      message: data.message,
      reason: data.reason,
    });
  };

  const hideOnboardingSlides = (reason: string) => {
    localStorage.setItem(DISPLAY_ONBOADING, 'false');
    AppState.setShowOnboardingStatus(false);
    Analytics.track(onboarding_slides_completed, {
      reason: reason,
    });
    history.push('/app/markets');
  };

  const hideSubscriptionConfirmation = () => {
    AppState.setSubscriptionConfirmationData(null);
  };

  return {
    State: AppState,
    onLoginSuccess,
    onInitSuccess,
    changePushRequestPopupStatus,
    requestWebPushNotifications,
    showPricingTable,
    showSubscriptionConfirmation,
    hideSubscriptionConfirmation,
    hideOnboardingSlides,
  };
};
