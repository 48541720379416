import { getUserStartegies } from 'apis/startegies';
import { getUserSettings, getUserWatchlist } from 'apis/user';
import { toast } from 'react-toastify';
import { UserCommonDataState } from './state';
import {
  PREFERRED_PORTFOLIO_CURRENCY,
  USER_WATCHLIST_KEY,
} from 'constants/app/localstore';
import { StrategySymbolsInfo } from 'types/strategies';
import { SAMPLE_WATCHLIST } from 'constants/app/app';

export const useUserCommonDataContainer = () => {
  const State = UserCommonDataState.get();

  // Method to get all user strategies
  const fetchUserStrategies = () => {
    State.setIsLoadingUserStartegies(true);
    getUserStartegies()
      .then((result) => {
        State.setUserStartegies(result);
      })
      .catch(() => {
        toast.error('Failed to fetch strategies, Please Refresh.');
      })
      .finally(() => {
        State.setIsLoadingUserStartegies(false);
      });
  };

  const updateWatchlist = (watchlist: StrategySymbolsInfo[]) => {
    if (!watchlist || watchlist.length === 0) {
      watchlist = SAMPLE_WATCHLIST;
    }
    State.setWatchlist(watchlist);
    // Update Local Store cache
    localStorage.setItem(USER_WATCHLIST_KEY, JSON.stringify(watchlist));
  };

  // Method to get user watchlist
  const fetchUserWatchlist = () => {
    getUserWatchlist()
      .then((result) => {
        updateWatchlist(result ?? []);
      })
      .catch(() => {
        toast.error('Failed to fetch watchlist, Please Refresh.');
      });
  };

  // fetch user setting
  const fetchUserSettings = () => {
    return getUserSettings()
      .then((userSettings) => {
        State.setUserSettings(userSettings);
        // Update preferred currency in local store
        if (userSettings.portfolioCurrency) {
          localStorage.setItem(
            PREFERRED_PORTFOLIO_CURRENCY,
            userSettings.portfolioCurrency,
          );
        }
      })
      .catch(() => {
        toast.error('Failed to fetch settinge, Please Refresh.');
      });
  };

  return {
    State,
    fetchUserStrategies,
    fetchUserWatchlist,
    fetchUserSettings,
    updateWatchlist,
  };
};
