import { useCallback } from 'react';
import { AuthenticationState } from './state';
import { Auth } from 'aws-amplify';
import { updateAuthToken } from 'utils/http';
import { Analytics } from 'utils/analytics';
import { event_login, event_logout } from 'constants/app/analyticsEvents';
import { isDemo } from 'utils/demo';
import { jwtDecode } from 'jwt-decode';

export const useAuthContainer = () => {
  const AuthState = AuthenticationState.get();

  const onLoginSuccess = useCallback((user: any) => {
    // Update HTTP Headers
    updateAuthToken(user.signInUserSession.idToken.jwtToken);
    const userInfo = jwtDecode(user.signInUserSession.idToken.jwtToken);
    const authenticatedUserInfo = {
      // @ts-ignore
      email: userInfo.email,
      sub: userInfo.sub,
      // @ts-ignore
      email_verified: userInfo.email_verified,
    };

    // Update Auth State of App
    AuthState.setAppUser(authenticatedUserInfo);
    AuthState.setAuthStatus(true);

    // Identify User in Analytics
    Analytics.IdentifyUser(authenticatedUserInfo);

    // Update Login event to Analytics
    Analytics.track(event_login, {
      email: authenticatedUserInfo.email,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogoutSuccess = useCallback(() => {
    // Update HTTP Headers
    updateAuthToken('');
    AuthState.setAppUser(null);
    AuthState.setAuthStatus(false);

    // Update logout event to Analytics
    Analytics.track(event_logout);

    // Reset User in Analytics on Logout
    Analytics.ResetUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAndUpdateAuthStatus = () => {
    if (isDemo) {
      return;
    }
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        // Update HTTP Headers
        console.log('fetchAndUpdateAuthStatus', user);
        updateAuthToken(user.signInUserSession.idToken.jwtToken);
        const userInfo = jwtDecode(user.signInUserSession.idToken.jwtToken);
        const authenticatedUserInfo = {
          // @ts-ignore
          email: userInfo.email,
          sub: userInfo.sub,
          // @ts-ignore
          email_verified: userInfo.email_verified,
        };
        AuthState.setAppUser(authenticatedUserInfo);
        AuthState.setAuthStatus(true);
        AuthState.setAuthLoadingStatus(false);

        // Identify User in Analytics
        Analytics.IdentifyUser(authenticatedUserInfo);
      })
      .catch(() => {
        AuthState.setAuthStatus(false);
        AuthState.setAuthLoadingStatus(false);
        AuthState.setAppUser(null);
      });
  };

  return {
    State: AuthState,
    onLoginSuccess,
    fetchAndUpdateAuthStatus,
    onLogoutSuccess,
  };
};
