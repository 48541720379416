import { Link, useLocation } from 'react-router-dom';
import styles from './pagemenu.module.css';
import { PageMenuProps } from './types';

const PageMenu = (props: PageMenuProps) => {
  const { menuItems, showBottomBorder } = props;

  const location = useLocation();
  const selectedPath = location.pathname;

  return (
    <div
      className={
        showBottomBorder ? styles.containerWithBottom : styles.container
      }
    >
      {menuItems.map((i) => {
        return (
          <Link to={i.link} key={i.name} className={styles.link}>
            <div
              className={`${styles.menuitem} ${
                selectedPath.includes(i.link) ? styles.selected : ''
              }`}
            >
              {i.name}
            </div>
            <span> {i.rightItem}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default PageMenu;
