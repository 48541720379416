import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { AuthStateProps } from './types';
import { isDemo } from 'utils/demo';

const defaultInitialState: AuthStateProps = {
  isAuthenticated: isDemo ? true : false,
  isLoadingAuthStatus: isDemo ? false : true,
  appUser: !isDemo
    ? null
    : {
        email: 'demo@uptrend.trade',
        email_verified: true,
        sub: '1234567890',
      },
};

const useStateDefination = (initialState = defaultInitialState) => {
  // State Properties
  let [isAuthenticated, setAuthStatus] = useState(initialState.isAuthenticated);
  let [isLoadingAuthStatus, setAuthLoadingStatus] = useState(
    initialState.isLoadingAuthStatus,
  );
  let [appUser, setAppUser] = useState(initialState.appUser);

  return {
    isAuthenticated,
    setAuthStatus,
    appUser,
    setAppUser,
    isLoadingAuthStatus,
    setAuthLoadingStatus,
  };
};

// Export Auth Context
const stateContainer = createContainer(useStateDefination);

export const AuthenticationState = {
  Provider: stateContainer.Provider,
  get: stateContainer.useContainer,
};
