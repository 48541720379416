import { ShellProps } from './types';
import { useComponentLogic } from './logics';
import styles from './shell.module.css';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDisplayType } from 'utils/display';
import SplashLoader from 'components/SplashLoader';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';
import { useApplicationContainer } from 'containers/application';
import Subscriptions from 'views/Subscriptions';
import { FullscreenView } from 'components/FullscreenView';
import { SubscriptionConfirmation } from 'components/SubscriptionConfirmation';
import { Onboarding } from 'views/Onboarding';
import { DISPLAY_ONBOADING } from 'constants/app/localstore';

const Shell = (props: ShellProps) => {
  const { children } = props;
  const { isDesktop } = useDisplayType();
  const Component = useComponentLogic();
  const AppContainer = useApplicationContainer();

  return (
    <div className={styles.container}>
      {children}
      <FullscreenView
        isOpen={AppContainer.State.showPricingTable}
        onClose={() => AppContainer.showPricingTable(false)}
      >
        <Subscriptions />
      </FullscreenView>
      <FullscreenView isOpen={AppContainer.State.showOnboarding}>
        <Onboarding />
      </FullscreenView>
      <ConfirmationBottomSheet
        show={AppContainer.State.showPushReqPopup}
        header={'Push Notifications'}
        subtitle={'Stay updated with push notifications.'}
        message={
          'Stay updated with what your strategies are up to, allow your live strategies to send you push notifications on this device ?'
        }
        onCancel={() => AppContainer.changePushRequestPopupStatus(false)}
        onConfim={() => {
          AppContainer.changePushRequestPopupStatus(false);
          Component.requestFirebaseNotifications(true);
        }}
        cancelText={'Cancel'}
        confirmText={'Allow'}
      />
      <SubscriptionConfirmation
        data={AppContainer.State.subscriptionConfirmationData}
      />
      <ToastContainer
        position={
          isDesktop ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.TOP_CENTER
        }
        autoClose={5000}
        transition={Slide}
        hideProgressBar
      />
      {Component.isLoadingAuth && <SplashLoader />}
    </div>
  );
};

export default Shell;
