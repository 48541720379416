import { useState } from 'react';
import { BacktestInitSteps } from './types';

export const useComponentState = () => {
  const [currentStep, setCurrentStep] = useState<BacktestInitSteps>(
    BacktestInitSteps.Allocations,
  );

  return {
    currentStep,
    setCurrentStep,
  };
};
