import {
  IndicatorInfo,
  LiveStrategySummary,
  StrategyAllocations,
  TextToConditionResponse,
  UserStrategy,
} from 'types/strategies';
import { dynaomoResponseFormater } from 'utils/formatters/dynamo';
import HTTP from 'utils/http';
import { TradingModeTypes } from 'views/Strategy/components/MakeLiveBottomsheet/types';
import { BasicStrategyParams } from 'views/Strategy/types';
import { formatToUserStartegyObj } from 'utils/formatters/assistant';

export const putStrategy = async (inputs: {
  userId: string;
  basicParams: BasicStrategyParams;
  indicators: IndicatorInfo[];
  buyCondition: string;
  buyConditionText: string;
  sellCondition: string;
  sellConditionText: string;
  tradingMode?: TradingModeTypes;
  allocations?: StrategyAllocations;
}): Promise<UserStrategy> => {
  var data = formatToUserStartegyObj(inputs);
  return await HTTP.put('/strategy', { data });
};

export const getUserStartegies = async (): Promise<UserStrategy[]> => {
  const resp = await HTTP.get(`/strategies`);
  return dynaomoResponseFormater(resp);
};

export const getUserStrategy = async (
  strategyId: string,
): Promise<UserStrategy> => {
  return HTTP.get(`/strategy/details/${strategyId}`);
};

export const updateStrategyStatus = async (
  strategyId: string,
  tradingMode: TradingModeTypes | undefined = undefined,
): Promise<UserStrategy[]> => {
  const body = {
    strategyId,
    tradingMode,
  };
  return await HTTP.post(`/strategy/status/`, body);
};

export const deleteUserStrategy = async (strategyId: string): Promise<void> => {
  return await HTTP.delete(`/strategy/${strategyId}`);
};

// export const updateStrategyAllocations = async (
//   strategyId: string,
//   allocation: StrategyAllocations,
// ): Promise<UserStrategy[]> => {
//   const body = {
//     strategyId,
//     data: allocation,
//   };
//   const resp = await HTTP.post(`/strategy/allocations`, body);
//   return dynaomoResponseFormater(resp);
// };

export const getLiveStrategyEvents = (
  eventsHash: string,
  limit: number,
  last: number | undefined,
): Promise<UserStrategy[]> => {
  const params = {
    limit,
    last,
  };
  return HTTP.get(`/strategy/events/${eventsHash}`, { params });
};

export const getLiveStrategySummary = (
  strategyId: string,
): Promise<LiveStrategySummary> => {
  return HTTP.get(`/strategy/summary/${strategyId}`);
};

export const getLiveStrategyAllocation = (
  strategyId: string,
): Promise<StrategyAllocations> => {
  return HTTP.get(`/strategy/allocation/${strategyId}`);
};

export const getConditionFromText = async (
  text: string,
  vars: any[],
): Promise<TextToConditionResponse> => {
  const body = {
    text,
    vars,
  };
  return HTTP.post(
    `${process.env.REACT_APP_API_BASE_URL}/completion/condition`,
    body,
  );
};
