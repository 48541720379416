import styles from './container.module.css';
import { SettingsContainerProps } from './types';

export const SettingsContainer = (props: SettingsContainerProps) => {
  const { header, settingsComponent } = props;
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>{header}</div>
      <div className={styles.bodyContainer}>{settingsComponent}</div>
    </div>
  );
};
