import { useDisplayType } from 'utils/display';
import styles from './onboardingSlide.module.css';
import { OnboardingSlideProps } from './types';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';

export const OnboardingSlide = (props: OnboardingSlideProps) => {
  const { header, explainer, description, image, actions } = props;
  const { isDesktop } = useDisplayType();
  return (
    <>
      <div className={isDesktop ? styles.container : styles.mobileContainer}>
        {isDesktop && (
          <div
            className={
              isDesktop ? styles.infoContainer : styles.infoContainerMobile
            }
          >
            <div className={styles.header}>{header}</div>
            <div className={styles.explainer}>{explainer}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.actions}>{actions}</div>
          </div>
        )}
        <div
          className={
            isDesktop ? styles.imageContainer : styles.imageContainerMobile
          }
        >
          {!isDesktop && (
            <div className={isDesktop ? styles.explainer : styles.mexplainer}>
              {explainer}
            </div>
          )}
          <img
            className={isDesktop ? styles.image : styles.mobileImage}
            src={image}
            alt='Onboarding'
          />
        </div>
        {!isDesktop && (
          <div className={styles.bottomsheet}>
            <div className={styles.mheader}>{header}</div>
            {/* <div className={styles.mexplainer}>{explainer}</div> */}
            <div className={styles.mdescription}>{description}</div>
            <div className={styles.actions}>{actions}</div>
          </div>
        )}
      </div>
    </>
  );
};
