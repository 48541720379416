import styles from './loader.module.css';
import { LoaderProps } from './types';

const Loader = (props: LoaderProps) => {
  const { containerClassName = '', bgColor, size = 'LARGE' } = props;
  const sizeStyles = {
    width: size === 'LARGE' ? '8px' : '4px',
    height: size === 'LARGE' ? '8px' : '4px',
    margin: size === 'LARGE' ? '0 4px' : '0 2px',
    backgroundColor: bgColor ? bgColor : '#5f5f5f',
  };
  return (
    <div className={containerClassName}>
      <div className={styles.loader}>
        <span style={sizeStyles}></span>
        <span style={sizeStyles}></span>
        <span style={sizeStyles}></span>
      </div>
    </div>
  );
};

export default Loader;
