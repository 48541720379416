// logics.ts
import { useEffect } from 'react';
import { CHAT_ACTION } from './types';
import { ASSISTANT_WELCOME_MESSAGE } from './constants';
import { AssistantChatState } from 'containers/assistantChat/state';
import { createThread, streamAssistantResponse } from 'apis/assistant';
import {
  formatToBacktestText,
  formatToUserStartegyObj,
  formatToUserStartegyText,
} from 'utils/formatters/assistant';
import { StrategyState } from 'views/Strategy/state';
import { ActionMessage } from '.';
import { Auth, Storage } from 'aws-amplify';
import { isDemo } from 'utils/demo';
import {
  click_assistant_send,
  click_share_backtest,
  click_share_startegy,
  close_assistant,
  open_assistant,
  page_assistant,
} from 'constants/app/analyticsEvents';
import { Analytics } from 'utils/analytics';
import { useUserCommonDataContainer } from 'containers/userCommonData';
import { useApplicationContainer } from 'containers/application';
import { OnCloseActionType } from 'components/SubscriptionConfirmation/types';

export const useComponentLogics = () => {
  const State = AssistantChatState.get();
  const strategyState = StrategyState.get();
  const AppContainer = useApplicationContainer();
  const CommonData = useUserCommonDataContainer();

  const hasAccess =
    CommonData.State.userSettings?.plan.planDetails?.strategyAssistant;

  console.log('hasAccess', hasAccess);

  const toggleChat = () => {
    // Send page visit event
    const event = !State.isOpen ? open_assistant : close_assistant;
    Analytics.track(event);

    State.setIsOpen(!State.isOpen);
  };

  const addUserMessage = (message: string | JSX.Element) => {
    State.setMessages([...State.messages, { message, sender: 'user' }]);
  };

  const addResponse = (chunk: string) => {
    // Use a functional update to ensure we always have the latest state
    State.setMessages((currentMessages) => {
      const messagesCopy = [...currentMessages];
      const lastMessage = messagesCopy[messagesCopy.length - 1];

      if (lastMessage && lastMessage.sender === 'assistant') {
        // If the last message is from the assistant, append the new message chunk
        lastMessage.message = `${lastMessage.message}${chunk}`;
        // Since we modified the last message directly, we can just return the modified array
        return messagesCopy;
      } else {
        // Else, add a new assistant message
        return [...messagesCopy, { message: chunk, sender: 'assistant' }];
      }
    });
  };

  const handleAccessDenied = () => {
    if (isDemo) {
      addResponse('Startegy Assistant chat is not available in Demo mode.');
    } else {
      addResponse(
        'Startegy Assistant chat is not available in your plan. Please upgrade to get access.',
      );
      AppContainer.showSubscriptionConfirmation({
        showConfirmation: true,
        onCloseAction: OnCloseActionType.Close,
        message: `Startegy Assistant chat is not available in your current plan. Please upgrade to talk to strategy assitant.`,
        screen: page_assistant,
        reason: 'assistantNotAvailable',
      });
    }
    State.setIsLoading(false);
  };

  const handleSend = async () => {
    addUserMessage(State.inputValue);
    State.setInputValue('');
    State.setIsLoading(true);

    Analytics.track(click_assistant_send, {
      message: State.inputValue,
      screen: page_assistant,
    });

    if (isDemo || !hasAccess) {
      handleAccessDenied();
      return;
    }

    let threadId = State.threadId;
    if (!State.threadId) {
      const thread = await createThread();
      threadId = thread.id;
      State.setThreadId(threadId);
    }

    const credentials = await Auth.currentCredentials();
    if (threadId) {
      streamAssistantResponse(
        threadId,
        State.inputValue,
        addResponse,
        () => {
          State.setIsLoading(false);
        },
        credentials,
      );
    }
  };

  const handleActionSend = async (action: CHAT_ACTION) => {
    const textToSend = executeChatAction(action);
    if (textToSend) {
      const actionMessage = ActionMessage(action);
      addUserMessage(actionMessage as JSX.Element);
      State.setIsLoading(true);

      if (isDemo || !hasAccess) {
        handleAccessDenied();
        return;
      }

      let threadId = State.threadId;
      if (!State.threadId) {
        const thread = await createThread();
        threadId = thread.id;
        State.setThreadId(threadId);
      }

      const credentials = await Auth.currentCredentials();
      if (threadId) {
        streamAssistantResponse(
          threadId,
          textToSend,
          addResponse,
          () => {
            State.setIsLoading(false);
          },
          credentials,
        );
      }
    }
  };

  const executeChatAction = (action: CHAT_ACTION) => {
    let text = '';
    switch (action) {
      case CHAT_ACTION.SHARE_STRATEGY:
        Analytics.track(click_share_startegy, {
          screen: page_assistant,
        });
        const startegyObj = formatToUserStartegyObj({
          userId: '',
          basicParams: strategyState.basicParams,
          indicators: strategyState.strategyIndicators,
          buyCondition: strategyState.buyCondition,
          buyConditionText: strategyState.buyConditionText,
          sellCondition: strategyState.sellCondition,
          sellConditionText: strategyState.sellConditionText,
        });
        text = formatToUserStartegyText(startegyObj);
        return text;
      case CHAT_ACTION.SHARE_BACKTEST:
        Analytics.track(click_share_backtest, {
          screen: page_assistant,
        });
        if (!strategyState.resultAnalysisData) {
          return '';
        }
        text = formatToBacktestText(
          strategyState.resultAnalysisData,
          strategyState.resultChartData?.backtestDateRange,
        );
        return text;
      default:
        break;
    }
  };

  useEffect(() => {
    if (State.messages.length === 0) {
      addResponse(ASSISTANT_WELCOME_MESSAGE);
    }
  }, []);

  return { State, toggleChat, handleSend, handleActionSend };
};
