// TODO:: Move These to s3
export const INDICATOR_GROUPS = {
  'Cycle Indicators': [
    {
      name: 'HT_DCPERIOD',
      display_name: 'Hilbert Transform - Dominant Cycle Period',
    },
    {
      name: 'HT_DCPHASE',
      display_name: 'Hilbert Transform - Dominant Cycle Phase',
    },
    {
      name: 'HT_PHASOR',
      display_name: 'Hilbert Transform - Phasor Components',
    },
    { name: 'HT_SINE', display_name: 'Hilbert Transform - SineWave' },
    {
      name: 'HT_TRENDMODE',
      display_name: 'Hilbert Transform - Trend vs Cycle Mode',
    },
  ],
  'Math Operators': [
    { name: 'ADD', display_name: 'Vector Arithmetic Add' },
    { name: 'DIV', display_name: 'Vector Arithmetic Div' },
    { name: 'MAX', display_name: 'Highest value over a specified period' },
    {
      name: 'MAXINDEX',
      display_name: 'Index of highest value over a specified period',
    },
    { name: 'MIN', display_name: 'Lowest value over a specified period' },
    {
      name: 'MININDEX',
      display_name: 'Index of lowest value over a specified period',
    },
    {
      name: 'MINMAX',
      display_name: 'Lowest and highest values over a specified period',
    },
    {
      name: 'MINMAXINDEX',
      display_name:
        'Indexes of lowest and highest values over a specified period',
    },
    { name: 'MULT', display_name: 'Vector Arithmetic Mult' },
    { name: 'SUB', display_name: 'Vector Arithmetic Substraction' },
    { name: 'SUM', display_name: 'Summation' },
  ],
  'Math Transform': [
    { name: 'ACOS', display_name: 'Vector Trigonometric ACos' },
    { name: 'ASIN', display_name: 'Vector Trigonometric ASin' },
    { name: 'ATAN', display_name: 'Vector Trigonometric ATan' },
    { name: 'CEIL', display_name: 'Vector Ceil' },
    { name: 'COS', display_name: 'Vector Trigonometric Cos' },
    { name: 'COSH', display_name: 'Vector Trigonometric Cosh' },
    { name: 'EXP', display_name: 'Vector Arithmetic Exp' },
    { name: 'FLOOR', display_name: 'Vector Floor' },
    { name: 'LN', display_name: 'Vector Log Natural' },
    { name: 'LOG10', display_name: 'Vector Log10' },
    { name: 'SIN', display_name: 'Vector Trigonometric Sin' },
    { name: 'SINH', display_name: 'Vector Trigonometric Sinh' },
    { name: 'SQRT', display_name: 'Vector Square Root' },
    { name: 'TAN', display_name: 'Vector Trigonometric Tan' },
    { name: 'TANH', display_name: 'Vector Trigonometric Tanh' },
  ],
  'Momentum Indicators': [
    { name: 'ADX', display_name: 'Average Directional Movement Index' },
    { name: 'ADXR', display_name: 'Average Directional Movement Index Rating' },
    { name: 'APO', display_name: 'Absolute Price Oscillator' },
    { name: 'AROON', display_name: 'Aroon' },
    { name: 'AROONOSC', display_name: 'Aroon Oscillator' },
    { name: 'BOP', display_name: 'Balance Of Power' },
    { name: 'CCI', display_name: 'Commodity Channel Index' },
    { name: 'CMO', display_name: 'Chande Momentum Oscillator' },
    { name: 'DX', display_name: 'Directional Movement Index' },
    { name: 'MACD', display_name: 'Moving Average Convergence/Divergence' },
    { name: 'MACDEXT', display_name: 'MACD with controllable MA type' },
    {
      name: 'MACDFIX',
      display_name: 'Moving Average Convergence/Divergence Fix 12/26',
    },
    { name: 'MFI', display_name: 'Money Flow Index' },
    { name: 'MINUS_DI', display_name: 'Minus Directional Indicator' },
    { name: 'MINUS_DM', display_name: 'Minus Directional Movement' },
    { name: 'MOM', display_name: 'Momentum' },
    { name: 'PLUS_DI', display_name: 'Plus Directional Indicator' },
    { name: 'PLUS_DM', display_name: 'Plus Directional Movement' },
    { name: 'PPO', display_name: 'Percentage Price Oscillator' },
    { name: 'ROC', display_name: 'Rate of change : ((price/prevPrice)-1)*100' },
    {
      name: 'ROCP',
      display_name: 'Rate of change Percentage: (price-prevPrice)/prevPrice',
    },
    { name: 'ROCR', display_name: 'Rate of change ratio: (price/prevPrice)' },
    {
      name: 'ROCR100',
      display_name: 'Rate of change ratio 100 scale: (price/prevPrice)*100',
    },
    { name: 'RSI', display_name: 'Relative Strength Index' },
    { name: 'STOCH', display_name: 'Stochastic' },
    { name: 'STOCHF', display_name: 'Stochastic Fast' },
    { name: 'STOCHRSI', display_name: 'Stochastic Relative Strength Index' },
    {
      name: 'TRIX',
      display_name: '1-day Rate-Of-Change (ROC) of a Triple Smooth EMA',
    },
    { name: 'ULTOSC', display_name: 'Ultimate Oscillator' },
    { name: 'WILLR', display_name: "Williams' %R" },
  ],
  'Overlap Studies': [
    { name: 'BBANDS', display_name: 'Bollinger Bands' },
    { name: 'DEMA', display_name: 'Double Exponential Moving Average' },
    { name: 'EMA', display_name: 'Exponential Moving Average' },
    {
      name: 'HT_TRENDLINE',
      display_name: 'Hilbert Transform - Instantaneous Trendline',
    },
    { name: 'KAMA', display_name: 'Kaufman Adaptive Moving Average' },
    { name: 'MA', display_name: 'Moving average' },
    { name: 'MAMA', display_name: 'MESA Adaptive Moving Average' },
    // { name: 'MAVP', display_name: 'Moving average with variable period' },
    { name: 'MIDPOINT', display_name: 'MidPoint over period' },
    { name: 'MIDPRICE', display_name: 'Midpoint Price over period' },
    { name: 'SAR', display_name: 'Parabolic SAR' },
    { name: 'SAREXT', display_name: 'Parabolic SAR - Extended' },
    { name: 'SMA', display_name: 'Simple Moving Average' },
    { name: 'T3', display_name: 'Triple Exponential Moving Average (T3)' },
    { name: 'TEMA', display_name: 'Triple Exponential Moving Average' },
    { name: 'TRIMA', display_name: 'Triangular Moving Average' },
    { name: 'WMA', display_name: 'Weighted Moving Average' },
  ],
  'Pattern Recognition': [
    { name: 'CDL2CROWS', display_name: 'Two Crows' },
    { name: 'CDL3BLACKCROWS', display_name: 'Three Black Crows' },
    { name: 'CDL3INSIDE', display_name: 'Three Inside Up/Down' },
    { name: 'CDL3LINESTRIKE', display_name: 'Three-Line Strike ' },
    { name: 'CDL3OUTSIDE', display_name: 'Three Outside Up/Down' },
    { name: 'CDL3STARSINSOUTH', display_name: 'Three Stars In The South' },
    {
      name: 'CDL3WHITESOLDIERS',
      display_name: 'Three Advancing White Soldiers',
    },
    { name: 'CDLABANDONEDBABY', display_name: 'Abandoned Baby' },
    { name: 'CDLADVANCEBLOCK', display_name: 'Advance Block' },
    { name: 'CDLBELTHOLD', display_name: 'Belt-hold' },
    { name: 'CDLBREAKAWAY', display_name: 'Breakaway' },
    { name: 'CDLCLOSINGMARUBOZU', display_name: 'Closing Marubozu' },
    { name: 'CDLCONCEALBABYSWALL', display_name: 'Concealing Baby Swallow' },
    { name: 'CDLCOUNTERATTACK', display_name: 'Counterattack' },
    { name: 'CDLDARKCLOUDCOVER', display_name: 'Dark Cloud Cover' },
    { name: 'CDLDOJI', display_name: 'Doji' },
    { name: 'CDLDOJISTAR', display_name: 'Doji Star' },
    { name: 'CDLDRAGONFLYDOJI', display_name: 'Dragonfly Doji' },
    { name: 'CDLENGULFING', display_name: 'Engulfing Pattern' },
    { name: 'CDLEVENINGDOJISTAR', display_name: 'Evening Doji Star' },
    { name: 'CDLEVENINGSTAR', display_name: 'Evening Star' },
    {
      name: 'CDLGAPSIDESIDEWHITE',
      display_name: 'Up/Down-gap side-by-side white lines',
    },
    { name: 'CDLGRAVESTONEDOJI', display_name: 'Gravestone Doji' },
    { name: 'CDLHAMMER', display_name: 'Hammer' },
    { name: 'CDLHANGINGMAN', display_name: 'Hanging Man' },
    { name: 'CDLHARAMI', display_name: 'Harami Pattern' },
    { name: 'CDLHARAMICROSS', display_name: 'Harami Cross Pattern' },
    { name: 'CDLHIGHWAVE', display_name: 'High-Wave Candle' },
    { name: 'CDLHIKKAKE', display_name: 'Hikkake Pattern' },
    { name: 'CDLHIKKAKEMOD', display_name: 'Modified Hikkake Pattern' },
    { name: 'CDLHOMINGPIGEON', display_name: 'Homing Pigeon' },
    { name: 'CDLIDENTICAL3CROWS', display_name: 'Identical Three Crows' },
    { name: 'CDLINNECK', display_name: 'In-Neck Pattern' },
    { name: 'CDLINVERTEDHAMMER', display_name: 'Inverted Hammer' },
    { name: 'CDLKICKING', display_name: 'Kicking' },
    {
      name: 'CDLKICKINGBYLENGTH',
      display_name: 'Kicking - bull/bear determined by the longer marubozu',
    },
    { name: 'CDLLADDERBOTTOM', display_name: 'Ladder Bottom' },
    { name: 'CDLLONGLEGGEDDOJI', display_name: 'Long Legged Doji' },
    { name: 'CDLLONGLINE', display_name: 'Long Line Candle' },
    { name: 'CDLMARUBOZU', display_name: 'Marubozu' },
    { name: 'CDLMATCHINGLOW', display_name: 'Matching Low' },
    { name: 'CDLMATHOLD', display_name: 'Mat Hold' },
    { name: 'CDLMORNINGDOJISTAR', display_name: 'Morning Doji Star' },
    { name: 'CDLMORNINGSTAR', display_name: 'Morning Star' },
    { name: 'CDLONNECK', display_name: 'On-Neck Pattern' },
    { name: 'CDLPIERCING', display_name: 'Piercing Pattern' },
    { name: 'CDLRICKSHAWMAN', display_name: 'Rickshaw Man' },
    {
      name: 'CDLRISEFALL3METHODS',
      display_name: 'Rising/Falling Three Methods',
    },
    { name: 'CDLSEPARATINGLINES', display_name: 'Separating Lines' },
    { name: 'CDLSHOOTINGSTAR', display_name: 'Shooting Star' },
    { name: 'CDLSHORTLINE', display_name: 'Short Line Candle' },
    { name: 'CDLSPINNINGTOP', display_name: 'Spinning Top' },
    { name: 'CDLSTALLEDPATTERN', display_name: 'Stalled Pattern' },
    { name: 'CDLSTICKSANDWICH', display_name: 'Stick Sandwich' },
    {
      name: 'CDLTAKURI',
      display_name: 'Takuri (Dragonfly Doji with very long lower shadow)',
    },
    { name: 'CDLTASUKIGAP', display_name: 'Tasuki Gap' },
    { name: 'CDLTHRUSTING', display_name: 'Thrusting Pattern' },
    { name: 'CDLTRISTAR', display_name: 'Tristar Pattern' },
    { name: 'CDLUNIQUE3RIVER', display_name: 'Unique 3 River' },
    { name: 'CDLUPSIDEGAP2CROWS', display_name: 'Upside Gap Two Crows' },
    {
      name: 'CDLXSIDEGAP3METHODS',
      display_name: 'Upside/Downside Gap Three Methods',
    },
  ],
  'Price Transform': [
    { name: 'AVGPRICE', display_name: 'Average Price' },
    { name: 'MEDPRICE', display_name: 'Median Price' },
    { name: 'TYPPRICE', display_name: 'Typical Price' },
    { name: 'WCLPRICE', display_name: 'Weighted Close Price' },
  ],
  'Statistic Functions': [
    { name: 'BETA', display_name: 'Beta' },
    { name: 'CORREL', display_name: "Pearson's Correlation Coefficient (r)" },
    { name: 'LINEARREG', display_name: 'Linear Regression' },
    { name: 'LINEARREG_ANGLE', display_name: 'Linear Regression Angle' },
    {
      name: 'LINEARREG_INTERCEPT',
      display_name: 'Linear Regression Intercept',
    },
    { name: 'LINEARREG_SLOPE', display_name: 'Linear Regression Slope' },
    { name: 'STDDEV', display_name: 'Standard Deviation' },
    { name: 'TSF', display_name: 'Time Series Forecast' },
    { name: 'VAR', display_name: 'Variance' },
  ],
  'Volatility Indicators': [
    { name: 'ATR', display_name: 'Average True Range' },
    { name: 'NATR', display_name: 'Normalized Average True Range' },
    { name: 'TRANGE', display_name: 'True Range' },
  ],
  'Volume Indicators': [
    { name: 'AD', display_name: 'Chaikin A/D Line' },
    { name: 'ADOSC', display_name: 'Chaikin A/D Oscillator' },
    { name: 'OBV', display_name: 'On Balance Volume' },
  ],
};

export const INDICATORS_INFO = {
  HT_DCPERIOD: {
    name: 'HT_DCPERIOD',
    group: 'Cycle Indicators',
    display_name: 'Hilbert Transform - Dominant Cycle Period',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  HT_DCPHASE: {
    name: 'HT_DCPHASE',
    group: 'Cycle Indicators',
    display_name: 'Hilbert Transform - Dominant Cycle Phase',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  HT_PHASOR: {
    name: 'HT_PHASOR',
    group: 'Cycle Indicators',
    display_name: 'Hilbert Transform - Phasor Components',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { inphase: ['Line'], quadrature: ['Dashed Line'] },
    output_names: ['inphase', 'quadrature'],
  },
  HT_SINE: {
    name: 'HT_SINE',
    group: 'Cycle Indicators',
    display_name: 'Hilbert Transform - SineWave',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { sine: ['Line'], leadsine: ['Dashed Line'] },
    output_names: ['sine', 'leadsine'],
  },
  HT_TRENDMODE: {
    name: 'HT_TRENDMODE',
    group: 'Cycle Indicators',
    display_name: 'Hilbert Transform - Trend vs Cycle Mode',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  ADD: {
    name: 'ADD',
    group: 'Math Operators',
    display_name: 'Vector Arithmetic Add',
    function_flags: null,
    input_names: { price0: 'high', price1: 'low' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  DIV: {
    name: 'DIV',
    group: 'Math Operators',
    display_name: 'Vector Arithmetic Div',
    function_flags: null,
    input_names: { price0: 'high', price1: 'low' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MAX: {
    name: 'MAX',
    group: 'Math Operators',
    display_name: 'Highest value over a specified period',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MAXINDEX: {
    name: 'MAXINDEX',
    group: 'Math Operators',
    display_name: 'Index of highest value over a specified period',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  MIN: {
    name: 'MIN',
    group: 'Math Operators',
    display_name: 'Lowest value over a specified period',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MININDEX: {
    name: 'MININDEX',
    group: 'Math Operators',
    display_name: 'Index of lowest value over a specified period',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  MINMAX: {
    name: 'MINMAX',
    group: 'Math Operators',
    display_name: 'Lowest and highest values over a specified period',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { min: ['Line'], max: ['Line'] },
    output_names: ['min', 'max'],
  },
  MINMAXINDEX: {
    name: 'MINMAXINDEX',
    group: 'Math Operators',
    display_name:
      'Indexes of lowest and highest values over a specified period',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { minidx: ['Line'], maxidx: ['Line'] },
    output_names: ['minidx', 'maxidx'],
  },
  MULT: {
    name: 'MULT',
    group: 'Math Operators',
    display_name: 'Vector Arithmetic Mult',
    function_flags: null,
    input_names: { price0: 'high', price1: 'low' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SUB: {
    name: 'SUB',
    group: 'Math Operators',
    display_name: 'Vector Arithmetic Substraction',
    function_flags: null,
    input_names: { price0: 'high', price1: 'low' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SUM: {
    name: 'SUM',
    group: 'Math Operators',
    display_name: 'Summation',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ACOS: {
    name: 'ACOS',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric ACos',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ASIN: {
    name: 'ASIN',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric ASin',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ATAN: {
    name: 'ATAN',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric ATan',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  CEIL: {
    name: 'CEIL',
    group: 'Math Transform',
    display_name: 'Vector Ceil',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  COS: {
    name: 'COS',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric Cos',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  COSH: {
    name: 'COSH',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric Cosh',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  EXP: {
    name: 'EXP',
    group: 'Math Transform',
    display_name: 'Vector Arithmetic Exp',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  FLOOR: {
    name: 'FLOOR',
    group: 'Math Transform',
    display_name: 'Vector Floor',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  LN: {
    name: 'LN',
    group: 'Math Transform',
    display_name: 'Vector Log Natural',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  LOG10: {
    name: 'LOG10',
    group: 'Math Transform',
    display_name: 'Vector Log10',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SIN: {
    name: 'SIN',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric Sin',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SINH: {
    name: 'SINH',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric Sinh',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SQRT: {
    name: 'SQRT',
    group: 'Math Transform',
    display_name: 'Vector Square Root',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TAN: {
    name: 'TAN',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric Tan',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TANH: {
    name: 'TANH',
    group: 'Math Transform',
    display_name: 'Vector Trigonometric Tanh',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ADX: {
    name: 'ADX',
    group: 'Momentum Indicators',
    display_name: 'Average Directional Movement Index',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ADXR: {
    name: 'ADXR',
    group: 'Momentum Indicators',
    display_name: 'Average Directional Movement Index Rating',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  APO: {
    name: 'APO',
    group: 'Momentum Indicators',
    display_name: 'Absolute Price Oscillator',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { fastperiod: 12, slowperiod: 26, matype: 0 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  AROON: {
    name: 'AROON',
    group: 'Momentum Indicators',
    display_name: 'Aroon',
    function_flags: null,
    input_names: { prices: ['high', 'low'] },
    parameters: { timeperiod: 14 },
    output_flags: { aroondown: ['Dashed Line'], aroonup: ['Line'] },
    output_names: ['aroondown', 'aroonup'],
  },
  AROONOSC: {
    name: 'AROONOSC',
    group: 'Momentum Indicators',
    display_name: 'Aroon Oscillator',
    function_flags: null,
    input_names: { prices: ['high', 'low'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  BOP: {
    name: 'BOP',
    group: 'Momentum Indicators',
    display_name: 'Balance Of Power',
    function_flags: null,
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  CCI: {
    name: 'CCI',
    group: 'Momentum Indicators',
    display_name: 'Commodity Channel Index',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  CMO: {
    name: 'CMO',
    group: 'Momentum Indicators',
    display_name: 'Chande Momentum Oscillator',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  DX: {
    name: 'DX',
    group: 'Momentum Indicators',
    display_name: 'Directional Movement Index',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MACD: {
    name: 'MACD',
    group: 'Momentum Indicators',
    display_name: 'Moving Average Convergence/Divergence',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { fastperiod: 12, slowperiod: 26, signalperiod: 9 },
    output_flags: {
      macd: ['Line'],
      macdsignal: ['Dashed Line'],
      macdhist: ['Histogram'],
    },
    output_names: ['macd', 'macdsignal', 'macdhist'],
  },
  MACDEXT: {
    name: 'MACDEXT',
    group: 'Momentum Indicators',
    display_name: 'MACD with controllable MA type',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: {
      fastperiod: 12,
      fastmatype: 0,
      slowperiod: 26,
      slowmatype: 0,
      signalperiod: 9,
      signalmatype: 0,
    },
    output_flags: {
      macd: ['Line'],
      macdsignal: ['Dashed Line'],
      macdhist: ['Histogram'],
    },
    output_names: ['macd', 'macdsignal', 'macdhist'],
  },
  MACDFIX: {
    name: 'MACDFIX',
    group: 'Momentum Indicators',
    display_name: 'Moving Average Convergence/Divergence Fix 12/26',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { signalperiod: 9 },
    output_flags: {
      macd: ['Line'],
      macdsignal: ['Dashed Line'],
      macdhist: ['Histogram'],
    },
    output_names: ['macd', 'macdsignal', 'macdhist'],
  },
  MFI: {
    name: 'MFI',
    group: 'Momentum Indicators',
    display_name: 'Money Flow Index',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close', 'volume'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MINUS_DI: {
    name: 'MINUS_DI',
    group: 'Momentum Indicators',
    display_name: 'Minus Directional Indicator',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MINUS_DM: {
    name: 'MINUS_DM',
    group: 'Momentum Indicators',
    display_name: 'Minus Directional Movement',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MOM: {
    name: 'MOM',
    group: 'Momentum Indicators',
    display_name: 'Momentum',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 10 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  PLUS_DI: {
    name: 'PLUS_DI',
    group: 'Momentum Indicators',
    display_name: 'Plus Directional Indicator',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  PLUS_DM: {
    name: 'PLUS_DM',
    group: 'Momentum Indicators',
    display_name: 'Plus Directional Movement',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  PPO: {
    name: 'PPO',
    group: 'Momentum Indicators',
    display_name: 'Percentage Price Oscillator',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { fastperiod: 12, slowperiod: 26, matype: 0 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ROC: {
    name: 'ROC',
    group: 'Momentum Indicators',
    display_name: 'Rate of change : ((price/prevPrice)-1)*100',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 10 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ROCP: {
    name: 'ROCP',
    group: 'Momentum Indicators',
    display_name: 'Rate of change Percentage: (price-prevPrice)/prevPrice',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 10 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ROCR: {
    name: 'ROCR',
    group: 'Momentum Indicators',
    display_name: 'Rate of change ratio: (price/prevPrice)',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 10 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ROCR100: {
    name: 'ROCR100',
    group: 'Momentum Indicators',
    display_name: 'Rate of change ratio 100 scale: (price/prevPrice)*100',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 10 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  RSI: {
    name: 'RSI',
    group: 'Momentum Indicators',
    display_name: 'Relative Strength Index',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  STOCH: {
    name: 'STOCH',
    group: 'Momentum Indicators',
    display_name: 'Stochastic',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: {
      fastk_period: 5,
      slowk_period: 3,
      slowk_matype: 0,
      slowd_period: 3,
      slowd_matype: 0,
    },
    output_flags: { slowk: ['Dashed Line'], slowd: ['Dashed Line'] },
    output_names: ['slowk', 'slowd'],
  },
  STOCHF: {
    name: 'STOCHF',
    group: 'Momentum Indicators',
    display_name: 'Stochastic Fast',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { fastk_period: 5, fastd_period: 3, fastd_matype: 0 },
    output_flags: { fastk: ['Line'], fastd: ['Line'] },
    output_names: ['fastk', 'fastd'],
  },
  STOCHRSI: {
    name: 'STOCHRSI',
    group: 'Momentum Indicators',
    display_name: 'Stochastic Relative Strength Index',
    function_flags: ['Function has an unstable period'],
    input_names: { price: 'close' },
    parameters: {
      timeperiod: 14,
      fastk_period: 5,
      fastd_period: 3,
      fastd_matype: 0,
    },
    output_flags: { fastk: ['Line'], fastd: ['Line'] },
    output_names: ['fastk', 'fastd'],
  },
  TRIX: {
    name: 'TRIX',
    group: 'Momentum Indicators',
    display_name: '1-day Rate-Of-Change (ROC) of a Triple Smooth EMA',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ULTOSC: {
    name: 'ULTOSC',
    group: 'Momentum Indicators',
    display_name: 'Ultimate Oscillator',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod1: 7, timeperiod2: 14, timeperiod3: 28 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  WILLR: {
    name: 'WILLR',
    group: 'Momentum Indicators',
    display_name: "Williams' %R",
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  BBANDS: {
    name: 'BBANDS',
    group: 'Overlap Studies',
    display_name: 'Bollinger Bands',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 5, nbdevup: 2, nbdevdn: 2, matype: 0 },
    output_flags: {
      upperband: ['Values represent an upper limit'],
      middleband: ['Line'],
      lowerband: ['Values represent a lower limit'],
    },
    output_names: ['upperband', 'middleband', 'lowerband'],
  },
  DEMA: {
    name: 'DEMA',
    group: 'Overlap Studies',
    display_name: 'Double Exponential Moving Average',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  EMA: {
    name: 'EMA',
    group: 'Overlap Studies',
    display_name: 'Exponential Moving Average',
    function_flags: [
      'Output scale same as input',
      'Function has an unstable period',
    ],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  HT_TRENDLINE: {
    name: 'HT_TRENDLINE',
    group: 'Overlap Studies',
    display_name: 'Hilbert Transform - Instantaneous Trendline',
    function_flags: [
      'Output scale same as input',
      'Function has an unstable period',
    ],
    input_names: { price: 'close' },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  KAMA: {
    name: 'KAMA',
    group: 'Overlap Studies',
    display_name: 'Kaufman Adaptive Moving Average',
    function_flags: [
      'Output scale same as input',
      'Function has an unstable period',
    ],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MA: {
    name: 'MA',
    group: 'Overlap Studies',
    display_name: 'Moving average',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30, matype: 0 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MAMA: {
    name: 'MAMA',
    group: 'Overlap Studies',
    display_name: 'MESA Adaptive Moving Average',
    function_flags: [
      'Output scale same as input',
      'Function has an unstable period',
    ],
    input_names: { price: 'close' },
    parameters: { fastlimit: 0.5, slowlimit: 0.05 },
    output_flags: { mama: ['Line'], fama: ['Dashed Line'] },
    output_names: ['mama', 'fama'],
  },
  // MAVP: {
  //   name: 'MAVP',
  //   group: 'Overlap Studies',
  //   display_name: 'Moving average with variable period',
  //   function_flags: ['Output scale same as input'],
  //   input_names: { price: 'close', periods: 'periods' },
  //   parameters: { minperiod: 2, maxperiod: 30, matype: 0 },
  //   output_flags: { real: ['Line'] },
  //   output_names: ['real'],
  // },
  MIDPOINT: {
    name: 'MIDPOINT',
    group: 'Overlap Studies',
    display_name: 'MidPoint over period',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MIDPRICE: {
    name: 'MIDPRICE',
    group: 'Overlap Studies',
    display_name: 'Midpoint Price over period',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['high', 'low'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SAR: {
    name: 'SAR',
    group: 'Overlap Studies',
    display_name: 'Parabolic SAR',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['high', 'low'] },
    parameters: { acceleration: 0.02, maximum: 0.2 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SAREXT: {
    name: 'SAREXT',
    group: 'Overlap Studies',
    display_name: 'Parabolic SAR - Extended',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['high', 'low'] },
    parameters: {
      startvalue: 0,
      offsetonreverse: 0,
      accelerationinitlong: 0.02,
      accelerationlong: 0.02,
      accelerationmaxlong: 0.2,
      accelerationinitshort: 0.02,
      accelerationshort: 0.02,
      accelerationmaxshort: 0.2,
    },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  SMA: {
    name: 'SMA',
    group: 'Overlap Studies',
    display_name: 'Simple Moving Average',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  T3: {
    name: 'T3',
    group: 'Overlap Studies',
    display_name: 'Triple Exponential Moving Average (T3)',
    function_flags: [
      'Output scale same as input',
      'Function has an unstable period',
    ],
    input_names: { price: 'close' },
    parameters: { timeperiod: 5, vfactor: 0.7 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TEMA: {
    name: 'TEMA',
    group: 'Overlap Studies',
    display_name: 'Triple Exponential Moving Average',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TRIMA: {
    name: 'TRIMA',
    group: 'Overlap Studies',
    display_name: 'Triangular Moving Average',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  WMA: {
    name: 'WMA',
    group: 'Overlap Studies',
    display_name: 'Weighted Moving Average',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  CDL2CROWS: {
    name: 'CDL2CROWS',
    group: 'Pattern Recognition',
    display_name: 'Two Crows',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDL3BLACKCROWS: {
    name: 'CDL3BLACKCROWS',
    group: 'Pattern Recognition',
    display_name: 'Three Black Crows',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDL3INSIDE: {
    name: 'CDL3INSIDE',
    group: 'Pattern Recognition',
    display_name: 'Three Inside Up/Down',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDL3LINESTRIKE: {
    name: 'CDL3LINESTRIKE',
    group: 'Pattern Recognition',
    display_name: 'Three-Line Strike ',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDL3OUTSIDE: {
    name: 'CDL3OUTSIDE',
    group: 'Pattern Recognition',
    display_name: 'Three Outside Up/Down',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDL3STARSINSOUTH: {
    name: 'CDL3STARSINSOUTH',
    group: 'Pattern Recognition',
    display_name: 'Three Stars In The South',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDL3WHITESOLDIERS: {
    name: 'CDL3WHITESOLDIERS',
    group: 'Pattern Recognition',
    display_name: 'Three Advancing White Soldiers',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLABANDONEDBABY: {
    name: 'CDLABANDONEDBABY',
    group: 'Pattern Recognition',
    display_name: 'Abandoned Baby',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.3 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLADVANCEBLOCK: {
    name: 'CDLADVANCEBLOCK',
    group: 'Pattern Recognition',
    display_name: 'Advance Block',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLBELTHOLD: {
    name: 'CDLBELTHOLD',
    group: 'Pattern Recognition',
    display_name: 'Belt-hold',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLBREAKAWAY: {
    name: 'CDLBREAKAWAY',
    group: 'Pattern Recognition',
    display_name: 'Breakaway',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLCLOSINGMARUBOZU: {
    name: 'CDLCLOSINGMARUBOZU',
    group: 'Pattern Recognition',
    display_name: 'Closing Marubozu',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLCONCEALBABYSWALL: {
    name: 'CDLCONCEALBABYSWALL',
    group: 'Pattern Recognition',
    display_name: 'Concealing Baby Swallow',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLCOUNTERATTACK: {
    name: 'CDLCOUNTERATTACK',
    group: 'Pattern Recognition',
    display_name: 'Counterattack',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLDARKCLOUDCOVER: {
    name: 'CDLDARKCLOUDCOVER',
    group: 'Pattern Recognition',
    display_name: 'Dark Cloud Cover',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.5 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLDOJI: {
    name: 'CDLDOJI',
    group: 'Pattern Recognition',
    display_name: 'Doji',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLDOJISTAR: {
    name: 'CDLDOJISTAR',
    group: 'Pattern Recognition',
    display_name: 'Doji Star',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLDRAGONFLYDOJI: {
    name: 'CDLDRAGONFLYDOJI',
    group: 'Pattern Recognition',
    display_name: 'Dragonfly Doji',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLENGULFING: {
    name: 'CDLENGULFING',
    group: 'Pattern Recognition',
    display_name: 'Engulfing Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLEVENINGDOJISTAR: {
    name: 'CDLEVENINGDOJISTAR',
    group: 'Pattern Recognition',
    display_name: 'Evening Doji Star',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.3 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLEVENINGSTAR: {
    name: 'CDLEVENINGSTAR',
    group: 'Pattern Recognition',
    display_name: 'Evening Star',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.3 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLGAPSIDESIDEWHITE: {
    name: 'CDLGAPSIDESIDEWHITE',
    group: 'Pattern Recognition',
    display_name: 'Up/Down-gap side-by-side white lines',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLGRAVESTONEDOJI: {
    name: 'CDLGRAVESTONEDOJI',
    group: 'Pattern Recognition',
    display_name: 'Gravestone Doji',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHAMMER: {
    name: 'CDLHAMMER',
    group: 'Pattern Recognition',
    display_name: 'Hammer',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHANGINGMAN: {
    name: 'CDLHANGINGMAN',
    group: 'Pattern Recognition',
    display_name: 'Hanging Man',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHARAMI: {
    name: 'CDLHARAMI',
    group: 'Pattern Recognition',
    display_name: 'Harami Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHARAMICROSS: {
    name: 'CDLHARAMICROSS',
    group: 'Pattern Recognition',
    display_name: 'Harami Cross Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHIGHWAVE: {
    name: 'CDLHIGHWAVE',
    group: 'Pattern Recognition',
    display_name: 'High-Wave Candle',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHIKKAKE: {
    name: 'CDLHIKKAKE',
    group: 'Pattern Recognition',
    display_name: 'Hikkake Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHIKKAKEMOD: {
    name: 'CDLHIKKAKEMOD',
    group: 'Pattern Recognition',
    display_name: 'Modified Hikkake Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLHOMINGPIGEON: {
    name: 'CDLHOMINGPIGEON',
    group: 'Pattern Recognition',
    display_name: 'Homing Pigeon',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLIDENTICAL3CROWS: {
    name: 'CDLIDENTICAL3CROWS',
    group: 'Pattern Recognition',
    display_name: 'Identical Three Crows',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLINNECK: {
    name: 'CDLINNECK',
    group: 'Pattern Recognition',
    display_name: 'In-Neck Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLINVERTEDHAMMER: {
    name: 'CDLINVERTEDHAMMER',
    group: 'Pattern Recognition',
    display_name: 'Inverted Hammer',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLKICKING: {
    name: 'CDLKICKING',
    group: 'Pattern Recognition',
    display_name: 'Kicking',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLKICKINGBYLENGTH: {
    name: 'CDLKICKINGBYLENGTH',
    group: 'Pattern Recognition',
    display_name: 'Kicking - bull/bear determined by the longer marubozu',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLLADDERBOTTOM: {
    name: 'CDLLADDERBOTTOM',
    group: 'Pattern Recognition',
    display_name: 'Ladder Bottom',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLLONGLEGGEDDOJI: {
    name: 'CDLLONGLEGGEDDOJI',
    group: 'Pattern Recognition',
    display_name: 'Long Legged Doji',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLLONGLINE: {
    name: 'CDLLONGLINE',
    group: 'Pattern Recognition',
    display_name: 'Long Line Candle',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLMARUBOZU: {
    name: 'CDLMARUBOZU',
    group: 'Pattern Recognition',
    display_name: 'Marubozu',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLMATCHINGLOW: {
    name: 'CDLMATCHINGLOW',
    group: 'Pattern Recognition',
    display_name: 'Matching Low',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLMATHOLD: {
    name: 'CDLMATHOLD',
    group: 'Pattern Recognition',
    display_name: 'Mat Hold',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.5 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLMORNINGDOJISTAR: {
    name: 'CDLMORNINGDOJISTAR',
    group: 'Pattern Recognition',
    display_name: 'Morning Doji Star',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.3 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLMORNINGSTAR: {
    name: 'CDLMORNINGSTAR',
    group: 'Pattern Recognition',
    display_name: 'Morning Star',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: { penetration: 0.3 },
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLONNECK: {
    name: 'CDLONNECK',
    group: 'Pattern Recognition',
    display_name: 'On-Neck Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLPIERCING: {
    name: 'CDLPIERCING',
    group: 'Pattern Recognition',
    display_name: 'Piercing Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLRICKSHAWMAN: {
    name: 'CDLRICKSHAWMAN',
    group: 'Pattern Recognition',
    display_name: 'Rickshaw Man',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLRISEFALL3METHODS: {
    name: 'CDLRISEFALL3METHODS',
    group: 'Pattern Recognition',
    display_name: 'Rising/Falling Three Methods',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLSEPARATINGLINES: {
    name: 'CDLSEPARATINGLINES',
    group: 'Pattern Recognition',
    display_name: 'Separating Lines',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLSHOOTINGSTAR: {
    name: 'CDLSHOOTINGSTAR',
    group: 'Pattern Recognition',
    display_name: 'Shooting Star',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLSHORTLINE: {
    name: 'CDLSHORTLINE',
    group: 'Pattern Recognition',
    display_name: 'Short Line Candle',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLSPINNINGTOP: {
    name: 'CDLSPINNINGTOP',
    group: 'Pattern Recognition',
    display_name: 'Spinning Top',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLSTALLEDPATTERN: {
    name: 'CDLSTALLEDPATTERN',
    group: 'Pattern Recognition',
    display_name: 'Stalled Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLSTICKSANDWICH: {
    name: 'CDLSTICKSANDWICH',
    group: 'Pattern Recognition',
    display_name: 'Stick Sandwich',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLTAKURI: {
    name: 'CDLTAKURI',
    group: 'Pattern Recognition',
    display_name: 'Takuri (Dragonfly Doji with very long lower shadow)',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLTASUKIGAP: {
    name: 'CDLTASUKIGAP',
    group: 'Pattern Recognition',
    display_name: 'Tasuki Gap',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLTHRUSTING: {
    name: 'CDLTHRUSTING',
    group: 'Pattern Recognition',
    display_name: 'Thrusting Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLTRISTAR: {
    name: 'CDLTRISTAR',
    group: 'Pattern Recognition',
    display_name: 'Tristar Pattern',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLUNIQUE3RIVER: {
    name: 'CDLUNIQUE3RIVER',
    group: 'Pattern Recognition',
    display_name: 'Unique 3 River',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLUPSIDEGAP2CROWS: {
    name: 'CDLUPSIDEGAP2CROWS',
    group: 'Pattern Recognition',
    display_name: 'Upside Gap Two Crows',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  CDLXSIDEGAP3METHODS: {
    name: 'CDLXSIDEGAP3METHODS',
    group: 'Pattern Recognition',
    display_name: 'Upside/Downside Gap Three Methods',
    function_flags: ['Output is a candlestick'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { integer: ['Line'] },
    output_names: ['integer'],
  },
  AVGPRICE: {
    name: 'AVGPRICE',
    group: 'Price Transform',
    display_name: 'Average Price',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['open', 'high', 'low', 'close'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  MEDPRICE: {
    name: 'MEDPRICE',
    group: 'Price Transform',
    display_name: 'Median Price',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['high', 'low'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TYPPRICE: {
    name: 'TYPPRICE',
    group: 'Price Transform',
    display_name: 'Typical Price',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  WCLPRICE: {
    name: 'WCLPRICE',
    group: 'Price Transform',
    display_name: 'Weighted Close Price',
    function_flags: ['Output scale same as input'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  BETA: {
    name: 'BETA',
    group: 'Statistic Functions',
    display_name: 'Beta',
    function_flags: null,
    input_names: { price0: 'high', price1: 'low' },
    parameters: { timeperiod: 5 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  CORREL: {
    name: 'CORREL',
    group: 'Statistic Functions',
    display_name: "Pearson's Correlation Coefficient (r)",
    function_flags: null,
    input_names: { price0: 'high', price1: 'low' },
    parameters: { timeperiod: 30 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  LINEARREG: {
    name: 'LINEARREG',
    group: 'Statistic Functions',
    display_name: 'Linear Regression',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  LINEARREG_ANGLE: {
    name: 'LINEARREG_ANGLE',
    group: 'Statistic Functions',
    display_name: 'Linear Regression Angle',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  LINEARREG_INTERCEPT: {
    name: 'LINEARREG_INTERCEPT',
    group: 'Statistic Functions',
    display_name: 'Linear Regression Intercept',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  LINEARREG_SLOPE: {
    name: 'LINEARREG_SLOPE',
    group: 'Statistic Functions',
    display_name: 'Linear Regression Slope',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  STDDEV: {
    name: 'STDDEV',
    group: 'Statistic Functions',
    display_name: 'Standard Deviation',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 5, nbdev: 1 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TSF: {
    name: 'TSF',
    group: 'Statistic Functions',
    display_name: 'Time Series Forecast',
    function_flags: ['Output scale same as input'],
    input_names: { price: 'close' },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  VAR: {
    name: 'VAR',
    group: 'Statistic Functions',
    display_name: 'Variance',
    function_flags: null,
    input_names: { price: 'close' },
    parameters: { timeperiod: 5, nbdev: 1 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ATR: {
    name: 'ATR',
    group: 'Volatility Indicators',
    display_name: 'Average True Range',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  NATR: {
    name: 'NATR',
    group: 'Volatility Indicators',
    display_name: 'Normalized Average True Range',
    function_flags: ['Function has an unstable period'],
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: { timeperiod: 14 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  TRANGE: {
    name: 'TRANGE',
    group: 'Volatility Indicators',
    display_name: 'True Range',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  AD: {
    name: 'AD',
    group: 'Volume Indicators',
    display_name: 'Chaikin A/D Line',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close', 'volume'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  ADOSC: {
    name: 'ADOSC',
    group: 'Volume Indicators',
    display_name: 'Chaikin A/D Oscillator',
    function_flags: null,
    input_names: { prices: ['high', 'low', 'close', 'volume'] },
    parameters: { fastperiod: 3, slowperiod: 10 },
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
  OBV: {
    name: 'OBV',
    group: 'Volume Indicators',
    display_name: 'On Balance Volume',
    function_flags: null,
    input_names: { price: 'close', prices: ['volume'] },
    parameters: {},
    output_flags: { real: ['Line'] },
    output_names: ['real'],
  },
};

export const TRADINGVIEW_INDICATOR_ID_MAP = [
  {
    label: '24-hour Volume',
    value: 'STD;24h%Volume',
  },
  {
    label: 'Accumulation/Distribution',
    value: 'STD;Accumulation_Distribution',
  },
  {
    label: 'Advance Decline Line',
    value: 'STD;Advance%1Decline%1Line',
  },
  {
    label: 'Advance Decline Ratio',
    value: 'STD;Advance%1Decline%1Ratio',
  },
  {
    label: 'Advance/Decline Ratio (Bars)',
    value: 'STD;Advance_Decline_Ratio_Bars',
  },
  {
    label: 'Arnaud Legoux Moving Average',
    value: 'STD;Arnaud%1Legoux%1Moving%1Average',
  },
  {
    label: 'Aroon',
    value: 'STD;Aroon',
  },
  {
    label: 'Average Day Range',
    value: 'STD;Average%Day%Range',
  },
  {
    label: 'Average Directional Index',
    value: 'STD;Average%1Directional%1Index',
  },
  {
    label: 'Average True Range',
    value: 'STD;Average_True_Range',
  },
  {
    label: 'Awesome Oscillator',
    value: 'STD;Awesome_Oscillator',
  },
  {
    label: 'Balance of Power',
    value: 'STD;Balance%1of%1Power',
  },
  {
    label: 'Bollinger Bands',
    value: 'STD;Bollinger_Bands',
  },
  {
    label: 'Bollinger Bands %B',
    value: 'STD;Bollinger_Bands_B',
  },
  {
    label: 'Bollinger Bands Width',
    value: 'STD;Bollinger_Bands_Width',
  },
  {
    label: 'Bull Bear Power',
    value: 'STD;Bull%Bear%Power',
  },
  {
    label: 'Chaikin Money Flow',
    value: 'STD;Chaikin_Money_Flow',
  },
  {
    label: 'Chaikin Oscillator',
    value: 'STD;Chaikin_Oscillator',
  },
  {
    label: 'Chande Kroll Stop',
    value: 'STD;Chande%1Kroll%1Stop',
  },
  {
    label: 'Chande Momentum Oscillator',
    value: 'STD;Chande_Momentum_Oscillator',
  },
  {
    label: 'Chop Zone',
    value: 'STD;Chop%1Zone',
  },
  {
    label: 'Choppiness Index',
    value: 'STD;Choppiness_Index',
  },
  {
    label: 'Commodity Channel Index',
    value: 'STD;CCI',
  },
  {
    label: 'Connors RSI',
    value: 'STD;Connors_RSI',
  },
  {
    label: 'Coppock Curve',
    value: 'STD;Coppock%1Curve',
  },
  {
    label: 'Correlation Coefficient',
    value: 'CorrelationCoefficient@tv-basicstudies',
  },
  {
    label: 'Cumulative Volume Index',
    value: 'STD;Cumulative%1Volume%1Index',
  },
  {
    label: 'Detrended Price Oscillator',
    value: 'STD;DPO',
  },
  {
    label: 'Directional Movement Index',
    value: 'STD;DMI',
  },
  {
    label: 'Divergence Indicator',
    value: 'STD;Divergence%1Indicator',
  },
  {
    label: 'Donchian Channels',
    value: 'STD;Donchian_Channels',
  },
  {
    label: 'Double EMA',
    value: 'STD;DEMA',
  },
  {
    label: 'Ease of Movement',
    value: 'STD;EOM',
  },
  {
    label: 'Elder Force Index',
    value: 'STD;EFI',
  },
  {
    label: 'Envelope',
    value: 'STD;ENV',
  },
  {
    label: 'Fisher Transform',
    value: 'STD;Fisher_Transform',
  },
  {
    label: 'Gaps',
    value: 'STD;Gaps',
  },
  {
    label: 'Historical Volatility',
    value: 'STD;Historical_Volatility',
  },
  {
    label: 'Hull Moving Average',
    value: 'STD;Hull%1MA',
  },
  {
    label: 'Ichimoku Cloud',
    value: 'STD;Ichimoku%1Cloud',
  },
  {
    label: 'Keltner Channels',
    value: 'STD;Keltner_Channels',
  },
  {
    label: 'Klinger Oscillator',
    value: 'STD;Klinger%1Oscillator',
  },
  {
    label: 'Know Sure Thing',
    value: 'STD;Know_Sure_Thing',
  },
  {
    label: 'Least Squares Moving Average',
    value: 'STD;Least%1Squares%1Moving%1Average',
  },
  {
    label: 'Linear Regression Channel',
    value: 'STD;Linear_Regression',
  },
  {
    label: 'MA Cross',
    value: 'STD;MA%1Cross',
  },
  {
    label: 'Mass Index',
    value: 'STD;Mass%1Index',
  },
  {
    label: 'McGinley Dynamic',
    value: 'STD;McGinley%1Dynamic',
  },
  {
    label: 'Median',
    value: 'STD;Median',
  },
  {
    label: 'Momentum',
    value: 'STD;Momentum',
  },
  {
    label: 'Money Flow Index',
    value: 'STD;Money_Flow',
  },
  {
    label: 'Moon Phases',
    value: 'MoonPhases@tv-basicstudies',
  },
  {
    label: 'Moving Average',
    value: 'STD;SMA',
  },
  {
    label: 'Moving Average Convergence Divergence',
    value: 'STD;MACD',
  },
  {
    label: 'Moving Average Exponential',
    value: 'STD;EMA',
  },
  {
    label: 'Moving Average Ribbon',
    value: 'STD;MA%Ribbon',
  },
  {
    label: 'Moving Average Weighted',
    value: 'STD;WMA',
  },
  {
    label: 'Multi-Time Period Charts',
    value: 'STD;Multi-Time%Period%Charts',
  },
  {
    label: 'Net Volume',
    value: 'STD;Net%1Volume',
  },
  {
    label: 'On Balance Volume',
    value: 'STD;On_Balance_Volume',
  },
  {
    label: 'Open Interest',
    value: 'STD;Open%Interest',
  },
  {
    label: 'Parabolic SAR',
    value: 'STD;PSAR',
  },
  {
    label: 'Pivot Points High Low',
    value: 'STD;Pivot%1Points%1High%1Low',
  },
  {
    label: 'Pivot Points Standard',
    value: 'STD;Pivot%1Points%1Standard',
  },
  {
    label: 'Price Oscillator',
    value: 'STD;Price_Oscillator',
  },
  {
    label: 'Price Volume Trend',
    value: 'PriceVolumeTrend@tv-basicstudies',
  },
  {
    label: 'Rate Of Change',
    value: 'STD;ROC',
  },
  {
    label: 'Relative Strength Index',
    value: 'STD;RSI',
  },
  {
    label: 'Relative Vigor Index',
    value: 'STD;Relative_Vigor_Index',
  },
  {
    label: 'Relative Volatility Index',
    value: 'STD;Relative_Volatility_Index',
  },
  {
    label: 'Rob Booker - Intraday Pivot Points',
    value: 'BookerIntradayPivots@tv-basicstudies',
  },
  {
    label: 'Rob Booker - Knoxville Divergence',
    value: 'BookerKnoxvilleDivergence@tv-basicstudies',
  },
  {
    label: 'Rob Booker - Missed Pivot Points',
    value: 'BookerMissedPivots@tv-basicstudies',
  },
  {
    label: 'Rob Booker - Reversal',
    value: 'BookerReversal@tv-basicstudies',
  },
  {
    label: 'Rob Booker - Ziv Ghost Pivots',
    value: 'STD;Rob%1Booker%1Ghost%1Pivots%1v2',
  },
  {
    label: 'SMI Ergodic Indicator',
    value: 'STD;SMI_Ergodic_Indicator_Oscillator',
  },
  {
    label: 'SMI Ergodic Oscillator',
    value: 'STD;SMI_Ergodic_Oscillator',
  },
  {
    label: 'Smoothed Moving Average',
    value: 'STD;Smoothed%1Moving%1Average',
  },
  {
    label: 'Stochastic',
    value: 'STD;Stochastic',
  },
  {
    label: 'Stochastic RSI',
    value: 'STD;Stochastic_RSI',
  },
  {
    label: 'Supertrend',
    value: 'STD;Supertrend',
  },
  {
    label: 'Technical Ratings',
    value: 'STD;Technical%1Ratings',
  },
  {
    label: 'Time Weighted Average Price',
    value: 'STD;Time%1Weighted%1Average%1Price',
  },
  {
    label: 'Triple EMA',
    value: 'STD;TEMA',
  },
  {
    label: 'TRIX',
    value: 'STD;TRIX',
  },
  {
    label: 'True Strength Index',
    value: 'STD;True%1Strength%1Indicator',
  },
  {
    label: 'Ultimate Oscillator',
    value: 'STD;Ultimate_Oscillator',
  },
  {
    label: 'Up/Down Volume',
    value: 'STD;UP_DOWN_Volume',
  },
  {
    label: 'Visible Average Price',
    value: 'STD;Visible%1Average%1Price',
  },
  {
    label: 'Volatility Stop',
    value: 'STD;Volatility_Stop',
  },
  {
    label: 'Volume',
    value: 'Volume@tv-basicstudies',
  },
  {
    label: 'Volume Oscillator',
    value: 'STD;Volume%1Oscillator',
  },
  {
    label: 'Volume Weighted Average Price',
    value: 'STD;VWAP',
  },
  {
    label: 'Volume Weighted Moving Average',
    value: 'STD;VWMA',
  },
  {
    label: 'Vortex Indicator',
    value: 'STD;Vortex%1Indicator',
  },
  {
    label: 'Williams Alligator',
    value: 'STD;Williams_Alligator',
  },
  {
    label: 'Williams Fractals',
    value: 'STD;Whilliams_Fractals',
  },
  {
    label: 'Williams Percent Range',
    value: 'STD;Willams_R',
  },
  {
    label: 'Woodies CCI',
    value: 'STD;Woodies%1CCI',
  },
  {
    label: 'Zig Zag',
    value: 'STD;Zig_Zag',
  },
];
