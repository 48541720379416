export type TimelineListProps = {
  events: TimelineEvents[];
  isLoading: boolean;
};

export type TimelineEvents = {
  timestamp: number;
  eventType: TimelineEventTypes;
  data: any;
};

export enum TimelineEventTypes {
  ORDER = 'order',
  ERROR = 'error',
  RUN_ALERT = 'run_alert',
  STOP_EVENT = 'strategy_stop_event',
  START_EVENT = 'strategy_start_event',
  LOG_EVENT = 'strategy_log',
}

export type OrderEventData = {
  datetime: number;
  type: 'buy' | 'sell';
  size: number;
  symbol: string;
  at_price: number;
  txn_value: number;
  txn_comm: number;
  txn_dedeuctions: TransactionDeductions;
  gross_profit: number;
  exec_type: string;
};

export type TransactionDeductions = {
  fees: {
    fee_details: {
      currency: string;
      cost: number;
    }[];
    fee_quote: number;
    quote_currency: string;
  };
};

export type TimelineOrderEvents = {
  timestamp: number;
  eventType: TimelineEventTypes;
  data: OrderEventData;
};
