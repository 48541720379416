import { StrategyState } from 'views/Strategy/state';
import { useParams } from 'react-router-dom';

export const useComponentLogic = () => {
  const State = StrategyState.get();
  const selectedStrategy = State.selectedStrategy;
  // @ts-ignore
  let { strategyId } = useParams();
  if (strategyId === 'configure') strategyId = undefined;

  return {
    State,
    selectedStrategy,
  };
};
