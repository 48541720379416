import { useMediaQuery } from 'react-responsive';

export const useDisplayType = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  return {
    isDesktop,
    isMobile: !isDesktop,
  };
};

export const useCheckTabletDisplay = () => {
  const isLandscapeTablet = useMediaQuery({ minWidth: 1025, maxWidth: 1300 });
  return { isLandscapeTablet };
};

export const appHeight = () => {
  const container = document.getElementById('app-container-main');
  container?.style.setProperty('height', `${window.innerHeight}px`);
};
