import { useState } from 'react';

export const useComponentState = () => {
  // Bottomsheet Show/Hide states
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  // Show Chart Flag for mobile
  const [showChartsOnMobile, setShowChartsOnMobile] = useState(false);
  const [showMakeLiveBottomSheet, setMakeLiveBottomsheetView] = useState(false);

  return {
    showDeactivateConfirmation,
    setShowDeactivateConfirmation,
    showChartsOnMobile,
    setShowChartsOnMobile,
    showMakeLiveBottomSheet,
    setMakeLiveBottomsheetView,
  };
};
