import ChartContainer from 'components/ChartContainer';
import PushButton from 'components/PushButton';
import { PushButtonType } from 'components/PushButton/types';
import { useEffect, useState } from 'react';
import {
  AiOutlineDownload,
  AiOutlineLineChart,
  AiOutlineRocket,
} from 'react-icons/ai';
import { useDisplayType } from 'utils/display';
import ResultAnalysis from './components/ResultAnalysis';
import styles from './backtestresults.module.css';
import { BacktestResulsProps } from './types';
import backtest_illustration from 'assets/svg/backtest_illustration.svg';
import StrategyMenu from '../StrategyMenu';
import NoDataMessage from 'components/NoDataDisplay';
import { MakeLiveBottomsheet } from '../MakeLiveBottomsheet';
import ConfirmationBottomSheet from 'components/ConfirmationBottomSheet';
import { TradingModeTypes } from '../MakeLiveBottomsheet/types';
import { useComponentLogic } from './logics';
import { Chat } from '../Chat';
import { Analytics } from 'utils/analytics';
import { page_visit_str_backtest } from 'constants/app/analyticsEvents';

export const BacktestResults = (props: BacktestResulsProps) => {
  const Component = useComponentLogic();
  const { State } = Component;
  const {
    resultChartData,
    resultAnalysisData: analysisData,
    basicParams,
    tradingMode,
    isLoadingStrategyStateChange,
  } = State;
  const { saveOrUpdate, selectedStrategy } = props;
  const { isDesktop, isMobile } = useDisplayType();

  // Bottomsheet Show/Hide states
  const [showMakeLiveBottomSheet, setMakeLiveBottomsheetView] = useState(false);
  const [showChangeConfirmation, setShowChangeConfirmation] = useState(false);

  // Component State (Move to state hook if required)
  const [showChartsOnMobile, setMobileChartDisplay] = useState(false);

  const summaryInfo = !!analysisData
    ? [
        {
          name: 'Initial Value',
          value: analysisData?.initial_portfolio_value.toFixed(2),
        },
        {
          name: 'Current Value',
          value: analysisData.final_portfolio_value.toFixed(2),
          color:
            analysisData.final_portfolio_value >
            analysisData.initial_portfolio_value
              ? '#27ad5f'
              : '#ff6868',
        },
        {
          name: 'Gross P&L',
          value: analysisData.trade_analysis.pnl_gross.toFixed(2),
          color:
            analysisData.trade_analysis.pnl_gross > 0 ? '#27ad5f' : '#ff6868',
        },
        {
          name: 'Fees',
          value: analysisData.trade_analysis.commissions.toFixed(2),
          color: '#ff6868',
        },
        {
          name: 'Net P&L',
          value: analysisData.trade_analysis.pnl_net.toFixed(2),
          color:
            analysisData.trade_analysis.pnl_net > 0 ? '#27ad5f' : '#ff6868',
        },
        {
          name: 'Returns',
          value:
            (
              (analysisData.trade_analysis.pnl_net /
                analysisData.initial_portfolio_value) *
              100
            ).toFixed(2) + '%',
          color:
            analysisData.trade_analysis.pnl_net > 0 ? '#27ad5f' : '#ff6868',
        },
      ]
    : [];

  const showChart = isDesktop || (isMobile && showChartsOnMobile);
  const resultsAvailable = !!resultChartData && !!analysisData;
  const symbolSplit = basicParams.symbol.split('/');

  // Send page visit event
  useEffect(() => {
    Analytics.track(page_visit_str_backtest);
  }, []);

  return resultsAvailable ? (
    <div className={styles.container}>
      {!showChartsOnMobile && (
        <div
          className={
            isDesktop ? styles.resultsContainer : styles.resultsContainerMobile
          }
        >
          <StrategyMenu
            selectedStrategy={selectedStrategy}
            showBackButton={true}
          />
          <ResultAnalysis
            resultData={analysisData}
            symbolsInfo={{
              base_currency: symbolSplit[0],
              quote_currency: symbolSplit[1],
              exchange_id: basicParams.exchange,
            }}
            interval={basicParams.interval}
            tradingMode={selectedStrategy?.tradingMode}
            backtestDateRange={resultChartData?.backtestDateRange}
          />
          <div className={styles.actionsContainer}>
            {!tradingMode && (
              <PushButton
                additionalClassName={styles.actionButton}
                title={'Save'}
                onClick={() => saveOrUpdate()}
                icon={<AiOutlineDownload />}
                type={PushButtonType.Secondary}
              />
            )}
            {isMobile && (
              <PushButton
                additionalClassName={styles.actionButton}
                title={'Charts'}
                onClick={() => {
                  setMobileChartDisplay(true);
                }}
                icon={<AiOutlineLineChart />}
                type={PushButtonType.Secondary}
              />
            )}
            <PushButton
              additionalClassName={styles.actionButton}
              title={tradingMode ? 'Deploy Changes' : 'Deploy'}
              onClick={() =>
                !tradingMode
                  ? setMakeLiveBottomsheetView(true)
                  : setShowChangeConfirmation(true)
              }
              icon={<AiOutlineRocket />}
            />
          </div>
          <ConfirmationBottomSheet
            show={showChangeConfirmation}
            header={'Deploy Changes'}
            subtitle={'Deploy recent changes to strategy.'}
            message={
              'This strategy is already live. Are you sure you want to deploy any changes made to the strategy configuration?'
            }
            onCancel={() => setShowChangeConfirmation(false)}
            onConfim={() => saveOrUpdate()}
            cancelText={'Cancel'}
            confirmText={'Confirm'}
            isLoading={isLoadingStrategyStateChange}
          />
          <MakeLiveBottomsheet
            tradingModeOptions={[TradingModeTypes.Paper, TradingModeTypes.Live]}
            isBottomSheetOpen={showMakeLiveBottomSheet}
            onDismiss={() => setMakeLiveBottomsheetView(false)}
            basicStrategyParams={basicParams}
            onMakeLive={saveOrUpdate}
          />
          <Chat />
        </div>
      )}
      {showChart && (
        <div className={styles.resultContainer}>
          {isMobile && (
            <StrategyMenu
              selectedStrategy={selectedStrategy}
              showBackButton={true}
              customActionOnBack={() => setMobileChartDisplay(false)}
            />
          )}
          <div className={styles.summaryContainer}>
            {isDesktop &&
              summaryInfo.map((s) => {
                return (
                  <div className={styles.infoColumn} key={s.name}>
                    <span className={styles.rowHeader}>{s.name}</span>
                    <span
                      className={styles.rowValue}
                      style={{
                        color: s.color ? s.color : '',
                      }}
                    >
                      {s.value}
                    </span>
                  </div>
                );
              })}
          </div>
          <div className={styles.chartContainer}>
            <ChartContainer data={resultChartData} isBacktest={false} />
          </div>
        </div>
      )}
    </div>
  ) : (
    <>
      <StrategyMenu selectedStrategy={selectedStrategy} showBackButton={true} />
      <NoDataMessage
        image={backtest_illustration}
        messageText='Please run the backest once, using the "Run Backtest" Button on
          configuration page.'
      />
    </>
  );
};
