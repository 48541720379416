export const get_exchanges = {
  $metadata: {
    httpStatusCode: 200,
    requestId: 'QPDQHALCCRK3T1E6TMAV3R97E3VV4KQNSO5AEMVJF66Q9ASUAAJG',
    attempts: 1,
    totalRetryDelay: 0,
  },
  Count: 1,
  Items: [
    {
      exchangeId: 'binance',
      exchangeName: 'Binance',
    },
  ],
  ScannedCount: 1,
};

export const get_exchanges_portfolio = {
  totalUsdBalance: 15297.26,
  totals: [
    {
      balance: 0.0851,
      id: 'bitcoin',
      name: 'Bitcoin',
      symbol: 'BTC',
      usdPrice: 64213,
      amount: 5466.25295202535,
    },
    {
      balance: 1.752,
      id: 'ethereum',
      name: 'Ethereum',
      symbol: 'ETH',
      usdPrice: 3120,
      amount: 5466.25295202535,
    },
    {
      balance: 52.35407589,
      id: 'tether',
      name: 'Tether',
      symbol: 'USDT',
      usdPrice: 83.37,
      amount: 4364.7593069493,
    },
  ],
  failedExchanges: [],
};

export const get_market_structure = {
  symbol: 'BTC/USDT',
  margin: true,
  ticker: {
    symbol: 'BTC/USDT',
    average: 63012.14,
    last: 62249.16,
    vwap: 63232.27017591,
    change: -1525.96,
    quoteVolume: 1622697743.246257,
    askVolume: 4.46572,
    previousClose: 63775.11,
    datetime: '2024-05-08T07:24:32.159Z',
    high: 64422.41,
    low: 62111,
    percentage: -2.393,
    ask: 62249.17,
    bidVolume: 1.93532,
    bid: 62249.16,
    baseVolume: 25662.49383,
    close: 62249.16,
    open: 63775.12,
    timestamp: 1715153072159,
    info: {
      priceChange: '-1525.96000000',
      symbol: 'BTCUSDT',
      askPrice: '62249.17000000',
      bidQty: '1.93532000',
      count: '1269626',
      openPrice: '63775.12000000',
      lastId: '3591132824',
      quoteVolume: '1622697743.24625700',
      bidPrice: '62249.16000000',
      firstId: '3589863199',
      volume: '25662.49383000',
      lastQty: '0.01020000',
      askQty: '4.46572000',
      weightedAvgPrice: '63232.27017591',
      lowPrice: '62111.00000000',
      highPrice: '64422.41000000',
      closeTime: '1715153072159',
      prevClosePrice: '63775.11000000',
      openTime: '1715066672159',
      priceChangePercent: '-2.393',
      lastPrice: '62249.16000000',
    },
  },
  swap: false,
  contract: false,
  precision: { amount: 5, quote: 8, price: 2, base: 8 },
  active: true,
  maker: 0.001,
  baseId: 'BTC',
  type: 'spot',
  quoteId: 'USDT',
  lowercaseId: 'btcusdt',
  quote: 'USDT',
  future: false,
  feeSide: 'get',
  spot: true,
  percentage: true,
  tierBased: false,
  id: 'BTCUSDT',
  taker: 0.001,
  limits: {
    market: { max: 95.73960333, min: 0 },
    leverage: {},
    amount: { max: 9000, min: 0.00001 },
    cost: { max: 9000000, min: 5 },
    price: { max: 1000000, min: 0.01 },
  },
  base: 'BTC',
  option: false,
  info: {
    symbol: 'BTCUSDT',
    quoteOrderQtyMarketAllowed: true,
    allowTrailingStop: true,
    permissionSets: [
      [
        'SPOT',
        'MARGIN',
        'TRD_GRP_004',
        'TRD_GRP_005',
        'TRD_GRP_006',
        'TRD_GRP_009',
        'TRD_GRP_010',
        'TRD_GRP_011',
        'TRD_GRP_012',
        'TRD_GRP_013',
        'TRD_GRP_014',
        'TRD_GRP_015',
        'TRD_GRP_016',
        'TRD_GRP_017',
        'TRD_GRP_018',
        'TRD_GRP_019',
        'TRD_GRP_020',
        'TRD_GRP_021',
        'TRD_GRP_022',
        'TRD_GRP_023',
        'TRD_GRP_024',
        'TRD_GRP_025',
      ],
    ],
    baseAsset: 'BTC',
    filters: [
      {
        maxPrice: '1000000.00000000',
        filterType: 'PRICE_FILTER',
        minPrice: '0.01000000',
        tickSize: '0.01000000',
      },
      {
        stepSize: '0.00001000',
        filterType: 'LOT_SIZE',
        maxQty: '9000.00000000',
        minQty: '0.00001000',
      },
      { filterType: 'ICEBERG_PARTS', limit: '10' },
      {
        stepSize: '0.00000000',
        filterType: 'MARKET_LOT_SIZE',
        maxQty: '95.73960333',
        minQty: '0.00000000',
      },
      {
        minTrailingBelowDelta: '10',
        maxTrailingBelowDelta: '2000',
        maxTrailingAboveDelta: '2000',
        filterType: 'TRAILING_DELTA',
        minTrailingAboveDelta: '10',
      },
      {
        bidMultiplierDown: '0.2',
        avgPriceMins: '5',
        filterType: 'PERCENT_PRICE_BY_SIDE',
        askMultiplierDown: '0.2',
        bidMultiplierUp: '5',
        askMultiplierUp: '5',
      },
      {
        applyMinToMarket: true,
        maxNotional: '9000000.00000000',
        avgPriceMins: '5',
        filterType: 'NOTIONAL',
        minNotional: '5.00000000',
        applyMaxToMarket: false,
      },
      { filterType: 'MAX_NUM_ORDERS', maxNumOrders: '200' },
      { filterType: 'MAX_NUM_ALGO_ORDERS', maxNumAlgoOrders: '5' },
    ],
    baseAssetPrecision: '8',
    isSpotTradingAllowed: true,
    quoteAssetPrecision: '8',
    quoteCommissionPrecision: '8',
    cancelReplaceAllowed: true,
    ocoAllowed: true,
    defaultSelfTradePreventionMode: 'EXPIRE_MAKER',
    quotePrecision: '8',
    orderTypes: [
      'LIMIT',
      'LIMIT_MAKER',
      'MARKET',
      'STOP_LOSS_LIMIT',
      'TAKE_PROFIT_LIMIT',
    ],
    permissions: [],
    icebergAllowed: true,
    otoAllowed: false,
    isMarginTradingAllowed: true,
    quoteAsset: 'USDT',
    baseCommissionPrecision: '8',
    allowedSelfTradePreventionModes: [
      'EXPIRE_TAKER',
      'EXPIRE_MAKER',
      'EXPIRE_BOTH',
    ],
    status: 'TRADING',
  },
};
