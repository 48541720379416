import { useEffect, useState } from 'react';

export const useCountdownTimer = (countdownTime: number) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(countdownTime);

  //   const resendOtp = useCallback(() => {
  //     setTimeRemaining(countdownTime);
  //     setResendButtonDisplay(false);
  //     resendMethod();
  //   }, [countdownTime, resendMethod]);

  useEffect(() => {
    if (timeRemaining > 0) {
      const t = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);

      return () => clearTimeout(t);
    }
  }, [timeRemaining]);

  const restart = () => {
    setTimeRemaining(countdownTime);
  };

  return {
    timeRemaining,
    restart,
  };
};
