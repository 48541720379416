import BottomSheet from 'components/BottomSheet';
import Loader from 'components/Loader';
import PageHeader from 'components/PageHeader';
import { useDisplayType } from 'utils/display';
import styles from './bsm.module.css';
import { ActionListItemProps, BottomSheetMenuProps } from './types';

const ActionListItem = (props: ActionListItemProps) => {
  const { name, description, action, icon } = props;
  return (
    <div className={styles.listItemContainer} onClick={action}>
      {icon ? <div className={styles.iconContainer}>{icon}</div> : null}
      <div>
        <div className={styles.listItemName}>{name}</div>
        <div className={styles.listItemDesc}>{description}</div>
      </div>
    </div>
  );
};

const BottomSheetMenu = (props: BottomSheetMenuProps) => {
  const {
    header,
    subtitle,
    show,
    onCancel,
    isLoading,
    actions,
    showbackButton,
  } = props;
  const { isDesktop } = useDisplayType();
  return (
    <BottomSheet
      isOpen={show}
      onDismiss={onCancel}
      header={
        <PageHeader
          title={header}
          subTitle={subtitle}
          showBackButton={showbackButton ? true : false}
          customActionOnBack={onCancel}
        />
      }
    >
      <>
        <div className={isDesktop ? styles.container : styles.mobileContainer}>
          {isLoading && <Loader containerClassName={styles.loaderContainer} />}
          <div className={styles.optionsContainer}>
            {actions.map((a) =>
              !!a ? (
                <ActionListItem
                  key={a.name}
                  name={a.name}
                  description={a.description}
                  action={a.action}
                  icon={a.icon}
                />
              ) : null,
            )}
          </div>
        </div>
      </>
    </BottomSheet>
  );
};

export default BottomSheetMenu;
