import { QueryBuilderProps } from './types';
import styles from './qb.module.css';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import CodeView from 'components/CodeView';
import { isDemo } from 'utils/demo';
import { MovesTypes } from 'views/Strategy/types';

const QueryBuilder = (props: QueryBuilderProps) => {
  const {
    text,
    condition,
    error,
    placeholder,
    onChange,
    onBlur,
    showCondition = true,
    moveType,
  } = props;
  const [lastValue, setLastValue] = useState<string>('');

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    let text = e.target.value;
    if (text) {
      // prevent the second space from being added to the input
      // text = text.replace(/\s\s/g, ' ');
      // const { output, error } = processStringInput(text ?? '', variables);
      // setError(error);
      onChange(text, '');
    } else {
      onChange('', '');
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    let text = e.target.value;
    if (text) {
      setLastValue(text);
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    let text = e.target.value;
    if (text !== lastValue) {
      if (isDemo) {
        onChange(lastValue, 'true');
        return;
      }
      onBlur(text);
    }
  };

  const formatPythonCondition = (conditionStr: string) => {
    // Replace multiple spaces with a single space
    conditionStr = conditionStr.replace(/\s+/g, ' ');

    // Break down conditions by 'and' & 'or' into an array
    const conditionArray = conditionStr.split(/( and | or )/g);

    // If only one operator, no need for multiple lines
    if (conditionArray.length < 2) {
      if (moveType === MovesTypes.ENTRY)
        return `# Buy when if condition is met \nif (${conditionStr.trim()}):\n    buy()`;
      else
        return `# Sell when if condition is met \nif (${conditionStr.trim()}):\n    sell()`;
    }

    // Indent & Join them back to string for multiple operators
    const indentedConditions = conditionArray
      .map((cond, i) => {
        if (cond.trim() === 'and' || cond.trim() === 'or') {
          return ` ${cond} `;
        }
        return `\n    ${cond.trim()}`;
      })
      .join('');

    if (moveType === MovesTypes.ENTRY)
      return `# Buy when if condition is met \nif (${indentedConditions.trim()}):\n    buy()`;
    else
      return `# Sell when if condition is met \nif (${indentedConditions.trim()}):\n    sell()`;
  };

  return (
    <>
      <div className={styles.container}>
        <TextareaAutosize
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={styles.textarea}
          onChange={handleChange}
          value={text}
        />
        {condition && showCondition ? (
          <div className={styles.output}>
            <CodeView value={formatPythonCondition(condition)} />
          </div>
        ) : null}
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    </>
  );
};

export default QueryBuilder;
