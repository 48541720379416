import { Auth, Storage } from 'aws-amplify';
import { get_backtestResult } from 'mocks/responses/backtestResult';
import { BacktestResults } from 'types/backtest';
import { CandleChartAxisMap, OrdersData } from 'types/chart';
import { isDemo } from 'utils/demo';

export const getBacktestResultsFromS3 = async (
  userId: string,
): Promise<BacktestResults> => {
  if (isDemo) {
    return new Promise((resolve) => {
      resolve(formatBacktestResults(get_backtestResult));
    });
  }
  return Auth.currentCredentials().then(async (credentials) => {
    const result = await Storage.get('results.json', {
      download: true,
      contentType: 'application/json',
      level: 'private',
      identityId: userId,
      // progressCallback(progress: any) {
      //   console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
      // },
      credentials: Auth.essentialCredentials(credentials),
    });

    // @ts-ignore
    let text = await result.Body.text();
    return formatBacktestResults(JSON.parse(text));
  });
};

export const formatBacktestResults = (rawData: any): BacktestResults => {
  // Convert to local timezone
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const xyMap: CandleChartAxisMap = {};
  const cData = rawData['datetime_data'].map((d: number, i: number) => {
    const t: number = d - offset * 60;
    for (let ind in rawData['plot_info']) {
      rawData['plot_info'][ind].forEach((iLineData: any) => {
        iLineData.data[i] = {
          time: t,
          value: iLineData.data[i] as unknown as number,
        };
      });
    }

    rawData['portfolio_value_data'][i] = {
      time: t,
      value: rawData['portfolio_value_data'][i],
    };

    rawData['drawdown_data'][i] = {
      time: t,
      value: rawData['drawdown_data'][i],
    };

    const y = {
      time: t,
      open: rawData['open_data'][i],
      high: rawData['high_data'][i],
      low: rawData['low_data'][i],
      close: rawData['close_data'][i],
    };

    xyMap[t] = y;

    return y;
  });

  const oData: OrdersData = rawData['orders'].map((o: any) => {
    const green =
      xyMap[o.datetime - offset * 60].close >
      xyMap[o.datetime - offset * 60].open;
    return {
      ...o,
      datetime: o.datetime - offset * 60,
      markerPosition: green ? 'belowBar' : 'aboveBar',
      shape: green ? 'arrowUp' : 'arrowDown',
    };
  });

  return {
    chartData: {
      cData,
      vData: [],
      oData: oData,
      tData: rawData['trades_closed'],
      iData: rawData['plot_info'],
      pData: rawData['portfolio_value_data'],
      drawdownData: rawData['drawdown_data'],
    },
    analysis: rawData['analysis'],
  };
};
